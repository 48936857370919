import {
  borderRadius,
  MediaBreakpoint,
  palette,
  Skeleton,
  spacing,
  useResponsive,
} from '@mortgagehippo/ds';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 40rem;

  ${MediaBreakpoint.PHONE} {
    width: ${spacing(11)};
  }
`;

const Column = styled.div`
  flex: 1 1 50%;
  margin-right: ${spacing(7)};

  ${MediaBreakpoint.PHONE} {
    margin: ${spacing(2)} 0 0;
  }
`;

const StyledSkeleton = styled(Skeleton)<{ backgroundColor?: string }>`
  .ant-skeleton-paragraph {
    margin: 0;
  }

  .ant-skeleton-content .ant-skeleton-paragraph > li {
    background: ${(p) => p.backgroundColor || palette('primary50')};
    opacity: 0.5;
    height: ${spacing(3)};
    border-radius: ${borderRadius(1)};

    & + li {
      margin-top: ${spacing(2)};
    }
  }
`;

export const ApplicationHeaderSkeleton = (props: { backgroundColor?: string }) => {
  const { backgroundColor } = props;
  const responsive = useResponsive();

  const skeletonEl = (
    <StyledSkeleton
      backgroundColor={backgroundColor}
      title={false}
      paragraph={{ rows: (responsive.PHONE.BIGGER && 2) || 1 }}
    />
  );

  return (
    <Container>
      <Column>{skeletonEl}</Column>
      {responsive.PHONE.BIGGER ? <Column>{skeletonEl}</Column> : null}
    </Container>
  );
};
