import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';

import { type ISmartviewDocumentation } from '$components/smart-view';

import { graphql } from '../apollo';
import { type SmartviewModelType } from '../apollo/graphql';
import { usePartnerDomain } from './use-partner-domain';

const QSmartviewDocumentation = graphql(`
  query SmartviewDocumentation($partnerId: ID!, $model: SmartviewModelType!) {
    partner(id: $partnerId) {
      id
      smartviewDocumentation(model: $model) {
        model
        dataFields {
          name
          title
          type
        }
        exportFields {
          name
          title
          type
        }
        searchFields {
          name
          title
          type
        }
      }
    }
  }
`);

export const useSmartviewDocumentation = (
  model: SmartviewModelType,
  options: IUseApolloQueryOptions = {}
) => {
  const partnerId = usePartnerDomain();
  const [data, loading] = useQuery(
    QSmartviewDocumentation,
    {
      partnerId,
      model,
    },
    {
      fetchPolicy: 'network-only',
      ...options,
    }
  );

  const result = data?.partner?.smartviewDocumentation;
  // use local type for enums
  const documentation = result ? (result as ISmartviewDocumentation) : undefined;

  return [documentation, loading] as const;
};
