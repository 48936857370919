import { spacing, useCustomizations } from '@mortgagehippo/ds';
import { type IBaseTask, type ITaskApplicant } from '@mortgagehippo/tasks';
import { Children, cloneElement, memo, type ReactElement, useCallback, useState } from 'react';
import styled from 'styled-components';

import { type ITasksListMenuGroupProps } from './tasks-list-menu-group';

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled('li')<{ spacingBottom?: number }>`
  padding-bottom: ${(p) => (p.spacingBottom !== undefined ? `${p.spacingBottom}px` : spacing(5))};
`;

interface ITasksListMenuProps<T extends IBaseTask, A extends ITaskApplicant> {
  defaultActiveKeys?: string[];
  children: Array<ReactElement<ITasksListMenuGroupProps<T, A>>>;
  className?: string;
  collapsible?: boolean;
}

export const TasksListMenu = memo(
  <T extends IBaseTask, A extends ITaskApplicant>(props: ITasksListMenuProps<T, A>) => {
    const { defaultActiveKeys, children, className, collapsible } = props;

    const [activeKeys, setActiveKeys] = useState<string[]>(
      (defaultActiveKeys && defaultActiveKeys) || []
    );

    const customizations = useCustomizations();
    const spacingBottom = customizations.number('app:taskList.group.spacing.bottom');

    const handleGroupToggle = useCallback(
      (key: string) => {
        if (!collapsible) {
          return;
        }

        setActiveKeys((prevActiveKeys) => {
          const index = prevActiveKeys.indexOf(key);

          if (index > -1) {
            const copy = [...prevActiveKeys];
            copy.splice(index, 1);
            return copy;
          }

          return [key, ...prevActiveKeys];
        });
      },
      [collapsible]
    );

    const items = Children.map(children, (child) => {
      if (!child) {
        return null;
      }

      const { taskGroup } = child.props;
      const { key } = taskGroup;
      const isActive = !collapsible || activeKeys.includes(key);

      const childProps = {
        ...child.props,
        collapsible,
        isActive,
        onToggle: handleGroupToggle,
      };

      const el = cloneElement(child, childProps);

      return (
        <ListItem key={key} spacingBottom={spacingBottom}>
          {el}
        </ListItem>
      );
    });

    return <List className={className}>{items}</List>;
  }
);
