import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../apollo';

export const MTriggerInternalSystemPushMutation = graphql(`
  mutation TriggerInternalSystemPush($applicationFileId: ID!) {
    triggerInternalSystemPush(applicationFileId: $applicationFileId)
  }
`);

export const useTriggerInternalSystemPush = () => {
  const triggerInternalSystemPush = useMutation(MTriggerInternalSystemPushMutation, undefined);

  return useCallback(
    async (applicationFileId: string) => {
      const response = await triggerInternalSystemPush({ applicationFileId });
      return response.data!.triggerInternalSystemPush;
    },
    [triggerInternalSystemPush]
  );
};
