import {
  Analytics,
  GoogleAnalyticsPlugin,
  HotjarPlugin,
  PostHogPlugin,
} from '@mortgagehippo/analytics';
import { AuthEventType } from '@mortgagehippo/auth';
import * as Sentry from '@sentry/browser';
import { isNil } from 'lodash-es';

import { config } from '../config';
import { getPartner, type IPartner } from '../hooks/use-partner';
import { getUser, type ILPUser } from '../hooks/use-user';
import { auth } from './auth';
import { history } from './history';

const APP_CODE = 'lhub';
let partnerPromise: Promise<IPartner | null> | null;
let partnerData: IPartner | null = null;
let userId: string | null = null;
let userData: ILPUser | null = null;

export const analytics = new Analytics(history);

const getPartnerData = async () => {
  if (isNil(userId)) {
    return null;
  }

  if (partnerData) {
    return partnerData;
  }

  partnerPromise ||= getPartner();

  try {
    partnerData = await partnerPromise;
    return partnerData;
  } catch (e) {
    console.error(e);
    Sentry.captureException(e);
    return null;
  }
};

const getUserData = async () => {
  if (isNil(userId)) {
    return null;
  }

  if (userData && userData.id === userId) {
    return userData;
  }

  try {
    return await getUser();
  } catch (e) {
    console.error(e);
    Sentry.captureException(e);
    return null;
  }
};

const identify = async (nextUserId: string | null) => {
  userId = nextUserId;

  const [partner, user] = await Promise.all([getPartnerData(), getUserData()]);

  // has it changed while we went to the server?
  const canceled = userId !== nextUserId;

  if (canceled) {
    return;
  }

  userData = user;

  analytics.identify(nextUserId, {
    App: APP_CODE,
    Domain: window.location.host,
    'Partner Id': partner?.id ? Number(partner.id) : null,
    Partner: partner?.name || null,
    'Site Id': null,
    Site: 'Lender Hub',
    'Application Id': null,
    'User Created': userData?.createdAt || null,
    'Last Login': userData?.lastLoginAt || null,
    'Login Count': userData?.loginCount || null,
    'Access Level': userData?.authRole?.name,
    Name: userData?.name || null,
    Email: userData?.email || null,
    Phone: userData?.phone || null,
    'Job Title': userData?.jobTitle || null,
    Environment: config.ENVIRONMENT,
  });

  if (partner?.id && partner?.name) {
    analytics.group('partner', partner.id, { name: partner.name });
  }
};

// Initialize Analytics
if (config.LP_GOOGLE_ANALYTICS_KEY) {
  analytics.addPlugin(new GoogleAnalyticsPlugin(config.LP_GOOGLE_ANALYTICS_KEY, 'mh'));
}

if (config.HOTJAR_ID) {
  analytics.addPlugin(new HotjarPlugin(config.HOTJAR_ID, APP_CODE));
}

if (config.POSTHOG_ID) {
  analytics.addPlugin(new PostHogPlugin(config.POSTHOG_ID, APP_CODE));
}

// Track Authorization Events
auth.subscribe((event, _state, user) => {
  if (
    !event ||
    event === AuthEventType.RESTART_SESSION_FAILED ||
    event === AuthEventType.FORCED_LOGOUT
  ) {
    identify(null);
  }

  if (event === AuthEventType.AUTHENTICATED) {
    identify(user?.id ? `${user.id}` : null);
  }

  if (event === AuthEventType.CREATE_ANONYMOUS_ACCOUNT_SUCCESS) {
    analytics.event('application', 'start');
  }

  if (event === AuthEventType.LOGIN_SUCCESS) {
    analytics.event('account', 'login');
  }

  if (event === AuthEventType.SIGNUP_SUCCESS) {
    analytics.event('account', 'signup');
  }
});
