import { createField, type IChoiceOption, type ISelectInputProps, Select } from '@mortgagehippo/ds';

import { useSites } from '../../hooks/use-sites';

type ISiteSelectProps = Omit<ISelectInputProps, 'options' | 'loading'>;

const SiteSelectInput = (props: ISiteSelectProps) => {
  const [sites, loading] = useSites();

  const siteOptions: IChoiceOption[] = sites || [];

  return <Select.Input {...props} options={siteOptions} loading={loading} />;
};

export const SiteSelect = createField(SiteSelectInput);
