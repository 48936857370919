import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../../apollo';

const MCreateHellosignDraftUploadUrl = graphql(`
  mutation CreateHellosignDraftUploadUrl($applicationFileId: ID!, $filename: String!) {
    helloSign {
      createDraftUploadUrl(applicationFileId: $applicationFileId, filename: $filename) {
        id
        postUrl
      }
    }
  }
`);

export const useCreateHellosignDraftUploadUrl = () => {
  const createDraftUploadUrl = useMutation(MCreateHellosignDraftUploadUrl, undefined);

  return useCallback(
    async (applicationFileId: string, filename: string) => {
      const result = await createDraftUploadUrl({ applicationFileId, filename });
      return result.data!.helloSign.createDraftUploadUrl;
    },
    [createDraftUploadUrl]
  );
};
