import { notifications, useCustomizations, useModal } from '@mortgagehippo/ds';
import { useCallback, useMemo } from 'react';

import { type IActionProps, type ISendPreQualificationLetterAction } from '$components/actions';

import { SendPreQualificationLetterModal } from './send-pre-qualification-letter-modal';
import {
  type SendPreQualificationLetterResult,
  useSendPreQualificationLetter,
} from './use-send-pre-qualification-letter';

export const SendPreQualificationLetterAction = (
  props: IActionProps<ISendPreQualificationLetterAction, SendPreQualificationLetterResult>
) => {
  const { action, onSubmit, onDone, onCancel } = props;
  const { letterReferenceId } = action;

  const [isOpen, , closeModal] = useModal(true);

  const customizations = useCustomizations();
  const prequalLetterLabel: string = customizations.get(
    'preQualification:letter.label',
    'Pre-Qualification Letter'
  );
  const title = customizations.text(
    'preQualification:send.modal.title',
    `Send ${prequalLetterLabel}`
  );

  const sendPreQualificationLetter = useSendPreQualificationLetter();

  const initialTitle = customizations.text(
    'preQualification:send.initialValues.title',
    prequalLetterLabel
  );
  const initialDescription = customizations.text(
    'preQualification:send.initialValues.description',
    `This is your official loan ${prequalLetterLabel.toLowerCase()}. You can download and print it.`
  );
  const ready = customizations.ready();

  const handleSubmit = async (values: any) => {
    try {
      onSubmit();

      const { title: taskTitle, description } = values;

      const preQualificationLetter = await sendPreQualificationLetter(letterReferenceId, {
        title: taskTitle,
        description,
      });

      closeModal(() => {
        notifications.success({
          messageCid: 'preQualification:send.message.success',
          message: `Successfully sent ${prequalLetterLabel.toLowerCase()}`,
        });
        onDone(preQualificationLetter);
      });
    } catch (e) {
      console.error(e);
      notifications.error({
        messageCid: 'preQualification:send.message.error',
        message: ` 'There was an unexpected error sending the ${prequalLetterLabel.toLowerCase()}'`,
      });
    }
  };

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  const initialValues = useMemo(
    () => ({
      title: initialTitle,
      description: initialDescription,
    }),
    [initialDescription, initialTitle]
  );

  return (
    <SendPreQualificationLetterModal
      isOpen={isOpen}
      title={title}
      onSubmit={handleSubmit}
      onRequestClose={handleCloseModal}
      initialValues={initialValues}
      loading={!ready}
    />
  );
};
