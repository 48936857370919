import { type IAction } from '@mortgagehippo/actionable';
import { Icon, Progress, T } from '@mortgagehippo/ds';
import { unreachable } from '@mortgagehippo/util';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  CREATE_CSV_PROGRESS,
  CREATE_CSV_STARTED,
  DOCUMENT_ARCHIVE_FAILURE,
  DOCUMENT_ARCHIVE_PROGRESS,
  DOCUMENT_ARCHIVE_STARTED,
  DOCUMENT_ARCHIVE_SUCCESS,
  useActionEffect,
} from '$components/actionable';

import { ExportJobStatusType } from '../../apollo/graphql';
import { type IExportJob } from './use-export-jobs';

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface IDownloadStatusProps {
  job: IExportJob;
}

export const DownloadStatus = (props: IDownloadStatusProps) => {
  const { job } = props;
  const [status, setStatus] = useState(job.status);
  const [progress, setProgress] = useState<number | null>(job.progress);

  const handleProgress = (
    action: IAction<any, { id: number; status: ExportJobStatusType; progress?: number }, any>
  ) => {
    const { payload } = action;
    const { id, status: nextStatus, progress: nextProgress } = payload;

    if (`${id}` !== `${job.id}`) {
      return;
    }

    if (nextProgress) {
      setProgress(nextProgress);
    }

    if (nextStatus) {
      setStatus(nextStatus);
    }
  };

  useActionEffect(CREATE_CSV_STARTED, handleProgress);
  useActionEffect(CREATE_CSV_PROGRESS, handleProgress);
  useActionEffect(DOCUMENT_ARCHIVE_STARTED, handleProgress);
  useActionEffect(DOCUMENT_ARCHIVE_PROGRESS, handleProgress);
  useActionEffect(DOCUMENT_ARCHIVE_SUCCESS, handleProgress);
  useActionEffect(DOCUMENT_ARCHIVE_FAILURE, handleProgress);

  useEffect(() => {
    setStatus(job.status);
  }, [job.status]);

  useEffect(() => {
    setProgress(job.progress);
  }, [job.progress]);

  switch (status) {
    case ExportJobStatusType.queued: {
      return (
        <StatusContainer>
          <T>Queued</T>
        </StatusContainer>
      );
    }
    case ExportJobStatusType.running: {
      return (
        <StatusContainer>
          <Progress percent={progress || 10} size="sm" />
        </StatusContainer>
      );
    }
    case ExportJobStatusType.complete: {
      return (
        <StatusContainer>
          <Icon name="success" color="success500" />
          &nbsp;<T>Complete</T>
        </StatusContainer>
      );
    }
    case ExportJobStatusType.failed: {
      return (
        <StatusContainer>
          <Icon name="error" color="danger500" />
          &nbsp;<T>Failed</T>
        </StatusContainer>
      );
    }
    case ExportJobStatusType.expired: {
      return (
        <StatusContainer>
          <T>Expired</T>
        </StatusContainer>
      );
    }
    default: {
      unreachable.warn(status);
      return null;
    }
  }
};
