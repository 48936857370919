import { Alert, ExpandableText, fontWeight, palette, spacing } from '@mortgagehippo/ds';
import { type ReactNode } from 'react';
import styled from 'styled-components';

import { type LosType } from '../../../../../apollo/graphql';
import { type ServiceType } from '../../types';
import { ServiceName } from '../service-name';

const StyledAlert = styled(Alert)<{ compact?: boolean }>`
  margin-bottom: ${(p) => spacing(p.compact ? 0 : 6)};
  margin-top: ${spacing(4)};
`;

const Message = styled.div`
  margin-bottom: ${spacing(4)};
`;

const ErrorDetailsTitle = styled.div`
  text-transform: uppercase;
  color: ${palette('neutral600')};
  font-weight: ${fontWeight('bold')};
  margin-bottom: 2px;
`;

const ErrorDetails = styled.div`
  background-color: ${palette('neutral50')};
  padding: ${spacing(4)};
`;

interface LosPushErrorDetailsProps {
  type: LosType | ServiceType;
  message?: ReactNode | null;
  details?: ReactNode | null;
  compact?: boolean;
}

export const LosPushErrorDetails = (props: LosPushErrorDetailsProps) => {
  const { compact, message: propMessage, details, type } = props;

  const title = (
    <>
      <ServiceName type={type} /> Push Failed
    </>
  );
  const message = propMessage || (
    <>
      An error occurred and the application could not be pushed to <ServiceName type={type} />.
    </>
  );

  const hasErrorDetails = !!details;

  return (
    <StyledAlert type="error" title={title} size="sm" compact={compact}>
      {hasErrorDetails ? (
        <>
          <Message>{message}</Message>
          <ErrorDetails>
            <ErrorDetailsTitle>LOS Error</ErrorDetailsTitle>
            <ExpandableText compact>{details}</ExpandableText>
          </ErrorDetails>
        </>
      ) : (
        message
      )}
    </StyledAlert>
  );
};
