import { ApolloError, useMutation, useQuery } from '@mortgagehippo/apollo-hooks';
import { notifications } from '@mortgagehippo/ds';
import { toFieldErrorsObject } from '@mortgagehippo/tasks';
import { toArray } from '@mortgagehippo/util';
import { isEmpty, isNil } from 'lodash-es';
import { useCallback, useEffect, useMemo } from 'react';

import { useUserCan } from '$components/permissions';

import { useSiteJobFunctions } from '../../../hooks/use-site-job-functions';
import {
  MCreateTeamTemplateMutation,
  MUpdateTeamTemplateMutation,
  QTeamTemplateQuery,
} from './queries';
import { TeamTemplateModal } from './team-templates-modal';

interface ITeamTemplateModalContainerProps {
  siteId: string;
  templateId?: string;
  onRequestClose: () => void;
  onSubmit: () => void;
  isOpen: boolean;
}

export const TeamTemplateModalContainer = (props: ITeamTemplateModalContainerProps) => {
  const { siteId, templateId, onSubmit, onRequestClose, isOpen } = props;

  const [can] = useUserCan();

  const isEditing = !isNil(templateId);

  const [jobFunctions, jobFunctionsLoading] = useSiteJobFunctions(siteId, { skip: !isOpen });
  const [data, dataLoading] = useQuery(
    QTeamTemplateQuery,
    { siteId, templateId: templateId! },
    {
      skip: !isEditing,
      fetchPolicy: 'network-only',
    }
  );
  const loading = jobFunctionsLoading || dataLoading;

  const teamTemplate = data?.site?.teamTemplate;

  const addTeamTemplate = useMutation(MCreateTeamTemplateMutation);
  const updateTeamTemplate = useMutation(MUpdateTeamTemplateMutation);

  const loadingFailed = isEditing && !loading && !teamTemplate;

  useEffect(() => {
    if (loadingFailed) {
      notifications.error({
        message: 'An error occurred while processing your request. Please try again later.',
      });
      onRequestClose();
    }
  }, [onRequestClose, loadingFailed]);

  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        let successMsg = '';
        const { name, slug, jobFunctions: rawJobFunctions = {} } = values;

        const nextJobFunctions = Object.entries(
          rawJobFunctions as Record<string, string | string[]>
        ).map(([key, agents]) => {
          const [_, id] = key.split(':');
          return { id: id!, agents: toArray(agents) };
        });

        const input = {
          name,
          slug,
          jobFunctions: nextJobFunctions,
        };

        if (!isNil(templateId)) {
          await updateTeamTemplate({ teamTemplateId: templateId, data: input });
          successMsg = 'The team template was successfully updated.';
        } else {
          await addTeamTemplate({ siteId, data: input });
          successMsg = 'The team template was successfully created.';
        }

        notifications.success({ message: successMsg });

        onSubmit();
        return undefined;
      } catch (e: unknown) {
        if (e instanceof ApolloError) {
          const fieldErrors = toFieldErrorsObject(e.graphQLErrors);

          if (!isEmpty(fieldErrors)) {
            return fieldErrors;
          }
        }

        notifications.error({
          message: 'An error occurred while processing your request. Please try again later.',
        });
        return undefined;
      }
    },
    [addTeamTemplate, onSubmit, siteId, templateId, updateTeamTemplate]
  );

  const initialValues = useMemo(() => {
    if (!teamTemplate) {
      return {};
    }

    const { name, slug } = teamTemplate;
    const initialJobFunctions = teamTemplate.jobFunctions.reduce(
      (acc, next) => {
        if (next.primary) {
          acc[`id:${next.id}`] = next.agents[0]?.id;
        } else {
          acc[`id:${next.id}`] = next.agents.map((agent) => agent.id);
        }
        return acc;
      },
      {} as Record<string, string | string[] | undefined>
    );

    return { name, slug, jobFunctions: initialJobFunctions };
  }, [teamTemplate]);

  const title = !isEditing
    ? 'Add Team Template'
    : `${can.UPDATE_TEAM_TEMPLATE ? 'Edit' : 'View'} Team Template`;
  const disabled = isEditing && !can.UPDATE_TEAM_TEMPLATE;

  return (
    <TeamTemplateModal
      isOpen={isOpen}
      title={title}
      siteId={siteId}
      jobFunctions={jobFunctions}
      onSubmit={handleSubmit}
      onRequestClose={onRequestClose}
      initialValues={initialValues}
      disabled={disabled}
      loading={loading || loadingFailed}
      disableOverlayClickClose
      showSlug={isEditing}
    />
  );
};
