import { type ISelectOption, Select, type UploadHandler, UploadInput } from '@mortgagehippo/ds';

interface IImportMismoModalFieldsProps {
  siteOptions: ISelectOption[];
  onUpload: UploadHandler;
}

const ACCEPTED_XML_MIME_TYPES = ['application/xml', 'text/xml'];

export const ImportMismoModalFields = (props: IImportMismoModalFieldsProps) => {
  const { siteOptions, onUpload } = props;

  return (
    <>
      <Select.Box name="site_id" label="Site" options={siteOptions} required searchable />
      <UploadInput.Box
        name="data.documents"
        label="Document"
        onUpload={onUpload}
        required
        maxItems={1}
        accept={ACCEPTED_XML_MIME_TYPES}
      />
    </>
  );
};
