import {
  Alert,
  Empty,
  MediaBreakpoint,
  palette,
  Select,
  spacing,
  Spinner,
  useResizeObserver,
} from '@mortgagehippo/ds';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { useUserCan } from '$components/permissions';

import { LayoutContext } from '../../layouts/layout-context';
import { useGetReportUrl } from './use-get-report-url';
import { useReportDashboards } from './use-report-dashboards';
import { useReportOwners } from './use-report-owners';
import { parseOwnerId } from './util';

const Controls = styled.div`
  display: flex;
  padding: ${spacing(3)} ${spacing(4)};
  background: ${palette('white')};

  ${MediaBreakpoint.PHONE} {
    display: block;
    padding: ${spacing(1)} ${spacing(2)};
  }

  && > * {
    flex: 1 1 auto;
    max-width: 350px;
    margin-bottom: 0;

    ${MediaBreakpoint.PHONE} {
      width: 100%;
      max-width: none;
      &:not(:last-child) {
        margin-bottom: ${spacing(2)};
      }
    }
  }
`;

const ContentContainer = styled('div')<{ heightOffset: number }>`
  height: calc(100vh - ${(p) => p.heightOffset}px);

  ${MediaBreakpoint.PHONE} {
    margin-left: -${spacing(4)};
    margin-right: -${spacing(4)};
  }
`;

const IFrame = styled.iframe`
  display: block;
  width: 100%;
  height: 100%;
  border: none;
`;

const LoadingEmptyContainer = styled.div`
  padding-top: ${spacing(7)};
  text-align: center;
`;

const ReportsAlert = styled(Alert)`
  margin: ${spacing(5)};
  ${MediaBreakpoint.PHONE} {
    margin: 0;
  }
`;

export const ReportsContent = () => {
  const [selectedOwner, setSelectedOwner] = useState<string | undefined>();
  const [selectedReport, setSelectedReport] = useState<string | undefined>();
  const [embedUrl, setEmbedUrl] = useState<string | null>(null);
  const [urlLoading, setUrlLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const contentRef = useRef<HTMLDivElement | null>(null);

  const [ownerType, ownerId] = (selectedOwner && parseOwnerId(selectedOwner)) || [];
  const skipDashboards = !ownerType || !ownerId;

  const [can, canReady] = useUserCan();
  const [ownerOptions, ownersLoading] = useReportOwners();
  const [dashboards, dashboardsLoading] = useReportDashboards(ownerType!, ownerId!, skipDashboards);

  const getReportUrl = useGetReportUrl();

  const reportOptions = useMemo(
    () =>
      (dashboards || []).map((dashboard) => ({
        label: dashboard,
        value: dashboard,
      })),
    [dashboards]
  );

  const handleOwnerChange = useCallback((nextOwner: string) => {
    setSelectedOwner(nextOwner);
    setSelectedReport(undefined);
    setEmbedUrl(null);
    setShowError(false);
  }, []);

  const handleReportChange = useCallback(
    async (nextReport: string) => {
      if (!ownerType || !ownerId) return;

      setSelectedReport(nextReport);
      setUrlLoading(true);
      setShowError(false);
      setEmbedUrl(null);

      try {
        const nextEmbedUrl = await getReportUrl(ownerType, ownerId, nextReport);

        if (nextEmbedUrl) {
          setEmbedUrl(nextEmbedUrl);
        } else {
          setShowError(true);
        }
      } catch (e) {
        setShowError(true);
      }

      setUrlLoading(false);

      if (contentRef.current) {
        contentRef.current.focus();
      }
    },
    [getReportUrl, ownerId, ownerType]
  );

  useEffect(() => {
    // automatically select the first owner
    if (!selectedOwner && ownerOptions.length) {
      handleOwnerChange(ownerOptions[0]!.value);
    }
  }, [handleOwnerChange, ownerOptions, selectedOwner]);

  const controlsRef = useRef<HTMLDivElement | null>(null);
  const { height: controlsHeight } = useResizeObserver(controlsRef, { height: true });
  const { layoutContext } = useContext(LayoutContext);
  const headerHeight = layoutContext?.headerHeight || 0;

  const heightOffset = useMemo(
    () => (controlsHeight || 0) + headerHeight,
    [headerHeight, controlsHeight]
  );

  const loading = ownersLoading || dashboardsLoading || !canReady || urlLoading;
  const showEmpty = !loading && !reportOptions.length;
  const showNoSelection = !loading && !showEmpty && !selectedReport;

  if (canReady && !can.VIEW_REPORTING_DASHBOARD_PAGE) {
    return <ReportsAlert type="error">You do not have permission to view this page</ReportsAlert>;
  }

  return (
    <>
      {/* Div without margins and padding to calculate resize observer height correctly */}
      <div ref={controlsRef}>
        <Controls>
          <Select.Input
            name="reportsOwner"
            options={ownerOptions}
            onChange={handleOwnerChange}
            placeholder="Select site"
            size="sm"
            value={selectedOwner}
            disabled={loading}
            loading={loading}
            searchable
          />
          <Select.Input
            name="reportsDashboard"
            options={reportOptions}
            onChange={handleReportChange}
            placeholder="Select report"
            size="sm"
            value={selectedReport}
            disabled={loading || showEmpty}
            loading={loading}
            searchable
          />
        </Controls>
      </div>

      {/* 
        The ReportContainer must contain either only the IFrame or an error. 
        This is in order to be able to control the height of the IFrame dynamically
      */}
      <ContentContainer ref={contentRef} heightOffset={heightOffset} tabIndex={-1}>
        {(embedUrl && <IFrame aria-live="polite" title="Dashboard" src={embedUrl} />) || (
          <>
            {loading ? (
              <LoadingEmptyContainer>
                <Spinner size="xxl" color="neutral400" />
              </LoadingEmptyContainer>
            ) : null}
            {showError ? (
              <ReportsAlert type="error">
                An error has occurred. Please refresh the page or try again later.
              </ReportsAlert>
            ) : null}
            {showEmpty ? <ReportsAlert type="error">No reports found</ReportsAlert> : null}
            {showNoSelection ? (
              <LoadingEmptyContainer>
                <Empty>Please select a report</Empty>
              </LoadingEmptyContainer>
            ) : null}
          </>
        )}
      </ContentContainer>
    </>
  );
};
