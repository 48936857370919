import { Button, Empty, fontSize, lineHeight, MediaBreakpoint, spacing } from '@mortgagehippo/ds';
import { useCallback } from 'react';
import styled from 'styled-components';

import { ActionType, useDispatchAction } from '$components/actions';

import { SmartviewModelType } from '../../apollo/graphql';
import { useSmartviews } from '../../hooks/use-smartviews';
import { Content, Layout } from '../../layouts/main';

const PAGE_TITLE = 'Smartviews';

const EmptyContainer = styled.div`
  max-width: 650px;
  margin: 0 auto;

  ${MediaBreakpoint.PHONE} {
    max-width: none;
  }
`;

const EmptyParagraph = styled.p`
  margin: 0;
  font-size: ${fontSize('xs')};
  line-height: ${lineHeight('xs')};
`;

const Actions = styled.div`
  margin-top: ${spacing(4)};
`;

export const SmartviewsPageDefaultContent = () => {
  const [smartviews, loading] = useSmartviews(SmartviewModelType.ApplicationFile, {
    fetchPolicy: 'cache-first',
  });

  const dispatch = useDispatchAction();

  const handleAddSmartview = useCallback(() => {
    dispatch({
      type: ActionType.CREATE_SMARTVIEW,
      model: SmartviewModelType.ApplicationFile,
    });
  }, [dispatch]);

  if (loading) {
    return (
      <Layout pageTitle={PAGE_TITLE}>
        <Content>&nbsp;</Content>
      </Layout>
    );
  }

  const hasSmartviews = smartviews.length > 0;

  return (
    <Layout pageTitle={PAGE_TITLE}>
      <Content>
        <EmptyContainer>
          <Empty>
            {!hasSmartviews && (
              <EmptyParagraph>
                <strong>You have not saved any smartviews yet.</strong>
              </EmptyParagraph>
            )}
            <EmptyParagraph>
              Smartviews are saved filters on the pipeline to quickly see a subset of data that you
              frequently use. You can also choose which columns and in what order are visble to
              reduce clutter. Your saved smartviews will be listed in the main navigation, always a
              click away.
            </EmptyParagraph>
            <Actions>
              <Button icon="plus" importance="primary" onClick={handleAddSmartview}>
                Add smartview
              </Button>
            </Actions>
          </Empty>
        </EmptyContainer>
      </Content>
    </Layout>
  );
};
