import {
  PreQualification,
  PreQualificationFormViewer,
  usePreQualificationLetters,
} from '@mortgagehippo/tasks';
import { useCallback } from 'react';

import {
  ActionStatus,
  ActionType,
  type ISendPreQualificationLetterAction,
  useActionEffect,
  useDispatchAction,
} from '$components/actions';

interface IApplicationPreQualificationProps {
  applicationFileId: string;
  canBypassLimits: boolean;
  canConfigureBorrowerLetters: boolean;
  canConfigurePrequalificationLetterCustomMessage: boolean;
  canConfigurePrequalificationLetterMortgageInsurance: boolean;
  canCreateTasks: boolean;
  canDownloadDocuments: boolean;
  canManagePrequalificationLetterContingency: boolean;
  canViewPricingTab: boolean;
}

export const ApplicationPreQualification = (props: IApplicationPreQualificationProps) => {
  const {
    applicationFileId,
    canBypassLimits,
    canConfigureBorrowerLetters,
    canConfigurePrequalificationLetterCustomMessage,
    canConfigurePrequalificationLetterMortgageInsurance,
    canCreateTasks,
    canDownloadDocuments,
    canManagePrequalificationLetterContingency,
    canViewPricingTab,
  } = props;

  const dispatch = useDispatchAction();

  const [, , refetchLetters] = usePreQualificationLetters(applicationFileId, true);

  const handleSendLetter = useCallback(
    (_applicationFileId: string, letterId: string) => {
      if (canCreateTasks) {
        dispatch({
          type: ActionType.SEND_PREQUALIFICATION_LETTER,
          applicationFileId: `${applicationFileId}`,
          letterReferenceId: letterId,
        });
      }
    },
    [applicationFileId, canCreateTasks, dispatch]
  );

  useActionEffect<ISendPreQualificationLetterAction>(
    async (action) => {
      const { applicationFileId: actionApplicationFileId } = action;

      if (actionApplicationFileId !== applicationFileId) {
        // this action belongs to another application file ID
        return;
      }

      await refetchLetters();
    },
    ActionType.SEND_PREQUALIFICATION_LETTER,
    ActionStatus.DONE
  );

  const pricingUrl = `#/applications/${applicationFileId}/pricing`;

  return (
    <PreQualification
      applicationFileId={applicationFileId}
      canCreateTasks={canCreateTasks}
      canViewPricingTab={canViewPricingTab}
      canConfigureBorrowerLetters={canConfigureBorrowerLetters}
      showContingentCheckbox={canManagePrequalificationLetterContingency}
      showCustomMessage={canConfigurePrequalificationLetterCustomMessage}
      enableMortgageInsuranceConfiguration={canConfigurePrequalificationLetterMortgageInsurance}
      viewer={
        canBypassLimits
          ? PreQualificationFormViewer.LENDER
          : PreQualificationFormViewer.LENDER_WITH_LIMITS
      }
      onSendLetter={handleSendLetter}
      preventDownloads={!canDownloadDocuments}
      pricingUrl={pricingUrl}
    />
  );
};
