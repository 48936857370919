export enum ActivityFeedFormatters {
  currency = 'currency',
  italicized = 'italicized',
  tag = 'tag',
}

export interface IActivityFeedEnrichSentinel {
  type: string;
  key: string;
  resource?: string;
  id?: string | number;
}

export interface IActivityFeedFormatterSentinel {
  type: 'formatted_value';
  formatters: ActivityFeedFormatters[];
  value: any;
}

export type IActivityFeedSentinel = IActivityFeedEnrichSentinel | IActivityFeedFormatterSentinel;

export type IActivityFeedFormattedValue = Array<string | IActivityFeedSentinel>;

export interface IActivityFeedEvent {
  id: string;
  actor: IActivityFeedFormattedValue | null;
  createdAt: string;
  event: IActivityFeedFormattedValue;
}

export type IActivityFeedDictionary = Record<string, string>;

export interface IActivityFeed {
  dictionary: IActivityFeedDictionary;
  items: IActivityFeedEvent[];
  nextCursor: string | null;
}
