import { type FieldSize } from '@mortgagehippo/ds';
import { QuerySelect } from '@mortgagehippo/query-components';
import { type ReactNode } from 'react';

import { usePartnerId } from '../../hooks/use-partner-id';
import { QAgentSelect, QAgentsSelect } from './queries';

interface IAgentsSelectProps {
  siteId?: string;
  jobFunctionId?: string;
  label?: ReactNode;
  name: string;
  required?: boolean;
  disabled?: boolean;
  size?: FieldSize;
  multiple?: boolean;
  compact?: boolean;
}

export const AgentSelect = (props: IAgentsSelectProps) => {
  const { siteId, jobFunctionId } = props;
  const [partnerId] = usePartnerId();
  const variables = { partnerId: partnerId!, siteId, jobFunctionId };

  if (!partnerId) return null;

  return (
    <QuerySelect.Box
      label={null}
      {...props}
      initialQuery={QAgentSelect}
      initialVariables={{}}
      initialLabel={(data) => data!.agent!.name}
      optionsQuery={QAgentsSelect}
      optionsVariables={variables}
      options={(data) => data.agents.items.map((a) => ({ value: a.id, label: a.name }))}
      placeholder="Type a full name to search"
      moMatchesLabel="No results found. Type a full name to get a match."
    />
  );
};
