import { AuthState, useAuth } from '@mortgagehippo/auth';
import { borderRadiuses, MediaBreakpoint, palette, shadow, spacing } from '@mortgagehippo/ds';
import { ErrorBoundary, NotFoundError } from '@mortgagehippo/util';
import { type ComponentType, createElement, type ReactNode, useCallback, useMemo } from 'react';
import ReactModal from 'react-modal';
import { type RouteComponentProps, withRouter } from 'react-router';
import { createGlobalStyle } from 'styled-components';

import { OverlayNotFoundPage } from '$components/overlay/not-found-page';

import { OverlayContext } from './context';
import { OverlayRoute } from './route';

const GlobalStyles = createGlobalStyle`

  .PageOverlay--body-open {
    overflow: hidden;

    ${MediaBreakpoint.PHONE}  {
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  .PageOverlay--body-open #root {
    ${MediaBreakpoint.PHONE} {
      overflow: hidden;
      height: 100vh;
    }
  }

  .PageOverlay-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background-color must not have transparency
    // because it creates scrolling performance issues
    // in some browsers. Most likely culprit is underlaying antd table
    background-color: ${palette('primary50')};

    z-index: 99;  // changing from 100 because otherwise modals may appear behind overlay (inactivity countdown)
    transform: translateZ(0);

    ${MediaBreakpoint.PHONE}  {
      position: absolute;
      bottom: auto;
      min-height: 100vh;
    }
  }

  .PageOverlay-content {
    position: absolute;
    top: ${spacing(2)};
    left: ${spacing(2)};
    right: ${spacing(2)};
    bottom: 0;
    background: ${palette('neutral50')};

    // this must be hidden because the content must take care of its own scrolling.
    // DO NOT add scrolling here.
    overflow: hidden;

    border-radius: ${borderRadiuses(2, 2, 0, 0)};
    outline: none;
    box-shadow: ${shadow(4, 'intense')};

    ${MediaBreakpoint.PHONE} {
      position: relative;
      left: 0;
      top: ${spacing(3)};
      border-radius: ${borderRadiuses(3, 3, 0, 0)};;
      right: 0;
      bottom: auto;
      min-height: 100vh;
    }
  }
`;

export interface IOverlayProps {
  path: string;
  component: ComponentType<RouteComponentProps<any>>;
  children?: ReactNode;
}

ReactModal.setAppElement('#root');

const OverlayWithRouter = (props: IOverlayProps & RouteComponentProps<any>) => {
  const { children, component, path, location, history } = props;
  const [, authState] = useAuth();
  const authenticated = authState === AuthState.AUTHENTICATED_ACCOUNT;

  const handleRequestClose = useCallback(() => {
    history.push(location.pathname);
  }, [history, location]);

  const value = useMemo(
    () => ({
      onRequestClose: handleRequestClose,
    }),
    [handleRequestClose]
  );

  if (!authenticated) {
    return null;
  }

  return (
    <>
      <GlobalStyles />
      <OverlayRoute
        path={path}
        render={(routeChildrenProps) => {
          const { match } = routeChildrenProps;
          if (!match) {
            return null;
          }

          const el = createElement(component, routeChildrenProps, children);

          return (
            <ReactModal
              onRequestClose={handleRequestClose}
              overlayClassName="PageOverlay-overlay"
              className="PageOverlay-content"
              bodyOpenClassName="PageOverlay--body-open"
              isOpen
              shouldCloseOnOverlayClick={false}
              shouldCloseOnEsc={false}
            >
              <OverlayContext.Provider value={value}>
                <ErrorBoundary errors={[NotFoundError]} errorComponent={OverlayNotFoundPage}>
                  {el}
                </ErrorBoundary>
              </OverlayContext.Provider>
            </ReactModal>
          );
        }}
      />
    </>
  );
};

export const Overlay = withRouter(OverlayWithRouter);
