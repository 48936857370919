import { ModalDialog, notifications, useCustomizations, useModal } from '@mortgagehippo/ds';
import { useCallback } from 'react';

import { useApplicationFile } from '../../../pages/application/use-application-file-data';
import { useDeleteApplicant } from '../../../pages/application/use-delete-applicant';
import { type IActionProps, type IDeleteApplicantAction } from '../types';

export const DeleteApplicantAction = (props: IActionProps<IDeleteApplicantAction>) => {
  const { action, onDone, onCancel } = props;
  const { applicationFileId, applicantId } = action;
  const [isOpen, , closeModal] = useModal(true);

  const customizations = useCustomizations();
  const title = customizations.text('delete-applicant-action:confirm-title', 'Are you sure?');
  const explanation = customizations.text(
    'delete-applicant-action:confirm-explanation',
    'This action is irreversible'
  );

  const deleteApplicant = useDeleteApplicant();
  const [, refresh] = useApplicationFile(action.applicationFileId, {
    skip: true,
  });

  const handleOk = async () => {
    try {
      await deleteApplicant(applicationFileId, applicantId);
      closeModal(async () => {
        await refresh();
        notifications.success({
          messageCid: 'delete-applicant-action:notification-success',
          message: 'Successfully removed borrower from application',
        });
        onDone();
      });
    } catch (e) {
      notifications.error({
        messageCid: 'delete-applicant-action:notification-error',
        message: 'There was an unexpected error removing borrower from application',
      });
    }
  };

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  // TODO: use hook useModalConfirm
  return (
    <ModalDialog
      type="dialog"
      title={title}
      onOk={handleOk}
      onRequestClose={handleCloseModal}
      isOpen={isOpen}
    >
      {explanation}
    </ModalDialog>
  );
};
