import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../apollo';
import { type UpdateLendingQbPushInput } from '../../../apollo/graphql';

const MUpdateLendingQBPush = graphql(`
  mutation UpdateLendingQBPush($applicationFileId: ID!, $data: UpdateLendingQBPushInput!) {
    updateLendingQbPush(applicationFileId: $applicationFileId, data: $data)
  }
`);

export const useUpdateLendingQBPush = () => {
  const updateLendingQBPush = useMutation(MUpdateLendingQBPush, undefined);

  return useCallback(
    async (applicationFileId: string, data: UpdateLendingQbPushInput) => {
      const response = await updateLendingQBPush({ applicationFileId, data });
      return response.data!.updateLendingQbPush;
    },
    [updateLendingQBPush]
  );
};
