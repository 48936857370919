import {
  Alert,
  Button,
  Email,
  type FormSubmitHandler,
  Input,
  ModalForm,
  Phone,
} from '@mortgagehippo/ds';
import { isPresent } from '@mortgagehippo/util';

export interface IUpdateApplicantBorrowerInformationModalFormValues {
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  cellPhoneNumber?: string | null;
  homePhoneNumber?: string | null;
  workPhoneNumber?: string | null;
}

interface IUpdateApplicantBorrowerInformationModalProps {
  isOpen: boolean;
  title: string;
  onSubmit: FormSubmitHandler<IUpdateApplicantBorrowerInformationModalFormValues>;
  onRequestClose: () => void;
  loading: boolean;
  width?: number;
  isFrozen?: boolean;
  onStartApplication?: () => void;
  initialValues?: IUpdateApplicantBorrowerInformationModalFormValues;
}

export const UpdateApplicantBorrowerInformationModal = (
  props: IUpdateApplicantBorrowerInformationModalProps
) => {
  const {
    title,
    onRequestClose,
    onSubmit,
    isOpen,
    width,
    initialValues,
    isFrozen,
    loading,
    onStartApplication,
  } = props;

  const {
    email: initialEmail,
    firstName: initialFirstName,
    lastName: initialLastName,
    cellPhoneNumber: initialMobileNumber,
  } = initialValues || {};

  // don't allow nulling out field if previously present
  const emailRequired = isPresent(initialEmail);
  const firstNameRequired = isPresent(initialFirstName);
  const lastNameRequired = isPresent(initialLastName);
  const mobilePhoneRequired = isPresent(initialMobileNumber);

  return (
    <ModalForm<IUpdateApplicantBorrowerInformationModalFormValues>
      loading={loading}
      title={title}
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      width={width}
      onSubmit={onSubmit}
      initialValues={initialValues}
      autoComplete={false}
      okButtonLabel="Save"
    >
      {!!isFrozen && (
        <Alert type="warning" size="sm">
          <p>
            You cannot update some borrower information because the application file is frozen.
            {!!onStartApplication &&
              ' You could start a new application with the option to import the borrower information, and then update it in the new application.'}
          </p>
          {!!onStartApplication && (
            <Button onClick={onStartApplication} size="xxs" importance="secondary">
              Start a new application
            </Button>
          )}
        </Alert>
      )}
      <Email.Box
        label="Email Address"
        name="email"
        info="Change the email only with the borrower’s consent because they use it to sign in."
        required={emailRequired}
      />
      <Input.Box
        label="First Name"
        name="firstName"
        required={firstNameRequired}
        disabled={isFrozen}
      />
      <Input.Box
        label="Last Name"
        name="lastName"
        required={lastNameRequired}
        disabled={isFrozen}
      />
      <Phone.Box
        label="Cell Phone Number"
        name="cellPhoneNumber"
        required={mobilePhoneRequired}
        disabled={isFrozen}
      />
      <Phone.Box label="Home Phone Number" name="homePhoneNumber" disabled={isFrozen} />
      <Phone.Box label="Work Phone Number" name="workPhoneNumber" disabled={isFrozen} />
    </ModalForm>
  );
};
