import { T } from '@mortgagehippo/ds';
import { type IQueryTableDataActions } from '@mortgagehippo/query-components';
import { useMountedRef } from '@mortgagehippo/util';
import { type MouseEvent, useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import { ActionStatus, ActionType, useActionEffect, useDispatchAction } from '$components/actions';
import { useUserCan } from '$components/permissions';

import { SmartviewModelType } from '../../../apollo/graphql';
import { useLocation } from '../../../hooks/use-location';
import { LayoutContext } from '../../../layouts/layout-context';
import { history } from '../../../services/history';
import { SmartView } from '../smart-view';
import { type ISmartviewRecord } from '../types';

enum GroupActionType {
  RESTORE = 'restore',
}

enum BulkActionType {
  RESTORE = 'restore',
}

export interface IArchivedApplicationFilesSmartviewProps {
  id?: string;
  caption: string;
  ariaLabel: string;
  localStorageKey?: string;
}

export const ArchivedApplicationFilesSmartview = (
  props: IArchivedApplicationFilesSmartviewProps
) => {
  const { id, caption, ariaLabel, localStorageKey } = props;

  const tableRef = useRef<any>();
  const mounted = useMountedRef();
  const dispatch = useDispatchAction();

  const { layoutContext } = useContext(LayoutContext);
  const { pathname } = useLocation();

  const [can] = useUserCan();

  useActionEffect(
    (action) => {
      if (
        action.type === ActionType.ARCHIVE_APPLICATION ||
        action.type === ActionType.RESTORE_APPLICATION
      ) {
        setTimeout(() => {
          if (mounted.current && tableRef.current) {
            tableRef.current.refetch();
          }
        }, 1500);
      }
    },
    undefined,
    ActionStatus.DONE
  );

  const handleRowClick = useCallback(
    (record: { id: string | number }, _index: number, clickDetails?: MouseEvent) => {
      if (clickDetails?.altKey || clickDetails?.metaKey) {
        window.open(`#/applications/${record.id}`);
      } else {
        history.push(`#/applications/${record.id}`);
      }
    },
    []
  );

  const handleRowMiddleClick = useCallback((key: string) => {
    window.open(`#/applications/${key}`);
  }, []);

  useEffect(
    () =>
      // refresh when application modal is closed
      history.listen((location) => {
        if (location.pathname === '/archive' && !location.hash && tableRef.current) {
          tableRef.current.refetch();
        }
      }),
    []
  );

  const handleOpenInNewTabAction = useCallback(
    async (_actionKey: string, record: ISmartviewRecord) => {
      const newWin = window.open(`${pathname}#/applications/${record.id}`);
      if (newWin) {
        newWin.focus();
      }
    },
    [pathname]
  );

  const handleGroupAction = useCallback(
    async (actionKey: string, record: ISmartviewRecord) => {
      switch (actionKey as GroupActionType) {
        case GroupActionType.RESTORE:
          dispatch({
            type: ActionType.RESTORE_APPLICATION,
            applicationFileId: record.id,
          });
          break;
        default: {
          throw new Error(`Unknown action ${actionKey}`);
        }
      }
    },
    [dispatch]
  );

  const handleBulkAction = useCallback(
    (actionKey: string, records?: ISmartviewRecord[]) => {
      const applicationFileId = (records || []).map((record) => record.id);

      if (!applicationFileId.length) {
        return;
      }

      switch (actionKey as BulkActionType) {
        case BulkActionType.RESTORE:
          dispatch({
            type: ActionType.RESTORE_APPLICATION,
            applicationFileId,
          });
          break;
        default: {
          throw new Error(`Unknown action ${actionKey}`);
        }
      }
    },
    [dispatch]
  );

  const [rowActions, selectionActions, rowActionsColWidth] = useMemo(() => {
    const nextGroupActions: IQueryTableDataActions = [];

    if (can.RESTORE_APPLICATION_FILE) {
      nextGroupActions.push({
        key: GroupActionType.RESTORE,
        label: <T cid="pageArchive:pipeline.actions.unarchive.label">Unarchive</T>,
        iconProps: {
          name: 'undo',
        },
      });
    }

    const nextRowActions: IQueryTableDataActions = [];

    nextRowActions.push({
      key: 'open-new-tab',
      label: 'Open in new tab',
      buttonProps: {
        icon: 'open-in-new',
        tooltip: 'Open in new tab',
        tooltipProps: {
          showDelayMs: 1000,
        },
      },
      onAction: handleOpenInNewTabAction,
    });

    if (nextGroupActions.length) {
      nextRowActions.push({
        key: 'options',
        label: 'Options',
        buttonProps: {
          icon: 'menu-dots',
        },
        actions: nextGroupActions,
        onGroupAction: handleGroupAction,
      });
    }

    const nextSelectionActions: IQueryTableDataActions = [];

    if (can.RESTORE_APPLICATION_FILE) {
      nextSelectionActions.push({
        key: BulkActionType.RESTORE,
        label: <T cid="pageArchive:pipeline.actions.unarchive.label">Unarchive</T>,
        buttonProps: {
          icon: 'delete',
        },
        onBulkAction: handleBulkAction,
      });
    }

    // the cell padding + 24 button width+ 2px button left margin
    const nextRowActionsColWidth = `${16 + nextRowActions.length * 26}px`;

    return [nextRowActions, nextSelectionActions, nextRowActionsColWidth];
  }, [can.RESTORE_APPLICATION_FILE, handleBulkAction, handleGroupAction, handleOpenInNewTabAction]);

  return (
    <SmartView
      ref={tableRef}
      model={SmartviewModelType.ArchivedApplicationFile}
      id={id}
      ariaLabel={ariaLabel}
      caption={caption}
      rowActions={rowActions}
      rowActionsColWidth={rowActionsColWidth}
      rowActionsFixed
      selectionActions={selectionActions}
      selectionFixed
      onRowClick={handleRowClick}
      onRowMiddleClick={handleRowMiddleClick}
      localStorageKey={localStorageKey}
      paginate
      summarize
      perPage={10}
      tableFullHeight
      tableFullHeightOffset={layoutContext.headerHeight}
      resizableColumns
    />
  );
};
