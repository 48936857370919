import { Alert, Button, T } from '@mortgagehippo/ds';

import { Content, Layout } from '../../layouts/login';

export const ForcedLogoutPage = () => (
  <Layout title="Session Ended">
    <Content>
      <Alert type="warning">
        Hi! We have signed you out because you signed in from another tab. You can either close this
        tab or sign in again.
      </Alert>
      <Button href="/login" importance="primary" icon="right-arrow" iconLocation="right" block>
        <T cid="logged-out:button.label">Sign in</T>
      </Button>
    </Content>
  </Layout>
);
