import { Button, spacing, T, Text, Title, useCustomizations } from '@mortgagehippo/ds';
import styled from 'styled-components';

import { CreditPullStatusType } from '../../../../../apollo/graphql';
import { ServiceType } from '../../types';
import { ServiceName } from '../service-name';
import { CreditCheckApplicant } from './credit-check-applicant';
import { type ICreditCheckPageApplicant } from './types';
import { isIndividualCreditPull } from './util';

const JointButton = styled.div`
  margin-bottom: ${spacing(6)};
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  > li {
    margin-top: ${spacing(9)};

    &:first-child {
      margin: 0;
    }
  }
`;

interface ICreditCheckPageProps {
  applicants: ICreditCheckPageApplicant[];
  onOrderCreditCheck?: (applicantId: string) => void;
  onOrderJointCreditCheck?: () => void;
  onChangeAnswers?: (applicantId: string, answers: any) => Promise<any>;
  onDownloadReport?: (creditPullId: string) => Promise<string | null>;
  onViewReport: (creditPullId: string) => Promise<string | null>;
}

export const CreditCheckPage = (props: ICreditCheckPageProps) => {
  const {
    applicants,
    onOrderCreditCheck,
    onOrderJointCreditCheck,
    onChangeAnswers,
    onDownloadReport,
    onViewReport,
  } = props;

  const customizations = useCustomizations();
  const showLiabilities = customizations.bool(
    'pageApplication:tabServices.creditCheck.liabilities.show',
    false
  );

  const hasMultipleApplicants = applicants.length > 1;
  const showName = hasMultipleApplicants;

  const startButtonLabel = hasMultipleApplicants ? (
    <T cid="pageApplication:tabServices.creditCheck.buttonOrderReportIndividual.label">
      Order individual report
    </T>
  ) : (
    <T cid="pageApplication:tabServices.creditCheck.buttonOrderReport.label">Order credit report</T>
  );

  const showJointButton =
    hasMultipleApplicants &&
    !!onOrderJointCreditCheck &&
    (applicants.some((a) => !a.creditPull) ||
      applicants.every((a) => !!a.creditPull && isIndividualCreditPull(a.creditPull)));

  const jointButtonDisabled = applicants.some(
    (a) => a.creditPull && a.creditPull.statusCode === CreditPullStatusType.InProgress
  );

  const handleOrderCreditCheck = (applicantId: string) => () => {
    if (!onOrderCreditCheck) {
      return;
    }

    onOrderCreditCheck(applicantId);
  };

  const handleChangeAnswers = (applicantId: string) => async (answers: any) => {
    if (!onChangeAnswers) {
      return undefined;
    }

    return onChangeAnswers(applicantId, answers);
  };

  return (
    <>
      <Title level={1}>
        <ServiceName type={ServiceType.CreditPull} />
      </Title>
      <Text as="p">The credit check will use data from the 1003.</Text>
      {showJointButton ? (
        <JointButton>
          <Button
            size="sm"
            onClick={onOrderJointCreditCheck}
            disabled={jointButtonDisabled}
            icon="rings"
          >
            <T cid="pageApplication:tabServices.creditCheck.buttonOrderReportJoint.label">
              Order joint report
            </T>
          </Button>
        </JointButton>
      ) : null}
      <List>
        {applicants.map((applicant) => {
          const onOrderCreditCheckFn = onOrderCreditCheck
            ? handleOrderCreditCheck(applicant.id)
            : undefined;
          const onChangeAnswersFn = onChangeAnswers ? handleChangeAnswers(applicant.id) : undefined;

          return (
            <li key={applicant.id}>
              <CreditCheckApplicant
                applicant={applicant}
                showName={showName}
                startButtonLabel={startButtonLabel}
                onOrderCreditCheck={onOrderCreditCheckFn}
                onOrderJointCreditCheck={onOrderJointCreditCheck}
                onChangeAnswers={onChangeAnswersFn}
                onDownloadReport={onDownloadReport}
                onViewReport={onViewReport}
                showLiabilities={showLiabilities}
              />
            </li>
          );
        })}
      </List>
    </>
  );
};
