import { Title } from '@mortgagehippo/ds';
import { useCallback } from 'react';

import { type ISite } from '../../../hooks/use-site';
import { type IApplicationApplicant } from '../use-application-file-data';
import { ApplicationInvitesApplicantContent } from './application-invites-applicant-content';

interface IApplicationInvitesApplicantProps {
  site: ISite;
  applicant: IApplicationApplicant;
  showTitle?: boolean;
  onInvite: (applicantId: string) => void;
  onUpdateEmail?: (applicantId: string) => void;
}

export const ApplicationInvitesApplicant = (props: IApplicationInvitesApplicantProps) => {
  const { site, applicant, showTitle, onInvite, onUpdateEmail } = props;
  const { id: applicantId, name: fullName } = applicant;

  const handleInvite = useCallback(() => {
    onInvite(applicantId);
  }, [applicantId, onInvite]);

  const handleUpdateEmail = useCallback(() => {
    if (!onUpdateEmail) {
      return;
    }

    onUpdateEmail(applicantId);
  }, [applicantId, onUpdateEmail]);

  return (
    <>
      {showTitle ? <Title level={2}>{fullName}</Title> : null}
      <ApplicationInvitesApplicantContent
        site={site}
        applicant={applicant}
        onInvite={handleInvite}
        onUpdateEmail={onUpdateEmail ? handleUpdateEmail : undefined}
      />
    </>
  );
};
