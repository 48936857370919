import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../apollo';

const MRefreshUnderwritingConditions = graphql(`
  mutation RefreshUnderwritingConditions($applicationFileId: ID!) {
    refreshUnderwritingConditions(applicationFileId: $applicationFileId)
  }
`);

export const useRefreshUnderwritingConditions = () => {
  const refreshUnderwritingConditions = useMutation(MRefreshUnderwritingConditions, undefined);

  return useCallback(
    async (applicationFileId: string) => {
      const response = await refreshUnderwritingConditions({ applicationFileId });

      return response.data!.refreshUnderwritingConditions;
    },
    [refreshUnderwritingConditions]
  );
};
