import { type RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import { Content, Layout, LAYOUT_DIMENSIONS } from '../../../layouts/application';
import { ApplicationMilestones } from './application-milestones';

const ApplicationMilestonesContentWrapper = styled('div')`
  max-width: ${LAYOUT_DIMENSIONS.extendedMaxContentWidth}px;
`;

export interface IApplicationMilestonesParams {
  applicationFileId: string;
}

export const ApplicationMilestonesRoute = (
  props: RouteComponentProps<IApplicationMilestonesParams>
) => {
  const { match } = props;
  const { params } = match;
  const { applicationFileId } = params;

  return (
    <Layout applicationFileId={applicationFileId}>
      <Content>
        <ApplicationMilestonesContentWrapper>
          <ApplicationMilestones applicationFileId={applicationFileId} />
        </ApplicationMilestonesContentWrapper>
      </Content>
    </Layout>
  );
};
