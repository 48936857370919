import { fontSize, fontWeight, Format, palette, spacing, T, Text } from '@mortgagehippo/ds';
import styled from 'styled-components';

import { type IApplicationApplicant } from '../../pages/application/use-application-file-data';

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const Item = styled.li`
  display: block;
  margin-top: ${spacing(3)};
  border-top: 1px solid ${palette('neutral100')};

  &:first-child {
    margin-top: 0;
    border-top: none;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  font-weight: ${fontWeight('light')};
  font-size: ${fontSize('xs')};

  > :first-child {
    margin-left: 0;
  }

  > * {
    margin-left: ${spacing(3)};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

interface IApplicationHeaderApplicantsProps {
  applicants: IApplicationApplicant[];
}

export const ApplicationHeaderApplicantsExtended = (props: IApplicationHeaderApplicantsProps) => {
  const { applicants } = props;

  return (
    <List>
      {applicants.map((applicant) => {
        const { id, name, phoneNumber, email, sharingEmail } = applicant;

        const phoneNumberEl = phoneNumber ? (
          <Format.Phone value={phoneNumber} />
        ) : (
          <T>Phone number unavailable</T>
        );

        return (
          <Item key={id}>
            <Text size="md" ellipsis>
              {name}
            </Text>
            <ContactInfo>
              <div>{phoneNumberEl}</div>
              <div>{(!sharingEmail && email) || <T>Email unavailable</T>}</div>
            </ContactInfo>
          </Item>
        );
      })}
    </List>
  );
};
