import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../apollo';
import { type SiteQuery } from '../apollo/graphql';
import { usePartnerDomain } from './use-partner-domain';

const QSite = graphql(`
  query Site($partnerId: ID!, $siteId: ID!) {
    partner(id: $partnerId) {
      id
      site(id: $siteId) {
        id
        name
        domain
      }
    }
  }
`);

export type ISite = NonNullable<NonNullable<SiteQuery['partner']>['site']>;

export const useSite = (siteId: string, options: IUseApolloQueryOptions = {}) => {
  const partnerId = usePartnerDomain();

  const [data, loading] = useQuery(
    QSite,
    {
      siteId,
      partnerId,
    },
    options
  );

  const result = data ? data.partner?.site : undefined;

  return [result, loading] as const;
};
