import { Button, fontSize, fontWeight, MediaBreakpoint, spacing, T } from '@mortgagehippo/ds';
import { drop, dropRight } from 'lodash-es';
import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';

import { LenderMilestonesList } from '../../../../components/lender-milestones/lender-milestones-list';
import { type ILenderMilestone } from '../../use-lender-milestones';
import { ApplicationLenderMilestonesActiveMilestone } from './application-lender-milestones-active-milestone';
import { ApplicationLenderMilestonesRefreshLosStatus } from './application-lender-milestones-refresh-los-status';

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${MediaBreakpoint.PHONE} {
    flex-flow: column-reverse;
  }
`;

const Wrapper = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-wrap: wrap;

  ${MediaBreakpoint.PHONE} {
    margin-top: ${spacing(5)};
    justify-content: flex-end;
  }
`;

const EditingSubtitle = styled.div`
  font-size: ${fontSize('sm')};
  font-weight: ${fontWeight('semibold')};
  margin-top: ${spacing(3)};
`;

interface IApplicationLenderMilestonesListContainerProps {
  milestones: ILenderMilestone[];
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  onSave: (completedMilestoneIds: string[], activeMilestoneId?: string) => Promise<boolean>;
  canEditMilestones?: boolean;
  loading?: boolean;
  lastUpdated?: string | null;
  onLosLoanStatusRefresh?: () => void;
  sequentialMilestones?: boolean;
}

export const ApplicationLenderMilestonesContainer = (
  props: IApplicationLenderMilestonesListContainerProps
) => {
  const {
    canEditMilestones,
    milestones,
    onSave,
    isEditing,
    setIsEditing,
    loading,
    lastUpdated,
    onLosLoanStatusRefresh,
    sequentialMilestones,
  } = props;

  const [selectedMilestoneIds, setSelectedMilestoneIds] = useState<string[]>([]);
  const [selectedActiveMilestoneId, setSelectedActiveMilestoneId] = useState<string | undefined>();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleEditMilestonesClick = useCallback(() => {
    setIsEditing(true);
  }, [setIsEditing]);
  const handleCancelClick = useCallback(() => {
    setIsEditing(false);
    setSelectedActiveMilestoneId(milestones.find((milestone) => milestone.active)?.id);
    setSelectedMilestoneIds(
      milestones.filter(({ completed }) => completed).map((milestone) => milestone.id)
    );
  }, [milestones, setIsEditing]);

  const handleSaveMilestonesClick = useCallback(async () => {
    let nextActiveLenderMilestoneId = selectedActiveMilestoneId;
    if (selectedActiveMilestoneId === 'none') {
      nextActiveLenderMilestoneId = undefined;
    }

    setSubmitting(true);
    const success = await onSave(selectedMilestoneIds, nextActiveLenderMilestoneId);
    setSubmitting(false);

    if (success) {
      setIsEditing(false);
    }
  }, [onSave, selectedActiveMilestoneId, selectedMilestoneIds, setIsEditing]);

  const handleSelectCurrentLenderMilestone = useCallback((nextId: string) => {
    setSelectedActiveMilestoneId(nextId);
  }, []);

  const handleMilestoneChange = useCallback(
    (milestoneId: string, newValue: boolean) => {
      const milestoneIndex = milestones.findIndex((milestone) => milestone.id === milestoneId);

      setSelectedMilestoneIds((prevState) => {
        if (newValue) {
          if (!sequentialMilestones) {
            return [...prevState, milestoneId];
          }
          return dropRight(milestones, milestones.length - milestoneIndex - 1).map(
            (milestone) => milestone.id
          );
        }

        if (!sequentialMilestones) {
          return prevState.filter((id) => id !== milestoneId);
        }

        const dropIds = drop(milestones, milestoneIndex).map((milestone) => milestone.id);
        return prevState.filter((id) => !dropIds.includes(id));
      });

      setSelectedActiveMilestoneId((prevState) => {
        if (!sequentialMilestones) {
          return prevState;
        }

        const activeIndex = milestones.findIndex((milestone) => milestone.id === prevState);

        if (newValue && activeIndex < milestoneIndex) {
          return milestones[milestoneIndex + 1]?.id || milestones[milestoneIndex]!.id;
        }

        return prevState;
      });
    },
    [milestones, sequentialMilestones]
  );

  const editedMilestones = useMemo(
    () =>
      milestones.map((milestone) => ({
        ...milestone,
        active: milestone.id === selectedActiveMilestoneId,
        completed: !!selectedMilestoneIds.find((selectedId) => selectedId === milestone.id),
      })),
    [milestones, selectedActiveMilestoneId, selectedMilestoneIds]
  );

  useEffect(() => {
    const nextActiveMilestone = milestones.find((m) => m.active);
    setSelectedActiveMilestoneId(nextActiveMilestone?.id || 'none');
    setSelectedMilestoneIds(
      milestones.filter(({ completed }) => completed).map((milestone) => milestone.id)
    );
  }, [milestones]);

  const EditButtons = isEditing ? (
    <>
      <Button importance="tertiary" size="sm" disabled={submitting} onClick={handleCancelClick}>
        Cancel
      </Button>
      <Button
        importance="primary"
        size="sm"
        disabled={submitting}
        loading={submitting}
        onClick={handleSaveMilestonesClick}
      >
        <T cid="pageApplication:tabMilestones.lenderMilestones.saveLosMilestonesButton">
          Save LOS milestone status
        </T>
      </Button>
    </>
  ) : (
    <Button
      importance="secondary"
      size="sm"
      disabled={submitting}
      loading={submitting}
      onClick={handleEditMilestonesClick}
    >
      <T cid="pageApplication:tabMilestones.lenderMilestones.updateLosMilestonesButton">
        Update LOS milestone status
      </T>
    </Button>
  );

  const showButtons = !!(canEditMilestones || onLosLoanStatusRefresh);

  return (
    <Wrapper>
      <TopContainer>
        <ApplicationLenderMilestonesActiveMilestone
          milestones={milestones}
          isEditing={isEditing}
          onChange={handleSelectCurrentLenderMilestone}
          selectedActiveMilestoneId={selectedActiveMilestoneId}
          submitting={submitting}
        />
        {showButtons ? (
          <ButtonContainer>
            {canEditMilestones ? EditButtons : null}
            {onLosLoanStatusRefresh ? (
              <ApplicationLenderMilestonesRefreshLosStatus
                lastUpdated={lastUpdated}
                loading={loading}
                onClick={onLosLoanStatusRefresh}
              />
            ) : null}
          </ButtonContainer>
        ) : null}
      </TopContainer>
      {!!editedMilestones.length && (
        <>
          {isEditing ? (
            <EditingSubtitle>
              <T cid="pageApplication:tabMilestones.lenderMilestones.editingSubtitle">
                Check all completed LOS milestones:
              </T>
            </EditingSubtitle>
          ) : null}
          <LenderMilestonesList
            milestones={editedMilestones}
            isEditing={isEditing}
            onEditMilestoneChange={handleMilestoneChange}
          />
        </>
      )}
    </Wrapper>
  );
};
