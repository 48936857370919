import { ModalForm, T } from '@mortgagehippo/ds';
import { type ILiability } from '@mortgagehippo/tasks';
import { isNil } from 'lodash-es';
import { useMemo } from 'react';

import { LIABILITY_TYPE_OPTIONS } from './constants';
import { CreditCheckLiabilitiesForm } from './credit-check-liabilities-modal-form';
import { type IModel } from './util';

interface ICreditCheckLiabilitiesModalProps {
  isOpen: boolean;
  liability?: ILiability;
  model: IModel;
  onRequestClose: () => void;
  onSubmit: (values: any) => void;
}

export const CreditCheckLiabilitiesModal = (props: ICreditCheckLiabilitiesModalProps) => {
  const { isOpen, onRequestClose, liability, model, onSubmit } = props;
  const editing = !!liability;
  const verifiedData = liability?.__verified || undefined;
  const requireMonthlyPayment = !editing || (liability && !isNil(liability.monthly_payment_amount));

  const [initialValues, typeOptions] = useMemo(() => {
    const {
      type_name,
      full_name,
      account_identifier,
      unpaid_balance_amount,
      monthly_payment_amount,
      paid_off_before_closing,
      excluded,
      borrower_comments,
    } = liability || {};

    const related_reo = model.getRealEstateOwnedIdForLiability(liability);
    const initialValuesResult = {
      type_name,
      full_name,
      related_reo,
      account_identifier,
      unpaid_balance_amount,
      monthly_payment_amount,
      paid_off_before_closing,
      excluded,
      borrower_comments,
    };

    /*
     * if we are adding a new liability or editing one with a known type we'll use our predefined options,
     * otherwise type will be an open field.
     */
    const typeOptionsResult =
      !liability || LIABILITY_TYPE_OPTIONS.find((o) => o.value === liability.type_name)
        ? LIABILITY_TYPE_OPTIONS
        : undefined;

    return [initialValuesResult, typeOptionsResult];
  }, [liability, model]);

  const title = editing ? <T>Edit Liability</T> : <T>Add Liability</T>;

  return (
    <ModalForm
      title={title}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onSubmit={onSubmit}
      initialValues={initialValues}
      disableOverlayClickClose
      okButtonLabel={<T>Save</T>}
      width="medium"
    >
      <CreditCheckLiabilitiesForm
        verifiedData={verifiedData}
        liability={liability}
        model={model}
        typeOptions={typeOptions}
        requireMonthlyPayment={requireMonthlyPayment}
      />
    </ModalForm>
  );
};
