import { type FormSubmitHandler, type UploadHandler } from '@mortgagehippo/ds';

import { useSites } from '../../../hooks/use-sites';
import { ImportMismoModal } from './import-mismo-modal';

interface IImportMismoModalContainerProps {
  isOpen: boolean;
  onSubmit: FormSubmitHandler;
  onRequestClose: () => void;
  onUpload: UploadHandler;
  submitDisabled?: boolean;
}

export const ImportMismoModalContainer = (props: IImportMismoModalContainerProps) => {
  const { onSubmit, onRequestClose, onUpload, isOpen, submitDisabled } = props;

  const [sitesData, sitesLoading] = useSites();

  return (
    <ImportMismoModal
      isOpen={isOpen}
      loading={sitesLoading}
      siteOptions={sitesData || []}
      onSubmit={onSubmit}
      onUpload={onUpload}
      onRequestClose={onRequestClose}
      submitDisabled={submitDisabled}
    />
  );
};
