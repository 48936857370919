import { ButtonLink, fontWeight, T } from '@mortgagehippo/ds';
import type { IApplicationFileApplicant } from '@mortgagehippo/tasks';
import { trim } from 'lodash-es';
import styled from 'styled-components';

import { type IApplicationFileDocumentTask } from '../../use-application-file-documents';

const Name = styled.span`
  font-weight: ${fontWeight('light')};
`;

interface ITaskColumnProps {
  applicationFileId: string;
  task: IApplicationFileDocumentTask;
  applicant: IApplicationFileApplicant;
  showName?: boolean;
  showInitial?: boolean;
}

export const TaskColumn = (props: ITaskColumnProps) => {
  const { applicationFileId, task, applicant, showName, showInitial } = props;
  const { meta, isVisible, common } = task;
  const { title } = meta || {};

  const applicantInitial =
    (showInitial && applicant.lastName && `${applicant.lastName[0]!.toUpperCase()}.`) || '';
  const applicantName = (applicant.firstName &&
    trim(`${applicant.firstName} ${applicantInitial}`)) || (
    <>
      <T cid="pageTasks:taskList.borrower.genericLabel">Borrower</T>
      {` #${(applicant.position || 0) + 1}`}
    </>
  );

  const taskTitle =
    !common && showName ? (
      <>
        <Name>{applicantName}: </Name>
        {title}
      </>
    ) : (
      title
    );

  const url = isVisible
    ? `#/applications/${applicationFileId}/tasks/${applicant.id}/${task.id}`
    : null;

  if (url) {
    return <ButtonLink to={url}>{taskTitle}</ButtonLink>;
  }

  return taskTitle;
};
