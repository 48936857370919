import { type IModalFormProps, ModalForm, TextArea } from '@mortgagehippo/ds';

export const ArchiveApplicationModal = (props: IModalFormProps) => {
  const { children, ...rest } = props;

  return (
    <ModalForm
      autoComplete={false}
      icon="warning"
      iconColor="warning500"
      okButtonIcon="archive"
      okButtonType="danger"
      width={730}
      {...rest}
    >
      {children}
      <TextArea.Box
        label="Reason for archiving"
        labelCid="actions:archiveApplication.modal.fields.reasonForArchiving.label"
        name="reason"
      />
    </ModalForm>
  );
};
