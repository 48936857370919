import { Menu, MenuItem } from '@mortgagehippo/menu';

import { type IApplicationService } from './types';

interface IApplicationServicesSidebarProps {
  applicationFileId: string;
  services: IApplicationService[];
}

export const ApplicationServicesSidebar = (props: IApplicationServicesSidebarProps) => {
  const { applicationFileId, services } = props;

  return (
    <Menu>
      {services.map((service) => {
        const { path, name } = service;

        const to = `#/applications/${applicationFileId}/integrations/${path}`;

        return <MenuItem key={path} to={to} label={name} />;
      })}
    </Menu>
  );
};
