import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo';
import { type CreateTasksInput } from '../../apollo/graphql';

export const MCreateTasks = graphql(`
  mutation CreateTasks(
    $applicationFileId: ID!
    $tasks: [CreateTasksInput!]!
    $emailMessage: String
  ) {
    createTasks(applicationFileId: $applicationFileId, tasks: $tasks, emailMessage: $emailMessage) {
      id
      primaryApplicantId
    }
  }
`);

export const useCreateTasks = () => {
  const createTasks = useMutation(MCreateTasks, {} as any);

  return useCallback(
    async (applicationFileId: string, tasks: CreateTasksInput[], emailMessage?: string | null) => {
      const response = await createTasks({
        applicationFileId,
        tasks,
        emailMessage,
      });
      return response.data!.createTasks;
    },
    [createTasks]
  );
};
