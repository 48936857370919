import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';
import { cloneDeep } from 'lodash-es';
import { useMemo } from 'react';

import { graphql } from '../../apollo';
import { type AgentDataQuery } from '../../apollo/graphql';
import { usePartnerDomain } from '../use-partner-domain';

export type IAgentData = NonNullable<NonNullable<AgentDataQuery['partner']>['agent']>;

export const QAgentData = graphql(`
  query AgentData($partnerId: ID!, $agentId: ID!) {
    partner(id: $partnerId) {
      id
      agent(id: $agentId) {
        id
        defaultFolderId
        folders {
          id
          name
          path
        }
        name
        email
        jobTitle
        phone
        cellPhone
        alternatePhone
        secondaryEmail
        license
        licensedStates
        bio
        facebookUrl
        linkedinUrl
        twitterUrl
        slug
        avatarUrl
        avatarUrlKey
        metadata
        visible

        authRole {
          id
        }

        sites(options: { perPage: 1000 }) {
          items {
            id
          }
        }

        jobFunctions(options: { perPage: 1000 }) {
          items {
            id
            siteId
          }
        }

        teamTemplates {
          items {
            id
            siteId
            jobFunctions {
              id
              agents {
                id
              }
            }
          }
        }
      }
    }
  }
`);

export const useAgentData = (agentId: string, options: IUseApolloQueryOptions = {}) => {
  const partnerId = usePartnerDomain();

  const [data, loading, , { refetch }] = useQuery(
    QAgentData,
    {
      partnerId,
      agentId,
    },
    {
      ...options,
      suspend: false,
      fetchPolicy: 'network-only',
    }
  );

  const handleRefresh = useSafeCallback(async () => refetch({ partnerId, agentId }));
  const result = useMemo(() => cloneDeep(data?.partner?.agent), [data]);

  if (result) {
    result.teamTemplates.items.forEach((teamTemplate) => {
      // eslint-disable-next-line no-param-reassign
      teamTemplate.jobFunctions = teamTemplate.jobFunctions.filter((jobFunction) =>
        jobFunction.agents.some((agent) => agent.id === agentId)
      );
    });
  }

  return [{ data: result, loading }, handleRefresh] as const;
};
