import { Modal, spacing, Text, Upload, type UploadHandler } from '@mortgagehippo/ds';
import styled from 'styled-components';

const IMAGE_MIME_TYPES = ['image/png', 'image/jpeg'];

const Warning = styled.div`
  margin-top: ${spacing(3)};
`;

interface IAvatarModalProps {
  onRequestClose: () => void;
  onUpload: UploadHandler;
}

export const AvatarModal = (props: IAvatarModalProps) => {
  const { onUpload, ...rest } = props;

  return (
    <Modal title="Change Photo" isOpen {...rest} hideOkButton>
      <Upload
        name="file"
        onUpload={onUpload}
        accept={IMAGE_MIME_TYPES}
        maxItems={1}
        fileTypeDescription="photo"
      />
      <Warning>
        <Text size="sm" variant="secondary">
          For best results use a square image with minimum width and height of 200x200px
        </Text>
      </Warning>
    </Modal>
  );
};
