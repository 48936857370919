import { useContext } from 'react';

import { OverlayContext } from './context';

export const useCloseOverlay = () => {
  const context = useContext(OverlayContext);
  return () => {
    if (context) {
      context.onRequestClose();
    }
  };
};
