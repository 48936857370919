import {
  Dropdown,
  fontSize,
  type IButtonProps,
  type IDropdownAction,
  palette,
  spacing,
  Title,
} from '@mortgagehippo/ds';
import { type ReactNode, useMemo } from 'react';
import styled from 'styled-components';

import { type IApplicationApplicant } from '../use-application-file-data';

const Container = styled.div`
  margin-bottom: ${spacing(7)};
`;

const ApplicantTitle = styled.div`
  margin-bottom: ${spacing(2)};
`;

const ApplicantName = styled(Title)`
  color: ${palette('primary600')};
  display: inline-block;
  font-size: ${fontSize('md')};
  vertical-align: middle;
  margin: 0;
`;

const StyledDropdown = styled(Dropdown)`
  display: inline-block;
  vertical-align: middle;
`;

const dropdownButtonProps: IButtonProps = {
  icon: 'menu-dots',
  iconButton: true,
  type: 'neutral',
  importance: 'tertiary',
  size: 'xxs',
  iconButtonRound: true,
  compact: true,
};

interface IApplicationViewSidebarMenuGroupProps {
  applicant: IApplicationApplicant;
  onDelete?: (applicantId: string) => void;
  children?: ReactNode;
}

export const ApplicationViewSidebarMenuGroup = (props: IApplicationViewSidebarMenuGroupProps) => {
  const { applicant, onDelete, children } = props;

  const actions = useMemo(() => {
    const nextActions: IDropdownAction[] = [];

    if (onDelete && !applicant.primary) {
      nextActions.push({
        key: 'delete',
        cid: 'delete-applicant-action:label',
        label: 'Remove co-borrower',
        onAction: () => {
          onDelete(applicant.id);
        },
      });
    }

    return nextActions;
  }, [applicant.id, applicant.primary, onDelete]);

  return (
    <Container>
      <ApplicantTitle>
        <ApplicantName level={2}>{applicant.name}</ApplicantName>&nbsp;
        {actions.length > 0 && (
          <StyledDropdown label="actions" buttonProps={dropdownButtonProps} actions={actions} />
        )}
      </ApplicantTitle>
      {children}
    </Container>
  );
};
