import { notifications, useCustomizations, useModal } from '@mortgagehippo/ds';
import { useSagaSubmitHandler } from '@mortgagehippo/tasks';
import { useCallback, useEffect, useMemo } from 'react';

import { type IActionProps, type IUpdateLendingQbPushAction } from '$components/actions';

import { useLendingQbLosPush } from '../../../pages/application/application-services/services/los/lending-qb/use-lending-qb-los-push';
import { UpdateLendingQBPushModal } from './update-lending-qb-push-modal';
import { useUpdateLendingQBPush } from './use-update-lending-qb-push';

export const UpdateLendingQBPushAction = (props: IActionProps<IUpdateLendingQbPushAction>) => {
  const { action, onSubmit, onDone, onCancel } = props;
  const { applicationFileId } = action;

  const [isOpen, , closeModal] = useModal(true);

  const customizations = useCustomizations();
  const title = customizations.text('updateLendingQBPushAction:modal.title', 'Update Loan Number');

  const [lendingQBPush, loading] = useLendingQbLosPush(applicationFileId);

  const updateLendingQBPush = useUpdateLendingQBPush();

  useEffect(() => {
    if (!loading && !lendingQBPush) {
      closeModal(() => {
        notifications.error({
          messageCid: 'updateLendingQBPushAction:error.load.message',
          message: 'The associated records could not be found.',
        });
        onCancel();
      });
    }
  }, [lendingQBPush, closeModal, loading, onCancel, onDone]);

  const [handleSubmit, running] = useSagaSubmitHandler({
    infoMessageCid: 'updateLendingQBPushAction:info.message',
    successMessageCid: 'updateLendingQBPushAction:success.message',
    errorMessageCid: 'updateLendingQBPushAction:error.submit.message',
    infoMessage: 'Updating loan number',
    successMessage: 'Loan number was updated.',
    errorMessage: 'There was an unexpected error updating the loan number.',
    async onSubmit(values: any, setAsyncId) {
      const { loan_number } = values;

      const data = {
        loanNumber: loan_number,
      };

      const asyncId = await updateLendingQBPush(applicationFileId, data);

      closeModal(() => {
        setAsyncId(asyncId);
        onSubmit();
      });
    },
    async onDone() {
      closeModal(onDone);
    },
  });

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  const initialValues = useMemo(() => {
    if (!lendingQBPush) {
      return {};
    }

    const { loanNumber } = lendingQBPush;

    return {
      loan_number: loanNumber,
    };
  }, [lendingQBPush]);

  if (running) {
    return null;
  }

  return (
    <UpdateLendingQBPushModal
      isOpen={isOpen}
      title={title}
      onSubmit={handleSubmit}
      onRequestClose={handleCloseModal}
      initialValues={initialValues}
      loading={loading}
    />
  );
};
