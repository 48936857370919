import { createContext, type ReactNode, useMemo, useState } from 'react';

export interface ILayoutContext {
  headerHeight?: number;
}

export interface IWithLayoutContext {
  layoutContext: ILayoutContext;
  setLayoutContext: (newContext: ILayoutContext) => void;
}

export const LayoutContext = createContext<IWithLayoutContext>({
  layoutContext: {},
  setLayoutContext: () => undefined,
});

interface ILayoutContextProviderProps {
  children?: ReactNode;
}

export const LayoutContextProvider = (props: ILayoutContextProviderProps) => {
  const { children } = props;
  const [layoutContext, setLayoutContext] = useState<ILayoutContext>({});

  const value = useMemo(() => ({ layoutContext, setLayoutContext }), [layoutContext]);

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
};
