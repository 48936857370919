import { NotFoundError } from '@mortgagehippo/util';
import { useEffect, useState } from 'react';
import { type RouteComponentProps } from 'react-router';

import { auth } from '../../services/auth';

export const AdminLoginPage = (props: RouteComponentProps) => {
  const { match, location } = props;
  const { pathname } = location;
  const { path } = match;

  const [error, setError] = useState<Error | null>();

  useEffect(() => {
    (async () => {
      const hasAdminLogin = await auth.hasAdminLogin();

      if (!hasAdminLogin) {
        setError(new NotFoundError());
        return;
      }

      await auth.login(undefined, { admin: true });
    })();
  }, [path, pathname]);

  if (error) {
    throw error;
  }

  return null;
};
