import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo';

export const MUpdateActiveMilestone = graphql(`
  mutation IUpdateActiveMilestone($applicationFileId: ID!, $milestoneId: ID!) {
    updateActiveMilestone(applicationFileId: $applicationFileId, milestoneId: $milestoneId)
  }
`);

export const useUpdateActiveMilestone = (applicationFileId: string) => {
  const updateActiveMilestone = useMutation(MUpdateActiveMilestone);

  return useCallback(
    async (milestoneId: string) => {
      const response = await updateActiveMilestone({
        applicationFileId,
        milestoneId,
      });
      return response.data!.updateActiveMilestone;
    },
    [applicationFileId, updateActiveMilestone]
  );
};
