import { type IUseApolloQueryOptions } from "@mortgagehippo/apollo-hooks";

import { LosType } from "../../../apollo/graphql";
import { useBytePush } from "./services/los/byte/use-byte-push";
import { useEncompassPush } from "./services/los/encompass/use-encompass-push";
import { useLendingQbLosPush } from "./services/los/lending-qb/use-lending-qb-los-push";
import { useMortgageDirectorLosPush } from "./services/los/mortgage-director/use-mortgage-director-los-push";
import { useMortgageBotLosPush } from "./services/los/mortgagebot/use-mortgagebot-los-push";
import { useMortgageflexLosPush } from "./services/los/mortgageflex/use-mortgageflex-los-push";


export const useLosPush = (
  applicationFileId: string,
  losType: LosType,
  options: IUseApolloQueryOptions = {}
) => {

  const { skip, ...restOptions } = options;

  const [mortgageDirectorPush, isMortgageDirectorLoading] = useMortgageDirectorLosPush(
    applicationFileId,
    {
     ...restOptions, skip: skip || losType !== LosType.MortgageDirector,
    }
  );

  const [encompassPush, isEncompassLoading] = useEncompassPush(applicationFileId, {
    ...restOptions ,skip: skip || losType !== LosType.EncompassApi,
  });

  const [bytePush, isByteLoading] = useBytePush(applicationFileId, {
    ...restOptions ,skip: skip || losType !== LosType.Byte,
  });

  const [lendingQbPush, isLendingQbLoading] = useLendingQbLosPush(applicationFileId, {
    ...restOptions ,skip: skip || losType !== LosType.LendingQb,
  });

  const [mortgageBotPush, isMortgageBotLoading] = useMortgageBotLosPush(applicationFileId, {
    ...restOptions ,skip: skip || losType !== LosType.MortgageBot,
  });

  const [mortgageFlexPush, isMortgageFlexLoading] = useMortgageflexLosPush(applicationFileId, {
    ...restOptions ,skip: skip || losType !== LosType.MortgageFlex,
  });

  const result =
    mortgageDirectorPush ||
    encompassPush ||
    bytePush ||
    lendingQbPush ||
    mortgageBotPush ||
    mortgageFlexPush;

  const isLoading =
    isMortgageDirectorLoading ||
    isEncompassLoading ||
    isByteLoading ||
    isLendingQbLoading ||
    isMortgageBotLoading ||
    isMortgageFlexLoading;

  return [result, isLoading] as const;
};
