/* eslint-disable react/no-array-index-key */
import { Button, fontSize, Icon, palette, spacing, useCustomizations } from '@mortgagehippo/ds';
import { Menu, MenuItem } from '@mortgagehippo/menu';
import { APPLICATION_TASK_TYPES, type IPanel, TaskType } from '@mortgagehippo/tasks';
import { isPresent, tween } from '@mortgagehippo/util';
import { type ReactNode, useCallback } from 'react';
import styled from 'styled-components';

import { type IApplicationApplicant } from '../use-application-file-data';
import { ApplicationViewSidebarMenuGroup } from './application-view-sidebar-menu-group';
import { useApplicationErrors } from './use-application-errors';

const ButtonContainer = styled.div`
  margin: 0 -${spacing(2)};
  padding: ${spacing(2)} 0;
`;

const StyledMenu = styled(Menu)`
  > li > ul {
    list-style: none;
    margin: 0 0 ${spacing(4)} ${spacing(3)};
    padding: 0 0 0 ${spacing(3)};
    font-size: ${fontSize('xs')};
    line-height: 1.3em;
    color: ${palette('neutral900')};
    border-left: 1px solid ${palette('neutral200')};
  }
`;

const TaskTitle = styled.span`
  vertical-align: middle;
`;

const PanelItem = styled.li`
  margin: 0;
  padding: ${spacing(1)} 0;

  ${Button} {
    span {
      white-space: normal;
      font-weight: normal;
    }
  }
`;

const PanelButton = styled(Button)`
  span,
  svg {
    vertical-align: middle;
  }
`;

const TaskErrorIcon = styled(Icon)`
  vertical-align: middle;
  margin-right: ${spacing(2)};
`;

const PanelErrorIcon = styled(Icon)`
  vertical-align: middle;
  margin-right: ${spacing(1)};
`;

const taskErrorIconTask = (
  <TaskErrorIcon name="error" aria-label="error icon" size="normal" color="danger600" />
);

const panelErrorIconTask = (
  <PanelErrorIcon name="error" aria-label="error icon" size="sm" color="danger600" />
);

interface IApplicationViewSidebarProps {
  applicationBlueprint?: any;
  applicationFileId: string;
  applicants: IApplicationApplicant[];
  onAddApplicant?: () => void;
  onDeleteApplicant?: (applicantId: string) => void;
}

export const ApplicationViewSidebar = (props: IApplicationViewSidebarProps) => {
  const { applicationBlueprint, applicationFileId, applicants, onAddApplicant, onDeleteApplicant } =
    props;

  const [applicationErrors] = useApplicationErrors();
  const customizations = useCustomizations();

  const minSubNavCount = customizations.number(
    'app:application.tabApplication.nav.minSubNavCount',
    15
  );

  const handlePanelJump = useCallback(
    (panelTitle: string | undefined) => () => {
      if (!panelTitle) {
        return;
      }
      const panelTitleEls = document.querySelectorAll('[data-panel-title]');
      panelTitleEls.forEach((el) => {
        const titleEl = el as HTMLHeadElement;
        if (titleEl.innerText === panelTitle) {
          titleEl.focus();
          titleEl.style.backgroundColor = 'rgba(249 ,227, 120,1)';
          const callback = (latest: number) => {
            if (titleEl) {
              titleEl.style.backgroundColor = latest ? `rgba(249 ,227, 120,${latest / 100})` : '';
            }
          };
          setTimeout(() => {
            tween(100, 0, 2000, callback);
          }, 1500);
        }
      });
    },
    []
  );

  let applicantsListEl: ReactNode = null;
  if (applicationBlueprint && applicants.length > 0) {
    const useGroups = applicants.length > 1;

    applicantsListEl = (
      <div>
        {applicants.map((applicant) => {
          const menuItems: any[] = applicationBlueprint.automated_tasks
            .filter((task: any) => APPLICATION_TASK_TYPES.includes(task.type_name))
            .map((task: any) => {
              // TODO: For now only show common tasks on the primary borrower
              const common =
                task && (task.meta?.common || task.type_name === TaskType.SubmitApplicationTask);
              if (common && !applicant.primary) {
                return null;
              }
              const to = `#/applications/${applicationFileId}/view/${applicant.id}/${task.key}`;
              const taskHasError = isPresent(applicationErrors?.[applicant.id]?.[task.key]);
              const panels: IPanel[] = task.data?.panels || [];

              return (
                <MenuItem
                  key={task.key}
                  to={to}
                  label={
                    <>
                      {(taskHasError && taskErrorIconTask) || null}
                      <TaskTitle>{task.meta.title}</TaskTitle>
                    </>
                  }
                  iconLocation="right"
                  dataAttributes={{
                    'task-list-group-item-link': task.key,
                  }}
                  autoHideChildren
                >
                  {panels.length >= minSubNavCount && (
                    <>
                      {panels.map((panel: IPanel, i: number) => {
                        const panelHasErrors = isPresent(
                          applicationErrors?.[applicant.id]?.[task.key]?.errors?.panels?.[i]
                        );

                        const icon =
                          applicationErrors && panelHasErrors ? panelErrorIconTask : undefined;

                        return (
                          <PanelItem key={`${i}`}>
                            <PanelButton
                              size="xxs"
                              type="neutral"
                              importance="tertiary"
                              onClick={handlePanelJump(panel.title || undefined)}
                              compact
                              align="left"
                            >
                              {icon}
                              <span>{panel.title || `Section ${i + 1}`}</span>
                            </PanelButton>
                          </PanelItem>
                        );
                      })}
                    </>
                  )}
                </MenuItem>
              );
            });

          if (!useGroups) {
            return (
              <StyledMenu key={applicant.id} size="normal">
                {menuItems}
              </StyledMenu>
            );
          }

          return (
            <ApplicationViewSidebarMenuGroup
              key={applicant.id}
              applicant={applicant}
              onDelete={onDeleteApplicant}
            >
              <StyledMenu size="normal">{menuItems}</StyledMenu>
            </ApplicationViewSidebarMenuGroup>
          );
        })}
      </div>
    );
  }

  return (
    <>
      {onAddApplicant ? (
        <ButtonContainer>
          <Button
            importance="secondary-outline"
            icon="add-user"
            onClick={onAddApplicant}
            size="sm"
            block
            compact
          >
            Add borrower
          </Button>
        </ButtonContainer>
      ) : null}
      {applicantsListEl}
    </>
  );
};
