import { Constant, Input } from '@mortgagehippo/ds';
import { type ReactNode } from 'react';

import { ContentEditor } from '$components/content-editor';
import { DueDate } from '$components/due-date-field';

import { type IApplicationApplicant } from '../../../../pages/application/use-application-file-data';
import { ApplicantChoice } from './applicant-choice';

interface IDefaultFieldsProps {
  applicants: IApplicationApplicant[];
  allowCommon?: boolean;
  disableApplicantSelection?: boolean;
  allowMultipleApplicants?: boolean;
  showMultipleApplicantsAlert?: boolean;
  initialValues?: {
    title?: string;
    description?: string;
  };
  children?: ReactNode;
  showDueDate?: boolean;
}

export const DefaultFields = (props: IDefaultFieldsProps) => {
  const {
    allowCommon,
    applicants,
    disableApplicantSelection,
    allowMultipleApplicants,
    showMultipleApplicantsAlert,
    initialValues,
    showDueDate,
    children,
  } = props;

  const { title: initialTitle, description: initialDescription } = initialValues || {};

  const applicantsElem =
    applicants.length === 1 ? (
      <Constant name="applicant_ids" initialValue={[applicants[0]!.id]} />
    ) : (
      <ApplicantChoice
        name="applicant_ids"
        applicants={applicants}
        allowCommon={allowCommon}
        disabled={disableApplicantSelection}
        multiple={allowMultipleApplicants}
        multipleAlert={showMultipleApplicantsAlert}
        required
      />
    );

  return (
    <>
      {!!showDueDate && (
        <DueDate.Box
          name="due_date"
          label="Due Date"
          labelInvisible
          size="sm"
          validateFromNow="gte"
        />
      )}
      <Input.Box
        name="title"
        label="Task Title"
        required
        initialValue={initialTitle}
        labelTooltip="The task title should describe what the borrower should do in up to 5 words. Do not include the borrower’s name if there is more than one borrower - the system will automatically display it."
        maxLength={45}
      />
      {applicantsElem}
      {children}
      <ContentEditor.Box
        name="description"
        label="Instructions for borrower"
        mode="micro"
        required
        initialValue={initialDescription}
        labelTooltip="Write a clear and brief explanation or instructions to help the borrower successfully complete this task."
      />
    </>
  );
};
