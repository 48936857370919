import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../apollo';

const MCreateUpscopeWatchUrl = graphql(`
  mutation CreateUpscopeWatchUrl($applicantId: ID!) {
    upscope {
      createWatchUrl(applicantId: $applicantId) {
        watchUrl
      }
    }
  }
`);

export const useCreateUpscopeWatchUrl = () => {
  const fn = useMutation(MCreateUpscopeWatchUrl);

  return useCallback(
    async (applicantId: string) => {
      const result = await fn({ applicantId });
      return result.data!.upscope.createWatchUrl.watchUrl;
    },
    [fn]
  );
};
