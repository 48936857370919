import {
  fontSize,
  fontWeight,
  Format,
  type IButtonProps,
  lineHeight,
  MediaBreakpoint,
  palette,
  Popover,
  spacing,
  T,
  useCustomizations,
} from '@mortgagehippo/ds';
import { type ReactNode } from 'react';
import styled from 'styled-components';

import { type IApplicationApplicant } from '../../pages/application/use-application-file-data';
import { ApplicationHeaderApplicantsActions } from './application-header-applicants-actions';
import { ApplicationHeaderApplicantsExtended } from './application-header-applicants-extended';

const Container = styled.div`
  display: flex;
`;

const List = styled.ul`
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;

  ${MediaBreakpoint.PHONE} {
    display: block;
    width: 100%;
  }
`;

const Item = styled.li`
  margin-left: ${spacing(5)};
  flex: 0 0 auto;
  line-height: 1;

  &:first-child {
    margin-left: 0;

    ${MediaBreakpoint.PHONE} {
      margin-top: ${spacing(2)};
    }
  }

  ${MediaBreakpoint.PHONE} {
    margin-left: 0;
    margin-top: ${spacing(4)};
    flex: none;
    width: 100%;
  }
`;

const Unavailable = styled.span``;

const ContactInfo = styled('div')<{ headerDetailsColor?: string }>`
  display: flex;
  margin-top: -${spacing(1)};
  font-size: ${fontSize('xs')};
  line-height: ${lineHeight('xs')};

  ${MediaBreakpoint.PHONE} {
    display: block;
  }

  a,
  a:visited,
  ${Unavailable} {
    font-weight: ${fontWeight('light')};
    color: ${(p) =>
      p.headerDetailsColor ||
      palette('primary200')} !important; /* !important to override link colors */
  }

  a:hover,
  a:active {
    text-decoration: underline !important;
  }

  > :first-child {
    margin-left: 0;
  }

  > * {
    margin-left: ${spacing(3)};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 300px;

    ${MediaBreakpoint.PHONE} {
      margin: 0;
      max-width: 100%;
      flex: none;
    }
  }
`;

const ExtendedListContainer = styled.div`
  flex: 0 0 auto;
  margin-left: ${spacing(5)};
`;

interface IApplicationHeaderApplicantsProps {
  applicants: IApplicationApplicant[];
  onUpdateApplicantEmail?: (applicantId: string) => void;
  onDeleteApplicant?: (applicant: string) => void;
}

export const ApplicationHeaderApplicants = (props: IApplicationHeaderApplicantsProps) => {
  const { applicants, onUpdateApplicantEmail, onDeleteApplicant } = props;

  const customizations = useCustomizations();
  const headerDetailsColor = customizations.color('app:application.header.details.color');

  const headerCloseButtonInverted = customizations.bool(
    'app:application.header.buttons.inverted',
    true
  );

  const buttonProps: IButtonProps = {
    size: 'xs',
    importance: 'tertiary',
    type: 'neutral',
    inverted: headerCloseButtonInverted,
  };

  const mainApplicants = applicants.slice(0, 2);
  const extendedApplicants = applicants.slice(2);

  let extendedEl: ReactNode;
  if (extendedApplicants.length > 0) {
    const expandLinkLabel =
      extendedApplicants.length > 1
        ? `${extendedApplicants.length} more borrowers`
        : `${extendedApplicants.length} more borrower`;

    const extendedElContent = (
      <ApplicationHeaderApplicantsExtended applicants={extendedApplicants} />
    );

    extendedEl = (
      <Popover align="BottomLeft" content={extendedElContent} buttonProps={buttonProps}>
        {expandLinkLabel}
      </Popover>
    );
  }

  return (
    <Container>
      <List>
        {mainApplicants.map((applicant) => {
          const { id: applicantId, name, phoneNumber, email, primary, sharingEmail } = applicant;

          return (
            <Item key={applicantId}>
              <ApplicationHeaderApplicantsActions
                applicantId={applicantId}
                onUpdateApplicantEmail={onUpdateApplicantEmail}
                onDeleteApplicant={!primary ? onDeleteApplicant : undefined}
              >
                {name}
              </ApplicationHeaderApplicantsActions>

              <ContactInfo headerDetailsColor={headerDetailsColor}>
                {phoneNumber ? (
                  <div>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <a href={`tel:${phoneNumber}`} target="_blank" rel="noreferrer">
                      <Format.Phone value={phoneNumber} />
                    </a>
                  </div>
                ) : null}
                <div>
                  {(email && !sharingEmail && (
                    <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
                      {email}
                    </a>
                  )) || (
                    <Unavailable>
                      <T>Email unavailable</T>
                    </Unavailable>
                  )}
                </div>
              </ContactInfo>
            </Item>
          );
        })}
      </List>
      {extendedEl ? <ExtendedListContainer>{extendedEl}</ExtendedListContainer> : null}
    </Container>
  );
};
