import { TabRoute, TabRouter, Text } from '@mortgagehippo/ds';

import {
  CREATE_CSV_FAILURE,
  CREATE_CSV_REQUEST,
  CREATE_CSV_STARTED,
  CREATE_CSV_SUCCESS,
  DOCUMENT_ARCHIVE_FAILURE,
  DOCUMENT_ARCHIVE_REQUEST,
  DOCUMENT_ARCHIVE_STARTED,
  DOCUMENT_ARCHIVE_SUCCESS,
  useActionEffect,
} from '$components/actionable';
import { useUserCan } from '$components/permissions';

import { ExportJobType } from '../../apollo/graphql';
import { Content, Layout } from '../../layouts/main';
import { DocumentsTable } from './documents-table';
import { useExportJobs } from './use-export-jobs';

export const DownloadsPage = () => {
  const [applicationFileCSVJobs, applicationFileCSVJobsLoading, refetchApplicationFileCSVJobs] =
    useExportJobs(ExportJobType.APPLICATION_FILES_CSV);

  const [can] = useUserCan();
  const [agentsCSVJobs, agentsCSVJobsLoading, refreshAgentsCSVJobs] = useExportJobs(
    ExportJobType.AGENTS_CSV
  );

  const [documentArchiveJobs, documentArchiveJobsLoading, refetchDocumentArchiveJobs] =
    useExportJobs(ExportJobType.DOCUMENTS_ARCHIVE);

  const handleRefetch = (fn: any) => () => {
    fn();
  };
  useActionEffect(CREATE_CSV_REQUEST, handleRefetch(refetchApplicationFileCSVJobs));
  useActionEffect(CREATE_CSV_STARTED, handleRefetch(refetchApplicationFileCSVJobs));
  useActionEffect(CREATE_CSV_SUCCESS, handleRefetch(refetchApplicationFileCSVJobs));
  useActionEffect(CREATE_CSV_FAILURE, handleRefetch(refetchApplicationFileCSVJobs));

  useActionEffect(CREATE_CSV_REQUEST, handleRefetch(refreshAgentsCSVJobs));
  useActionEffect(CREATE_CSV_STARTED, handleRefetch(refreshAgentsCSVJobs));
  useActionEffect(CREATE_CSV_SUCCESS, handleRefetch(refreshAgentsCSVJobs));
  useActionEffect(CREATE_CSV_FAILURE, handleRefetch(refreshAgentsCSVJobs));

  useActionEffect(DOCUMENT_ARCHIVE_REQUEST, handleRefetch(refetchDocumentArchiveJobs));
  useActionEffect(DOCUMENT_ARCHIVE_STARTED, handleRefetch(refetchDocumentArchiveJobs));
  useActionEffect(DOCUMENT_ARCHIVE_SUCCESS, handleRefetch(refetchDocumentArchiveJobs));
  useActionEffect(DOCUMENT_ARCHIVE_FAILURE, handleRefetch(refetchDocumentArchiveJobs));

  return (
    <Layout pageTitle="Exports">
      <Content>
        <Text as="p">
          This page contains the most recent exports that you have generated from various places,
          such as the Pipeline.
        </Text>

        <TabRouter>
          {can.EXPORT_CSV ? (
            <TabRoute path="/exports/application-files" label="Applications">
              <DocumentsTable
                caption="Applications"
                dataSource={applicationFileCSVJobs}
                loading={applicationFileCSVJobsLoading}
              />
            </TabRoute>
          ) : null}

          {can.EXPORT_CSV ? (
            <TabRoute path="/exports/agents" label="Agents">
              <DocumentsTable
                caption="Agents"
                dataSource={agentsCSVJobs}
                loading={agentsCSVJobsLoading}
              />
            </TabRoute>
          ) : null}

          {can.EXPORT_DOCUMENTS ? (
            <TabRoute path="/exports/documents" label="Document">
              <DocumentsTable
                caption="Documents"
                dataSource={documentArchiveJobs}
                loading={documentArchiveJobsLoading}
              />
            </TabRoute>
          ) : null}
        </TabRouter>
      </Content>
    </Layout>
  );
};
