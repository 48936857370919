import { ForbiddenError } from '@mortgagehippo/auth';
import { type RouteComponentProps } from 'react-router';

import { useApplicationFileCan } from '$components/permissions';

import { ApplicationActivity } from './application-activity';

interface IApplicationActivityRouteParams {
  applicationFileId: string;
}

export const ApplicationActivityRoute = (
  props: RouteComponentProps<IApplicationActivityRouteParams>
) => {
  const { match } = props;
  const { params } = match;
  const { applicationFileId } = params;

  const [can, canReady] = useApplicationFileCan(applicationFileId);

  if (!canReady) {
    return null;
  }

  if (!can.VIEW_APPLICATION_ACTIVITY_PAGE) {
    throw new ForbiddenError();
  }

  return <ApplicationActivity applicationFileId={applicationFileId} />;
};
