import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo';

export const MTriggerCreditCheck = graphql(`
  mutation TriggerCreditCheck(
    $applicationFileId: ID!
    $applicantId: ID!
    $forceAuthorization: Boolean
  ) {
    triggerCreditPull(
      applicationFileId: $applicationFileId
      applicantId: $applicantId
      forceAuthorization: $forceAuthorization
    )
  }
`);

export const useTriggerCreditCheck = () => {
  const triggerCreditCheck = useMutation(MTriggerCreditCheck, undefined);

  return useCallback(
    async (
      applicationFileId: string,
      applicantId: string,
      forceAuthorization?: boolean
    ): Promise<string> => {
      const response = await triggerCreditCheck({
        applicationFileId,
        applicantId,
        forceAuthorization,
      });
      return response.data!.triggerCreditPull;
    },
    [triggerCreditCheck]
  );
};
