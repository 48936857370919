import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo';

const MSiteReport = graphql(`
  mutation SiteReport($siteId: String!, $dashboard: String!) {
    getSiteReportUrl(siteId: $siteId, dashboard: $dashboard)
  }
`);

export const useGetSiteReportUrl = () => {
  const fn = useMutation(MSiteReport);

  return useCallback(
    async (siteId: string, dashboard: string): Promise<string | null> => {
      const response = await fn({
        siteId,
        dashboard,
      });

      return response.data!.getSiteReportUrl;
    },
    [fn]
  );
};
