import { Card, fontSize, MediaBreakpoint, palette, spacing, Title } from '@mortgagehippo/ds';
import { orderBy } from 'lodash-es';
import { type ReactNode, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { type ILenderMilestone } from '../../use-lender-milestones';
import { ApplicationLenderMilestonesContainer } from './application-lender-milestones-container';
import { ApplicationLenderMilestonesEmpty } from './application-lender-milestones-empty';
import { ApplicationLenderMilestonesSkeleton } from './application-lender-milestones-skeleton';

const StyledCard = styled(Card)<{ isEditing?: boolean }>`
  padding: ${spacing(5)};
  margin-top: ${spacing(6)};

  &:first-child {
    margin-top: ${spacing(2)};
  }

  ${MediaBreakpoint.PHONE} {
    padding: ${spacing(3)};
    margin-top: ${spacing(2)};
  }

  ${(p) =>
    p.isEditing &&
    css`
      background-color: ${palette('primary50')};
      border: 1px solid ${palette('primary600')};
    `}
`;

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${spacing(2)};

  ${MediaBreakpoint.PHONE} {
    display: block;
  }
`;

const TitleCol = styled.div`
  flex: 0 0 120px;

  ${MediaBreakpoint.PHONE} {
    flex: none;
  }
`;

const StyledTitle = styled(Title)`
  margin: 0;
  font-size: ${fontSize('sm')};

  ${MediaBreakpoint.PHONE} {
    flex: none;
  }
`;

interface IApplicationLenderMilestonesContentProps {
  milestones: ILenderMilestone[];
  loading?: boolean;
  canRefreshMilestones?: boolean;
  onSave: (completedMilestoneIds: string[], activeMilestoneId?: string) => Promise<boolean>;
  onLosLoanStatusRefresh: () => void;
  canEditMilestones?: boolean;
  sequentialLenderMilestones?: boolean;
}

export const ApplicationLenderMilestonesContent = (
  props: IApplicationLenderMilestonesContentProps
) => {
  const {
    loading,
    canEditMilestones,
    canRefreshMilestones,
    onLosLoanStatusRefresh,
    onSave,
    milestones,
    sequentialLenderMilestones,
  } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const lastCompletedMilestone = useMemo(() => {
    const completedMilestones = milestones.filter((m) => m.completed);
    return orderBy(completedMilestones, ['updatedOn'], ['desc'])[0];
  }, [milestones]);

  const lastUpdated = lastCompletedMilestone?.updatedOn;

  const isEmpty = !loading && !milestones.length;

  let Content: ReactNode = null;
  if (loading) {
    Content = <ApplicationLenderMilestonesSkeleton />;
  } else if (!isEmpty) {
    Content = (
      <ApplicationLenderMilestonesContainer
        milestones={milestones}
        onSave={onSave}
        canEditMilestones={canEditMilestones}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        lastUpdated={lastUpdated}
        loading={loading}
        onLosLoanStatusRefresh={canRefreshMilestones ? onLosLoanStatusRefresh : undefined}
        sequentialMilestones={sequentialLenderMilestones}
      />
    );
  }

  return (
    <StyledCard isEditing={isEditing}>
      <Container>
        <TitleCol>
          <StyledTitle level={2} cid="pageApplication:tabMilestones.lenderMilestones.title">
            LOS Milestones
          </StyledTitle>
        </TitleCol>
        {Content}
      </Container>
      {isEmpty ? <ApplicationLenderMilestonesEmpty /> : null}
    </StyledCard>
  );
};
