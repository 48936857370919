import { useQuery } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../apollo';

export const QJobFunctions = graphql(`
  query UseJobFunctions($siteId: ID!, $perPage: Int!, $cursor: String) {
    site(id: $siteId) {
      id
      jobFunctions(
        options: { perPage: $perPage, cursor: $cursor, orderBy: { primary: "desc", name: "asc" } }
      ) {
        items {
          id
          name
          primary
          createdAt
          updatedAt
        }
      }
    }
  }
`);

export const useJobFunctions = (siteId: string) => {
  const [data, ...rest] = useQuery(QJobFunctions, { siteId, perPage: 1000 });
  const nextData = data?.site?.jobFunctions.items;
  return [nextData, ...rest] as const;
};
