import { ModalDialog, notifications, useModal } from '@mortgagehippo/ds';
import { useSagaClickHandler } from '@mortgagehippo/tasks';
import { useCallback, useEffect } from 'react';

import { type IActionProps, type ISendApplicantInvitationAction } from '$components/actions';

import { useApplicationFileApplicant } from '../../../pages/application/use-application-file-applicant';
import { useSendApplicantInvitation } from '../../../pages/application/use-send-applicant-invitation';

export const SendApplicantInvitationAction = (
  props: IActionProps<ISendApplicantInvitationAction>
) => {
  const { action, onSubmit, onDone, onCancel } = props;
  const { applicationFileId, applicantId } = action;

  const [isOpen, , closeModal] = useModal(true);

  const [applicant, loading] = useApplicationFileApplicant(applicationFileId, applicantId);

  const sendApplicantInvitation = useSendApplicantInvitation();

  useEffect(() => {
    if (!loading && !applicant) {
      notifications.error({
        messageCid: 'sendApplicantInvitation:message.load.error',
        message: 'There was an unexpected error loading the applicant',
      });
      onCancel();
    }
  }, [applicant, loading, onCancel]);

  const [handleOk, running] = useSagaClickHandler({
    infoMessage: 'Sending borrower invitation',
    infoMessageCid: 'sendApplicantInvitation:message.send.info',
    successMessageCid: 'sendApplicantInvitation:message.send.success',
    errorMessageCid: 'sendApplicantInvitation:message.send.error',
    successMessage: 'The invitation was successfully sent.',
    errorMessage: 'There was an unexpected error sending the invitation.',
    async onClick(setAsyncId) {
      const nextAsyncId = await sendApplicantInvitation(applicantId);

      closeModal(() => {
        setAsyncId(nextAsyncId);
        onSubmit();
      });
    },
    async onDone() {
      closeModal(onDone);
    },
  });

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  if (loading || !applicant || running) {
    return null;
  }

  const name = applicant.name || `Borrower #${applicant.position + 1}`;
  const title = `Invite ${name}`;
  const explanation = `You are about to send an invite to ${applicant.email}.`;

  // TODO: use hook useModalConfirm
  return (
    <ModalDialog
      type="dialog"
      title={title}
      onOk={handleOk}
      onRequestClose={handleCloseModal}
      isOpen={isOpen}
      okButtonLabel="Send invite"
    >
      {explanation}
    </ModalDialog>
  );
};
