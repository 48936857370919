import { useSagaProgressNotification } from '@mortgagehippo/tasks';
import { useEffect, useRef, useState } from 'react';

import { type IActionProps, type IPushDocumentsToLosAction } from '../types';
import { useTriggerLosDocumentsPush } from './use-trigger-los-documents-push';

export const PushDocumentsToLosAction = (props: IActionProps<IPushDocumentsToLosAction>) => {
  const { action, onDone: _onDone } = props;
  const { applicationFileId, documentIds, losType } = action;

  const onDoneRef = useRef(_onDone);

  const [asyncId, setAsyncId] = useState<string | undefined>(undefined);

  const triggerLosDocumentPush = useTriggerLosDocumentsPush(losType);

  const [notification] = useSagaProgressNotification({
    asyncId,
    onDone: _onDone,
    errorMessageCid: 'los-push-document:error',
    errorMessage: 'There was an unexpected error pushing your documents to the LOS',
  });

  const running = !!asyncId;

  useEffect(() => {
    onDoneRef.current = _onDone;
  }, [_onDone]);

  useEffect(() => {
    if (running) {
      return;
    }

    (async () => {
      try {
        const nextAsyncId = await triggerLosDocumentPush(applicationFileId, documentIds);

        setAsyncId(nextAsyncId);
      } catch (e) {
        const onDone = onDoneRef.current;
        notification.error();
        onDone();
      }
    })();
  }, [applicationFileId, documentIds, notification, running, triggerLosDocumentPush]);

  return null;
};
