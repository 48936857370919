import { Alert, type ISearchFilter } from '@mortgagehippo/ds';
import { useCallback } from 'react';

import { ActionType, useDispatchAction } from '$components/actions';
import { useUserCan } from '$components/permissions';
import { ApplicationFilesSmartview } from '$components/smart-view';

import { SmartviewModelType } from '../../apollo/graphql';

const LOCAL_STORAGE_KEY = 'lp:dashboard-pipeline';

interface IDashboardPageContentProps {
  title: string;
}

export const DashboardPageContent = (props: IDashboardPageContentProps) => {
  const { title } = props;

  const [can, canReady] = useUserCan();

  const dispatch = useDispatchAction();

  const handleCreateFrom = useCallback(
    (filters?: ISearchFilter[], sortField?: string, sortDirection?: string) => {
      dispatch({
        type: ActionType.CREATE_SMARTVIEW,
        model: SmartviewModelType.ApplicationFile,
        initialValues: {
          filters,
          defaultSortField: sortField,
          defaultSortDirection: sortDirection,
        },
      });
    },
    [dispatch]
  );

  if (!canReady) {
    return null;
  }

  if (!can.VIEW_PIPELINE) {
    return <Alert type="info">Currently there are no items in your pipeline.</Alert>;
  }

  return (
    <ApplicationFilesSmartview
      ariaLabel={title}
      caption={title}
      localStorageKey={LOCAL_STORAGE_KEY}
      onCreateFrom={handleCreateFrom}
    />
  );
};
