import { useMutation } from '@mortgagehippo/apollo-hooks';
import { type ITableActions, notifications, useModal } from '@mortgagehippo/ds';
import {
  type IQueryTableColumns,
  type IQueryTableDataActions,
  QueryTable,
} from '@mortgagehippo/query-components';
import { UnreachableCaseError, useSessionStorage } from '@mortgagehippo/util';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { useUserCan } from '$components/permissions';
import { PrimaryLabel } from '$components/primary-label';
import { SiteSelect } from '$components/site-select';
import { SiteSelectSkeleton } from '$components/site-select-skeleton';

import { type JobFunctionsQuery, type JobFunctionsQueryVariables } from '../../../apollo/graphql';
import { useSites } from '../../../hooks/use-sites';
import { JobFunctionsModalContainer } from './job-functions-modal-container';
import {
  type IJobFunction,
  MDeleteJobFunction,
  MSetJobFunctionAsPrimary,
  QJobFunctions,
} from './queries';
import { UsersModalContainer } from './users-modal';

const TopContent = styled.div`
  max-width: 300px;
`;

enum JobFunctionsTabActionType {
  MAKE_PRIMARY = 'primary',
  EDIT = 'edit',
  DELETE = 'delete',
  USERS = 'users',
}

const columns: IQueryTableColumns<IJobFunction> = [
  {
    title: 'Role',
    key: 'role',
    render: ({ name, primary }) => (primary ? <PrimaryLabel size="xs">{name}</PrimaryLabel> : name),
  },
  {
    title: 'Users',
    key: 'users',
    render: (t) => t.agents.total,
  },
];

export const JobFunctionsTab = () => {
  const [siteId, setSiteId] = useSessionStorage('jobFunctionsTabSiteId', null);
  const [selectedJobFunction, setSelectedJobFunction] = useState<IJobFunction | undefined>();
  const [isOpen, openModal, closeModal] = useModal(false);
  const [usersModalIsOpen, openUsersModal, closeUsersModal] = useModal(false);

  const tableRef = useRef<any>();

  const [can] = useUserCan();

  const [sites, loading] = useSites();

  const setJobFunctionAsPrimary = useMutation(MSetJobFunctionAsPrimary);

  const deleteJobFunction = useMutation(MDeleteJobFunction);

  useEffect(() => {
    if (!siteId && !loading && sites && sites.length === 1) {
      setSiteId(sites[0]!.value);
    }
  }, [siteId, loading, sites, setSiteId]);

  const handleSiteChange = useCallback(
    (nextSiteId: string) => {
      setSiteId(nextSiteId);
    },
    [setSiteId]
  );

  const handleAdd = useCallback(() => {
    openModal();
  }, [openModal]);

  const handleEdit = useCallback(
    (record: IJobFunction) => {
      setSelectedJobFunction(record);
      openModal();
    },
    [openModal]
  );

  const handleSubmit = useCallback(() => {
    closeModal(() => {
      setSelectedJobFunction(undefined);
    });

    if (tableRef) {
      tableRef.current.refetch();
    }
  }, [closeModal]);

  const handleClose = useCallback(() => {
    closeModal(() => {
      setSelectedJobFunction(undefined);
    });
  }, [closeModal]);

  const handleUsers = useCallback(
    (record: IJobFunction) => {
      setSelectedJobFunction(record);
      openUsersModal();
    },
    [openUsersModal]
  );

  const handleUsersClose = useCallback(() => {
    closeUsersModal(() => {
      setSelectedJobFunction(undefined);
    });

    if (tableRef) {
      tableRef.current.refetch();
    }
  }, [closeUsersModal]);

  const handlePrimary = useCallback(
    async (record: IJobFunction) => {
      try {
        await setJobFunctionAsPrimary({
          jobFunctionId: record.id,
        });

        if (tableRef) {
          tableRef.current.refetch();
        }
      } catch (error) {
        notifications.error({
          message: 'There was an error processing your request, please try again later',
        });
      }
    },
    [setJobFunctionAsPrimary, tableRef]
  );

  const handleDelete = useCallback(
    async (record: IJobFunction) => {
      try {
        await deleteJobFunction({ jobFunctionId: record.id });

        notifications.success({ message: 'The role was deleted successfully.' });

        if (tableRef) {
          tableRef.current.refetch();
        }
      } catch (error) {
        notifications.error({
          message: 'There was an error processing your request, please try again later',
        });
      }
    },
    [deleteJobFunction, tableRef]
  );

  const handleRowClick = useCallback(
    (record: IJobFunction) => {
      if (can.UPDATE_JOB_FUNCTION) {
        handleEdit(record);
      }
    },
    [can.UPDATE_JOB_FUNCTION, handleEdit]
  );

  const handleAction = useCallback(
    async (actionKey: string, record: IJobFunction) => {
      const actionType = actionKey as JobFunctionsTabActionType;

      switch (actionType) {
        case JobFunctionsTabActionType.MAKE_PRIMARY:
          await handlePrimary(record);
          break;
        case JobFunctionsTabActionType.USERS:
          handleUsers(record);
          break;
        case JobFunctionsTabActionType.EDIT:
          handleEdit(record);
          break;
        case JobFunctionsTabActionType.DELETE:
          await handleDelete(record);
          break;
        default: {
          throw new UnreachableCaseError(actionType);
        }
      }
    },
    [handlePrimary, handleUsers, handleEdit, handleDelete]
  );

  const actions: IQueryTableDataActions = useMemo(
    () => [
      {
        key: 'options',
        label: 'Options',
        buttonProps: {
          icon: 'menu-dots',
        },
        onGroupAction: handleAction,
        actions: [
          {
            key: JobFunctionsTabActionType.MAKE_PRIMARY,
            label: 'Make Primary',
            iconProps: {
              name: 'star',
            },
            disabled: (record: any) => record.primary,
            confirm: {
              title: 'Warning',
              explanation: 'Are you sure you want to set this role as primary?',
              type: 'warning',
            },
            hidden: !can.UPDATE_JOB_FUNCTION,
          },
          {
            key: JobFunctionsTabActionType.USERS,
            label: 'Users',
            iconProps: {
              name: 'team',
            },
            hidden: !can.VIEW_JOB_FUNCTION_USERS,
          },
          {
            key: JobFunctionsTabActionType.EDIT,
            label: 'Edit',
            iconProps: {
              name: 'edit',
            },
            hidden: !can.UPDATE_JOB_FUNCTION,
          },
          {
            key: JobFunctionsTabActionType.DELETE,
            label: 'Delete',
            iconProps: {
              name: 'delete',
            },
            disabled: (record: any) => record.primary,
            confirm: {
              title: 'Warning',
              explanation:
                'Are you sure you want to delete this role? This action cannot be undone.',
              type: 'warning',
            },
            hidden: !can.DELETE_JOB_FUNCTION,
          },
        ],
      },
    ],
    [can, handleAction]
  );

  const topActions: ITableActions = useMemo(
    () => [
      {
        key: 'add-new-role',
        label: 'Add new role',
        buttonProps: {
          icon: 'plus',
        },
        hidden: !can.CREATE_JOB_FUNCTION,
        onAction: handleAdd,
      },
    ],
    [can.CREATE_JOB_FUNCTION, handleAdd]
  );

  const topContent = useMemo(
    () => (
      <TopContent>
        <SiteSelect.Input
          value={siteId}
          onChange={handleSiteChange}
          name="siteId"
          aria-label="Select site to see roles"
          placeholder="Select site to see roles"
          size="sm"
          compact
        />
      </TopContent>
    ),
    [siteId, handleSiteChange]
  );

  if (!siteId) {
    return <SiteSelectSkeleton onSiteChange={handleSiteChange} />;
  }

  return (
    <>
      <QueryTable<JobFunctionsQuery, JobFunctionsQueryVariables>
        caption="Job Functions"
        rowActions={actions}
        query={QJobFunctions}
        dataSource={(result) => (result.site ? result.site.jobFunctions.items : [])}
        itemTotal={(result) => (result.site ? result.site.jobFunctions.total : 0)}
        nextCursor={(result) => result.site?.jobFunctions.nextCursor}
        previousCursor={(result) => result.site?.jobFunctions.previousCursor}
        rowKey={(item) => item.id}
        columns={columns}
        variables={{ siteId }}
        onRowClick={handleRowClick}
        ref={tableRef}
        bottomContent={<QueryTable.Pagination />}
        topContent={topContent}
        topActions={topActions}
      >
        <QueryTable.Data />
      </QueryTable>
      <JobFunctionsModalContainer
        siteId={siteId}
        jobFunctionId={selectedJobFunction?.id || undefined}
        onRequestClose={handleClose}
        onSubmit={handleSubmit}
        isOpen={isOpen}
      />
      <UsersModalContainer
        siteId={siteId}
        jobFunctionId={selectedJobFunction?.id || undefined}
        title={(selectedJobFunction && `${selectedJobFunction.name} Users`) || undefined}
        onRequestClose={handleUsersClose}
        isOpen={usersModalIsOpen}
        width={800}
      />
    </>
  );
};
