import { UnreachableCaseError } from '@mortgagehippo/util';
import { cloneDeep, isNil } from 'lodash-es';
import * as uuid from 'uuid';

import { type IPartnerFolder } from '../../../../hooks/use-partner-folders';
import { ManagedFolder } from './managed-folder';
import {
  FolderActionType,
  type IAddFolderAction,
  type IAddFolderUsersAction,
  type IDeleteFolderAction,
  type IDeleteFolderUserAction,
  type IFolderAction,
  type IMoveFolderAction,
  type IRestoreFolderUserAction,
  type IUpdateFolderAction,
} from './types';

export type ManagedFolders = Map<string, ManagedFolder>;

type FolderManagerEvent = 'action' | 'undo' | 'discard';

type FolderManagerListener = (event: FolderManagerEvent, action?: IFolderAction) => void;

interface FolderUserData {
  id: string;
  name: string;
  email: string;
}

export class FolderManager {
  private folders: ManagedFolders;
  private actions: IFolderAction[] = [];
  private listeners: FolderManagerListener[] = [];

  public constructor(folders: IPartnerFolder[]) {
    this.folders = new Map();

    const clonedFolders = cloneDeep(folders);

    this.registerFolders(clonedFolders);
  }

  // instantiate the folder class objects and put them in a map
  private registerFolders(folders: IPartnerFolder[], parent?: ManagedFolder) {
    const children = folders.filter((folder) => folder.parentId === parent?.getId());

    children.forEach((folder) => {
      const { id, description, name } = folder;

      const managedFolder = new ManagedFolder(id, name, description, parent);

      this.folders.set(id, managedFolder);

      this.registerFolders(folders, managedFolder);
    });
  }

  public folderExists(id: string) {
    return this.folders.has(id);
  }

  public addFolder(name: string, description?: string | null, parentId?: string) {
    let parentFolder;
    if (!isNil(parentId)) {
      parentFolder = this.folders.get(parentId);

      if (!parentFolder) {
        return undefined;
      }
    }

    const folderId = uuid.v4();
    const folder = ManagedFolder.new(folderId, name, description, parentFolder);

    if (!folder) {
      return undefined;
    }

    this.folders.set(folderId, folder);

    const newAction: IAddFolderAction = {
      id: uuid.v4(),
      type: FolderActionType.ADD_FOLDER,
      folderId,
      prevData: null,
      nextData: {
        name,
        description,
        parentId,
      },
    };

    this.trackAction(newAction);

    return folder;
  }

  public updateFolder(id: string, name: string, description?: string | null) {
    const folder = this.folders.get(id);

    if (!folder) {
      return undefined;
    }

    const prevName = folder.getName();
    const prevDescription = folder.getDescription();

    const success = folder.edit(name, description);

    if (!success) {
      return undefined;
    }

    const newAction: IUpdateFolderAction = {
      id: uuid.v4(),
      type: FolderActionType.UPDATE_FOLDER,
      folderId: id,
      prevData: {
        name: prevName,
        description: prevDescription,
      },
      nextData: {
        name,
        description,
      },
    };

    this.trackAction(newAction);

    return folder;
  }

  public moveFolder(id: string, parentId?: string) {
    const folder = this.folders.get(id);

    if (!folder) {
      return undefined;
    }

    let parentFolder;
    if (!isNil(parentId)) {
      parentFolder = this.folders.get(parentId);

      if (!parentFolder) {
        return undefined;
      }
    }

    const prevParentId = folder.getParentId();

    const success = folder.moveTo(parentFolder);

    if (!success) {
      return undefined;
    }

    const newAction: IMoveFolderAction = {
      id: uuid.v4(),
      type: FolderActionType.MOVE_FOLDER,
      folderId: id,
      prevData: {
        parentId: prevParentId,
      },
      nextData: {
        parentId,
      },
    };

    this.trackAction(newAction);

    return folder;
  }

  public deleteFolder(id: string) {
    const folder = this.folders.get(id);

    if (!folder) {
      return undefined;
    }

    const deletePermanently = folder.canPermanentlyDelete();

    if (deletePermanently) {
      const parent = folder.getParent();
      if (parent) {
        parent.removeChild(id);
      }

      this.folders.delete(id);
    } else {
      const success = folder.softDelete();

      if (!success) {
        return undefined;
      }
    }

    const newAction: IDeleteFolderAction = {
      id: uuid.v4(),
      type: FolderActionType.DELETE_FOLDER,
      folderId: id,
      prevData: {
        name: folder.getName(),
        description: folder.getDescription(),
        parentId: folder.getParentId(),
      },
      nextData: null,
    };

    this.trackAction(newAction);

    return folder;
  }

  public registerFolderUser(folderId: string, data: FolderUserData) {
    const folder = this.folders.get(folderId);

    if (!folder) {
      return false;
    }

    const { id: userId, name, email } = data;
    return folder.registerExistingUser(userId, name, email);
  }

  public addFolderUsers(folderId: string, users: FolderUserData[]) {
    const folder = this.folders.get(folderId);

    if (!folder) {
      return undefined;
    }

    const dataUsers: any = [];
    users.forEach((user) => {
      const { id: userId, name, email } = user;
      folder.addUser(userId, name, email);
      dataUsers.push({ userId, name, email });
    });

    const newAction: IAddFolderUsersAction = {
      id: uuid.v4(),
      type: FolderActionType.ADD_USERS,
      folderId,
      prevData: null,
      nextData: {
        users: dataUsers,
      },
    };

    this.trackAction(newAction);

    return folder;
  }

  public deleteFolderUser(folderId: string, userId: string) {
    const folder = this.folders.get(folderId);

    if (!folder) {
      return undefined;
    }

    const user = folder.getUser(userId);

    if (!user) {
      return undefined;
    }

    const success = folder.deleteUser(userId);

    if (!success) {
      return undefined;
    }

    const newAction: IDeleteFolderUserAction = {
      id: uuid.v4(),
      type: FolderActionType.DELETE_USER,
      folderId,
      prevData: {
        userId,
        name: user.getName(),
        email: user.getEmail(),
      },
      nextData: null,
    };

    this.trackAction(newAction);

    return folder;
  }

  public restoreFolderUser(folderId: string, userId: string) {
    const folder = this.folders.get(folderId);

    if (!folder) {
      return undefined;
    }

    const success = folder.restoreUser(userId);

    if (!success) {
      return undefined;
    }

    const newAction: IRestoreFolderUserAction = {
      id: uuid.v4(),
      type: FolderActionType.RESTORE_USER,
      folderId,
      prevData: {
        userId,
      },
      nextData: {
        userId,
      },
    };

    this.trackAction(newAction);

    return folder;
  }

  public undo() {
    if (this.actions.length === 0) {
      return undefined;
    }

    const action = this.actions.pop();

    if (!action) {
      return undefined;
    }

    this.undoAction(action);

    this.publish('undo', action);

    return action;
  }

  public discardChanges() {
    if (this.actions.length === 0) {
      return;
    }

    while (this.actions.length) {
      const action = this.actions.pop();

      if (action) {
        this.undoAction(action);
      }
    }

    this.publish('discard');
  }

  private undoAction(action: IFolderAction) {
    const { type, folderId } = action;

    switch (type) {
      case FolderActionType.ADD_FOLDER: {
        const folder = this.folders.get(folderId);

        if (!folder) {
          return;
        }

        const parent = folder.getParent();
        if (parent) {
          parent.removeChild(folderId);
        }

        this.folders.delete(folderId);
        break;
      }
      case FolderActionType.UPDATE_FOLDER: {
        const { prevData } = action as IUpdateFolderAction;

        const folder = this.folders.get(folderId);

        if (!folder) {
          return;
        }

        const { name, description } = prevData;

        folder.edit(name, description);
        break;
      }
      case FolderActionType.DELETE_FOLDER: {
        const folder = this.folders.get(folderId);

        if (!folder) {
          // was permanently deleted, we must re-add
          const { prevData } = action as IDeleteFolderAction;
          const { name, description, parentId } = prevData!;

          let parentFolder;
          if (!isNil(parentId)) {
            parentFolder = this.folders.get(parentId);

            if (!parentFolder) {
              return;
            }
          }

          const newFolder = ManagedFolder.new(folderId, name, description, parentFolder);

          if (!newFolder) {
            return;
          }

          this.folders.set(folderId, newFolder);
        } else {
          // was soft deleted
          folder.restore();
        }
        break;
      }
      case FolderActionType.MOVE_FOLDER: {
        const folder = this.folders.get(folderId);

        if (!folder) {
          return;
        }

        const { prevData } = action as IMoveFolderAction;
        const { parentId } = prevData;

        let nextParent;
        if (!isNil(parentId)) {
          nextParent = this.folders.get(parentId);

          if (!nextParent) {
            return;
          }
        }

        folder.moveTo(nextParent);
        break;
      }
      case FolderActionType.ADD_USERS: {
        const folder = this.folders.get(folderId);

        if (!folder) {
          return;
        }

        const { nextData } = action as IAddFolderUsersAction;
        const { users } = nextData;

        users.forEach((user) => {
          const { userId } = user;
          folder.deleteUser(userId);
        });

        break;
      }
      case FolderActionType.DELETE_USER: {
        const folder = this.folders.get(folderId);

        if (!folder) {
          return;
        }

        const { prevData } = action as IDeleteFolderUserAction;
        const { userId, name, email } = prevData;

        const user = folder.getUser(userId);

        if (!user) {
          // was permanently deleted, we must re-add
          folder.addUser(userId, name, email);
        } else {
          // was soft deleted
          folder.restoreUser(userId);
        }
        break;
      }
      case FolderActionType.RESTORE_USER: {
        const folder = this.folders.get(folderId);

        if (!folder) {
          return;
        }

        const { prevData } = action as IRestoreFolderUserAction;
        const { userId } = prevData;

        folder.deleteUser(userId);
        break;
      }
      default:
        throw new UnreachableCaseError(type);
    }
  }

  private trackAction(action: IFolderAction) {
    this.actions.push(action);

    this.publish('action', action);
  }

  private publish(event: FolderManagerEvent, action?: IFolderAction) {
    this.listeners.forEach((fn) => {
      fn(event, action);
    });
  }

  public on(fn: (event: FolderManagerEvent, action?: IFolderAction) => void) {
    this.listeners.push(fn);

    return () => {
      this.listeners = this.listeners.filter((func) => func !== fn);
    };
  }

  public getTotalActions() {
    return this.actions.length;
  }

  public toArray() {
    return cloneDeep(Array.from(this.folders.values()));
  }
}
