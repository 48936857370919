import { useCustomizations } from '@mortgagehippo/ds';
import { type ReactElement, useCallback, useMemo, useState } from 'react';

import { ApplicationOverviewContext } from './application-overview-context';
import { type IApplicationOverviewSection as IApplicationOverviewBaseSection } from './types';

interface IApplicationOverviewSection extends IApplicationOverviewBaseSection {
  ready?: boolean;
  disabled?: boolean;
}

interface IApplicationOverviewProviderProps {
  sections: IApplicationOverviewBaseSection[];
  children: (sections: IApplicationOverviewSection[], ready: boolean) => ReactElement;
}

export const ApplicationOverviewProvider = (props: IApplicationOverviewProviderProps) => {
  const { sections: initialSections, children: childrenFn } = props;

  const [sections, setSections] = useState<Array<IApplicationOverviewSection>>(initialSections);

  const customizations = useCustomizations();

  const customizedSections = sections.filter((section) => {
    if (!section.cid) {
      return true;
    }

    const isDisabled = customizations.bool(section.cid, false);

    return !isDisabled;
  });

  const customizationsReady = customizations.ready();

  const ready = customizationsReady && customizedSections.every((s) => !!s.ready || !!s.disabled);

  const sectionReady = useCallback((id: string) => {
    setSections((prevSections) =>
      prevSections.map((s) => {
        if (s.id !== id) {
          return s;
        }

        return {
          ...s,
          ready: true,
        };
      })
    );
  }, []);

  const sectionDisabled = useCallback((id: string) => {
    setSections((prevSections) =>
      prevSections.map((s) => {
        if (s.id !== id) {
          return s;
        }

        return {
          ...s,
          disabled: true,
        };
      })
    );
  }, []);

  const value = useMemo(
    () => ({
      sectionReady,
      sectionDisabled,
      ready,
    }),
    [sectionReady, sectionDisabled, ready]
  );

  const children = childrenFn(customizationsReady ? customizedSections : [], ready);

  return (
    <ApplicationOverviewContext.Provider value={value}>
      {children}
    </ApplicationOverviewContext.Provider>
  );
};
