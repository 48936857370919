import { type IErrorComponentProps } from '@mortgagehippo/util';
import styled from 'styled-components';

import { GeneralError } from '$components/general-error';

const StyledGeneralError = styled(GeneralError)`
  min-height: 100vh;
`;

export const ErrorPage = (props: IErrorComponentProps) => (
  <StyledGeneralError {...props} size="normal" />
);
