import { type FormSubmitHandler, Input, ModalForm, TextArea } from '@mortgagehippo/ds';

interface ISendPreQualificationLetterProps {
  isOpen: boolean;
  title: string;
  onSubmit: FormSubmitHandler;
  onRequestClose: () => void;
  width?: number;
  initialValues?: any;
  loading: boolean;
}

export const SendPreQualificationLetterModal = (props: ISendPreQualificationLetterProps) => {
  const { title, onRequestClose, onSubmit, isOpen, width, initialValues, loading } = props;

  return (
    <ModalForm
      title={title}
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      width={width}
      okButtonLabel="Send"
      onSubmit={onSubmit}
      initialValues={initialValues}
      loading={loading}
    >
      <Input.Box name="title" label="Task Title" labelCid="custom-task:task-title" required />
      <TextArea.Box
        name="description"
        label="Instructions for borrower"
        labelCid="custom-task:instructions-for-borrower"
        required
      />
    </ModalForm>
  );
};
