import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo';
import { type DocumentInput } from '../../apollo/graphql';

export const SubmitDocuments = graphql(`
  mutation ISubmitDocuments($taskId: ID!, $documents: [DocumentInput!]!) {
    submitDocuments(taskId: $taskId, documents: $documents) {
      id
    }
  }
`);

export const useSubmitDocuments = () => {
  const submitDocument = useMutation(SubmitDocuments);

  return useCallback(
    async (taskId: string, documents: DocumentInput[]) => {
      const response = await submitDocument({
        taskId,
        documents,
      });

      return response.data!.submitDocuments;
    },
    [submitDocument]
  );
};
