import { Card, Empty, spacing } from '@mortgagehippo/ds';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  padding: ${spacing(6)};
`;

export const ApplicationLenderMilestonesEmpty = () => (
  <StyledCard>
    <Empty>No lender milestones available.</Empty>
  </StyledCard>
);
