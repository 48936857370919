import { UnreachableCaseError } from '@mortgagehippo/util';

import { LosType } from '../../../apollo/graphql';
import { useTriggerByteDocumentPush } from './use-trigger-byte-document-push';
import { useTriggerEncompassDocumentsPush } from './use-trigger-encompass-documents-push';
import { useTriggerLendingQbDocumentPush } from './use-trigger-lending-qb-document-push';
import { useTriggerMortgageDirectorDocumentPush } from './use-trigger-mortgage-director-document-push';
import { useTriggerMortgageBotDocumentPush } from './use-trigger-mortgagebot-document-push';
import { useTriggerMortgageflexDocumentPush } from './use-trigger-mortgageflex-document-push';

export const useTriggerLosDocumentPush = (losType: LosType) => {
  const byteFn = useTriggerByteDocumentPush();
  const encompassFn = useTriggerEncompassDocumentsPush();
  const mortgageBotFn = useTriggerMortgageBotDocumentPush();
  const mortgageDirectorFn = useTriggerMortgageDirectorDocumentPush();
  const mortgageflexFn = useTriggerMortgageflexDocumentPush();
  const lendingQbFn = useTriggerLendingQbDocumentPush();

  switch (losType) {
    case LosType.Byte:
      return byteFn;
    case LosType.LendingQb:
      return lendingQbFn;
    case LosType.EncompassApi:
      return encompassFn;
    case LosType.MortgageBot:
      return mortgageBotFn;
    case LosType.MortgageDirector:
      return mortgageDirectorFn;
    case LosType.MortgageFlex:
      return mortgageflexFn;
    default:
      throw new UnreachableCaseError(losType);
  }
};
