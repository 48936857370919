import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { NotFoundError, useSafeCallback } from '@mortgagehippo/util';
import { compact, orderBy } from 'lodash-es';
import { useMemo } from 'react';

import { graphql } from '../../apollo';
import { type ApplicationFileDataQuery } from '../../apollo/graphql';

export const QApplicationFileData = graphql(`
  query ApplicationFileData($applicationFileId: ID!) {
    applicationFile(id: $applicationFileId) {
      ...ApplicationFileDataFragment
    }
  }
`);

export type IApplicationFile = NonNullable<ApplicationFileDataQuery['applicationFile']>;
type IApplicationFileDataQueryApplicant = NonNullable<
  ApplicationFileDataQuery['applicationFile']
>['applicants']['items'][0];

export interface IApplicationApplicant extends IApplicationFileDataQueryApplicant {
  name: string;
  primary: boolean;
}

interface IUseApplicationFileResultData {
  applicationFile?: IApplicationFile | null;
  applicants: IApplicationApplicant[];
  primaryApplicantId?: string | null;
  loading: boolean;
}

export type IUseApplicationFileResult = [IUseApplicationFileResultData, () => Promise<any>];

export const useApplicationFile = (
  applicationFileId: string,
  options: IUseApolloQueryOptions = {}
): IUseApplicationFileResult => {
  const [data, loading, error, { refetch }] = useQuery(
    QApplicationFileData,
    { applicationFileId },
    {
      fetchPolicy: 'network-only',
      ...options,
      throw: false,
    }
  );

  if (error) {
    throw new NotFoundError(error.message);
  }

  const handleRefetch = useSafeCallback(() => refetch({ applicationFileId }));

  const result = useMemo(() => {
    if (loading) {
      return {
        applicationFile: undefined,
        applicants: [],
        primaryApplicantId: undefined,
        loading,
      };
    }

    if (!data?.applicationFile?.primaryApplicant) {
      return {
        applicationFile: null,
        applicants: [],
        primaryApplicantId: null,
        loading,
      };
    }

    const { applicationFile } = data;
    const primaryApplicantId = data.applicationFile.primaryApplicant.id;

    const sortedApplicants = orderBy(applicationFile.applicants.items, ['position']);

    // lets apply useful transformations on the applicants here
    const applicants: IApplicationApplicant[] = compact(
      sortedApplicants.map((applicant, index) => {
        const { id } = applicant;
        const name = applicant.name || `Borrower #${index + 1}`;

        return {
          ...applicant,
          name,
          primary: id === primaryApplicantId,
        };
      })
    );

    return {
      applicationFile,
      applicants,
      primaryApplicantId,
      loading,
    };
  }, [data, loading]);

  return [result, handleRefetch];
};
