import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../apollo';
import { type UpdateApplicantBorrowerInformationInput } from '../../../apollo/graphql';

export const MUpdateApplicantBorrowerInformation = graphql(`
  mutation UpdateApplicantBorrowerInformation(
    $applicantId: ID!
    $data: UpdateApplicantBorrowerInformationInput!
  ) {
    updateApplicantBorrowerInformation(applicantId: $applicantId, data: $data)
  }
`);

export const useUpdateApplicantBorrowerInformation = () => {
  const updateApplicantBorrowerInformation = useMutation(
    MUpdateApplicantBorrowerInformation,
    undefined
  );

  return useCallback(
    async (applicantId: string, data: UpdateApplicantBorrowerInformationInput) => {
      const response = await updateApplicantBorrowerInformation({ applicantId, data });
      return response.data!.updateApplicantBorrowerInformation;
    },
    [updateApplicantBorrowerInformation]
  );
};
