import { Alert, notifications, T, useOpenClose } from '@mortgagehippo/ds';
import {
  DEFAULT_PRICING_SORT_ORDER,
  type IPricingLoan,
  type IPricingScenario,
  type IPricingSortOption,
  isPricingLoanLocked,
  isPricingLoanPollable,
  PricingContent,
  PricingLoadingSpinner,
  PricingSortSelect,
  useApplicationFilePricingScenario,
  useGeneratePricingBuyDownOptions,
  useGeneratePricingFees,
  useGeneratePricingLoans,
  useLockPricingLoan,
  useSelectedPricingLoan,
  useSelectPricingLoan,
} from '@mortgagehippo/tasks';
import { useLocalStorage, useMountedRef } from '@mortgagehippo/util';
import { isEqualWith, isNil } from 'lodash-es';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import styled from 'styled-components';

import { useApplicationFileCan } from '$components/permissions';

import { Content, Layout, Nav } from '../../../layouts/application';
import { ApplicationPricingForm } from './application-pricing-form';
import { ApplicationPricingFormSkeleton } from './application-pricing-form-skeleton';
import { toInitialValues, toServerInput } from './util';

const POLLING_INTERVAL = 30000;

const StyledAlert = styled(Alert)`
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
`;

const isEqualCustomizer = (objValue: any, otherValue: any) => {
  if (isNil(objValue) && isNil(otherValue)) {
    return true;
  }

  return undefined;
};

interface IApplicationPricingState {
  loansLoading: boolean;
  pricingLoans?: IPricingLoan[];
  customScenario?: IPricingScenario;
  submitting?: boolean;
  loansError: any;
  sortOrderValue: string;
}

const reducer = (
  previousState: IApplicationPricingState,
  action: any
): IApplicationPricingState => {
  switch (action.type) {
    case 'LOADING':
      return { ...previousState, loansLoading: true };
    case 'LOADED':
      return {
        ...previousState,
        loansLoading: false,
        pricingLoans: action.pricingLoans,
        customScenario: action.customScenario,
        submitting: false,
        loansError: undefined,
      };
    case 'LOADING_ERROR':
      return {
        ...previousState,
        loansLoading: false,
        submitting: false,
        loansError: action.loansError,
      };
    case 'SUBMITTING':
      return {
        ...previousState,
        submitting: true,
      };
    case 'SORT_CHANGE':
      return {
        ...previousState,
        sortOrderValue: action.sortOrderValue,
      };
    default:
      throw new Error(`Unknown action ${action.type}`);
  }
};

interface IApplicationPricingProps {
  applicationFileId: string;
}

export const ApplicationPricing = (props: IApplicationPricingProps) => {
  const { applicationFileId } = props;

  const [storedSortOrderValue, setStoredSortOrderValue] = useLocalStorage(
    'lp:pricing-sort-order',
    DEFAULT_PRICING_SORT_ORDER
  );

  const [isMobileNavOpen, openMobileNav, closeMobileNav] = useOpenClose(false);

  const mounted = useMountedRef();

  const [state, dispatch] = useReducer(reducer, {
    loansLoading: true,
    pricingLoans: undefined,
    customScenario: undefined,
    loansError: undefined,
    sortOrderValue: storedSortOrderValue || DEFAULT_PRICING_SORT_ORDER,
  });

  const { loansLoading, pricingLoans, customScenario, loansError, sortOrderValue, submitting } =
    state;

  const [
    selectedPricingLoan,
    selectedPricingLoanLoading,
    { startPolling, stopPolling, refetch: refetchSelectedPricingLoan },
  ] = useSelectedPricingLoan(applicationFileId);

  const skipScenario = loansLoading;
  // generating the loans does a compile so we are gonna wait until that is done
  const [initialScenario, scenarioLoading, applicationFile] = useApplicationFilePricingScenario(
    applicationFileId,
    {
      skip: skipScenario,
    }
  );

  const scenarioLoadingOrSkipped = skipScenario || scenarioLoading;

  const [can] = useApplicationFileCan(applicationFileId);

  const selectPricingLoan = useSelectPricingLoan();
  const lockPricingLoan = useLockPricingLoan();
  const generatePricingLoans = useGeneratePricingLoans();
  const generatePricingFees = useGeneratePricingFees();
  const generatePricingBuyDownOptions = useGeneratePricingBuyDownOptions();

  const allowProductSelect = !customScenario && can.SELECT_PRICING_OPTION;
  const allowRateLock =
    !customScenario &&
    !!applicationFile &&
    !!applicationFile.rateLockable &&
    can.LOCK_PRICING_OPTION;

  const { productId: selectedProductId, lockStatus } = selectedPricingLoan || {};

  const pricingScenario: IPricingScenario | undefined = useMemo(() => {
    if (customScenario) {
      return customScenario;
    }

    if (!initialScenario) {
      return undefined;
    }

    const {
      calculated_loan_amount: loanAmount = 0,
      down_payment: downPayment = 0,
      cash_out_amount: cashOutAmount = 0,
      collateral = {},
    } = initialScenario;

    const {
      property_type_name: propertyType,
      city_name: propertyCity,
      postal_code: propertyZip,
      estimated_value: propertyEstimatedValue,
    } = collateral;

    const propertyPrice = propertyEstimatedValue || loanAmount + downPayment;

    return {
      loanAmount,
      downPayment,
      cashOutAmount,
      propertyType,
      propertyCity,
      propertyZip,
      propertyPrice,
    };
  }, [customScenario, initialScenario]);

  const initialValues = useMemo(() => {
    if (!initialScenario) {
      return {};
    }

    return toInitialValues(initialScenario);
  }, [initialScenario]);

  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        const input = toServerInput(values);
        const initialInput = toServerInput(initialValues);
        const hasChanges = !isEqualWith(input, initialInput, isEqualCustomizer);

        const scenario = hasChanges ? input : undefined;

        dispatch({ type: 'SUBMITTING' });

        const result = await generatePricingLoans(applicationFileId, scenario);

        if (!mounted.current) {
          return;
        }

        const { loanAmount = 0, downPayment = 0, cashOutAmount = 0 } = input || {};

        const nextCustomScenario: IPricingScenario = {
          loanAmount: loanAmount || undefined,
          downPayment: downPayment || undefined,
          cashOutAmount: cashOutAmount || undefined,
        };

        dispatch({
          type: 'LOADED',
          pricingLoans: result,
          customScenario: hasChanges ? nextCustomScenario : undefined,
        });
      } catch (e) {
        if (!mounted.current) {
          return;
        }

        notifications.error({
          message: 'There was an error generating pricing options, please try again later',
          messageCid: 'pageApplication:tabLoanPricer.pricingGenerator.error.message',
        });
      }
    },
    [applicationFileId, generatePricingLoans, initialValues, mounted]
  );

  const handleSelectLoan = useCallback(
    async (pricingLoan: IPricingLoan) => {
      try {
        await selectPricingLoan(applicationFileId, pricingLoan.id);

        await refetchSelectedPricingLoan();
      } catch (e) {
        notifications.error({
          message: 'There was an error selecting the price option, please try again later',
        });
      }
    },
    [applicationFileId, refetchSelectedPricingLoan, selectPricingLoan]
  );

  const handleLockLoan = useCallback(
    async (pricingLoan: IPricingLoan) => {
      try {
        await lockPricingLoan(applicationFileId, pricingLoan.id);

        await refetchSelectedPricingLoan();

        dispatch({
          type: 'LOADED',
          pricingLoans: [pricingLoan],
          customScenario: undefined,
        });

        notifications.success({ message: 'The rate lock request was successfully submitted.' });
      } catch (e) {
        notifications.error({ message: 'The rate lock request was denied.' });
      }
    },
    [applicationFileId, lockPricingLoan, refetchSelectedPricingLoan]
  );

  const handleRequestFees = useCallback(
    async (pricingLoan: IPricingLoan) => generatePricingFees(pricingLoan.id),
    [generatePricingFees]
  );

  const handleRequestBuyDownOptions = useCallback(
    async (pricingLoan: IPricingLoan) =>
      generatePricingBuyDownOptions(applicationFileId, pricingLoan.id),
    [applicationFileId, generatePricingBuyDownOptions]
  );

  const handleSortChange = useCallback(
    (newValue: string) => {
      setStoredSortOrderValue(newValue);
      dispatch({ type: 'SORT_CHANGE', sortOrderValue: newValue });
    },
    [setStoredSortOrderValue]
  );

  useEffect(() => {
    (async function fetchInitialLoans() {
      try {
        dispatch({ type: 'LOADING' });

        const result = await generatePricingLoans(applicationFileId);

        if (!mounted.current) {
          return;
        }

        dispatch({
          type: 'LOADED',
          pricingLoans: result,
          customScenario: undefined,
        });
      } catch (e) {
        if (!mounted.current) {
          return;
        }

        dispatch({ type: 'LOADING_ERROR', loansError: e });
      }
    })();
  }, [applicationFileId, generatePricingLoans, mounted]);

  const [selectedProductPresent, selectedProductLocked] = useMemo(() => {
    const nextSelectedProductPresent =
      !!pricingLoans &&
      !!selectedProductId &&
      pricingLoans.some((l) => l.productId === selectedProductId);

    const nextSelectedProductLocked =
      nextSelectedProductPresent && !!lockStatus && isPricingLoanLocked(lockStatus);

    return [nextSelectedProductPresent, nextSelectedProductLocked];
  }, [lockStatus, pricingLoans, selectedProductId]);

  useEffect(() => {
    const shouldPoll = selectedProductPresent && !!lockStatus && isPricingLoanPollable(lockStatus);

    if (shouldPoll) {
      startPolling(POLLING_INTERVAL);
    }

    return () => {
      if (shouldPoll) {
        stopPolling();
      }
    };
  }, [lockStatus, selectedProductPresent, startPolling, stopPolling]);

  const contentLoading = scenarioLoadingOrSkipped || loansLoading || selectedPricingLoanLoading;

  const disableForm = contentLoading || selectedProductLocked;

  const [sortOrder, sortDirection] = sortOrderValue.split(':') as [
    IPricingSortOption,
    'asc' | 'desc',
  ];

  const navEl = scenarioLoadingOrSkipped ? (
    <ApplicationPricingFormSkeleton />
  ) : (
    <ApplicationPricingForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      disabled={disableForm}
    />
  );

  let contentEl;
  if (contentLoading) {
    contentEl = <PricingLoadingSpinner />;
  } else {
    contentEl = loansError ? (
      <StyledAlert type="error">
        <T cid="pageApplication:tabLoanPricer.pricingGenerator.error.message">
          There was an error generating the pricing options.
        </T>
      </StyledAlert>
    ) : (
      <>
        <PricingSortSelect value={sortOrderValue} onChange={handleSortChange} />
        {submitting ? <PricingLoadingSpinner /> : null}
        <PricingContent
          pricingLoans={pricingLoans || []}
          pricingScenario={pricingScenario}
          onRequestFees={handleRequestFees}
          onRequestBuyDownOptions={handleRequestBuyDownOptions}
          selectedLoan={selectedPricingLoan}
          onSelectLoan={handleSelectLoan}
          onLockLoan={handleLockLoan}
          allowProductSelect={allowProductSelect}
          showMissingLoanDetails
          allowBuyDownOptions
          allowRateLock={allowRateLock}
          submitting={submitting}
          showLockErrors
          sortOrder={sortOrder}
          sortDirection={sortDirection}
        />
      </>
    );
  }

  return (
    <Layout applicationFileId={applicationFileId}>
      <Nav
        mobileOpen={isMobileNavOpen}
        onMobileOpen={openMobileNav}
        onMobileClose={closeMobileNav}
        mobileNavTitle="Filters"
        mobileNavIcon="filter"
        width="320px"
      >
        {navEl}
      </Nav>
      <Content>{contentEl}</Content>
    </Layout>
  );
};
