import { Title } from '@mortgagehippo/ds';
import { type RouteComponentProps } from 'react-router';

import { Content, Layout } from '../../../layouts/application';
import { ApplicationDocumentsContentContainer } from './application-documents-content-container';

interface IApplicationDocumentsParams {
  applicationFileId: string;
}

export const ApplicationDocumentsRoute = (
  props: RouteComponentProps<IApplicationDocumentsParams>
) => {
  const { match } = props;
  const { params } = match;
  const { applicationFileId } = params;

  return (
    <Layout applicationFileId={applicationFileId} tutorialId="documents">
      <Content>
        <Title level={1} cid="pageApplication:menu.tabs.documents.label">
          Documents
        </Title>
        <ApplicationDocumentsContentContainer applicationFileId={applicationFileId} />
      </Content>
    </Layout>
  );
};
