/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  createLayout,
  createSlot,
  type IconNames,
  type ITemplateProps,
  MediaBreakpoint,
  palette,
  Popover,
  Responsive,
  spacing,
  useCustomizations,
  useOpenClose,
  useResponsive,
} from '@mortgagehippo/ds';
import { ErrorBoundary } from '@mortgagehippo/util';
import { type ReactNode, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { GeneralError } from '$components/general-error';
import { GlobalScripts } from '$components/global-scripts';

import { useLocation } from '../../hooks/use-location';
import { ApplicationDrawer } from './application-drawer';
import { ApplicationTutorialButton } from './application-tutorial-button';
import { LAYOUT_DIMENSIONS, MAIN_CONTENT_ID } from './constants';

export const BodyComponent = styled('div')`
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  height: 100%;

  ${MediaBreakpoint.PHONE} {
    display: block;
    overflow: visible;
    height: auto;
    min-height: 100vh;
  }
`;

const NavOuter = styled('div')<{ navWidth?: string }>`
  /* this needs to be a separate div than NavComponent since a scroll div that is also flex hides the scrollbar */
  flex: 0 0 ${(p) => p.navWidth || '250px'};
  max-width: ${(p) => p.navWidth || '250px'};
  height: 100%;
  z-index: 3;
`;

const NavInner = styled('nav')`
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: ${palette('neutral50')};
  border-right: 1px solid ${palette('neutral200')};
  // prettier-ignore
  padding: ${spacing(LAYOUT_DIMENSIONS.sideNavInnerPaddingSpacing.top)} ${spacing(
    LAYOUT_DIMENSIONS.sideNavInnerPaddingSpacing.horizontal
  )} ${spacing(LAYOUT_DIMENSIONS.sideNavInnerPaddingSpacing.bottom)};
  box-sizing: border-box;

  ${MediaBreakpoint.PHONE} {
    height: auto;
    max-height: calc(100vh - 220px);
    border: none;
    background: ${palette('white')};
    // prettier-ignore
    width: calc(100vw - 2 * ${spacing(4)}); /* 2 * the styled popover padding padding */
  }
`;

const Scroller = styled('main')``;

const ContentFlex = styled('div')`
  // this needs to be a separate div than ContentComponent since a scroll div that is also flex hides the scrollbar
  flex: 1 1 100%;
  z-index: 2;

  ${MediaBreakpoint.PHONE} {
    flex-basis: auto;
  }

  ${Scroller} {
    padding: ${spacing(5)} ${spacing(7)};
    background: ${palette('neutral50')};
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    height: 100%;

    ${MediaBreakpoint.PHONE} {
      padding: ${spacing(2)} ${spacing(4)};
      overflow: visible;
      height: auto;
      min-height: 100vh;
    }
  }

  &:first-child ${Scroller} {
    /* when no navigation is present */
    padding-left: ${spacing(6)};
    padding-right: ${spacing(6)};
  }
`;

const ContentComponent = styled('div')`
  min-height: 100%;
  padding-bottom: ${spacing(9)};

  ${MediaBreakpoint.PHONE} {
    min-height: 100vh;
  }
`;

const StyledPopover = styled(Popover)`
  && {
    padding: ${spacing(4)};
    display: block;

    button {
      display: inline-flex;
      align-items: center;
      padding-left: ${spacing(4)};
      padding-right: ${spacing(4)};

      > span {
        flex: 1 1 auto;
      }

      > svg {
        flex: 0 0 auto;
      }
    }
  }
`;

const MobileNavWrap = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: ${spacing(1)};

  & > *:first-child {
    flex: 1 1 auto;
  }
`;

const NavComponent = ({
  children,
  width,
  mobileOpen,
  onMobileOpen,
  onMobileClose,
  mobileNavTitle,
  mobileNavIcon,
}: {
  width?: string;
  mobileOpen: boolean;
  onMobileOpen: () => void;
  onMobileClose: () => void;
  mobileNavTitle?: ReactNode;
  mobileNavIcon?: IconNames;
  children?: ReactNode;
}) => {
  const [isOpen, openNav, closeNav] = useOpenClose(mobileOpen);

  useEffect(() => {
    // avoid doing an action if open === undefined
    if (mobileOpen) {
      openNav();
    } else if (!mobileOpen) {
      closeNav();
    }
  }, [mobileOpen, closeNav, openNav]);

  const { hash } = useLocation();

  const refFirstLoad = useRef(true);

  useEffect(() => {
    if (refFirstLoad.current) {
      refFirstLoad.current = false;
    } else {
      closeNav();
    }
  }, [hash, closeNav]);

  const handleCloseNav = useCallback(() => {
    closeNav();
    onMobileClose();
  }, [closeNav, onMobileClose]);

  const handleOpenNav = useCallback(() => {
    openNav();
    onMobileOpen();
  }, [openNav, onMobileOpen]);

  return (
    <Responsive>
      <Responsive.Phone>
        <StyledPopover
          content={<NavInner>{children}</NavInner>}
          buttonProps={{
            icon: (isOpen && 'close') || mobileNavIcon || 'menu',
            iconLocation: 'right',
            compact: true,
            importance: 'secondary',
            block: true,
            size: 'normal',
            align: 'left',
          }}
          align="BottomLeft"
          forceAlign
          borderless
          open={isOpen}
          onHide={handleCloseNav}
          onShow={handleOpenNav}
          maxWidthCSS="none"
        >
          {mobileNavTitle || '---'}
        </StyledPopover>
      </Responsive.Phone>
      <Responsive.Desktop>
        <NavOuter navWidth={width}>
          <NavInner>{children}</NavInner>
        </NavOuter>
      </Responsive.Desktop>
    </Responsive>
  );
};

const TutorialFixed = styled.div`
  position: fixed;
  bottom: ${spacing(2)};
  right: 21px;
  z-index: 20;
`;

const Template = (
  props: ITemplateProps & {
    applicationFileId?: string;
    tutorialId?: string;
  }
) => {
  const { tutorialId, applicationFileId } = props;
  const location = useLocation();
  const contentRef = useRef<HTMLDivElement>(null);
  const responsive = useResponsive();

  const customizations = useCustomizations();
  const chatDisabled = customizations.bool('app:chat.disabled', true);
  const notesDisabled = customizations.bool('app:notes.disabled', true);
  const screenshareDisabled = customizations.bool('app:screenshare.disabled', false);

  useEffect(() => {
    if (responsive.PHONE.EXACT_OR_SMALLER) {
      window.scrollTo(0, 0);
    } else if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [location.hash, responsive.PHONE.EXACT_OR_SMALLER]);

  return (
    <>
      <GlobalScripts />
      <BodyComponent>
        <Responsive>
          <Responsive.Phone>
            <MobileNavWrap>
              <Nav.Slot />
            </MobileNavWrap>
          </Responsive.Phone>
          <Responsive.Desktop>
            <Nav.Slot />
          </Responsive.Desktop>
        </Responsive>
        <ContentFlex>
          <ErrorBoundary errorComponent={GeneralError}>
            <Scroller ref={contentRef}>
              <Content.Slot id={MAIN_CONTENT_ID} />
            </Scroller>
          </ErrorBoundary>
        </ContentFlex>

        <Responsive>
          <Responsive.Desktop>
            {applicationFileId && (!chatDisabled || !notesDisabled || !screenshareDisabled) ? (
              <ApplicationDrawer
                applicationFileId={applicationFileId}
                chatEnabled={!chatDisabled}
                notesEnabled={!notesDisabled}
                screenshareEnabled={!screenshareDisabled}
              />
            ) : null}
            <TutorialFixed>
              <ApplicationTutorialButton tutorialId={tutorialId} />
            </TutorialFixed>
          </Responsive.Desktop>
        </Responsive>
      </BodyComponent>
    </>
  );
};

export const Layout = createLayout(Template);
export const Nav = createSlot(Layout, NavComponent);
export const Content = createSlot(Layout, ContentComponent);
