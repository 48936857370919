import {
  Checkbox,
  FORM_ERROR,
  FormError,
  type FormSubmitHandler,
  type IModalFormProps,
  Input,
  type ISearchFields,
  MediaBreakpoint,
  ModalForm,
  palette,
  spacing,
  Title,
} from '@mortgagehippo/ds';
import { type ReactNode, useCallback } from 'react';
import styled from 'styled-components';

import { type ISmartviewDocumentation } from '$components/smart-view';

import { SmartviewModalColumns } from './smartview-modal-columns';
import { SmartviewModalFilters } from './smartview-modal-filters';
import { SmartviewModalSort } from './smartview-modal-sort';

const Container = styled.div`
  display: flex;

  ${MediaBreakpoint.PHONE} {
    display: block;
  }
`;

const Column = styled.div`
  flex: 0 0 33.3%;
  padding: 0 ${spacing(5)};
  border-left: 1px solid ${palette('neutral50')};
  box-sizing: border-box;

  &:first-child {
    padding-left: 0;
    border-left: none;
  }

  &:last-child {
    padding-right: 0;
  }

  ${MediaBreakpoint.PHONE} {
    padding: 0;
    border-left: none;
    padding: ${spacing(5)} 0;
    border-top: 1px solid ${palette('neutral50')};

    &:first-child {
      padding-top: 0;
      border-top: none;
    }
  }
`;

interface ISmartviewModalProps extends Omit<IModalFormProps, 'children'> {
  documentation?: ISmartviewDocumentation;
  allowOrganizationLevel?: boolean;
  editing?: boolean;
  children?: ReactNode;
  searchFields?: ISearchFields;
}

export const SmartviewModal = (props: ISmartviewModalProps) => {
  const { documentation, allowOrganizationLevel, editing, children, onSubmit, searchFields } =
    props;

  const handleSubmit: FormSubmitHandler = useCallback(
    (values, form, callback) => {
      const { config } = values;
      const { columns, filters } = config || {};

      if (!columns?.length) {
        return {
          [FORM_ERROR]: 'At least 1 column is required.',
        };
      }

      if (!filters?.length) {
        return {
          [FORM_ERROR]: 'At least 1 filter is required.',
        };
      }

      if (!onSubmit) {
        return undefined;
      }

      return onSubmit(values, form, callback);
    },
    [onSubmit]
  );

  return (
    <ModalForm {...props} okButtonLabel="Save" width={1200} onSubmit={handleSubmit}>
      {children}
      <Container>
        <Column>
          <Title level={4} size="bigger">
            Settings
          </Title>
          <Input.Box name="title" label="Title" maxLength={40} required size="sm" />
          <SmartviewModalSort documentation={documentation} />
          {allowOrganizationLevel ? (
            <Checkbox.Box
              name="isOrganizationLevel"
              label="This is an organization-level smartview"
              description="An organization-level smartview is visible to all users in your organization. Only users who can edit organization-level smartviews can edit or delete it. This setting cannot be changed once the smartview is created."
              descriptionPosition="bottom"
              size="sm"
              labelInvisible
              disabled={editing}
            >
              This is an organization-level smartview
            </Checkbox.Box>
          ) : null}
        </Column>
        <Column>
          <Title level={4} size="bigger">
            Filters
          </Title>
          <SmartviewModalFilters searchFields={searchFields} />
        </Column>
        <Column>
          <Title level={4} size="bigger">
            Columns
          </Title>
          <SmartviewModalColumns documentation={documentation} />
        </Column>
      </Container>
      <FormError />
    </ModalForm>
  );
};
