import { Content, Title } from '@mortgagehippo/ds';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import {
  ActionStatus,
  ActionType,
  type IUpdateApplicantBorrowerInformationAction,
  useActionEffect,
  useDispatchAction,
} from '$components/actions';
import { useApplicationFileCan } from '$components/permissions';

import { useSite } from '../../../hooks/use-site';
import { Content as LayoutContent, Layout } from '../../../layouts/application';
import { useApplicationFile } from '../use-application-file-data';
import { ApplicationInvitesApplicants } from './application-invites-applicants';
import { ApplicationInvitesSkeleton } from './application-invites-skeleton';

interface IApplicationInvitesProps {
  applicationFileId: string;
}

const Wrapper = styled('div')`
  max-width: 600px;
`;

export const ApplicationInvites = (props: IApplicationInvitesProps) => {
  const { applicationFileId } = props;

  const [{ applicationFile, applicants }, refreshApplicationFile] =
    useApplicationFile(applicationFileId);

  const siteId = applicationFile && `${applicationFile.siteId}`;
  const [site] = useSite(siteId!, { skip: !siteId });

  const [can, canReady] = useApplicationFileCan(applicationFileId);

  const dispatch = useDispatchAction();

  useActionEffect<IUpdateApplicantBorrowerInformationAction>(
    async (action) => {
      const { applicationFileId: actionApplicationFileId } = action;

      if (actionApplicationFileId !== applicationFileId) {
        // this action belongs to another application file
        return;
      }

      await refreshApplicationFile();
    },
    ActionType.UPDATE_APPLICANT_BORROWER_INFORMATION,
    ActionStatus.DONE
  );

  const handleInvite = useCallback(
    (applicantId: string) => {
      dispatch({
        type: ActionType.SEND_APPLICANT_INVITATION,
        applicationFileId,
        applicantId,
      });
    },
    [applicationFileId, dispatch]
  );

  const handleUpdateEmail = useCallback(
    (applicantId: string) => {
      dispatch({
        type: ActionType.UPDATE_APPLICANT_BORROWER_INFORMATION,
        applicationFileId,
        applicantId,
      });
    },
    [applicationFileId, dispatch]
  );

  const hideDescription = useMemo(
    () => applicants.every((applicant) => !applicant.unregistered),
    [applicants]
  );

  if (!applicationFile || !site || !canReady) {
    return <ApplicationInvitesSkeleton />;
  }

  return (
    <Layout applicationFileId={applicationFileId}>
      <LayoutContent>
        <Wrapper>
          <Title level={1} cid="pageApplication:tabInvitationLink.title">
            Send Invitation Link
          </Title>
          {!hideDescription && (
            <Content cid="pageApplication:tabInvitationLink.description">
              <p>
                Send an invitation link to the {`borrower${(applicants.length > 1 && 's') || ''}`}{' '}
                via email. This will allow them to create a password and access their account.
                Alternatively, you can copy and paste the link below in an email or text to the
                borrower.
              </p>
            </Content>
          )}
          <ApplicationInvitesApplicants
            site={site}
            applicants={applicants}
            onInvite={handleInvite}
            onUpdateEmail={can.UPDATE_APPLICANT_EMAIL ? handleUpdateEmail : undefined}
          />
        </Wrapper>
      </LayoutContent>
    </Layout>
  );
};
