import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { graphql } from '../../../../../../apollo';
import { type ILosPush } from '../types';

const QEncompassPush = graphql(`
  query EncompassPush($applicationFileId: ID!) {
    applicationFile(id: $applicationFileId) {
      id
      hasLossIntegration
      encompassLoanFilePush {
        id
        pushed
        loanNumber
        createdAt
        updatedAt
        statusCode
        statusDescription
      }
      lossLoanExport {
        id
        pushed
        loanNumber
        createdAt
        updatedAt
        statusCode
        statusDescription
      }
    }
  }
`);

export const useEncompassPush = (applicationFileId: string, options: IUseApolloQueryOptions = {}) => {
  const [data, loading, , { refetch }] = useQuery(
    QEncompassPush,
    { applicationFileId },
    { fetchPolicy: 'network-only', ...options }
  );

  const handleRefresh = useSafeCallback(async () => {
    await refetch({ applicationFileId });
  });

  let loanData;

  const hasLossIntegration: boolean | null | undefined = data?.applicationFile?.hasLossIntegration;

  if (!loading) {
    if (hasLossIntegration && !!data?.applicationFile?.lossLoanExport) {
      loanData = data?.applicationFile?.lossLoanExport;
    } else {
      loanData = data?.applicationFile?.encompassLoanFilePush;
    }
  } else {
    loanData = undefined;
  }

  const result: ILosPush | null | undefined = loanData;

  return [result, hasLossIntegration, loading, handleRefresh] as const;
};
