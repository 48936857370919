import { ButtonRouterLink, Card, Divider } from '@mortgagehippo/ds';
import { useContext, useEffect } from 'react';

import { useApplicationFileCan } from '../../../../../components/permissions';
import { ApplicationOverviewContext } from '../../application-overview-context';
import { type IApplicationOverviewSectionProps } from '../../types';
import {
  BlockContent,
  BlockContentContainer,
  BlockLabel,
  BlockValue,
  BlockValueLabel,
  SectionBlock,
  SectionFooter,
} from '../common';
import { useUnderwritingConditionsOverviewSection } from './use-underwriting-conditions-overview-section';

export const ApplicationOverviewUnderwritingConditionsSection = (
  props: IApplicationOverviewSectionProps
) => {
  const { id, applicationFileId, className } = props;

  const { sectionReady, sectionDisabled, ready } = useContext(ApplicationOverviewContext)!;

  const [can, canReady] = useApplicationFileCan(applicationFileId);
  const [stats, sectionLoading] = useUnderwritingConditionsOverviewSection(applicationFileId);

  const isDisabled = !can.VIEW_UNDERWRITING_CONDITIONS_PAGE;

  useEffect(() => {
    if (sectionLoading || !canReady) {
      return;
    }

    if (isDisabled) {
      sectionDisabled(id);
      return;
    }

    sectionReady(id);
  }, [sectionLoading, id, sectionReady, sectionDisabled, canReady, isDisabled]);

  if (!ready || isDisabled) {
    return null;
  }

  const { open, completed } = stats || {};
  const pageUrl = `#/applications/${applicationFileId}/integrations/underwriting_conditions`;

  return (
    <Card compact className={className}>
      <SectionBlock>
        <BlockLabel>Underwriting Conditions</BlockLabel>
        <BlockContentContainer>
          <BlockContent textAlign="center">
            <BlockValue>{open || 0}</BlockValue>
            <BlockValueLabel>Open</BlockValueLabel>
          </BlockContent>
          <BlockContent textAlign="center">
            <BlockValue>{completed || 0}</BlockValue>
            <BlockValueLabel>Completed</BlockValueLabel>
          </BlockContent>
        </BlockContentContainer>
      </SectionBlock>
      <Divider compact />
      <SectionFooter>
        <ButtonRouterLink
          to={pageUrl}
          icon="right-arrow"
          iconLocation="right"
          importance="tertiary"
          size="xs"
          compact
        >
          Open underwriting conditions
        </ButtonRouterLink>
      </SectionFooter>
    </Card>
  );
};
