import { notifications, useCustomizations, useModal } from '@mortgagehippo/ds';
import { toArray } from '@mortgagehippo/util';
import { flatten } from 'lodash-es';
import { useCallback, useEffect, useMemo } from 'react';

import { type IUserCan, useApplicationFileCan } from '$components/permissions';

import { type CreateTasksInput, TaskType } from '../../../apollo/graphql';
import { useApplicationFile } from '../../../pages/application/use-application-file-data';
import { useCreateTasks } from '../../../pages/application/use-create-tasks';
import { useUnderwritingConditions } from '../../../pages/application/use-undewriting-conditions';
import { type IActionProps, type IBulkCreateCustomTaskAction } from '../types';
import { BulkCreateCustomTaskWizard } from './bulk-create-custom-task-wizard';
import { COMMON_APPLICANT_OPTION_VALUE } from './constants';
import { type IBulkCustomTask } from './types';
import { useTaskTemplates } from './use-task-templates';
import { isDefaultTemplate, isValidTask } from './util';

const getUnderwritingTaskTypes = (can: IUserCan) => {
  const taskTypes = [TaskType.DocumentSubmissionTask];

  if (can.CREATE_DOCUSIGN_TASK) {
    taskTypes.push(TaskType.DocusignTask);
  }

  if (can.CREATE_HELLOSIGN_TASK) {
    taskTypes.push(TaskType.HellosignTask);
  }

  return taskTypes;
};

export const BulkCreateCustomTaskAction = (props: IActionProps<IBulkCreateCustomTaskAction>) => {
  const { action, onDone, onCancel } = props;
  const { applicationFileId, underwritingConditionIds } = action;

  const skipUnderwritingConditions = !underwritingConditionIds?.length;

  const [isOpen, , closeModal] = useModal(true);

  const [{ applicationFile, applicants, primaryApplicantId, loading: applicationFileLoading }] =
    useApplicationFile(applicationFileId);
  const [{ items: underwritingConditions }, underwritingConditionsLoading] =
    useUnderwritingConditions(applicationFileId, underwritingConditionIds, {
      skip: skipUnderwritingConditions,
    });

  const [templates, templatesLoading] = useTaskTemplates(applicationFileId);

  const [can, canReady] = useApplicationFileCan(applicationFileId);
  const customizations = useCustomizations();
  const dueDateDisabled = customizations.bool('pageApplication:tabTasks.dueDates.disabled', true);

  const customizationsReady = customizations.ready();

  const loading =
    !customizationsReady ||
    !canReady ||
    applicationFileLoading ||
    templatesLoading ||
    underwritingConditionsLoading;

  const createTasks = useCreateTasks();

  const handleCancel = useCallback(() => {
    closeModal(() => {
      onCancel();
    });
  }, [closeModal, onCancel]);

  const handleFinish = useCallback(async () => {
    closeModal(onDone);
  }, [closeModal, onDone]);

  const handleSave = useCallback(
    async (tasks: IBulkCustomTask[], message?: string | null) => {
      try {
        const validTasks = tasks.filter((t) => isValidTask(t));

        if (!validTasks.length) {
          return false;
        }

        const input: CreateTasksInput[] = flatten(
          validTasks.map((task) => {
            const {
              applicant_ids,
              description,
              title,
              type,
              data,
              template_key,
              underwriting_condition_id,
              due_date,
            } = task;

            // if this task is based on a hardcoded template on the frontend then we don't need to track it
            const templateKey =
              template_key && !isDefaultTemplate(template_key) ? template_key : undefined;

            // docusign breaks the pattern and expects a single record for all selected applicants
            if (type === TaskType.DocusignTask) {
              return [
                {
                  applicantId: null,
                  data,
                  description: description!,
                  templateKey,
                  title: title!,
                  type: TaskType.DocusignTask,
                  underwritingConditionId: underwriting_condition_id,
                  dueDate: due_date,
                },
              ];
            }
            const applicantIds = toArray(applicant_ids);

            return applicantIds.map((applicantId) => ({
              applicantId: applicantId === COMMON_APPLICANT_OPTION_VALUE ? null : applicantId,
              data,
              description: description!,
              templateKey,
              title: title!,
              type: type as TaskType,
              underwritingConditionId: underwriting_condition_id,
              dueDate: due_date,
            }));
          })
        );

        await createTasks(applicationFileId, input, message);

        return true;
      } catch (e) {
        notifications.error({
          message: 'There was an unexpected error creating the tasks',
        });

        return false;
      }
    },
    [applicationFileId, createTasks]
  );

  useEffect(() => {
    if (!loading && !applicationFile) {
      notifications.error({
        message: 'There was an unexpected error loading the application file.',
      });
      onCancel();
    }
  }, [applicationFile, loading, onCancel]);

  const underwritingTaskTypes = useMemo(() => getUnderwritingTaskTypes(can), [can]);

  return (
    <BulkCreateCustomTaskWizard
      applicationFileId={applicationFileId}
      isOpen={isOpen}
      loading={loading}
      applicants={applicants}
      primaryApplicantId={primaryApplicantId}
      templates={templates}
      underwritingConditions={underwritingConditions}
      underwritingTaskTypes={underwritingTaskTypes}
      onCancel={handleCancel}
      onFinish={handleFinish}
      onSave={handleSave}
      showDueDate={!dueDateDisabled}
    />
  );
};
