import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';
import { useMemo } from 'react';

import { graphql } from '../../../apollo';
import {
  type AgentModalDataQuery,
  type AgentModalDataQueryVariables,
} from '../../../apollo/graphql';
import { usePartnerDomain } from '../../../hooks/use-partner-domain';

export const QAgentModalData = graphql(`
  query AgentModalData($partnerId: ID!) {
    partner(id: $partnerId) {
      id
      folders(options: { perPage: 1000 }) {
        items {
          id
          name
          path
        }
      }
      sites(options: { perPage: 1000, orderBy: { name: "asc" } }) {
        items {
          id
          name
        }
      }
    }
  }
`);

type IPartner = NonNullable<AgentModalDataQuery['partner']>;
type ISite = IPartner['sites']['items'][0];
export type IFolder = IPartner['folders']['items'][0];

type IUseAgentModalDataResult = [
  {
    sites: ISite[] | undefined;
    folders: IFolder[] | undefined;
    loading: boolean;
  },
  () => Promise<any>,
];

export const useAgentModalData = (
  options: IUseApolloQueryOptions = {}
): IUseAgentModalDataResult => {
  const partnerId = usePartnerDomain();

  const [data, loading, , { refetch }] = useQuery<
    AgentModalDataQuery,
    AgentModalDataQueryVariables
  >(
    QAgentModalData,
    {
      partnerId,
    },
    {
      ...options,
      suspend: false,
      fetchPolicy: 'network-only',
    }
  );

  const handleRefresh = useSafeCallback(async () => refetch({ partnerId }));

  const result = useMemo(() => {
    if (loading || !data?.partner) {
      return {
        sites: undefined,
        folders: undefined,
        loading,
      };
    }

    const { partner } = data;
    const {
      sites: { items: sites },
      folders: { items: partnerFolders },
    } = partner;

    return {
      sites,
      folders: partnerFolders,
      loading,
    };
  }, [data, loading]);

  return [result, handleRefresh];
};
