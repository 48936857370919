import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo';

const MDuplicateApplicationFile = graphql(`
  mutation DuplicateApplication($applicationFileId: ID!, $sendInvitation: Boolean, $wait: Boolean) {
    duplicateApplicationFile(
      applicationFileId: $applicationFileId
      sendInvitation: $sendInvitation
      wait: $wait
    )
  }
`);

export const useDuplicateApplicationFile = () => {
  const duplicateApplicationFile = useMutation(MDuplicateApplicationFile);

  return useCallback(
    async (applicationFileId: string, sendInvitation: boolean | null = null, wait = false) => {
      const response = await duplicateApplicationFile({
        applicationFileId,
        sendInvitation,
        wait,
      });
      return response.data!.duplicateApplicationFile;
    },
    [duplicateApplicationFile]
  );
};
