import {
  ChoiceInput,
  createField,
  type IChoiceOption,
  type IChoiceProps,
  type IFieldInputProps,
} from '@mortgagehippo/ds';
import { memo } from 'react';

import { NotificationOptInsFieldInternalValue } from './types';
import { fromExternalValue, toExternalValue } from './util';

interface INotificationOptInsInputProps
  extends Omit<IChoiceProps, 'options' | 'multiple'>,
    IFieldInputProps {}

const notificationOptInsOptions: IChoiceOption[] = [
  {
    label: 'Email',
    value: NotificationOptInsFieldInternalValue.Email,
  },
  {
    label: 'Text message',
    value: NotificationOptInsFieldInternalValue.TextMessage,
  },
  {
    label: 'Both Email & Text message',
    value: NotificationOptInsFieldInternalValue.EmailAndTextMessage,
  },
];

export const NotificationOptInsInput = memo((props: INotificationOptInsInputProps) => {
  const { value: externalValue, onChange, ...rest } = props;

  const value = fromExternalValue(externalValue);

  const handleChange = (nextValue: any) => {
    const nextExternalValue = toExternalValue(nextValue);

    onChange(nextExternalValue);
  };

  return (
    <ChoiceInput
      {...rest}
      options={notificationOptInsOptions}
      value={value}
      onChange={handleChange}
    />
  );
});

export const NotificationOptIns =
  createField<INotificationOptInsInputProps>(NotificationOptInsInput);
