import { MediaBreakpoint, spacing } from '@mortgagehippo/ds';
import { groupBy, reduce } from 'lodash-es';
import styled from 'styled-components';

import { ApplicationOverviewProvider } from './application-overview-provider';
import { ApplicationOverviewSection } from './application-overview-section';
import { ApplicationOverviewSkeleton } from './application-overview-skeleton';
import { OVERVIEW_SECTIONS } from './constant';
import { type IApplicationOverviewSection } from './types';

const Sections = styled.div``;

const Columns = styled.div`
  display: flex;

  ${MediaBreakpoint.TABLET} {
    flex-direction: column;
  }
`;

const Column = styled.div`
  flex: 0 0 50%;
  padding: 0 0 0 ${spacing(2)};
  box-sizing: border-box;

  &:first-child {
    padding: 0 ${spacing(2)} 0 0;
  }

  ${MediaBreakpoint.TABLET} {
    padding: 0;

    &:first-child {
      padding: 0;
    }
  }
`;

const Section = styled(ApplicationOverviewSection)`
  margin-bottom: ${spacing(4)};
`;

interface IApplicationOverviewProps {
  applicationFileId: string;
}

export const ApplicationOverview = (props: IApplicationOverviewProps) => {
  const { applicationFileId } = props;

  return (
    <ApplicationOverviewProvider sections={OVERVIEW_SECTIONS}>
      {(sections, ready) => {
        const { full: fullSections = [], half: halfSections = [] } = groupBy(
          sections,
          (s) => s.size || 'half'
        );

        const columns = reduce(
          halfSections,
          (accum: Array<IApplicationOverviewSection[]>, section) => {
            const targetColIndex = section.column === 'right' ? 1 : 0;

            if (!accum[targetColIndex]) {
              accum.push([]);
            }

            accum[targetColIndex]!.push(section);

            return accum;
          },
          [] as Array<IApplicationOverviewSection[]>
        );

        return (
          <>
            {!ready && <ApplicationOverviewSkeleton />}
            <Sections>
              {fullSections.map((section) => (
                <Section key={section.id} applicationFileId={applicationFileId} section={section} />
              ))}
              {columns.length > 0 && (
                <Columns>
                  {columns.map((columnSections, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Column key={index}>
                      {columnSections.map((section) => (
                        <Section
                          key={section.id}
                          applicationFileId={applicationFileId}
                          section={section}
                        />
                      ))}
                    </Column>
                  ))}
                </Columns>
              )}
            </Sections>
          </>
        );
      }}
    </ApplicationOverviewProvider>
  );
};
