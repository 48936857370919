import { spacing } from '@mortgagehippo/ds';
import styled from 'styled-components';

import { type ISite } from '../../../hooks/use-site';
import { type IApplicationApplicant } from '../use-application-file-data';
import { ApplicationInvitesApplicant } from './application-invites-applicant';

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  > li {
    margin-top: ${spacing(8)};

    &:first-child {
      margin-top: ${spacing(4)};
    }
  }
`;

interface IApplicationInvitesApplicantsProps {
  site: ISite;
  applicants: IApplicationApplicant[];
  onInvite: (applicantId: string) => void;
  onUpdateEmail?: (applicantId: string) => void;
}

export const ApplicationInvitesApplicants = (props: IApplicationInvitesApplicantsProps) => {
  const { site, applicants, onInvite, onUpdateEmail } = props;

  if (applicants.length === 1) {
    const [applicant] = applicants;

    return (
      <ApplicationInvitesApplicant
        site={site}
        applicant={applicant!}
        onInvite={onInvite}
        onUpdateEmail={onUpdateEmail}
      />
    );
  }

  return (
    <List>
      {applicants.map((applicant) => (
        <li key={applicant.id}>
          <ApplicationInvitesApplicant
            site={site}
            applicant={applicant}
            showTitle
            onInvite={onInvite}
            onUpdateEmail={onUpdateEmail}
          />
        </li>
      ))}
    </List>
  );
};
