import {
  type INotificationOptions,
  ModalDialog,
  notifications,
  T,
  useModal,
} from '@mortgagehippo/ds';
import { toArray } from '@mortgagehippo/util';
import { useCallback } from 'react';

import { useRestoreApplicationFile } from '../../../pages/application/use-restore-application';
import { type IActionProps, type IRestoreApplicationAction } from '../types';

export const RestoreApplicationAction = (props: IActionProps<IRestoreApplicationAction>) => {
  const { action, onDone, onCancel } = props;
  const { applicationFileId } = action;
  const [isOpen, , closeModal] = useModal(true);

  const restoreApplication = useRestoreApplicationFile();

  const applicationFileIds = toArray(applicationFileId);
  const totalApplications = applicationFileIds.length;
  const isBulk = totalApplications > 1;

  const successNotificationOptions: INotificationOptions = isBulk
    ? {
        message: (
          <T
            cid="actions:restoreApplication.bulk.notification.success"
            cvars={{ totalApplications }}
          >
            Successfully restored {totalApplications} applications
          </T>
        ),
      }
    : {
        messageCid: 'actions:restoreApplication.single.notification.success',
        message: 'The application was successfully restored',
      };

  const errorNotificationOptions: INotificationOptions = isBulk
    ? {
        messageCid: 'actions:restoreApplication.bulk.notification.error',
        message: 'There was an unexpected error restoring the applications',
      }
    : {
        messageCid: 'actions:restoreApplication.single.notification.error',
        message: 'There was an unexpected error restoring the application',
      };

  const handleOk = async () => {
    try {
      await Promise.all(applicationFileIds.map((id) => restoreApplication(id)));

      closeModal(() => {
        notifications.success(successNotificationOptions);
        onDone();
      });
    } catch (e) {
      console.error(e);
      notifications.error(errorNotificationOptions);
    }
  };

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  if (isBulk) {
    return (
      <ModalDialog
        type="warning"
        title={
          <T cid="actions:restoreApplication.bulk.modal.title">
            Are you sure you want to restore these applications?
          </T>
        }
        onOk={handleOk}
        onRequestClose={handleCloseModal}
        isOpen={isOpen}
      >
        <T cid="actions:restoreApplication.bulk.modal.description">
          Borrowers will have access to these applications once they have been restored.
        </T>
      </ModalDialog>
    );
  }

  return (
    <ModalDialog
      type="warning"
      title={
        <T cid="actions:restoreApplication.single.modal.title">
          Are you sure you want to restore this application?
        </T>
      }
      onOk={handleOk}
      onRequestClose={handleCloseModal}
      isOpen={isOpen}
    >
      <T cid="actions:restoreApplication.single.modal.description">
        Borrowers will have access to this application once it has been restored.
      </T>
    </ModalDialog>
  );
};
