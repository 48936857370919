import { ApplicationBorrowerMilestones } from './application-borrower-milestones';
import { ApplicationLenderMilestones } from './lender-milestones';

export interface IApplicationMilestonesProps {
  applicationFileId: string;
}

export const ApplicationMilestones = (props: IApplicationMilestonesProps) => {
  const { applicationFileId } = props;

  return (
    <>
      <ApplicationBorrowerMilestones applicationFileId={applicationFileId} />
      <ApplicationLenderMilestones applicationFileId={applicationFileId} />
    </>
  );
};
