import { useMutation } from '@mortgagehippo/apollo-hooks';
import { notifications } from '@mortgagehippo/ds';
import { useCallback } from 'react';

import { graphql } from '../../apollo';

const downloadFile = (href: string) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = href;
  downloadLink.click();
};

const MGenerateMismoFile = graphql(`
  mutation GenerateMismoFile($applicationFileId: ID!, $typeName: String) {
    generateMismoFile(applicationFileId: $applicationFileId, typeName: $typeName) {
      downloadUrl
    }
  }
`);

export const useDownloadMismoFile = (applicationFileId: string) => {
  const generateMismoFile = useMutation(MGenerateMismoFile, undefined);

  return useCallback(
    async (typeName: string) => {
      const key = notifications.info({
        message: 'Generating MISMO File',
        duration: 0,
        loading: true,
      });
      try {
        const response = await generateMismoFile({
          applicationFileId,
          typeName,
        });

        const url = response?.data?.generateMismoFile?.downloadUrl;

        if (!url) {
          notifications.error({
            key,
            message: 'There was a problem generating your MISMO file.',
            loading: false,
          });
          return;
        }

        downloadFile(url);
        notifications.success({
          key,
          message: 'MISMO file was successfully generated and should automatically download.',
          loading: false,
          duration: 'auto',
        });
      } catch (e) {
        notifications.error({
          key,
          message: 'There was a problem generating your MISMO file.',
          loading: false,
        });
      }
    },
    [applicationFileId, generateMismoFile]
  );
};
