import { Empty, Modal, T } from '@mortgagehippo/ds';
import { type ReactNode } from 'react';
import styled from 'styled-components';

const StyledFrame = styled.iframe`
  width: 100%;
  min-height: calc(100vh - 100px);
`;

interface ICreditCheckReportModalProps {
  fileContents?: string | null;
  onRequestClose: () => void;
  isOpen: boolean;
  preventDownload?: boolean;
}

export const CreditCheckReportModal = (props: ICreditCheckReportModalProps) => {
  const { fileContents, onRequestClose, preventDownload, ...rest } = props;
  let contentEl: ReactNode;

  if (fileContents) {
    contentEl = (
      <StyledFrame
        title="Full Credit Report"
        src={`data:application/pdf;base64,${fileContents}${
          preventDownload ? '#toolbar=0&navpanes=0' : ''
        }`}
      />
    );
  } else {
    contentEl = (
      <Empty>
        <T cid="pageApplication:tabServices.creditCheck.results.modal.error.message">
          Full credit report not available.
        </T>
      </Empty>
    );
  }

  return (
    <Modal
      title="Full Credit Report"
      hideCancelButton
      onRequestClose={onRequestClose}
      width="full"
      compact
      hideOkButton
      {...rest}
    >
      {contentEl}
    </Modal>
  );
};
