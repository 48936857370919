import { ButtonRouterLink, Card, Divider } from '@mortgagehippo/ds';
import { useContext, useEffect } from 'react';

import { ActivityFeedContent } from '$components/activity-feed/activity-feed-content';

import { useApplicationFileCan } from '../../../../../components/permissions';
import { useApplicationFileActivity } from '../../../use-application-file-activity';
import { ApplicationOverviewContext } from '../../application-overview-context';
import { type IApplicationOverviewSectionProps } from '../../types';
import { BlockLabel, SectionBlock, SectionFooter } from '../common';

export const ApplicationOverviewLatestActivitySection = (
  props: IApplicationOverviewSectionProps
) => {
  const { id, applicationFileId, className } = props;

  const { sectionReady, ready, sectionDisabled } = useContext(ApplicationOverviewContext)!;

  const [can, canReady] = useApplicationFileCan(applicationFileId);
  const [result, loading, , error] = useApplicationFileActivity({
    applicationFileId,
    perPage: 5,
  });
  const canViewApplicationActivityPage = can.VIEW_APPLICATION_ACTIVITY_PAGE;

  useEffect(() => {
    if (loading || !canReady) {
      return;
    }

    if (!canViewApplicationActivityPage) {
      sectionDisabled(id);
      return;
    }

    sectionReady(id);
  }, [loading, id, sectionReady, canReady, canViewApplicationActivityPage, sectionDisabled]);

  if (!ready || !result || error || !canViewApplicationActivityPage) {
    return null;
  }

  const { dictionary, items } = result;
  const activityFeedUrl = `#/applications/${applicationFileId}/activity`;

  return (
    <Card compact className={className}>
      <SectionBlock hasBlockValueLabels>
        <BlockLabel>Latest Activity</BlockLabel>
      </SectionBlock>

      <ActivityFeedContent
        dictionary={dictionary}
        items={items}
        hideHeader={!items.length}
        narrow
      />

      <Divider compact />
      <SectionFooter>
        <ButtonRouterLink
          to={activityFeedUrl}
          icon="right-arrow"
          iconLocation="right"
          importance="tertiary"
          size="xs"
          compact
        >
          See all activity
        </ButtonRouterLink>
      </SectionFooter>
    </Card>
  );
};
