import { Form, notifications, SubmitButton, T } from '@mortgagehippo/ds';
import { useCallback, useMemo } from 'react';

import { type AgentProfileInput } from '../../../apollo/graphql';
import { useUser } from '../../../hooks/use-user';
import { Actions, Container } from '../common';
import { useUpdateAgentProfile } from '../use-update-agent-profile';
import { AccountTabFields } from './account-tab-fields';

export const AccountTab = () => {
  const [user, userLoading] = useUser();

  const updateAgentProfile = useUpdateAgentProfile();

  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        const {
          name,
          phone = null,
          cell_phone: cellPhone = null,
          alternate_phone: alternatePhone = null,
          secondary_email: secondaryEmail = [],
          notification_opt_ins: notificationOptIns = [],
        } = values;

        const secondaryEmailInput = secondaryEmail.reduce((accumulator: string[], item: any) => {
          if (item.email) {
            accumulator.push(item.email);
          }

          return accumulator;
        }, []);

        const data: AgentProfileInput = {
          name,
          phone,
          cellPhone,
          alternatePhone,
          secondaryEmail: secondaryEmailInput,
          notificationOptIns,
        };

        await updateAgentProfile(data);

        notifications.success({ message: 'Your account was updated.' });
      } catch (error) {
        notifications.error({
          message: 'There was an error processing your request, please try again later',
        });
      }
    },
    [updateAgentProfile]
  );

  const initialValues = useMemo(() => {
    if (!user) {
      return {};
    }

    const { name, phone, cellPhone, alternatePhone, secondaryEmail, notificationOptIns } = user;

    const secondaryEmailValue = secondaryEmail.map((v) => ({
      email: v,
    }));

    return {
      name,
      phone,
      cell_phone: cellPhone,
      alternate_phone: alternatePhone,
      secondary_email: secondaryEmailValue,
      notification_opt_ins: notificationOptIns,
    };
  }, [user]);

  if (userLoading || !user) {
    return null;
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit} initialValues={initialValues}>
        <AccountTabFields />
        <Actions>
          <SubmitButton importance="primary">
            <T>Save</T>
          </SubmitButton>
        </Actions>
      </Form>
    </Container>
  );
};
