import {
  FORM_ERROR,
  FormError,
  type FormSubmitHandler,
  type IModalFormProps,
  ModalForm,
  notifications,
  type UploadHandler,
} from '@mortgagehippo/ds';
import axios, { type AxiosProgressEvent } from 'axios';
import { useCallback } from 'react';

import { type IApplicationApplicant } from '../../../../../pages/application/use-application-file-data';
import { useCreateHellosignDraftUploadUrl } from '../../../edit-custom-task-action/custom-hellosign-task/use-create-hellosign-draft-upload-url';
import {
  HellosignTaskEditorFields,
  SignatureSelectionMethod,
} from './hellosign-task-editor-fields';

interface IHellosignTaskEditorProps extends IModalFormProps {
  applicationFileId: string;
  applicants: IApplicationApplicant[];
  showDueDate?: boolean;
}

export const HellosignTaskEditor = (props: IHellosignTaskEditorProps) => {
  const { applicationFileId, applicants, showDueDate, onSubmit, children, ...rest } = props;

  const createDraftUploadUrl = useCreateHellosignDraftUploadUrl();

  const handleUpload: UploadHandler = useCallback(
    async (file, meta, progress) => {
      try {
        const { id, postUrl } = await createDraftUploadUrl(applicationFileId, meta.filename);

        await axios.put(postUrl, file, {
          headers: {
            'Content-Type': meta.mime,
          },
          onUploadProgress: (e: AxiosProgressEvent) => {
            if (!e.total) return;
            const percent = Math.round((e.loaded * 100) / e.total);
            progress(percent);
          },
        });

        return { id };
      } catch (e) {
        notifications.error({
          message: 'There was an unexpected error while uploading your file',
        });
        throw e;
      }
    },
    [applicationFileId, createDraftUploadUrl]
  );

  const handleSubmit: FormSubmitHandler = useCallback(
    (values, form, callback) => {
      const { state, data } = values || {};
      const { signature_selection_method: signatureSelectionMethod } = state;
      const { signature_request_id: signatureRequestId } = data || {};

      if (signatureSelectionMethod === SignatureSelectionMethod.MANUAL && !signatureRequestId) {
        return { [FORM_ERROR]: 'You must finish configuring the signatures.' };
      }

      if (!onSubmit) {
        return undefined;
      }

      return onSubmit(values, form, callback);
    },
    [onSubmit]
  );

  return (
    <ModalForm {...rest} onSubmit={handleSubmit}>
      {children}
      <HellosignTaskEditorFields
        applicationFileId={applicationFileId}
        applicants={applicants}
        onUpload={handleUpload}
        showDueDate={showDueDate}
      />
      <FormError />
    </ModalForm>
  );
};
