import { type ISelectOption, useModal } from '@mortgagehippo/ds';
import { generateUrlSafeHash } from '@mortgagehippo/util';
import { useCallback, useMemo } from 'react';

import { type IActionProps, type IShareAssignmentLinkAction } from '../types';
import { ShareAssignmentLinkModal } from './share-assignment-link-modal';
import { useAgentShareAssignmentLink } from './use-agent-share-assignment-link';

export const ShareAssignmentLinkAction = (props: IActionProps<IShareAssignmentLinkAction>) => {
  const { action, onDone, onSubmit, onCancel } = props;
  const { agentId } = action;

  const [isOpen, , closeModal] = useModal(true);

  const [{ data, loading }] = useAgentShareAssignmentLink(agentId);

  const { name, slug, sites } = data || {};

  const handleCloseModal = useCallback(() => {
    closeModal(() => {
      onSubmit();
      onDone();
      onCancel();
    });
  }, [closeModal, onCancel, onDone, onSubmit]);

  const siteOptions: ISelectOption[] = useMemo(() => {
    if (loading || !sites?.items.length) {
      return [];
    }

    return sites.items.map((site) => ({
      label: site.name,
      value: site.domain,
    }));
  }, [loading, sites?.items]);

  const sharePath = useMemo(() => {
    if (loading) {
      return '';
    }

    const pathStart = '/assign';
    const hashString = generateUrlSafeHash(`Agent:${agentId}`);

    if (slug) {
      return `${pathStart}/${slug}/${hashString}`;
    }
    if (name) {
      return `${pathStart}/${name.replace(' ', '')}/${hashString}`;
    }
    return `${pathStart}/to/${hashString}`;
  }, [agentId, loading, name, slug]);

  return (
    <ShareAssignmentLinkModal
      onRequestClose={handleCloseModal}
      isOpen={isOpen}
      loading={loading}
      siteOptions={siteOptions}
      sharePath={sharePath}
    />
  );
};
