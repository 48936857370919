import { Format, Skeleton } from '@mortgagehippo/ds';
import moment from 'moment';

interface IActivityFeedTableTitleProps {
  date?: string | null;
  loading?: boolean;
}

export const ActivityFeedTableTitle = (props: IActivityFeedTableTitleProps) => {
  const { date, loading } = props;

  const tz = moment.tz.guess();
  const today = moment();
  const yesterday = moment().subtract(1, 'day');
  const momentDate = moment.utc(date).tz(tz);

  const isToday = today.isSame(momentDate, 'day');
  const isYesterday = yesterday.isSame(momentDate, 'day');

  const daysDiff = today.diff(momentDate, 'day');

  if (loading) {
    return <Skeleton paragraph={false} title={{ width: '50%' }} />;
  }

  if (!date) {
    return <>&nbsp;</>;
  }

  if (isToday) {
    return (
      <>
        <strong>Today</strong>, <Format.Date value={date} customFormat="dddd" />,{' '}
        <Format.Date value={date} format="date-med" />
      </>
    );
  }

  if (isYesterday) {
    return (
      <>
        <strong>Yesterday</strong>, <Format.Date value={date} customFormat="dddd" />,{' '}
        <Format.Date value={date} format="date-med" />
      </>
    );
  }

  if (daysDiff <= 7) {
    return (
      <>
        <strong>
          <Format.Date value={date} customFormat="dddd" />
        </strong>
        , <Format.Date value={date} format="date-med" />
      </>
    );
  }

  // more than 7 days
  return <Format.Date value={date} format="date-med" />;
};
