import { notifications, useCustomizations, useModal } from '@mortgagehippo/ds';
import { useAnswers, useSagaSubmitHandler } from '@mortgagehippo/tasks';
import { useCallback, useEffect, useMemo } from 'react';

import {
  ActionStatus,
  ActionType,
  type IActionProps,
  type IUpdateApplicantBorrowerInformationAction,
  useDispatchAction,
} from '$components/actions';

import { type UpdateApplicantBorrowerInformationInput } from '../../../apollo/graphql';
import { useApplicationFileApplicant } from '../../../pages/application/use-application-file-applicant';
import { history } from '../../../services/history';
import { useApplicationFileCan } from '../../permissions';
import {
  type IUpdateApplicantBorrowerInformationModalFormValues,
  UpdateApplicantBorrowerInformationModal,
} from './update-applicant-borrower-information-modal';
import { useUpdateApplicantBorrowerInformation } from './use-update-applicant-borrower-information';

export const UpdateApplicantBorrowerInformationAction = (
  props: IActionProps<IUpdateApplicantBorrowerInformationAction>
) => {
  const { action, onSubmit, onDone, onCancel } = props;
  const { applicationFileId, applicantId } = action;

  const [isOpen, , closeModal] = useModal(true);

  const [can, canReady, { data: applicationFile }] = useApplicationFileCan(applicationFileId);
  const [applicant, applicantLoading] = useApplicationFileApplicant(applicationFileId, applicantId);
  const [answers, answersLoading] = useAnswers(applicationFileId, applicantId);

  const dispatch = useDispatchAction();

  const isFrozen = (applicationFile && applicationFile.isFrozen) || false;

  const updateApplicantBorrowerInformation = useUpdateApplicantBorrowerInformation();

  const loading = answersLoading || applicantLoading;

  const customizations = useCustomizations();
  const title = customizations.text(
    'updateApplicantEmailAction:modal.title',
    'Update Borrower Information'
  );
  const successMessage = customizations.text(
    'updateApplicantEmailAction:success.message',
    'Borrower information was updated.'
  );

  useEffect(() => {
    if (!loading && (!applicant || !answers)) {
      closeModal(() => {
        notifications.error({
          messageCid: 'updateApplicantEmailAction:error.load.message',
          message: 'The borrower could not be found.',
        });
        onCancel();
      });
    }
  }, [answers, applicant, closeModal, loading, onCancel]);

  const [handleSubmit, running] =
    useSagaSubmitHandler<IUpdateApplicantBorrowerInformationModalFormValues>({
      infoMessageCid: 'updateApplicantEmailAction:info.message',
      errorMessageCid: 'updateApplicantEmailAction:error.submit.message',
      infoMessage: 'Updating borrower information',
      successMessage,
      errorMessage: "There was an unexpected error updating the borrower's information.",
      async onSubmit(values, setAsyncId) {
        const { email, firstName, lastName, cellPhoneNumber, homePhoneNumber, workPhoneNumber } =
          values;

        const payload: UpdateApplicantBorrowerInformationInput = {
          email,
          firstName,
          lastName,
          cellPhoneNumber,
          homePhoneNumber,
          workPhoneNumber,
        };

        const asyncId = await updateApplicantBorrowerInformation(applicantId, payload);

        closeModal(() => {
          setAsyncId(asyncId);
          onSubmit();
        });
      },
      async onDone() {
        closeModal(onDone);
      },
    });

  const handleStartApplication = useCallback(() => {
    dispatch(
      {
        type: ActionType.DUPLICATE_APPLICATION,
        applicationFileId,
      },
      (_action, status, result) => {
        const { applicationFileId: nextApplicationFileId } = result || {};

        if (status !== ActionStatus.DONE || !nextApplicationFileId) {
          return;
        }
        closeModal(() => {
          onDone();
          history.push(`#/applications/${nextApplicationFileId}`);
        });
      }
    );
  }, [applicationFileId, closeModal, dispatch, onDone]);

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  const initialValues: IUpdateApplicantBorrowerInformationModalFormValues = useMemo(() => {
    if (!answers || !applicant) {
      return {};
    }

    const { sharingEmail } = applicant;
    let { email } = applicant;

    const { first_name, last_name, mobile_phone_number, home_phone_number, work_phone_number } =
      answers;

    if (sharingEmail) {
      email = null;
    }

    return {
      email,
      firstName: first_name,
      lastName: last_name,
      cellPhoneNumber: mobile_phone_number,
      homePhoneNumber: home_phone_number,
      workPhoneNumber: work_phone_number,
    };
  }, [answers, applicant]);

  if (running) {
    return null;
  }

  const modalLoading = loading || !canReady || !applicant || !answers;

  return (
    <UpdateApplicantBorrowerInformationModal
      isOpen={isOpen}
      title={title}
      onSubmit={handleSubmit}
      onRequestClose={handleCloseModal}
      initialValues={initialValues}
      isFrozen={isFrozen}
      loading={modalLoading}
      onStartApplication={can.CREATE_APPLICATION_FILE ? handleStartApplication : undefined}
    />
  );
};
