import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../apollo';
import { type ExportSettings } from '../apollo/graphql';

const MRequestAgentsExport = graphql(`
  mutation RequestAgentsExport($partnerId: ID!, $settings: ExportSettings) {
    requestAgentsExport(partnerId: $partnerId, settings: $settings)
  }
`);

export const useRequestAgentsExport = () => {
  const requestAgentsExport = useMutation(MRequestAgentsExport);

  return useCallback(
    async (partnerId: string, settings?: ExportSettings) => {
      const response = await requestAgentsExport({
        partnerId,
        settings,
      });
      return response.data!.requestAgentsExport;
    },
    [requestAgentsExport]
  );
};
