import {
  type FormSubmitHandler,
  Input,
  type ITreeSelectOption,
  ModalForm,
  T,
  TreeSelect,
} from '@mortgagehippo/ds';
import { useMemo } from 'react';

import { ROOT_TREE_OPTION_KEY } from './constants';
import { type ManagedFolder } from './manager';
import { isFakeRootFolder } from './util';

const toTreeSelectOptions = (folders: ManagedFolder[]): ITreeSelectOption[] =>
  folders.map((folder) => ({
    value: folder.getId(),
    label: folder.getName(),
    children: toTreeSelectOptions(folder.getActiveChildren()),
  }));

interface IAddFolderModalProps {
  folders: ManagedFolder[];
  selectedFolderId?: string;
  onSubmit: FormSubmitHandler;
  onRequestClose: () => void;
  isOpen: boolean;
}

export const AddFolderModal = (props: IAddFolderModalProps) => {
  const { folders, selectedFolderId, onSubmit, onRequestClose, isOpen } = props;

  const options: ITreeSelectOption[] = useMemo(() => {
    const topLevelFolders = folders.filter(
      (folder) => folder.getParentId() === undefined && !folder.isSoftDeleted()
    );
    const nextTreeSelectOptions = toTreeSelectOptions(topLevelFolders);

    const fakeRootFolder: ITreeSelectOption = {
      value: ROOT_TREE_OPTION_KEY,
      label: <T>Root</T>,
      children: nextTreeSelectOptions,
    };

    return [fakeRootFolder];
  }, [folders]);

  const initialValues = useMemo(() => {
    if (!selectedFolderId) {
      return {
        parent_id: ROOT_TREE_OPTION_KEY,
      };
    }

    const folderExists = folders.some(
      (folder) => folder.getId() === selectedFolderId && !folder.isSoftDeleted()
    );

    if (!folderExists && !isFakeRootFolder(selectedFolderId)) {
      return {};
    }

    return { parent_id: selectedFolderId };
  }, [folders, selectedFolderId]);

  return (
    <ModalForm
      title="Add New Folder"
      isOpen={isOpen}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onRequestClose={onRequestClose}
      disableOverlayClickClose
      autoComplete={false}
    >
      <Input.Box label="Name" name="name" required />
      <Input.Box label="Description" name="description" />
      <TreeSelect.Box
        label="Parent folder"
        name="parent_id"
        options={options}
        allowClear
        leafNodeIconProps={{ name: 'folder', outline: true }}
        searchable
        treeIconProps={{ name: 'folder', outline: true }}
        treeIconExpandedProps={{ name: 'folder-open', outline: true }}
        required
      />
    </ModalForm>
  );
};
