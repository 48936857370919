import { spacing } from '@mortgagehippo/ds';
import { groupBy, map } from 'lodash-es';
import moment from 'moment';
import { useMemo } from 'react';
import styled from 'styled-components';

import { ActivityFeedTable } from './activity-feed-table';
import { type IActivityFeedDictionary, type IActivityFeedEvent } from './types';

const Container = styled.div`
  margin-top: ${spacing(4)};

  &:first-child {
    margin-top: 0;
  }
`;

interface IActivityFeedContentProps {
  dictionary: IActivityFeedDictionary;
  items: IActivityFeedEvent[];
  hideHeader?: boolean;
  narrow?: boolean;
}

export const ActivityFeedContent = (props: IActivityFeedContentProps) => {
  const { dictionary, hideHeader, items, narrow } = props;

  const itemsByDate = useMemo(() => {
    const tz = moment.tz.guess();

    return groupBy(items, (item) => {
      const { createdAt } = item;

      const momentDate = moment.utc(createdAt);

      return momentDate.tz(tz).format('l');
    });
  }, [items]);

  // empty state
  if (!items.length) {
    return (
      <ActivityFeedTable
        dictionary={dictionary}
        items={items}
        narrow={narrow}
        hideHeader={hideHeader}
      />
    );
  }

  return (
    <>
      {map(itemsByDate, (dateItems, date) => (
        <Container key={date}>
          <ActivityFeedTable
            dictionary={dictionary}
            items={dateItems}
            narrow={narrow}
            hideHeader={hideHeader}
          />
        </Container>
      ))}
    </>
  );
};
