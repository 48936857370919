import { type IItemizationListItem, ItemizationList, T } from '@mortgagehippo/ds';
import { useMemo } from 'react';

import { type IApplicationFileTeamAgent } from '../../../../pages/application/use-application-file-agents';

interface ITeamAgentListProps {
  agents: IApplicationFileTeamAgent[];
}

export const TeamAgentsList = (props: ITeamAgentListProps) => {
  const { agents } = props;

  const teamItems: IItemizationListItem[] = useMemo(
    () =>
      agents.map((agent) => ({
        id: agent.id,
        label: agent.name,
        value: agent.jobFunctions.items.map((j) => j.name).join(', ') || <T>No role assigned.</T>,
      })),
    [agents]
  );

  return <ItemizationList title={<T>Team</T>} items={teamItems} size="sm" />;
};
