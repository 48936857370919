import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { graphql } from '../../apollo';
import { type ApplicationFileAgentsQuery } from '../../apollo/graphql';

export const QApplicationFileAgents = graphql(`
  query ApplicationFileAgents($applicationFileId: ID!, $perPage: Int!, $cursor: String) {
    applicationFile(id: $applicationFileId) {
      id
      primaryAgent {
        id
      }
      teamAgents(options: { perPage: $perPage, cursor: $cursor, orderBy: { name: "asc" } }) {
        total
        nextCursor
        previousCursor
        items {
          id
          name
          email
          phone
          jobFunctions(options: { perPage: 10000, orderBy: { name: "asc" } }) {
            items {
              id
              name
              primary
            }
          }
        }
      }
    }
  }
`);

export type IApplicationFileTeamAgent = NonNullable<
  ApplicationFileAgentsQuery['applicationFile']
>['teamAgents']['items'][0];

export const useApplicationFileAgents = (
  applicationFileId: string,
  options: IUseApolloQueryOptions = {}
) => {
  const [data, loading, , { refetch }] = useQuery(
    QApplicationFileAgents,
    {
      applicationFileId,
      perPage: 10000,
    },
    {
      ...options,
      suspend: false,
      fetchPolicy: 'network-only',
    }
  );

  const handleRefresh = useSafeCallback(async () => refetch({ applicationFileId, perPage: 10000 }));

  const primaryAgent = data?.applicationFile?.primaryAgent;
  const agents = data?.applicationFile?.teamAgents.items;

  return [{ primaryAgent, agents }, loading, handleRefresh] as const;
};
