import { Card, Divider, Format, T, useResponsive } from '@mortgagehippo/ds';
import { isNil } from 'lodash-es';
import { useContext, useEffect } from 'react';

import { ApplicationOverviewContext } from '../../application-overview-context';
import { type IApplicationOverviewSectionProps } from '../../types';
import {
  BlockContent,
  BlockContentContainer,
  BlockLabel,
  BlockValue,
  SectionBlock,
} from '../common';
import { Dash } from '../dash';
import { useLoanOverviewSection } from './use-loan-overview-section';

export const ApplicationOverviewLoanSection = (props: IApplicationOverviewSectionProps) => {
  const { id, applicationFileId, className } = props;

  const { sectionReady, ready } = useContext(ApplicationOverviewContext)!;

  const [{ calculations }, sectionLoading] = useLoanOverviewSection(applicationFileId);

  const { propertyValuationAmount, downPayment, downPaymentPercent, loanAmount } =
    calculations || {};

  useEffect(() => {
    if (sectionLoading) {
      return;
    }

    sectionReady(id);
  }, [sectionLoading, id, sectionReady]);

  const responsive = useResponsive();
  const isTablet = responsive.TABLET.EXACT_OR_SMALLER;

  if (!ready) {
    return null;
  }

  const hasPropertyValue = !isNil(propertyValuationAmount);
  const hasDownPayment = !isNil(downPayment);
  const hasDownPaymentPercent = !isNil(downPaymentPercent);

  return (
    <Card compact className={className}>
      <SectionBlock>
        <BlockLabel>
          <T>Subject Property Est. Value</T>
        </BlockLabel>
        <BlockContentContainer>
          <BlockContent textAlign={isTablet ? 'left' : 'right'}>
            <BlockValue>
              {hasPropertyValue ? <Format.Currency value={propertyValuationAmount!} /> : <Dash />}
            </BlockValue>
          </BlockContent>
        </BlockContentContainer>
      </SectionBlock>
      <Divider compact />
      <SectionBlock>
        <BlockLabel>
          <T>Loan Amount</T>
        </BlockLabel>
        <BlockContentContainer>
          <BlockContent textAlign={isTablet ? 'left' : 'right'}>
            <BlockValue>
              {!isNil(loanAmount) ? <Format.Currency value={loanAmount!} /> : <Dash />}
            </BlockValue>
          </BlockContent>
        </BlockContentContainer>
      </SectionBlock>
      <Divider compact />
      <SectionBlock>
        <BlockLabel>
          <T>Down Payment</T>
        </BlockLabel>
        <BlockContentContainer>
          {hasDownPaymentPercent ? (
            <BlockContent textAlign={isTablet ? 'left' : 'right'}>
              <BlockValue>
                <Format.Number value={downPaymentPercent!} suffix="%" decimals={1} />
              </BlockValue>
            </BlockContent>
          ) : null}

          <BlockContent textAlign={isTablet ? 'left' : 'right'}>
            <BlockValue>
              {hasDownPayment ? <Format.Currency value={downPayment!} /> : <Dash />}
            </BlockValue>
          </BlockContent>
        </BlockContentContainer>
      </SectionBlock>
    </Card>
  );
};
