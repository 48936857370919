import { type IUseApolloQueryOptions, useMutation, useQuery } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../apollo';
import { type ApplicationStatusesQuery } from '../../apollo/graphql';
import { usePartnerId } from '../../hooks/use-partner-id';

export const QApplicationStatuses = graphql(`
  query ApplicationStatuses($partnerId: ID!) {
    partner(id: $partnerId) {
      id
      applicationFileStatuses {
        id
        name
        color
        index
      }
    }
  }
`);

const QApplicationStatus = graphql(`
  query ApplicationStatusForFile($applicationFileId: ID!) {
    applicationFile(id: $applicationFileId) {
      id
      status {
        id
        name
        color
        index
      }
    }
  }
`);

const MSetApplicationStatus = graphql(`
  mutation SetApplicationFileStatus($applicationFileId: ID!, $statusId: ID) {
    setApplicationFileStatus(applicationFileId: $applicationFileId, statusId: $statusId) {
      id
      status {
        id
        name
        color
        index
      }
    }
  }
`);

export type IApplicationStatus = NonNullable<
  ApplicationStatusesQuery['partner']
>['applicationFileStatuses'][0];

export const useApplicationStatuses = () => {
  const [partnerId] = usePartnerId();
  const [data, loading] = useQuery(
    QApplicationStatuses,
    { partnerId: partnerId! },
    { skip: !partnerId }
  );

  const result = data?.partner?.applicationFileStatuses ?? [];
  return [result, loading] as const;
};

export const useApplicationStatus = (
  applicationFileId: string,
  options: IUseApolloQueryOptions = {}
) => {
  const [data, loading] = useQuery(
    QApplicationStatus,
    {
      applicationFileId,
    },
    options
  );

  const result = data?.applicationFile?.status?.id;

  return [result, loading] as const;
};

export const useSetApplicationStatus = () => {
  const fn = useMutation(MSetApplicationStatus);
  return async (applicationFileId: string, statusId: string | null) => {
    await fn({ applicationFileId, statusId });
  };
};
