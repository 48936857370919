import {
  type AgentFolderInput,
  type FolderInput,
  type UpdatePartnerFoldersInput,
} from '../../../apollo/graphql';
import { ROOT_TREE_OPTION_KEY } from './constants';
import { type ManagedFolder, type ManagedFolderUser } from './manager';
import { type RequiredNotNull } from './types';

export const isFakeRootFolder = (folderId: string) => folderId === ROOT_TREE_OPTION_KEY;

const toAgentsServerInput = (agents: ManagedFolderUser[]): AgentFolderInput => {
  const created: string[] = [];
  const deleted: string[] = [];

  const changedAgents = agents.filter((agent) => agent.hasUnsavedChanges());

  changedAgents.forEach((agent) => {
    if (agent.isSoftDeleted()) {
      if (agent.isNew()) {
        return;
      }

      deleted.push(agent.getId());
      return;
    }

    if (agent.isNew()) {
      created.push(agent.getId());
    }
  });

  return {
    create: created,
    delete: deleted,
  };
};

const toNestedFolderChanges = (
  folders: ManagedFolder[],
  accum: RequiredNotNull<UpdatePartnerFoldersInput> = { create: [], update: [], delete: [] }
): UpdatePartnerFoldersInput => {
  folders.forEach((folder) => {
    if (folder.hasUnsavedChanges()) {
      if (folder.isSoftDeleted() && !folder.isNew()) {
        accum.delete.push(folder.getId());
      }

      if (folder.isNew() && !folder.isSoftDeleted()) {
        accum.create.push({
          id: folder.getId(),
          name: folder.getName(),
          description: folder.getDescription() || null,
          parentId: folder.getParentId() || null,
          agents: toAgentsServerInput(folder.getUsers()),
        });
      }

      if (folder.isEdited() && !folder.isNew() && !folder.isSoftDeleted()) {
        accum.update.push({
          id: folder.getId(),
          name: folder.getName(),
          description: folder.getDescription() || null,
          parentId: folder.getParentId() || null,
          agents: toAgentsServerInput(folder.getUsers()),
        });
      }
    }

    return toNestedFolderChanges(folder.getChildren(), accum);
  });

  return accum;
};

const toOrderedFolderChanges = (folders: ManagedFolder[]): UpdatePartnerFoldersInput => {
  const topLevelFolders = folders.filter((folder) => folder.getParentId() === undefined);

  return toNestedFolderChanges(topLevelFolders);
};

const toUnorderedFolderChanges = (folders: ManagedFolder[]): UpdatePartnerFoldersInput => {
  const created: FolderInput[] = [];
  const updated: FolderInput[] = [];
  const deleted: string[] = [];

  const changedFolders = folders.filter((folder) => folder.hasUnsavedChanges());

  changedFolders.forEach((folder) => {
    if (folder.isSoftDeleted()) {
      if (folder.isNew()) {
        return;
      }

      deleted.push(folder.getId());
      return;
    }

    if (folder.isNew()) {
      created.push({
        id: folder.getId(),
        name: folder.getName(),
        description: folder.getDescription() || null,
        parentId: folder.getParentId() || null,
        agents: toAgentsServerInput(folder.getUsers()),
      });
      return;
    }

    if (folder.isEdited()) {
      updated.push({
        id: folder.getId(),
        name: folder.getName(),
        description: folder.getDescription() || null,
        parentId: folder.getParentId() || null,
        agents: toAgentsServerInput(folder.getUsers()),
      });
    }
  });

  return {
    create: created,
    update: updated,
    delete: deleted,
  };
};

export const toServerInput = (
  folders: ManagedFolder[],
  respectNestingOrder = true
): UpdatePartnerFoldersInput => {
  if (respectNestingOrder) {
    // loop through the folders from the top level down (recursion)
    return toOrderedFolderChanges(folders);
  }

  // loop through the folders without caring about folder structure
  return toUnorderedFolderChanges(folders);
};
