import { spacing, TabRoute, TabRouter } from '@mortgagehippo/ds';
import styled from 'styled-components';

import { Content, Layout } from '../../layouts/main';
import { AccountTab } from './account-tab';
import { PasswordTab } from './password-tab';
import { ProfileTab } from './profile-tab';

const StyledTabRouter = styled(TabRouter)`
  margin-bottom: ${spacing(5)};
`;

export const AccountPage = () => (
  <Layout pageTitle="Account Settings">
    <Content>
      <StyledTabRouter contentPaddingV={5}>
        <TabRoute label="Account" path="/account/settings" component={AccountTab} exact />
        <TabRoute label="Password" path="/account/password" component={PasswordTab} exact />
        <TabRoute label="Profile" path="/account/profile" component={ProfileTab} exact />
      </StyledTabRouter>
    </Content>
  </Layout>
);
