import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { graphql } from '../apollo';
import { type PartnerAgentsQuery } from '../apollo/graphql';
import { usePartnerDomain } from './use-partner-domain';

const PER_PAGE = 10000;

export type IPartnerAgent = NonNullable<PartnerAgentsQuery['partner']>['agents']['items'][0];

export const QPartnerAgents = graphql(`
  query PartnerAgents($partnerId: ID!, $perPage: Int!) {
    partner(id: $partnerId) {
      id
      agents(options: { perPage: $perPage, orderBy: { name: "asc" } }) {
        items {
          id
          email
          name
        }
        itemsTotal
      }
    }
  }
`);

type IUsePartnerAgentsResult = [IPartnerAgent[], boolean, () => Promise<any>];

export const usePartnerAgents = (options: IUseApolloQueryOptions = {}): IUsePartnerAgentsResult => {
  const partnerId = usePartnerDomain();

  const [data, loading, , { refetch }] = useQuery(
    QPartnerAgents,
    {
      partnerId,
      perPage: PER_PAGE,
    },
    {
      fetchPolicy: 'network-only',
      ...options,
    }
  );

  const handleRefresh = useSafeCallback(async () => refetch({ partnerId, perPage: PER_PAGE }));

  const result = data?.partner?.agents?.items || [];

  return [result, loading, handleRefresh];
};
