import { type ISelectOption } from '@mortgagehippo/ds';
import { useMemo } from 'react';

import { useUserCan } from '$components/permissions';

import { usePartner } from '../../hooks/use-partner';
import { useSites } from '../../hooks/use-sites';
import { toOwnerId } from './util';

export const useReportOwners = () => {
  const [partner, partnerLoading] = usePartner();
  const [sites, sitesLoading] = useSites();
  const [can, canReady] = useUserCan();

  const loading = partnerLoading || sitesLoading || !canReady;

  const owners = useMemo(() => {
    const options: ISelectOption[] = [];

    if (loading) {
      return options;
    }

    if (can.VIEW_PARTNER_REPORTS && partner) {
      options.push({
        label: 'All Sites',
        value: toOwnerId('partner', partner.id),
      });
    }

    if (sites) {
      sites.forEach(({ label, value }) => {
        options.push({
          label,
          value: toOwnerId('site', value),
        });
      });
    }

    return options;
  }, [can.VIEW_PARTNER_REPORTS, loading, partner, sites]);

  return [owners, loading] as const;
};
