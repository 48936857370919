import { useMutation } from '@mortgagehippo/apollo-hooks';
import {
  type FormSubmitHandler,
  notifications,
  useCustomizations,
  useModal,
} from '@mortgagehippo/ds';
import { type ApplicationFileTeamAgentInput } from '@mortgagehippo/tasks/build/gql/graphql';
import { toArray } from '@mortgagehippo/util';
import { useCallback, useEffect, useMemo } from 'react';

import { MSetApplicationFileTeamAgents } from '$components/actions/manage-application-team-action/queries';

import { useSiteTeamTemplates } from '../../../hooks/use-site-team-templates';
import { type IActionProps, type IManageApplicationTeamAction } from '../types';
import { ManageApplicationTeamActionModal } from './manage-application-team-action-modal';
import { type FormValues } from './types';
import { useApplicationTeam } from './use-application-team';

export const ManageApplicationTeamAction = (props: IActionProps<IManageApplicationTeamAction>) => {
  const { action, onSubmit, onDone, onCancel } = props;
  const { applicationFileId } = action;

  const [isOpen, , closeModal] = useModal(true);

  const customizations = useCustomizations();
  const title = customizations.text('manageApplicationTeam:manage-team', 'Manage Team');

  const [siteId, team, teamLoading] = useApplicationTeam(applicationFileId, {
    fetchPolicy: 'no-cache',
  });

  const [teamTemplates, teamTemplatesLoading] = useSiteTeamTemplates(siteId!, {
    fetchPolicy: 'no-cache',
    skip: !siteId,
  });

  const loading = teamTemplatesLoading || teamLoading;
  const setApplicationFileTeamAgents = useMutation(MSetApplicationFileTeamAgents);

  const loadingFailed = !loading && !team;
  useEffect(() => {
    if (loadingFailed) {
      notifications.error({
        message: 'There was an error processing your request, please try again later',
      });
      onCancel();
    }
  }, [onCancel, loadingFailed]);

  const handleSubmit: FormSubmitHandler<any> = useCallback(
    async (values: Partial<FormValues>) => {
      try {
        onSubmit();

        const teamAgents = Object.entries(values.jobFunctions || {}).reduce(
          (acum: ApplicationFileTeamAgentInput[], entry) => {
            const [key, agentIds] = entry;
            const [_, jobFunctionId] = key.split(':');
            toArray(agentIds).forEach((agentId) => {
              acum.push({ jobFunctionId: jobFunctionId!, agentId });
            });
            return acum;
          },
          []
        );

        await setApplicationFileTeamAgents({ applicationFileId, teamAgents });

        notifications.success({ message: 'The team was successfully updated.' });

        onDone();
      } catch (error) {
        notifications.error({
          message: 'There was an error processing your request, please try again later',
        });
      }
    },
    [applicationFileId, onDone, onSubmit, setApplicationFileTeamAgents]
  );

  const handleRequestClose = useCallback(() => {
    closeModal(onCancel);
  }, [onCancel, closeModal]);

  const initialValues: FormValues = useMemo(() => {
    if (!team) {
      return { jobFunctions: {} };
    }

    const jobFunctions: FormValues['jobFunctions'] = {};
    for (const jobFunction of team) {
      if (jobFunction.primary) {
        jobFunctions[`id:${jobFunction.id}`] = jobFunction.agents[0]?.id;
      } else {
        jobFunctions[`id:${jobFunction.id}`] = jobFunction.agents.map((a) => a.id);
      }
    }

    return {
      jobFunctions,
    };
  }, [team]);

  return (
    <ManageApplicationTeamActionModal
      title={title}
      onSubmit={handleSubmit}
      onRequestClose={handleRequestClose}
      initialValues={initialValues}
      team={team || []}
      teamTemplates={teamTemplates}
      isOpen={isOpen}
      disableOverlayClickClose
      loading={loading || loadingFailed}
    />
  );
};
