import {
  Currency,
  Divider,
  Form,
  Format,
  type FormSubmitHandler,
  Input,
  type ISelectOption,
  Numeric,
  Select,
  spacing,
  T,
  USZipCode,
  YesNo,
} from '@mortgagehippo/ds';
import { Options } from '@mortgagehippo/tasks';
import styled from 'styled-components';

import { ApplicationPricingFormField } from './application-pricing-form-field';
import { ApplicationPricingFormSubmit } from './application-pricing-form-submit';

const formatCurrencyValue = (initialValue: any) => <Format.Currency value={initialValue} />;

const formatNumberValue = (initialValue: any) => <Format.Number value={initialValue} />;

const formatZipCodeValue = (initialValue: any) => <Format.USZipCode value={initialValue} />;

const formatYesNoValue = (initialValue: any) => (initialValue === true ? 'Yes' : 'No');

const formatSelectValue = (options: ISelectOption[]) => (initialValue: any) => {
  const selectedOption = options.find((o) => o.value === initialValue);

  return selectedOption && selectedOption.label;
};

const StyledDivider = styled(Divider)`
  margin: ${spacing(4)} 0;
`;

interface IApplicationPricingFormProps {
  initialValues?: any;
  disabled?: boolean;
  onSubmit?: FormSubmitHandler;
}

export const ApplicationPricingForm = (props: IApplicationPricingFormProps) => {
  const { initialValues, disabled, onSubmit } = props;

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit}>
      <ApplicationPricingFormField formatInitialValue={formatCurrencyValue}>
        <Currency.Box label="Loan amount" name="loan_amount" size="sm" disabled={disabled} />
      </ApplicationPricingFormField>
      <ApplicationPricingFormField>
        <Input.Box name="county" label="County" size="sm" disabled={disabled} />
      </ApplicationPricingFormField>
      <ApplicationPricingFormField formatInitialValue={formatZipCodeValue}>
        <USZipCode.Box name="zip_code" label="Zip code" size="sm" disabled={disabled} />
      </ApplicationPricingFormField>
      <ApplicationPricingFormField formatInitialValue={formatSelectValue(Options.PropertyTypeName)}>
        <Select.Box
          name="property_type"
          label="Property type"
          size="sm"
          options={Options.PropertyTypeName}
          disabled={disabled}
        />
      </ApplicationPricingFormField>
      <ApplicationPricingFormField formatInitialValue={formatSelectValue(Options.LoanPurposeType)}>
        <Select.Box
          name="loan_type"
          label="Loan purpose"
          size="sm"
          options={Options.LoanPurposeType}
          disabled={disabled}
        />
      </ApplicationPricingFormField>
      <ApplicationPricingFormField formatInitialValue={formatNumberValue}>
        <Numeric.Box
          name="credit_score"
          label="Credit score used for pricing"
          size="sm"
          disabled={disabled}
        />
      </ApplicationPricingFormField>
      <StyledDivider />
      <ApplicationPricingFormField
        formatInitialValue={formatSelectValue(Options.PropertyUsageType)}
      >
        <Select.Box
          name="property_usage_type"
          label="Property use"
          size="sm"
          options={Options.PropertyUsageType}
          disabled={disabled}
        />
      </ApplicationPricingFormField>
      <ApplicationPricingFormField formatInitialValue={formatCurrencyValue}>
        <Currency.Box
          name="cash_out_amount"
          label="Cash out amount"
          size="sm"
          disabled={disabled}
        />
      </ApplicationPricingFormField>
      <ApplicationPricingFormField formatInitialValue={formatCurrencyValue}>
        <Currency.Box name="down_payment" label="Down payment" size="sm" disabled={disabled} />
      </ApplicationPricingFormField>
      <ApplicationPricingFormField formatInitialValue={formatNumberValue}>
        <Numeric.Box name="number_of_units" label="Number of units" size="sm" disabled={disabled} />
      </ApplicationPricingFormField>
      <StyledDivider />
      <ApplicationPricingFormField
        formatInitialValue={formatSelectValue(Options.CitizenResidencyType)}
      >
        <Select.Box
          name="citizenship_type"
          label="Citizenship"
          size="sm"
          options={Options.CitizenResidencyType}
          disabled={disabled}
        />
      </ApplicationPricingFormField>
      <ApplicationPricingFormField formatInitialValue={formatYesNoValue}>
        <YesNo.Box
          name="is_construction_loan"
          label="Construction loan?"
          size="sm"
          disabled={disabled}
        />
      </ApplicationPricingFormField>
      <ApplicationPricingFormField formatInitialValue={formatYesNoValue}>
        <YesNo.Box
          name="property_foreclosed"
          label="Property foreclosed upon?"
          size="sm"
          disabled={disabled}
        />
      </ApplicationPricingFormField>
      <ApplicationPricingFormField formatInitialValue={formatYesNoValue}>
        <YesNo.Box
          name="has_declared_bankruptcy"
          label="Declared bankruptcy?"
          size="sm"
          disabled={disabled}
        />
      </ApplicationPricingFormField>
      <ApplicationPricingFormSubmit disabled={disabled}>
        <T>Get Pricing</T>
      </ApplicationPricingFormSubmit>
    </Form>
  );
};
