import { Alert, Button, ButtonLink, Divider, Input, spacing, T } from '@mortgagehippo/ds';
import styled from 'styled-components';

import { type ISite } from '../../../hooks/use-site';
import { type IApplicationApplicant } from '../use-application-file-data';
import { ApplicationInvitesApplicantCopyButton } from './application-invites-copy-button';

const CopyLink = styled.div`
  position: relative;
`;

const CopyInput = styled(Input.Input)`
  padding-right: ${spacing(7)} !important;
`;

const CopyButton = styled(ApplicationInvitesApplicantCopyButton)`
  position: absolute !important;
  right: 0;
  top: 0;

  && span {
    vertical-align: baseline;
  }
`;

const StyledAlert = styled(Alert)`
  margin-bottom: ${spacing(4)};
`;

interface IApplicationInvitesApplicantContentProps {
  site: ISite;
  applicant: IApplicationApplicant;
  onInvite: () => void;
  onUpdateEmail?: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const handleChange = () => {};

export const ApplicationInvitesApplicantContent = (
  props: IApplicationInvitesApplicantContentProps
) => {
  const { site, applicant, onInvite, onUpdateEmail } = props;
  const { name: fullName, firstName, claimId, email, unregistered, sharingEmail } = applicant;

  const claimUrl = `https://${site.domain}/claim/${claimId}`;
  const inviteButtonName = firstName || fullName;

  if (!unregistered) {
    return (
      <StyledAlert type="success">
        <T cid="pageApplication:tabInvitationLink.alertAccepted.message">
          The borrower has accepted the invitation.
        </T>
      </StyledAlert>
    );
  }

  if (!email || sharingEmail) {
    return (
      <>
        {!!onUpdateEmail && (
          <Alert type="warning" inline>
            <T cid="tabInvitationLink.alertNoEmail.message" cvars={{ fullName }}>
              {fullName} has no email. To send an invite, you must{' '}
            </T>
            <ButtonLink onClick={onUpdateEmail}>
              <T cid="tabInvitationLink.alertNoEmail.button.label">provide an email</T>
            </ButtonLink>
            .
          </Alert>
        )}
        {!onUpdateEmail && (
          <Alert type="warning" inline>
            <T cid="tabInvitationLink.alertNoEmailNoAddButton.message" cvars={{ fullName }}>
              {fullName} has no email. To send an invite, you must provide an email.
            </T>
          </Alert>
        )}
      </>
    );
  }

  return (
    <>
      <StyledAlert type="warning">
        <T cid="tabInvitationLink.alertNotAccepted.message">
          The borrower has not accepted the invitation yet.
        </T>
      </StyledAlert>
      <Button onClick={onInvite} importance="primary" block compact>
        <T cvars={{ name: inviteButtonName }}>Invite {inviteButtonName}</T>
      </Button>
      <Divider>Or copy link</Divider>
      <CopyLink>
        <CopyInput name="link" disabled value={claimUrl} onChange={handleChange} compact />
        <CopyButton claimUrl={claimUrl} compact>
          Copy
        </CopyButton>
      </CopyLink>
    </>
  );
};
