import {
  type AlertType,
  fontSize,
  Icon,
  type IconNames,
  MediaBreakpoint,
  palette,
  spacing,
} from '@mortgagehippo/ds';
import { type ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { LAYOUT_DIMENSIONS } from '../../layouts/application';

interface IApplicationAlertProps {
  type: AlertType;
  icon?: IconNames;
  children?: ReactNode;
}

const StyledIcon = styled(Icon)`
  margin-right: ${spacing(1)};
`;

const Message = styled.span``;

const Wrapper = styled.div<{ type: AlertType }>`
  height: ${LAYOUT_DIMENSIONS.alertHeight}px;
  padding: 0 ${spacing(4)};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  font-size: ${fontSize('xs')};
  line-height: 25px; // vertical centering in parent

  ${MediaBreakpoint.PHONE} {
    height: auto;
    padding: ${spacing(3)};
    white-space: normal;
    line-height: ${fontSize('xs')};
    text-align: left;
  }

  ${(p) => {
    switch (p.type) {
      case 'error':
        return css`
          background-color: ${palette('danger50')};
          color: ${palette('danger900')};

          ${StyledIcon} {
            color: ${palette('danger600')};
          }
        `;
      case 'info':
        return css`
          background-color: ${palette('secondary50')};
          color: ${palette('secondary900')};

          ${StyledIcon} {
            color: ${palette('secondary600')};
          }
        `;
      case 'success':
        return css`
          background-color: ${palette('success50')};
          color: ${palette('success900')};

          ${StyledIcon} {
            color: ${palette('success600')};
          }
        `;
      case 'warning':
      default: {
        return css`
          background-color: ${palette('warning50')};
          color: ${palette('warning900')};

          ${StyledIcon} {
            color: ${palette('warning600')};
          }
        `;
      }
    }
  }}
`;

export const ApplicationAlert = (props: IApplicationAlertProps) => {
  const { type, icon, children } = props;

  return (
    <Wrapper type={type} role={(type === 'error' && 'alert') || 'status'}>
      {icon ? <StyledIcon name={icon} size="xs" /> : null}
      <Message>{children}</Message>
    </Wrapper>
  );
};
