import { Alert, Content as CustomizableContent } from '@mortgagehippo/ds';
import { type ReactNode } from 'react';

interface IApplicationTasksLockedContentProps {
  children?: ReactNode;
}

export const ApplicationTasksLockedContent = (props: IApplicationTasksLockedContentProps) => {
  const { children } = props;

  return (
    <>
      {children}
      <Alert type="info">
        <CustomizableContent cid="tasks:status.locked.description">
          This task can&apos;t be edited or completed at this moment because it depends on another
          task being completed first.
        </CustomizableContent>
      </Alert>
    </>
  );
};
