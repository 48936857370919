import { Button, SubmitButton, T } from '@mortgagehippo/ds';
import { TaskState } from '@mortgagehippo/tasks';

import { ActionType, useDispatchAction } from '$components/actions';
import { useApplicationFileCan } from '$components/permissions';

import { type IApplicationFileTask } from '../use-application-file-tasks';
import { ApplicationTaskReopenButton } from './application-task-reopen-button';

const isEditable = (task: IApplicationFileTask) => {
  const { custom, editable, followUp } = task;

  return (custom || followUp) && editable && !(task.state === TaskState.completed || task.isFrozen);
};

const isDeletable = (task: IApplicationFileTask) => {
  const { custom } = task;
  const documentUploadTask = task.typeName === 'DocumentSubmissionTask';

  return (custom || documentUploadTask) && !(task.state === TaskState.completed || task.isFrozen);
};

interface IApplicationTaskActionProps {
  applicationFileId: string;
  task: IApplicationFileTask;
}

export const ApplicationTaskActions = (props: IApplicationTaskActionProps) => {
  const { applicationFileId, task } = props;
  const { custom } = task;

  const [can] = useApplicationFileCan(applicationFileId);

  const dispatch = useDispatchAction();

  const handleEditTask = () => {
    if (custom) {
      dispatch({
        type: ActionType.EDIT_CUSTOM_TASK,
        taskId: task.id,
        taskType: task.typeName as any,
        applicationFileId,
      });
    } else {
      // use simplified, limited version of editing
      dispatch({
        type: ActionType.EDIT_FOLLOW_UP_TASK,
        taskId: task.id,
      });
    }
  };

  const handleDeleteTask = async () => {
    dispatch({
      type: ActionType.DELETE_TASK,
      taskId: task.id,
      applicationFileId,
    });
  };

  return (
    <>
      {can.UPDATE_TASK && isEditable(task) ? (
        <Button
          iconButton
          icon="edit"
          size="xs"
          importance="tertiary"
          onClick={handleEditTask}
          dataAttributes={{
            'task-edit-button': task.key,
          }}
        >
          <T cid="application_tasks:edit_task">Edit</T>
        </Button>
      ) : null}
      {can.DELETE_TASK && isDeletable(task) ? (
        <Button
          iconButton
          icon="delete"
          size="xs"
          importance="tertiary"
          onClick={handleDeleteTask}
          dataAttributes={{
            'task-delete-button': task.key,
          }}
        >
          <T cid="application_tasks:delete_task">Delete</T>
        </Button>
      ) : null}

      {task.state === TaskState.open && !task.isFrozen && can.UPDATE_TASK ? (
        <SubmitButton
          icon="right-arrow"
          iconLocation="right"
          importance="primary"
          size="xs"
          tooltip="Mark this task as completed. This will also run any on-complete actions associated with this task but only if this is the first time it is marked as completed."
          tooltipProps={{ showDelayMs: 1000 }}
          dataAttributes={{
            'task-complete-button': task.key,
          }}
        >
          <T cid="application_tasks:complete_task">Complete task</T>
        </SubmitButton>
      ) : null}

      {task.state === TaskState.completed && !task.isFrozen && can.UPDATE_TASK ? (
        <ApplicationTaskReopenButton
          taskId={task.id}
          taskKey={task.key}
          applicationFileId={applicationFileId}
          isFollowUp={task.followUp}
        />
      ) : null}
    </>
  );
};
