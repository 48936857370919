import { ModalDialog, useCustomizations, useModal } from '@mortgagehippo/ds';
import { useSagaClickHandler } from '@mortgagehippo/tasks';
import { useCallback } from 'react';

import { useDeleteAgent } from '$components/actions/delete-agent-action/use-delete-agent';

import { type IActionProps, type IDeleteAgentAction } from '../types';

export const DeleteAgentAction = (props: IActionProps<IDeleteAgentAction>) => {
  const { action, onSubmit, onDone, onCancel } = props;
  const { agentId } = action;
  const [isOpen, , closeModal] = useModal(true);

  const customizations = useCustomizations();
  const title = customizations.text('delete-agent-action:confirm-title', 'Warning');
  const explanation = customizations.text(
    'delete-agent-action:confirm-explanation',
    'Are you sure you want to delete this user? This action cannot be undone.'
  );

  const deleteAgent = useDeleteAgent();

  const [handleOk] = useSagaClickHandler({
    infoMessageCid: 'delete-agent-action:info',
    successMessageCid: 'delete-agent-action:success',
    errorMessageCid: 'delete-agent-action:error',
    infoMessage: 'Deleting User',
    successMessage: 'The user was deleted successfully.',
    errorMessage: 'There was an error processing your request, please try again later.',
    async onClick(setAsyncId) {
      const asyncId = await deleteAgent(agentId);
      closeModal(() => {
        setAsyncId(asyncId);
        onSubmit();
      });
    },
    async onDone() {
      closeModal(onDone);
    },
  });

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  // TODO: use hook useModalConfirm
  return (
    <ModalDialog
      type="error"
      title={title}
      onOk={handleOk}
      onRequestClose={handleCloseModal}
      isOpen={isOpen}
    >
      {explanation}
    </ModalDialog>
  );
};
