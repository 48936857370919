import { AuthState, useAuth } from '@mortgagehippo/auth';
import {
  borderRadiuses,
  fontSize,
  fontWeight,
  palette,
  shadow,
  spacing,
  T,
  Tag,
  useCustomizations,
} from '@mortgagehippo/ds';
import {
  type IBaseTask,
  type ITaskApplicant,
  TaskDueDate,
  TaskState,
  TaskStateIcon,
} from '@mortgagehippo/tasks';
import { AnimatePresence, motion } from 'framer-motion';
import { trim } from 'lodash-es';
import { type MouseEvent } from 'react';
import { Link, type LinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const ListItem = styled.div<{
  spacingLeft?: number;
  spacingRight?: number;
}>`
  display: block;
  padding-left: ${(p) => (p.spacingLeft !== undefined ? `${p.spacingLeft}px` : spacing(2))};
  padding-right: ${(p) => (p.spacingRight !== undefined ? `${p.spacingRight}px` : spacing(2))};
  font-size: ${fontSize('sm')};
  line-height: 1.25em;
`;

const ListItemIcon = styled('div')<{ iconColor?: string }>`
  flex: 0 0 auto;
  padding: ${spacing(3)} ${spacing(2)} 0 0;

  svg {
    vertical-align: middle;
    ${(p) =>
      p.iconColor &&
      css`
        color: ${p.iconColor};
      `}
  }
`;

const ListItemTitle = styled.div`
  flex: 1 1 auto;
  padding: ${spacing(3)} ${spacing(1)} ${spacing(3)} 0;
  transition: color 400ms;
`;

const ListItemTitleName = styled.span`
  font-weight: ${fontWeight('light')};
  white-space: nowrap;
`;

const TaskDueDateContainer = styled.div`
  margin-top: ${spacing(1)};
`;

const StyledLink = styled(
  ({
    isActive: _isActive,
    selectedBackground: _selectedBackground,
    selectedShadow: _selectedShadow,
    selectedColor: _selectedColor,
    normalColor: _normalColor,
    hoverColor: _hoverColor,
    hoverBackground: _hoverBackground,
    completedColor: _completedColor,
    lockedColor: _lockedColor,
    isCompleted: _isCompleted,
    isLocked: _isLocked,
    isFrozen: _isFrozen,
    borderRadiusLeft: _borderRadiusLeft,
    borderRadiusRight: _borderRadiusRight,
    ...rest
  }) => <Link {...rest} />
)<{
  isActive: boolean;
  selectedBackground?: string;
  selectedColor?: string;
  selectedShadow: number;
  isCompleted?: boolean;
  isLocked?: boolean;
  isFrozen?: boolean;
  completedColor?: string;
  lockedColor?: string;
  normalColor?: string;
  hoverColor?: string;
  hoverBackground?: string;
  borderRadiusLeft?: number;
  borderRadiusRight?: number;
}>`
  && {
    display: flex;
    position: relative;
    text-decoration: none !important;
    padding: 0 ${spacing(4)};
    transition: all 400ms;
    border-radius: ${borderRadiuses(2, 2, 2, 2)};
    ${(p) =>
      p.borderRadiusLeft !== undefined &&
      css`
        border-top-left-radius: ${p.borderRadiusLeft}px;
        border-bottom-left-radius: ${p.borderRadiusLeft}px;
      `}
    ${(p) =>
      p.borderRadiusRight !== undefined &&
      css`
        border-top-right-radius: ${p.borderRadiusRight}px;
        border-bottom-right-radius: ${p.borderRadiusRight}px;
      `}
    z-index: 0;
    margin: 0;
    box-shadow: none;
    background: transparent;

    ${ListItemTitle} {
      font-weight: ${fontWeight('semibold')};
      color: ${(p) => p.normalColor || palette('neutral900')};
    }

    ${(p) =>
      p.isCompleted &&
      css`
        ${ListItemTitle} {
          font-weight: ${fontWeight('regular')};
          color: ${p.completedColor || palette('neutral600')};
        }
      `}

    ${(p) =>
      p.isLocked &&
      css`
        ${ListItemTitle} {
          font-weight: ${fontWeight('regular')};
          color: ${p.lockedColor || palette('neutral600')};
        }
      `}
      
    ${(p) =>
      p.isFrozen &&
      css`
        ${ListItemTitle} {
          font-weight: ${fontWeight('regular')};
          color: ${p.completedColor || palette('neutral600')};
        }
      `}

    ${(p) =>
      !p.isActive &&
      css`
        &:hover {
          background: ${p.hoverBackground || palette('neutral50')};

          ${ListItemTitle} {
            color: ${p.hoverColor || palette('primary600')};
          }
        }
      `}

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }

    &:focus {
      z-index: 10;
    }

    ${(p) =>
      p.isActive &&
      css`
        box-shadow: ${shadow(p.selectedShadow)};
        background: ${p.selectedBackground || palette('neutral700')};
        z-index: 5;

        ${ListItemTitle} {
          font-weight: ${fontWeight('bold')};
          color: ${p.selectedColor || palette('white')};
        }
      `}
  }
`;

const ListItemLink = (
  props: LinkProps & {
    to: string;
    isActive?: boolean;
    isLocked?: boolean;
    isCompleted?: boolean;
    isFrozen?: boolean;
    visible: boolean;
  }
) => {
  const { to, children, isActive, visible, isLocked, isCompleted, isFrozen, ...rest } = props;

  const customizations = useCustomizations();
  const contentBackground = customizations.color('app:content.background');
  const selectedBackground = customizations.color('app:taskList.item.selected.background');
  const selectedColor = customizations.color('app:taskList.item.selected.color');
  const selectedShadow = customizations.shadow('app:taskList.item.selected.shadow', 1);
  const borderRadiusLeft = customizations.number('app:taskList.item.borderRadius.left');
  const borderRadiusRight = customizations.number('app:taskList.item.borderRadius.right');
  const completedColor = customizations.color('app:taskList.item.completed.color');
  const lockedColor = customizations.color('app:taskList.item.locked.color');
  const normalColor = customizations.color('app:taskList.item.color');
  const hoverColor = customizations.color('app:taskList.item.hover.color');
  const hoverBackground = customizations.color('app:taskList.item.hover.background');

  return (
    <StyledLink
      to={to}
      aria-label={(isActive && 'Selected Task') || undefined}
      aria-current={(isActive && 'location') || undefined}
      tabIndex={(!visible && '-1') || undefined}
      isActive={isActive}
      selectedColor={selectedColor}
      selectedBackground={selectedBackground || contentBackground || undefined}
      selectedShadow={selectedShadow}
      completedColor={completedColor}
      lockedColor={lockedColor}
      normalColor={normalColor}
      hoverColor={hoverColor}
      hoverBackground={hoverBackground}
      isLocked={isLocked}
      isCompleted={isCompleted}
      isFrozen={isFrozen}
      borderRadiusLeft={borderRadiusLeft}
      borderRadiusRight={borderRadiusRight}
      {...rest}
    >
      {children}
    </StyledLink>
  );
};

const TaskTitle = styled.span`
  vertical-align: middle;
`;

const TaskTitleNew = styled(motion.span)`
  vertical-align: middle;
  white-space: nowrap;
  /* fontSize needed to make the &nbsp; small enough not to cause taller line */
  font-size: ${fontSize('xs')};
`;

const NewTag = styled(Tag)`
  margin: 0;
`;

export interface ITasksListMenuGroupItemProps<T extends IBaseTask, A extends ITaskApplicant> {
  task: T;
  isActive?: boolean;
  applicant: A;
  showName?: boolean;
  showInitial?: boolean;
  to: string;
  onClick?: (e: MouseEvent) => void;
  showNewTag?: boolean;
}

export const TasksListMenuGroupItem = <T extends IBaseTask, A extends ITaskApplicant>(
  props: ITasksListMenuGroupItemProps<T, A>
) => {
  const { task, isActive, applicant, showName, showInitial, to, onClick, showNewTag } = props;

  const customizations = useCustomizations();
  const selectedIconColor = customizations.color('app:taskList.item.selected.icon.color', 'white');
  const completedIconColor = customizations.color('app:taskList.item.completed.icon.color');
  const lockedIconColor = customizations.color('app:taskList.item.locked.icon.color');
  const normalIconColor = customizations.color('app:taskList.item.icon.color');
  const spacingLeft = customizations.number('app:taskList.item.spacing.left');
  const spacingRight = customizations.number('app:taskList.item.spacing.right');

  const applicantInitial =
    (showInitial && applicant.lastName && `${applicant.lastName[0]!.toUpperCase()}.`) || '';
  const applicantName = (applicant.firstName &&
    trim(`${applicant.firstName} ${applicantInitial}`)) || (
    <>
      <T cid="pageTasks:taskList.borrower.genericLabel">Borrower</T>
      {` #${(applicant.position || 0) + 1}`}
    </>
  );

  const [, authState] = useAuth();
  const requiresAuth =
    task &&
    task.meta &&
    task.meta.requiresAuthentication &&
    authState !== AuthState.AUTHENTICATED_ACCOUNT;

  const iconColor = isActive
    ? selectedIconColor
    : (task.state === TaskState.completed && !task.isAlerted && completedIconColor) ||
      (!!requiresAuth && lockedIconColor) ||
      (task.state === TaskState.open && !task.isAlerted && normalIconColor) ||
      (task.state === TaskState.locked && lockedIconColor) ||
      undefined;

  return (
    /*
     * the data-active prop is used in tasks-page, or other upper containers to
     *  control autoscroll behavior of the active task
     */
    <ListItem
      spacingLeft={spacingLeft}
      spacingRight={spacingRight}
      data-active={isActive ? 'true' : undefined}
    >
      <ListItemLink
        to={to}
        isActive={isActive}
        visible
        isLocked={task.state === TaskState.locked || !!requiresAuth}
        isCompleted={task.state === TaskState.completed}
        isFrozen={task.isFrozen}
        onClick={onClick}
        data-task-list-group-item-link={task.key}
      >
        <ListItemIcon iconColor={iconColor}>
          <TaskStateIcon
            state={requiresAuth ? TaskState.locked : task.state}
            alerted={task.isAlerted}
            frozen={task.isFrozen}
            size="md"
          />
        </ListItemIcon>
        <ListItemTitle>
          <div>
            <TaskTitle>
              {!task.common && showName ? (
                <ListItemTitleName>{applicantName}:&nbsp;</ListItemTitleName>
              ) : null}
              {task.meta.title}
            </TaskTitle>
            <AnimatePresence initial={false}>
              {task.isNew && showNewTag ? (
                <TaskTitleNew
                  key={`${task.id}:new`}
                  animate={{
                    opacity: 1,
                  }}
                  exit={{
                    opacity: 0,
                    transition: { duration: 1, delay: 2 },
                  }}
                >
                  &nbsp;
                  <NewTag size="xs" color="danger" inverted>
                    new
                  </NewTag>
                </TaskTitleNew>
              ) : null}
            </AnimatePresence>
          </div>
          {!!task.dueDate && (
            <TaskDueDateContainer>
              <TaskDueDate dueDate={task.dueDate} />
            </TaskDueDateContainer>
          )}
        </ListItemTitle>
      </ListItemLink>
    </ListItem>
  );
};
