import { type ReactElement, useEffect } from 'react';

import { history } from '../../services/history';
import { useGetRedirectPath } from '.';

export interface ILinkActivityEventProps {
  path: string;
  loadingComponent: ReactElement;
  errorComponent: ReactElement;
}

export const LinkActivityEvent = (props: ILinkActivityEventProps) => {
  const { path, loadingComponent, errorComponent } = props;

  const [redirectPath, redirectPathLoading] = useGetRedirectPath(path || '');

  useEffect(() => {
    if (redirectPath) {
      history.replace(redirectPath);
    }
  }, [redirectPath]);

  const showError = !redirectPathLoading && !redirectPath;

  if (showError) {
    return errorComponent;
  }

  return loadingComponent;
};
