import {
  Card,
  type ISearchFields,
  type ISearchFilter,
  SearchFilterList,
  spacing,
} from '@mortgagehippo/ds';
import { type ReactNode } from 'react';
import styled from 'styled-components';

const FiltersList = styled(Card)`
  margin-bottom: ${spacing(4)};
`;

interface ISmartviewModalFiltersGroupProps {
  filters: ISearchFilter[];
  searchFields?: ISearchFields;
  onAddFilter: (filter: ISearchFilter) => void;
  onRemoveFilter: (index: number) => void;
  children?: ReactNode;
}

export const SmartviewModalFilterGroup = (props: ISmartviewModalFiltersGroupProps) => {
  const { filters, searchFields, onAddFilter, onRemoveFilter, children } = props;

  return (
    <FiltersList raised={2}>
      {children}
      <SearchFilterList
        fields={searchFields || {}}
        filters={filters}
        onAdd={onAddFilter}
        onRemove={onRemoveFilter}
      />
    </FiltersList>
  );
};
