import { useAuthService } from '@mortgagehippo/auth';
import { Alert, Button, T } from '@mortgagehippo/ds';
import qs from 'qs';
import { type RouteComponentProps } from 'react-router';

import { Content, Layout } from '../../layouts/login';

export const SessionTimeoutPage = (props: RouteComponentProps) => {
  const auth = useAuthService();
  const { location } = props;
  const { search } = location;
  const { n: returnTo } = qs.parse(search || '', {
    ignoreQueryPrefix: true,
  });

  const handleLogin = () => {
    auth.login(returnTo as string);
  };

  return (
    <Layout title="Session Timeout">
      <Content>
        <Alert type="warning">
          Hi! For your security we have signed you out after a period of inactivity.
        </Alert>

        <Button
          importance="primary"
          icon="right-arrow"
          iconLocation="right"
          onClick={handleLogin}
          block
        >
          <T cid="session-timeout:button.label">Sign in</T>
        </Button>
      </Content>
    </Layout>
  );
};
