import {
  Checkbox,
  Col,
  Currency,
  Format,
  HelpButton,
  Input,
  type ISelectOption,
  Numeric,
  Row,
  Select,
  spacing,
  TextArea,
  useFormValue,
} from '@mortgagehippo/ds';
import {
  type ILiability,
  type ILiabilityVerifiedData,
  LiabilityType,
  Verification,
} from '@mortgagehippo/tasks';
import { isEmpty, isNil } from 'lodash-es';
import { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { assetToString, type IModel } from './util';

const StyledVerification = styled(Verification)`
  margin-top: -20px;
  margin-bottom: 20px;
`;

const StyledCheckbox = styled(Checkbox.Box)`
  padding: 0;
  margin-bottom: ${spacing(2)};
`;

interface ICreditCheckLiabilitiesFormProps {
  typeOptions?: ISelectOption[];
  liability?: ILiability;
  model: IModel;
  verifiedData?: ILiabilityVerifiedData;
  requireMonthlyPayment?: boolean;
}

export const CreditCheckLiabilitiesForm = (props: ICreditCheckLiabilitiesFormProps) => {
  const { typeOptions, liability, model, verifiedData, requireMonthlyPayment } = props;

  const [typeName] = useFormValue('type_name');
  const [unpaidBalance] = useFormValue('unpaid_balance_amount');
  const [monthlyPayment] = useFormValue('monthly_payment_amount');
  const [, setMonthsLeft] = useFormValue('months_left');
  const [, setExcluded] = useFormValue('excluded');

  const syncExcludedField = useCallback(
    (e: any) => {
      if (e) {
        setExcluded(e);
      }
    },
    [setExcluded]
  );

  const monthsLeft =
    !isNil(unpaidBalance) && !isNil(monthlyPayment)
      ? Math.ceil(unpaidBalance / monthlyPayment)
      : undefined;

  useEffect(() => {
    if (Number.isFinite(monthsLeft)) {
      setMonthsLeft(monthsLeft);
    } else {
      setMonthsLeft(undefined);
    }
  }, [monthsLeft, setMonthsLeft]);

  const isVerified = !isEmpty(verifiedData);

  const {
    unpaid_balance_amount: verifiedBalance,
    monthly_payment_amount: verifiedMonthlyPayment,
    __verified_at: verifiedAt,
    __verified_by: verifiedBy,
  } = verifiedData || {};

  const verifiedMonthsLeft =
    !isNil(verifiedBalance) && !isNil(verifiedMonthlyPayment)
      ? Math.ceil(verifiedBalance / verifiedMonthlyPayment)
      : undefined;
  const showVerifiedMonthsLeft = !isNil(verifiedMonthsLeft) && Number.isFinite(verifiedMonthsLeft);

  const realEstateOwnedOptions: ISelectOption[] = [{ value: null, label: 'None' }];
  model.realEstateOwned.forEach((reo) => {
    const otherLiability = model.getLiabilityForRealEstateOwned(reo);
    const isReoTaken = otherLiability && otherLiability !== liability;
    realEstateOwnedOptions.push({
      value: reo.id || reo.__id,
      label: assetToString(reo),
      disabled: isReoTaken,
    });
  });

  return (
    <>
      <Row compact>
        <Col xs={24}>
          {typeOptions ? (
            <Select.Box
              name="type_name"
              label="Type"
              options={typeOptions}
              disabled={isVerified}
              required
            />
          ) : null}
          {!typeOptions && (
            <Input.Box name="type_name" label="Type" disabled={isVerified} required />
          )}
        </Col>
      </Row>

      {typeName === LiabilityType.MortgageLoan && (
        <Row compact>
          <Col xs={24}>
            <Select.Box
              name="related_reo"
              label="Associated REO property"
              options={realEstateOwnedOptions}
              allowClear
            />
          </Col>
        </Row>
      )}

      <Row compact>
        <Col xs={24} md={12}>
          <Input.Box name="full_name" label="Creditor name" required disabled={isVerified} />
        </Col>
        <Col xs={24} md={12}>
          <Input.Box name="account_identifier" label="Account number" disabled={isVerified} />
        </Col>
      </Row>
      <Row compact>
        <Col xs={24} md={8}>
          <Currency.Box name="unpaid_balance_amount" label="Remaining balance" required />
          {!isNil(verifiedBalance) && (
            <StyledVerification
              hideHelpButton
              source={verifiedBy}
              date={verifiedAt}
              verifiedValue={<Format.Currency value={verifiedBalance} noDecimals />}
              currentValue={
                !isNil(unpaidBalance) ? (
                  <Format.Currency value={unpaidBalance} noDecimals />
                ) : undefined
              }
            />
          )}
        </Col>
        <Col xs={24} md={8}>
          <Currency.Box
            name="monthly_payment_amount"
            label="Monthly payment"
            required={requireMonthlyPayment}
          />
          {!isNil(verifiedMonthlyPayment) && (
            <StyledVerification
              hideHelpButton
              source={verifiedBy}
              date={verifiedAt}
              verifiedValue={<Format.Currency value={verifiedMonthlyPayment} noDecimals />}
              currentValue={
                !isNil(monthlyPayment) ? (
                  <Format.Currency value={monthlyPayment} noDecimals />
                ) : undefined
              }
            />
          )}
        </Col>
        <Col xs={24} md={8}>
          <Numeric.Box name="months_left" label="Months left" disabled />
          {showVerifiedMonthsLeft ? (
            <StyledVerification
              hideHelpButton
              source={verifiedBy}
              date={verifiedAt}
              verifiedValue={verifiedMonthsLeft}
              currentValue={monthsLeft}
            />
          ) : null}
        </Col>
      </Row>

      <Row compact>
        <Col xs={24}>
          <StyledCheckbox
            name="paid_off_before_closing"
            label="The balance will be paid off at or before closing"
            labelInvisible
            onChange={syncExcludedField}
          >
            The balance will be paid off at or before closing
          </StyledCheckbox>
        </Col>
      </Row>

      <Row compact>
        <Col xs={24}>
          <StyledCheckbox name="excluded" label="Exclude liability from ratios" labelInvisible>
            Exclude this liability from ratios
            <HelpButton icon="question" size="xs" iconOutline={false}>
              Do not include this liability into the calculation of the ratios and do not submit it
              to any AUS.
            </HelpButton>
          </StyledCheckbox>
        </Col>
      </Row>
      <Row compact>
        <Col xs={24}>
          <TextArea.Box name="borrower_comments" label="Comments" autosize />
        </Col>
      </Row>
    </>
  );
};
