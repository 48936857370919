import {
  Button,
  Form,
  type FormSubmitHandler,
  Icon,
  type ISelectOption,
  Select,
  SubmitButton,
} from '@mortgagehippo/ds';
import { useMemo } from 'react';
import styled from 'styled-components';

import { type IFolderAgent } from '../../../hooks/use-folder-agents';

const Actions = styled.div`
  text-align: right;
`;

interface AddFolderUserPopoverFormProps {
  folderName: string;
  agents: IFolderAgent[];
  onSubmit: FormSubmitHandler;
  onCancel: () => void;
}

export const AddFolderUserPopoverForm = (props: AddFolderUserPopoverFormProps) => {
  const { folderName, agents, onSubmit, onCancel } = props;

  const options: ISelectOption[] = useMemo(() => {
    if (!agents) {
      return [];
    }

    return agents.map((agent) => ({
      label: agent.name,
      value: agent.id,
    }));
  }, [agents]);

  return (
    <Form onSubmit={onSubmit}>
      <Select.Box
        label={
          <span>
            Select users to add to <Icon name="folder-open" outline size="sm" />{' '}
            <strong>{folderName}</strong>
          </span>
        }
        name="agents"
        multiple
        options={options}
        searchable
      />
      <Actions>
        <Button onClick={onCancel} size="sm" importance="tertiary" type="neutral">
          Cancel
        </Button>
        <SubmitButton size="sm" importance="primary">
          Add users
        </SubmitButton>
      </Actions>
    </Form>
  );
};
