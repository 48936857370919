import { type TagColor } from '@mortgagehippo/ds';

export const toTagColorProps = (color: number) => {
  const inverted = color >= 100;
  let returnColor = inverted ? color - 100 : color;

  if (returnColor > 32) {
    returnColor = 32;
  }

  return {
    color: returnColor as TagColor,
    inverted,
  };
};
