import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { graphql } from '../../../../../../apollo';
import { type ILosPush } from '../types';

const QLendingQbLosPush = graphql(`
  query LendingQbLosPush($applicationFileId: ID!) {
    applicationFile(id: $applicationFileId) {
      id
      lendingQbLoanFilePush {
        id
        pushed
        loanNumber
        statusCode
        statusDescription
        createdAt
        updatedAt
      }
    }
  }
`);

export const useLendingQbLosPush = (applicationFileId: string, options: IUseApolloQueryOptions = {}) => {
  const [data, loading, , { refetch }] = useQuery(
    QLendingQbLosPush,
    { applicationFileId },
    {
      fetchPolicy: 'network-only',
      ...options,
    }
  );

  const handleRefresh = useSafeCallback(async () => {
    await refetch({ applicationFileId });
  });

  let result: ILosPush | null | undefined;

  if (!loading) {
    if (data?.applicationFile) {
      result = data.applicationFile.lendingQbLoanFilePush;
    } else {
      result = null;
    }
  }

  return [result, loading, handleRefresh] as const;
};
