import { Format, MediaBreakpoint, spacing, T, Tag } from '@mortgagehippo/ds';
import { isNil } from 'lodash-es';
import styled from 'styled-components';

import {
  BlockContent,
  BlockContentContainer,
  BlockLabel,
  BlockSublabel,
  BlockSubvalue,
  BlockValue,
  BlockValueLabel,
  SectionBlock,
} from '../common';
import { Dash } from '../dash';
import { ApplicationOverviewLiabilitiesSectionEmptyCreditScore } from './application-overview-liabilities-section-empty-credit-score';
import { type ILiabilitiesOverviewSectionApplicant } from './use-liabilities-overview-section';

const CreditPullDate = styled.div`
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: ${spacing(3)};

  ${MediaBreakpoint.TABLET} {
    justify-content: flex-start;
  }
`;

const JointCreditPull = styled(Tag)`
  margin-right: ${spacing(2)};
`;

interface IApplicationOverviewLiabilitiesSectionCreditScoreProps {
  applicant: ILiabilitiesOverviewSectionApplicant;
  showNames?: boolean;
}

export const ApplicationOverviewLiabilitiesSectionCreditScore = (
  props: IApplicationOverviewLiabilitiesSectionCreditScoreProps
) => {
  const { applicant, showNames } = props;

  const scores = applicant.creditPulls.items[0]?.scores;

  if (!scores?.length) {
    return (
      <ApplicationOverviewLiabilitiesSectionEmptyCreditScore
        applicant={applicant}
        showNames={showNames}
      />
    );
  }

  const applicantScores = scores.find(
    (score) => Number(score.applicantId) === Number(applicant.id)
  );

  if (!applicantScores) {
    return (
      <ApplicationOverviewLiabilitiesSectionEmptyCreditScore
        applicant={applicant}
        showNames={showNames}
      />
    );
  }

  const creditReportDate = applicant.creditPulls.items[0]!.createdAt;
  const isJointCreditPull = scores.length > 1; // scores will contain the reports for both applicants if it is joint pull
  const { equifax, experian, transunion } = applicantScores;

  return (
    <SectionBlock hasBlockValueLabels>
      <BlockLabel>
        <T>Credit Scores</T>
        {showNames ? <BlockSublabel>{applicant.name}</BlockSublabel> : null}
      </BlockLabel>
      <BlockContentContainer wrap>
        <BlockContent textAlign="center">
          <BlockValue>{isNil(equifax) ? <Dash /> : equifax}</BlockValue>
          <BlockValueLabel>Equifax</BlockValueLabel>
        </BlockContent>
        <BlockContent textAlign="center">
          <BlockValue>{isNil(experian) ? <Dash /> : experian}</BlockValue>
          <BlockValueLabel>Experian</BlockValueLabel>
        </BlockContent>
        <BlockContent textAlign="center">
          <BlockValue>{isNil(transunion) ? <Dash /> : transunion}</BlockValue>
          <BlockValueLabel>Transunion</BlockValueLabel>
        </BlockContent>
        <CreditPullDate>
          {isJointCreditPull ? (
            <JointCreditPull color="success" size="xxs" icon="rings" compact>
              joint report
            </JointCreditPull>
          ) : null}
          {!!creditReportDate && (
            <BlockSubvalue>
              <Format.Date format="date-med-time" value={creditReportDate} />
            </BlockSubvalue>
          )}
        </CreditPullDate>
      </BlockContentContainer>
    </SectionBlock>
  );
};
