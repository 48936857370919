import { type ButtonSize } from '@mortgagehippo/ds';
import { type ReactNode } from 'react';

import { LosType } from '../../../../../apollo/graphql';
import { type ServiceType } from '../../types';
import { ServiceName } from '../service-name';
import { LosErrorButton } from './los-error-button';

const parseError = (error: string, losType: LosType | ServiceType): ReactNode => {
  switch (losType) {
    case LosType.EncompassApi:
      try {
        const json = JSON.parse(error);
        const { summary, details } = json;

        return (
          <>
            {summary}
            {details ? <div>{`${details}`}</div> : null}
          </>
        );
      } catch (e) {
        return error;
      }
    default:
      return error;
  }
};

interface ILosDocumentErrorButtonProps {
  type: LosType | ServiceType;
  message?: ReactNode;
  errorDetails?: string | undefined | null;
  size?: ButtonSize;
  className?: string;
}

export const LosDocumentErrorButton = (props: ILosDocumentErrorButtonProps) => {
  const { type, message, errorDetails, size, className } = props;

  const details = errorDetails ? parseError(errorDetails, type) : errorDetails;

  return (
    <LosErrorButton
      type={type}
      message={
        message || (
          <>
            There was an error pushing this document to <ServiceName type={type} />.
          </>
        )
      }
      details={details}
      size={size}
      className={className}
    />
  );
};
