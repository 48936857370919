import {
  Button,
  Currency,
  FieldBox,
  FORM_ERROR,
  FormError,
  Input,
  ModalWizard2 as ModalWizard,
  notifications,
  SimpleFieldAutoArray,
  spacing,
  T,
  useCustomizations,
  useModal,
} from '@mortgagehippo/ds';
import {
  type IPaymentTask,
  type ITransnationalInvoiceLineItem,
  useTask,
  useTransnationalInvoice,
} from '@mortgagehippo/tasks';
import { startCase } from '@mortgagehippo/util';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useUpdatePaymentTask } from '../../../pages/application/use-update-payment-task';
import { CustomTaskInitialFields } from './custom-task-initial-fields';
import { type ICustomTaskTypeProps } from './types';

enum WizardSteps {
  TASK_FIELDS = 'task-fields',
  LINE_ITEMS = 'line-items',
}

interface ICustomPaymentsTaskFormValues {
  applicant_ids?: string[] | string;
  description?: string;
  title?: string;
  line_items?: ITransnationalInvoiceLineItem[];
  due_date?: string | null;
}

const LINE_ITEM_PRESENT_FIELDS = ['description', 'amount'];

const LineItemRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${spacing(3)};

  &:first-child {
    margin-top: 0;
  }
`;

const LineItemCol = styled.div<{ flex?: number }>`
  flex: ${({ flex }) => (flex === undefined ? 1 : flex)};
  flex-basis: auto;
  margin-right: ${spacing(3)};

  &:last-child {
    margin-right: 0;
  }
`;

export const CustomPaymentTask = (props: ICustomTaskTypeProps) => {
  const { taskId, applicationFileId, onDone, onCancel, showDueDate } = props;

  const [task, , taskLoading] = useTask<IPaymentTask>(taskId);
  const [step, setStep] = useState<string>(WizardSteps.TASK_FIELDS);
  const [isOpen, , closeModal] = useModal(true);

  const [invoice, invoiceLoading] = useTransnationalInvoice(applicationFileId, taskId);

  const updatePaymentTask = useUpdatePaymentTask();

  const customizations = useCustomizations();
  const modalTitle = customizations.text('custom_task:payment_task', 'Request Payment Task');

  const loading = taskLoading || invoiceLoading;

  const initialValues: ICustomPaymentsTaskFormValues = useMemo(() => {
    if (task && invoice) {
      return {
        title: task.meta.title,
        description: task.data.panel?.description,
        line_items: invoice.lineItems.map(({ id, description, amount }) => ({
          id,
          description,
          amount,
        })),
        due_date: task.dueDate,
      };
    }

    return {};
  }, [task, invoice]);

  const handleStepChange = async (nextStep?: string) => {
    if (nextStep) {
      setStep(nextStep);
    }
  };

  const handleValidateItem = useCallback((value: ITransnationalInvoiceLineItem) => {
    if (!value.description || !value.amount) {
      return 'Invalid Item';
    }

    return undefined;
  }, []);

  const handleUpdate = async (values: ICustomPaymentsTaskFormValues) => {
    try {
      const { title: taskTitle, description, due_date } = values;
      const lineItems = values.line_items?.filter(
        (i: any) => typeof handleValidateItem(i) === 'undefined'
      );

      if (!lineItems?.length) {
        return {
          [FORM_ERROR]: <T>You must enter at least 1 item.</T>,
        };
      }

      const data = {
        title: startCase(taskTitle)!,
        description: description!,
        lineItems,
        dueDate: due_date,
      };

      await updatePaymentTask(taskId, data);

      closeModal(() => {
        notifications.success({
          messageCid: 'custom_task:payment_task_update_success_msg',
          message: 'Successfully updated task',
        });
        onDone();
      });
    } catch (e) {
      console.error(e);

      notifications.error({
        messageCid: 'custom_task:payment_task_update_failure_msg',
        message: 'There was an unexpected error updating the task',
      });
    }

    return undefined;
  };

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  return (
    <ModalWizard
      loading={loading}
      width="medium"
      isOpen={isOpen}
      step={step}
      title={modalTitle}
      onRequestClose={handleCloseModal}
      onStepChange={handleStepChange}
      initialValues={initialValues}
      completeButtonLabel={taskId ? <T>Update task</T> : <T>Create task</T>}
      nextButtonLabel={<T>Continue</T>}
      prevButtonLabel={<T>Back</T>}
      onSubmit={handleUpdate}
      disableOverlayClickClose
    >
      <ModalWizard.Step id={WizardSteps.TASK_FIELDS} hidePrevButton>
        <CustomTaskInitialFields showDueDate={showDueDate} />
      </ModalWizard.Step>
      <ModalWizard.Step id={WizardSteps.LINE_ITEMS}>
        <FieldBox
          name="line_items"
          label="Payment Items"
          description="The items you are requesting payment for."
          required
        >
          <SimpleFieldAutoArray<ITransnationalInvoiceLineItem>
            name="line_items"
            validateItem={handleValidateItem}
            presentFields={LINE_ITEM_PRESENT_FIELDS}
            render={({ name: itemName, index, value, error, onRemove, active }) => (
              <LineItemRow key={value.id || index}>
                <LineItemCol>
                  <Input
                    name={`${itemName}.description`}
                    placeholder="Item Description"
                    compact
                    aria-label="Item Description"
                    error={!active && !!error}
                  />
                </LineItemCol>
                <LineItemCol flex={0}>
                  <Currency
                    name={`${itemName}.amount`}
                    placeholder="Amount"
                    compact
                    aria-label="Amount"
                    error={!active && !!error}
                    decimalMode="allow"
                  />
                </LineItemCol>
                <LineItemCol flex={0}>
                  <Button
                    icon="delete"
                    iconButton
                    size="xs"
                    type="danger"
                    importance="tertiary"
                    onClick={onRemove}
                    compact
                  />
                </LineItemCol>
              </LineItemRow>
            )}
          />
        </FieldBox>
      </ModalWizard.Step>

      <FormError hideOnDirty />
    </ModalWizard>
  );
};
