import { type FormSubmitHandler, Input, ModalForm } from '@mortgagehippo/ds';
import { useMemo } from 'react';

import { type ManagedFolder } from './manager';

interface IEditFolderModalProps {
  folder?: ManagedFolder;
  onSubmit: FormSubmitHandler;
  onRequestClose: () => void;
  isOpen: boolean;
}

export const EditFolderModal = (props: IEditFolderModalProps) => {
  const { folder, onSubmit, onRequestClose, isOpen } = props;

  const initialValues = useMemo(() => {
    const name = folder?.getName();
    const description = folder?.getDescription();

    return { name, description };
  }, [folder]);

  return (
    <ModalForm
      title="Edit folder"
      isOpen={isOpen}
      onSubmit={onSubmit}
      onRequestClose={onRequestClose}
      initialValues={initialValues}
      disableOverlayClickClose
      autoComplete={false}
    >
      <Input.Box label="Name" name="name" required />
      <Input.Box label="Description" name="description" />
    </ModalForm>
  );
};
