import { UnreachableCaseError } from '@mortgagehippo/util';

import { LosType } from '../../../apollo/graphql';
import { useTriggerBytePush } from './use-trigger-byte-push';
import { useTriggerEncompassPush } from './use-trigger-encompass-push';
import { useTriggerLendingQbPush } from './use-trigger-lending-qb-push';
// This will be callable for all LOS's in the future. It's only Encompass right now.
import { useTriggerLossEncompassPush } from './use-trigger-loss-encompass-push';
import { useTriggerMortgageDirectorPush } from './use-trigger-mortgage-director-push';
import { useTriggerMortgageBotPush } from './use-trigger-mortgagebot-push';
import { useTriggerMortgageflexPush } from './use-trigger-mortgageflex-push';

export const useTriggerLosPush = (losType: LosType, asyncLossExport?: boolean) => {
  const byteFn = useTriggerBytePush();
  const encompassFn = useTriggerEncompassPush();
  const mortgageBotFn = useTriggerMortgageBotPush();
  const mortgageDirectorFn = useTriggerMortgageDirectorPush();
  const mortgageflexFn = useTriggerMortgageflexPush();
  const lendingQbFn = useTriggerLendingQbPush();
  const lossEncompassFn = useTriggerLossEncompassPush();

  if (asyncLossExport === true && losType === LosType.EncompassApi) {
    return lossEncompassFn;
  }

  switch (losType) {
    case LosType.Byte:
      return byteFn;
    case LosType.LendingQb:
      return lendingQbFn;
    case LosType.EncompassApi:
      return encompassFn;
    case LosType.MortgageBot:
      return mortgageBotFn;
    case LosType.MortgageDirector:
      return mortgageDirectorFn;
    case LosType.MortgageFlex:
      return mortgageflexFn;
    default:
      throw new UnreachableCaseError(losType);
  }
};
