import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo';

export const MRefreshFinicityReport = graphql(`
  mutation RefreshFinicityReport(
    $applicationFileId: ID!
    $applicantId: ID!
    $forceRefresh: Boolean
    $reportDays: Int
  ) {
    createFinicityReport(
      applicationFileId: $applicationFileId
      applicantId: $applicantId
      forceRefresh: $forceRefresh
      reportDays: $reportDays
    )
  }
`);

export const useRefreshFinicityReport = () => {
  const refreshFinicityReport = useMutation(MRefreshFinicityReport, undefined);

  return useCallback(
    async (applicationFileId: string, applicantId: string, reportDays: number | null = null) => {
      const response = await refreshFinicityReport({
        applicationFileId,
        applicantId,
        forceRefresh: true,
        reportDays,
      });
      return response.data!.createFinicityReport;
    },
    [refreshFinicityReport]
  );
};
