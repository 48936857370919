import { type FormSubmitHandler, type ISelectOption, ModalForm } from '@mortgagehippo/ds';

import { CreateApplicationModalFields } from './create-application-modal-fields';

interface ICreateApplicationModalProps {
  isOpen: boolean;
  initialValues?: any;
  loading: boolean;
  siteOptions: ISelectOption[];
  onSubmit: FormSubmitHandler;
  onRequestClose: () => void;
}

const defaultInitialValues = {};
export const CreateApplicationModal = (props: ICreateApplicationModalProps) => {
  const {
    initialValues = defaultInitialValues,
    isOpen,
    loading,
    siteOptions,
    onRequestClose,
    onSubmit,
  } = props;

  return (
    <ModalForm
      loading={loading}
      title="New Application"
      initialValues={initialValues}
      isOpen={isOpen}
      okButtonLabel="Create"
      onRequestClose={onRequestClose}
      onSubmit={onSubmit}
      disableOverlayClickClose
      autoComplete={false}
    >
      <CreateApplicationModalFields siteOptions={siteOptions} />
    </ModalForm>
  );
};
