import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../../apollo';
import { type CreateDocusignEnvelopeUploadUrlMutation } from '../../../../apollo/graphql';

const MCreateDocusignEnvelopeUploadUrl = graphql(`
  mutation CreateDocusignEnvelopeUploadUrl($applicationFileId: ID!, $filename: String!) {
    docusign {
      createEnvelopeUploadUrl(applicationFileId: $applicationFileId, filename: $filename) {
        id
        postUrl
      }
    }
  }
`);

export type CreateDocusignEnvelopeUploadUrl =
  CreateDocusignEnvelopeUploadUrlMutation['docusign']['createEnvelopeUploadUrl'];

export const useCreateDocusignEnvelopeUploadUrl = () => {
  const fn = useMutation(MCreateDocusignEnvelopeUploadUrl, undefined);

  return useCallback(
    async (
      applicationFileId: string,
      filename: string
    ): Promise<CreateDocusignEnvelopeUploadUrl> => {
      const result = await fn({ applicationFileId, filename });
      return result.data!.docusign.createEnvelopeUploadUrl;
    },
    [fn]
  );
};
