import { pickBy, uniqBy } from 'lodash-es';

import { type IUserCan } from '$components/permissions';

import { type IntegrationType,type LosType } from '../../../apollo/graphql';
import { SERVICES } from './constants';
import { type IApplicationService,type ServiceType } from './types';

export const integrationsToServices = (integrations: IntegrationType[], can?: IUserCan) => {
  const services = integrations.reduce<IApplicationService[]>((accum, integration) => {
    // find what services match this integration
    const result = pickBy(SERVICES, (service) =>
      Array.isArray(service.integrations)
        ? service.integrations.includes(integration)
        : service.integrations === integration
    );

    const integrationServices = Object.values(result);

    return [...accum, ...integrationServices];
  }, []);

  // if you have LPA and DU you'd end up with the same service entry twice
  const uniqueServices = uniqBy(services, (service) => service.path);

  const accessibleServices = can
    ? uniqueServices.filter((service) => (service.canAccess ? service.canAccess(can) : true))
    : uniqueServices;

  return accessibleServices;
};

export const getServiceUrl = (applicationFileId: string, serviceType: ServiceType | LosType) => {
  const service = SERVICES[serviceType];
  return service ? `#/applications/${applicationFileId}/integrations/${service.path}` : undefined;
};
