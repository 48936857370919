import { graphql } from '../../../apollo';
import {
  type JobFunctionAgentsListQuery,
  type JobFunctionFragmentFragment,
} from '../../../apollo/graphql';

// eslint-disable-next-line unused-imports/no-unused-vars
const FJobFunctionFragment = graphql(`
  fragment JobFunctionFragment on JobFunction {
    id
    name
    primary
    createdAt
    updatedAt
    agents {
      total
    }
  }
`);

export type IJobFunction = JobFunctionFragmentFragment;

export const QJobFunctions = graphql(`
  query JobFunctions($siteId: ID!, $perPage: Int!, $cursor: String) {
    site(id: $siteId) {
      id
      jobFunctions(
        options: { perPage: $perPage, cursor: $cursor, orderBy: { primary: "desc", name: "asc" } }
      ) {
        total
        nextCursor
        previousCursor
        items {
          ...JobFunctionFragment
        }
      }
    }
  }
`);

export const QJobFunction = graphql(`
  query JobFunction($siteId: ID!, $jobFunctionId: ID!) {
    site(id: $siteId) {
      id
      jobFunction(id: $jobFunctionId) {
        ...JobFunctionFragment
      }
    }
  }
`);

export const QJobFunctionAgentsList = graphql(`
  query JobFunctionAgentsList($siteId: ID!, $jobFunctionId: ID!, $perPage: Int!, $cursor: String) {
    site(id: $siteId) {
      id
      jobFunction(id: $jobFunctionId) {
        id
        agents(options: { perPage: $perPage, cursor: $cursor, orderBy: { name: "asc" } }) {
          total
          nextCursor
          previousCursor
          items {
            id
            email
            name
            license
            phone
          }
        }
      }
    }
  }
`);

export type IJobFunctionAgent = NonNullable<
  NonNullable<JobFunctionAgentsListQuery['site']>['jobFunction']
>['agents']['items'][0];

export const MCreateJobFunction = graphql(`
  mutation CreateJobFunction($siteId: ID!, $data: JobFunctionInput!) {
    createJobFunction(siteId: $siteId, data: $data) {
      ...JobFunctionFragment
    }
  }
`);

export const MUpdateJobFunction = graphql(`
  mutation UpdateJobFunction($jobFunctionId: ID!, $data: JobFunctionInput!) {
    updateJobFunction(jobFunctionId: $jobFunctionId, data: $data) {
      ...JobFunctionFragment
    }
  }
`);

export const MSetJobFunctionAsPrimary = graphql(`
  mutation SetJobFunctionAsPrimary($jobFunctionId: ID!) {
    setJobFunctionAsPrimary(jobFunctionId: $jobFunctionId) {
      ...JobFunctionFragment
    }
  }
`);

export const MDeleteJobFunction = graphql(`
  mutation DeleteJobFunction($jobFunctionId: ID!) {
    deleteJobFunction(jobFunctionId: $jobFunctionId) {
      ...JobFunctionFragment
    }
  }
`);

export const MCreateAgentJobFunction = graphql(`
  mutation CreateAgentJobFunction($agentId: ID!, $jobFunctionId: ID!) {
    createAgentJobFunction(agentId: $agentId, jobFunctionId: $jobFunctionId)
  }
`);

export const MDeleteAgentJobFunction = graphql(`
  mutation DeleteAgentJobFunction($agentId: ID!, $jobFunctionId: ID!) {
    deleteAgentJobFunction(agentId: $agentId, jobFunctionId: $jobFunctionId)
  }
`);
