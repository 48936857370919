import {
  type ISearchFields,
  type ISearchFilter,
  isTextSearchFilter,
  MediaBreakpoint,
  Search,
  spacing,
  TableColSortDirection,
  useResponsive,
  useTableSort,
} from '@mortgagehippo/ds';
import {
  type IQueryTableColumn,
  type IQueryTableControlledVariables,
  type IQueryTableProps,
  QueryTable,
} from '@mortgagehippo/query-components';
import { getLocalTimezone, toArray } from '@mortgagehippo/util';
import { isEmpty } from 'lodash-es';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

import {
  type SmartviewDataQuery,
  type SmartviewDataQueryVariables,
  type SmartviewModelType,
} from '../../apollo/graphql';
import { usePartnerDomain } from '../../hooks/use-partner-domain';
import { useSmartviewConfig } from '../../hooks/use-smartview-config';
import { QSmartviewData } from './queries';
import { type ISmartviewRecord } from './types';
import { useSmartviewStorage } from './use-smartview-storage';
import {
  getChoicesFn,
  getColumnKey,
  getOrderBy,
  toQueryTableColumns,
  toSearchFields,
} from './util';

const COLUMN_KEY_SEPARATOR = ':';

const getColumnSortField = (key: string) => key.split(COLUMN_KEY_SEPARATOR)[0];

const rowKey = (item: ISmartviewRecord) => item.id;

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;

  ${MediaBreakpoint.PHONE} {
    display: flex;
  }
`;

const BottomContent = styled.div`
  display: flex;
  align-items: center;

  > * {
    flex: 0 0 auto;
    margin-left: ${spacing(5)};

    ${MediaBreakpoint.PHONE} {
      margin-left: 0;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  ${MediaBreakpoint.PHONE} {
    width: 100%;
    flex-direction: column;
  }
`;

type INarrowQueryTableProps<
  GraphQLQueryResult,
  GraphQLQueryVariables extends IQueryTableControlledVariables,
  T,
> = Omit<
  IQueryTableProps<GraphQLQueryResult, GraphQLQueryVariables, T>,
  | 'columns'
  | 'variables'
  | 'loading'
  | 'query'
  | 'dataSource'
  | 'itemCount'
  | 'itemTotal'
  | 'rowKey'
  | 'sortProps'
>;

interface ISmartViewProps
  extends INarrowQueryTableProps<
    SmartviewDataQuery,
    SmartviewDataQueryVariables,
    ISmartviewRecord
  > {
  model: SmartviewModelType;
  id?: string;
  paginate?: boolean;
  summarize?: boolean;
  onExport?: (
    criteria?: ISearchFilter[],
    sortField?: string,
    sortDirection?: string
  ) => Promise<string | null>;
  onCreateFrom?: (criteria?: ISearchFilter[], sortField?: string, sortDirection?: string) => void;
  presetsLabel?: string;
}

function FSmartView(props: ISmartViewProps, ref: any) {
  const {
    model,
    id: smartviewId,
    paginate,
    onExport,
    onCreateFrom,
    summarize,
    tableFullHeightOffset,
    topActions: propsTopActions,
    localStorageKey,
    skip,
    caption,
    presetsLabel,
    ...rest
  } = props;

  const tableRef = useRef<any>();
  const partnerId = usePartnerDomain();

  const [columns, setColumns] = useState<Array<IQueryTableColumn<ISmartviewRecord>> | undefined>();
  const [searchFields, setSearchFields] = useState<ISearchFields | undefined>();

  const [storedValues, setStoredValues, storedValuesRef] = useSmartviewStorage(localStorageKey);
  const { searchFilters: storedSearchFilters } = storedValues || {};

  const sortProps = useTableSort();
  const { sortColumn, sortDirection, onColumnSort } = sortProps;
  const sortField = sortColumn && getColumnSortField(sortColumn);

  const [searchFilters, setSearchFilters] = useState<ISearchFilter[] | undefined>(
    storedSearchFilters
  );
  const [presetSearchFilters, setPresetSearchFilters] = useState<ISearchFilter[] | undefined>();
  const [hasUpdatedState, setHasUpdatedState] = useState(false);

  const [config, , { refetch: refetchConfig, networkStatus }] = useSmartviewConfig(
    model,
    smartviewId,
    {
      skip,
    }
  );

  const responsive = useResponsive();
  const isPhone = responsive.PHONE.EXACT_OR_SMALLER;

  const configLoading = networkStatus === 1 || networkStatus === 2 || networkStatus === 4;
  const loading = configLoading || !columns || !hasUpdatedState;

  useEffect(() => {
    if (configLoading) {
      setHasUpdatedState(false);
    }
  }, [configLoading]);

  useEffect(() => {
    if (!config) {
      return;
    }

    const {
      metadata,
      columns: configColumns,
      filters: configFilters,
      defaultSortDirection,
      defaultSortField,
    } = config;
    const configSearchFields = config.searchFields || [];

    const nextColumns = toQueryTableColumns(configColumns, metadata);
    const nextSearchFields = toSearchFields(
      configSearchFields,
      metadata,
      getChoicesFn(partnerId, model, smartviewId)
    );

    let nextSortColumn: string | undefined;
    let nextSortDirection: TableColSortDirection | undefined;

    const { sortColumn: storedSortColumn, sortDirection: storedSortDirection } =
      storedValuesRef.current || {};

    if (storedSortColumn) {
      // check if the column we were previously sorting by still exists
      const nextDefaultSortColumn = nextColumns.find((c) => c.key === storedSortColumn);

      if (nextDefaultSortColumn) {
        nextSortColumn = storedSortColumn;
        nextSortDirection = storedSortDirection;
      }
    }

    if (!nextSortColumn) {
      // sort based on smartview configuration
      const nextDefaultSortColumn = defaultSortField
        ? configColumns.find((c: Record<string, any>) => toArray(c.fields)[0] === defaultSortField)
        : configColumns.find((c: Record<string, any>) => !!c.defaultSort);

      if (nextDefaultSortColumn) {
        nextSortColumn = getColumnKey(nextDefaultSortColumn);
        nextSortDirection = defaultSortDirection || nextDefaultSortColumn.defaultSort;
      }
    }

    if (nextSortColumn) {
      onColumnSort(nextSortColumn, nextSortDirection || TableColSortDirection.DESCENDING);
    }

    setColumns(nextColumns);
    setPresetSearchFilters(configFilters || []);
    setSearchFields(nextSearchFields);
    setHasUpdatedState(true);
  }, [config, model, onColumnSort, partnerId, setSearchFilters, smartviewId, storedValuesRef]);

  const orderBy = useMemo(() => getOrderBy(sortField, sortDirection), [sortDirection, sortField]);

  const topActions = useMemo(() => {
    const nextTopActions = [...(propsTopActions || [])];

    if (onCreateFrom) {
      // we don't care about global text searches for pre-filling a smartview
      const filters = (searchFilters || []).filter((f) => !isTextSearchFilter(f));

      nextTopActions.push({
        key: 'save',
        label: 'Save as smartview',
        buttonProps: {
          icon: 'smartview',
        },
        onAction: () => {
          onCreateFrom(filters, sortField, sortDirection);
        },
        hidden: !filters.length,
      });
    }

    if (onExport) {
      nextTopActions.push({
        key: 'export',
        label: 'Download CSV',
        buttonProps: {
          icon: 'download',
          tooltip:
            'Export the current table in CSV file format. The export will contain all records matching your current search and filters, including records that may not be visible in the current table page.',
          tooltipProps: {
            showDelayMs: 1000,
          },
        },
        onAction: () => onExport(searchFilters, sortField, sortDirection),
      });
    }

    return nextTopActions;
  }, [onCreateFrom, onExport, propsTopActions, searchFilters, sortDirection, sortField]);

  useEffect(() => {
    setStoredValues({
      searchFilters,
    });
  }, [searchFilters, setStoredValues]);

  useImperativeHandle(
    ref,
    () => ({
      async refetch(ensureId?: string | null, reloadConfig?: boolean) {
        if (reloadConfig) {
          await refetchConfig();
        }

        await tableRef.current.refetch({ ensureId });
      },
    }),
    [refetchConfig]
  );

  const handleColumnSort = useCallback(
    (nextColumnKey: string, nextSortDirection: TableColSortDirection) => {
      onColumnSort(nextColumnKey, nextSortDirection);

      setStoredValues({
        sortColumn: nextColumnKey,
        sortDirection: nextSortDirection,
      });
    },
    [onColumnSort, setStoredValues]
  );

  const showSearch = !!searchFields && !isEmpty(searchFields);
  const showFooter = summarize || paginate;

  const topContent = showSearch && (
    <Search
      fields={searchFields}
      showSearchBarIcon
      label="Search"
      size="sm"
      search={searchFilters}
      onChange={setSearchFilters}
      presets={presetSearchFilters}
      presetsLabel={presetsLabel}
    />
  );

  const bottomContent = showFooter && (
    <BottomContent>
      {summarize ? <QueryTable.Total /> : null}
      {paginate ? <QueryTable.Pagination alignment={!isPhone ? 'left' : 'center'} /> : null}
    </BottomContent>
  );

  return (
    <Container>
      <QueryTable<SmartviewDataQuery, SmartviewDataQueryVariables, ISmartviewRecord>
        ref={tableRef}
        caption={caption}
        query={QSmartviewData}
        variables={{
          partnerId,
          model,
          smartviewId,
          orderBy,
          criteria: searchFilters as any,
          timezone: getLocalTimezone(),
        }}
        dataSource={(dataResult) => dataResult.partner?.smartview?.data?.items || []}
        itemTotal={(dataResult) => dataResult.partner?.smartview?.data?.itemsTotal || 0}
        columns={columns || []}
        rowKey={rowKey}
        sortProps={{
          sortColumn,
          sortDirection,
          onColumnSort: handleColumnSort,
        }}
        topActions={topActions}
        topContent={topContent}
        bottomContent={bottomContent}
        tableFullHeightOffset={tableFullHeightOffset || 0}
        localStorageKey={localStorageKey}
        loading={loading}
        skip={loading || skip}
        {...rest}
      >
        <QueryTable.Data columnTitleCasing="none" verticalAlign="top" />
      </QueryTable>
    </Container>
  );
}

export const SmartView = forwardRef(FSmartView) as typeof FSmartView;
