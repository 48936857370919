import {
  borderRadius,
  Checkbox,
  fontSize,
  fontWeight,
  Format,
  Icon,
  type IconNames,
  lineHeight,
  palette,
  spacing,
  Tag,
} from '@mortgagehippo/ds';
import styled, { css } from 'styled-components';

import { type ILenderMilestone } from '../../pages/application/use-lender-milestones';

const CHECK_SIZE = 16;

const ActiveTagCol = styled.div`
  position: absolute;
  right: 100%;
  font-weight: ${fontWeight('light')};
  text-align: right;
  padding-right: ${spacing(2)};
`;

const Item = styled.div<{ activeOrCompleted?: boolean }>`
  display: block;
  position: relative;
  color: ${palette('neutral600')};

  ${(p) =>
    p.activeOrCompleted &&
    css`
      font-weight: ${fontWeight('bold')};
      color: ${palette('neutral900')};
    `}
`;

const DateColContent = styled.div<{ isCheckboxInput?: boolean }>`
  text-align: right;
  color: ${palette('neutral600')};
  font-size: ${fontSize('xs')};
  font-weight: ${fontWeight('regular')};
  min-width: 120px;
  margin-left: ${spacing(1)};

  ${(p) =>
    p.isCheckboxInput &&
    css`
      line-height: 1.5;
    `}
`;

const LabelCol = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LabelColContent = styled.div`
  position: relative;
  padding-left: calc(${CHECK_SIZE}px + ${spacing(2)});
`;

const StatusIcon = styled(Icon)``;

const Status = styled('div')<{
  complete?: boolean;
}>`
  position: absolute;
  top: 5px;
  left: 0;
  width: ${CHECK_SIZE}px;
  height: ${CHECK_SIZE}px;
  z-index: 10;
  border-radius: ${borderRadius(1)};

  /* STATE NORMAL */
  background: ${palette('neutral100')};

  /* STATE CURRENT */
  color: ${palette('success600')};

  /* STATE COMPLETE */
  ${(p) =>
    p.complete &&
    css`
      color: ${palette('success50')};
      background: ${palette('success600')};
    `}

  ${StatusIcon} {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 1;
  }
`;

const Label = styled('span')`
  font-size: ${fontSize('sm')};
  line-height: ${lineHeight('sm')};
`;

const CheckboxLabel = styled('span')<{ activeOrCompleted?: boolean }>`
  font-size: ${fontSize('sm')};
  line-height: 1.3;

  color: ${palette('neutral600')};

  ${(p) =>
    p.activeOrCompleted &&
    css`
      font-weight: ${fontWeight('bold')};
      color: ${palette('neutral900')};
    `}
`;

interface ILenderMilestonesListItemProps {
  milestone: ILenderMilestone;
  onChange: (isSelected: boolean) => void;
  isEditing?: boolean;
}

export const LenderMilestonesListItem = (props: ILenderMilestonesListItemProps) => {
  const { milestone, isEditing, onChange } = props;
  const { active, completed, updatedOn, name, id } = milestone;

  let icon: IconNames | undefined;

  if (completed) {
    icon = 'check';
  }

  const MilestoneItem = (
    <LabelColContent>
      <Status complete={completed}>{icon ? <StatusIcon name={icon} size="xs" /> : null}</Status>
      <Label>{name}</Label>
    </LabelColContent>
  );

  const showCheckboxInput = isEditing;

  return (
    <Item activeOrCompleted={active || completed}>
      <ActiveTagCol>
        {active ? (
          <div>
            <Tag
              color="success"
              inverted={false}
              icon="direction-right"
              iconPosition="right"
              size="xs"
            >
              Current
            </Tag>
          </div>
        ) : null}
      </ActiveTagCol>
      <LabelCol>
        {showCheckboxInput ? (
          <Checkbox.Input
            name={`lender_milestone_${id}`}
            onChange={onChange}
            value={completed}
            size="sm"
            compact
            showSuccess
          >
            <CheckboxLabel activeOrCompleted={active || completed}>{name}</CheckboxLabel>
          </Checkbox.Input>
        ) : (
          MilestoneItem
        )}
        {updatedOn ? (
          <DateColContent isCheckboxInput={showCheckboxInput}>
            <Format.Date format="date-med" value={updatedOn} />
          </DateColContent>
        ) : null}
      </LabelCol>
    </Item>
  );
};
