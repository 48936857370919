import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../apollo';

const QLinkTask = graphql(`
  query LinkTask($taskId: ID!) {
    task(id: $taskId) {
      id
      primaryApplicantId
      applicationFileId
    }
  }
`);

export const useLinkTask = (taskId?: string, options?: IUseApolloQueryOptions) => {
  const [data, loading] = useQuery(
    QLinkTask,
    {
      taskId: taskId!,
    },
    {
      ...options,
      skip: !taskId || options?.skip,
    }
  );

  const applicantId = data?.task?.primaryApplicantId;
  const applicationFileId = data?.task?.applicationFileId;

  return [applicantId, applicationFileId, loading] as const;
};
