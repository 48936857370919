import { ForbiddenError } from '@mortgagehippo/auth';
import { type RouteComponentProps } from 'react-router';

import { useUserCan } from '$components/permissions';

import { SmartviewsPageContent } from './smartviews-page-content';
import { SmartviewsPageDefaultContent } from './smartviews-page-default-content';

interface ISmartviewsPageParams {
  id?: string;
}

export const SmartviewsPage = (props: RouteComponentProps<ISmartviewsPageParams>) => {
  const { match } = props;
  const { params } = match;
  const { id } = params;

  const [can, canReady] = useUserCan();

  if (!canReady) {
    return null;
  }

  if (!can.VIEW_SMARTVIEWS) {
    throw new ForbiddenError();
  }

  if (id) {
    return <SmartviewsPageContent id={id} />;
  }

  return <SmartviewsPageDefaultContent />;
};
