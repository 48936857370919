import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';
import { compact, flatMap } from 'lodash-es';

import { graphql } from '../../../../../apollo';
import { type LiabilitiesOverviewSectionQuery } from '../../../../../apollo/graphql';

const QLiabilitiesOverviewSection = graphql(`
  query LiabilitiesOverviewSection($applicationFileId: ID!) {
    applicationFile(id: $applicationFileId) {
      id
      applicants {
        items {
          id
          name
          answers
          creditPulls(options: { perPage: 1, orderBy: { created_at: "desc" } }) {
            items {
              id
              createdAt
              scores {
                id
                applicantId
                equifax
                transunion
                experian
              }
            }
          }
        }
      }
      calculations {
        householdMonthlyIncome
        assetsAmount
        householdMonthlyDebt
        liabilitiesUnpaidBalanceAmount
        frontEndDti
        backEndDti
        cltv
        ltv
      }
    }
  }
`);

export type ILiabilitiesOverviewSectionApplicant = NonNullable<
  LiabilitiesOverviewSectionQuery['applicationFile']
>['applicants']['items'][0];

export const useLiabilitiesOverviewSection = (
  applicationFileId: string,
  options: IUseApolloQueryOptions = {}
) => {
  const [data, loading, , { refetch }] = useQuery(
    QLiabilitiesOverviewSection,
    { applicationFileId },
    {
      fetchPolicy: 'network-only',
      suspend: false,
      ...options,
    }
  );

  const handleRefetch = useSafeCallback(() => refetch({ applicationFileId }));

  const calculatedLiabilities = data?.applicationFile?.calculations || null;
  const applicants = data?.applicationFile?.applicants.items || [];
  const liabilities = compact(flatMap(applicants, (a) => a.answers.liabilities));
  const hasExcludedLiabilities = liabilities.some((liability: any) => !!liability.excluded);

  return [
    { calculatedLiabilities, applicants, hasExcludedLiabilities },
    loading,
    handleRefetch,
  ] as const;
};
