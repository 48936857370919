import { IntegrationType } from '../../../apollo/graphql';
import { type IApplicationService, ServiceType } from './types';

export const SERVICES: Record<ServiceType, IApplicationService> = {
  [ServiceType.CreditPull]: {
    name: 'Credit Check',
    nameCid: 'pageApplication:tabServices.creditCheck.name',
    path: 'credit_pull',
    type: ServiceType.CreditPull,
    position: 1,
    integrations: IntegrationType.CreditPull,
  },
  [ServiceType.AccountChek]: {
    name: 'Verification of Assets',
    nameCid: 'pageApplication:tabServices.accountChek.name',
    path: 'account_chek',
    type: ServiceType.AccountChek,
    position: 3,
    integrations: IntegrationType.AccountChek,
  },
  [ServiceType.Byte]: {
    name: 'Byte',
    nameCid: 'pageApplication:tabServices.byte.name',
    path: 'byte',
    position: 99,
    type: ServiceType.Byte,
    integrations: IntegrationType.Byte,
  },
  [ServiceType.EncompassApi]: {
    name: 'Encompass',
    nameCid: 'pageApplication:tabServices.encompass.name',
    path: 'encompass_api',
    position: 99,
    type: ServiceType.EncompassApi,
    integrations: IntegrationType.EncompassApi,
  },
  [ServiceType.WorkNumber]: {
    name: 'Verification of Income & Employment (Work Number)',
    nameCid: 'pageApplication:tabServices.workNumber.name',
    path: 'work-number',
    type: ServiceType.WorkNumber,
    position: 4,
    integrations: IntegrationType.WorkNumber,
  },
  [ServiceType.Truework]: {
    name: 'Verification of Income & Employment (Truework)',
    nameCid: 'pageApplication:tabServices.truework.name',
    path: 'truework',
    type: ServiceType.Truework,
    position: 4,
    integrations: IntegrationType.Truework,
  },
  [ServiceType.AUS]: {
    name: 'AUS',
    nameCid: 'pageApplication:tabServices.aus.name',
    path: 'aus',
    type: ServiceType.AUS,
    position: 5,
    integrations: [IntegrationType.LoanProductAdvisor, IntegrationType.DesktopUnderwriter],
  },
  [ServiceType.MortgageBot]: {
    name: 'MortgageBot',
    nameCid: 'pageApplication:tabServices.mortgageBot.name',
    path: 'mortgagebot',
    type: ServiceType.MortgageBot,
    position: 99,
    integrations: IntegrationType.MortgageBot,
  },
  [ServiceType.MortgageFlex]: {
    name: 'MortgageFlex',
    nameCid: 'pageApplication:tabServices.mortgageFlex.name',
    path: 'mortgageflex',
    type: ServiceType.MortgageFlex,
    position: 99,
    integrations: IntegrationType.MortgageFlex,
  },
  [ServiceType.MortgageInsurance]: {
    name: 'Mortgage Insurance',
    nameCid: 'pageApplication:tabServices.mortgageInsurance.name',
    path: 'mortgage_insurance',
    type: ServiceType.MortgageInsurance,
    position: 99,
    integrations: IntegrationType.MortgageInsurance,
  },
  [ServiceType.IDS]: {
    name: 'IDS',
    nameCid: 'pageApplication:tabServices.ids.name',
    path: 'ids',
    type: ServiceType.IDS,
    position: 2,
    integrations: IntegrationType.IDS,
  },
  [ServiceType.InternalSystem]: {
    name: 'Internal System',
    nameCid: 'pageApplication:tabServices.internalSystem.name',
    path: 'internal_system',
    type: ServiceType.InternalSystem,
    position: 99,
    canAccess: (can) => can.PUSH_TO_INTERNAL_SYSTEM,
    integrations: IntegrationType.InternalSystem,
  },
  [ServiceType.LendingQb]: {
    name: 'Meridian Link',
    nameCid: 'pageApplication:tabServices.lendingQb.name',
    path: 'meridian_link',
    position: 99,
    type: ServiceType.LendingQb,
    integrations: IntegrationType.LendingQb,
  },
  [ServiceType.Finicity]: {
    name: 'Verification of Assets',
    nameCid: 'pageApplication:tabServices.finicity.name',
    path: 'finicity',
    type: ServiceType.Finicity,
    position: 3,
    integrations: IntegrationType.Finicity,
  },
  [ServiceType.MortgageDirector]: {
    name: 'Mortgage Director',
    nameCid: 'pageApplication:tabServices.mortgageDirector.name',
    path: 'mortgage_director',
    position: 99,
    type: ServiceType.MortgageDirector,
    integrations: IntegrationType.MortgageDirector,
  },
  [ServiceType.Docutech]: {
    name: 'Docutech',
    path: 'docutech',
    type: ServiceType.Docutech,
    canAccess: (can) => can.VIEW_DOCUTECH,
    integrations: IntegrationType.Docutech,
  },
  [ServiceType.SmartFees]: {
    name: 'SmartFees',
    path: 'smart_fees',
    type: ServiceType.SmartFees,
    canAccess: (can) => can.VIEW_SMART_FEES,
    integrations: IntegrationType.SmartFees,
  },
  [ServiceType.TotalExpert]: {
    name: 'Total Expert',
    path: 'total_expert',
    type: ServiceType.TotalExpert,
    integrations: IntegrationType.TotalExpert,
  },
  [ServiceType.Velocify]: {
    name: 'Velocify',
    path: 'velocify',
    type: ServiceType.Velocify,
    integrations: IntegrationType.Velocify,
  },
  [ServiceType.UnderwritingConditions]: {
    name: 'Underwriting Conditions',
    path: 'underwriting_conditions',
    type: ServiceType.UnderwritingConditions,
    integrations: IntegrationType.EncompassApi,
    canAccess: (can) => can.VIEW_UNDERWRITING_CONDITIONS_PAGE,
  },
};
