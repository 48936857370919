import {
  Dropdown,
  type IButtonProps,
  type IDropdownActionList,
  useCustomizations,
} from '@mortgagehippo/ds';

import { ActionType, useDispatchAction } from '$components/actions';
import { useApplicationFileCan } from '$components/permissions';

import { useDownloadFannieMaeFile } from './use-download-fannie-mae-file';
import { useDownloadMismoFile } from './use-download-mismo-file';

interface IApplicationActionsProps {
  applicationFileId: string;
}

const buttonProps: IButtonProps = {
  type: 'neutral',
  size: 'xs',
  icon: 'menu-dots',
  iconSize: 'sm',
  importance: 'tertiary',
  iconButton: true,
  compact: true,
  iconButtonHumble: true,
  iconButtonRound: true,
};

export const ApplicationActions = (props: IApplicationActionsProps) => {
  const { applicationFileId } = props;

  const [can] = useApplicationFileCan(applicationFileId);

  const downloadFannieMaeFile = useDownloadFannieMaeFile(applicationFileId);
  const downloadMismoFile = useDownloadMismoFile(applicationFileId);

  const dispatch = useDispatchAction();

  const customizations = useCustomizations();
  const prequalLetterLabel = customizations.text(
    'preQualification:letter.label',
    'Pre-qualification letter'
  );

  const handleMismoClick = (typeName: string) => async () => downloadMismoFile(typeName);

  // TODO: modify dropdown component so that actions have a hidden prop
  const actions: IDropdownActionList = [];

  if (can.CREATE_TASK) {
    actions.push(
      {
        key: 'add_tasks',
        label: 'Add borrower follow-up tasks',
        cid: 'pageApplication:menu.dots.createTask.label',
        iconProps: {
          name: 'add-task',
          size: 'sm',
        },
        onAction: () => {
          dispatch({ type: ActionType.BULK_CREATE_CUSTOM_TASK, applicationFileId });
        },
      },
      { separator: true }
    );
  }

  if (can.ADD_BORROWER) {
    actions.push({
      key: 'add_borrower',
      cid: 'pageApplication:menu.dots.addBorrower.label',
      label: 'Add a co-borrower',
      iconProps: {
        name: 'add-user',
        size: 'sm',
      },
      onAction: () => {
        dispatch({ type: ActionType.ADD_APPLICANT, applicationFileId });
      },
    });
  }

  if (can.VIEW_SEND_INVITES_PAGE) {
    actions.push({
      key: 'send_invitation',
      cid: 'pageApplication:menu.dots.sendInvitation.label',
      label: 'Send invitation link',
      iconProps: {
        name: 'share',
        size: 'sm',
      },
      to: `#/applications/${applicationFileId}/invite`,
    });
  }

  if (can.VIEW_APPLICATION_ACTIVITY_PAGE) {
    actions.push({
      key: 'activity',
      cid: 'pageApplication:menu.dots.activity.label',
      label: 'Activity',
      iconProps: {
        name: 'history',
        size: 'sm',
      },
      to: `#/applications/${applicationFileId}/activity`,
    });
  }

  if (
    actions.length > 0 &&
    (can.DOWNLOAD_FANNIE_MAE_FILE ||
      (can.DOWNLOAD_MISMO_FILE && can.DOWNLOAD_DOCUMENTS) ||
      can.VIEW_PREQUALIFICATION_LETTERS_PAGE)
  ) {
    actions.push({ separator: true });
  }

  if (can.DOWNLOAD_FANNIE_MAE_FILE) {
    actions.push({
      key: 'download_fannie_mae',
      cid: 'pageApplication:menu.dots.downloadFannieFile.label',
      label: 'Download Fannie Mae file',
      iconProps: {
        name: 'download',
        size: 'sm',
      },
      onAction: downloadFannieMaeFile,
    });
  }

  if (can.DOWNLOAD_MISMO_FILE && can.DOWNLOAD_DOCUMENTS) {
    const mismoSubactions: IDropdownActionList = [];

    if (can.DOWNLOAD_MISMO_34_FILE) {
      mismoSubactions.push({
        key: 'download_mismo',
        label: 'MISMO 3.4',
        onAction: handleMismoClick('Mismo34Map'),
      });
    }

    if (can.DOWNLOAD_MISMO_34_PLUS_DU_FILE) {
      mismoSubactions.push({
        key: 'download_mismo_du',
        label: 'MISMO 3.4 + DU extensions',
        onAction: handleMismoClick('Mismo34DuMap'),
      });
    }

    if (can.DOWNLOAD_MISMO_34_PLUS_LPA_FILE) {
      mismoSubactions.push({
        key: 'download_mismo_lpa',
        label: 'MISMO 3.4 + LPA extensions',
        onAction: handleMismoClick('Mismo34LpaMap'),
      });
    }

    actions.push({
      label: 'Download MISMO file',
      cid: 'pageApplication:menu.dots.downloadMismo.label',
      actions: mismoSubactions,
      iconProps: {
        name: 'download',
        size: 'sm',
      },
    });
  }

  if (can.VIEW_PREQUALIFICATION_LETTERS_PAGE) {
    actions.push({
      key: 'generate_pre_qualification',
      cid: 'pageApplication:menu.dots.preQualification.label',
      label: `Generate ${prequalLetterLabel}`,
      iconProps: {
        name: 'prequal',
        size: 'sm',
      },
      to: `#/applications/${applicationFileId}/prequalification`,
    });
  }

  if (
    actions.length > 0 &&
    (can.ARCHIVE_APPLICATION_FILE || can.RESTORE_APPLICATION_FILE || can.DELETE_APPLICATION_FILE)
  ) {
    actions.push({ separator: true });
  }

  if (can.DELETE_APPLICATION_FILE) {
    const deleteActions: IDropdownActionList = [
      {
        key: 'delete',
        cid: 'pageApplication:menu.dots.delete.label',
        label: 'Delete application',
        iconProps: {
          name: 'delete',
          size: 'sm',
        },
        onAction: () => {
          dispatch({ type: ActionType.DELETE_APPLICATION, applicationFileId });
        },
      },
    ];

    actions.push(...deleteActions);
  }

  if (can.ARCHIVE_APPLICATION_FILE) {
    const archiveActions: IDropdownActionList = [
      {
        key: 'archive',
        cid: 'pageApplication:menu.dots.archive.label',
        label: 'Archive application',
        iconProps: {
          name: 'archive',
          size: 'sm',
        },
        onAction: () => {
          dispatch({ type: ActionType.ARCHIVE_APPLICATION, applicationFileId });
        },
      },
    ];

    actions.push(...archiveActions);
  }

  if (can.RESTORE_APPLICATION_FILE) {
    const restoreActions: IDropdownActionList = [
      {
        key: 'restore',
        cid: 'pageApplication:menu.dots.restore.label',
        label: 'Restore application',
        iconProps: {
          name: 'undo',
          size: 'sm',
        },
        onAction: () => {
          dispatch({ type: ActionType.RESTORE_APPLICATION, applicationFileId });
        },
      },
    ];

    actions.push(...restoreActions);
  }

  if (actions.length === 0) {
    return null;
  }

  return <Dropdown label="Application actions" buttonProps={buttonProps} actions={actions} />;
};
