import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../apollo';

const MTriggerLendingQbPush = graphql(`
  mutation TriggerLendingQbPush($applicationFileId: ID!) {
    triggerLendingQbPush(applicationFileId: $applicationFileId)
  }
`);

export const useTriggerLendingQbPush = () => {
  const fn = useMutation(MTriggerLendingQbPush, undefined);

  return useCallback(
    async (applicationFileId: string) => {
      const response = await fn({ applicationFileId });
      return response.data!.triggerLendingQbPush;
    },
    [fn]
  );
};
