import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';
import { usePartnerDomain } from 'src/hooks/use-partner-domain';

import { graphql } from '../apollo';
import { type SmartviewModelType } from '../apollo/graphql';

const QSmartviewConfig = graphql(`
  query SmartviewConfig($partnerId: ID!, $model: SmartviewModelType!, $smartviewId: ID) {
    partner(id: $partnerId) {
      id
      smartview(id: $smartviewId, model: $model) {
        id
        config {
          metadata
          columns
          searchFields
          filters
          defaultSortField
          defaultSortDirection
        }
      }
    }
  }
`);

export const useSmartviewConfig = (
  model: SmartviewModelType,
  smartviewId?: string,
  options: IUseApolloQueryOptions = {}
) => {
  const partnerId = usePartnerDomain();
  const [data, loading, , { refetch, networkStatus }] = useQuery(
    QSmartviewConfig,
    {
      partnerId,
      model,
      smartviewId,
    },
    {
      fetchPolicy: 'network-only',
      ...options,
    }
  );

  const handleRefetch = useSafeCallback(async () => refetch());

  const config = data?.partner?.smartview?.config;

  return [config, loading, { refetch: handleRefetch, networkStatus }] as const;
};
