import { Button, fontSize, Format, palette, spacing } from '@mortgagehippo/ds';
import { useMemo } from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  & > * {
    flex: 1 1 auto;
  }
`;

const StyledP = styled.p`
  margin-bottom: 0;
  margin-top: ${spacing(1)};
  color: ${palette('neutral600')};
  font-size: ${fontSize('xs')};
  text-align: center;
`;

const StyledButton = styled(Button)`
  margin-right: 0;
`;

export interface IApplicationLenderMilestonesRefreshLosStatusProps {
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
  lastUpdated?: string | null;
}

export const ApplicationLenderMilestonesRefreshLosStatus = (
  props: IApplicationLenderMilestonesRefreshLosStatusProps
) => {
  const { disabled, lastUpdated: rawLastUpdated, loading, onClick } = props;

  const handleClick = () => {
    onClick();
  };

  const lastUpdated = useMemo(() => {
    if (!rawLastUpdated) {
      return 'never';
    }

    return <Format.Date format="fromnow" value={rawLastUpdated} />;
  }, [rawLastUpdated]);

  return (
    <StyledDiv>
      <StyledButton
        icon="refresh"
        iconLocation="left"
        onClick={handleClick}
        type="default"
        importance="primary"
        size="xxs"
        loading={loading}
        disabled={disabled || loading}
        compact
      >
        Update from LOS
      </StyledButton>
      <StyledP>{loading ? 'Updating...' : <>Last updated: {lastUpdated}</>}</StyledP>
    </StyledDiv>
  );
};
