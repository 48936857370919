import { ModalDialog, useModal } from '@mortgagehippo/ds';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

interface IScreensharePageContentProps {
  watchUrl: string;
}

const IFrame = styled.iframe`
  width: 100%;
  height: 80vh;
  border: 0;
`;

export const ScreensharePageContent = (props: IScreensharePageContentProps) => {
  const { watchUrl } = props;
  const [isOpen, , closeModal] = useModal(true);
  const [sessionEndedByApplicant, setSessionEndedByApplicant] = useState(false);
  const [sessionEndedByAgent, setSessionEndedByAgent] = useState(false);
  const [sessionEnded, setSessionEnded] = useState(false);

  const listenForIframeMessage = (event: any) => {
    const nextSessionEndedByAgent = event.origin === 'https://maxwell-error-pages.s3.amazonaws.com';

    const nextSessionEndedByApplicant =
      event.origin.includes('upscope.io') &&
      event.data &&
      event.data.sessionStatus === 'terminated' &&
      event.data.type === 'sessionStatusUpdate';

    if (
      (nextSessionEndedByApplicant || nextSessionEndedByAgent) &&
      !sessionEndedByApplicant &&
      !sessionEndedByAgent
    ) {
      setSessionEnded(true);
      setSessionEndedByApplicant(nextSessionEndedByApplicant);
      setSessionEndedByAgent(nextSessionEndedByAgent);
    }
  };

  useEffect(() => {
    window.addEventListener('message', listenForIframeMessage, false);
    return () => {
      window.removeEventListener('message', listenForIframeMessage, false);
    };
  });

  const handleCloseModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  if (sessionEnded && sessionEndedByAgent) {
    window.close();
  }

  return (
    <div>
      {sessionEnded && sessionEndedByApplicant ? (
        <ModalDialog
          isOpen={isOpen}
          onRequestClose={handleCloseModal}
          type="warning"
          hideOkButton
          cancelButtonLabel="Close"
          title="Screen Sharing ended with borrower"
        >
          <strong>Borrower ended the screen sharing session.</strong>
        </ModalDialog>
      ) : null}
      {watchUrl && !sessionEnded ? (
        <IFrame
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
          src={watchUrl}
          title="Co-browse"
        />
      ) : null}
    </div>
  );
};
