import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { graphql } from '../apollo';
import { type SmartviewModelType } from '../apollo/graphql';
import { usePartnerDomain } from './use-partner-domain';

export const FSmartview = graphql(`
  fragment SmartviewFragment on Smartview {
    id
    model
    partnerId
    title
    editable
    isOrganizationLevel
  }
`);

const QSmartview = graphql(`
  query Smartview($partnerId: ID!, $smartviewId: ID!, $model: SmartviewModelType!) {
    partner(id: $partnerId) {
      id
      smartview(id: $smartviewId, model: $model) {
        ...SmartviewFragment
      }
    }
  }
`);

export const useSmartview = (
  model: SmartviewModelType,
  smartviewId: string,
  options: IUseApolloQueryOptions = {}
) => {
  const partnerId = usePartnerDomain();
  const [data, loading, , { refetch }] = useQuery(
    QSmartview,
    {
      partnerId,
      model,
      smartviewId,
    },
    {
      fetchPolicy: 'network-only',
      ...options,
    }
  );

  const handleRefetch = useSafeCallback(async () => refetch());

  const smartview = data?.partner?.smartview;

  return [smartview, loading, handleRefetch] as const;
};
