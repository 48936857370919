import {
  ButtonRouterLink,
  Card,
  Divider,
  Format,
  lineHeight,
  spacing,
  useResponsive,
} from '@mortgagehippo/ds';
import { Fragment, useContext, useEffect } from 'react';
import styled from 'styled-components';

import { useApplicationFileCan } from '../../../../../components/permissions';
import { AusPageTypeLabel } from '../../../application-services/services/aus/aus-page-type-label';
import { ApplicationOverviewContext } from '../../application-overview-context';
import { type IApplicationOverviewSectionProps } from '../../types';
import {
  BlockContent,
  BlockContentContainer,
  BlockLabel,
  BlockSubvalue,
  BlockValue,
  SectionBlock,
  SectionFooter,
} from '../common';
import { Dash } from '../dash';
import { useAusOverviewSection } from './use-aus-overview-section';

const BlockVal = styled(BlockValue)`
  line-height: ${lineHeight('md')};
`;

const BlockSubVal = styled(BlockSubvalue)`
  padding-top: ${spacing(1)};
`;

export const ApplicationOverviewAusSection = (props: IApplicationOverviewSectionProps) => {
  const { id, applicationFileId, className } = props;

  const { sectionReady, sectionDisabled, ready } = useContext(ApplicationOverviewContext)!;

  const [can, canReady] = useApplicationFileCan(applicationFileId);
  const [ausIntegrations, sectionLoading] = useAusOverviewSection(applicationFileId);

  const isDisabled = !sectionLoading && !ausIntegrations.length;

  const responsive = useResponsive();
  const isTablet = responsive.TABLET.EXACT_OR_SMALLER;

  useEffect(() => {
    if (sectionLoading || !canReady) {
      return;
    }

    if (isDisabled) {
      sectionDisabled(id);
      return;
    }

    sectionReady(id);
  }, [sectionLoading, id, isDisabled, sectionReady, sectionDisabled, canReady]);

  if (!ready || isDisabled) {
    return null;
  }

  const ausUrl = `#/applications/${applicationFileId}/integrations/aus`;

  return (
    <Card compact className={className}>
      {ausIntegrations.map((ausIntegration, index) => {
        const { type, assessment } = ausIntegration;

        const addDivider = index < ausIntegrations.length - 1;

        return (
          <Fragment key={type}>
            <SectionBlock>
              <BlockLabel>
                <AusPageTypeLabel type={type} />
              </BlockLabel>
              <BlockContentContainer>
                <BlockContent textAlign={isTablet ? 'left' : 'right'}>
                  <BlockVal fontSize="md">{assessment?.recommendation || <Dash />}</BlockVal>
                  {assessment ? (
                    <BlockSubVal>
                      <Format.Date format="date-med-time" value={assessment.createdAt} />
                    </BlockSubVal>
                  ) : null}
                </BlockContent>
              </BlockContentContainer>
            </SectionBlock>
            {addDivider ? <Divider compact /> : null}
          </Fragment>
        );
      })}
      {can.VIEW_SERVICES_TAB ? (
        <>
          <Divider compact />
          <SectionFooter>
            <ButtonRouterLink
              to={ausUrl}
              icon="right-arrow"
              iconLocation="right"
              importance="tertiary"
              size="xs"
              compact
            >
              Open AUS
            </ButtonRouterLink>
          </SectionFooter>
        </>
      ) : null}
    </Card>
  );
};
