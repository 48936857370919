import { AuthState } from '@mortgagehippo/auth';
import { detectLanguage } from '@mortgagehippo/ds';
import { type IErrorComponentProps } from '@mortgagehippo/util';
import styled from 'styled-components';

import { auth } from '../../services/auth';

/*
 * This component cannot rely on theme provider or cusotmization provider
 * as it may be rendered outside of those providers
 */

const StyledPre = styled.pre`
  white-space: normal;
  background: #e3e7eb;
  border-radius: 4px;
  margin-top: 3em;
  padding: 1em;
  color: #5a6069;
  font-size: 0.75em;
  font-weight: 300;
  text-align: left;
`;

const PrimaryButton = styled.button``;

export type GeneralErrorSize = 'normal' | 'sm';

const Content = styled('div')<{ componentSize?: GeneralErrorSize }>`
  width: 100%;
  font-size: ${(p) => (p.componentSize && p.componentSize === 'sm' && 14) || 16}px;
`;

const Description = styled.div`
  padding: 2em 0.75em;
  max-width: 600px;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
  position: relative;
  margin: 0 auto;
  width: 100%;

  h1 {
    color: #165bae;
    font-size: 1.5em;
    font-weight: 700;
    line-height: 1.2em;
  }

  p {
    color: #262a2f;
    font-size: 1em;
  }

  img {
    width: 100%;
    max-width: 400px;
    max-height: 50vh;
    margin-bottom: 1.5em;
  }

  ${PrimaryButton} {
    font-size: 1em;
    padding: 0.75em 1.5em;
    font-weight: 600;
    text-align: center;
    border-radius: 4px;
    border: none;
    margin-bottom: 0.25em;
    margin-top: 0.25em;
    min-width: 150px;
    max-width: 100%;
    color: #262a2f;
    background: #e3e7eb;

    &:hover {
      cursor: pointer;
    }

    &:hover,
    &:active {
      background: #d2d7dd;
      color: #0e1012;
    }
  }
`;

export const GeneralError = (
  props: IErrorComponentProps & {
    size?: GeneralErrorSize;
    className?: string;
  }
) => {
  const { error, size, className } = props;

  const handleReload = () => {
    window.location.reload();
  };

  const authenticated = auth.getAuthState() === AuthState.AUTHENTICATED_ACCOUNT;

  let language;
  try {
    language = detectLanguage();
  } catch (err) {
    language = 'en';
  }

  let title;
  let message;
  let authenticatedMessage;
  let authenticatedLinkLabel;
  let buttonLabel;
  switch (language) {
    case 'es':
      title = 'Ha Ocurrido Algo Inesperado!';
      message = `Por favor, disculpe las molestias. Nuestros ingenieros han sido notificados sobre este problema. Estamos trabajando para resolverlo lo mas rápidamente posible. Mientras tanto, por favor intente recargar esta pagina otra vez para ver si esto resuelve el problema.`;
      authenticatedMessage = 'Si no es así, puede';
      authenticatedLinkLabel = 'entrar en su cuenta otra vez';
      buttonLabel = 'Recargar la página';
      break;

    case 'en':
    default:
      title = `Oops, we've hit a snag!`;
      message = `Please excuse us for the inconvenience. Our engineers have been notified about the problem. They are working on resolving it as fast as possible. In the meantime, please try reloading this page to see if it will fix the issue.`;
      authenticatedMessage = 'If not, you could try to';
      authenticatedLinkLabel = 'sign in again';
      buttonLabel = 'Reload page';
  }

  return (
    <Content componentSize={size} className={className}>
      <Description>
        <img src="/general-error-robot.svg" alt="Robot working on her laptop to fix the problem" />
        <h1>{title}</h1>
        <p>
          {message}{' '}
          {authenticated ? (
            <>
              {authenticatedMessage} <a href="/logout">{authenticatedLinkLabel}</a>.
            </>
          ) : null}
        </p>
        <p>
          <PrimaryButton type="button" onClick={handleReload}>
            {buttonLabel}
          </PrimaryButton>
        </p>

        {error ? (
          <StyledPre>
            {error.name ? (
              <>
                ERROR: {(error.name === 'Error' && 'Unknown Error') || error.name}
                <br />
              </>
            ) : null}
          </StyledPre>
        ) : null}
      </Description>
    </Content>
  );
};
