import { MediaBreakpoint, palette, spacing, T, useCustomizations } from '@mortgagehippo/ds';
import { type ReactNode } from 'react';
import styled from 'styled-components';

import { OverlayClose } from '$components/overlay';
import { useUserCan } from '$components/permissions';

import {
  type IApplicationApplicant,
  type IApplicationFile,
} from '../../pages/application/use-application-file-data';
import { type IPrimaryApplicantApplicationFile } from '../../pages/application/use-primary-applicants-files';
import { ApplicationHeaderContent } from './application-header-content';
import { ApplicationHeaderSkeleton } from './application-header-skeleton';
import { LAYOUT_DIMENSIONS } from './constants';

const Container = styled('div')<{
  headerBackground?: string;
  headerTitleColor?: string;
}>`
  padding: ${spacing(3)};
  display: flex;
  align-items: center;
  background: ${(p) => p.headerBackground || palette('primary900')};
  flex-basis: ${spacing(8)};
  height: ${LAYOUT_DIMENSIONS.headerHeight}px;
  color: ${(p) => p.headerTitleColor || palette('primary50')};

  ${MediaBreakpoint.PHONE} {
    height: auto;
    align-items: flex-start;
  }
`;

const Content = styled.div`
  flex: 1 1 auto;
`;

const Controls = styled.div`
  flex: 0 0 auto;
  margin-left: ${spacing(4)};
  padding-left: ${spacing(2)};

  ${MediaBreakpoint.PHONE} {
    padding: 0;
    margin: 0;
  }
`;

interface IApplicationHeaderProps {
  applicationFile?: IApplicationFile | null;
  applicants: IApplicationApplicant[];
  primaryApplicantsFiles?: IPrimaryApplicantApplicationFile[];
  showApplicationStatus?: boolean;
  disableApplicationStatus?: boolean;
  onStartApplication?: () => void;
  onUpdateApplicantEmail?: (applicantId: string) => void;
  onDeleteApplicant?: (applicant: string) => void;
}

export const ApplicationHeader = (props: IApplicationHeaderProps) => {
  const {
    applicationFile,
    applicants,
    primaryApplicantsFiles,
    showApplicationStatus,
    disableApplicationStatus,
    onStartApplication,
    onUpdateApplicantEmail,
    onDeleteApplicant,
  } = props;
  const [can] = useUserCan();

  const customizations = useCustomizations();
  const headerTitleColor = customizations.color('app:application.header.title.color');
  const headerBackground = customizations.color('app:application.header.background');
  const headerCloseButtonInverted = customizations.bool(
    'app:application.header.buttons.inverted',
    true
  );

  let contentEl: ReactNode = null;

  if (!applicationFile || !primaryApplicantsFiles) {
    contentEl = <ApplicationHeaderSkeleton backgroundColor={headerTitleColor} />;
  } else {
    contentEl = (
      <ApplicationHeaderContent
        applicationFile={applicationFile}
        applicants={applicants}
        primaryApplicantsFiles={primaryApplicantsFiles}
        showApplicationStatus={showApplicationStatus}
        disableApplicationStatus={disableApplicationStatus}
        onStartApplication={onStartApplication}
        onUpdateApplicantEmail={onUpdateApplicantEmail}
        onDeleteApplicant={onDeleteApplicant}
      />
    );
  }

  return (
    <Container headerTitleColor={headerTitleColor} headerBackground={headerBackground}>
      <Content>{contentEl}</Content>
      {can.VIEW_PIPELINE ? (
        <Controls>
          <OverlayClose.Button
            size="xs"
            iconButton
            icon="close"
            inverted={headerCloseButtonInverted}
            compact
            iconButtonRound
            dimIcon
            type="neutral"
          >
            <T>Close dialog</T>
          </OverlayClose.Button>
        </Controls>
      ) : null}
    </Container>
  );
};
