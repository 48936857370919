import { Button, fontSize, type IButtonProps, NewBadge } from '@mortgagehippo/ds';
import styled from 'styled-components';

interface INotificationIconProps extends IButtonProps {
  count?: number;
  bounce?: boolean;
}

const Container = styled.span`
  display: inline-block;
  position: relative;
  margin-right: ${fontSize('xs')};
`;

const BadgeContainer = styled.div`
  z-index: 10;
  position: absolute;
  top: -${fontSize('xs')};
  right: -${fontSize('xs')};
`;

export const NotificationIconButton = (props: INotificationIconProps) => {
  const { count, bounce = true, ...rest } = props;

  return (
    <Container>
      {count ? (
        <BadgeContainer>
          <NewBadge count={count} bounce={bounce} autoPosition={false} textSize="xs" />
        </BadgeContainer>
      ) : null}
      <Button iconButton iconButtonHumble iconButtonRound {...rest} />
    </Container>
  );
};
