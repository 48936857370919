import { useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { graphql } from '../../../../../apollo';

const QUnderwritingConditionsOverviewSection = graphql(`
  query UnderwritingConditionsOverviewSection($applicationFileId: ID!) {
    applicationFile(id: $applicationFileId) {
      id
      underwritingConditions {
        stats {
          open
          completed
        }
      }
    }
  }
`);

export const useUnderwritingConditionsOverviewSection = (applicationFileId: string) => {
  const [data, loading, , { refetch }] = useQuery(
    QUnderwritingConditionsOverviewSection,
    { applicationFileId },
    {
      suspend: false,
      fetchPolicy: 'network-only',
    }
  );

  const handleRefresh = useSafeCallback(() => refetch({ applicationFileId }));

  const result = data?.applicationFile?.underwritingConditions.stats;

  return [result, loading, handleRefresh] as const;
};
