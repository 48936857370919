import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../../../apollo';
import { type UpdateHellosignTaskInput } from '../../../../apollo/graphql';

export const MUpdateHellosignTask = graphql(`
  mutation UpdateHellosignTask($taskId: ID!, $data: UpdateHellosignTaskInput!) {
    updateHellosignTask(taskId: $taskId, data: $data) {
      id
      meta {
        title
      }
      data
      dueDate
    }
  }
`);

export const useUpdateHellosignTask = () => {
  const updateHellosignTask = useMutation(MUpdateHellosignTask, {} as any);

  return async (taskId: string, data: UpdateHellosignTaskInput) => {
    const response = await updateHellosignTask({
      taskId,
      data,
    });

    return response.data!.updateHellosignTask;
  };
};
