import { type GraphQLError, useApolloClient } from '@mortgagehippo/apollo-hooks';
import { useAuth } from '@mortgagehippo/auth';
import { useEffect, useReducer } from 'react';

import { graphql } from '../../apollo';
import { type UserScopesQuery } from '../../apollo/graphql';

const QUserScopes = graphql(`
  query UserScopes {
    me {
      id
      allowedScopes
    }
  }
`);

interface IUseUserScopesState {
  loading: boolean;
  error: GraphQLError | undefined;
  scopes: string[];
}

const reducer = (_previousState: IUseUserScopesState, action: any): IUseUserScopesState => {
  switch (action.type) {
    case 'LOADING':
      return { loading: true, error: undefined, scopes: [] };
    case 'LOADED':
      return {
        loading: false,
        scopes: action.result,
        error: undefined,
      };
    case 'GRAPHQL_ERROR':
      return {
        loading: false,
        scopes: [],
        error: action.error,
      };
    case 'NETWORK_ERROR':
      return {
        loading: false,
        scopes: [],
        error: undefined,
      };
    default:
      throw new Error(`Unknown action ${action.type}`);
  }
};

export const useUserScopes = () => {
  const client = useApolloClient();
  const [user] = useAuth();
  const userId = user?.id;

  const [state, dispatch] = useReducer(reducer, {
    loading: true,
    error: undefined,
    scopes: [],
  });
  const { loading, error, scopes } = state;

  /*
   * need to make sure we distinguish network errors from apollo
   * errors so that we can "ignore" network errors caused by
   * canceled requests from redirects.
   */
  useEffect(() => {
    let cancelled = false;

    if (!userId) {
      return undefined;
    }

    dispatch({ type: 'LOADING' });
    client
      .query<UserScopesQuery>({
        query: QUserScopes,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      })
      .then((result) => {
        if (cancelled) return undefined;

        if (result.errors?.length) {
          // apollo error
          dispatch({ type: 'GRAPHQL_ERROR', error: result.errors[0] });
          return undefined;
        }

        const scopesResult = result.data?.me?.allowedScopes || [];

        dispatch({ type: 'LOADED', result: scopesResult });

        return scopesResult;
      })
      .catch((e) => {
        // network error
        console.error(e);
        if (cancelled) return;
        dispatch({ type: 'NETWORK_ERROR' });
      });

    return () => {
      cancelled = true;
    };
  }, [client, userId]);

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  const scopesReady = !!userId && !loading;

  return [scopes, scopesReady, { user }] as const;
};
