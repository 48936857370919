import { spacing, Spinner, Text } from '@mortgagehippo/ds';
import styled from 'styled-components';

const LoadingContainer = styled.div`
  padding: ${spacing(6)};
  text-align: center;
`;

export const WorkNumberInstantReportRunningStep = () => (
  <LoadingContainer>
    <Text as="p">Running report. please don&rsquo;t close the window...</Text>
    <Spinner size="xxl" type="spinner4" />
  </LoadingContainer>
);
