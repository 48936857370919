import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../apollo';

export const MCreateApplicationImportMutation = graphql(`
  mutation CreateApplicationImport($agentId: ID!, $siteId: ID!, $file: String!) {
    createApplicationImport(agentId: $agentId, siteId: $siteId, file: $file)
  }
`);

export const useImportMismo = () => {
  const importMismo = useMutation(MCreateApplicationImportMutation);

  return async (agentId: string, siteId: string, file: string) => {
    const response = await importMismo({ agentId, siteId, file });
    return response.data!.createApplicationImport;
  };
};
