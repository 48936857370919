import { notifications, type UploadHandler, useCustomizations, useModal } from '@mortgagehippo/ds';
import { isInsecureFileUploadError, useScanDocument } from '@mortgagehippo/tasks';
import axios, { type AxiosProgressEvent } from 'axios';
import { useCallback, useState } from 'react';

import { useCreateLenderDocuments } from '../../../pages/application/use-create-lender-documents';
import { useLenderDocumentSubmitUrl } from '../../../pages/application/use-lender-document-submit-url';
import { type IActionProps, type IUploadLenderDocumentsAction } from '../types';
import { UploadLenderDocumentsModal } from './upload-lender-documents-modal';

export const UploadLenderDocumentsAction = (props: IActionProps<IUploadLenderDocumentsAction>) => {
  const { action, onDone, onCancel } = props;
  const { applicationFileId } = action;

  const [submitDisabled, setSubmitDisabled] = useState<boolean | undefined>();

  const customizations = useCustomizations();
  const title = customizations.text('uploadLenderDocumentsModal:title', 'Upload Lender Documents');

  const getSubmitUrl = useLenderDocumentSubmitUrl();
  const createLenderDocuments = useCreateLenderDocuments();
  const scanDocument = useScanDocument();

  const [isOpen, , closeModal] = useModal(true);

  const handleSubmit = async (values: any) => {
    try {
      const documents = (values.documents || []).map(({ id, description }: any) => ({
        id,
        description,
      }));

      await createLenderDocuments(applicationFileId, documents);

      closeModal(() => {
        notifications.success({
          messageCid: 'uploadLenderDocumentsModal:notification.create.success',
          message: 'Successfully uploaded documents',
        });
        onDone();
      });
    } catch (e) {
      console.error(e);
      notifications.error({
        messageCid: 'uploadLenderDocumentsModal:notification.create.error',
        message: 'There was an unexpected error creating the documents',
      });
    }
  };

  const handleUpload: UploadHandler = async (file, meta, progress) => {
    try {
      setSubmitDisabled(true);
      const { id: uploadId, postUrl } = await getSubmitUrl(applicationFileId, meta.filename);

      await axios.put(postUrl, file, {
        headers: {
          'Content-Type': meta.mime,
        },
        onUploadProgress: (e: AxiosProgressEvent) => {
          if (!e.total) return;
          const percent = Math.round((e.loaded * 100) / e.total);
          progress(percent);
        },
      });

      try {
        await scanDocument(uploadId);
      } catch (e: unknown) {
        if (isInsecureFileUploadError(e)) {
          return { id: uploadId, hasVirus: true };
        }
        throw e;
      }

      return { id: uploadId };
    } catch (e) {
      notifications.error({
        messageCid: 'uploadLenderDocumentsModal:notification.upload.error',
        message: 'There was an unexpected error while uploading your file',
      });
      throw e;
    } finally {
      setSubmitDisabled(false);
    }
  };

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  return (
    <UploadLenderDocumentsModal
      isOpen={isOpen}
      title={title}
      onUpload={handleUpload}
      onSubmit={handleSubmit}
      onRequestClose={handleCloseModal}
      submitDisabled={submitDisabled}
    />
  );
};
