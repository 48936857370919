import { useResponsive } from '@mortgagehippo/ds';
import { type IQueryTableDataActions } from '@mortgagehippo/query-components';
import { NotFoundError, Storage } from '@mortgagehippo/util';
import { useCallback, useMemo, useRef } from 'react';

import {
  ActionStatus,
  ActionType,
  type IUpdateSmartviewAction,
  type IUpdateSmartviewActionResult,
  useActionEffect,
  useDispatchAction,
} from '$components/actions';
import { ApplicationFilesSmartview } from '$components/smart-view';

import { SmartviewModelType } from '../../apollo/graphql';
import { useSmartview } from '../../hooks/use-smartview';
import { Content, Layout } from '../../layouts/main';
import { history } from '../../services/history';

const toLocalStorageKey = (smartviewId: string) => `lp:smartview-${smartviewId}`;

const removeFromStorage = (smartviewId: string) => {
  try {
    const storage = new Storage('local');
    const key = toLocalStorageKey(smartviewId);
    storage.remove(key);
  } catch (e) {
    //
  }
};

interface ISmartviewsPageContentProps {
  id: string;
}

export const SmartviewsPageContent = (props: ISmartviewsPageContentProps) => {
  const { id } = props;

  const smartviewRef = useRef<any>();

  const [smartview, loading, refetch] = useSmartview(SmartviewModelType.ApplicationFile, id);

  const editable = smartview?.editable;

  const responsive = useResponsive();
  const dispatch = useDispatchAction();

  const handleEdit = useCallback(() => {
    dispatch({
      type: ActionType.UPDATE_SMARTVIEW,
      model: SmartviewModelType.ApplicationFile,
      smartviewId: id,
    });
  }, [dispatch, id]);

  const topActions = useMemo(() => {
    const nextTopActions: IQueryTableDataActions = [];

    if (editable) {
      nextTopActions.push({
        key: 'edit',
        label: 'Edit smartview',
        buttonProps: {
          icon: 'edit',
        },
        onAction: handleEdit,
      });
    }

    return nextTopActions;
  }, [editable, handleEdit]);

  useActionEffect<IUpdateSmartviewAction, IUpdateSmartviewActionResult>(
    async (action, _status, result) => {
      const { smartviewId: actionSmartviewId } = action;
      const { updated, deleted } = result || {};

      if (updated) {
        await refetch();

        if (smartviewRef.current) {
          await smartviewRef.current.refetch(undefined, true);
        }

        return;
      }

      if (deleted) {
        removeFromStorage(actionSmartviewId);
        history.replace('/dashboard');
      }
    },
    ActionType.UPDATE_SMARTVIEW,
    ActionStatus.DONE
  );

  if (loading) {
    return (
      <Layout compact={responsive.PHONE.BIGGER}>
        <Content>&nbsp;</Content>
      </Layout>
    );
  }

  if (!smartview) {
    throw new NotFoundError();
  }

  const pageTitle = smartview.title || 'Smartview';
  const localStorageKey = toLocalStorageKey(smartview.id);

  return (
    <Layout pageTitle={pageTitle} compact={responsive.PHONE.BIGGER}>
      <Content>
        <ApplicationFilesSmartview
          ref={smartviewRef}
          id={id}
          ariaLabel={pageTitle}
          caption={pageTitle}
          topActions={topActions}
          presetsLabel="Filters applied by this smartview"
          localStorageKey={localStorageKey}
        />
      </Content>
    </Layout>
  );
};
