import { Button, Form, SubmitButton, T, TextArea, useCustomizations } from '@mortgagehippo/ds';
import { useMemo } from 'react';
import styled from 'styled-components';

import {
  DEFAULT_DUE_DATE_DAYS_FROM_NOW,
  DueDate,
  getDueDateFromToday,
} from '$components/due-date-field';

const Container = styled.div`
  min-width: 525px;
`;

const Buttons = styled.div`
  display: flex;
  margin: 0;
  align-items: center;
  justify-content: flex-end;

  ${Button} {
    margin-bottom: 0;
  }
`;

export interface IApplicationTaskReopenFormProps {
  taskKey: string;
  onSubmit: (description: string, dueDate: string | null) => void;
  onCancel: () => void;
  isFollowUp?: boolean;
}

export const ApplicationTaskReopenForm = (props: IApplicationTaskReopenFormProps) => {
  const { taskKey, onSubmit, onCancel, isFollowUp } = props;

  const customizations = useCustomizations();
  const placeholder = customizations.text(
    'application_tasks:reopen_description_placeholder',
    'Tell the borrower why are you re-opening this task (optional)'
  );
  const dueDateDisabled = customizations.bool('pageApplication:tabTasks.dueDates.disabled', true);

  const customizationsReady = customizations.ready();

  const showDueDate = !!isFollowUp && !dueDateDisabled;

  const handleSubmit = (values: any) => {
    const { description, due_date } = values;

    onSubmit(description, due_date || null);
  };

  const initialValues = useMemo(() => {
    const defaultDueDate = showDueDate ? getDueDateFromToday(DEFAULT_DUE_DATE_DAYS_FROM_NOW) : null;

    return {
      due_date: defaultDueDate,
    };
  }, [showDueDate]);

  return (
    <Container>
      <Form onSubmit={handleSubmit} initialValues={initialValues}>
        <div>
          <TextArea
            size="sm"
            name="description"
            placeholder={customizationsReady ? placeholder : ''}
          />
        </div>
        {showDueDate ? (
          <DueDate.Box
            name="due_date"
            label="Due Date"
            labelInvisible
            size="sm"
            validateFromNow="gte"
          />
        ) : null}
        <Buttons>
          <Button
            importance="tertiary"
            onClick={onCancel}
            size="xs"
            dataAttributes={{
              'task-reopen-cancel-button': taskKey,
            }}
          >
            <T cid="application_tasks:reopen_cancel_button">Cancel</T>
          </Button>
          <SubmitButton
            type="default"
            importance="primary"
            size="xs"
            disableOnError
            dataAttributes={{
              'task-reopen-confirm-button': taskKey,
            }}
          >
            <T cid="application_tasks:reopen_task_save_button">Re-open task</T>
          </SubmitButton>
        </Buttons>
      </Form>
    </Container>
  );
};
