import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { graphql } from '../../../../../../apollo';
import { type ILosPush } from '../types';

const QByteLosPush = graphql(`
  query ByteLosPush($applicationFileId: ID!) {
    applicationFile(id: $applicationFileId) {
      id
      bytePush {
        id
        pushed
        loanNumber
        createdAt
        updatedAt
        statusCode
        statusDescription
      }
    }
  }
`);

export const useBytePush = (applicationFileId: string, options: IUseApolloQueryOptions = {}) => {
  const [data, loading, , { refetch }] = useQuery(QByteLosPush, { applicationFileId }, options);

  const handleRefresh = useSafeCallback(async () => {
    await refetch({ applicationFileId });
  });

  const result: ILosPush | null | undefined = !loading
    ? data?.applicationFile?.bytePush
    : undefined;

  return [result, loading, handleRefresh] as const;
};
