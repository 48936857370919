import {
  Col,
  Form,
  type ISelectOption,
  Modal,
  Row,
  spacing,
  SubmitButton,
} from '@mortgagehippo/ds';
import { type IQueryTableColumns, QueryTable } from '@mortgagehippo/query-components';
import { isPromise } from '@mortgagehippo/util';
import { useCallback, useRef, useState } from 'react';
import { AgentSelect } from 'src/components/agent-select';
import styled from 'styled-components';

import { useUserCan } from '$components/permissions';

import {
  type PermissionAgentsListQuery,
  type PermissionAgentsListQueryVariables,
} from '../../../../apollo/graphql';
import { type PermissionAgentsListAgent, QPermissionAgentsList } from '../queries';

const columns: IQueryTableColumns<PermissionAgentsListAgent> = [
  {
    title: 'User',
    key: 'name',
    render: (t) => t.name,
  },
  {
    title: 'Email',
    key: 'email',
    render: (t) => t.email,
  },
];

const PaginationWrapper = styled.div`
  margin-top: ${spacing(3)};
`;

const TableWrapper = styled.div`
  margin-bottom: ${spacing(3)};
`;

interface IUsersModalProps {
  partnerId: string;
  authRoleId?: string;
  authRoleName?: string;
  title?: string;
  unassignedAgentOptions?: ISelectOption[];
  onSwitchRole?: ((agentId: string) => Promise<any>) | ((agentId: string) => void);
  onRequestClose?: () => void;
  isOpen: boolean;
  width?: number;
}

export const UsersModal = (props: IUsersModalProps) => {
  const { partnerId, authRoleId, authRoleName, onSwitchRole, onRequestClose, isOpen, ...rest } =
    props;
  const tableRef = useRef<any>();
  const [formKey, setFormKey] = useState(0);
  const [can] = useUserCan();

  const skip = !isOpen || !authRoleId;

  const handleSwitchRole = useCallback(
    async (values: any) => {
      if (!onSwitchRole) {
        return;
      }

      const { agentId } = values;

      const result = onSwitchRole(agentId);

      if (isPromise(result)) {
        await result;
      }

      setFormKey(formKey + 1);

      tableRef!.current.refetch();
    },
    [formKey, onSwitchRole]
  );

  return (
    <Modal
      hideCancelButton
      onRequestClose={onRequestClose}
      onOk={onRequestClose}
      isOpen={isOpen}
      {...rest}
    >
      {can.UPDATE_AUTH_ROLE_USERS ? (
        <Form key={formKey} onSubmit={handleSwitchRole}>
          <Row>
            <Col xs={24} md={12}>
              <AgentSelect name="agentId" required compact size="sm" />
            </Col>
            <Col xs={24} md={6}>
              <SubmitButton type="default" importance="secondary" size="xs" compact>
                Switch to {authRoleName}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ) : null}
      <TableWrapper>
        <QueryTable<PermissionAgentsListQuery, PermissionAgentsListQueryVariables>
          caption="Agents"
          query={QPermissionAgentsList}
          dataSource={(result) => result.partner?.authRole?.agents.items || []}
          itemTotal={(result) => result.partner?.authRole?.agents.total || 0}
          nextCursor={(result) => result.partner?.authRole?.agents.nextCursor}
          previousCursor={(result) => result.partner?.authRole?.agents.previousCursor}
          rowKey={(item) => item.id}
          variables={{ partnerId, authRoleId: authRoleId! }}
          columns={columns}
          ref={tableRef}
          skip={skip}
          bottomContent={
            <PaginationWrapper>
              <QueryTable.Pagination />
            </PaginationWrapper>
          }
        >
          <QueryTable.Data />
        </QueryTable>
      </TableWrapper>
    </Modal>
  );
};
