import { Button, Input, SimpleFieldAutoArray } from '@mortgagehippo/ds';
import { useCallback } from 'react';
import styled from 'styled-components';

const PRESENT_FIELDS = ['name', 'value'];

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Col = styled.div<{ flex?: number }>`
  flex: ${({ flex }) => (flex === undefined ? 1 : flex)};
  flex-basis: auto;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

export interface IMetadataItem {
  name: string;
  value: string;
}

export type IMetadataFieldValue = IMetadataItem[];

interface IAgentModalMetadata {
  readOnly?: boolean;
}
export const AgentModalMetadata = (props: IAgentModalMetadata) => {
  const { readOnly = false } = props;

  const handleValidateItem = useCallback((value: IMetadataItem) => {
    if (!value.name || !value.value) {
      return 'Invalid Item';
    }

    return undefined;
  }, []);

  return (
    <SimpleFieldAutoArray<IMetadataItem>
      name="metadata"
      validateItem={handleValidateItem}
      presentFields={PRESENT_FIELDS}
      render={({ name: itemName, error, onRemove, active }) => (
        <Row>
          <Col>
            <Input
              name={`${itemName}.name`}
              placeholder="Name"
              compact
              aria-label="Name"
              error={!active && !!error}
            />
          </Col>
          <Col>
            <Input
              name={`${itemName}.value`}
              placeholder="Value"
              compact
              aria-label="Value"
              error={!active && !!error}
            />
          </Col>
          {!readOnly && (
            <Col flex={0}>
              <Button
                icon="delete"
                iconButton
                size="xs"
                type="danger"
                importance="tertiary"
                onClick={onRemove}
                compact
              />
            </Col>
          )}
        </Row>
      )}
    />
  );
};
