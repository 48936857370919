import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../../apollo';

export const MUpdateAgentAvatar = graphql(`
  mutation UpdateAgentAvatar($agentId: ID!, $uploadKey: String!) {
    updateAgentAvatar(agentId: $agentId, uploadKey: $uploadKey) {
      ...CurrentUserDataFragment
    }
  }
`);

export const useUpdateAgentAvatar = () => {
  const updateAgentAvatar = useMutation(MUpdateAgentAvatar, undefined);

  return async (agentId: string, uploadKey: string) => {
    await updateAgentAvatar({ agentId, uploadKey });
  };
};
