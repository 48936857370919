import { graphql } from '../../apollo';

export const SEvents = graphql(`
  subscription Events {
    events {
      typeName
      payload
      meta
      error
    }
  }
`);
