import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useMountedRef } from '@mortgagehippo/util';
import { useEffect, useState } from 'react';

import { graphql } from '../../../../../apollo';

const MGetDocutechCreateDocumentsUrl = graphql(`
  mutation GetDocutechCreateDocumentsUrl($applicationFileId: ID!) {
    getDocutechCreateDocumentsUrl(applicationFileId: $applicationFileId) {
      url
    }
  }
`);

export const useDocutechCreateDocumentsUrl = (applicationFileId: string) => {
  const fn = useMutation(MGetDocutechCreateDocumentsUrl, undefined);

  const mountedRef = useMountedRef();
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      if (!mountedRef.current) {
        return;
      }
      const result = await fn({ applicationFileId });
      const { url: nextUrl } = result.data!.getDocutechCreateDocumentsUrl;
      setUrl(nextUrl);
    })();
  }, [fn, applicationFileId, mountedRef]);

  return url;
};
