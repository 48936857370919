import { Title } from '@mortgagehippo/ds';
import { type RouteComponentProps } from 'react-router';

import { Content, Layout } from '../../../layouts/application';
import { ApplicationTeam } from './application-team';

export interface IApplicationTeamRouteParams {
  applicationFileId: string;
}

export const ApplicationTeamRoute = (props: RouteComponentProps<IApplicationTeamRouteParams>) => {
  const { match } = props;
  const { params } = match;
  const { applicationFileId } = params;

  return (
    <Layout tutorialId="team" applicationFileId={applicationFileId}>
      <Content>
        <Title level={1} cid="pageApplication:menu.tabs.team.label">
          Team
        </Title>
        <ApplicationTeam applicationFileId={applicationFileId} />
      </Content>
    </Layout>
  );
};
