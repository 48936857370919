import {
  borderRadius,
  fontSize,
  fontWeight,
  Icon,
  lineHeight,
  palette,
  paletteAlpha,
  spacing,
} from '@mortgagehippo/ds';
import { TaskTypeLabel } from '@mortgagehippo/tasks';
import { toArray } from '@mortgagehippo/util';
import styled from 'styled-components';

import { type IApplicationApplicant } from '../../../../../pages/application/use-application-file-data';
import { type IUnderwritingCondition } from '../../../../../pages/application/use-undewriting-conditions';
import { COMMON_APPLICANT_OPTION_VALUE } from '../../constants';
import { type IBulkCustomTask } from '../../types';
import { getTaskValidationErrors } from '../../util';

const TaskName = styled.div`
  font-weight: ${fontWeight('bold')};
  color: ${palette('neutral900')};
`;

const TaskBorrowers = styled.div`
  color: ${palette('neutral600')};
  font-size: ${fontSize('xs')};
  line-height: ${lineHeight('xs')};
`;

const UnderwritingCondition = styled.div`
  background-color: ${paletteAlpha('white', 7)};
  margin-top: ${spacing(2)};
  padding: ${spacing(3)};
  color: ${palette('neutral600')};
  border-radius: ${borderRadius(3)};
`;

const Item = styled.div``;
const Label = styled.div``;
const Value = styled.div``;

const TaskDetails = styled.div`
  ${Item} {
    display: flex;
    align-items: center;
  }

  ${Label} {
    flex: 0 0 65px;
    text-transform: uppercase;
    padding-right: ${spacing(2)};
    font-size: ${fontSize('xxs')};
    line-height: ${lineHeight('xxs')};
  }

  ${Value} {
    flex: 1 1 auto;
    font-weight: ${fontWeight('bold')};
    font-size: ${fontSize('xs')};
    line-height: ${lineHeight('xs')};
  }
`;

const ErrorList = styled.ul`
  padding: 0;
  margin: ${spacing(2)} 0 0;
  list-style-type: none;
  color: ${palette('danger500')};
`;

const Error = styled.li`
  display: flex;
`;

const ErrorIcon = styled.span`
  flex: 0 0 auto;
  padding-right: ${spacing(2)};
`;

const ErrorMessage = styled.span`
  flex: 1 1 auto;
  font-weight: ${fontWeight('bold')};
  font-size: ${fontSize('xs')};
  line-height: ${lineHeight('xs')};
`;

interface ITasksListLabelProps {
  itemValue: IBulkCustomTask;
  applicants: IApplicationApplicant[];
  underwritingConditions?: IUnderwritingCondition[];
  showBorrowerNames?: boolean;
}

export const TasksListLabel = (props: ITasksListLabelProps) => {
  const { itemValue, applicants, underwritingConditions, showBorrowerNames } = props;

  const { type, title, underwriting_condition_id: underwritingConditionId } = itemValue;

  const applicantIds = toArray(itemValue.applicant_ids);

  const applicantNames = applicantIds.map((id) => {
    if (id === COMMON_APPLICANT_OPTION_VALUE) {
      return 'All borrowers';
    }

    const applicant = applicants.find((a) => a.id === id);

    return applicant?.name || 'Borrower';
  });

  const applicantsEl = applicantNames.join(' & ');

  const underwritingCondition = underwritingConditionId
    ? underwritingConditions?.find((u) => u.id === underwritingConditionId)
    : undefined;

  const errors = underwritingCondition ? getTaskValidationErrors(itemValue) : [];

  return (
    <>
      <TaskName>{title}</TaskName>
      {showBorrowerNames ? <TaskBorrowers>{applicantsEl}</TaskBorrowers> : null}
      {underwritingCondition ? (
        <UnderwritingCondition>
          <TaskDetails>
            <Item>
              <Label>Task Type</Label>
              <Value>
                <TaskTypeLabel type={type} />
              </Value>
            </Item>
            <Item>
              <Label>Condition</Label>
              <Value>{underwritingCondition.title || <>&mdash;</>}</Value>
            </Item>
          </TaskDetails>
          {errors.length > 0 && (
            <ErrorList>
              {errors.map((error) => (
                <Error key={error}>
                  <ErrorIcon>
                    <Icon name="error" />
                  </ErrorIcon>
                  <ErrorMessage>{error}. Edit to fix the issue.</ErrorMessage>
                </Error>
              ))}
            </ErrorList>
          )}
        </UnderwritingCondition>
      ) : null}
    </>
  );
};
