import {
  Alert,
  ButtonLink,
  FilePreview,
  fontWeight,
  Format,
  type ITableActions,
  type ITableCols,
  MediaBreakpoint,
  notifications,
  Select,
  styled,
  T,
  Table,
} from '@mortgagehippo/ds';
import { type IApplicationFileApplicant } from '@mortgagehippo/tasks';
import { isNil, keyBy, uniq, without } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';

import { DocumentType, type LosType } from '../../../apollo/graphql';
import { getServiceUrl, isDocumentPushed, ServiceName } from '../application-services';
import { type IApplicationFileDocument } from '../use-application-file-documents';
import { LosStatusColumn, TaskColumn } from './columns';

const isDeletable = (document: IApplicationFileDocument) =>
  document.type === DocumentType.LenderDocument;

const LightFont = styled.span`
  font-weight: ${fontWeight('light')};
`;

const SelectWrapper = styled.div`
  max-width: 250px;

  ${MediaBreakpoint.PHONE} {
    max-width: none;
    width: 100%;
  }
`;

interface IApplicationDocumentsContentProps {
  applicationFileId: string;
  documents: IApplicationFileDocument[];
  applicants: IApplicationFileApplicant[];
  losTypes?: LosType[];
  activeLosType?: LosType | null;
  showPushApplicationAlert?: boolean;
  onChangeLosType: (losType: LosType) => void;
  onUploadDocument?: () => void;
  onDeleteLenderDocument?: (documentId: string) => Promise<void>;
  onCreateDocumentsArchive?: (documentIds: string[]) => Promise<void>;
  preventDownloads?: boolean;
  onTriggerDocumentsPush?: (documentIds: string[]) => Promise<unknown>;
}

export const ApplicationDocumentsContent = (props: IApplicationDocumentsContentProps) => {
  const {
    applicationFileId,
    documents,
    applicants,
    losTypes,
    activeLosType,
    showPushApplicationAlert,
    onChangeLosType,
    onUploadDocument,
    onDeleteLenderDocument,
    onCreateDocumentsArchive,
    preventDownloads,
    onTriggerDocumentsPush,
  } = props;

  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
  const [documentsBeingPushed, setDocumentsBeingPushed] = useState<string[]>([]);

  const canUploadDocument = !!onUploadDocument;
  const canDeleteLenderDocument = !!onDeleteLenderDocument;
  const canExportDocuments = !!onCreateDocumentsArchive;
  const canTriggerDocumentsPush = !!onTriggerDocumentsPush;

  const handleCreateArchive = useCallback(async () => {
    if (!onCreateDocumentsArchive) {
      return;
    }

    await onCreateDocumentsArchive(selectedDocuments);
  }, [onCreateDocumentsArchive, selectedDocuments]);

  const handlePushSelectedDocuments = useCallback(async () => {
    if (!onTriggerDocumentsPush || !selectedDocuments.length) {
      return;
    }

    const documentsToPush = selectedDocuments.filter((documentId) => {
      const document = documents.find((d) => d.id === documentId);

      if (!document) {
        return false;
      }

      return !isDocumentPushed(document) && !documentsBeingPushed.includes(documentId);
    });

    setSelectedDocuments([]);

    if (!documentsToPush.length) {
      notifications.info({ message: 'All your selected documents were already pushed to the LOS' });
      return;
    }

    setDocumentsBeingPushed((d) => [...d, ...documentsToPush]);

    await onTriggerDocumentsPush(documentsToPush);

    setDocumentsBeingPushed((d) => without(d, ...documentsToPush));
  }, [documents, documentsBeingPushed, onTriggerDocumentsPush, selectedDocuments]);

  const handlePushDocument = useCallback(
    (documentId: string) => async () => {
      if (!onTriggerDocumentsPush) {
        return;
      }

      if (documentsBeingPushed.includes(documentId)) {
        return;
      }

      setDocumentsBeingPushed((d) => [...d, documentId]);

      await onTriggerDocumentsPush([documentId]);

      setDocumentsBeingPushed((d) => without(d, documentId));
    },
    [documentsBeingPushed, onTriggerDocumentsPush]
  );

  const handleAction = useCallback(
    async (_actionKey: string, data: IApplicationFileDocument | undefined) => {
      try {
        if (!data || !canDeleteLenderDocument || !isDeletable(data)) {
          return;
        }

        await onDeleteLenderDocument(data.id);

        notifications.success({
          message: 'Successfully deleted document.',
        });
      } catch (e) {
        notifications.error({
          message: 'There was an unexpected error deleting the document.',
        });
      }
    },
    [canDeleteLenderDocument, onDeleteLenderDocument]
  );

  const columns: ITableCols<IApplicationFileDocument> = useMemo(() => {
    const applicantsById = keyBy(applicants, 'id');
    const showNames = applicants.length > 1;
    const showInitials =
      showNames && uniq(applicants.map((v) => v.firstName)).length < applicants.length;

    return [
      {
        title: <T cid="pageApplication:tabDocuments.table.column.document">Document</T>,
        key: 'document',
        colTitleLocationMobile: 'top',
        alignMobile: 'left',
        render: (record) => {
          const { filename, url, description } = record;

          return (
            <FilePreview.Link
              name={filename || ''}
              url={url || ''}
              description={description || undefined}
            />
          );
        },
        verticalAlign: 'top',
      },
      {
        title: <T cid="pageApplication:tabDocuments.table.column.dateUploaded">Date Uploaded</T>,
        key: 'date_uploaded',
        colTitleLocationMobile: 'top',
        alignMobile: 'left',
        render: (record) => <Format.Date format="date-short" value={record.createdAt} />,
        verticalAlign: 'top',
      },
      {
        title: <T cid="pageApplication:tabDocuments.table.column.uploadedBy">Uploaded by</T>,
        key: 'uploaded_by',
        colTitleLocationMobile: 'top',
        alignMobile: 'left',
        render: (record) => record.uploadedBy?.name || <>&mdash;</>,
        verticalAlign: 'top',
      },
      {
        title: <T cid="pageApplication:tabDocuments.table.column.task">Borrower Task</T>,
        key: 'task',
        colTitleLocationMobile: 'top',
        alignMobile: 'left',
        render: (record) => {
          const { task } = record;

          const applicant = !isNil(task) ? applicantsById[`${task.primaryApplicantId}`] : null;

          if (!task || !applicant) {
            return <>&mdash;</>;
          }

          return (
            <TaskColumn
              applicationFileId={applicationFileId}
              task={task}
              applicant={applicant}
              showName={showNames}
              showInitial={showInitials}
            />
          );
        },
        verticalAlign: 'top',
      },
      {
        title: <T cid="pageApplication:tabDocuments.table.column.pushedToLos">Pushed to LOS</T>,
        key: 'pushed_to_los',
        colTitleLocationMobile: 'top',
        alignMobile: 'left',
        render: (record) => {
          if (!activeLosType || showPushApplicationAlert) {
            return <>&mdash;</>;
          }

          const disabled = documentsBeingPushed.includes(record.id);

          return (
            <LosStatusColumn
              type={activeLosType}
              push={record.losPush}
              onTriggerDocumentPush={
                canTriggerDocumentsPush ? handlePushDocument(record.id) : undefined
              }
              disabled={disabled}
            />
          );
        },
        verticalAlign: 'top',
        visible: !!activeLosType,
      },
    ];
  }, [
    applicants,
    activeLosType,
    applicationFileId,
    documentsBeingPushed,
    handlePushDocument,
    showPushApplicationAlert,
    canTriggerDocumentsPush,
  ]);

  const losTypeOptions = useMemo(
    () =>
      (losTypes || []).map((losType) => ({
        value: losType,
        label: <ServiceName type={losType} />,
        selectedLabel: (
          <ServiceName type={losType}>
            <LightFont>LOS:</LightFont>{' '}
          </ServiceName>
        ),
      })),
    [losTypes]
  );

  const showLosSelect = losTypeOptions.length > 1;

  const topContent = showLosSelect ? (
    <SelectWrapper>
      <Select.InputBox
        name="los_type"
        label="LOS Type"
        labelInvisible
        value={activeLosType}
        onChange={onChangeLosType}
        options={losTypeOptions}
        size="sm"
        compact
      />
    </SelectWrapper>
  ) : null;

  const topActions: ITableActions = [
    {
      key: 'upload',
      label: <T cid="pageApplication:tabDocuments.table.uploadButton.label">Upload document</T>,
      buttonProps: {
        icon: 'plus',
      },
      hidden: !canUploadDocument,
      onAction: onUploadDocument,
    },
    {
      key: 'export',
      label: <T cid="pageApplication:tabDocuments.table.downloadButton.label">Download selected</T>,
      buttonProps: {
        icon: 'download',
      },
      hidden: !canExportDocuments,
      disabled: !selectedDocuments.length,
      onAction: handleCreateArchive,
    },
    {
      key: 'push',
      label: 'Push selected to LOS',
      buttonProps: {
        icon: 'direction-up',
        importance: 'secondary',
      },
      hidden: !canTriggerDocumentsPush || !activeLosType || showPushApplicationAlert,
      disabled: !selectedDocuments.length,
      onAction: handlePushSelectedDocuments,
    },
  ];

  const actions: ITableActions<IApplicationFileDocument> = [
    {
      key: 'delete',
      label: 'Delete',
      buttonProps: {
        icon: 'delete',
      },
      confirm: {
        title: 'Are you sure?',
        explanation: 'This action is irreversible',
        okButtonLabel: 'Delete',
        type: 'info',
      },
      onAction: handleAction,
      hidden: (record) => !record || !canDeleteLenderDocument || !isDeletable(record),
    },
  ];

  const name = activeLosType && <ServiceName type={activeLosType} />;

  const linkToService = activeLosType && getServiceUrl(applicationFileId, activeLosType);

  const buttonLink = linkToService ? (
    <ButtonLink to={linkToService}>Open {name} service</ButtonLink>
  ) : (
    `Open ${name} service`
  );
  return (
    <>
      {showPushApplicationAlert ? (
        <Alert type="warning">
          Pushing documents is currently unavailable because the application file has not yet been
          pushed to {name}. Please {buttonLink} to push the application file.
        </Alert>
      ) : null}
      <FilePreview title="Preview" preventDownloads={preventDownloads}>
        <Table<IApplicationFileDocument>
          size="sm"
          caption="Documents"
          data={documents}
          rowKey={(item) => item.id}
          cols={columns}
          selectionMode={canExportDocuments ? 'checkbox' : undefined}
          selectedKeys={selectedDocuments}
          onSelectionChange={setSelectedDocuments}
          topActions={topActions}
          topContent={topContent}
          rowActions={actions}
          hideTopBackground={!showLosSelect}
        />
      </FilePreview>
    </>
  );
};
