import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../apollo';

export const MDeleteApplicationFileApplicant = graphql(`
  mutation DeleteApplicationFileApplicant($applicationFileId: ID!, $applicantId: ID!) {
    deleteApplicationFileApplicant(applicationFileId: $applicationFileId, applicantId: $applicantId)
  }
`);

export const useDeleteApplicant = () => {
  const deleteApplicant = useMutation(MDeleteApplicationFileApplicant, undefined);

  return async (applicationFileId: string, applicantId: string) => {
    await deleteApplicant({ applicationFileId, applicantId });
  };
};
