import { CustomizationService } from '@mortgagehippo/ds';

import { graphql } from '../apollo';
import { client } from '../apollo/apollo-client';
import {
  type CustomizationValuesQuery,
  type CustomizationValuesQueryVariables,
} from '../apollo/graphql';

const QCustomizationValues = graphql(`
  query CustomizationValues($domain: String!, $namespaces: [String!]!, $languages: [String!]!) {
    customizations(domain: $domain, namespaces: $namespaces, languages: $languages) {
      id
      key
      namespace
      value
      language
    }
  }
`);

export const customizationsService = new CustomizationService({
  cachePrefix: 'lp',
  fetcher: async (namespaces: string[], language?: string) => {
    const { host } = window.location;

    const result: any = await client.query<
      CustomizationValuesQuery,
      CustomizationValuesQueryVariables
    >({
      query: QCustomizationValues,
      variables: {
        domain: host,
        namespaces,
        languages: language ? [language] : [],
      },
    });

    const data = result?.data?.customizations || [];
    return CustomizationService.fromArray(namespaces, data);
  },
});
