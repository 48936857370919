import {
  Alert,
  type FormSubmitHandler,
  notifications,
  spacing,
  useModal,
  useModalConfirm,
} from '@mortgagehippo/ds';
import { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { useUserCan } from '$components/permissions';
import { getChoicesFn, toSearchFields } from '$components/smart-view/util';

import { useSmartview } from '../../../hooks/use-smartview';
import { useSmartviewConfig } from '../../../hooks/use-smartview-config';
import { useSmartviewDocumentation } from '../../../hooks/use-smartview-documentation';
import { useSmartviews } from '../../../hooks/use-smartviews';
import {
  type IActionProps,
  type IUpdateSmartviewAction,
  type IUpdateSmartviewActionResult,
} from '../types';
import { SmartviewModal } from './smartview-modal';
import { useDeleteSmartview } from './use-delete-smartview';
import { useUpdateSmartview } from './use-update-smartview';

const StyledAlert = styled(Alert)`
  margin-bottom: ${spacing(5)};
`;

export const UpdateSmartviewAction = (
  props: IActionProps<IUpdateSmartviewAction, IUpdateSmartviewActionResult>
) => {
  const { action, onDone, onCancel } = props;
  const { model, smartviewId } = action;

  const [isOpen, , closeModal] = useModal(true);
  const [confirmDelete, confirmDeleteModal] = useModalConfirm(
    'Are you sure you want to delete this smartview?',
    {
      okButtonLabel: 'Yes, delete it',
      cancelButtonLabel: 'Cancel',
      type: 'warning',
    }
  );

  const [can, canReady] = useUserCan();
  const [smartview, smartviewLoading] = useSmartview(model, smartviewId);
  const [config, configLoading] = useSmartviewConfig(model, smartviewId);
  const [documentation, documentationLoading] = useSmartviewDocumentation(model);
  const [, , refetchSmartviews] = useSmartviews(model, {
    skip: true,
  });

  const loading = !canReady || smartviewLoading || configLoading || documentationLoading;
  const partnerId = smartview?.partnerId;
  const allowOrganizationLevel =
    can.CREATE_ORGANIZATION_LEVEL_SMARTVIEW && smartview?.isOrganizationLevel;
  const showOrganizationLevelAlert = smartview?.isOrganizationLevel;

  const updateSmartview = useUpdateSmartview();
  const deleteSmartview = useDeleteSmartview();

  const handleSubmit: FormSubmitHandler = async (values) => {
    try {
      const { title, config: valuesConfig } = values;

      const input = {
        title,
        config: valuesConfig,
      };

      await updateSmartview(smartviewId, input);

      closeModal(async () => {
        notifications.success({
          message: 'Successfully updated smartview',
        });
        onDone({ updated: true });
      });
    } catch (e) {
      notifications.error({
        message: 'There was an unexpected error updating the smartview',
      });
    }
  };

  const handleDelete = async () => {
    try {
      await deleteSmartview(smartviewId);

      // don't have a good place to do this, layout gets unmounted on redirect
      await refetchSmartviews();

      closeModal(async () => {
        notifications.success({
          message: 'Successfully deleted smartview',
        });
        onDone({ deleted: true });
      });
    } catch (e) {
      notifications.error({
        message: 'There was an unexpected error deleting the smartview',
      });
    }
  };

  const handleConfirmDelete = () => {
    confirmDelete({
      onConfirm: handleDelete,
    });
  };

  const handleRequestClose = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  const initialValues = useMemo(() => {
    if (!smartview || !config) {
      return {};
    }

    const { columns, filters, defaultSortField, defaultSortDirection } = config;

    return {
      ...smartview,
      config: {
        columns,
        filters,
        defaultSortField,
        defaultSortDirection,
      },
    };
  }, [config, smartview]);

  const searchFields = useMemo(() => {
    if (!config || !partnerId) {
      return {};
    }

    const { metadata } = config;
    const configSearchFields = config.searchFields || [];

    return toSearchFields(configSearchFields, metadata, getChoicesFn(`${partnerId}`, model));
  }, [config, model, partnerId]);

  const loadingFailed = !loading && (!smartview || !documentation || !config);
  useEffect(() => {
    if (loadingFailed) {
      notifications.error({
        message: 'An error occurred while processing your request. Please try again later.',
      });
      handleRequestClose();
    }
  }, [handleRequestClose, loadingFailed]);

  useEffect(() => {
    if (smartview && !smartview.editable) {
      notifications.error({
        message: 'Access denied',
      });
      handleRequestClose();
    }
  }, [handleRequestClose, smartview]);

  const showLoading = loading || !smartview || !documentation || !config;

  return (
    <>
      <SmartviewModal
        title="Edit Smartview"
        onSubmit={handleSubmit}
        onRequestClose={handleRequestClose}
        isOpen={isOpen}
        loading={showLoading}
        initialValues={initialValues}
        secondaryActionButtonLabel="Delete smartview"
        secondaryActionButtonPosition="left"
        secondaryActionButtonType="danger"
        secondaryActionButtonImportance="tertiary"
        onSecondaryActionButtonAction={handleConfirmDelete}
        allowOrganizationLevel={allowOrganizationLevel}
        editing
        documentation={documentation}
        searchFields={searchFields}
      >
        {showOrganizationLevelAlert ? (
          <StyledAlert type="warning" size="sm">
            This is an organization-level smartview. Any changes you make will become available to
            all users in your organization.
          </StyledAlert>
        ) : null}
      </SmartviewModal>
      {confirmDeleteModal}
    </>
  );
};
