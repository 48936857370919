/* eslint-disable no-nested-ternary */
import {
  Alert,
  borderRadiuses,
  Button,
  Empty,
  fontSize,
  type ITreeOption,
  lineHeight,
  MediaBreakpoint,
  NewBadge,
  notifications,
  palette,
  Popover,
  shadow,
  spacing,
  T,
  Tabs,
  Tag,
  Tree,
  useModal,
  useResponsive,
  useTheme,
} from '@mortgagehippo/ds';
import * as Sentry from '@sentry/browser';
import { sortBy, uniq } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { useUserCan } from '$components/permissions';

import { type IFolderAgent } from '../../../hooks/use-folder-agents';
import { usePartnerDomain } from '../../../hooks/use-partner-domain';
import { usePartnerFolders } from '../../../hooks/use-partner-folders';
import { AddFolderModal } from './add-folder-modal';
import { AddFolderUserPopover } from './add-folder-user-popover';
import { ROOT_TREE_OPTION_KEY } from './constants';
import { EditFolderModal } from './edit-folder-modal';
import { FolderAgentsPendingTable } from './folder-agents-pending-table';
import { FolderAgentsTable } from './folder-agents-table';
import { FolderManager, type ManagedFolder, type ManagedFolderUser } from './manager';
import { useUpdatePartnerFolders } from './use-update-partner-folders';
import { isFakeRootFolder, toServerInput } from './util';

const CURRENT_TAB_ID = 'current';
const PENDING_TAB_ID = 'pending';

const Row = styled.div`
  display: flex;

  ${MediaBreakpoint.PHONE} {
    display: block;
  }
`;

const TreeCol = styled.div`
  flex: 0 0 340px;
`;

const ContentCol = styled.div`
  flex: 1 1 auto;
  margin-top: ${spacing(5)};
`;

const UnsavedChangesTag = styled(Tag)`
  margin-right: ${spacing(2)};
`;

const UnsavedChangesAlert = styled(Alert)`
  margin-bottom: 0;
`;

const PageActions = styled.div`
  display: flex;
  background: ${palette('white')};
  border-radius: ${borderRadiuses(0, 0, 2, 2)};
  box-shadow: ${shadow(1)};
  padding: ${spacing(2)} ${spacing(1)};

  ${MediaBreakpoint.PHONE} {
    display: block;
    padding: ${spacing(2)};

    ${UnsavedChangesTag} {
      display: none;
    }
  }
`;

const LeftPageActions = styled.div`
  flex: 0 0 auto;

  ${MediaBreakpoint.PHONE} {
    display: inline;
  }
`;

const RightPageActions = styled.div`
  flex: 1 1 auto;
  text-align: right;

  ${MediaBreakpoint.PHONE} {
    display: inline;
  }
`;

const SaveButton = styled(Button)`
  margin-right: ${spacing(2)};

  ${MediaBreakpoint.PHONE} {
    margin-right: 0;

    && {
      width: 100%;
    }
  }
`;

const PageContent = styled.div`
  ${MediaBreakpoint.PHONE} {
    margin-top: ${spacing(3)};
  }
`;

const TreeWrapper = styled.div`
  margin-right: ${spacing(4)};
  padding-right: ${spacing(2)};
  padding-top: ${spacing(5)};
  border-right: 1px solid ${palette('neutral200')};
  min-height: 65vh;
  overflow: hidden;

  ${MediaBreakpoint.PHONE} {
    margin-right: 0;
    padding-right: 0;
    min-height: auto;
    border-right: none;
    margin-bottom: ${spacing(4)};
    padding-bottom: ${spacing(2)};
    border-bottom: 1px solid ${palette('neutral200')};
  }
`;

const TreeFolderName = styled.span<{ status: string }>`
  text-decoration: ${(p) => (p.status === 'deleted' ? 'line-through' : 'none')};
`;

const FolderDetails = styled.div`
  margin-bottom: ${spacing(4)};
`;

const FolderNameWrapper = styled.div`
  display: flex;
  align-items: end;
`;

const FolderName = styled.h2`
  flex: 0 0 auto;
  font-size: ${fontSize('lg')};
  line-height: ${lineHeight('lg')};
  margin-bottom: 0;
`;

const FolderActions = styled.div`
  flex: 0 0 auto;
  margin-left: ${spacing(1)};
`;

const FolderDescription = styled.p`
  font-size: ${fontSize('sm')};
  line-height: ${lineHeight('sm')};
  margin-bottom: 0;
`;

const toTreeOptions = (folders: ManagedFolder[]): ITreeOption[] => {
  const sortedFolders = sortBy(folders, (f) => f.getName());

  return sortedFolders.map((folder) => {
    const status = folder.isSoftDeleted()
      ? 'deleted'
      : folder.isNew()
        ? 'new'
        : folder.isEdited()
          ? 'edited'
          : 'none';

    return {
      key: folder.getId(),
      title: (
        <>
          <TreeFolderName status={status}>{folder.getName()}</TreeFolderName>
          {status === 'deleted' && (
            <Tag size="xs" color="danger">
              Deleted
            </Tag>
          )}
          {status === 'new' && (
            <Tag size="xs" color="success">
              New
            </Tag>
          )}
          {status === 'edited' && (
            <>
              {' '}
              <NewBadge ariaLabel="changes" />
            </>
          )}
        </>
      ),
      children: toTreeOptions(folder.getChildren()),
    };
  });
};

export const FoldersTab = () => {
  const [folders, setFolders] = useState<ManagedFolder[]>([]);
  const [selectedFolderId, setSelectedFolderId] = useState<string | undefined>();
  const [selectedTab, setSelectedTab] = useState<string | undefined>();
  const [expandedKeys, setExpandedKeys] = useState<string[]>([ROOT_TREE_OPTION_KEY]);
  const [saving, setSaving] = useState(false);

  const [addModalIsOpen, openAddModal, closeAddModal] = useModal(false);
  const [editModalIsOpen, openEditModal, closeEditModal] = useModal(false);

  const tableRef = useRef<any>();

  const partnerId = usePartnerDomain();
  const [originalFolders, loading, refreshFolders] = usePartnerFolders();

  const [can] = useUserCan();

  const responsive = useResponsive();

  const updatePartnerFolders = useUpdatePartnerFolders();

  const manager = useMemo(() => new FolderManager(originalFolders), [originalFolders]);

  useEffect(() => {
    setFolders(manager.toArray());

    return manager.on(() => {
      setFolders(manager.toArray());
    });
  }, [manager]);

  const treeOptions = useMemo(() => {
    const topLevelFolders = folders.filter((folder) => folder.getParentId() === undefined);
    const nextTreeOptions = toTreeOptions(topLevelFolders);

    // adding a fake level so that drag and drop works for top level folders
    const fakeRootFolder: ITreeOption = {
      key: ROOT_TREE_OPTION_KEY,
      title: <T>Root</T>,
      children: nextTreeOptions,
    };

    return [fakeRootFolder];
  }, [folders]);

  const [selectedFolder, changedUsers] = useMemo(() => {
    if (!selectedFolderId || isFakeRootFolder(selectedFolderId)) {
      return [undefined, []];
    }

    const nextSelectedFolder = folders.find((folder) => folder.getId() === selectedFolderId);

    const nextChangedUsers =
      nextSelectedFolder?.getUsers().filter((user) => user.hasUnsavedChanges()) || [];

    return [nextSelectedFolder, nextChangedUsers];
  }, [folders, selectedFolderId]);

  const selectedKeys = useMemo(() => {
    if (selectedFolderId) {
      return [selectedFolderId];
    }

    return undefined;
  }, [selectedFolderId]);

  useEffect(() => {
    if (selectedTab === CURRENT_TAB_ID) {
      return;
    }

    if (!changedUsers.length) {
      setSelectedTab(CURRENT_TAB_ID);
    }
  }, [changedUsers, selectedTab]);

  useEffect(() => {
    const selectedFolderWasDeleted =
      !!selectedFolderId && !isFakeRootFolder(selectedFolderId) && !selectedFolder;

    if (selectedFolderWasDeleted) {
      setExpandedKeys((prevExpandedKeys) => prevExpandedKeys.filter((k) => k !== selectedFolderId));
      setSelectedFolderId(undefined);
    }
  }, [selectedFolder, selectedFolderId]);

  const handleExpand = useCallback((folderId: string) => {
    setExpandedKeys((prevExpandedKeys) => [...prevExpandedKeys, folderId]);
  }, []);

  const handleCollapse = useCallback((folderId: string) => {
    setExpandedKeys((prevExpandedKeys) => prevExpandedKeys.filter((k) => k !== folderId));
  }, []);

  const handleSelect = useCallback((folderId: string) => {
    setSelectedFolderId(folderId);
    setSelectedTab(CURRENT_TAB_ID);
  }, []);

  const handleAdd = useCallback(() => {
    openAddModal();
  }, [openAddModal]);

  const handleAddSubmit = useCallback(
    (values: any) => {
      const { name, description, parent_id } = values;

      const parentId = !isFakeRootFolder(parent_id) ? parent_id : undefined;

      const nextFolder = manager.addFolder(name, description, parentId);

      if (nextFolder) {
        // expand all the parent nodes for the new folder so that its visible in tree
        setExpandedKeys((prevExpandedKeys) => {
          const parentIds = nextFolder.getPath();

          return uniq([...prevExpandedKeys, ...parentIds, ROOT_TREE_OPTION_KEY]);
        });

        setSelectedFolderId(nextFolder.getId());
      }

      closeAddModal();
    },
    [closeAddModal, manager]
  );

  const handleEdit = useCallback(() => {
    if (!selectedFolderId || isFakeRootFolder(selectedFolderId)) {
      return;
    }

    openEditModal();
  }, [openEditModal, selectedFolderId]);

  const handleEditSubmit = useCallback(
    (values: any) => {
      if (!selectedFolderId || isFakeRootFolder(selectedFolderId)) {
        return;
      }

      const { name, description } = values;

      manager.updateFolder(selectedFolderId, name, description);

      closeEditModal();
    },
    [closeEditModal, manager, selectedFolderId]
  );

  const handleDelete = useCallback(() => {
    if (!selectedFolderId || isFakeRootFolder(selectedFolderId)) {
      return;
    }

    manager.deleteFolder(selectedFolderId);
  }, [manager, selectedFolderId]);

  const handleDrop = useCallback(
    (id: string, targetId: string) => {
      const nextTargetId = !isFakeRootFolder(targetId) ? targetId : undefined;

      manager.moveFolder(id, nextTargetId);
    },
    [manager]
  );

  const handleAddUsers = useCallback(
    (agents: IFolderAgent[]) => {
      if (!selectedFolderId || isFakeRootFolder(selectedFolderId)) {
        return;
      }

      const users = agents.map((a) => ({
        id: a.id,
        name: a.name || '',
        email: a.email || '',
      }));

      manager.addFolderUsers(selectedFolderId, users);
    },
    [selectedFolderId, manager]
  );

  const handleDeleteUser = useCallback(
    (record: IFolderAgent) => {
      if (!selectedFolderId || isFakeRootFolder(selectedFolderId)) {
        return;
      }

      // push the previously existing user into the folder so that we can track deleting it.
      manager.registerFolderUser(selectedFolderId, {
        id: record.id,
        name: record.name || '',
        email: record.email || '',
      });

      manager.deleteFolderUser(selectedFolderId, record.id);
    },
    [manager, selectedFolderId]
  );

  const handleCancelUserChange = useCallback(
    (record: ManagedFolderUser) => {
      if (!selectedFolderId || isFakeRootFolder(selectedFolderId)) {
        return;
      }

      if (!record.hasUnsavedChanges()) {
        return;
      }

      if (record.isNew()) {
        manager.deleteFolderUser(selectedFolderId, record.getId());
        return;
      }

      if (record.isSoftDeleted()) {
        manager.restoreFolderUser(selectedFolderId, record.getId());
      }
    },
    [manager, selectedFolderId]
  );

  const handleUndo = useCallback(() => {
    manager.undo();
  }, [manager]);

  const handleDiscard = useCallback(() => {
    manager.discardChanges();
  }, [manager]);

  const handleTabChange = useCallback((key: string) => {
    setSelectedTab(key);
  }, []);

  const handleSave = useCallback(async () => {
    try {
      setSaving(true);

      const data = toServerInput(folders);

      await updatePartnerFolders(partnerId, data);

      notifications.success({ message: 'Folder changes saved successfully' });

      await refreshFolders();

      if (tableRef.current) {
        tableRef.current.refetch();
      }
    } catch (e) {
      Sentry.captureException(e);
      notifications.error({
        message: 'There was an error processing your request, please try again later',
      });
    } finally {
      setSaving(false);
    }
  }, [folders, partnerId, refreshFolders, updatePartnerFolders]);

  const theme = useTheme();

  const canCreate = can.CREATE_FOLDER;
  const canEdit = !!selectedFolder && selectedFolder.canEdit() && can.UPDATE_FOLDER;
  const canDelete =
    !!selectedFolder &&
    (selectedFolder.canPermanentlyDelete() ||
      (can.DELETE_FOLDER && selectedFolder.canSoftDelete()));
  const totalActions = manager.getTotalActions();
  const canSave = totalActions > 0;

  const showActionsBar = canCreate || canSave;
  const showFolderActions = canEdit || canDelete;

  const draggable = !saving && can.UPDATE_FOLDER;

  if (loading) {
    return null;
  }

  const emptyMessage = !folders.length ? (
    <T>There are no folders available</T>
  ) : (
    <T>Please select a folder</T>
  );

  return (
    <>
      {showActionsBar ? (
        <PageActions>
          {canCreate ? (
            <LeftPageActions>
              <Button
                onClick={handleAdd}
                size="xs"
                importance="tertiary"
                icon="plus"
                compact
                disabled={saving}
              >
                Add new folder
              </Button>
            </LeftPageActions>
          ) : null}
          {canSave ? (
            <RightPageActions>
              <UnsavedChangesTag size="xs" color="danger" inverted compact>
                {totalActions} unsaved change{totalActions > 1 ? 's' : ''}
              </UnsavedChangesTag>
              <Button
                onClick={handleUndo}
                size="xs"
                importance="tertiary"
                icon="undo"
                compact
                disabled={saving}
              >
                Undo last change
              </Button>
              <Popover
                content="This action cannot be undone."
                title="Are you sure?"
                confirm
                onConfirm={handleDiscard}
                buttonProps={{
                  size: 'xs',
                  importance: 'tertiary',
                  icon: 'close',
                  compact: true,
                  disabled: saving,
                }}
              >
                <T>Discard all changes</T>
              </Popover>
              <SaveButton
                onClick={handleSave}
                size="xs"
                importance="primary"
                compact
                loading={saving}
              >
                Save changes
              </SaveButton>
            </RightPageActions>
          ) : null}
        </PageActions>
      ) : null}
      {responsive.PHONE.EXACT_OR_SMALLER && totalActions > 0 ? (
        <UnsavedChangesAlert type="warning" size="sm">
          You have {totalActions} unsaved change{totalActions > 1 ? 's' : ''}
        </UnsavedChangesAlert>
      ) : null}
      <PageContent>
        <Row>
          <TreeCol>
            <TreeWrapper>
              <Tree
                expandedKeys={expandedKeys}
                selectedKeys={selectedKeys}
                options={treeOptions}
                draggable={draggable}
                onDrop={handleDrop}
                onSelect={handleSelect}
                onExpand={handleExpand}
                onCollapse={handleCollapse}
                leafNodeIconProps={{ name: 'folder', outline: true }}
                treeIconProps={{ name: 'folder', outline: true }}
                treeIconExpandedProps={{ name: 'folder-open', outline: true }}
                hoverBackground={theme.palette.neutral100}
                selectedBackground={theme.palette.neutral700}
                selectedColor={theme.palette.white}
              />
            </TreeWrapper>
          </TreeCol>
          <ContentCol>
            {selectedFolder ? (
              <>
                <FolderDetails>
                  <FolderNameWrapper>
                    <FolderName>{selectedFolder.getName()}</FolderName>
                    {showFolderActions ? (
                      <FolderActions>
                        {canEdit ? (
                          <Button
                            onClick={handleEdit}
                            size="xxs"
                            importance="tertiary"
                            icon="edit"
                            iconButton
                            iconButtonRound
                            compact
                            disabled={!canEdit || saving}
                            type="neutral"
                          >
                            Edit Folder
                          </Button>
                        ) : null}
                        {canDelete ? (
                          <Popover
                            content="All users associated with this folder will no longer have access to the applications that were within it unless they are directly assigned to them. Once you save all your changes this action cannot be undone."
                            title="Are you sure?"
                            confirm
                            onConfirm={handleDelete}
                            buttonProps={{
                              size: 'xxs',
                              importance: 'tertiary',
                              icon: 'delete-close',
                              compact: true,
                              iconButton: true,
                              iconButtonRound: true,
                              disabled: !canDelete || saving,
                              type: 'neutral',
                            }}
                          >
                            Delete Folder
                          </Popover>
                        ) : null}
                        {canEdit ? (
                          <AddFolderUserPopover
                            folder={selectedFolder}
                            buttonProps={{
                              size: 'xxs',
                              importance: 'tertiary',
                              icon: 'add-user',
                              compact: true,
                              disabled: saving,
                            }}
                            onAddUsers={handleAddUsers}
                            widthCSS="500px"
                            maxWidthCSS="500px"
                            align="BottomLeft"
                          >
                            <T>Add users</T>
                          </AddFolderUserPopover>
                        ) : null}
                      </FolderActions>
                    ) : null}
                  </FolderNameWrapper>
                  {selectedFolder.getDescription() && (
                    <FolderDescription>{selectedFolder.getDescription()}</FolderDescription>
                  )}
                </FolderDetails>

                <Tabs onChange={handleTabChange} selected={selectedTab} size="sm">
                  <Tabs.Tab key={CURRENT_TAB_ID} id={CURRENT_TAB_ID} label="Current Users">
                    <FolderAgentsTable
                      key={selectedFolder.getId()}
                      ref={tableRef}
                      caption="Current Users"
                      partnerId={partnerId}
                      folder={selectedFolder}
                      onDeleteUser={handleDeleteUser}
                      disabled={!canEdit || saving}
                    />
                  </Tabs.Tab>
                  {changedUsers.length ? (
                    <Tabs.Tab
                      key={PENDING_TAB_ID}
                      id={PENDING_TAB_ID}
                      label={
                        <>
                          <span>Unsaved Changes</span>{' '}
                          <NewBadge count={changedUsers.length} ariaLabel="changes" textSize="xs" />
                        </>
                      }
                    >
                      <FolderAgentsPendingTable
                        caption="Unsaved Changes"
                        users={changedUsers}
                        onCancelChange={handleCancelUserChange}
                        disabled={!canEdit || saving}
                      />
                    </Tabs.Tab>
                  ) : null}
                </Tabs>
              </>
            ) : null}
            {!selectedFolder && <Empty>{emptyMessage}</Empty>}
          </ContentCol>
        </Row>
      </PageContent>

      <AddFolderModal
        isOpen={addModalIsOpen}
        folders={folders}
        selectedFolderId={selectedFolderId}
        onSubmit={handleAddSubmit}
        onRequestClose={closeAddModal}
      />
      <EditFolderModal
        isOpen={editModalIsOpen}
        folder={selectedFolder}
        onSubmit={handleEditSubmit}
        onRequestClose={closeEditModal}
      />
    </>
  );
};
