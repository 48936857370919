import { Button, Choice, palette, spacing } from '@mortgagehippo/ds';
import styled from 'styled-components';

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: ${spacing(5)};
`;

export const SpinnerMessage = styled.span`
  color: ${palette('neutral600')};
  margin-top: ${spacing(2)};
`;

export const RefreshButton = styled(Button)`
  margin-top: ${spacing(3)};
`;

export const BorrowerRequestMessage = styled.div`
  margin-bottom: ${spacing(5)};
`;

export const Bold = styled.span`
  font-weight: 700;
`;

export const StyledChoiceBox = styled(Choice.Box)`
  margin-top: ${spacing(5)};
  padding-bottom: 0;
`;
