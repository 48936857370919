import { ForbiddenError } from '@mortgagehippo/auth';
import { spacing, Title, useCustomizations } from '@mortgagehippo/ds';
import { type RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import { useApplicationFileCan } from '$components/permissions';

import { Content, Layout } from '../../../layouts/application';
import { ApplicationPreQualification } from './application-pre-qualification';

const StyledTitle = styled(Title)`
  margin-bottom: ${spacing(6)};
`;

interface IApplicationPreQualificationRouteParams {
  applicationFileId: string;
}

export const ApplicationPreQualificationRoute = (
  props: RouteComponentProps<IApplicationPreQualificationRouteParams>
) => {
  const { match } = props;
  const { params } = match;
  const { applicationFileId } = params;

  const [can, canReady] = useApplicationFileCan(applicationFileId);

  const customizations = useCustomizations();
  const prequalLetterLabel = customizations.text(
    'preQualification:letter.label',
    'Pre-Qualification Letter'
  );

  if (!canReady) {
    return null;
  }

  if (!can.VIEW_PREQUALIFICATION_LETTERS_PAGE) {
    throw new ForbiddenError();
  }

  return (
    <Layout applicationFileId={applicationFileId}>
      <Content>
        <StyledTitle cid="pagePreQualification:title" level={1}>
          {prequalLetterLabel}
        </StyledTitle>
        <ApplicationPreQualification
          applicationFileId={applicationFileId}
          canBypassLimits={can.PREQUALIFICATION_LETTERS_BYPASS_LIMITS}
          canViewPricingTab={can.VIEW_PRICING_TAB}
          canConfigureBorrowerLetters={can.CONFIGURE_BORROWER_PREQUALIFICATION_LETTERS}
          canConfigurePrequalificationLetterCustomMessage={
            can.CONFIGURE_PREQUALIFICATION_LETTER_CUSTOM_MESSAGE
          }
          canConfigurePrequalificationLetterMortgageInsurance={
            can.CONFIGURE_PREQUALIFICATION_LETTER_MORTGAGE_INSURANCE
          }
          canCreateTasks={can.CREATE_TASK}
          canDownloadDocuments={can.DOWNLOAD_DOCUMENTS}
          canManagePrequalificationLetterContingency={
            can.MANAGE_PREQUALIFICATION_LETTER_CONTINGENGY
          }
        />
      </Content>
    </Layout>
  );
};
