import { useQuery } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../apollo';
import { client } from '../apollo/apollo-client';
import { type PartnerQuery, type PartnerQueryVariables } from '../apollo/graphql';
import { getPartnerDomain, usePartnerDomain } from './use-partner-domain';

const QPartner = graphql(`
  query Partner($domain: ID!) {
    partner(id: $domain) {
      id
      name
      adminDomain
      sequentialLenderMilestones
      verticals
    }
  }
`);

export type IPartner = NonNullable<PartnerQuery['partner']>;

export const usePartner = () => {
  const domain = usePartnerDomain();
  const [data, loading] = useQuery(QPartner, {
    domain,
  });

  let result;

  if (data?.partner) {
    result = data.partner;
  }

  return [result, loading] as const;
};

export const getPartner = async () => {
  const domain = getPartnerDomain();

  const result = await client.query<PartnerQuery, PartnerQueryVariables>({
    query: QPartner,
    variables: { domain },
  });

  const { data } = result;

  return data.partner || null;
};
