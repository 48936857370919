import { T } from '@mortgagehippo/ds';

import { type LosType } from '../../../../apollo/graphql';
import { SERVICES } from '../constants';
import { type ServiceType } from '../types';

interface IServiceNameProps {
  type: LosType | ServiceType;
  children?: React.ReactNode;
}

export const ServiceName = (props: IServiceNameProps) => {
  const { type, children } = props;
  const service = SERVICES[type];

  return (
    <>
      {children}
      <T cid={service.nameCid}>{service.name}</T>
    </>
  );
};
