import {
  Card,
  fontSize,
  fontWeight,
  MediaBreakpoint,
  palette,
  spacing,
  T,
} from '@mortgagehippo/ds';
import { TaskDueDate, TaskStateLabel, TaskTitle } from '@mortgagehippo/tasks';
import styled from 'styled-components';

import { type IApplicationApplicant } from '../use-application-file-data';
import { type IApplicationFileTask } from '../use-application-file-tasks';
import { ApplicationTaskActions } from './application-task-actions';

const Row = styled.div`
  display: flex;
  flex: 1 1 auto;

  &:not(:last-child) {
    border-bottom: 1px solid ${palette('neutral100')};
    margin-bottom: ${spacing(4)};
    padding-bottom: ${spacing(3)};
  }

  ${MediaBreakpoint.PHONE} {
    display: block;
    border: none;
    margin: 0;
    padding: 0;
  }
`;

const StatsCard = styled(Card)`
  padding: ${spacing(5)};
  margin-bottom: ${spacing(7)};

  position: sticky;
  /** top = - scroll container padding - border radius so StatsCard touches top scroll edge on scroll */
  top: calc(-${spacing(5)} - 8px);
  z-index: 50;

  ${MediaBreakpoint.PHONE} {
    position: relative;
    top: 0;
  }
`;

const TitleWrapper = styled('div')`
  /* the extra spacing must be the same as the negative margin top of StatsCard above */
  margin-bottom: ${spacing(3)};
`;

const Col = styled.div`
  margin: 0 ${spacing(4)};

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  ${MediaBreakpoint.PHONE} {
    margin: ${spacing(2)} 0;
    &:not(.description) {
      display: flex;
    }
  }
`;

const ColLabel = styled.span`
  color: ${palette('neutral600')};
  line-height: 1.2em;
  display: block;
  text-transform: uppercase;
  font-size: ${fontSize('xxs')};
  margin-bottom: ${spacing(1)};

  ${MediaBreakpoint.PHONE} {
    flex: 1 1 auto;
    margin: 0;
  }
`;

const ColValue = styled.span`
  font-weight: ${fontWeight('semibold')};
  line-height: 1.2em;
  font-size: ${fontSize('sm')};
  display: block;

  ${MediaBreakpoint.PHONE} {
    flex: 0 1 auto;
    align-self: flex-end;
    padding-left: ${spacing(2)};
  }
`;

const ColActions = styled.div`
  margin-bottom: -${spacing(3)};
  align-self: flex-end;
  flex: 1 1 auto;
  white-space: nowrap;

  ${MediaBreakpoint.PHONE} {
    margin-top: ${spacing(4)};
    white-space: normal;

    button {
      width: 100% !important;
    }
  }
`;

export interface IApplicationTaskHeaderProps {
  applicationFileId: string;
  task: IApplicationFileTask;
  taskApplicant: IApplicationApplicant;
  showApplicantName?: boolean;
}

export const ApplicationTaskHeader = (props: IApplicationTaskHeaderProps) => {
  const { applicationFileId, task, taskApplicant, showApplicantName } = props;
  const { state, isAlerted, isFrozen } = task;

  const applicantName = taskApplicant.name || (
    <>
      <T cid="application_tasks:applicant.genericLabel">Borrower</T>
      {` #${(taskApplicant.position || 0) + 1}`}
    </>
  );

  /**
   * The StatsCard below must be top level in this component which is why it is wrapped in <>...</>
   * This ensures that position: sticky works by having content below it not pushing it away.
   */
  return (
    <>
      <TitleWrapper>
        <TaskTitle title={task.meta.title} />
      </TitleWrapper>
      <StatsCard raised={2}>
        <Row>
          <Col>
            <ColLabel>
              <T cid="application_tasks:task_state">Task State</T>
            </ColLabel>
            <ColValue>
              <TaskStateLabel state={state} alerted={isAlerted} frozen={isFrozen} />
            </ColValue>
          </Col>
          {!!task.dueDate && (
            <Col>
              <ColLabel>
                <T cid="application_tasks:task_due_date">Due</T>
              </ColLabel>
              <ColValue>
                <TaskDueDate dueDate={task.dueDate} hideLabel size="xs" />
              </ColValue>
            </Col>
          )}
          {!task.common && showApplicantName ? (
            <Col>
              <ColLabel>
                <T cid="application_tasks:applicant">Applicant</T>
              </ColLabel>
              <ColValue>{applicantName}</ColValue>
            </Col>
          ) : null}
          <Col
            style={{
              flex: '1 1 auto',
              textAlign: 'right',
              display: 'flex',
            }}
          >
            <ColActions>
              <ApplicationTaskActions task={task} applicationFileId={applicationFileId} />
            </ColActions>
          </Col>
        </Row>
      </StatsCard>
    </>
  );
};
