import { ButtonRouterLink, Card, Divider, MediaBreakpoint, spacing } from '@mortgagehippo/ds';
import { useCallback, useContext, useEffect } from 'react';
import styled from 'styled-components';

import {
  ActionStatus,
  ActionType,
  type IAddApplicantAction,
  type IBulkCreateCustomTaskAction,
  type IDeleteApplicantAction,
  type IDeleteTaskAction,
  useActionEffect,
} from '../../../../../components/actions';
import { useApplicationFileCan } from '../../../../../components/permissions';
import { ApplicationOverviewContext } from '../../application-overview-context';
import { type IApplicationOverviewSectionProps } from '../../types';
import {
  BlockContent,
  BlockContentContainer,
  BlockLabel,
  SectionBlock,
  SectionFooter,
} from '../common';
import { ApplicationOverviewTasksList } from './application-overview-tasks-list';
import { useTasksOverviewSection } from './use-tasks-overview-section';

const Container = styled(BlockContentContainer)`
  display: block;

  ${MediaBreakpoint.TABLET} {
    padding-top: ${spacing(2)};
  }
`;

const StyledBlockContent = styled(BlockContent)`
  display: block;
`;

type REFRESHABLE_ACTION_TYPES =
  | IBulkCreateCustomTaskAction
  | IDeleteTaskAction
  | IAddApplicantAction
  | IDeleteApplicantAction;

const REFRESHABLE_ACTIONS = [
  ActionType.BULK_CREATE_CUSTOM_TASK,
  ActionType.DELETE_TASK,
  ActionType.ADD_APPLICANT,
  ActionType.DELETE_APPLICANT,
];

export const ApplicationOverviewTasksSection = (props: IApplicationOverviewSectionProps) => {
  const { id, applicationFileId, className } = props;

  const { sectionReady, ready } = useContext(ApplicationOverviewContext)!;

  const [can, canReady] = useApplicationFileCan(applicationFileId);
  const [{ tasks, applicants, primaryApplicantId }, sectionLoading, refreshSection] =
    useTasksOverviewSection(applicationFileId);

  const taskPageUrl = `#/applications/${applicationFileId}/tasks`;

  const handleTo = useCallback(
    (taskId: string, applicantId: string) =>
      `#/applications/${applicationFileId}/tasks/${applicantId}/${taskId}`,
    [applicationFileId]
  );

  useActionEffect<REFRESHABLE_ACTION_TYPES>(
    async (action) => {
      const { applicationFileId: actionApplicationFileId, type } = action;

      if (actionApplicationFileId !== applicationFileId) {
        // this action belongs to another application file ID
        return;
      }

      if (!REFRESHABLE_ACTIONS.includes(type)) {
        return;
      }

      await refreshSection();
    },
    undefined,
    ActionStatus.DONE
  );

  useEffect(() => {
    if (sectionLoading || !canReady) {
      return;
    }

    sectionReady(id);
  }, [sectionLoading, id, sectionReady, canReady]);

  if (!ready) {
    return null;
  }

  return (
    <Card compact className={className}>
      <SectionBlock>
        <BlockLabel maxWidth>Open Borrower Tasks</BlockLabel>
        <Container>
          <StyledBlockContent>
            <ApplicationOverviewTasksList
              tasks={tasks}
              applicants={applicants}
              primaryApplicantId={primaryApplicantId}
              to={can.VIEW_TASKS_TAB ? handleTo : undefined}
            />
          </StyledBlockContent>
        </Container>
      </SectionBlock>
      {can.VIEW_TASKS_TAB ? (
        <>
          <Divider compact />
          <SectionFooter>
            <ButtonRouterLink
              to={taskPageUrl}
              icon="right-arrow"
              iconLocation="right"
              importance="tertiary"
              size="xs"
              compact
            >
              See all borrower tasks
            </ButtonRouterLink>
          </SectionFooter>
        </>
      ) : null}
    </Card>
  );
};
