import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../../apollo';
import { type UpdateSmartviewInput } from '../../../apollo/graphql';

export const MUpdateSmartview = graphql(`
  mutation UpdateSmartview($smartviewId: ID!, $data: UpdateSmartviewInput!) {
    updateSmartview(smartviewId: $smartviewId, data: $data) {
      ...SmartviewFragment
    }
  }
`);

export const useUpdateSmartview = () => {
  const updateSmartview = useMutation(MUpdateSmartview, undefined);

  return async (smartviewId: string, data: UpdateSmartviewInput): Promise<any> => {
    const response = await updateSmartview({
      smartviewId,
      data,
    });
    return response.data!.updateSmartview;
  };
};
