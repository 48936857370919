import { type FontSize, spacing, T, Tag } from '@mortgagehippo/ds';
import { type ReactNode } from 'react';
import styled from 'styled-components';

const Content = styled.span`
  margin-right: ${spacing(1)};
`;

const StyledTag = styled(Tag)`
  text-transform: none;
`;

interface IPrimaryLabelProps {
  size?: FontSize;
  children?: ReactNode;
}

export const PrimaryLabel = (props: IPrimaryLabelProps) => {
  const { children, size } = props;

  return (
    <>
      <Content>{children}</Content>
      <StyledTag size={size}>
        <T>Primary</T>
      </StyledTag>
    </>
  );
};
