import { useAuth } from '@mortgagehippo/auth';
import { useModal } from '@mortgagehippo/ds';
import { useSagaSubmitHandler } from '@mortgagehippo/tasks';
import { useCallback } from 'react';

import { useCreateApplication } from '../../../pages/application/use-create-application';
import { type IActionProps, type ICreateApplicationAction } from '../types';
import { CreateApplicationModalContainer } from './create-application-modal-container';

export const CreateApplicationAction = (props: IActionProps<ICreateApplicationAction>) => {
  const { onDone, onSubmit, onCancel } = props;
  const [user] = useAuth();

  const createApplication = useCreateApplication();
  const [isOpen, , closeModal] = useModal(true);

  const [handleSubmit, running] = useSagaSubmitHandler({
    infoMessageCid: 'create-application-action:info',
    successMessageCid: 'create-application-action:success',
    errorMessageCid: 'create-application-action:error',
    infoMessage: 'Creating the application',
    successMessage: 'The application was successfully created.',
    errorMessage: 'There was an unexpected error creating the application.',
    async onSubmit(values: any, setAsyncId) {
      const {
        site_id: siteId,
        email,
        first_name: firstName,
        last_name: lastName,
        cell_phone_number: cellPhoneNumber,
        home_phone_number: homePhoneNumber,
        send_invitation: sendInvitation,
      } = values;

      const data = {
        email,
        firstName,
        lastName,
        cellPhoneNumber,
        homePhoneNumber,
        agentId: user!.id,
        sendInvitation,
      };

      const nextAsyncId = await createApplication(siteId, data);

      closeModal(() => {
        setAsyncId(nextAsyncId);
        onSubmit();
      });
    },
    async onDone() {
      closeModal(onDone);
    },
  });

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  if (running || !user) {
    return null;
  }

  return (
    <CreateApplicationModalContainer
      isOpen={isOpen}
      onSubmit={handleSubmit}
      onRequestClose={handleCloseModal}
    />
  );
};
