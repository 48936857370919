import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../../apollo';
import { type WorkNumberRequestInstantReportActionMutation } from '../../../apollo/graphql';

const MWorkNumberRequestInstantReportAction = graphql(`
  mutation WorkNumberRequestInstantReportAction(
    $applicationFileId: ID!
    $applicantId: ID!
    $data: RequestNumberInstantReportInput!
  ) {
    requestWorkNumberInstantReport(
      applicationFileId: $applicationFileId
      applicantId: $applicantId
      data: $data
    ) {
      id
      statusCode
      errorCode
      errorDescription
      identifier
      pdf
      createdAt
      updatedAt
      employers {
        id
        statusType
        fullName
        positionDescription
        startDate
        endDate
        createdAt
        updatedAt
        incomes {
          id
          typeName
          monthlyAmount
          createdAt
          updatedAt
        }
      }
    }
  }
`);

interface IWorkNumberRequestData {
  salaryKey?: string;
  alternateId?: string;
}

export type IWorkNumberReport =
  WorkNumberRequestInstantReportActionMutation['requestWorkNumberInstantReport'];

export const useRequestWorkNumberInstantReport = () => {
  const fn = useMutation(MWorkNumberRequestInstantReportAction);
  return async (applicationFileId: string, applicantId: string, data: IWorkNumberRequestData) => {
    const result = await fn({
      applicationFileId,
      applicantId,
      data,
    });

    return result.data!.requestWorkNumberInstantReport;
  };
};
