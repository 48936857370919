import { Overlay as PlainOverlay } from './overlay';
import { OverlayRoute } from './route';

export const Overlay = Object.assign(PlainOverlay, {
  Route: OverlayRoute,
});

export * from './close';
export * from './context';
export * from './use-close-overlay';
