import { PermissionConfig, type PermissionType } from './permissions';
import { type IUserCan } from './permissions-context';

export const createEmptyCan = (): IUserCan => {
  const result: Partial<IUserCan> = {};
  const permissions = Object.keys(PermissionConfig) as PermissionType[];

  permissions.forEach((permission) => {
    result[permission] = false;
  });

  return result as IUserCan;
};
