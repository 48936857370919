import { type ISearchFilter } from '@mortgagehippo/ds';

import { type LosType, type SmartviewModelType, type TaskType } from '../../apollo/graphql';
import { type IApplicationApplicant } from '../../pages/application/use-application-file-data';

export enum ActionType {
  CREATE_APPLICATION_FROM_APPLICANT,
  DELETE_APPLICATION,
  EDIT_CUSTOM_TASK,
  DELETE_TASK,
  ADD_APPLICANT,
  DELETE_APPLICANT,
  CREATE_AGENT,
  UPDATE_AGENT,
  CREATE_APPLICATION,
  PUSH_TO_LOS,
  CREDIT_PULL,
  JOINT_CREDIT_PULL,
  GENERATE_DU_ASSESSMENT,
  SEND_PREQUALIFICATION_LETTER,
  REFRESH_MORTGAGEFLEX_STATUS,
  WORK_NUMBER_INSTANT_REPORT,
  DELETE_AGENT,
  UPLOAD_LENDER_DOCUMENTS,
  UPLOAD_DOCUMENTS,
  SEND_APPLICANT_INVITATION,
  UPDATE_APPLICANT_BORROWER_INFORMATION,
  GENERATE_LPA_ASSESSMENT,
  BULK_CREATE_CUSTOM_TASK,
  UPDATE_LENDING_QB_PUSH,
  REFRESH_FINICITY_REPORT,
  MANAGE_APPLICATION_TEAM_ACTION,
  PUSH_TO_INTERNAL_SYSTEM,
  ARCHIVE_APPLICATION,
  RESTORE_APPLICATION,
  DUPLICATE_APPLICATION,
  CREATE_SMARTVIEW,
  UPDATE_SMARTVIEW,
  PUSH_DOCUMENT_TO_LOS,
  PUSH_DOCUMENTS_TO_LOS,
  REFRESH_UNDERWRITING_CONDITIONS,
  SHARE_ASSIGNMENT_LINK,
  GET_SCREENSHARE_WATCH_URL,
  IMPORT_MISMO,
  EDIT_FOLLOW_UP_TASK,
}

export interface ICreateApplicationFromApplicantAction {
  type: ActionType.CREATE_APPLICATION_FROM_APPLICANT;
  applicantId: string;
}

export interface IDeleteApplicationAction {
  type: ActionType.DELETE_APPLICATION;
  applicationFileId: string;
}

export interface IEditCustomTaskAction {
  type: ActionType.EDIT_CUSTOM_TASK;
  applicationFileId: string;
  taskId: string;
  taskType:
    | TaskType.SendDocumentTask
    | TaskType.InformationTask
    | TaskType.DocumentSubmissionTask
    | TaskType.PaymentTask;
}

export interface IDeleteTaskAction {
  type: ActionType.DELETE_TASK;
  applicationFileId: string;
  taskId: string;
}

export interface IAddApplicantAction {
  type: ActionType.ADD_APPLICANT;
  applicationFileId: string;
}

export interface IDeleteApplicantAction {
  type: ActionType.DELETE_APPLICANT;
  applicationFileId: string;
  applicantId: string;
}

export interface IGetScreenshareWatchUrlAction {
  type: ActionType.GET_SCREENSHARE_WATCH_URL;
  applicationFileId: string;
  applicants: IApplicationApplicant[];
}

export interface ICreateAgentAction {
  type: ActionType.CREATE_AGENT;
}

export interface IUpdateAgentAction {
  type: ActionType.UPDATE_AGENT;
  agentId: string;
}

export interface ICreateApplicationAction {
  type: ActionType.CREATE_APPLICATION;
}

export interface IImportMismoAction {
  type: ActionType.IMPORT_MISMO;
}

export interface IPushToLosAction {
  type: ActionType.PUSH_TO_LOS;
  applicationFileId: string;
  losType: LosType;
  asyncLossExport?: boolean;
}

export interface IPushDocumentToLosAction {
  type: ActionType.PUSH_DOCUMENT_TO_LOS;
  applicationFileId: string;
  documentId: string;
  losType: LosType;
}

export interface IPushDocumentsToLosAction {
  type: ActionType.PUSH_DOCUMENTS_TO_LOS;
  applicationFileId: string;
  documentIds?: string[];
  losType: LosType;
}

export interface ICreditPullAction {
  type: ActionType.CREDIT_PULL;
  applicationFileId: string;
  applicantId: string;
}

export interface IJointCreditPullAction {
  type: ActionType.JOINT_CREDIT_PULL;
  applicationFileId: string;
}

export interface IGenerateDUAssessmentAction {
  type: ActionType.GENERATE_DU_ASSESSMENT;
  applicationFileId: string;
  confirm?: boolean;
}

export interface ISendPreQualificationLetterAction {
  type: ActionType.SEND_PREQUALIFICATION_LETTER;
  applicationFileId: string;
  letterReferenceId: string;
}

export interface IRefreshMortgageFlexLoanStatusAction {
  type: ActionType.REFRESH_MORTGAGEFLEX_STATUS;
  applicationFileId: string;
  silent?: boolean;
}

export interface IWorkNumberInstantReportAction {
  type: ActionType.WORK_NUMBER_INSTANT_REPORT;
  applicationFileId: string;
  applicantId?: string;
}

export interface IDeleteAgentAction {
  type: ActionType.DELETE_AGENT;
  agentId: string;
}

export interface IUploadLenderDocumentsAction {
  type: ActionType.UPLOAD_LENDER_DOCUMENTS;
  applicationFileId: string;
}

export interface IUploadDocumentsAction {
  type: ActionType.UPLOAD_DOCUMENTS;
  applicationFileId: string;
}

export interface ISendApplicantInvitationAction {
  type: ActionType.SEND_APPLICANT_INVITATION;
  applicationFileId: string;
  applicantId: string;
}

export interface IUpdateApplicantBorrowerInformationAction {
  type: ActionType.UPDATE_APPLICANT_BORROWER_INFORMATION;
  applicationFileId: string;
  applicantId: string;
}

export interface IPushToInternalSystemAction {
  type: ActionType.PUSH_TO_INTERNAL_SYSTEM;
  applicationFileId: string;
}

export interface IGenerateLPAAssessmentAction {
  type: ActionType.GENERATE_LPA_ASSESSMENT;
  applicationFileId: string;
  confirm?: boolean;
}

export interface IBulkCreateCustomTaskAction {
  type: ActionType.BULK_CREATE_CUSTOM_TASK;
  applicationFileId: string;
  underwritingConditionIds?: string[];
}

export interface IUpdateLendingQbPushAction {
  type: ActionType.UPDATE_LENDING_QB_PUSH;
  applicationFileId: string;
}

export interface IRefreshFinicityReportAction {
  type: ActionType.REFRESH_FINICITY_REPORT;
  applicationFileId: string;
  applicantId: string;
  defaultReportDays?: number;
  canChangeDays?: boolean;
}

export interface IManageApplicationTeamAction {
  type: ActionType.MANAGE_APPLICATION_TEAM_ACTION;
  applicationFileId: string;
}

export interface IArchiveApplicationAction {
  type: ActionType.ARCHIVE_APPLICATION;
  applicationFileId: string | string[];
}

export interface IRestoreApplicationAction {
  type: ActionType.RESTORE_APPLICATION;
  applicationFileId: string | string[];
}

export interface IDuplicateApplicationAction {
  type: ActionType.DUPLICATE_APPLICATION;
  applicationFileId: string;
}

export interface IDuplicateApplicationActionResult {
  applicationFileId: string;
}

export interface ICreateSmartviewAction {
  type: ActionType.CREATE_SMARTVIEW;
  model: SmartviewModelType;
  initialValues?: {
    filters?: ISearchFilter[];
    defaultSortField?: string;
    defaultSortDirection?: string;
  };
}

export interface IUpdateSmartviewAction {
  type: ActionType.UPDATE_SMARTVIEW;
  model: SmartviewModelType;
  smartviewId: string;
}

export interface IUpdateSmartviewActionResult {
  updated?: boolean;
  deleted?: boolean;
}

export interface IRefreshUnderwritingConditionsAction {
  type: ActionType.REFRESH_UNDERWRITING_CONDITIONS;
  applicationFileId: string;
}

export interface IShareAssignmentLinkAction {
  type: ActionType.SHARE_ASSIGNMENT_LINK;
  agentId: string;
}

export interface IEditFollowUpTaskAction {
  type: ActionType.EDIT_FOLLOW_UP_TASK;
  taskId: string;
}

export type Action =
  | ICreateApplicationFromApplicantAction
  | IDeleteApplicationAction
  | IEditCustomTaskAction
  | IDeleteTaskAction
  | IAddApplicantAction
  | IDeleteApplicantAction
  | IGetScreenshareWatchUrlAction
  | ICreateAgentAction
  | IUpdateAgentAction
  | ICreateApplicationAction
  | IImportMismoAction
  | IPushToLosAction
  | ICreditPullAction
  | IJointCreditPullAction
  | IGenerateDUAssessmentAction
  | ISendPreQualificationLetterAction
  | IWorkNumberInstantReportAction
  | IDeleteAgentAction
  | IUploadLenderDocumentsAction
  | IUploadDocumentsAction
  | ISendApplicantInvitationAction
  | IUpdateApplicantBorrowerInformationAction
  | IPushToInternalSystemAction
  | IGenerateLPAAssessmentAction
  | IBulkCreateCustomTaskAction
  | IUpdateLendingQbPushAction
  | IRefreshFinicityReportAction
  | IRefreshMortgageFlexLoanStatusAction
  | IManageApplicationTeamAction
  | IArchiveApplicationAction
  | IRestoreApplicationAction
  | IDuplicateApplicationAction
  | ICreateSmartviewAction
  | IUpdateSmartviewAction
  | IPushDocumentToLosAction
  | IPushDocumentsToLosAction
  | IRefreshUnderwritingConditionsAction
  | IShareAssignmentLinkAction
  | IEditFollowUpTaskAction;

export interface IActionProps<A extends Action, R = any> {
  action: A;
  onSubmit: () => void;
  onDone: (result?: R) => void;
  onCancel: () => void;
}

export enum ActionStatus {
  OPEN = 'OPEN',
  SUBMITTED = 'SUBMITTED',
  DONE = 'DONE',
  CANCEL = 'CANCEL',
}

export type ActionListener<T extends Action = Action, R = any> = (
  action: T,
  status: ActionStatus,
  result?: R
) => void;
