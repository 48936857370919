import { ApolloHooksProvider, withFallback } from '@mortgagehippo/apollo-hooks';
import {
  AuthCallbackRoute,
  AuthenticationError,
  AuthLogoutRoute,
  AuthProvider,
  AuthUniversalLoginRoute,
  ForbiddenError,
  ProtectedRoute,
} from '@mortgagehippo/auth';
import { CustomizationProvider, useCustomizations } from '@mortgagehippo/ds';
import {
  DOCUSIGN_CONSENT_CALLBACK_PATH,
  DOCUSIGN_CONSENT_PATH,
  DOCUSIGN_EDITOR_CALLBACK_PATH,
  DOCUSIGN_EDITOR_PATH,
  DOCUSIGN_SIGN_PATH,
  DOCUSIGN_SIGNED_CALLBACK_PATH,
  DocusignConsentCallbackRoute,
  DocusignConsentRoute,
  DocusignEditorCallbackRoute,
  DocusignEditorRoute,
  DocusignSignedCallbackRoute,
  DocusignSignRoute,
  UpscopeInit,
} from '@mortgagehippo/tasks';
import { ErrorBoundary, NotFoundError } from '@mortgagehippo/util';
import { Redirect, Route, Switch } from 'react-router';
import { Router } from 'react-router-dom';

import { Actionable } from '$components/actionable';
import { ActionsProvider } from '$components/actions/actions-provider';
import { LogoutCountdown } from '$components/logout-countdown';
import { Overlay } from '$components/overlay';
import { PermissionsProvider } from '$components/permissions';

import { client } from './apollo/apollo-client';
import { config } from './config';
import { LayoutContextProvider } from './layouts/layout-context';
import { AccountPage } from './pages/account';
import { AdminLoginPage } from './pages/admin-login';
import { ApplicationPage } from './pages/application';
import { DocutechRedirectPage } from './pages/application/application-services/services/docutech/docutech-redirect-page';
import { SmartFeesIFramePage } from './pages/application/application-services/services/smart-fees/smart-fees-iframe-page';
import { ArchivePage } from './pages/archive';
import { AuthErrorPage } from './pages/auth-error';
import { DashboardPage } from './pages/dashboard';
import { DownloadsPage } from './pages/downloads';
import { ErrorPage } from './pages/error';
import { ForcedLogoutPage } from './pages/forced-logout';
import { LinkActivityEventPage } from './pages/link-activity-event';
import { LoadingPage } from './pages/loading';
import { NotFoundPage } from './pages/not-found';
import { ReportsPage } from './pages/reports';
import { RootPage } from './pages/root';
import { ScreensharePage } from './pages/screenshare';
import { SessionTimeoutPage } from './pages/session-timeout';
import { SmartviewsPage } from './pages/smartviews';
import { UsersPage } from './pages/users';
import { auth } from './services/auth';
import { customizationsService } from './services/customizations';
import { history } from './services/history';

const App = () => {
  const customizations = useCustomizations();
  customizations.namespace('default');
  customizations.namespace('app');
  customizations.namespace('settings');
  customizations.namespace('pageDashboard');
  customizations.namespace('pageApplication');
  customizations.namespace('header');

  if (customizations.loading()) {
    return <LoadingPage />;
  }

  return (
    <ApolloHooksProvider client={client}>
      <CustomizationProvider service={customizationsService} fallback={<LoadingPage />}>
        <ErrorBoundary
          errors={[ForbiddenError, AuthenticationError]}
          errorComponent={AuthErrorPage}
        >
          <AuthProvider auth={auth} loadingComponent={LoadingPage}>
            <PermissionsProvider>
              <ErrorBoundary errors={[NotFoundError]} errorComponent={NotFoundPage}>
                {config.UPSCOPE_JS_PACKAGE_KEY ? (
                  <UpscopeInit packageKey={config.UPSCOPE_JS_PACKAGE_KEY} />
                ) : null}
                <Actionable>
                  <ActionsProvider>
                    <LayoutContextProvider>
                      <Router history={history}>
                        <>
                          <Switch>
                            <Route path="/" component={RootPage} exact />
                            <AuthUniversalLoginRoute path="/login" exact allowSignup={false} />
                            <Route path="/admin" component={AdminLoginPage} exact />
                            <ProtectedRoute path="/dashboard" component={DashboardPage} />
                            <ProtectedRoute path="/exports/:type?" component={DownloadsPage} />
                            <ProtectedRoute path="/account/:id?" component={AccountPage} />
                            <ProtectedRoute path="/users/:id?" component={UsersPage} />
                            <ProtectedRoute path="/archive" component={ArchivePage} />
                            <ProtectedRoute
                              path="/screenshare/:applicationFileId"
                              component={ScreensharePage}
                            />
                            <ProtectedRoute path="/reports" component={ReportsPage} />
                            <ProtectedRoute path="/smartviews/:id?" component={SmartviewsPage} />
                            <Route path="/session-timeout" component={SessionTimeoutPage} />
                            <Route path="/forced-logout" component={ForcedLogoutPage} />
                            <AuthCallbackRoute path="/authorize" />
                            <AuthLogoutRoute path="/logout" />
                            <ProtectedRoute
                              path="/smart-fees/:id"
                              component={SmartFeesIFramePage}
                            />
                            <ProtectedRoute path="/docutech/:id" component={DocutechRedirectPage} />
                            <ProtectedRoute
                              path={DOCUSIGN_SIGN_PATH}
                              component={DocusignSignRoute}
                            />
                            <ProtectedRoute
                              path={DOCUSIGN_SIGNED_CALLBACK_PATH}
                              component={DocusignSignedCallbackRoute}
                            />
                            <ProtectedRoute
                              path={DOCUSIGN_EDITOR_PATH}
                              component={DocusignEditorRoute}
                            />
                            <ProtectedRoute
                              path={DOCUSIGN_EDITOR_CALLBACK_PATH}
                              component={DocusignEditorCallbackRoute}
                            />
                            <ProtectedRoute
                              path={DOCUSIGN_CONSENT_PATH}
                              component={DocusignConsentRoute}
                            />
                            <ProtectedRoute
                              path={DOCUSIGN_CONSENT_CALLBACK_PATH}
                              component={DocusignConsentCallbackRoute}
                            />
                            <ProtectedRoute path="/link/:path" component={LinkActivityEventPage} />
                            <Redirect
                              to="/applications/:applicationFileId/prequalification"
                              from="/applications/:applicationFileId/preapproval"
                            />
                            <Route component={NotFoundPage} />
                          </Switch>
                          <Overlay
                            path="/applications/:applicationFileId/:id?"
                            component={ApplicationPage}
                          />
                        </>
                      </Router>
                      <LogoutCountdown />
                    </LayoutContextProvider>
                  </ActionsProvider>
                </Actionable>
              </ErrorBoundary>
            </PermissionsProvider>
          </AuthProvider>
        </ErrorBoundary>
      </CustomizationProvider>
    </ApolloHooksProvider>
  );
};

export const Root = withFallback(LoadingPage, ErrorPage)(App);
