import {
  Button,
  fontSize,
  Format,
  Icon,
  type ITableCols,
  lineHeight,
  Popover,
  spacing,
  T,
  Table,
  Tag,
  Title,
  useModal,
} from '@mortgagehippo/ds';
import { type ILiability, type IRelationship, Verification } from '@mortgagehippo/tasks';
import { isEmpty, isNil } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import * as uuid from 'uuid';

import { ASSET_OBJECT_TYPE, LIABILITY_OBJECT_TYPE } from './constants';
import { CreditCheckLiabilitiesColCreditor } from './credit-check-liabilities-col-creditor';
import { CreditCheckLiabilitiesColType } from './credit-check-liabilities-col-type';
import { CreditCheckLiabilitiesModal } from './credit-check-liabilities-modal';
import { type IModel } from './util';

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing(1)};
`;

const StyledTitle = styled(Title)`
  margin: 0 ${spacing(1)} 0 0;
`;

const StyledVerification = styled(Verification)`
  text-align: right;
`;

const TagWrapper = styled.div`
  margin-bottom: ${spacing(1)};
`;

const CheckIcon = styled(Icon)`
  margin-right: ${spacing(1)};
`;

const BorrowerComments = styled.span`
  font-size: ${fontSize('xs')};
  line-height: ${lineHeight('xs')};
`;

const Actions = styled.span`
  white-space: nowrap;
`;

const deleteButtonProps = {
  icon: 'delete',
  iconButton: true,
  iconButtonRound: true,
  size: 'xxs',
  compact: true,
  importance: 'tertiary',
  type: 'neutral',
} as const;

const rowKey = (record: ILiability) => `${record.id || record.__id}`;

interface ICreditCheckLiabilitiesProps {
  model: IModel;
  onChangeAnswers?: (answers: any) => Promise<any>;
}

export const CreditCheckLiabilities = (props: ICreditCheckLiabilitiesProps) => {
  const { model, onChangeAnswers } = props;
  const [isOpen, openModal, closeModal] = useModal(false);
  const [selectedLiability, setSelectedLiability] = useState<ILiability>();

  const readOnly = !onChangeAnswers;

  const handleAdd = useCallback(() => {
    openModal();
  }, [openModal]);

  const handleEdit = useCallback(
    (liability: ILiability) => () => {
      setSelectedLiability(liability);
      openModal();
    },
    [openModal]
  );

  const handleModalSubmit = useCallback(
    async (values: any) => {
      const {
        type_name,
        full_name,
        account_identifier,
        unpaid_balance_amount,
        monthly_payment_amount,
        paid_off_before_closing,
        excluded,
        borrower_comments,
        related_reo,
      } = values;

      const { id, __id } = selectedLiability || { __id: uuid.v4() };
      const liabilityId = id || __id;

      const nextLiability: Record<string, any> = {
        id,
        __id,
        type_name,
        full_name,
        account_identifier: account_identifier || null,
        unpaid_balance_amount,
        monthly_payment_amount,
        paid_off_before_closing,
        excluded,
        borrower_comments: borrower_comments || null,
      };

      const prevRealEstateOwned = model.getRealEstateOwnedIdForLiability(selectedLiability);
      const relationships: IRelationship[] = [];

      if (prevRealEstateOwned && prevRealEstateOwned !== related_reo) {
        relationships.push({
          parent_object_type: ASSET_OBJECT_TYPE,
          parent_object_id: prevRealEstateOwned,
          child_object_type: LIABILITY_OBJECT_TYPE,
          child_object_id: liabilityId!,
          __deleted: true,
        });
      }

      if (related_reo) {
        relationships.push({
          parent_object_type: ASSET_OBJECT_TYPE,
          parent_object_id: related_reo!,
          child_object_type: LIABILITY_OBJECT_TYPE,
          child_object_id: liabilityId!,
        });
      }

      if (!onChangeAnswers) {
        return;
      }

      await onChangeAnswers({
        liabilities: [nextLiability],
        relationships,
      });

      closeModal(() => {
        setSelectedLiability(undefined);
      });
    },

    [closeModal, model, onChangeAnswers, selectedLiability]
  );

  const handleCloseModal = useCallback(() => {
    closeModal(() => {
      setSelectedLiability(undefined);
    });
  }, [closeModal]);

  const handleDelete = useCallback(
    (liability: ILiability) => async () => {
      const { id, __id } = liability;

      if (!onChangeAnswers) {
        return;
      }

      await onChangeAnswers({
        liabilities: [
          {
            id,
            __id,
            __deleted: true,
          },
        ],
      });
    },
    [onChangeAnswers]
  );

  const cols: ITableCols<ILiability> = useMemo(
    () => [
      {
        title: (
          <T cid="pageApplication:tabServices.creditCheck.liabilities.column.creditor.title">
            Creditor
          </T>
        ),
        key: 'creditor',
        verticalAlign: 'top',
        render: (liability) => <CreditCheckLiabilitiesColCreditor liability={liability} />,
        totalSource: () => 0,
        renderTotal: () => <T>TOTAL</T>,
      },
      {
        title: (
          <T cid="pageApplication:tabServices.creditCheck.liabilities.column.type.title">Type</T>
        ),
        key: 'type',
        verticalAlign: 'top',
        render: (liability) => (
          <CreditCheckLiabilitiesColType liability={liability} model={model} />
        ),
      },
      {
        title: (
          <T cid="pageApplication:tabServices.creditCheck.liabilities.column.balance.title">
            Remaining Balance
          </T>
        ),
        key: 'balance',
        align: 'right',
        verticalAlign: 'top',
        render: ({ unpaid_balance_amount, __verified }) => {
          const showValue = !isNil(unpaid_balance_amount);
          const { unpaid_balance_amount: verifiedBalance } = __verified || {};
          const showVerified = !isNil(verifiedBalance);

          const renderValue = showValue ? (
            <Format.Currency value={unpaid_balance_amount!} noDecimals />
          ) : (
            <>&mdash;</>
          );

          return (
            <>
              {renderValue}
              {showVerified ? (
                <StyledVerification
                  hideHelpButton
                  verifiedValue={<Format.Currency value={verifiedBalance!} noDecimals />}
                  currentValue={<Format.Currency value={unpaid_balance_amount!} noDecimals />}
                />
              ) : null}
            </>
          );
        },
        totalSource: ({ unpaid_balance_amount }) => unpaid_balance_amount || 0,
        renderTotal: (total: number) => <Format.Currency value={total} noDecimals />,
      },
      {
        title: (
          <T cid="pageApplication:tabServices.creditCheck.liabilities.column.monthlyPayment.title">
            Mo. Payment
          </T>
        ),
        key: 'monthly_payment',
        align: 'right',
        verticalAlign: 'top',
        render: ({ monthly_payment_amount, __verified }) => {
          const showValue = !isNil(monthly_payment_amount);
          const { monthly_payment_amount: verifiedMonthlyPayment } = __verified || {};
          const showVerified = !isNil(verifiedMonthlyPayment);

          const renderValue = showValue ? (
            <Format.Currency value={monthly_payment_amount!} noDecimals />
          ) : (
            <>&mdash;</>
          );

          return (
            <>
              {renderValue}
              {showVerified ? (
                <StyledVerification
                  hideHelpButton
                  verifiedValue={<Format.Currency value={verifiedMonthlyPayment!} noDecimals />}
                  currentValue={<Format.Currency value={monthly_payment_amount!} noDecimals />}
                />
              ) : null}
            </>
          );
        },
        totalSource: ({ monthly_payment_amount }) => monthly_payment_amount || 0,
        renderTotal: (total: number) => <Format.Currency value={total} noDecimals />,
      },
      {
        title: (
          <T cid="pageApplication:tabServices.creditCheck.liabilities.column.monthsLeft.title">
            Months Left
          </T>
        ),
        key: 'months_left',
        align: 'right',
        verticalAlign: 'top',
        render: ({ unpaid_balance_amount, monthly_payment_amount }) => {
          if (isNil(monthly_payment_amount) || isNil(unpaid_balance_amount)) {
            return <>&mdash;</>;
          }

          const monthsLeft = Math.ceil(unpaid_balance_amount / monthly_payment_amount);

          if (!Number.isFinite(monthsLeft) || monthsLeft === 0) {
            return <>&mdash;</>;
          }

          return monthsLeft;
        },
      },
      {
        title: (
          <T cid="pageApplication:tabServices.creditCheck.liabilities.column.comments.title">
            Comments
          </T>
        ),
        key: 'comments',
        verticalAlign: 'top',
        render: ({ borrower_comments, paid_off_before_closing, excluded }) => (
          <>
            {paid_off_before_closing ? (
              <TagWrapper>
                <Tag color="success" size="xs" compact>
                  <CheckIcon name="check" size="xs" /> <T>to be paid off</T>
                </Tag>
              </TagWrapper>
            ) : null}
            {excluded ? (
              <TagWrapper>
                <Tag color="secondary" size="xs" compact>
                  <CheckIcon name="check" size="xs" /> exclude from ratios
                </Tag>
              </TagWrapper>
            ) : null}
            <BorrowerComments>{borrower_comments}</BorrowerComments>
          </>
        ),
      },
      {
        key: 'actions',
        align: 'right',
        verticalAlign: 'top',
        visible: !readOnly,
        render: (liability) => {
          const { __verified } = liability;
          const canDelete = isEmpty(__verified);

          return (
            <Actions>
              <Button
                icon="edit"
                iconButton
                iconButtonRound
                size="xxs"
                compact
                importance="tertiary"
                type="neutral"
                onClick={handleEdit(liability)}
              >
                <T cid="pageApplication:tabServices.creditCheck.liabilities.column.actions.editButton.label">
                  Edit
                </T>
              </Button>
              {canDelete ? (
                <Popover
                  content="This action cannot be undone."
                  title="Are you sure?"
                  confirm
                  onConfirm={handleDelete(liability)}
                  buttonProps={deleteButtonProps}
                >
                  <T cid="pageApplication:tabServices.creditCheck.liabilities.column.actions.deleteButton.label">
                    Delete
                  </T>
                </Popover>
              ) : null}
            </Actions>
          );
        },
      },
    ],
    [readOnly, model, handleEdit, handleDelete]
  );

  return (
    <>
      <Header>
        <StyledTitle cid="pageApplication:tabServices.creditCheck.liabilities.title" level={4}>
          Liabilities
        </StyledTitle>
        {!readOnly && (
          <Button icon="plus" size="xxs" compact importance="tertiary" onClick={handleAdd}>
            <T cid="pageApplication:tabServices.creditCheck.liabilities.addButton.label">
              Add liability
            </T>
          </Button>
        )}
      </Header>

      <Table caption="Liabilities" rowKey={rowKey} cols={cols} data={model.liabilities} size="sm" />

      <CreditCheckLiabilitiesModal
        isOpen={isOpen}
        liability={selectedLiability}
        model={model}
        onRequestClose={handleCloseModal}
        onSubmit={handleModalSubmit}
      />
    </>
  );
};
