import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../apollo';
import { type ApplicationFileApplicantQuery } from '../../apollo/graphql';

const QApplicationFileApplicant = graphql(`
  query ApplicationFileApplicant($applicationFileId: ID!, $applicantId: ID!) {
    applicationFile(id: $applicationFileId) {
      id
      applicant(id: $applicantId) {
        id
        position
        name
        email
        sharingEmail
      }
    }
  }
`);

export type IApplicationFileApplicant = NonNullable<
  NonNullable<ApplicationFileApplicantQuery['applicationFile']>['applicant']
>;

export const useApplicationFileApplicant = (
  applicationFileId: string,
  applicantId: string,
  options: IUseApolloQueryOptions = {}
) => {
  const [data, loading] = useQuery(
    QApplicationFileApplicant,
    { applicationFileId, applicantId },
    {
      fetchPolicy: 'network-only',
      ...options,
    }
  );

  let result;
  if (!loading) {
    if (data?.applicationFile?.applicant) {
      const { applicant } = data.applicationFile;

      result = applicant;
    } else {
      result = null;
    }
  }

  return [result, loading] as const;
};
