import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo';

const MRestoreApplicationFile = graphql(`
  mutation RestoreApplicationFile($applicationFileId: ID!) {
    restoreApplicationFile(applicationFileId: $applicationFileId) {
      id
      archivedAt
      archivalReason
    }
  }
`);

export const useRestoreApplicationFile = () => {
  const restoreApplicationFile = useMutation(MRestoreApplicationFile, undefined);

  return useCallback(
    async (applicationFileId: string) => {
      await restoreApplicationFile({ applicationFileId });
    },
    [restoreApplicationFile]
  );
};
