import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../apollo';

export const MDeleteApplicationFile = graphql(`
  mutation DeleteApplicationFile($applicationFileId: ID!) {
    deleteApplicationFile(applicationFileId: $applicationFileId) {
      id
    }
  }
`);

export const useDeleteApplicationFile = () => {
  const deleteApplicationFile = useMutation(MDeleteApplicationFile, undefined);

  return async (applicationFileId: string) => {
    await deleteApplicationFile({ applicationFileId });
  };
};
