import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';
import { useMemo } from 'react';

import { graphql } from '../../apollo';
import { type ApplicationFileTasksQuery } from '../../apollo/graphql';

export const TaskFragment = graphql(`
  fragment ITaskFragment on Task {
    id
    key
    typeName
    title
    state
    isAlerted
    isFrozen
    isNew
    isVisible
    isStarted
    common
    custom
    blueprintIndex
    alertMessage
    primaryApplicantId
    editable
    meta {
      title
      requiresAuthentication
      requiresEmailVerification
      description {
        default
        open
        locked
        completed
      }
      disclaimer
      groupKey
      nextButtonLabelWhenCompleted
    }
    dueDate
    followUp
  }
`);

const QApplicationFileTasks = graphql(`
  query ApplicationFileTasks($applicationFileId: ID!) {
    applicationFile(id: $applicationFileId) {
      id
      tasks {
        ...ITaskFragment
      }
      blueprint {
        id
        json
      }
    }
  }
`);

export type IApplicationFileBlueprint = NonNullable<
  NonNullable<ApplicationFileTasksQuery['applicationFile']>['blueprint']
>;

export type IApplicationFileTask = NonNullable<
  ApplicationFileTasksQuery['applicationFile']
>['tasks'][0];

type IUseApplicationFileTasksResult = {
  tasks: IApplicationFileTask[];
  blueprint?: IApplicationFileBlueprint | null;
};

export const useApplicationFileTasks = (
  applicationFileId: string,
  options: IUseApolloQueryOptions = {}
) => {
  const [data, loading, , { refetch }] = useQuery(
    QApplicationFileTasks,
    { applicationFileId },
    {
      fetchPolicy: 'network-only',
      ...options,
      throw: false,
    }
  );

  const handleRefetch = useSafeCallback(() => refetch({ applicationFileId }));

  const result = useMemo<IUseApplicationFileTasksResult>(() => {
    if (loading) {
      return {
        tasks: [],
        blueprint: undefined,
      };
    }

    if (!data?.applicationFile) {
      return {
        tasks: [],
        blueprint: null,
      };
    }

    const { applicationFile } = data;
    const { blueprint, tasks } = applicationFile;

    return {
      tasks,
      blueprint,
    };
  }, [data, loading]);

  return [result, loading, handleRefetch] as const;
};
