import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../../../apollo';
import { type UpdateDocusignTaskInput } from '../../../../apollo/graphql';

export const MUpdateDocusignTask = graphql(`
  mutation UpdateDocusignTask($taskId: ID!, $data: UpdateDocusignTaskInput!) {
    updateDocusignTask(taskId: $taskId, data: $data) {
      id
      meta {
        title
      }
      data
      dueDate
    }
  }
`);

export const useUpdateDocusignTask = () => {
  const updateHellosignTask = useMutation(MUpdateDocusignTask, {} as any);

  return async (taskId: string, data: UpdateDocusignTaskInput) => {
    const response = await updateHellosignTask({
      taskId,
      data,
    });

    return response.data!.updateDocusignTask;
  };
};
