import { Select } from '@mortgagehippo/ds';
import { useMemo } from 'react';

import { useAuthRoles } from '../../../hooks/use-auth-roles';

export const AgentModalAuthRoles = () => {
  const [authRoles = [], loading] = useAuthRoles();
  const options = useMemo(
    () =>
      authRoles.map((role) => ({
        value: role.id,
        selectedLabel: role.name,
        label: (
          <>
            {role.name}
            {role.description ? (
              <em>
                <br />
                {role.description}
              </em>
            ) : null}
          </>
        ),
      })),
    [authRoles]
  );

  return (
    <Select.Box
      name="authRoleId"
      label="Access Level"
      options={options}
      loading={loading}
      required
    />
  );
};
