import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo';
import { type AgentProfileInput } from '../../apollo/graphql';

export const MUpdateAgentProfileMutation = graphql(`
  mutation UpdateAgentProfile($data: AgentProfileInput!) {
    updateAgentProfile(data: $data) {
      ...CurrentUserDataFragment
    }
  }
`);

export const useUpdateAgentProfile = () => {
  const updateAgentProfile = useMutation(MUpdateAgentProfileMutation, undefined);

  return useCallback(
    async (data: AgentProfileInput) => {
      await updateAgentProfile({ data });
    },
    [updateAgentProfile]
  );
};
