import { isFunction, trimStart } from 'lodash-es';
import qs from 'qs';
import { createElement } from 'react';
import { matchPath, Route as RRoute, type RouteProps } from 'react-router';

import { useDrawerTabId } from '$components/drawer/use-drawer-tab-id';

export const OverlayRoute = (props: RouteProps) => {
  const { path: overlayPath, exact, strict, render, component, children } = props;
  const [, setDrawerId] = useDrawerTabId();
  return (
    <RRoute
      render={(routerProps) => {
        const { location } = routerProps;

        // Extract the default drawer id from the hash
        const [, tail] = location.hash.split('?');
        const { collaboration } = qs.parse(tail!);
        if (collaboration) {
          setDrawerId(collaboration as string);
        }

        const path = trimStart(overlayPath as string, '#');
        const hash = trimStart(location.hash, '#').replace(/\?.*$/, '');

        const hashMatch = matchPath(hash, {
          path,
          exact,
          strict,
        });

        if (hashMatch && render) {
          const hashRouterProps = {
            ...routerProps,
            match: hashMatch,
          };
          return render(hashRouterProps);
        }

        if (hashMatch && component) {
          const hashRouterProps = {
            ...routerProps,
            match: hashMatch,
          };
          return createElement(component, hashRouterProps);
        }

        if (isFunction(children)) {
          const hashRouterProps = {
            ...routerProps,
            match: hashMatch,
          };
          return children(hashRouterProps);
        }

        return null;
      }}
    />
  );
};
