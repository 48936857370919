import { type IAction, match, or, when } from '@mortgagehippo/actionable';
import { type NotificationActions, notifications } from '@mortgagehippo/ds';

import { history } from '../../services/history';
import { store } from './actionable';
import {
  CREATE_CSV_FAILURE,
  CREATE_CSV_PROGRESS,
  CREATE_CSV_REQUEST,
  CREATE_CSV_STARTED,
  CREATE_CSV_SUCCESS,
  DOCUMENT_ARCHIVE_FAILURE,
  DOCUMENT_ARCHIVE_PROGRESS,
  DOCUMENT_ARCHIVE_REQUEST,
  DOCUMENT_ARCHIVE_STARTED,
  DOCUMENT_ARCHIVE_SUCCESS,
} from './actions';

type IRequestAction = IAction<any, { id: number | string; progress?: number }, any>;
type ISuccessAction = IAction<any, { id: number | string; url?: string }, any>;
type IFailureAction = IAction<any, { id: number | string }, any>;

const getNotificationId = (action: IAction<any, any, any>) =>
  match(
    action,
    when(CREATE_CSV_REQUEST, (_, { id }) => `exports.csv.${id}`),
    when(CREATE_CSV_STARTED, (_, { id }) => `exports.csv.${id}`),
    when(CREATE_CSV_PROGRESS, (_, { id }) => `exports.csv.${id}`),
    when(CREATE_CSV_SUCCESS, (_, { id }) => `exports.csv.${id}`),
    when(CREATE_CSV_FAILURE, (_, { id }) => `exports.csv.${id}`),
    when(DOCUMENT_ARCHIVE_REQUEST, (_, { id }) => `exports.documents.${id}`),
    when(DOCUMENT_ARCHIVE_STARTED, (_, { id }) => `exports.documents.${id}`),
    when(DOCUMENT_ARCHIVE_PROGRESS, (_, { id }) => `exports.documents.${id}`),
    when(DOCUMENT_ARCHIVE_SUCCESS, (_, { id }) => `exports.documents.${id}`),
    when(DOCUMENT_ARCHIVE_FAILURE, (_, { id }) => `exports.documents.${id}`)
  )!;

const handleRequest = () => (action: IRequestAction) => {
  const notificationId = getNotificationId(action);
  const { progress } = action.payload;

  const messageCsv = (_: any, { model }: any) => {
    if (model === 'ApplicationFile') {
      return 'Export Applications';
    }
    if (model === 'Agent') {
      return 'Export Users';
    }
    return 'Export';
  };

  const title = match(
    action,
    when(CREATE_CSV_REQUEST, messageCsv),
    when(CREATE_CSV_STARTED, messageCsv),
    when(CREATE_CSV_PROGRESS, messageCsv),
    when(DOCUMENT_ARCHIVE_REQUEST, () => 'Export Documents'),
    when(DOCUMENT_ARCHIVE_STARTED, () => 'Export Documents'),
    when(DOCUMENT_ARCHIVE_PROGRESS, () => 'Export Documents'),
    or(() => 'Exporting')
  );

  const csvLink = (_: any, { model }: any) => {
    if (model === 'ApplicationFile') {
      return '/exports/application-files';
    }
    if (model === 'Agent') {
      return '/exports/agents';
    }
    return '/exports/';
  };

  const link = match(
    action,
    when(CREATE_CSV_REQUEST, csvLink),
    when(CREATE_CSV_STARTED, csvLink),
    when(CREATE_CSV_PROGRESS, csvLink),
    when(DOCUMENT_ARCHIVE_REQUEST, () => '/exports/documents'),
    when(DOCUMENT_ARCHIVE_STARTED, () => '/exports/documents'),
    when(DOCUMENT_ARCHIVE_PROGRESS, () => '/exports/documents'),
    or(() => '/exports')
  )!;

  // our components and react router links don't work inside notification component
  const handleLinkClick = (event: any) => {
    event.preventDefault();
    history.push(link);
    notifications.close(notificationId);
  };

  const message = (
    <>
      Processing... When done, you can download the file from the{' '}
      <a href={link} onClick={handleLinkClick}>
        Exports&nbsp;page.
      </a>
    </>
  );

  notifications.info({
    message,
    title,
    duration: 0,
    progress: progress === undefined ? 0 : progress,
    loading: progress === undefined,
    key: notificationId,
  });
};

const handleError = () => (action: IFailureAction) => {
  const notificationId = getNotificationId(action);
  const message =
    match(
      action,
      when(CREATE_CSV_FAILURE, (_, { model }) => {
        if (model === 'ApplicationFile') {
          return 'Exporting applications failed. Please try exporting again.';
        }
        if (model === 'Agent') {
          return 'Exporting users failed. Please try exporting again.';
        }
        return 'Exporting failed. Please try exporting again.';
      }),
      when(DOCUMENT_ARCHIVE_FAILURE, () => 'Documents export failed. Please try exporting again.'),
      or(() => 'Exporting failed. Please try exporting again.')
    ) || 'Exporting failed.';

  notifications.error({
    message,
    duration: 15000,
    loading: false,
    key: notificationId,
  });
};

const handleSuccess = () => (action: ISuccessAction) => {
  const { url } = action.payload;
  const notificationId = getNotificationId(action);
  const label =
    match(
      action,
      when(CREATE_CSV_SUCCESS, () => 'Download CSV file'),
      when(DOCUMENT_ARCHIVE_SUCCESS, () => 'Download ZIP file'),
      or(() => 'Download file')
    ) || 'Download file';

  const message =
    match(
      action,
      when(CREATE_CSV_SUCCESS, (_, { model }) => {
        if (model === 'ApplicationFile') {
          return 'Applications export completed.';
        }
        if (model === 'Agent') {
          return 'Users export completed.';
        }
        return 'Export completed.';
      }),
      when(DOCUMENT_ARCHIVE_SUCCESS, () => 'Documents export completed.'),
      or(() => 'Export completed')
    ) || 'Export completed.';

  const handleLinkClick = () => {
    notifications.close(notificationId);
  };

  // our components and react router links don't work inside notification component
  const notificationActions: NotificationActions | undefined = url
    ? [
        {
          label,
          onAction: handleLinkClick,
          buttonProps: {
            href: url,
            target: '_blank',
            icon: 'download',
            iconLocation: 'left',
          },
        },
      ]
    : undefined;

  notifications.success({
    message,
    key: notificationId,
    progress: 100,
    loading: false,
    actions: notificationActions,
  });
};

store.take(CREATE_CSV_REQUEST, handleRequest());
store.take(CREATE_CSV_STARTED, handleRequest());
store.take(CREATE_CSV_PROGRESS, handleRequest());
store.take(CREATE_CSV_SUCCESS, handleSuccess());
store.take(CREATE_CSV_FAILURE, handleError());
store.take(DOCUMENT_ARCHIVE_REQUEST, handleRequest());
store.take(DOCUMENT_ARCHIVE_STARTED, handleRequest());
store.take(DOCUMENT_ARCHIVE_PROGRESS, handleRequest());
store.take(DOCUMENT_ARCHIVE_SUCCESS, handleSuccess());
store.take(DOCUMENT_ARCHIVE_FAILURE, handleError());
