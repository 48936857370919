import { Store as BaseStore } from '@mortgagehippo/actionable';
import { type Subscription } from 'apollo-client/util/Observable';

import { client } from '../../apollo/apollo-client';
import { type EventsSubscription } from '../../apollo/graphql';
import { SEvents } from './event-subscription';

export class Store extends BaseStore {
  private subscription: Subscription | null = null;

  public startSubscription() {
    if (this.subscription) {
      return;
    }

    this.subscription = client
      .subscribe<EventsSubscription>({
        query: SEvents,
      })
      .subscribe({
        next: (result) => {
          const { data } = result;

          if (!data?.events) {
            return;
          }

          const { typeName, payload, meta } = data.events;

          super.dispatch({
            type_name: typeName,
            payload,
            meta,
          });
        },
      });
  }

  public stopSubscription() {
    if (!this.subscription) {
      return;
    }

    this.subscription.unsubscribe();
    this.subscription = null;
  }
}
