import { type FormSubmitHandler, ModalForm } from '@mortgagehippo/ds';

import { AddApplicantModalForm } from './add-applicant-modal-form';

interface IAddApplicantModalProps {
  onSubmit: FormSubmitHandler;
  onRequestClose: () => void;
  isOpen: boolean;
  forceSharedTasks?: boolean;
  allowSharedTasks?: boolean;
  allowSharedTasksNonSpouse?: boolean;
  allowSharedEmailAddress?: boolean;
  loading: boolean;
}

export const AddApplicantModal = (props: IAddApplicantModalProps) => {
  const {
    onSubmit,
    isOpen,
    onRequestClose,
    forceSharedTasks,
    allowSharedTasks,
    allowSharedTasksNonSpouse,
    allowSharedEmailAddress,
    loading,
  } = props;

  return (
    <ModalForm
      title="Add Borrower"
      isOpen={isOpen}
      onSubmit={onSubmit}
      onRequestClose={onRequestClose}
      disableOverlayClickClose
      autoComplete={false}
      loading={loading}
    >
      <AddApplicantModalForm
        forceSharedTasks={forceSharedTasks}
        allowSharedTasks={allowSharedTasks}
        allowSharedTasksNonSpouse={allowSharedTasksNonSpouse}
        allowSharedEmailAddress={allowSharedEmailAddress}
      />
    </ModalForm>
  );
};
