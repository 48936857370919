import { graphql } from '../../../apollo';
import { type PermissionAgentsListQuery, type PermissionsQuery } from '../../../apollo/graphql';

// query for displaying the list of authRoles
export const QPermissions = graphql(`
  query Permissions($partnerId: ID!, $perPage: Int!, $cursor: String) {
    partner(id: $partnerId) {
      id
      authRoles(options: { cursor: $cursor, perPage: $perPage, orderBy: { name: "asc" } }) {
        total
        nextCursor
        previousCursor
        items {
          id
          name
          description
          agents {
            total
          }
        }
      }
    }
  }
`);

export type IPermissionsAuthRole = NonNullable<
  PermissionsQuery['partner']
>['authRoles']['items'][0];

// query for displaying the users belonging to a specific authRole
export const QPermissionAgentsList = graphql(`
  query PermissionAgentsList($partnerId: ID!, $authRoleId: ID!, $perPage: Int!, $cursor: String) {
    partner(id: $partnerId) {
      id
      authRole(id: $authRoleId) {
        id
        agents(options: { perPage: $perPage, cursor: $cursor, orderBy: { name: "asc" } }) {
          total
          nextCursor
          previousCursor
          items {
            id
            email
            name
            license
            phone
          }
        }
      }
    }
  }
`);

export type PermissionAgentsListAgent = NonNullable<
  NonNullable<PermissionAgentsListQuery['partner']>['authRole']
>['agents']['items'][0];

export const MSwitchAgentPermission = graphql(`
  mutation SwitchAgentPermission($agentId: ID!, $authRoleId: ID!) {
    updateAgentRole(agentId: $agentId, authRoleId: $authRoleId)
  }
`);
