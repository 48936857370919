import { ForbiddenError } from '@mortgagehippo/auth';
import { type RouteComponentProps } from 'react-router';

import { useApplicationFileCan } from '$components/permissions';

import { ApplicationInvites } from './application-invites';

export interface IApplicationInvitesRouteParams {
  applicationFileId: string;
}

export const ApplicationInvitesRoute = (
  props: RouteComponentProps<IApplicationInvitesRouteParams>
) => {
  const { match } = props;
  const { params } = match;
  const { applicationFileId } = params;

  const [can, canReady] = useApplicationFileCan(applicationFileId);

  if (!canReady) {
    return null;
  }

  if (!can.VIEW_SEND_INVITES_PAGE) {
    throw new ForbiddenError();
  }

  return <ApplicationInvites applicationFileId={applicationFileId} />;
};
