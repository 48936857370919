import { Button, Format, type ITableCols, NewLines, Table } from '@mortgagehippo/ds';

import { ExportJobStatusType } from '../../apollo/graphql';
import { DownloadStatus } from './download-status';
import { type IExportJob } from './use-export-jobs';

const cols: ITableCols<IExportJob> = [
  {
    title: 'Description',
    key: 'description',
    render({ description }) {
      return <NewLines value={description}>&mdash;</NewLines>;
    },
  },
  {
    title: 'Status',
    key: 'status',
    render(record) {
      return <DownloadStatus job={record} />;
    },
  },
  {
    title: 'Created At',
    key: 'createdAt',
    render({ createdAt }) {
      return <Format.Date value={createdAt} format="date-med-time" />;
    },
  },
  {
    key: 'download',
    render({ url, status }) {
      const disabled = status !== ExportJobStatusType.complete || !url;

      return (
        <Button
          icon="download"
          size="sm"
          importance="tertiary"
          href={url || ''}
          disabled={disabled}
          target="_blank"
          compact
        >
          Download
        </Button>
      );
    },
    align: 'right',
  },
];

interface IDocumentsTableProps {
  caption: string;
  loading: boolean;
  dataSource?: IExportJob[];
}

export const DocumentsTable = (props: IDocumentsTableProps) => {
  const { caption, dataSource = [], loading } = props;

  return (
    <Table<IExportJob>
      caption={caption}
      data={dataSource}
      rowKey={(item) => item.id}
      cols={cols}
      loading={loading}
      size="sm"
    />
  );
};
