import { HelpButton, Text, useCustomizations } from '@mortgagehippo/ds';
import { type ReactNode } from 'react';

interface ApplicationPricingFieldWarningProps {
  onReset: () => void;
  children?: ReactNode;
}

export const ApplicationPricingFieldWarning = (props: ApplicationPricingFieldWarningProps) => {
  const { onReset, children } = props;

  const customizations = useCustomizations();
  const confirmLabelNo = customizations.text(
    'pageApplication:tabPricing.form.warning.popover.close',
    'Close'
  );

  const confirmLabelYes = customizations.text(
    'pageApplication:tabPricing.form.warning.popover.reset',
    'Reset to initial value'
  );

  return (
    <>
      {children}
      <HelpButton
        icon="information"
        popover
        popoverProps={{
          confirm: true,
          align: 'RightMiddle',
          minWidthCSS: '360px',
          confirmLabelYes,
          confirmLabelNo,
          onConfirm: onReset,
        }}
        size="xxs"
        iconColor="warning800"
      >
        <Text as="p" size="sm">
          If you want to officially use the updated value you must change it in the application
          first.
          <br />
          <br />
          You can select a loan product only when the pricing results are based on the initial
          values from the application.
        </Text>
      </HelpButton>
    </>
  );
};
