import {
  type IDateTimeFormat,
  type ISearchInputMode,
  type ITreeSelectOption,
  type TableColSortDirection,
} from '@mortgagehippo/ds';
import { type ISelectChoice } from '@mortgagehippo/tasks';

import { type SmartviewModelType } from '../../apollo/graphql';

export enum SmartviewFieldType {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  BOOLEAN = 'boolean',
  PHONE = 'phone',
  PERCENT = 'percent',
  URL = 'url',
  CURRENCY = 'currency',
  ID = 'id',
  APPLICATION_STATUS = 'application_status',
  TEAM_AGENTS = 'team_agents',
  SSN = 'ssn',
}

export interface ISmartviewDataFieldOptions {
  minWidth?: number;
  noWrap?: boolean;
  format?: IDateTimeFormat;
  disabled?: boolean;
  truncate?: number;
}

export interface ISmartviewSearchFieldOptions {
  inputMode?: ISearchInputMode;
  group?: string;
  choices?: ISelectChoice[] | ITreeSelectOption[] | string;
}

export interface ISmartviewFieldOptions {
  column?: ISmartviewDataFieldOptions;
  searchField?: ISmartviewSearchFieldOptions;
}

export interface ISmartviewFieldMetadata {
  type: SmartviewFieldType;
  title: string;
  options?: ISmartviewFieldOptions;
}

export interface ISmartviewMetadata {
  [key: string]: ISmartviewFieldMetadata;
}

export interface ISmartviewRecord {
  id: string;
  data: any;
}

export interface ISmartViewColumn {
  title?: string;
  fields: string | string[];
  sortField?: string;
  defaultSort?: TableColSortDirection;
}

export interface ISmartViewSearchField {
  label?: string;
  field: string;
}

export type ChoicesFn = (token: string) => () => Promise<ISelectChoice[] | ITreeSelectOption[]>;

export interface ISmartviewDocumentationField {
  name: string;
  title: string;
  type: SmartviewFieldType;
}

export interface ISmartviewDocumentation {
  model: SmartviewModelType;
  dataFields: ISmartviewDocumentationField[];
  searchFields: ISmartviewDocumentationField[];
  exportFields?: ISmartviewDocumentationField[] | null;
}
