import { ExportJobStatusType } from '../../apollo/graphql';
import { Actionable } from './actionable';

/* CSV */
export const [CREATE_CSV_REQUEST] = Actionable.create(
  'CREATE_CSV_REQUEST',
  (id: number, model: string) => ({
    id,
    model,
    status: ExportJobStatusType.running,
  }),
  () => ({})
);

export const [CREATE_CSV_STARTED] = Actionable.create(
  'CREATE_CSV_STARTED',
  (id: number, model: string) => ({ id, model, status: ExportJobStatusType.running }),
  () => ({})
);

export const [CREATE_CSV_PROGRESS] = Actionable.create(
  'CREATE_CSV_PROGRESS',
  (id: number, model: string, progress: number, progressDescription: string | null = null) => ({
    id,
    model,
    progress,
    progressDescription,
    status: ExportJobStatusType.running,
  }),
  () => ({})
);

export const [CREATE_CSV_SUCCESS] = Actionable.create(
  'CREATE_CSV_SUCCESS',
  (id: number, model: string, url: string) => ({
    id,
    model,
    url,
    status: ExportJobStatusType.complete,
  }),
  () => ({})
);

export const [CREATE_CSV_FAILURE] = Actionable.create(
  'CREATE_CSV_FAILURE',
  (id: number, model: string) => ({ id, model, status: ExportJobStatusType.failed }),
  () => ({})
);

/* DOCUMENT ARCHIVER */
export const [DOCUMENT_ARCHIVE_REQUEST] = Actionable.create(
  'DOCUMENT_ARCHIVE_REQUEST',
  (id: number, status: ExportJobStatusType) => ({
    id,
    status,
  }),
  () => ({})
);

export const [DOCUMENT_ARCHIVE_STARTED] = Actionable.create(
  'DOCUMENT_ARCHIVE_STARTED',
  (id: number) => ({
    id,
    status: ExportJobStatusType.running,
  }),
  () => ({})
);

export const [DOCUMENT_ARCHIVE_PROGRESS] = Actionable.create(
  'DOCUMENT_ARCHIVE_PROGRESS',
  (id: number, progress: number, progressDescription: string | null = null) => ({
    id,
    progress,
    progressDescription,
    status: ExportJobStatusType.running,
  }),
  () => ({})
);

export const [DOCUMENT_ARCHIVE_SUCCESS] = Actionable.create(
  'DOCUMENT_ARCHIVE_SUCCESS',
  (id: number, url: string) => ({
    id,
    url,
    status: ExportJobStatusType.complete,
  }),
  () => ({})
);

export const [DOCUMENT_ARCHIVE_FAILURE] = Actionable.create(
  'DOCUMENT_ARCHIVE_FAILURE',
  (id: number) => ({
    id,
    status: ExportJobStatusType.failed,
  }),
  () => ({})
);

export const [EXITED_APPLICATION] = Actionable.create(
  'EXITED_APPLICATION',
  (id: number | string) => ({
    id,
  }),
  () => ({})
);
