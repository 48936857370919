import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo';

const MSendDocumentPostUrl = graphql(`
  mutation ISendDocumentPostUrl($applicationFileId: ID!, $filename: String!) {
    getSendDocumentPostUrl(applicationFileId: $applicationFileId, filename: $filename) {
      id
      url
      postUrl
    }
  }
`);

export const useSendDocumentSubmitUrl = () => {
  const getUpload = useMutation(MSendDocumentPostUrl, undefined);

  return useCallback(
    async (applicationFileId: string, filename: string) => {
      const response = await getUpload(
        {
          applicationFileId,
          filename,
        },
        { fetchPolicy: 'no-cache' }
      );

      return response.data!.getSendDocumentPostUrl;
    },
    [getUpload]
  );
};
