import { useForceUpdate } from '@mortgagehippo/util';
import { useEffect } from 'react';

import { history } from '../services/history';

export const useLocation = (): any => {
  const forceUpdate = useForceUpdate();
  useEffect(() => history.listen(forceUpdate), [forceUpdate]);
  return history.location;
};
