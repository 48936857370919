import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../../apollo';

export const MTriggerMortgageRefreshFlexLoanStatusMutation = graphql(`
  mutation TriggerMortgageFlexRefreshLoanStatus($applicationFileId: ID!) {
    triggerMortgageFlexRefreshLoanStatus(applicationFileId: $applicationFileId)
  }
`);

export const useTriggerRefreshMortgageFlexLoanStatus = () => {
  const triggerRefreshMortgageFlexLoanStatus = useMutation(
    MTriggerMortgageRefreshFlexLoanStatusMutation,
    undefined
  );

  return async (applicationFileId: string) => {
    const response = await triggerRefreshMortgageFlexLoanStatus({
      applicationFileId,
    });
    return response.data!.triggerMortgageFlexRefreshLoanStatus;
  };
};
