import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../apollo';

const MTriggerLossEncompassPush = graphql(`
  mutation TriggerLossEncompassPush($applicationFileId: ID!) {
    triggerLossEncompassLoanExport(applicationFileId: $applicationFileId)
  }
`);

export const useTriggerLossEncompassPush = () => {
  const fn = useMutation(MTriggerLossEncompassPush, undefined);

  return useCallback(
    async (applicationFileId: string): Promise<string> => {
      const response = await fn({ applicationFileId });

      return response.data!.triggerLossEncompassLoanExport;
    },
    [fn]
  );
};
