import {
  Button,
  Email,
  FieldBox,
  Input,
  Phone,
  SimpleFieldAutoArray,
  useFormValue,
} from '@mortgagehippo/ds';
import styled from 'styled-components';

import { NotificationOptIns } from '$components/notification-opt-ins-field';

import { NotificationOptInType } from '../../../apollo/graphql';

const ArrayRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ArrayCol = styled.div<{ flex?: number }>`
  flex: ${({ flex }) => (flex === undefined ? 1 : flex)};
  flex-basis: auto;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

export const AccountTabFields = () => {
  const [selectedNotificationMethods] = useFormValue<NotificationOptInType[] | undefined>(
    'notification_opt_ins'
  );
  const smsSelected =
    selectedNotificationMethods && selectedNotificationMethods.includes(NotificationOptInType.sms);

  return (
    <>
      <Input.Box label="Name" name="name" required />
      <NotificationOptIns.Box
        label="Notification Method"
        name="notification_opt_ins"
        description="This is how you will receive important communication about your applications."
      />
      <Phone.Box label="Phone" name="phone" />
      <Phone.Box
        label="Cell Phone"
        name="cell_phone"
        description="This is where you will receive text-based notifications"
        required={smsSelected}
      />
      <Phone.Box label="Alternate Phone" name="alternate_phone" />
      <FieldBox name="secondary_email" label="Secondary Email (cc'd on email notifications)">
        <SimpleFieldAutoArray
          name="secondary_email"
          presentFields={['email']}
          render={({ name: itemName, error, onRemove, active }) => (
            <ArrayRow>
              <ArrayCol>
                <Email
                  name={`${itemName}.email`}
                  placeholder="Email"
                  compact
                  aria-label="Email"
                  error={!active && !!error}
                />
              </ArrayCol>
              <ArrayCol flex={0}>
                <Button
                  icon="delete"
                  iconButton
                  size="xs"
                  type="danger"
                  importance="tertiary"
                  onClick={onRemove}
                  compact
                />
              </ArrayCol>
            </ArrayRow>
          )}
        />
      </FieldBox>
    </>
  );
};
