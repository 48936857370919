import { graphql } from '../../apollo';
import { client } from '../../apollo/apollo-client';
import { type CreditPullFileQuery, type CreditPullFileQueryVariables } from '../../apollo/graphql';

const QCreditPullFile = graphql(`
  query CreditPullFile($applicationFileId: ID!, $creditPullId: ID!) {
    applicationFile(id: $applicationFileId) {
      id
      creditPull(id: $creditPullId) {
        file
      }
    }
  }
`);

export const getCreditPullFile = async (applicationFileId: string, creditPullId: string) => {
  const result = await client.query<CreditPullFileQuery, CreditPullFileQueryVariables>({
    query: QCreditPullFile,
    variables: {
      applicationFileId,
      creditPullId,
    },
  });

  if (!result?.data?.applicationFile?.creditPull) {
    return null;
  }

  return result.data.applicationFile.creditPull.file;
};
