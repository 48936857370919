import { useMutation, useQuery } from '@mortgagehippo/apollo-hooks';
import { notifications } from '@mortgagehippo/ds';
import { isNil } from 'lodash-es';
import { useCallback, useEffect, useMemo } from 'react';

import { JobFunctionsModal } from './job-functions-modal';
import { MCreateJobFunction, MUpdateJobFunction, QJobFunction } from './queries';

interface IJobFunctionsModalContainerProps {
  siteId: string;
  jobFunctionId?: string;
  onRequestClose: () => void;
  onSubmit: () => void;
  isOpen: boolean;
}

export const JobFunctionsModalContainer = (props: IJobFunctionsModalContainerProps) => {
  const { siteId, jobFunctionId, onRequestClose, onSubmit, isOpen } = props;

  const isEditing = !isNil(jobFunctionId);

  const [data, loading] = useQuery(
    QJobFunction,
    { siteId, jobFunctionId: jobFunctionId! },
    { skip: !isOpen || !isEditing, fetchPolicy: 'network-only' }
  );

  const jobFunction = data?.site?.jobFunction;

  const addJobFunction = useMutation(MCreateJobFunction);

  const updateJobFunction = useMutation(MUpdateJobFunction);

  const loadingFailed = isOpen && isEditing && !loading && !jobFunction;
  useEffect(() => {
    if (loadingFailed) {
      notifications.error({
        message: 'An error occurred while processing your request. Please try again later.',
      });
      onRequestClose();
    }
  }, [onRequestClose, loadingFailed]);

  const handleSubmit = useCallback(
    async (values: any) => {
      let successMsg = '';

      try {
        const { name } = values;

        if (!isNil(jobFunctionId)) {
          await updateJobFunction({ jobFunctionId, data: { name } });
          successMsg = 'The role was successfully updated.';
        } else {
          await addJobFunction({ siteId, data: { name } });
          successMsg = 'The role was successfully created.';
        }

        notifications.success({ message: successMsg });

        onSubmit();
      } catch (error) {
        notifications.error({
          message: 'An error occurred while processing your request. Please try again later.',
        });
      }
    },
    [jobFunctionId, updateJobFunction, addJobFunction, siteId, onSubmit]
  );

  const initialValues = useMemo(() => {
    if (!jobFunction) {
      return {};
    }

    const { name } = jobFunction;

    return {
      name,
    };
  }, [jobFunction]);

  const title = !isEditing ? 'Add Role' : 'Edit Role';

  return (
    <JobFunctionsModal
      title={title}
      onSubmit={handleSubmit}
      onRequestClose={onRequestClose}
      initialValues={initialValues}
      isOpen={isOpen}
      loading={loading || loadingFailed}
      disableOverlayClickClose
    />
  );
};
