import { HelpButton, spacing } from '@mortgagehippo/ds';
import { isNil } from 'lodash-es';
import styled from 'styled-components';

import { TeamAgentsListContainer } from './team-agents-list';

const Name = styled.span`
  margin-right: ${spacing(1)};
`;

const PopoverScroll = styled.div`
  max-height: calc(100vh - 120px);
  overflow-y: auto;
  padding: 0 ${spacing(3)} ${spacing(2)} 0; /* bottom padding needed to hide vert. scrollbar */
  margin: 0 -${spacing(3)} 0 0;
`;

interface ITeamAgentsRendererProps {
  applicationFileId: string;
  primaryAgentName?: string | null;
}

export const TeamAgentsRenderer = (props: ITeamAgentsRendererProps) => {
  const { applicationFileId, primaryAgentName } = props;

  if (isNil(primaryAgentName)) {
    return null;
  }

  return (
    <div>
      <Name>{primaryAgentName}</Name>
      <HelpButton
        icon="team"
        size="xxs"
        popover
        popoverProps={{
          align: 'RightMiddle',
          minWidthCSS: '400px',
          maxWidthCSS: '500px',
          widthCSS: '100%',
        }}
      >
        <PopoverScroll>
          <TeamAgentsListContainer applicationFileId={applicationFileId} />
        </PopoverScroll>
      </HelpButton>
    </div>
  );
};
