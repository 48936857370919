import { useAuth } from '@mortgagehippo/auth';
import { ModalDialog, notifications, useModal } from '@mortgagehippo/ds';
import { useCreateApplicationFromApplicant } from '@mortgagehippo/tasks';
import { type FunctionComponent, useCallback } from 'react';

import { type IActionProps, type ICreateApplicationFromApplicantAction } from '../types';

export const CreateApplicationFromApplicantAction: FunctionComponent<
  IActionProps<ICreateApplicationFromApplicantAction>
> = (props) => {
  const { action, onDone, onSubmit, onCancel } = props;
  const { applicantId } = action;

  const [isOpen, , closeModal] = useModal(true);
  const [user] = useAuth();

  const createApplicationFromApplicant = useCreateApplicationFromApplicant();

  const handleSubmit = useCallback(async () => {
    try {
      const agentId = user?.id;

      onSubmit();

      const nextApplicationFileId = await createApplicationFromApplicant(
        applicantId,
        {
          assignmentOptions: { agentId, copyFromApplicationFileId: null },
          sendInvitation: null,
        },
        true
      );

      closeModal(() => {
        onDone({
          applicationFileId: nextApplicationFileId,
        });
      });
    } catch (e) {
      console.error(e);
      notifications.error({
        message: 'There was an unexpected error creating the application.',
      });
    }
  }, [onSubmit, closeModal, user, createApplicationFromApplicant, applicantId, onDone]);

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  if (!user) return null;

  return (
    <ModalDialog
      type="info"
      title="Do you want to create an application?"
      onOk={handleSubmit}
      onRequestClose={handleCloseModal}
      isOpen={isOpen}
    >
      You are about to create an application based on the primary borrower of this one.
    </ModalDialog>
  );
};
