import { times } from 'lodash-es';

import { ActivityFeedTable } from './activity-feed-table';

const fakeRows = (num: number) =>
  times(num, (currentNum) => ({
    id: `${currentNum}`,
    actor: null,
    createdAt: '',
    event: [],
  }));

export const ActivityFeedSkeleton = () => {
  const items = fakeRows(2);

  return <ActivityFeedTable dictionary={{}} items={items} loading />;
};
