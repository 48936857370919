import { OWNER_SEPARATOR } from './constants';
import { type OwnerType } from './types';

export const toOwnerId = (type: OwnerType, id: string) => `${type}:${id}`;

export const parseOwnerId = (ownerId: string): [OwnerType, string] => {
  const [type, id] = ownerId.split(OWNER_SEPARATOR);

  return [type as OwnerType, id!];
};
