import 'moment-timezone';

import { ButtonLink, T } from '@mortgagehippo/ds';
import moment from 'moment';
import { useCallback } from 'react';

import { ActionType, useDispatchAction } from '$components/actions';
import { useApplicationFileCan } from '$components/permissions';

import { ApplicationAlert } from './application-alert';
import { isApplicationFileAlerted } from './util';

export interface IApplicationAlertsProps {
  applicationFileId: string;
}

export const ApplicationAlerts = (props: IApplicationAlertsProps) => {
  const { applicationFileId } = props;

  const [can, , { data: applicationFile }] = useApplicationFileCan(applicationFileId);

  const dispatch = useDispatchAction();

  const isAlerted = !!applicationFile && isApplicationFileAlerted(applicationFile);
  const isArchived = !!applicationFile && !!applicationFile.archivedAt;

  const handleRestore = useCallback(() => {
    dispatch({ type: ActionType.RESTORE_APPLICATION, applicationFileId });
  }, [applicationFileId, dispatch]);

  if (!isAlerted || !applicationFile) {
    return null;
  }

  if (isArchived) {
    const archivedAt = moment.tz(applicationFile.archivedAt, 'UTC');
    const now = moment.utc();
    const daysAgo = now.diff(archivedAt, 'days');
    const isToday = daysAgo === 0;

    return (
      <ApplicationAlert type="warning" icon="delete">
        {isToday ? (
          <T cid="pageApplication:alert.archived.today">This application was archived today.</T>
        ) : null}
        {!isToday && (
          <T cid="pageApplication:alert.archived.daysAgo" cvars={{ daysAgo }}>
            This application was archived {daysAgo} day{daysAgo > 1 ? 's' : ''} ago.
          </T>
        )}
        {can.RESTORE_APPLICATION_FILE ? (
          <>
            &nbsp;&nbsp;
            <ButtonLink icon="undo" onClick={handleRestore} iconSize="xs">
              <T>Restore application</T>
            </ButtonLink>
          </>
        ) : null}
      </ApplicationAlert>
    );
  }

  // add more alerts

  return null;
};
