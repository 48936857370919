import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { graphql } from '../../../../../../apollo';

const QMortgageBotLosPush = graphql(`
  query MortgageBotLosPush($applicationFileId: ID!) {
    applicationFile(id: $applicationFileId) {
      id
      mortgageBotLoanFilePush {
        id
        dealId
        pushed
        loanNumber
        createdAt
        updatedAt
        statusCode
        statusDescription
      }
    }
  }
`);

export const useMortgageBotLosPush = (applicationFileId: string, options: IUseApolloQueryOptions = {}) => {
  const [data, loading, , { refetch }] = useQuery(
    QMortgageBotLosPush,
    { applicationFileId },
    { fetchPolicy: 'network-only', ...options }
  );

  const handleRefresh = useSafeCallback(async () => {
    await refetch({ applicationFileId });
  });

  return [data?.applicationFile?.mortgageBotLoanFilePush, loading, handleRefresh] as const;
};
