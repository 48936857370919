import { fontSize, fontWeight, type ISelectOption, Select } from '@mortgagehippo/ds';
import { useMemo } from 'react';
import styled from 'styled-components';

import { type ILenderMilestone } from '../../use-lender-milestones';

const NO_ACTIVE_MILESTONE = 'No active milestone';

const ActiveMilestoneName = styled.span`
  font-size: ${fontSize('md')};
  font-weight: ${fontWeight('bold')};
  line-height: 1.5;
`;

const ActiveMilestoneSelect = styled(Select.Input)`
  max-width: 360px;
`;

const SelectedLenderMilestoneCurrentLabel = styled.span`
  font-weight: ${fontWeight('light')};
`;

interface IApplicationLenderMilestonesActiveMilestoneProps {
  milestones: ILenderMilestone[];
  isEditing: boolean;
  onChange: (newValue: string) => void;
  selectedActiveMilestoneId?: string;
  submitting?: boolean;
}

export const ApplicationLenderMilestonesActiveMilestone = (
  props: IApplicationLenderMilestonesActiveMilestoneProps
) => {
  const { isEditing, milestones, onChange, selectedActiveMilestoneId, submitting } = props;

  const activeMilestoneName = useMemo(() => {
    if (!milestones.length) {
      return null;
    }

    const activeMilestone = milestones.find((milestone) => milestone.active);
    if (activeMilestone?.name) {
      return activeMilestone.name;
    }

    const hasNotCompletedAnyMilestones = milestones.every((milestone) => !milestone.completed);
    if (hasNotCompletedAnyMilestones) {
      return 'No registered events';
    }

    const hasCompletedAllMilestones = milestones.every((milestone) => milestone.completed);
    if (hasCompletedAllMilestones) {
      return 'All completed!';
    }

    return `${NO_ACTIVE_MILESTONE}`;
  }, [milestones]);

  const currentLenderMilestoneSelectOptions: ISelectOption[] = useMemo(() => {
    const options = milestones.map((milestone) => ({
      label: milestone.name,
      value: milestone.id,
      selectedLabel: (
        <>
          <SelectedLenderMilestoneCurrentLabel>Current:</SelectedLenderMilestoneCurrentLabel>{' '}
          {milestone.name}
        </>
      ),
    }));
    options.unshift({
      label: NO_ACTIVE_MILESTONE,
      value: 'none',
      selectedLabel: (
        <>
          <SelectedLenderMilestoneCurrentLabel>Current:</SelectedLenderMilestoneCurrentLabel>{' '}
          {NO_ACTIVE_MILESTONE}
        </>
      ),
    });
    return options;
  }, [milestones]);

  if (isEditing) {
    return (
      <ActiveMilestoneSelect
        onChange={onChange}
        value={selectedActiveMilestoneId}
        name="current_lender_milestone"
        options={currentLenderMilestoneSelectOptions}
        placeholder="Set active milestone"
        loading={submitting}
        disabled={submitting}
      />
    );
  }

  return <ActiveMilestoneName>{activeMilestoneName}</ActiveMilestoneName>;
};
