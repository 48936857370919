import { type INotificationOptions, notifications, T, Text, useModal } from '@mortgagehippo/ds';
import { toArray } from '@mortgagehippo/util';
import { useCallback, useEffect } from 'react';

import { useArchiveApplicationFile } from '../../../pages/application/use-archive-application';
import { type IActionProps, type IArchiveApplicationAction } from '../types';
import { ArchiveApplicationModal } from './archive-application-modal';

export const ArchiveApplicationAction = (props: IActionProps<IArchiveApplicationAction>) => {
  const { action, onDone, onCancel } = props;
  const { applicationFileId } = action;
  const [isOpen, , closeModal] = useModal(true);

  const archiveApplication = useArchiveApplicationFile();

  const applicationFileIds = toArray(applicationFileId);
  const totalApplications = applicationFileIds.length;
  const isBulk = totalApplications > 1;

  const successNotificationOptions: INotificationOptions = isBulk
    ? {
        message: (
          <T
            cid="actions:archiveApplication.bulk.notification.success"
            cvars={{ totalApplications }}
          >
            Successfully archived {totalApplications} applications
          </T>
        ),
      }
    : {
        messageCid: 'actions:archiveApplication.single.notification.success',
        message: 'The application was successfully archived',
      };

  const errorNotificationOptions: INotificationOptions = isBulk
    ? {
        messageCid: 'actions:archiveApplication.bulk.notification.error',
        message: 'There was an unexpected error archiving the applications',
      }
    : {
        messageCid: 'actions:archiveApplication.single.notification.error',
        message: 'There was an unexpected error archiving the application',
      };

  const isInvalid = !applicationFileIds.length;
  useEffect(() => {
    if (isInvalid) {
      console.error('No ID specified for archival.');
      onCancel();
    }
  }, [isInvalid, onCancel]);

  const handleSubmit = async (values: any) => {
    try {
      const { reason } = values;

      await Promise.all(
        applicationFileIds.map((id) =>
          archiveApplication(id, {
            reason,
          })
        )
      );

      closeModal(() => {
        notifications.success(successNotificationOptions);
        onDone();
      });
    } catch (e) {
      console.error(e);
      notifications.error(errorNotificationOptions);

      if (isBulk) {
        onCancel();
      }
    }
  };

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  if (isBulk) {
    return (
      <ArchiveApplicationModal
        title={<T cid="actions:archiveApplication.bulk.modal.title">Archive Applications</T>}
        okButtonLabel={
          <T cid="actions:archiveApplication.bulk.modal.okButton.label">Archive applications</T>
        }
        onSubmit={handleSubmit}
        onRequestClose={handleCloseModal}
        isOpen={isOpen}
      >
        <Text as="p" cid="actions:archiveApplication.bulk.modal.description">
          Borrowers will not have access to these applications once they have been archived.
        </Text>
      </ArchiveApplicationModal>
    );
  }

  return (
    <ArchiveApplicationModal
      title={<T cid="actions:archiveApplication.single.modal.title">Archive Application</T>}
      okButtonLabel={
        <T cid="actions:archiveApplication.single.modal.okButton.label">Archive application</T>
      }
      onSubmit={handleSubmit}
      onRequestClose={handleCloseModal}
      isOpen={isOpen}
    >
      <Text as="p" cid="actions:archiveApplication.single.modal.description">
        Borrowers will not have access to this application once it has been archived.
      </Text>
    </ArchiveApplicationModal>
  );
};
