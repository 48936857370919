import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo';

export const MTriggerLPAAssessment = graphql(`
  mutation TriggerLPAAssessment($applicationFileId: ID!) {
    triggerLPAAssessment(applicationFileId: $applicationFileId)
  }
`);

export const useTriggerLPAAssessment = () => {
  const triggerLPAAssessment = useMutation(MTriggerLPAAssessment, undefined);

  return useCallback(
    async (applicationFileId: string) => {
      const response = await triggerLPAAssessment({ applicationFileId });
      return response.data!.triggerLPAAssessment;
    },
    [triggerLPAAssessment]
  );
};
