import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../apollo';
import { type DocumentInput } from '../../apollo/graphql';

export const MCreateLenderDocuments = graphql(`
  mutation ICreateLenderDocuments($applicationFileId: ID!, $documents: [DocumentInput!]!) {
    createLenderDocuments(applicationFileId: $applicationFileId, documents: $documents) {
      id
    }
  }
`);

export const useCreateLenderDocuments = () => {
  const createLenderDocuments = useMutation(MCreateLenderDocuments);
  return async (applicationFileId: string, documents: DocumentInput[]) => {
    const response = await createLenderDocuments({
      applicationFileId,
      documents,
    });
    return response.data!.createLenderDocuments;
  };
};
