import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo';

const MPartnerReport = graphql(`
  mutation PartnerReport($partnerId: String!, $dashboard: String!) {
    getPartnerReportUrl(partnerId: $partnerId, dashboard: $dashboard)
  }
`);

export const useGetPartnerReportUrl = () => {
  const fn = useMutation(MPartnerReport);

  return useCallback(
    async (partnerId: string, dashboard: string) => {
      const response = await fn({
        partnerId,
        dashboard,
      });

      return response.data!.getPartnerReportUrl;
    },
    [fn]
  );
};
