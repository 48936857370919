import {
  Button,
  Email,
  FieldBox,
  FormSection,
  type IModalFormProps,
  Input,
  ModalForm,
  Phone,
  SimpleFieldAutoArray,
  Slug,
  type UploadHandler,
  Url,
  USState,
  YesNo,
} from '@mortgagehippo/ds';
import styled from 'styled-components';

import { ContentEditor } from '$components/content-editor';
import { UploadAvatar } from '$components/upload-avatar-field';

import { AgentModalAuthRoles } from './agent-modal-auth-roles';
import { AgentModalFoldersSection } from './agent-modal-folders-section';
import { AgentModalMetadata } from './agent-modal-metadata';
import { AgentModalSitesSection } from './agent-modal-sites-section';
import { type IFolder } from './use-agent-modal-data';

const ArrayRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ArrayCol = styled.div<{ flex?: number }>`
  flex: ${({ flex }) => (flex === undefined ? 1 : flex)};
  flex-basis: auto;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

interface IAgentModalProps extends Omit<IModalFormProps, 'children'> {
  agentId?: string;
  folders: IFolder[];
  readOnly?: boolean;
  showSlug?: boolean;
  onUpload: UploadHandler;
}

export const AgentModal = (props: IAgentModalProps) => {
  const {
    agentId,
    folders,
    loading,
    readOnly,
    showSlug,
    onUpload,
    okButtonDisabled: propsOkButtonDisabled,
    initialValues,
    ...rest
  } = props;

  const hasFolders = folders.length > 0;

  return (
    <ModalForm
      width={800}
      loading={loading}
      initialValues={initialValues}
      {...rest}
      okButtonDisabled={propsOkButtonDisabled || readOnly}
      autoComplete={false}
      readOnly={readOnly}
    >
      <FormSection title="Account">
        <UploadAvatar.Box label="Avatar" name="avatar" onUpload={onUpload} />
        <Input.Box name="name" label="Name" required />
        <Email.Box name="email" type="email" label="Email" required />
        {showSlug ? <Slug.Box name="slug" label="Slug" required /> : null}
        <Phone.Box name="phone" label="Phone Number" />
        <Phone.Box
          label="Cell Phone"
          name="cellPhone"
          description="This is where the agent will receive text-based notifications"
        />
        <Phone.Box label="Alternate Phone" name="alternatePhone" />

        <FieldBox name="secondary_email" label="Secondary Email (cc'd on email notifications)">
          <SimpleFieldAutoArray
            name="secondaryEmail"
            presentFields={['email']}
            render={({ name: itemName, error, onRemove, active }) => (
              <ArrayRow>
                <ArrayCol>
                  <Email
                    name={`${itemName}.email`}
                    placeholder="Email"
                    compact
                    aria-label="Email"
                    error={!active && !!error}
                  />
                </ArrayCol>
                {!readOnly && (
                  <ArrayCol flex={0}>
                    <Button
                      icon="delete"
                      iconButton
                      size="xs"
                      type="danger"
                      importance="tertiary"
                      onClick={onRemove}
                      compact
                    />
                  </ArrayCol>
                )}
              </ArrayRow>
            )}
          />
        </FieldBox>

        <YesNo.Box label="Enable a landing page for this user" name="visible" required />
      </FormSection>
      <FormSection title="Profile">
        <Input.Box label="Job Title" name="jobTitle" />
        <Input.Box name="license" label="NMLS ID" />
        <USState.Box name="licensedStates" label="Licensed States" multiple />
        <ContentEditor.Box name="bio" label="Bio" mode="micro" />
        <Url.Box label="LinkedIn" name="linkedinUrl" />
        <Url.Box label="Twitter" name="twitterUrl" />
        <Url.Box label="Facebook" name="facebookUrl" />
      </FormSection>
      <FormSection title="Security">
        {hasFolders ? <AgentModalFoldersSection folders={folders} /> : null}
        <AgentModalAuthRoles />
      </FormSection>
      <AgentModalSitesSection agentId={agentId} />
      <FormSection title="Metadata">
        <AgentModalMetadata readOnly={readOnly} />
      </FormSection>
    </ModalForm>
  );
};
