import { type ReactNode } from 'react';
import styled from 'styled-components';

import { type IBulkCustomTask } from '../../types';
import { TasksArrayListItem } from './tasks-array-list-item';

interface ITasksArrayListProps {
  'aria-labelledby'?: string;
  'aria-describedby'?: string;
  'aria-label'?: string;
  values: IBulkCustomTask[];
  onEdit: (index: number, item: IBulkCustomTask) => void;
  onDelete: (index: number, item: IBulkCustomTask) => void;
  renderItemLabel?: (itemValue: IBulkCustomTask) => ReactNode;
}

const UL = styled.ul`
  display: block;
  padding: 0;
  margin: 0;
`;

const LI = styled.li`
  display: block;
`;

export const TasksArrayList = (props: ITasksArrayListProps) => {
  const {
    values,
    onEdit,
    onDelete,
    'aria-labelledby': ariaLabelledBy,
    'aria-describedby': ariaDescribedBy,
    'aria-label': ariaLabel,
    renderItemLabel,
  } = props;

  const handleEdit = (index: number) => () => {
    const itemValue = values[index]!;
    onEdit(index, itemValue);
  };

  const handleDelete = (index: number) => () => {
    if (!onDelete) {
      return;
    }

    const itemValue = values[index]!;
    onDelete(index, itemValue);
  };

  return (
    <UL
      role="group"
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
      aria-label={ariaLabel}
    >
      {values.map((itemValue, index: number) => (
        <LI key={itemValue.id}>
          <TasksArrayListItem
            onEdit={handleEdit(index)}
            onDelete={handleDelete(index)}
            itemValue={itemValue}
            renderItemLabel={renderItemLabel}
          />
        </LI>
      ))}
    </UL>
  );
};
