import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo';
import { type CreateAgentInput } from '../../apollo/graphql';

export const MCreateAgentMutation = graphql(`
  mutation CreateAgent($partnerId: ID!, $data: CreateAgentInput!) {
    createAgent(partnerId: $partnerId, data: $data)
  }
`);

export const useCreateAgent = () => {
  const createAgent = useMutation(MCreateAgentMutation, undefined);

  return useCallback(
    async (partnerId: string, data: CreateAgentInput) => {
      const response = await createAgent({ partnerId, data });
      return response.data!.createAgent;
    },
    [createAgent]
  );
};
