import { type RouteComponentProps } from 'react-router';

import { ApplicationPricing } from './application-pricing';

interface IApplicationPricingParams {
  applicationFileId: string;
}

export const ApplicationPricingRoute = (props: RouteComponentProps<IApplicationPricingParams>) => {
  const { match } = props;
  const { params } = match;
  const { applicationFileId } = params;

  return <ApplicationPricing applicationFileId={applicationFileId} />;
};
