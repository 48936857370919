import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../apollo';

const MTriggerMortgageDirectorPush = graphql(`
  mutation TriggerMortgageDirectorPush($applicationFileId: ID!) {
    triggerMortgageDirectorPush(applicationFileId: $applicationFileId)
  }
`);

export const useTriggerMortgageDirectorPush = () => {
  const fn = useMutation(MTriggerMortgageDirectorPush, undefined);

  return useCallback(
    async (applicationFileId: string) => {
      const response = await fn({ applicationFileId });
      return response.data!.triggerMortgageDirectorPush;
    },
    [fn]
  );
};
