import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo';
import { type UpdateSendDocumentTaskInput } from '../../apollo/graphql';

export const MUpdateSendDocumentTask = graphql(`
  mutation IUpdateSendDocumentTask($taskId: ID!, $data: UpdateSendDocumentTaskInput!) {
    updateSendDocumentTask(taskId: $taskId, data: $data) {
      id
      meta {
        title
      }
      data
      dueDate
    }
  }
`);

export const useUpdateSendDocumentTask = () => {
  const updateSendDocumentTask = useMutation(MUpdateSendDocumentTask, undefined);

  return useCallback(
    async (taskId: string, data: UpdateSendDocumentTaskInput) => {
      const response = await updateSendDocumentTask({
        taskId,
        data,
      });
      return response.data!.updateSendDocumentTask;
    },
    [updateSendDocumentTask]
  );
};
