import { createContext } from 'react';

import { type PermissionType } from './permissions';

export type IUserCan = Record<PermissionType, boolean>;

export interface IPermissionsContext {
  can: IUserCan;
  ready: boolean;
  user: any;
}

export const PermissionsContext = createContext<IPermissionsContext | null>(null);
