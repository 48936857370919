import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../apollo';
import { type CreateApplicationFileInput } from '../../apollo/graphql';

export const MCreateApplicationFileMutation = graphql(`
  mutation CreateApplicationFile($siteId: ID!, $data: CreateApplicationFileInput!) {
    createApplicationFile(siteId: $siteId, data: $data)
  }
`);

export const useCreateApplication = () => {
  const createApplication = useMutation(MCreateApplicationFileMutation);

  return async (siteId: string, data: CreateApplicationFileInput) => {
    const response = await createApplication({ siteId, data });
    return response.data!.createApplicationFile;
  };
};
