import { type IconSize, type ISpacing, type PaletteColor } from '@mortgagehippo/ds';
import { type IBaseTask, type ITaskApplicant } from '@mortgagehippo/tasks';
import { keyBy, uniq } from 'lodash-es';
import { type ReactNode } from 'react';
import styled from 'styled-components';

import { TasksListItem } from './tasks-list-item';

const List = styled.ol`
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
`;

interface ITasksListProps<T extends IBaseTask, A extends ITaskApplicant> {
  tasks: T[];
  applicants: A[];
  to?: (taskId: string, applicantId: string) => string;
  linkColor?: PaletteColor;
  iconSize?: IconSize;
  iconMargin?: ISpacing;
  renderItemDescription?: (task: T) => ReactNode;
  className?: string;
}

export const TasksList = <T extends IBaseTask, A extends ITaskApplicant>(
  props: ITasksListProps<T, A>
) => {
  const {
    tasks,
    applicants,
    to: toFn,
    linkColor,
    iconSize,
    iconMargin,
    renderItemDescription,
    className,
  } = props;

  const showNames = applicants.length > 1;
  const showInitials =
    showNames && uniq(applicants.map((v) => v.firstName)).length < applicants.length;

  const keyedApplicants = keyBy(applicants, (v) => v.id);

  return (
    <List className={className}>
      {tasks.map((task) => {
        const taskApplicant = keyedApplicants[task.primaryApplicantId]! || {};

        const to = toFn ? toFn(task.id, taskApplicant.id) : undefined;

        return (
          <TasksListItem
            key={task.id}
            task={task}
            applicant={taskApplicant}
            showName={showNames}
            showInitial={showInitials}
            to={to}
            linkColor={linkColor}
            iconSize={iconSize}
            iconMargin={iconMargin}
            renderItemDescription={renderItemDescription}
          />
        );
      })}
    </List>
  );
};
