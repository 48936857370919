import {
  Alert,
  CheckboxList,
  type IChoiceOption,
  Radio,
  spacing,
  useFormValue,
} from '@mortgagehippo/ds';
import { toArray, usePrevious } from '@mortgagehippo/util';
import { isArray, without } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { type IApplicationApplicant } from '../../../../pages/application/use-application-file-data';
import { COMMON_APPLICANT_OPTION_VALUE } from '../constants';

const StyledAlert = styled(Alert)`
  margin-bottom: ${spacing(6)};
`;

interface IApplicantChoiceProps {
  name: string;
  applicants: IApplicationApplicant[];
  required?: boolean;
  allowCommon?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  multipleAlert?: boolean;
}

export const ApplicantChoice = (props: IApplicantChoiceProps) => {
  const {
    name,
    applicants,
    required,
    allowCommon,
    disabled,
    multiple = true,
    multipleAlert = true,
  } = props;

  const [values, setValues] = useFormValue<string | string[] | undefined>(name);
  const previousValues = usePrevious(values);

  useEffect(() => {
    const currentValues = toArray(values);
    const prevValues = toArray(previousValues);

    if (!multiple || !allowCommon || !currentValues.length) {
      return;
    }

    const hadCommon = prevValues.some(
      (previousValue) => previousValue === COMMON_APPLICANT_OPTION_VALUE
    );
    const hasCommon = currentValues.some((value) => value === COMMON_APPLICANT_OPTION_VALUE);
    const hasApplicants = currentValues.some((value) => value !== COMMON_APPLICANT_OPTION_VALUE);

    if (hadCommon && hasCommon && hasApplicants) {
      setValues(without(values, COMMON_APPLICANT_OPTION_VALUE));
    } else if (!hadCommon && hasCommon) {
      setValues([COMMON_APPLICANT_OPTION_VALUE]);
    }
  }, [allowCommon, multiple, previousValues, setValues, values]);

  const options: IChoiceOption[] = useMemo(() => {
    const o: IChoiceOption[] = [];

    if (allowCommon && applicants.length > 1) {
      o.push({
        value: COMMON_APPLICANT_OPTION_VALUE,
        label: (
          <>
            <strong>All borrowers</strong> as 1 shared task that either borrower can complete
          </>
        ),
      });
    }

    applicants.forEach((applicant) => {
      o.push({
        value: applicant.id,
        label: (
          <>
            <strong>{applicant.name}</strong> as individual task
          </>
        ),
      });
    });

    return o;
  }, [allowCommon, applicants]);

  const tooltip =
    multiple || allowCommon ? (
      <>
        {allowCommon ? (
          <p>
            If you select &quot;All borrowers&quot;, only 1 task will be added to the application.
            Any of the borrowers will be able to complete it
          </p>
        ) : null}
        {multiple ? (
          <p>
            If you select 1 or more borrowers, then a separate task will be added for each selected
            borrower and assigned to them individually.
          </p>
        ) : null}
      </>
    ) : undefined;

  const borrowersSelected = isArray(values) ? values.length : 1;
  const showAlert = multipleAlert && borrowersSelected > 1;
  const compact = showAlert;

  return (
    <>
      {multiple ? (
        <CheckboxList.Box
          disabled={disabled}
          label="Assign to (select 1 or more)"
          labelTooltip={tooltip}
          name={name}
          options={options}
          required={required}
          multiple
          compact={compact}
        />
      ) : null}
      {!multiple && (
        <Radio.Box
          disabled={disabled}
          label="Assign to"
          labelTooltip={tooltip}
          name={name}
          options={options}
          required={required}
          compact={compact}
        />
      )}
      {showAlert ? (
        <StyledAlert type="info" size="sm">
          {borrowersSelected} separate tasks will be created and assigned individually to the
          selected borrowers
        </StyledAlert>
      ) : null}
    </>
  );
};
