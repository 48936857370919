import { Icon, spacing, Text } from '@mortgagehippo/ds';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-left: ${spacing(3)};

    &:first-child {
      margin-left: 0;
    }
  }
`;

export const CreditCheckReportProcessing = () => (
  <Container>
    <Icon size="xl" name="progress" color="neutral600" />
    <Text
      size="sm"
      variant="secondary"
      cid="pageApplication:tabServices.creditCheck.processing.message"
    >
      The credit report is being processed. You will see the results here when it’s ready.
    </Text>
  </Container>
);
