import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { graphql } from '../../../apollo';
import { usePartnerDomain } from '../../../hooks/use-partner-domain';

export const QAgentShareAssignmentLink = graphql(`
  query AgentShareAssignmentLink($partnerId: ID!, $agentId: ID!) {
    partner(id: $partnerId) {
      id
      agent(id: $agentId) {
        id
        name
        slug

        sites(options: { perPage: 10000 }) {
          items {
            id
            domain
            name
          }
        }
      }
    }
  }
`);

export const useAgentShareAssignmentLink = (
  agentId: string,
  options: IUseApolloQueryOptions = {}
) => {
  const partnerId = usePartnerDomain();

  const [data, loading, , { refetch }] = useQuery(
    QAgentShareAssignmentLink,
    {
      partnerId,
      agentId,
    },
    {
      ...options,
      suspend: false,
      fetchPolicy: 'network-only',
    }
  );

  const handleRefresh = useSafeCallback(async () => refetch({ partnerId, agentId }));
  return [{ data: data?.partner?.agent, loading }, handleRefresh] as const;
};
