import {
  Dropdown,
  fontFamily,
  fontWeight,
  type IButtonProps,
  type IDropdownAction,
  lineHeight,
  MediaBreakpoint,
  palette,
  spacing,
  useCustomizations,
} from '@mortgagehippo/ds';
import { type ReactNode } from 'react';
import styled from 'styled-components';

const StyledDropdown = styled(Dropdown)<{ headerTitleColor?: string }>`
  && .application-header-applicants-dropdown-button {
    margin-left: -${spacing(2)};
    padding: 1px 8px 7px;
    font-family: ${fontFamily('secondary')};
    font-weight: ${fontWeight('semibold', 'secondary')};
    line-height: ${lineHeight('xs')};
    color: ${(p) => p.headerTitleColor || palette('primary50')} !important;
    background: transparent !important;
    border-width: 0;

    svg {
      margin-left: ${spacing(1)};
    }
  }
`;

/*
 * trying to match the styles on the button version so that the elements
 * line up properly.
 */
const NotAButton = styled.span<{ headerTitleColor?: string }>`
  display: inline-block;
  font-family: ${fontFamily('secondary')};
  line-height: ${lineHeight('xs')};
  color: ${(p) => p.headerTitleColor || palette('primary50')} !important;
  padding: 1px 0 7px;

  ${MediaBreakpoint.PHONE} {
    margin-bottom: ${spacing(1)};
  }
`;

const ApplicantName = styled.span`
  font-size: 1.25rem;
  vertical-align: middle;
  font-weight: ${fontWeight('semibold', 'secondary')};
  line-height: ${lineHeight('xs')};
`;

interface IApplicationHeaderApplicantsActionsProps {
  applicantId: string;
  onUpdateApplicantEmail?: (applicantId: string) => void;
  onDeleteApplicant?: (applicant: string) => void;
  children?: ReactNode;
}

const dropdownButtonProps: IButtonProps = {
  size: 'md',
  importance: 'tertiary',
  inverted: true,
  icon: 'down-arrow-small',
  iconLocation: 'right',
  compact: true,
  dimIcon: true,
  className: 'application-header-applicants-dropdown-button',
  align: 'left',
};

export const ApplicationHeaderApplicantsActions = (
  props: IApplicationHeaderApplicantsActionsProps
) => {
  const { applicantId, onUpdateApplicantEmail, onDeleteApplicant, children } = props;

  const customizations = useCustomizations();
  const headerTitleColor = customizations.color('app:application.header.title.color');

  const actions: IDropdownAction[] = [];

  if (onUpdateApplicantEmail) {
    actions.push({
      key: 'update_applicant_email',
      cid: 'update-applicant-email-action:label',
      label: 'Update borrower information',
      onAction: () => {
        onUpdateApplicantEmail(applicantId);
      },
    });
  }

  if (onDeleteApplicant) {
    actions.push({
      key: 'delete',
      cid: 'delete-applicant-action:label',
      label: 'Remove co-borrower',
      onAction: () => {
        onDeleteApplicant(applicantId);
      },
    });
  }

  if (!actions.length) {
    return (
      <NotAButton headerTitleColor={headerTitleColor}>
        <ApplicantName>{children}</ApplicantName>
      </NotAButton>
    );
  }

  return (
    <StyledDropdown
      headerTitleColor={headerTitleColor}
      actions={actions}
      data={applicantId}
      label={children}
      buttonProps={dropdownButtonProps}
    />
  );
};
