import { useMutation } from '@mortgagehippo/apollo-hooks';
import { notifications } from '@mortgagehippo/ds';
import { useCallback } from 'react';

import { MCreateAgentJobFunction, MDeleteAgentJobFunction } from '../queries';
import { UsersModal } from './users-modal';

interface IUsersModalContainerProps {
  siteId: string;
  jobFunctionId?: string;
  title?: string;
  onRequestClose?: () => void;
  isOpen: boolean;
  width?: number;
}

export const UsersModalContainer = (props: IUsersModalContainerProps) => {
  const { siteId, jobFunctionId, onRequestClose, isOpen, ...rest } = props;

  const createAgentJobFunction = useMutation(MCreateAgentJobFunction);
  const deleteAgentJobFunction = useMutation(MDeleteAgentJobFunction);

  const handleAdd = useCallback(
    async (agentId: string) => {
      try {
        if (!jobFunctionId) {
          return;
        }

        await createAgentJobFunction({ jobFunctionId, agentId });

        notifications.success({ message: 'The user was successfully added to the role.' });
      } catch (error) {
        notifications.error({
          message: 'There was an error processing your request, please try again later',
        });
      }
    },
    [jobFunctionId, createAgentJobFunction]
  );

  const handleDelete = useCallback(
    async (agentId: string) => {
      try {
        if (!jobFunctionId) {
          return;
        }

        await deleteAgentJobFunction({
          agentId,
          jobFunctionId,
        });

        notifications.success({ message: 'The user was removed from the role.' });
      } catch (error) {
        notifications.error({
          message: 'There was an error processing your request, please try again later',
        });
      }
    },
    [jobFunctionId, deleteAgentJobFunction]
  );

  return (
    <UsersModal
      siteId={siteId}
      jobFunctionId={jobFunctionId}
      onAdd={handleAdd}
      onDelete={handleDelete}
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      {...rest}
    />
  );
};
