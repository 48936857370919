import { Icon, iconHeight, type IconNames, palette } from '@mortgagehippo/ds';
import { getTaskTypeLabel, TaskType } from '@mortgagehippo/tasks';
import styled from 'styled-components';

const getIconName = (taskType: TaskType): IconNames => {
  switch (taskType) {
    case TaskType.PaymentTask: {
      return 'currency-dollar';
    }
    case TaskType.HellosignTask || TaskType.DocusignTask: {
      return 'sign';
    }
    case TaskType.DocumentSubmissionTask: {
      return 'upload-document';
    }
    default:
      return 'information';
  }
};

const IconCircle = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${palette('white')};
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: calc(${iconHeight('normal')} / 2 * -1);
  margin-left: calc(${iconHeight('normal')} / 2 * -1);
`;

interface ITaskIconProps {
  taskType: TaskType;
}

export const TaskIcon = (props: ITaskIconProps) => {
  const { taskType } = props;

  const name = getIconName(taskType);
  const label = getTaskTypeLabel(taskType);
  const title = label ? `Task Type: ${label}` : undefined;

  return (
    <IconCircle title={title}>
      <StyledIcon name={name} color="primary400" outline />
    </IconCircle>
  );
};
