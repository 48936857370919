import { Form, type FormSubmitHandler, Input, SubmitButton, T } from '@mortgagehippo/ds';
import { useCallback } from 'react';

import { Actions } from '../common';

interface IPasswordTabFormProps {
  onSubmit: FormSubmitHandler;
}

export const PasswordTabForm = (props: IPasswordTabFormProps) => {
  const { onSubmit } = props;

  const handleValidateConfirm = useCallback((_value: any, allValues: any) => {
    const { newPassword, confirmPassword } = allValues;

    if (newPassword !== confirmPassword) {
      return 'New Password and Confirm New Password do not match';
    }

    return undefined;
  }, []);

  return (
    <Form onSubmit={onSubmit}>
      <Input.Box label="Current Password" name="currentPassword" type="password" required />
      <Input.Box label="New Password" name="newPassword" type="password" required />
      <Input.Box
        label="Confirm New Password"
        name="confirmPassword"
        type="password"
        required
        validate={handleValidateConfirm}
      />
      <Actions>
        <SubmitButton importance="primary">
          <T>Save</T>
        </SubmitButton>
      </Actions>
    </Form>
  );
};
