import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../apollo';
import { type SiteTeamTemplatesQuery } from '../apollo/graphql';

const QSiteTeamTemplates = graphql(`
  query SiteTeamTemplates($siteId: ID!) {
    site(id: $siteId) {
      id
      teamTemplates(options: { perPage: 10000, orderBy: { name: "asc" } }) {
        total
        items {
          id
          name
          jobFunctions {
            id
            primary
            name
            agents {
              id
              name
            }
          }
        }
      }
    }
  }
`);

export type ISiteTeamTemplate = NonNullable<
  SiteTeamTemplatesQuery['site']
>['teamTemplates']['items'][0];

export const useSiteTeamTemplates = (
  siteId: string | number,
  options: IUseApolloQueryOptions = {}
) => {
  const [data, loading] = useQuery(
    QSiteTeamTemplates,
    {
      siteId: `${siteId}`,
    },
    { fetchPolicy: 'network-only', ...options }
  );

  const teamTemplates = data?.site?.teamTemplates.items || [];

  return [teamTemplates, loading] as const;
};
