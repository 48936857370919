import {
  Alert,
  Button,
  fontSize,
  fontWeight,
  Format,
  lineHeight,
  MediaBreakpoint,
  notifications,
  palette,
  spacing,
  T,
  useModal,
} from '@mortgagehippo/ds';
import { CreditPullScores } from '@mortgagehippo/tasks';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { CreditPullStatusType } from '../../../../../apollo/graphql';
import { CreditCheckReportModal } from './credit-check-report-modal';
import { CreditCheckReportProcessing } from './credit-check-report-processing';
import { type ICreditCheckPageCreditPull } from './types';
import { downloadPDF, isJointCreditPull } from './util';

const Header = styled.div`
  margin-left: -${spacing(2)};
  margin-bottom: ${spacing(1)};
`;

const Footer = styled.div`
  margin-top: ${spacing(1)};
  text-align: right;

  ${MediaBreakpoint.PHONE} {
    text-align: left;
  }
`;

const Detail = styled.span`
  margin-left: ${spacing(3)};
  font-size: ${fontSize('xs')};
  line-height: ${lineHeight('xs')};
  color: ${palette('neutral600')};

  &:first-child {
    margin-left: 0;
  }
`;

const DetailLabel = styled.span`
  font-weight: ${fontWeight('semibold')};
`;

interface ICreditCheckReportProps {
  applicantId: string;
  applicantName: string;
  creditPull: ICreditCheckPageCreditPull;
  onOrderCreditCheck?: () => void;
  onOrderJointCreditCheck?: () => void;
  onDownloadReport?: (creditPullId: string) => Promise<string | null>;
  onViewReport: (creditPullId: string) => Promise<string | null>;
}

export const CreditCheckReport = (props: ICreditCheckReportProps) => {
  const {
    applicantId,
    applicantName,
    creditPull,
    onOrderCreditCheck,
    onOrderJointCreditCheck,
    onDownloadReport,
    onViewReport,
  } = props;

  const {
    id: creditPullId,
    statusDescription,
    statusCode,
    vendorOrderIdentifier,
    requestedBy,
  } = creditPull;

  const [reportFile, setReportFile] = useState<string | undefined>();
  const [isOpen, openModal, closeModal] = useModal(false);

  const isJoint = isJointCreditPull(creditPull);
  const hasError = statusCode === CreditPullStatusType.Error;

  const applicantScores = creditPull.scores.find((s) => `${s.applicantId}` === applicantId);

  const requesterName = requestedBy?.name;

  const preventDownload = !onDownloadReport;

  const handleDownloadReport = useCallback(async () => {
    if (reportFile) {
      downloadPDF(reportFile);
      return;
    }

    if (!onDownloadReport) {
      return;
    }

    const file = await onDownloadReport(creditPullId);

    if (!file) {
      notifications.error({
        message: 'An error occurred while processing your request. Please try again later.',
      });
      return;
    }

    setReportFile(file);
    downloadPDF(file);
  }, [creditPullId, onDownloadReport, reportFile]);

  const handleViewReport = useCallback(async () => {
    if (reportFile) {
      openModal();
      return;
    }

    const file = await onViewReport(creditPullId);

    if (!file) {
      notifications.error({
        message: 'An error occurred while processing your request. Please try again later.',
      });
      return;
    }

    setReportFile(file);
    openModal();
  }, [creditPullId, onViewReport, openModal, reportFile]);

  useEffect(() => {
    setReportFile(undefined);
  }, [creditPullId]);

  if (statusCode === CreditPullStatusType.InProgress) {
    return <CreditCheckReportProcessing />;
  }

  return (
    <>
      <Header>
        {!!onOrderJointCreditCheck && !!onOrderCreditCheck && (
          <Button
            icon="refresh"
            iconLocation="left"
            compact
            size="xxs"
            importance="tertiary"
            onClick={isJoint ? onOrderJointCreditCheck : onOrderCreditCheck}
          >
            {(isJoint && (
              <T cid="pageApplication:tabServices.creditCheck.results.column.actions.reorderJointButton.label">
                Reorder joint report
              </T>
            )) || (
              <T cid="pageApplication:tabServices.creditCheck.results.column.actions.reorderButton.label">
                Reorder report
              </T>
            )}
          </Button>
        )}

        {isJoint && !!onOrderCreditCheck ? (
          <Button
            compact
            size="xxs"
            importance="tertiary"
            icon="user"
            iconLocation="left"
            onClick={onOrderCreditCheck}
          >
            <T
              cid="pageApplication:tabServices.creditCheck.results.joint.buttonOrderIndividual.label"
              cvars={{ name: applicantName }}
            >
              Order individual report for {applicantName}
            </T>
          </Button>
        ) : null}

        {!hasError && (
          <>
            <Button
              compact
              size="xxs"
              importance="tertiary"
              icon="preview"
              onClick={handleViewReport}
            >
              <T cid="pageApplication:tabServices.creditCheck.results.column.actions.viewButton.label">
                View report
              </T>
            </Button>
            {!preventDownload && (
              <Button
                compact
                size="xxs"
                importance="tertiary"
                icon="download"
                onClick={handleDownloadReport}
              >
                <T cid="pageApplication:tabServices.creditCheck.results.column.actions.downloadButton.label">
                  Download PDF
                </T>
              </Button>
            )}
          </>
        )}
      </Header>

      {hasError ? (
        <Alert>
          {statusDescription || (
            <T cid="pageApplication:tabServices.creditCheck.errors.general.message">
              There was an error processing the credit report.
            </T>
          )}
        </Alert>
      ) : null}

      {applicantScores ? <CreditPullScores score={applicantScores} align="left" /> : null}

      <Footer>
        {requesterName ? (
          <Detail>
            <DetailLabel>
              <T cid="pageApplication:tabServices.creditCheck.results.column.requestedBy.title">
                Requested by
              </T>
              {': '}
            </DetailLabel>
            {requesterName}
          </Detail>
        ) : null}
        <Detail>
          <DetailLabel>
            <T cid="pageApplication:tabServices.creditCheck.results.column.date.title">Date</T>
            {': '}
          </DetailLabel>
          <Format.Date format="date-med-time" value={creditPull.createdAt} />
        </Detail>
        {vendorOrderIdentifier ? (
          <Detail>
            <DetailLabel>
              <T cid="pageApplication:tabServices.creditCheck.results.column.reportId.title">
                Report Id
              </T>
              {': '}
            </DetailLabel>
            {vendorOrderIdentifier}
          </Detail>
        ) : null}
      </Footer>

      <CreditCheckReportModal
        fileContents={reportFile}
        onRequestClose={closeModal}
        isOpen={isOpen}
        preventDownload={preventDownload}
      />
    </>
  );
};
