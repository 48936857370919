import { ForbiddenError } from '@mortgagehippo/auth';
import { useEffect } from 'react';
import { type RouteComponentProps } from 'react-router';

import { useApplicationFileCan } from '$components/permissions';

import { LoadingPage } from '../../../../loading';
import { useDocutechCreateDocumentsUrl } from './use-docutech-create-documents-url';

export const DocutechRedirectPage = (props: RouteComponentProps<{ id: string }>) => {
  const { match } = props;
  const { params } = match;
  const { id: applicationFileId } = params;
  const [can, canReady] = useApplicationFileCan(applicationFileId);
  const url = useDocutechCreateDocumentsUrl(applicationFileId);

  if (canReady && !can.VIEW_DOCUTECH) {
    throw new ForbiddenError();
  }

  useEffect(() => {
    if (url) {
      window.location.href = url;
    }
  }, [url]);

  return <LoadingPage />;
};
