import { ChannelList, useChannelList } from '@mortgagehippo/chat';
import {
  fontWeight,
  Icon,
  MediaBreakpoint,
  NewBadge,
  spacing,
  Tabs,
  useCustomizations,
} from '@mortgagehippo/ds';
import styled from 'styled-components';

import { chatService } from '../../services/chat';

const DEFAULT_ITEM_HEIGHT = 108;
const MIN_ITEMS_SCROLL = 4;

const Container = styled.div`
  min-width: 500px;
  max-width: 500px;

  ${MediaBreakpoint.PHONE} {
    min-width: 90vw;
    max-width: 90vw;
  }
`;

const IconContainer = styled.div`
  display: inline-block;
  position: relative;
  margin-right: ${spacing(2)};
  line-height: 1em;
  vertical-align: middle;
`;

const TabBadgeContainer = styled.span`
  font-weight: ${fontWeight('light')};
`;

const StyledTabs = styled(Tabs)`
  ul {
    flex: 1;
    display: flex;
  }

  li {
    flex: 1;
    display: flex;
    align-items: center !important;
    text-align: center !important;
  }

  .actions,
  .tab-bar:after {
    content: none !important;
    display: none !important;
  }
`;

const EmptyStateContainer = styled.div`
  padding: ${spacing(6)};
  text-align: center;
`;

export interface INotificationsPopoverProps {
  onClick: (applicationFileId: string, channelId: string) => void;
}

export const NotificationsPopover = (props: INotificationsPopoverProps) => {
  const { onClick } = props;

  const customizations = useCustomizations();
  const chatDisabled = customizations.bool('app:chat.disabled', true);
  const notesDisabled = customizations.bool('app:notes.disabled', true);

  const messagesList = useChannelList(
    chatService,
    {
      channelType: 'applicationfile',
    },
    { list: true, unreadCount: true }
  );

  const notesList = useChannelList(
    chatService,
    {
      channelType: 'notes',
    },
    { list: true, unreadCount: true }
  );

  const messagesUnreadCount = messagesList.getUnreadCount();
  const notesUnreadCount = notesList.getUnreadCount();

  const handleClick = (channelId: string) => {
    const [, id] = channelId.split(':');
    onClick(id!, channelId);
  };

  return (
    <Container>
      <StyledTabs size="sm">
        {!chatDisabled && (
          <Tabs.Tab
            id="messages"
            label={
              <>
                <IconContainer>
                  <Icon name="chat" size="normal" outline />
                </IconContainer>
                Messages{' '}
                {messagesUnreadCount ? (
                  <TabBadgeContainer>
                    <NewBadge
                      textSize="xxs"
                      count={messagesUnreadCount}
                      autoPosition={false}
                      bounce={false}
                    />
                  </TabBadgeContainer>
                ) : null}
              </>
            }
          >
            {messagesList.getChannels().length ? (
              <ChannelList
                height={DEFAULT_ITEM_HEIGHT * MIN_ITEMS_SCROLL}
                applyHeightAfterItemCount={MIN_ITEMS_SCROLL}
                service={chatService}
                options={{ channelType: 'applicationfile' }}
                onClick={handleClick}
                type="bubble"
              />
            ) : (
              <EmptyStateContainer>
                <strong>There are no messages yet.</strong>
                <p>
                  When a borrower sends you a new message regarding their application, you will be
                  notified here.
                </p>
              </EmptyStateContainer>
            )}
          </Tabs.Tab>
        )}

        {!notesDisabled && (
          <Tabs.Tab
            id="notes"
            label={
              <>
                <IconContainer>
                  <Icon name="notebook" size="normal" outline />
                </IconContainer>
                Notes{' '}
                {notesUnreadCount ? (
                  <TabBadgeContainer>
                    <NewBadge
                      textSize="xxs"
                      count={messagesUnreadCount}
                      autoPosition={false}
                      bounce={false}
                    />
                  </TabBadgeContainer>
                ) : null}
              </>
            }
          >
            {notesList.getChannels().length ? (
              <ChannelList
                height={DEFAULT_ITEM_HEIGHT * MIN_ITEMS_SCROLL}
                applyHeightAfterItemCount={MIN_ITEMS_SCROLL}
                service={chatService}
                options={{ channelType: 'notes' }}
                onClick={handleClick}
                type="flat"
              />
            ) : (
              <EmptyStateContainer>
                <strong>There are no internal notes yet.</strong>
                <p>
                  When you or somebody else adds a new note to an application you will be notified
                  here
                </p>
              </EmptyStateContainer>
            )}
          </Tabs.Tab>
        )}
      </StyledTabs>
    </Container>
  );
};
