import {
  borderRadius,
  borderRadiuses,
  Button,
  fontFamily,
  fontSize,
  fontWeight,
  type IconNames,
  lineHeight,
  palette,
  shadow,
  spacing,
} from '@mortgagehippo/ds';
import { useSessionStorage } from '@mortgagehippo/util';
import {
  Children,
  isValidElement,
  type MouseEvent,
  type ReactElement,
  type ReactNode,
  useState,
} from 'react';
import styled from 'styled-components';

import { NotificationIcon } from '$components/notification-icon';

const DRAWER_VERTICAL_MARGIN = 0;
const DRAWER_CONTENT_WIDTH = 420;
const DRAWER_EXPANDED_CONTENT_WIDTH = 600;
const DRAWER_MENU_WIDTH = 70;
const DRAWER_TRANSITION_TIME = 250;

export interface IDrawerProps {
  initialId?: string;
  onOpen?: (id: string) => void;
  onClose?: () => void;
  children?: ReactNode;
}

export interface IDrawerItemProps {
  /* eslint-disable react/no-unused-prop-types */
  id: string;
  icon: IconNames;
  label: ReactNode;
  count?: number;
  children?: ReactNode;
  onClick?: () => void;
  /* eslint-enable react/no-unused-prop-types */
}

const DrawerContainer = styled.aside<{ isOpen: boolean }>`
  flex: 0 0 ${DRAWER_MENU_WIDTH}px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

const DrawerBody = styled.div<{
  isOpen: boolean;
  expanded: boolean;
}>`
  flex: 1 1 auto;
  position: relative;
  z-index: 5; // fixes IE display issue
  border-radius: ${borderRadius(3)};
  border-top-right-radius: 0;
  width: ${({ isOpen, expanded }) => {
    if (!isOpen) return DRAWER_MENU_WIDTH;
    return expanded ? '40vw' : `${DRAWER_CONTENT_WIDTH}px`;
  }};

  max-width: ${DRAWER_EXPANDED_CONTENT_WIDTH}px;

  /* width: ${({ isOpen, expanded }) => {
    if (!isOpen) return DRAWER_MENU_WIDTH;
    return (expanded ? DRAWER_EXPANDED_CONTENT_WIDTH : DRAWER_CONTENT_WIDTH) + DRAWER_MENU_WIDTH;
  }}px; */

  transition: width ${DRAWER_TRANSITION_TIME}ms;
`;

const DrawerContent = styled.div<{
  isOpen: boolean;
  expanded: boolean;
}>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: absolute;
  top: ${DRAWER_VERTICAL_MARGIN}px;
  left: 0;
  /* width: ${({ isOpen, expanded }) => {
    if (!isOpen) return DRAWER_MENU_WIDTH;
    return expanded ? DRAWER_EXPANDED_CONTENT_WIDTH : DRAWER_CONTENT_WIDTH;
  }}px; */
  right: 0;
  bottom: ${DRAWER_VERTICAL_MARGIN}px;
  margin-right: ${DRAWER_MENU_WIDTH}px;
  z-index: 0;
  overflow-y: auto;
  background: white;
  border-left: 1px solid ${palette('neutral100')};
  border-right: 1px solid ${palette('neutral100')};
  /* transition: width ${DRAWER_TRANSITION_TIME}ms; */
`;

const DrawerContentHeader = styled.div`
  display: flex;
  align-items: baseline;
  flex: 0 0 auto;
  padding: ${spacing(3)} ${spacing(3)};

  && button {
    display: block;
  }
`;

const DrawerContentHeaderItem = styled.div<{ flex: number }>`
  flex: ${({ flex }) => flex} ${({ flex }) => flex} auto;
`;

const DrawerContentItem = styled.div`
  flex: 1;
`;

const DrawerMenu = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: ${DRAWER_VERTICAL_MARGIN}px;
  right: 0;
  bottom: ${DRAWER_VERTICAL_MARGIN}px;
  width: ${DRAWER_MENU_WIDTH}px;
  z-index: 1;
  display: flex;
  list-style: none;
  margin: 0;
  flex-direction: column;
  height: 100%;
  background: white;
`;

const DrawerMenuItem = styled.div<{ active?: boolean }>`
  position: relative;
  display: block;
  box-sizing: border-box;
  flex: 0 0 auto;
  margin: 0;
  left: ${(p) => (p.active ? '-2px' : '0')};
  padding: ${spacing(3)} ${spacing(1)};
  text-align: center;
  color: ${(p) => (p.active ? palette('neutral900')(p) : palette('neutral600')(p))};
  background-color: ${(p) => (p.active ? 'rgba(255,255,255, 1)' : 'rgba(255,255,255, 0)')};
  box-shadow: ${(p) => (p.active ? shadow(1)(p) : 'none')};
  cursor: pointer;
  clip-path: inset(-20px -20px -20px 0);
  transition:
    left ${DRAWER_TRANSITION_TIME}ms,
    box-shadow ${DRAWER_TRANSITION_TIME}ms;
  border-radius: ${borderRadiuses(0, 2, 2, 0)};

  &:hover,
  &:focus {
    color: ${palette('neutral900')};
  }
`;

const DrawerMenuLabel = styled.div<{ active?: boolean }>`
  font-size: ${fontSize('xs')};
  font-weight: ${(p) => fontWeight(p.active ? 'semibold' : 'light')};
  line-height: 1.2em;
  text-align: center;
  text-decoration: none;
`;

const DrawerTitle = styled.div`
  font-size: ${fontSize('xs')};
  font-family: ${fontFamily('secondary')};
  line-height: ${lineHeight('xs')};
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: ${palette('neutral600')};
  text-align: center;
`;

export const Drawer = (props: IDrawerProps) => {
  const { initialId, onOpen, onClose, children } = props;
  const [menuActiveItemId, setMenuActiveItemId] = useState<string | null>(initialId || null);
  const [isOpen, setIsOpen] = useState(!!menuActiveItemId);
  const [savedExpanded, setSavedExpanded] = useSessionStorage('application.drawer.expanded', null);
  const [expanded, setExpanded] = useState(!!savedExpanded);

  const handleToggle = (nextActiveItem: string | null) => (e: MouseEvent) => {
    e.preventDefault();
    if (nextActiveItem === null) {
      setIsOpen(false);
      setMenuActiveItemId(null);
      if (onClose) onClose();
    } else if (nextActiveItem === menuActiveItemId) {
      setIsOpen(false);
      setMenuActiveItemId(null);
      if (onClose) onClose();
    } else {
      setMenuActiveItemId(nextActiveItem);
      setIsOpen(true);
      if (onOpen) onOpen(nextActiveItem);
    }
  };

  const handleToggleExpanded = () => {
    setExpanded((e) => {
      setSavedExpanded(!e ? '1' : null);
      return !e;
    });
  };

  const items = Children.toArray(children).filter((c) => isValidElement(c)) as Array<
    ReactElement<IDrawerItemProps>
  >;

  const activeItemContent = items.find((item) => item.props.id === menuActiveItemId);

  return (
    <DrawerContainer isOpen={isOpen}>
      <DrawerBody isOpen={isOpen} expanded={expanded}>
        <DrawerContent isOpen={isOpen} expanded={expanded}>
          <DrawerContentHeader>
            <DrawerContentHeaderItem flex={0}>
              <Button
                importance="tertiary"
                type="neutral"
                // icon={expanded ? 'direction-right' : 'direction-left'}
                icon={expanded ? 'minimize-h' : 'maximize-h'}
                size="xxs"
                iconButton
                iconButtonHumble
                iconButtonRound
                compact
                onClick={handleToggleExpanded}
                iconColor="neutral600"
              >
                Expand
              </Button>
            </DrawerContentHeaderItem>
            <DrawerContentHeaderItem flex={1}>
              <DrawerTitle>{activeItemContent?.props.label}</DrawerTitle>
            </DrawerContentHeaderItem>
            <DrawerContentHeaderItem flex={0}>
              <Button
                importance="tertiary"
                type="neutral"
                icon="close"
                size="xxs"
                iconButton
                iconButtonHumble
                iconButtonRound
                compact
                onClick={handleToggle(null)}
                iconColor="neutral600"
              >
                Close
              </Button>
            </DrawerContentHeaderItem>
          </DrawerContentHeader>
          <DrawerContentItem>{activeItemContent}</DrawerContentItem>
        </DrawerContent>
        <DrawerMenu isOpen={isOpen}>
          {items.map((item) => {
            const { id, icon, count, label, onClick } = item.props;
            const active = menuActiveItemId === id;
            return (
              <DrawerMenuItem key={id} active={active} onClick={onClick || handleToggle(id)}>
                {icon ? (
                  <NotificationIcon name={icon} size="lg" count={count} outline={!active} />
                ) : null}
                <DrawerMenuLabel active={active}>{label}</DrawerMenuLabel>
              </DrawerMenuItem>
            );
          })}
        </DrawerMenu>
      </DrawerBody>
    </DrawerContainer>
  );
};

export const DrawerItem = (props: IDrawerItemProps) => {
  const { children } = props;
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
