import { useCustomizations } from '@mortgagehippo/ds';
import { appendScript } from '@mortgagehippo/util';
import { useEffect } from 'react';

export const GlobalScripts = () => {
  const customizations = useCustomizations();
  const script = customizations.text('settings:globalScript');
  const ready = customizations.ready();

  useEffect(() => {
    if (ready && script) {
      appendScript('settings:globalScript', script);
    }
  }, [ready, script]);

  return null;
};
