import { MediaBreakpoint, Spinner } from '@mortgagehippo/ds';
import styled from 'styled-components';

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${MediaBreakpoint.TABLET} {
    top: 100px;
  }
`;

export const ApplicationOverviewSkeleton = () => (
  <StyledSpinner type="spinner1" color="neutral400" size="xxxl" />
);
