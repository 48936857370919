import { Select, useFormValue } from '@mortgagehippo/ds';
import { useCallback, useMemo, useState } from 'react';

import { AgentSelect } from '$components/agent-select/agent-select';
import { PrimaryLabel } from '$components/primary-label';

import { type ISiteTeamTemplate } from '../../../hooks/use-site-team-templates';
import { type FormValues } from './types';
import { type IApplicationFileTeamJobFunction } from './use-application-team';

interface IManageApplicationTeamActionModalFormProps {
  team: IApplicationFileTeamJobFunction[];
  teamTemplates: ISiteTeamTemplate[];
}

export const ManageApplicationTeamActionModalForm = (
  props: IManageApplicationTeamActionModalFormProps
) => {
  const { team, teamTemplates } = props;
  const [selectedTemplate, setSelectedTemplate] = useState<string | undefined>(undefined);
  const [, setJobFunctionsValue] = useFormValue('jobFunctions');

  const handleTemplateChange = useCallback(
    (teamTemplateId: string) => {
      const teamTemplate = teamTemplates.find((t) => t.id === teamTemplateId);

      if (!teamTemplate) {
        return;
      }

      const nextJobFunctionsValue = teamTemplate.jobFunctions.reduce(
        (acum: FormValues['jobFunctions'], jobFunction) => {
          if (jobFunction.primary) {
            // eslint-disable-next-line no-param-reassign
            acum[`id:${jobFunction.id}`] = jobFunction.agents[0]?.id;
          } else {
            // eslint-disable-next-line no-param-reassign
            acum[`id:${jobFunction.id}`] = jobFunction.agents.map((agent) => agent.id);
          }
          return acum;
        },
        {}
      );

      setJobFunctionsValue(nextJobFunctionsValue);
      setSelectedTemplate(teamTemplateId);
    },
    [setJobFunctionsValue, teamTemplates]
  );

  const teamTemplateOptions = useMemo(
    () =>
      teamTemplates.map((teamTemplate) => ({
        label: teamTemplate.name,
        value: teamTemplate.id,
      })),
    [teamTemplates]
  );

  return (
    <>
      {teamTemplateOptions.length > 0 && (
        <div>
          <Select.Input
            name="teamTemplate"
            options={teamTemplateOptions}
            placeholder="Apply Team Template"
            onChange={handleTemplateChange}
            value={selectedTemplate}
          />
        </div>
      )}

      {team.map((jobFunction) => {
        const { id, primary } = jobFunction;
        const label = primary ? <PrimaryLabel>{jobFunction.name}</PrimaryLabel> : jobFunction.name;
        const multiple = !primary;

        return (
          <AgentSelect
            key={`${id}`}
            name={`jobFunctions.id:${id}`}
            jobFunctionId={id}
            label={label}
            multiple={multiple}
          />
        );
      })}
    </>
  );
};
