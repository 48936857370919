import { type ButtonSize, HelpButton } from '@mortgagehippo/ds';
import { type ReactNode } from 'react';
import styled from 'styled-components';

import { type LosType } from '../../../../../apollo/graphql';
import { type ServiceType } from '../../types';
import { LosPushErrorDetails } from './los-push-error-details';

const ContentWrapper = styled.div`
  max-height: 50vh;
  overflow-y: auto;
`;

interface ILosErrorButtonProps {
  type: LosType | ServiceType;
  message: ReactNode;
  details?: ReactNode | null;
  size?: ButtonSize;
  className?: string;
}

export const LosErrorButton = (props: ILosErrorButtonProps) => {
  const { type, message, details, size, className } = props;

  const contentEl = details ? (
    <LosPushErrorDetails details={details} message={message} type={type} compact />
  ) : (
    message
  );

  return (
    <HelpButton
      type="danger"
      icon="error"
      popover={!!details}
      popoverProps={{
        forceAlign: true,
        align: 'LeftMiddle',
        maxWidthCSS: '600px',
        compact: true,
      }}
      size={size}
      className={className}
    >
      <ContentWrapper>{contentEl}</ContentWrapper>
    </HelpButton>
  );
};
