import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { graphql } from '../../../../../../apollo';
import { type ILosPush } from '../types';

export const FMortgageFlexLoanFilePushFragment = graphql(`
  fragment MortgageFlexLoanFilePushFragment on MortgageFlexLoanFilePush {
    id
    pushed
    loanNumber @include(if: $fullResult)
    createdAt @include(if: $fullResult)
    updatedAt @include(if: $fullResult)
    statusCode @include(if: $fullResult)
    statusDescription @include(if: $fullResult)
  }
`);

const QMortgageFlexLosPush = graphql(`
  query MortgageFlexLosPush($applicationFileId: ID!, $fullResult: Boolean!) {
    applicationFile(id: $applicationFileId) {
      id
      mortgageFlexLoanFilePush {
        ...MortgageFlexLoanFilePushFragment
      }
    }
  }
`);

export const useMortgageflexLosPush = (
  applicationFileId: string,
  options: IUseApolloQueryOptions = {}
) => {
  const [data, loading, , { refetch }] = useQuery(
    QMortgageFlexLosPush,
    { applicationFileId, fullResult: true },
    { fetchPolicy: 'network-only', ...options }
  );

  const handleRefresh = useSafeCallback(async () => {
    await refetch({ applicationFileId, fullResult: true });
  });

  let result: ILosPush | null | undefined;

  if (!loading) {
    if (data?.applicationFile) {
      result = data.applicationFile.mortgageFlexLoanFilePush;
    } else {
      result = null;
    }
  }

  return [result, loading, handleRefresh] as const;
};
