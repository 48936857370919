/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  borderRadiuses,
  createLayout,
  createSlot,
  Image,
  type ITemplateProps,
  MediaBreakpoint,
  palette,
  Popover,
  Responsive,
  shadow,
  spacing,
  useCustomizations,
  useResponsive,
} from '@mortgagehippo/ds';
import { useLocalStorage } from '@mortgagehippo/util';
import { useCallback, useContext, useEffect, useRef } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import styled, { createGlobalStyle, css } from 'styled-components';

import { GlobalScripts } from '$components/global-scripts';

import { useFavicon } from '../../hooks/use-favicon';
import { LayoutContext } from '../layout-context';
import { NAV_WIDTH } from './constants';
import { ContentComponent } from './content';
import { DefaultNavigation } from './navigation/default-navigation';
import { TopBar } from './top-bar';

interface IActiveSlots {
  isNavActive?: boolean;
  isContentNavActive?: boolean;
  isSidebarActive?: boolean;
  background?: string;
}

/*
 * The UNIQUE COMMENT below starting with --- XXX --- is required so that styled components creates a new ID that does not conflict with other FUNCTION global styles (this is fixed in v5.0+)
 * https://github.com/styled-components/styled-components/issues/3097
 */
const GlobalStyles = createGlobalStyle`
  // --- MAIN LAYOUT --- 81fed779-2618-4b94-8f7a-85272dbb00b6
${(p) => css`
  body,
  html {
    background: linear-gradient(
      ${p.theme.pageBackground.angle}deg,
      ${p.theme.pageBackground.start} 0%,
      ${p.theme.pageBackground.end} 100%
    ) !important;
    background-attachment: fixed !important;
  }
`}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const BodyComponent = styled('div')<IActiveSlots>`
  position: relative;
  flex: 1 1 auto;
  min-height: 100vh;
  width: 100%;
`;

const NavComponent = styled('nav')<IActiveSlots & { menuOpen: boolean }>`
  width: ${NAV_WIDTH}px;
  padding: ${spacing(4)};
  box-sizing: border-box;
  background: ${(p) => p.background || 'transparent'};
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;

  > * {
    transition: opacity 250ms ease-out;
    opacity: ${(p) => (p.menuOpen ? 1 : 0)};
  }

  ${MediaBreakpoint.PHONE} {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    min-width: 0;
    padding: ${spacing(2)};
    height: auto;
    overflow-y: unset;
    > * {
      opacity: 1;
    }
  }
`;

const LogoContainer = styled('div')<{
  logoHeight?: number;
  logoHeightMobile?: number;
}>`
  padding: ${spacing(2)} 0 ${spacing(6)};
  text-align: center;

  ${MediaBreakpoint.PHONE} {
    flex: 1 1 auto;
    padding: 0 ${spacing(2)};
    text-align: right;
  }

  img {
    max-width: 100%;
    height: ${(p) => (p.logoHeight !== undefined ? `${p.logoHeight}px` : 'auto')};
    object-fit: contain;

    ${MediaBreakpoint.PHONE} {
      height: ${(p) => (p.logoHeightMobile !== undefined ? `${p.logoHeightMobile}px` : 'auto')};
      max-height: ${(p) => (p.logoHeightMobile !== undefined ? `none` : '36px')};
      max-width: 70%;
    }
  }
`;

const StyledPopover = styled(Popover)`
  display: block;
  flex: 0 0 auto;
  margin-left: -${spacing(1)};
  button:not(:hover):not(:disabled):not(:active) svg {
    color: ${palette('white')};
  }
`;

const PopOverContentWrapper = styled('div')<{ background?: string }>`
  ${(p) =>
    css`
    min-width: 200px;
    padding: ${spacing(4)};


    background: linear-gradient(
      ${p.theme.pageBackground.angle}deg,
      ${p.theme.pageBackground.start} 0%,
      ${p.theme.pageBackground.end} 100%
    );
    }

    ${
      p.background &&
      css`
        background: ${p.background};
      `
    }
  `}
`;

const Box = styled('div')<{ menuOpen: boolean }>`
  position: absolute;
  top: 0;
  left: ${(p) => (p.menuOpen ? `${NAV_WIDTH}px` : spacing(2))};
  right: 0;
  height: 100vh;
  border-radius: ${borderRadiuses(1, 0, 0, 1)};
  background: ${palette('neutral50')};
  padding: 0;
  box-shadow: ${shadow(3, 'intense')};
  transition: left 250ms ease-out;
  overflow-y: auto;
  overflow-x: hidden;

  ${MediaBreakpoint.PHONE} {
    height: auto;
    min-height: 100vh;
    position: relative;
    left: 0;
    border-radius: 0;
    overflow: hidden;
  }
`;

/*
 * we need this so that the TopBar can be sticky both vertically and horizontally
 * since the padding interferes.
 */
const BoxPadding = styled.div<{ compact?: boolean }>`
  ${(p) =>
    p.compact
      ? css`
          padding: 0;
        `
      : css`
          padding: ${spacing(6)} ${spacing(5)} ${spacing(5)};
        `}

  width: 100%;
  display: inline-block;

  // for IE11 only since it does not support position: sticky for TopBar component
  @media all and (-ms-high-contrast: none) {
    border-top: 1px solid ${palette('neutral100')};
  }

  ${MediaBreakpoint.PHONE} {
    ${(p) =>
      p.compact
        ? css`
            padding: 0;
          `
        : css`
            padding: ${spacing(3)} ${spacing(2)} ${spacing(5)};
          `}
    display: block;
  }
`;

interface IMyTemplateProps extends ITemplateProps {
  pageTitle?: string;
  compact?: boolean;
}

const Template = (props: IMyTemplateProps) => {
  const { compact, pageTitle } = props;
  const activeSlots: IActiveSlots = {
    isNavActive: true,
  };

  const topBarRef = useRef<HTMLDivElement | null>(null);
  const navRef = useRef<HTMLDivElement | null>(null);
  const responsive = useResponsive();

  const customizations = useCustomizations();
  const logoHeight = customizations.number('app:header.logo.height');
  const logoHeightMobile = customizations.number('app:header.logo.heightMobile');

  const faviconSrc = useFavicon();
  const navBackground = customizations.color('app:header.background');

  const [menuOpenRaw, setMenuOpen] = useLocalStorage('lp:menu-toggle', '1');
  const menuOpen = menuOpenRaw === '1';
  const handleMenuToggle = useCallback(() => {
    setMenuOpen(menuOpen ? '0' : '1');
  }, [menuOpen, setMenuOpen]);

  const { layoutContext, setLayoutContext } = useContext(LayoutContext);

  const handleHeightContext = useCallback(() => {
    let height = 0;
    if (topBarRef.current) {
      height += topBarRef.current.getBoundingClientRect().height;
    }
    if (navRef.current && responsive.PHONE.EXACT_OR_SMALLER) {
      height += navRef.current.getBoundingClientRect().height;
    }
    if (height && height !== layoutContext.headerHeight) {
      setLayoutContext({
        ...layoutContext,
        headerHeight: height,
      });
    }
  }, [layoutContext, responsive.PHONE.EXACT_OR_SMALLER, setLayoutContext]);

  useEffect(() => {
    // TODO: This should be rewritten using useResizeObserver()
    window.addEventListener('resize', handleHeightContext);
    return () => {
      window.removeEventListener('resize', handleHeightContext);
    };
  }, [handleHeightContext]);

  useEffect(() => {
    if (!topBarRef.current || !navRef.current) {
      return;
    }
    handleHeightContext();
  }, [handleHeightContext, navRef]);

  return (
    <>
      <GlobalStyles />
      <Helmet>
        <title>{pageTitle || 'Lender Hub'}</title>
        <link rel="icon" type="image/png" href={faviconSrc} />
      </Helmet>
      <GlobalScripts />
      <Container>
        <BodyComponent {...activeSlots}>
          <Nav.Slot {...activeSlots} background={navBackground} menuOpen={menuOpen} ref={navRef}>
            <LogoContainer logoHeight={logoHeight} logoHeightMobile={logoHeightMobile}>
              <Link to="/dashboard">
                <Image cid="app:header.logo" alt="Revvin" src="/maxwell.svg" />
              </Link>
            </LogoContainer>
            <Responsive>
              <Responsive.Phone>
                <StyledPopover
                  content={
                    <PopOverContentWrapper background={navBackground}>
                      <DefaultNavigation />
                    </PopOverContentWrapper>
                  }
                  buttonProps={{
                    icon: 'menu',
                    iconButton: true,
                    compact: true,
                    importance: 'tertiary',
                    size: 'sm',
                  }}
                  align="BottomLeft"
                  forceAlign
                  borderless
                  compact
                >
                  Main menu
                </StyledPopover>
              </Responsive.Phone>
              <Responsive.Desktop>
                <DefaultNavigation />
              </Responsive.Desktop>
            </Responsive>
          </Nav.Slot>
          <Box menuOpen={menuOpen}>
            <TopBar
              ref={topBarRef}
              menuOpen={menuOpen}
              handleMenuToggle={handleMenuToggle}
              pageTitle={pageTitle}
            />
            <BoxPadding compact={compact}>
              <Content.Slot />
            </BoxPadding>
          </Box>
        </BodyComponent>
      </Container>
    </>
  );
};

export const Layout = createLayout(Template);
export const Nav = createSlot(Layout, NavComponent);
export const Content = createSlot(Layout, ContentComponent);
