import { MenuGroup, MenuItem } from '@mortgagehippo/menu';
import { useCallback } from 'react';

import { ActionType, useDispatchAction } from '$components/actions';

import { SmartviewModelType } from '../../../apollo/graphql';
import { useSmartviews } from '../../../hooks/use-smartviews';

export const SmartviewsMenu = () => {
  const [smartviews, loading] = useSmartviews(SmartviewModelType.ApplicationFile, {
    fetchPolicy: 'cache-first',
  });

  const dispatch = useDispatchAction();

  const handleAddSmartview = useCallback(() => {
    dispatch({
      type: ActionType.CREATE_SMARTVIEW,
      model: SmartviewModelType.ApplicationFile,
    });
  }, [dispatch]);

  if (loading) {
    return <MenuItem label="Smartviews" icon="smartview" />;
  }

  if (!smartviews.length) {
    return <MenuItem label="Smartviews" to="/smartviews" icon="smartview" exact />;
  }

  return (
    <MenuGroup label="Smartviews" compact icon="smartview">
      {smartviews.map((smartview) => {
        const { id, title } = smartview;

        return <MenuItem key={id} label={title} to={`/smartviews/${id}`} exact />;
      })}
      <MenuItem label="Add smartview" icon="plus" onClick={handleAddSmartview} />
    </MenuGroup>
  );
};
