import {
  fontSize,
  MediaBreakpoint,
  palette,
  spacing,
  T,
  useCustomizations,
} from '@mortgagehippo/ds';
import { type ReactNode, useMemo } from 'react';
import styled from 'styled-components';

import { ApplicationStatusDropdown } from '$components/application-status-dropdown';

import { type LosType } from '../../apollo/graphql';

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const Label = styled.span``;

const Value = styled.span``;

const Item = styled('li')<{
  headerTitleColor?: string;
  headerDetailsColor?: string;
}>`
  display: flex;
  align-items: center;
  padding: 0;
  font-size: ${fontSize('xs')};
  line-height: 1.4em;

  ${MediaBreakpoint.PHONE} {
    display: block;
    margin-bottom: ${spacing(2)};
  }

  ${Label},
  ${Value} {
    white-space: nowrap;
    display: inline-block;

    ${MediaBreakpoint.PHONE} {
      display: block;
    }
  }

  ${Label} {
    flex: 0 0 auto;
    color: ${(p) => p.headerDetailsColor || palette('primary200')} !important;
    text-align: right;
    width: 130px;
    font-size: ${fontSize('xxs')};
    text-transform: uppercase;

    ${MediaBreakpoint.PHONE} {
      width: auto;
      text-align: left;
    }
  }

  ${Value} {
    flex: 1 1 auto;
    color: ${(p) => p.headerTitleColor || palette('primary50')} !important;
    margin-left: ${spacing(1)};
    text-overflow: ellipsis;
    max-width: 200px;
    overflow: hidden;

    ${MediaBreakpoint.PHONE} {
      margin-left: 0;
      max-width: 100%;
    }
  }
`;

interface IApplicationHeaderStatusProps {
  applicationFileId: string;
  losType?: LosType | null;
  losNumber?: string | null;
  lenderMilestone?: string | null;
  showApplicationStatus?: boolean;
  disableApplicationStatus?: boolean;
}

interface IStatusItem {
  id: string;
  label: ReactNode;
  value: ReactNode;
}

export const ApplicationHeaderStatus = (props: IApplicationHeaderStatusProps) => {
  const {
    applicationFileId,
    losType,
    losNumber,
    lenderMilestone,
    showApplicationStatus,
    disableApplicationStatus,
  } = props;

  const customizations = useCustomizations();
  const headerTitleColor = customizations.color('app:application.header.title.color');
  const headerDetailsColor = customizations.color('app:application.header.details.color');

  const items = useMemo(() => {
    const result: IStatusItem[] = [];

    if (showApplicationStatus) {
      result.push({
        id: 'status',
        label: <T>Application Status</T>,
        value: (
          <ApplicationStatusDropdown
            applicationFileId={applicationFileId}
            size="xs"
            disabled={disableApplicationStatus}
          />
        ),
      });
    }

    if (losType) {
      result.push({
        id: 'loan_number',
        label: <T>Loan #</T>,
        value: losNumber || <T>not pushed to LOS</T>,
      });
    }

    if (lenderMilestone) {
      result.push({
        id: 'los_milestone',
        label: <T>LOS Milestone</T>,
        value: lenderMilestone || '--',
      });
    }

    return result;
  }, [
    applicationFileId,
    disableApplicationStatus,
    lenderMilestone,
    losNumber,
    losType,
    showApplicationStatus,
  ]);

  if (!items.length) {
    return null;
  }

  return (
    <List>
      {items.map((item) => (
        <Item
          key={item.id}
          headerTitleColor={headerTitleColor}
          headerDetailsColor={headerDetailsColor}
        >
          <Label>{item.label}:</Label>
          <Value>{item.value}</Value>
        </Item>
      ))}
    </List>
  );
};
