import { useQuery } from '@mortgagehippo/apollo-hooks';
import { useMemo } from 'react';

import { graphql } from '../../apollo';
import { type ExportJobsQuery, type ExportJobType } from '../../apollo/graphql';

const QExportJobs = graphql(`
  query ExportJobs($type: ExportJobType!) {
    exportJobs(type: $type, options: { page: 1, perPage: 10, orderBy: { createdAt: "desc" } }) {
      id
      type
      status
      description
      progress
      createdAt
      url
    }
  }
`);

export type IExportJob = ExportJobsQuery['exportJobs'][0];

export const useExportJobs = (type: ExportJobType) => {
  const [data, loading, , { refetch }] = useQuery(
    QExportJobs,
    {
      type,
    },
    {
      fetchPolicy: 'network-only',
    }
  );

  const result: IExportJob[] = useMemo(() => {
    if (!data?.exportJobs) {
      return [];
    }

    return data.exportJobs;
  }, [data]);

  return [result, loading, refetch] as const;
};
