import {
  Alert,
  borderRadius,
  Button,
  fontSize,
  fontWeight,
  Icon,
  type ISelectOption,
  lineHeight,
  palette,
  Select,
  SimpleFieldAutoArray,
  spacing,
  useFormValue,
} from '@mortgagehippo/ds';
import { flatten, keyBy, reduce } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import {
  type ISmartViewColumn,
  type ISmartviewDocumentation,
  type ISmartviewDocumentationField,
} from '$components/smart-view';

const ColumnsList = styled.div`
  margin-top: ${spacing(3)};
`;

const ColumnsListItem = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${palette('neutral200')};
  border-radius: ${borderRadius(2)};
  padding: ${spacing(1)} ${spacing(2)};
  margin-bottom: ${spacing(3)};

  > * {
    flex: 0 0 auto;
  }
`;

const ColumnName = styled.span`
  flex: 1 1 auto;
  padding: 0 ${spacing(1)};
  box-sizing: border-box;
  font-weight: ${fontWeight('bold')};
  font-size: ${fontSize('sm')};
  line-height: ${lineHeight('sm')};
`;

interface ISmartviewModalColumnsProps {
  documentation?: ISmartviewDocumentation;
}

export const SmartviewModalColumns = (props: ISmartviewModalColumnsProps) => {
  const { documentation } = props;
  const { dataFields } = documentation || {};
  const dataFieldsByName = keyBy(dataFields, (f) => f.name);

  const [selectedColumns, setSelectedColumns] = useFormValue<ISmartViewColumn[] | undefined>(
    'config.columns'
  );

  const hasColumns = !!selectedColumns && selectedColumns.length > 0;

  const handleAddColumn = useCallback(
    (nextValue: any) => {
      setSelectedColumns([...(selectedColumns || []), { fields: nextValue }]);
    },
    [selectedColumns, setSelectedColumns]
  );

  const columnOptions = useMemo(() => {
    if (!dataFields) {
      return [];
    }

    const selectedFields = selectedColumns ? flatten(selectedColumns.map((c) => c.fields)) : [];

    return reduce<ISmartviewDocumentationField, ISelectOption[]>(
      dataFields,
      (accum, field) => {
        const { title, name } = field;

        if (!selectedFields.includes(name)) {
          accum.push({
            label: title,
            value: name,
          });
        }
        return accum;
      },
      []
    );
  }, [dataFields, selectedColumns]);

  return (
    <>
      <Select.Input
        name="column"
        options={columnOptions}
        value={undefined}
        onChange={handleAddColumn}
        searchable
        size="sm"
        placeholder="Add column"
        compact
      />
      <ColumnsList>
        {!hasColumns && (
          <Alert size="sm" type="warning">
            At least 1 column is required.
          </Alert>
        )}
        {hasColumns ? (
          <SimpleFieldAutoArray<ISmartViewColumn>
            name="config.columns"
            render={({ value, onRemove, handleProps }) => {
              const { fields } = value || {};

              const fieldName = Array.isArray(fields) ? fields[0]! : fields;
              const dataField = dataFieldsByName[fieldName];

              if (!dataField) {
                return null;
              }

              return (
                <ColumnsListItem {...(handleProps || {})}>
                  <Icon name="drag-handle" size="xs" />
                  <ColumnName>{dataField.title}</ColumnName>
                  <Button
                    icon="close"
                    iconButton
                    size="xxs"
                    importance="tertiary"
                    type="neutral"
                    onClick={onRemove}
                    compact
                  />
                </ColumnsListItem>
              );
            }}
            sortable
            disableAutoGrow
          />
        ) : null}
      </ColumnsList>
    </>
  );
};
