import { useSagaClickHandler } from '@mortgagehippo/tasks';
import { useEffect } from 'react';

import { type IActionProps, type IRefreshUnderwritingConditionsAction } from '../types';
import { useRefreshUnderwritingConditions } from './use-refresh-underwriting-conditions';

export const RefreshUnderwritingConditionsAction = (
  props: IActionProps<IRefreshUnderwritingConditionsAction>
) => {
  const { action, onSubmit, onDone } = props;
  const { applicationFileId } = action;

  const refreshUnderwritingConditions = useRefreshUnderwritingConditions();

  const [handleClick, running] = useSagaClickHandler({
    infoMessage: 'Refreshing underwriting conditions from LOS',
    successMessage: 'Successfully refreshed underwriting conditions',
    errorMessage: 'There was an unexpected error refreshing the underwriting conditions',
    async onClick(setAsyncId) {
      const nextAsyncId = await refreshUnderwritingConditions(applicationFileId);

      setAsyncId(nextAsyncId);

      onSubmit();
    },
    async onDone() {
      onDone();
    },
  });

  useEffect(() => {
    if (running) {
      return;
    }

    (async () => {
      await handleClick();
    })();
  }, [handleClick, running]);

  return null;
};
