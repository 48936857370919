import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo';
import { type UpdateAgentInput } from '../../apollo/graphql';

export const MUpdateAgentMutation = graphql(`
  mutation UpdateAgent($agentId: ID!, $data: UpdateAgentInput!) {
    updateAgent(agentId: $agentId, data: $data)
  }
`);

export const useUpdateAgent = () => {
  const updateAgent = useMutation(MUpdateAgentMutation, undefined);

  return useCallback(
    async (agentId: string, data: UpdateAgentInput) => {
      const response = await updateAgent({ agentId, data });
      return response.data!.updateAgent!;
    },
    [updateAgent]
  );
};
