import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../../apollo';
import { type OpenTaskInput } from '../../../apollo/graphql';

export const MOpenTask = graphql(`
  mutation OpenTask($taskId: ID!, $data: OpenTaskInput) {
    openTask(taskId: $taskId, data: $data) {
      id
      state
      isAlerted
      alertMessage
      dueDate
    }
  }
`);

export const useOpenTask = (taskId: string) => {
  const fn = useMutation(MOpenTask);

  return (data?: OpenTaskInput) =>
    fn({
      taskId,
      data,
    });
};
