import {
  type IModalFormProps,
  ModalForm,
  notifications,
  type UploadHandler,
  UploadInput,
} from '@mortgagehippo/ds';
import {
  ACCEPTED_DOCUMENT_MIME_TYPES,
  isInsecureFileUploadError,
  useScanDocument,
} from '@mortgagehippo/tasks';
import axios, { type AxiosProgressEvent } from 'axios';

import { type IApplicationApplicant } from '../../../../../pages/application/use-application-file-data';
import { useSendDocumentSubmitUrl } from '../../../../../pages/application/use-send-document-submit-url';
import { DefaultFields } from '../default-fields';

interface ISendDocumentTaskEditorProps extends IModalFormProps {
  applicationFileId: string;
  applicants: IApplicationApplicant[];
  showDueDate?: boolean;
}

export const SendDocumentTaskEditor = (props: ISendDocumentTaskEditorProps) => {
  const { applicationFileId, applicants, showDueDate, children, ...rest } = props;

  const getSubmitUrl = useSendDocumentSubmitUrl();
  const scanDocument = useScanDocument();

  const handleUpload: UploadHandler = async (file, meta, progress) => {
    try {
      const { id: uploadId, postUrl } = await getSubmitUrl(applicationFileId, meta.filename);

      await axios.put(postUrl, file, {
        headers: {
          'Content-Type': meta.mime,
        },
        onUploadProgress: (e: AxiosProgressEvent) => {
          if (!e.total) return;
          const percent = Math.round((e.loaded * 100) / e.total);
          progress(percent);
        },
      });

      try {
        await scanDocument(uploadId);
      } catch (e: unknown) {
        if (isInsecureFileUploadError(e)) {
          return { id: uploadId, hasVirus: true };
        }
        throw e;
      }

      return { id: uploadId };
    } catch (e) {
      notifications.error({
        message: 'There was an unexpected error while uploading your file',
      });
      throw e;
    }
  };

  /*
   * attempting to create separate tasks at the same time does not work
   * as a document can only be claimed once and will be claimed by the first task
   * we create. Any subsequent tasks would end up with no document. Until this
   * issue is addressed this must be false.
   */
  const allowMultipleApplicants = false;

  return (
    <ModalForm {...rest}>
      {children}
      <DefaultFields
        applicants={applicants}
        allowCommon
        allowMultipleApplicants={allowMultipleApplicants}
        showDueDate={showDueDate}
      />
      <UploadInput.Box
        name="data.documents"
        label="Documents"
        onUpload={handleUpload}
        required
        descriptionField
        accept={ACCEPTED_DOCUMENT_MIME_TYPES}
        fileTypeDescription="document"
      />
    </ModalForm>
  );
};
