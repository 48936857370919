import { Button, type IButtonProps } from '@mortgagehippo/ds';
import { Children, cloneElement, type ReactElement, useCallback, useContext } from 'react';

import { OverlayContext } from './context';

interface IOverlayCloseProps {
  children: ReactElement;
}

const OverlayCloseContainer = (props: IOverlayCloseProps) => {
  const { children } = props;
  const value = useContext(OverlayContext);
  const { onRequestClose } = value!;
  const child: ReactElement = Children.only(children);
  const childClickHandler = child ? child.props.onClick : undefined;

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (childClickHandler) {
        childClickHandler(e);
      }

      onRequestClose();
    },
    [onRequestClose, childClickHandler]
  );

  if (!child) {
    return null;
  }

  const childProps = {
    ...child.props,
    onClick: handleClick,
  };

  return cloneElement(child, childProps);
};

const OverlayCloseButton = (props: IButtonProps) => {
  const { children, ...rest } = props;

  return (
    <OverlayCloseContainer>
      <Button {...rest} importance="tertiary">
        {children}
      </Button>
    </OverlayCloseContainer>
  );
};

export const OverlayClose = Object.assign(OverlayCloseContainer, {
  Button: OverlayCloseButton,
});
