import {
  FormSection,
  type ISelectGroup,
  type ISelectOption,
  Select,
  type Validator,
} from '@mortgagehippo/ds';
import { useSafeCallback } from '@mortgagehippo/util';
import { useMemo } from 'react';

import { useJobFunctions } from '../../../hooks/use-job-functions';
import { useSiteTeamTemplates } from '../../../hooks/use-site-team-templates';

interface IAgentModalSiteSectionProps {
  siteId: string;
  siteName: string;
  agentId?: string;
}

export const AgentModalSiteSection = (props: IAgentModalSiteSectionProps) => {
  const { siteId, agentId, siteName } = props;

  const [jobFunctions, jobFunctionsLoading] = useJobFunctions(siteId);
  const [teamTemplates, teamTemplatesLoading] = useSiteTeamTemplates(siteId);

  const loading = jobFunctionsLoading || teamTemplatesLoading;

  const jobFunctionsOptions: ISelectOption[] = useMemo(
    () =>
      jobFunctions?.map((t) => ({
        value: t.id,
        label: t.name,
      })) ?? [],
    [jobFunctions]
  );

  const teamTemplateOptions: ISelectGroup[] = useMemo(
    () =>
      teamTemplates.map((t) => {
        const childOptions: ISelectOption[] = t.jobFunctions.map((j) => ({
          selectedLabel: `${t.name} - ${j.name}`,
          label: j.name,
          value: `${t.id}-${j.id}`,
        }));

        return {
          label: t.name,
          options: childOptions,
        };
      }),
    [teamTemplates]
  );

  const handleValidate: Validator = useSafeCallback((value: any, allValues) => {
    if (loading || !Array.isArray(value)) {
      return undefined;
    }

    const jobFunctionsValue = allValues.jobFunctions?.[`site-${siteId}`] || [];

    for (const id of value) {
      const [_templateId, jobFunctionId] = id.split('-');
      if (!jobFunctionsValue.includes(jobFunctionId)) {
        const roleName = jobFunctionsOptions.find((option) => option.value === jobFunctionId);
        return `User doesn't belong to role ${roleName?.label}`;
      }
    }
    return undefined;
  });

  const handleWarn = useSafeCallback((value: any) => {
    if (loading || !Array.isArray(value)) {
      return undefined;
    }

    for (const id of value) {
      const [templateId, jobFunctionId] = id.split('-');
      const teamTemplate = teamTemplates.find((template) => template.id === templateId);
      const jobFunction = teamTemplate?.jobFunctions.find((jf) => jf.id === jobFunctionId);

      if (!teamTemplate || !jobFunction?.primary) continue;

      const templatePrimaryAgent = jobFunction.agents[0];
      const templatePrimaryAgentId = templatePrimaryAgent?.id;

      if (templatePrimaryAgentId !== agentId) {
        return `You have selected "${teamTemplate.name} - ${jobFunction.name}" this will replace the current user "${templatePrimaryAgent?.name}"`;
      }
    }

    return undefined;
  });

  return (
    <FormSection title={siteName}>
      <Select.Box
        name={`jobFunctions.site-${siteId}`}
        label="Roles"
        options={jobFunctionsOptions}
        loading={loading}
        multiple
      />
      <Select.Box
        name={`teamTemplates.site-${siteId}`}
        label="Team Templates"
        options={teamTemplateOptions}
        loading={loading}
        validate={handleValidate}
        warn={handleWarn}
        multiple
      />
    </FormSection>
  );
};
