import { useOpenClose } from '@mortgagehippo/ds';
import { APPLICATION_TASK_TYPES, fromBlueprintTask, type ITask } from '@mortgagehippo/tasks';
import { useCallback, useEffect, useMemo } from 'react';
import { type RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import {
  ActionStatus,
  ActionType,
  type IDeleteApplicantAction,
  useActionEffect,
  useDispatchAction,
} from '$components/actions';
import { useApplicationFileCan } from '$components/permissions';

import { Content, Layout, LAYOUT_DIMENSIONS, Nav } from '../../../layouts/application';
import { analytics } from '../../../services/analytics';
import { useApplicationBlueprint } from '../use-application-blueprint';
import { useApplicationFile } from '../use-application-file-data';
import { ApplicationViewContent } from './application-view-content';
import { ApplicationViewSidebar } from './application-view-sidebar';
import { ApplicationViewSkeleton } from './application-view-skeleton';
import { ApplicationViewState } from './use-application-errors';

const APPLICATION_REFRESH_FIELDS = [
  'first_name',
  'last_name',
  'suffix_name',
  'middle_name',
  'mobile_phone_number',
  'home_phone_number',
  'work_phone_number',
];

const PERMISSIONS_REFRESH_FIELDS = ['purpose_type'];

const ApplicationViewContentWrapper = styled('div')`
  position: relative;
  min-height: 100%;
  max-width: ${LAYOUT_DIMENSIONS.maxContentWidth}px;
`;

interface IApplicationViewParams {
  applicationFileId: string;
  applicantId?: string;
  taskId?: string;
}

export const ApplicationViewRoute = (props: RouteComponentProps<IApplicationViewParams>) => {
  const { match, history } = props;
  const { params } = match;
  const { applicationFileId, applicantId, taskId } = params;

  const [{ applicationFile, applicants }, refreshApplication] =
    useApplicationFile(applicationFileId);
  const { siteId } = applicationFile || {};

  const [applicationBlueprint] = useApplicationBlueprint(siteId!, {
    skip: !siteId,
  });

  const [can, , { refresh: refreshPermissions }] = useApplicationFileCan(applicationFileId);
  const canUpdate = can.UPDATE_TASK && can.UPDATE_ANSWERS;

  const [isMobileNavOpen, openMobileNav, closeMobileNav] = useOpenClose(false);
  const dispatch = useDispatchAction();

  const handleAddApplicant = useCallback(() => {
    closeMobileNav();
    dispatch({
      type: ActionType.ADD_APPLICANT,
      applicationFileId,
    });
  }, [applicationFileId, closeMobileNav, dispatch]);

  const handleDeleteApplicant = useCallback(
    (deleteApplicantId: string) => {
      closeMobileNav();
      dispatch({
        type: ActionType.DELETE_APPLICANT,
        applicationFileId,
        applicantId: deleteApplicantId,
      });
    },
    [applicationFileId, closeMobileNav, dispatch]
  );

  const handleNext = (key: string) => {
    const taskIndex = applicationBlueprint.automated_tasks.findIndex((t: ITask) => t.key === key);
    const nextTask = applicationBlueprint.automated_tasks[taskIndex + 1];

    if (nextTask) {
      history.push(`#/applications/${applicationFileId}/view/${applicantId}/${nextTask.key}`);
    }
  };

  const handleAction = useCallback(
    (action: any) => {
      const { type_name: typeName, payload = {} } = action;

      if (typeName === 'SET_ANSWER') {
        const { key: fieldKey } = payload;

        if (APPLICATION_REFRESH_FIELDS.includes(fieldKey)) {
          // force application refresh
          refreshApplication();
        }

        if (PERMISSIONS_REFRESH_FIELDS.includes(fieldKey)) {
          // force permissions refresh
          refreshPermissions();
        }
      }

      if (typeName === 'SUBMIT_APPLICATION') {
        analytics.event('application', 'submitted', {
          applicationFileId,
        });
      }
    },
    [applicationFileId, refreshApplication, refreshPermissions]
  );

  const task: ITask | null = useMemo(() => {
    if (!applicationBlueprint || !taskId || !applicantId || !applicationFile) {
      return null;
    }

    const automatedTask = applicationBlueprint.automated_tasks.find(
      (t: Record<string, any>) =>
        t.key === taskId && APPLICATION_TASK_TYPES.includes((t as any).type_name)
    );

    if (!automatedTask) {
      return null;
    }

    const result = fromBlueprintTask<ITask>(automatedTask, Number(applicantId));
    const taskFrozen = applicationFile.isFrozen
      ? result.meta.frozenOnApplicationFreeze ?? true
      : false;

    return {
      ...result,
      isFrozen: taskFrozen || !canUpdate,
    };
  }, [applicantId, applicationBlueprint, applicationFile, canUpdate, taskId]);

  // Redirect to the first applicant/task
  useEffect(() => {
    if (applicationFile && applicants && applicationBlueprint && (!applicantId || !taskId)) {
      const applicant = applicants[0];
      const firstTask = applicationBlueprint.automated_tasks[0];
      if (applicant && firstTask) {
        history.replace(
          `#/applications/${applicationFileId}/view/${applicantId || applicant.id}/${firstTask.key}`
        );
      }
    }
  }, [
    applicantId,
    applicants,
    applicationBlueprint,
    applicationFile,
    applicationFileId,
    history,
    taskId,
  ]);

  useActionEffect<IDeleteApplicantAction>(
    async (action) => {
      const { applicationFileId: actionApplicationFileId, applicantId: actionApplicantId } = action;

      if (actionApplicationFileId !== applicationFileId) {
        // this action belongs to another application file ID
        return;
      }

      if (actionApplicantId !== applicantId) {
        return;
      }

      // we just deleted the borrower of the task we were viewing, redirect back to root route
      history.push(`#/applications/${applicationFileId}/view`);
    },
    ActionType.DELETE_APPLICANT,
    ActionStatus.DONE
  );

  if (!applicationFile || !applicationBlueprint || !applicantId || !taskId) {
    return <ApplicationViewSkeleton />;
  }

  return (
    <ApplicationViewState.Provider>
      <Layout applicationFileId={applicationFileId} tutorialId="application">
        <Nav
          width="330px"
          mobileOpen={isMobileNavOpen}
          onMobileOpen={openMobileNav}
          onMobileClose={closeMobileNav}
          mobileNavTitle="Switch Section"
        >
          <ApplicationViewSidebar
            applicationFileId={applicationFileId}
            applicants={applicants}
            applicationBlueprint={applicationBlueprint}
            onAddApplicant={can.ADD_BORROWER ? handleAddApplicant : undefined}
            onDeleteApplicant={can.DELETE_BORROWER ? handleDeleteApplicant : undefined}
          />
        </Nav>
        <Content>
          <ApplicationViewContentWrapper>
            <ApplicationViewContent
              key={taskId}
              applicationFileId={applicationFileId}
              applicantId={applicantId}
              applicants={applicants}
              task={task}
              applicationBlueprint={applicationBlueprint}
              onNext={handleNext}
              onAction={handleAction}
            />
          </ApplicationViewContentWrapper>
        </Content>
      </Layout>
    </ApplicationViewState.Provider>
  );
};
