import { fontWeight, palette } from '@mortgagehippo/ds';
import { type ILiability } from '@mortgagehippo/tasks';
import styled from 'styled-components';

const AccountName = styled.div`
  color: black;
  font-weight: ${fontWeight('light')};
`;

const AccountIdentifier = styled.div`
  color: ${palette('neutral600')};
`;

export interface ICreditLiabilityCreditorColProps {
  liability: ILiability;
}

export const CreditCheckLiabilitiesColCreditor = (props: ICreditLiabilityCreditorColProps) => {
  const { liability } = props;
  const { full_name: fullName, account_identifier: accountIdentifier } = liability;

  return (
    <>
      {fullName ? <AccountName>{fullName}</AccountName> : null}{' '}
      {accountIdentifier ? <AccountIdentifier>Acct# {accountIdentifier}</AccountIdentifier> : null}
    </>
  );
};
