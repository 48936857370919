import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../apollo';
import {
  type SendPreQualificationLetterInput,
  type SendPreQualificationLetterMutation,
} from '../../../apollo/graphql';

export type SendPreQualificationLetterResult =
  SendPreQualificationLetterMutation['sendPreQualificationLetter'];

const MSendPreQualificationLetter = graphql(`
  mutation SendPreQualificationLetter($letterId: ID!, $data: SendPreQualificationLetterInput!) {
    sendPreQualificationLetter(letterId: $letterId, data: $data) {
      id
      applicationFileId
      createdAt
      document {
        id
        filename
        url
        size
        applicationFileId
        applicantId
        task {
          id
          createdAt
        }
      }
    }
  }
`);

export const useSendPreQualificationLetter = (): ((
  letterId: string,
  data: SendPreQualificationLetterInput
) => Promise<SendPreQualificationLetterResult>) => {
  const sendPreQualificationLetter = useMutation(MSendPreQualificationLetter, undefined);

  return useCallback(
    async (letterId: string, data: SendPreQualificationLetterInput) => {
      const response = await sendPreQualificationLetter({
        letterId,
        data,
      });
      return response.data!.sendPreQualificationLetter;
    },
    [sendPreQualificationLetter]
  );
};
