import { type IAction } from '@mortgagehippo/actionable';
import { notifications, useOpenClose } from '@mortgagehippo/ds';
import { toApplicationFileTaskGroups } from '@mortgagehippo/tasks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { type RouteComponentProps } from 'react-router';

import {
  ActionStatus,
  ActionType,
  type IAddApplicantAction,
  type IBulkCreateCustomTaskAction,
  type IDeleteApplicantAction,
  type IDeleteTaskAction,
  type IEditCustomTaskAction,
  type IEditFollowUpTaskAction,
  useActionEffect,
  useDispatchAction,
} from '$components/actions';
import { useApplicationFileCan } from '$components/permissions';

import { Content, Layout, Nav } from '../../../layouts/application';
import { useApplicationFile } from '../use-application-file-data';
import { useApplicationFileTasks } from '../use-application-file-tasks';
import { ApplicationTasksRouteContent } from './application-tasks-route-content';
import { ApplicationTasksSidebar } from './application-tasks-sidebar';
import { ApplicationTasksSkeleton } from './application-tasks-skeleton';
import { useCompleteTask } from './use-complete-task';
import { findNextTask } from './util';

const PERMISSIONS_REFRESH_FIELDS = ['purpose_type'];

interface IApplicationTasksParams {
  taskId?: string;
  applicantId?: string;
  applicationFileId: string;
}

export const ApplicationTasksRoute = (props: RouteComponentProps<IApplicationTasksParams>) => {
  const { match, history } = props;
  const { params } = match;
  const { applicationFileId, taskId, applicantId } = params;
  const ref = useRef<boolean>(false);

  const shouldRefreshPermissions = useRef(false);
  const [taskKeySuffix, setTaskKeySuffix] = useState(0);

  const [{ applicants, loading: applicationFileLoading }, refreshApplicationFile] =
    useApplicationFile(applicationFileId);
  const [{ tasks, blueprint }, tasksLoading, refreshTasks] =
    useApplicationFileTasks(applicationFileId);

  const [can, , { refresh: refreshPermissions }] = useApplicationFileCan(applicationFileId);

  const dispatch = useDispatchAction();

  const completeTask = useCompleteTask({ fetchPolicy: 'no-cache' });

  const loading = applicationFileLoading || tasksLoading;
  const showApplicantName = applicants.length > 1;

  const taskGroups = useMemo(() => {
    const blueprintJson = blueprint?.json || {};

    return toApplicationFileTaskGroups(tasks, blueprintJson);
  }, [blueprint, tasks]);

  const [isMobileNavOpen, openMobileNav, closeMobileNav] = useOpenClose(false);

  const [task, taskApplicant] = useMemo(() => {
    if (!taskId || !applicantId) {
      return [null, null];
    }

    const taskResult = tasks.find(
      (t) => t.id === taskId && `${t.primaryApplicantId}` === applicantId
    );

    if (!taskResult) {
      return [null, null];
    }

    const applicantResult = applicants.find((a) => a.id === `${taskResult.primaryApplicantId}`);

    return [taskResult, applicantResult];
  }, [applicantId, applicants, taskId, tasks]);

  useEffect(() => {
    const tasksLoad = async () => {
      // Redirect to the first  open task or first task if no open tasks
      if (!loading && (!applicantId || !taskId)) {
        ref.current = true;
        const [, nextTasksResult] = await Promise.all([refreshApplicationFile(), refreshTasks()]);
        const nextApplicationFile = nextTasksResult?.data?.applicationFile;
        const nextTasks = nextApplicationFile?.tasks || [];

        const nextTask = findNextTask(nextTasks);
        const firstTask = nextTasks.filter((t) => t.isVisible)[0];

        if (nextTask) {
          history.replace(
            `#/applications/${applicationFileId}/tasks/${nextTask.primaryApplicantId}/${nextTask.id}`
          );
        } else if (firstTask) {
          history.replace(
            `#/applications/${applicationFileId}/tasks/${firstTask.primaryApplicantId}/${firstTask.id}`
          );
        }
        ref.current = false;
      }
    };

    if (ref.current) {
      return undefined;
    }
    tasksLoad();

    return () => {
      ref.current = false;
    };
  }, [
    applicantId,
    applicationFileId,
    history,
    loading,
    taskId,
    tasks,
    refreshApplicationFile,
    refreshTasks,
  ]);

  useEffect(() => {
    shouldRefreshPermissions.current = false;
  }, [taskId]);

  useActionEffect<IEditCustomTaskAction | IEditFollowUpTaskAction>(
    async (action) => {
      const { taskId: actionTaskId, type } = action;

      if (
        (type !== ActionType.EDIT_CUSTOM_TASK && type !== ActionType.EDIT_FOLLOW_UP_TASK) ||
        actionTaskId !== taskId
      ) {
        return;
      }

      /*
       * force content to reload so we get updated task data (needed for things like making the documents update
       * since they are no longer on task data)
       */
      setTaskKeySuffix((prevVal) => prevVal + 1);
    },
    undefined,
    ActionStatus.DONE
  );

  useActionEffect<IDeleteTaskAction>(
    async (action) => {
      const { applicationFileId: actionApplicationFileId } = action;

      if (actionApplicationFileId !== applicationFileId) {
        // this action belongs to another application file ID
        return;
      }

      const [, nextTasksResult] = await Promise.all([refreshApplicationFile(), refreshTasks()]);

      const nextApplicationFile = nextTasksResult?.data?.applicationFile;
      const nextTasks = nextApplicationFile?.tasks || [];
      const nextBlueprint = nextApplicationFile?.blueprint;

      const currentTaskId = task?.id || undefined;
      const nextTask = findNextTask(nextTasks, nextBlueprint, currentTaskId);
      const firstTask = nextTasks.filter((t) => t.isVisible)[0];

      // redirect to next task
      if (nextTask) {
        history.push(
          `#/applications/${applicationFileId}/tasks/${nextTask.primaryApplicantId}/${nextTask.id}`
        );
      }
      if (!nextTask && firstTask) {
        history.push(
          `#/applications/${applicationFileId}/tasks/${firstTask.primaryApplicantId}/${firstTask.id}`
        );
      }
    },
    ActionType.DELETE_TASK,
    ActionStatus.DONE
  );

  useActionEffect<IAddApplicantAction>(
    async (action) => {
      const { applicationFileId: actionApplicationFileId } = action;

      if (actionApplicationFileId !== applicationFileId) {
        // this action belongs to another application file ID
        return;
      }

      await refreshTasks();
    },
    ActionType.ADD_APPLICANT,
    ActionStatus.DONE
  );

  useActionEffect<IDeleteApplicantAction>(
    async (action) => {
      const { applicationFileId: actionApplicationFileId } = action;

      if (actionApplicationFileId !== applicationFileId) {
        // this action belongs to another application file ID
        return;
      }

      const nextTasksResult = await refreshTasks();
      const nextApplicationFile = nextTasksResult?.data?.applicationFile;
      const nextTasks = nextApplicationFile?.tasks || [];
      const currentTask =
        !!taskId && !!applicantId
          ? nextTasks.find((t) => t.id === taskId && `${t.primaryApplicantId}` === applicantId)
          : undefined;

      if (!currentTask) {
        history.push(`#/applications/${applicationFileId}/tasks`);
      }
    },
    ActionType.DELETE_APPLICANT,
    ActionStatus.DONE
  );

  useActionEffect<IBulkCreateCustomTaskAction>(
    async (action) => {
      const { applicationFileId: actionApplicationFileId } = action;

      if (actionApplicationFileId !== applicationFileId) {
        // this action belongs to another application file ID
        return;
      }

      await refreshTasks();
    },
    ActionType.BULK_CREATE_CUSTOM_TASK,
    ActionStatus.DONE
  );

  const handleCreate = useCallback(() => {
    closeMobileNav();
    dispatch({
      type: ActionType.BULK_CREATE_CUSTOM_TASK,
      applicationFileId,
    });
  }, [applicationFileId, closeMobileNav, dispatch]);

  const handleAction = useCallback((action: IAction<string, any, any>) => {
    const { type_name: typeName, payload = {} } = action;
    const { key: fieldKey } = payload;

    if (typeName === 'SET_ANSWER' && PERMISSIONS_REFRESH_FIELDS.includes(fieldKey)) {
      /*
       * we can't refresh the permissions here because saving to the answers
       * won't necessarily have happened yet...
       * TODO: improve this
       */
      shouldRefreshPermissions.current = true;
    }
  }, []);

  const handleComplete = useCallback(async () => {
    if (!taskId) {
      return;
    }

    await completeTask(taskId);

    /*
     * in addition to the tasks an applicant may have been invited or the
     * application file may have been frozen so we have to refresh the
     * application file too
     */
    const [, nextTasksResult] = await Promise.all([refreshApplicationFile(), refreshTasks()]);

    if (shouldRefreshPermissions.current) {
      refreshPermissions();
    }

    const nextApplicationFile = nextTasksResult?.data?.applicationFile;
    const nextTasks = nextApplicationFile?.tasks || [];
    const nextBlueprint = nextApplicationFile?.blueprint;

    const currentTaskId = task?.id || undefined;
    const nextTask = findNextTask(nextTasks, nextBlueprint, currentTaskId);

    if (!nextTask) {
      notifications.success({
        titleCid: 'tasks:notification.allTasksCompleted.title',
        title: 'All Done!',
        messageCid: 'tasks:notification.allTasksCompleted.message',
        message: 'All tasks are now completed.',
      });

      return;
    }

    notifications.success({
      messageCid: 'tasks:notification.taskCompleted.message',
      message: 'Task successfully completed',
    });

    history.push(
      `#/applications/${applicationFileId}/tasks/${nextTask.primaryApplicantId}/${nextTask.id}`
    );
  }, [
    applicationFileId,
    completeTask,
    history,
    refreshApplicationFile,
    refreshPermissions,
    refreshTasks,
    task,
    taskId,
  ]);

  if (loading || !applicantId || !taskId) {
    return <ApplicationTasksSkeleton />;
  }

  if (!task || !taskApplicant) {
    return <ApplicationTasksSkeleton />;
  }

  const key = `${taskId}${taskKeySuffix}`;

  return (
    <Layout applicationFileId={applicationFileId} tutorialId="borrowerPortal">
      <Nav
        width="380px"
        mobileOpen={isMobileNavOpen}
        onMobileOpen={openMobileNav}
        onMobileClose={closeMobileNav}
        mobileNavTitle="Task List"
        mobileNavIcon="tasks"
      >
        <ApplicationTasksSidebar
          applicationFileId={applicationFileId}
          activeTaskId={taskId}
          applicants={applicants}
          taskGroups={taskGroups}
          onCreate={can.CREATE_TASK ? handleCreate : undefined}
        />
      </Nav>
      <Content>
        <ApplicationTasksRouteContent
          key={key}
          task={task}
          taskApplicant={taskApplicant}
          blueprint={blueprint}
          applicationFileId={applicationFileId}
          showApplicantName={showApplicantName}
          onComplete={handleComplete}
          onAction={handleAction}
          readOnly={!can.UPDATE_TASK || !can.UPDATE_ANSWERS}
          preventDownloads={!can.DOWNLOAD_DOCUMENTS}
        />
      </Content>
    </Layout>
  );
};
