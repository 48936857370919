import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo';
import { type CreateApplicationFileApplicantInput } from '../../apollo/graphql';

const MCreateApplicationFileApplicantMutation = graphql(`
  mutation CreateApplicationFileApplicant(
    $applicationFileId: ID!
    $data: CreateApplicationFileApplicantInput!
  ) {
    createApplicationFileApplicant(applicationFileId: $applicationFileId, data: $data)
  }
`);

export const useCreateApplicant = () => {
  const createApplicant = useMutation(MCreateApplicationFileApplicantMutation);

  return useCallback(
    async (applicationFileId: string, data: CreateApplicationFileApplicantInput) => {
      const response = await createApplicant({ applicationFileId, data });
      return response.data!.createApplicationFileApplicant!; // UNSURE: Should this be optional?
    },
    [createApplicant]
  );
};
