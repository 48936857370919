import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../../apollo';

export const MDeleteAgentMutation = graphql(`
  mutation DeleteAgent($agentId: ID!) {
    deleteAgent(agentId: $agentId)
  }
`);

export const useDeleteAgent = () => {
  const deleteAgent = useMutation(MDeleteAgentMutation, undefined);

  return async (agentId: string): Promise<string> => {
    const response = await deleteAgent({
      agentId,
    });
    return response.data!.deleteAgent;
  };
};
