import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useMountedRef } from '@mortgagehippo/util';
import { useEffect, useState } from 'react';

import { graphql } from '../../../../../apollo';
import { type SmartFeesFrameMutation } from '../../../../../apollo/graphql';

const MSmartFeesFrameMutation = graphql(`
  mutation SmartFeesFrame($applicationFileId: ID!) {
    getSmartFeesFrame(applicationFileId: $applicationFileId) {
      statusCode
      statusMessage
      url
      formValues
    }
  }
`);

type SmartFeesIFrame = SmartFeesFrameMutation['getSmartFeesFrame'];

export const useSmartFeesFrame = (applicationFileId: string) => {
  const fn = useMutation(MSmartFeesFrameMutation);
  const mountedRef = useMountedRef();
  const [value, setValue] = useState<SmartFeesIFrame | undefined>(undefined);

  useEffect(() => {
    let cancel = false;

    (async () => {
      const nextValue = await fn({ applicationFileId });
      if (!cancel && mountedRef.current) {
        setValue(nextValue.data!.getSmartFeesFrame);
      }
    })();

    return () => {
      cancel = true;
    };
  }, [applicationFileId, fn, mountedRef]);

  return value;
};
