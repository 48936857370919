import { type IModalFormProps, ModalForm } from '@mortgagehippo/ds';

import { type IApplicationApplicant } from '../../../../../pages/application/use-application-file-data';
import { DefaultFields } from '../default-fields';

const DEFAULT_VALUES = {
  title: 'Your Assets',
};

interface IAssetsTaskEditorProps extends IModalFormProps {
  applicants: IApplicationApplicant[];
  showDueDate?: boolean;
}

export const AssetsTaskEditor = (props: IAssetsTaskEditorProps) => {
  const { applicants, showDueDate, children, ...rest } = props;

  return (
    <ModalForm {...rest}>
      {children}
      <DefaultFields
        applicants={applicants}
        initialValues={DEFAULT_VALUES}
        showDueDate={showDueDate}
      />
    </ModalForm>
  );
};
