import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo';
import { type UpdateDocumentSubmissionTaskInput } from '../../apollo/graphql';

export const MUpdateDocumentSubmissionTask = graphql(`
  mutation IUpdateDocumentSubmissionTask($taskId: ID!, $data: UpdateDocumentSubmissionTaskInput!) {
    updateDocumentSubmissionTask(taskId: $taskId, data: $data) {
      id
      meta {
        title
      }
      data
      dueDate
    }
  }
`);

export const useUpdateDocumentSubmissionTask = () => {
  const updateDocumentSubmissionTask = useMutation(MUpdateDocumentSubmissionTask, undefined);

  return useCallback(
    async (taskId: string, data: UpdateDocumentSubmissionTaskInput) => {
      const response = await updateDocumentSubmissionTask({
        taskId,
        data,
      });
      return response.data!.updateDocumentSubmissionTask;
    },
    [updateDocumentSubmissionTask]
  );
};
