import { useCustomizations, useTheme } from '@mortgagehippo/ds';
import { Menu, MenuItem } from '@mortgagehippo/menu';
import { withRouter } from 'react-router-dom';

import { useUserCan } from '$components/permissions';

import { SmartviewsMenu } from './smartviews-menu';

export const DefaultNavigationWithRouter = () => {
  const [can] = useUserCan();

  const theme = useTheme();
  const customizations = useCustomizations();
  const activeColor = customizations.color(
    'app:header.nav.item.active.color',
    theme.palette.primary900
  );
  const activeBackground = customizations.color(
    'app:header.nav.item.active.background',
    theme.palette.primary50
  );
  const darkBackground = customizations.bool('app:header.nav.darkBackground', true);

  return (
    <Menu dark={darkBackground} activeColor={activeColor} activeBackground={activeBackground}>
      <MenuItem label="Pipeline" to="/dashboard" icon="pipeline" />

      {can.VIEW_SMARTVIEWS ? <SmartviewsMenu /> : null}

      {can.VIEW_USERS_MANAGEMENT_PAGE ? (
        <MenuItem label="User Management" to="/users" icon="team" />
      ) : null}
      {can.EXPORT_CSV || can.EXPORT_DOCUMENTS ? (
        <MenuItem label="Exports" to="/exports" icon="download" />
      ) : null}
      {can.VIEW_REPORTING_DASHBOARD_PAGE ? (
        <MenuItem label="Reports" to="/reports" icon="report" />
      ) : null}
      {can.VIEW_ARCHIVE_PAGE ? <MenuItem label="Archive" to="/archive" icon="archive" /> : null}
    </Menu>
  );
};

export const DefaultNavigation = withRouter(DefaultNavigationWithRouter);
