import { ModalDialog, useCustomizations, useModal } from '@mortgagehippo/ds';
import { useSagaClickHandler } from '@mortgagehippo/tasks';
import { useCallback } from 'react';

import { type IActionProps, type IPushToInternalSystemAction } from '../types';
import { useTriggerInternalSystemPush } from './use-trigger-internal-system-push';

export const PushToInternalSystemAction = (props: IActionProps<IPushToInternalSystemAction>) => {
  const { action, onSubmit, onDone, onCancel } = props;
  const { applicationFileId } = action;

  const [isOpen, , closeModal] = useModal(true);

  const customizations = useCustomizations();
  const title = customizations.text('actions:pushToInternalSystem.confirmTitle', 'Are you sure?');
  const explanation = customizations.text(
    'actions:pushToInternalSystem.confirmExplanation',
    'You are about to push the application to your internal system.'
  );

  const triggerInternalSystemPush = useTriggerInternalSystemPush();

  const [handleClick, running] = useSagaClickHandler({
    infoMessageCid: 'actions:pushToInternalSystem.info',
    successMessageCid: 'actions:pushToInternalSystem.success',
    errorMessageCid: 'actions:pushToInternalSystemAction.error',
    infoMessage: 'Pushing to internal system',
    successMessage: 'Successfully pushed to internal system',
    errorMessage: 'There was an unexpected error pushing to internal system',
    async onClick(setAsyncId) {
      const asyncId = await triggerInternalSystemPush(applicationFileId);
      closeModal(() => {
        setAsyncId(asyncId);
        onSubmit();
      });
    },
    async onDone() {
      closeModal(onDone);
    },
  });

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  if (running) return null;

  // TODO: use hook useModalConfirm
  return (
    <ModalDialog
      type="dialog"
      title={title}
      onOk={handleClick}
      onRequestClose={handleCloseModal}
      isOpen={isOpen}
    >
      {explanation}
    </ModalDialog>
  );
};
