import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../apollo';
import { type ExportSettings } from '../apollo/graphql';

const MRequestApplicationFilesExport = graphql(`
  mutation RequestApplicationFilesExport(
    $partnerId: ID!
    $smartviewId: ID
    $settings: ExportSettings
  ) {
    requestApplicationFilesExport(
      partnerId: $partnerId
      smartviewId: $smartviewId
      settings: $settings
    )
  }
`);

export const useRequestApplicationFilesExport = () => {
  const requestApplicationFilesExport = useMutation(MRequestApplicationFilesExport);

  return useCallback(
    async (
      partnerId: string,
      smartviewId?: string,
      settings?: ExportSettings
    ): Promise<string | null> => {
      const response = await requestApplicationFilesExport({
        partnerId,
        smartviewId,
        settings,
      });

      return response.data!.requestApplicationFilesExport;
    },
    [requestApplicationFilesExport]
  );
};
