import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../../apollo';
import {
  type CreateHellosignUnclaimedDraftInput,
  type CreateHellosignUnclaimedDraftMutation,
  type CreateHellosignUnclaimedDraftMutationVariables,
} from '../../../../apollo/graphql';

export const MCreateHellosignUnclaimedDraft = graphql(`
  mutation CreateHellosignUnclaimedDraft(
    $applicationFileId: ID!
    $data: CreateHellosignUnclaimedDraftInput!
  ) {
    helloSign {
      createUnclaimedDraft(applicationFileId: $applicationFileId, data: $data) {
        signatureRequestId
        claimUrl
        clientId
      }
    }
  }
`);

export const useCreateHellosignUnclaimedDraft = () => {
  const createUnclaimedDraft = useMutation<
    CreateHellosignUnclaimedDraftMutation,
    CreateHellosignUnclaimedDraftMutationVariables
  >(MCreateHellosignUnclaimedDraft, undefined);

  return useCallback(
    async (applicationFileId: string, data: CreateHellosignUnclaimedDraftInput) => {
      const result = await createUnclaimedDraft({ applicationFileId, data });
      return result.data!.helloSign.createUnclaimedDraft;
    },
    [createUnclaimedDraft]
  );
};
