import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo';
import { type UpdateAssetsTaskInput } from '../../apollo/graphql';

export const MUpdateAssetsTask = graphql(`
  mutation UpdateAssetsTask($taskId: ID!, $data: UpdateAssetsTaskInput!) {
    updateAssetsTask(taskId: $taskId, data: $data) {
      id
      meta {
        title
      }
      data
      dueDate
    }
  }
`);

export const useUpdateAssetsTask = () => {
  const updateAssetsTask = useMutation(MUpdateAssetsTask, undefined);

  return useCallback(
    async (taskId: string, data: UpdateAssetsTaskInput) => {
      const response = await updateAssetsTask({
        taskId,
        data,
      });

      return response.data!.updateAssetsTask;
    },
    [updateAssetsTask]
  );
};
