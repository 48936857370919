import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../apollo';

const MTriggerByteDocumentPush = graphql(`
  mutation TriggerByteDocumentPush($applicationFileId: ID!, $documentId: ID!) {
    triggerByteDocumentPush(applicationFileId: $applicationFileId, documentId: $documentId)
  }
`);

export const useTriggerByteDocumentPush = () => {
  const fn = useMutation(MTriggerByteDocumentPush, undefined);

  return useCallback(
    async (applicationFileId: string, documentId: string) => {
      const response = await fn({ applicationFileId, documentId });
      return response.data!.triggerByteDocumentPush;
    },
    [fn]
  );
};
