import { notifications, useCustomizations } from '@mortgagehippo/ds';
import { TaskType } from '@mortgagehippo/tasks';
import { useEffect } from 'react';

import { useApplicationFile } from '../../../pages/application/use-application-file-data';
import { CustomAssetsTask } from './custom-assets-task';
import { CustomDocusignTask } from './custom-docusign-task';
import { CustomHellosignTask } from './custom-hellosign-task';
import { CustomInformationTask } from './custom-information-task';
import { CustomPaymentTask } from './custom-payment-task';
import { CustomRequestDocumentTask } from './custom-request-document-task';
import { CustomSendDocumentTask } from './custom-send-document-task';

interface ICustomTaskProps {
  taskType: TaskType;
  applicationFileId: string;
  taskId: string;
  onDone: () => void;
  onCancel: () => void;
}

export const CustomTask = (props: ICustomTaskProps) => {
  const { taskType, taskId, applicationFileId, onDone, onCancel } = props;

  const [{ applicationFile, applicants, loading }] = useApplicationFile(applicationFileId);
  const customizations = useCustomizations();

  const dueDateDisabled = customizations.bool('pageApplication:tabTasks.dueDates.disabled', true);
  const customizationsReady = customizations.ready();
  const showDueDate = !dueDateDisabled;

  useEffect(() => {
    if (!loading && !applicationFile) {
      notifications.error({
        messageCid: 'custom_task:message.load.error',
        message: 'There was an unexpected error loading the application file.',
      });
      onCancel();
    }
  }, [applicationFile, loading, onCancel]);

  if (loading || !applicationFile || !customizationsReady) {
    return null;
  }

  const { siteId } = applicationFile;

  switch (taskType) {
    case TaskType.DocumentSubmissionTask:
      return (
        <CustomRequestDocumentTask
          siteId={siteId}
          applicationFileId={applicationFileId}
          applicants={applicants}
          taskId={taskId}
          onDone={onDone}
          onCancel={onCancel}
          showDueDate={showDueDate}
        />
      );
    case TaskType.SendDocumentTask:
      return (
        <CustomSendDocumentTask
          siteId={siteId}
          applicationFileId={applicationFileId}
          applicants={applicants}
          taskId={taskId}
          onDone={onDone}
          onCancel={onCancel}
          showDueDate={showDueDate}
        />
      );
    case TaskType.InformationTask: {
      return (
        <CustomInformationTask
          siteId={siteId}
          applicationFileId={applicationFileId}
          applicants={applicants}
          taskId={taskId}
          onDone={onDone}
          onCancel={onCancel}
          showDueDate={showDueDate}
        />
      );
    }
    case TaskType.HellosignTask: {
      return (
        <CustomHellosignTask
          siteId={siteId}
          applicationFileId={applicationFileId}
          applicants={applicants}
          taskId={taskId}
          onDone={onDone}
          onCancel={onCancel}
          showDueDate={showDueDate}
        />
      );
    }
    case TaskType.PaymentTask: {
      return (
        <CustomPaymentTask
          siteId={siteId}
          applicationFileId={applicationFileId}
          applicants={applicants}
          taskId={taskId}
          onDone={onDone}
          onCancel={onCancel}
          showDueDate={showDueDate}
        />
      );
    }
    case TaskType.AssetsTask: {
      return (
        <CustomAssetsTask
          siteId={siteId}
          applicationFileId={applicationFileId}
          applicants={applicants}
          taskId={taskId}
          onDone={onDone}
          onCancel={onCancel}
          showDueDate={showDueDate}
        />
      );
    }
    case TaskType.DocusignTask: {
      return (
        <CustomDocusignTask
          siteId={siteId}
          applicationFileId={applicationFileId}
          applicants={applicants}
          taskId={taskId}
          onDone={onDone}
          onCancel={onCancel}
          showDueDate={showDueDate}
        />
      );
    }
    default: {
      throw new Error(`Unknown custom task type: '${taskType}'`);
    }
  }
};
