import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo';

export const MCreateAgentAvatarUploadUrl = graphql(`
  mutation CreateAgentAvatarUploadUrl($partnerId: ID!, $filename: String!) {
    createAgentAvatarUploadUrl(partnerId: $partnerId, filename: $filename) {
      presignedUrl
      uploadKey
    }
  }
`);

export const useCreateAgentAvatarUploadUrl = () => {
  const createUploadUrl = useMutation(MCreateAgentAvatarUploadUrl, undefined);

  return useCallback(
    async (partnerId: string, filename: string) => {
      const result = await createUploadUrl({ partnerId, filename });
      return result.data!.createAgentAvatarUploadUrl;
    },
    [createUploadUrl]
  );
};
