import { ModalDialog, T, useCustomizations, useModal } from '@mortgagehippo/ds';
import { useSagaClickHandler } from '@mortgagehippo/tasks';
import { useCallback, useEffect } from 'react';

import { useTriggerDuAssessment } from '../../../pages/application/use-trigger-du-assessment';
import { type IActionProps, type IGenerateDUAssessmentAction } from '../types';

export const GenerateDUAssessmentAction = (props: IActionProps<IGenerateDUAssessmentAction>) => {
  const { action, onSubmit, onDone, onCancel } = props;
  const { applicationFileId, confirm } = action;

  const [isOpen, , closeModal] = useModal(true);

  const customizations = useCustomizations();
  const title = customizations.text('generate-du-assessment:confirm-title', 'Are you sure?');
  const explanation = customizations.text(
    'generate-du-assessment:confirm-explanation',
    'You are about to push the application to DU.'
  );

  const triggerDuAssessment = useTriggerDuAssessment();

  const [handleOk, running] = useSagaClickHandler({
    infoMessageCid: 'generate-du-assessment:info',
    successMessageCid: 'generate-du-assessment:success',
    errorMessageCid: 'generate-du-assessment:error',
    infoMessage: 'Pushing to DU',
    successMessage: 'Successfully pushed to DU',
    errorMessage: 'There was an unexpected error pushing to DU',
    async onClick(setAsyncId) {
      const asyncId = await triggerDuAssessment(applicationFileId);

      closeModal(() => {
        setAsyncId(asyncId);
        onSubmit();
      });
    },
    async onDone() {
      onDone();
    },
  });

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  useEffect(() => {
    if (!confirm && !running) {
      handleOk();
    }
  }, [confirm, handleOk, running]);

  if (running || !confirm) return null;

  // TODO: use hook useModalConfirm
  return (
    <ModalDialog
      type="info"
      title={title}
      onOk={handleOk}
      okButtonLabel="Yes"
      onRequestClose={handleCloseModal}
      isOpen={isOpen}
    >
      {explanation} <T>If this requires a separate credit pull, charges will be incurred.</T>
      <br />
      <br />
      <T>Would you like to proceed?</T>
    </ModalDialog>
  );
};
