import {
  fontFamily,
  fontSize,
  fontWeight,
  MediaBreakpoint,
  palette,
  spacing,
} from '@mortgagehippo/ds';
import styled, { css } from 'styled-components';

export const SectionBlock = styled.div<{
  hasBlockValueLabels?: boolean;
  wrap?: boolean;
}>`
  display: flex;
  padding: ${spacing(5)};
  gap: ${spacing(4)};

  ${(p) =>
    p.wrap &&
    css`
      flex-flow: wrap;
    `}

  ${MediaBreakpoint.TABLET} {
    flex-direction: column;
    padding: ${spacing(4)};
  }
`;

export const BlockLabel = styled.div<{ maxWidth?: boolean }>`
  flex: 1 1 auto;
  max-width: ${(p) => (p.maxWidth ? '125px' : 'none')};
  font-family: ${fontFamily('secondary')};
  font-size: ${fontSize('sm')};
  line-height: ${fontSize('md')};
  color: ${palette('neutral500')};
  display: flex;
  flex-direction: column;
  align-items: baseline;

  ${MediaBreakpoint.TABLET} {
    flex: 1 1 100%;
    max-width: 300px;
  }
`;

export const BlockContentContainer = styled.div<{
  textAlign?: 'right' | 'left' | 'center';
  wrap?: boolean;
}>`
  flex: 1 1 auto;
  text-align: ${(p) => (p.textAlign ? p.textAlign : 'left')};
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  column-gap: ${spacing(6)};
  row-gap: ${spacing(4)};
  flex-wrap: ${(p) => (p.wrap ? 'wrap' : 'nowrap')};

  ${MediaBreakpoint.TABLET} {
    text-align: left;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  ${MediaBreakpoint.PHONE} {
    column-gap: ${spacing(4)};
  }
`;

export const BlockSublabel = styled.div`
  font-weight: ${fontWeight('light')};
  color: ${palette('neutral500')};
`;

export const BlockContent = styled.div<{
  textAlign?: 'right' | 'left' | 'center';
}>`
  flex: 0 0 auto;
  text-align: ${(p) => (p.textAlign ? p.textAlign : 'left')};
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const HelpButtonContent = styled.div`
  flex: 0 0 auto;
  padding-left: ${spacing(1)};
  display: inline-flex;
  justify-content: center;
  flex-direction: column;

  ${MediaBreakpoint.TABLET} {
    padding-left: 0;
  }
`;

export const BlockValue = styled.div<{ fontSize?: 'md' | 'normal' }>`
  font-size: ${fontSize('lg')};
  line-height: ${fontSize('lg')};
  font-weight: ${fontWeight('bold')};

  ${(p) =>
    (p.fontSize &&
      css`
        font-size: ${fontSize(p.fontSize)};
        line-height: ${fontSize('md')};
      `) ||
    css`
      ${MediaBreakpoint.PHONE} {
        font-size: ${fontSize('md')};
        line-height: ${fontSize('md')};
      }
    `}
`;

export const BlockValueLabel = styled.div<{ mobileTextAlign?: 'center' | 'left' | 'right' }>`
  font-size: ${fontSize('xxs')};
  line-height: ${fontSize('xs')};
  color: ${palette('neutral500')};
  text-transform: uppercase;
  padding-top: ${spacing(1)};

  ${MediaBreakpoint.TABLET} {
    text-align: ${(p) => (p.mobileTextAlign ? p.mobileTextAlign : 'center')};
  }
`;

export const BlockSubvalue = styled.div`
  font-size: ${fontSize('xxs')};
  line-height: ${fontSize('xxs')};
  font-weight: ${fontWeight('light')};
  color: ${palette('neutral600')};
`;

export const SectionFooter = styled.div`
  text-align: right;
  font-size: ${fontSize('xs')};
  padding: ${spacing(3)} ${spacing(5)};
`;
