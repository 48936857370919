import {
  ButtonRouterLink,
  Card,
  fontFamily,
  fontSize,
  MediaBreakpoint,
  MilestoneList,
  type MilestoneListMilestones,
  palette,
  Progress,
  spacing,
  T,
  useResponsive,
} from '@mortgagehippo/ds';
import { isNil } from 'lodash-es';
import { useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { useApplicationFileCan } from '$components/permissions';

import { ApplicationOverviewContext } from '../../application-overview-context';
import { type IApplicationOverviewSectionProps } from '../../types';
import { BlockLabel, SectionBlock } from '../common';
import { useMilestonesOverviewSection } from './use-milestones-overview-section';

const Flex = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  ${MediaBreakpoint.TABLET} {
    flex-direction: column;
  }
`;

const FlexItem = styled.div<{ flex?: boolean; padded?: boolean }>`
  flex: 1 1 auto;

  ${(p) =>
    p.flex &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    `}

  ${(p) =>
    p.padded &&
    css`
      padding-top: ${spacing(2)};

      ${MediaBreakpoint.TABLET} {
        padding-top: ${spacing(0)};
      }
    `}
  
  &:last-child:not(:first-child) {
    flex: 0 0 auto;
  }
`;

const FlexItemTitle = styled.div`
  font-family: ${fontFamily('secondary')};
  font-size: ${fontSize('xs')};
  line-height: ${fontSize('xs')};
  color: ${palette('neutral500')};
  text-transform: uppercase;

  ${MediaBreakpoint.TABLET} {
    padding-top: ${spacing(5)};
  }
`;

const StyledProgress = styled(Progress)`
  margin-top: ${spacing(1)};
  min-width: 150px;
`;

const StyledButtonRouterLink = styled(ButtonRouterLink)`
  &&& {
    margin-top: ${spacing(5)};
    font-size: ${fontSize('xs')};
    line-height: ${fontSize('xs')};
    text-align: right;
    flex: 0 0 auto;
    margin-left: auto;
  }
`;

export const ApplicationOverviewMilestonesSection = (props: IApplicationOverviewSectionProps) => {
  const { id, applicationFileId, className } = props;

  const responsive = useResponsive();
  const [can, canReady] = useApplicationFileCan(applicationFileId);

  const { sectionReady, sectionDisabled, ready } = useContext(ApplicationOverviewContext)!;

  const [{ milestones, pipelineData }, sectionLoading] =
    useMilestonesOverviewSection(applicationFileId);

  const activeIndex = milestones.findIndex((m) => m.active === true);
  const { percentComplete } = pipelineData || {};

  const isDisabled = !sectionLoading && !milestones.length && isNil(percentComplete);
  const loading = sectionLoading || !canReady;

  useEffect(() => {
    if (loading) {
      return;
    }

    if (isDisabled) {
      sectionDisabled(id);
      return;
    }

    sectionReady(id);
  }, [loading, id, sectionReady, sectionDisabled, isDisabled]);

  if (!ready || isDisabled) {
    return null;
  }

  const milestonesUrl = `#/applications/${applicationFileId}/milestones`;

  return (
    <Card compact className={className}>
      <SectionBlock>
        <BlockLabel maxWidth>
          <T>Borrower Milestones</T>
        </BlockLabel>
        <Flex>
          {!!milestones.length && (
            <FlexItem>
              <MilestoneList
                activeIndex={activeIndex}
                milestones={milestones as MilestoneListMilestones}
                compact={responsive.PHONE.EXACT_OR_SMALLER}
              />
            </FlexItem>
          )}
          <FlexItem flex padded>
            <div>
              {!isNil(percentComplete) && (
                <>
                  <FlexItemTitle>
                    <T>Application Completed</T>
                  </FlexItemTitle>
                  <StyledProgress percent={percentComplete} hideIcons />
                </>
              )}
            </div>
            {can.UPDATE_APPLICATION_ACTIVE_MILESTONE ? (
              <StyledButtonRouterLink
                to={milestonesUrl}
                icon="right-arrow"
                iconLocation="right"
                importance="tertiary"
                size="xs"
                compact
              >
                Manage milestones
              </StyledButtonRouterLink>
            ) : null}
          </FlexItem>
        </Flex>
      </SectionBlock>
    </Card>
  );
};
