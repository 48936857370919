import {
  TaskState,
  toApplicationFileTaskGroups,
} from '@mortgagehippo/tasks';
import { flatten } from 'lodash-es';

import {
  type IApplicationFileBlueprint,
  type IApplicationFileTask,
} from '../use-application-file-tasks';

export const findNextTask = (
  tasks: IApplicationFileTask[],
  blueprint?: IApplicationFileBlueprint | null,
  currentTaskId?: string
): IApplicationFileTask | undefined => {
  const blueprintJson = blueprint?.json || {};
  const taskGroups = toApplicationFileTaskGroups(tasks, blueprintJson); // TaskType is incompatible
  const allTasks: IApplicationFileTask[] = flatten(taskGroups.map((group) => group.tasks || []));

  const taskIndex = allTasks.findIndex((t) => `${t.id}` === `${currentTaskId}`);
  let prevTasks: IApplicationFileTask[] = [];
  let nextTasks: IApplicationFileTask[] = [];

  if (taskIndex < 0) {
    nextTasks = [...allTasks];
  } else {
    prevTasks = allTasks.slice(0, taskIndex);
    nextTasks = allTasks.slice(taskIndex + 1);
  }

  const nextOpenTask = nextTasks.filter((t) => t.state === TaskState.open)[0];
  const prevOpenTask = prevTasks.filter((t) => t.state === TaskState.open)[0];

  if (nextOpenTask) {
    return nextOpenTask;
  }

  if (prevOpenTask) {
    return prevOpenTask;
  }

  return undefined;
};
