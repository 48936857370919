import { Content, Title } from '@mortgagehippo/ds';
import styled from 'styled-components';

import {
  type ApplicationFileActivityQuery,
  type ApplicationFileActivityQueryVariables,
} from '../../../apollo/graphql';
import { ActivityFeed } from '../../../components/activity-feed';
import { Content as LayoutContent, Layout, LAYOUT_DIMENSIONS } from '../../../layouts/application';
import { QApplicationFileActivity } from '../use-application-file-activity';

const Wrapper = styled('div')`
  max-width: ${LAYOUT_DIMENSIONS.extendedMaxContentWidth}px;
`;

interface IApplicationOverviewProps {
  applicationFileId: string;
}

export const ApplicationActivity = (props: IApplicationOverviewProps) => {
  const { applicationFileId } = props;

  return (
    <Layout applicationFileId={applicationFileId}>
      <LayoutContent>
        <Wrapper>
          <Title level={1} cid="pageApplication:tabActivity.title">
            Activity
          </Title>

          <Content cid="pageApplication:tabActivity.description">
            <p>Everything that happened to this application</p>
          </Content>

          <ActivityFeed<ApplicationFileActivityQuery, ApplicationFileActivityQueryVariables>
            query={QApplicationFileActivity}
            dataSource={(result) => result?.applicationFile?.activityFeed || null}
            variables={{
              applicationFileId,
            }}
          />
        </Wrapper>
      </LayoutContent>
    </Layout>
  );
};
