import {
  Avatar,
  Button,
  Form,
  FormSection,
  Input,
  notifications,
  spacing,
  SubmitButton,
  T,
  Url,
} from '@mortgagehippo/ds';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { ContentEditor } from '$components/content-editor';

import type { AgentProfileInput } from '../../../apollo/graphql';
import { useUser } from '../../../hooks/use-user';
import { Actions, Container } from '../common';
import { useUpdateAgentProfile } from '../use-update-agent-profile';
import { AvatarDeleteButton } from './avatar-delete-button';
import { AvatarModalContainer } from './avatar-modal-container';
import { useDeleteAgentAvatar } from './use-delete-agent-avatar';

const AvatarButtons = styled.div`
  margin-top: ${spacing(3)};
`;

export const ProfileTab = () => {
  const [avatarModalOpen, setAvatarModalOpen] = useState(false);

  const [user, userLoading] = useUser();

  const updateAgentProfile = useUpdateAgentProfile();
  const deleteAgentAvatar = useDeleteAgentAvatar();

  const handleFormSubmit = useCallback(
    async (values: any) => {
      try {
        if (!user) {
          return;
        }

        const { name } = user;

        const {
          job_title: jobTitle = null,
          bio = null,
          linkedin_url: linkedinUrl = null,
          twitter_url: twitterUrl = null,
          facebook_url: facebookUrl = null,
        } = values;

        const data: AgentProfileInput = {
          name: name!, // lets just pass this along
          jobTitle,
          bio,
          linkedinUrl,
          twitterUrl,
          facebookUrl,
        };

        await updateAgentProfile(data);

        notifications.success({ message: 'Your profile was updated.' });
      } catch (error) {
        notifications.error({
          message: 'There was an error processing your request, please try again later',
        });
      }
    },
    [updateAgentProfile, user]
  );

  const handleEditAvatar = useCallback(() => {
    setAvatarModalOpen(true);
  }, []);

  const handleDeleteAvatar = useCallback(async () => {
    try {
      if (!user) {
        return;
      }

      await deleteAgentAvatar(user.id);

      notifications.success({ message: 'Your profile was updated.' });
    } catch (error) {
      notifications.error({
        message: 'There was an error processing your request, please try again later',
      });
    }
  }, [deleteAgentAvatar, user]);

  const handleModalClose = useCallback(() => {
    setAvatarModalOpen(false);
  }, []);

  const initialValues = useMemo(() => {
    if (!user) {
      return {};
    }

    const { jobTitle, bio, linkedinUrl, twitterUrl, facebookUrl } = user;

    return {
      job_title: jobTitle,
      bio,
      linkedin_url: linkedinUrl,
      twitter_url: twitterUrl,
      facebook_url: facebookUrl,
    };
  }, [user]);

  if (userLoading || !user) {
    return null;
  }

  return (
    <Container>
      <Form onSubmit={handleFormSubmit} initialValues={initialValues}>
        <FormSection title="Photo">
          <Avatar src={user.avatarUrl} alt={`${user.name}`} size={200} shape="square" />
          <AvatarButtons>
            <Button size="xs" onClick={handleEditAvatar}>
              Change
            </Button>
            {user.avatarUrl ? (
              <AvatarDeleteButton size="xs" type="danger" onClick={handleDeleteAvatar}>
                Delete
              </AvatarDeleteButton>
            ) : null}
          </AvatarButtons>
        </FormSection>
        <FormSection title="Information">
          <Input.Box label="Job Title" name="job_title" />
          <ContentEditor.Box name="bio" label="Bio" mode="micro" />
          <Url.Box label="LinkedIn" name="linkedin_url" />
          <Url.Box label="Twitter" name="twitter_url" />
          <Url.Box label="Facebook" name="facebook_url" />
          <Actions>
            <SubmitButton importance="primary">
              <T>Save</T>
            </SubmitButton>
          </Actions>
        </FormSection>
      </Form>
      <AvatarModalContainer
        agentId={user.id}
        isOpen={avatarModalOpen}
        onRequestClose={handleModalClose}
        onSubmit={handleModalClose}
      />
    </Container>
  );
};
