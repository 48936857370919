import { notifications } from '@mortgagehippo/ds';
import { type ICreditPullScore, useSaveApplicationAnswers } from '@mortgagehippo/tasks';
import { keyBy } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';

import {
  ActionStatus,
  ActionType,
  type ICreditPullAction,
  type IJointCreditPullAction,
  useActionEffect,
  useDispatchAction,
} from '$components/actions';
import { useApplicationFileCan } from '$components/permissions';

import { CreditPullStatusType } from '../../../../../apollo/graphql';
import { useApplicantsCreditPullData } from '../../../use-applicants-credit-pull-data';
import { getCreditPullFile } from '../../../use-credit-pull-file';
import { type IApplicationServicePageProps } from '../../types';
import { CreditCheckPage } from './credit-check-page';
import type { ICreditCheckPageApplicant } from './types';
import { createEmptyCreditPull } from './util';

export const CreditCheckPageContainer = (props: IApplicationServicePageProps) => {
  const { applicationFileId, applicants } = props;
  const [activeApplicantSagas, setActiveApplicantSagas] = useState<string[]>([]);

  const [creditPullData, queryLoading, refresh] = useApplicantsCreditPullData(applicationFileId);

  const [can, canReady] = useApplicationFileCan(applicationFileId);

  const saveAnswers = useSaveApplicationAnswers(applicationFileId);
  const dispatch = useDispatchAction();

  const loading = queryLoading || !canReady;

  useActionEffect<ICreditPullAction>(async (action, status) => {
    const { applicationFileId: actionApplicationFileId, applicantId: actionApplicantId } = action;

    if (actionApplicationFileId !== applicationFileId) {
      // this action belongs to another application file ID
      return;
    }

    if (status === ActionStatus.SUBMITTED) {
      setActiveApplicantSagas([...activeApplicantSagas, actionApplicantId]);
    } else if (status === ActionStatus.DONE) {
      await refresh();

      setActiveApplicantSagas((previousApplicantSagas) =>
        previousApplicantSagas.filter((v) => v !== actionApplicantId)
      );
    }
  }, ActionType.CREDIT_PULL);

  useActionEffect<IJointCreditPullAction>(async (action, status) => {
    const { applicationFileId: actionApplicationFileId } = action;

    if (actionApplicationFileId !== applicationFileId) {
      // this action belongs to another application file ID
      return;
    }

    if (status === ActionStatus.SUBMITTED) {
      setActiveApplicantSagas(applicants.map((a) => a.id));
    } else if (status === ActionStatus.DONE) {
      await refresh();

      setActiveApplicantSagas([]);
    }
  }, ActionType.JOINT_CREDIT_PULL);

  const handleOrderCreditCheck = useCallback(
    (applicantId: string) => {
      dispatch({
        type: ActionType.CREDIT_PULL,
        applicationFileId,
        applicantId,
      });
    },
    [applicationFileId, dispatch]
  );

  const handleOrderJointCreditCheck = useCallback(() => {
    dispatch({
      type: ActionType.JOINT_CREDIT_PULL,
      applicationFileId,
    });
  }, [applicationFileId, dispatch]);

  const handleChangeAnswers = useCallback(
    async (applicantId: string, values: any) => {
      try {
        await saveAnswers(applicantId, values);
        await refresh();
        return true;
      } catch (e) {
        notifications.error({
          message: 'An error occurred while processing your request. Please try again later.',
        });
        return undefined;
      }
    },
    [refresh, saveAnswers]
  );

  const handleDownloadReport = useCallback(
    async (creditPullId: string) => {
      try {
        return await getCreditPullFile(applicationFileId, creditPullId);
      } catch (e) {
        notifications.error({
          message: 'An error occurred while processing your request. Please try again later.',
        });
        return null;
      }
    },
    [applicationFileId]
  );

  const handleViewReport = useCallback(
    async (creditPullId: string) => {
      try {
        return await getCreditPullFile(applicationFileId, creditPullId);
      } catch (e) {
        notifications.error({
          message: 'An error occurred while processing your request. Please try again later.',
        });
        return null;
      }
    },
    [applicationFileId]
  );

  // we want to add the credit pull data to our pre-existing applicants array
  const applicantsWithCreditData: ICreditCheckPageApplicant[] = useMemo(() => {
    const keyedApplicants = keyBy(applicants, (a) => a.id);
    const keyedCreditData = keyBy(creditPullData, (a) => a.id);

    return applicants.map((applicant) => {
      const creditData = keyedCreditData[applicant.id];

      const answers = creditData?.answers || {};
      const creditPulls = creditData?.creditPulls?.items || [];
      const [creditPull] = creditPulls;

      if (activeApplicantSagas.includes(applicant.id)) {
        return {
          ...applicant,
          answers,
          creditPull: createEmptyCreditPull(CreditPullStatusType.InProgress),
        };
      }

      if (!creditPull) {
        return {
          ...applicant,
          answers,
        };
      }

      // we want to add the applicants to the credit scores objects for easy reference.
      const scores: ICreditPullScore[] = creditPull.scores.map((score) => {
        const scoreApplicant = keyedApplicants[`${score.applicantId}`] || null;

        return {
          ...score,
          applicant: scoreApplicant
            ? {
                ...applicant,
                answers,
              }
            : null,
        };
      });

      return {
        ...applicant,
        answers,
        creditPull: {
          ...creditPull,
          scores,
        },
      };
    });
  }, [activeApplicantSagas, applicants, creditPullData]);

  if (loading) {
    return null;
  }

  return (
    <CreditCheckPage
      applicants={applicantsWithCreditData}
      onOrderCreditCheck={can.TRIGGER_CREDIT_PULL ? handleOrderCreditCheck : undefined}
      onOrderJointCreditCheck={can.TRIGGER_CREDIT_PULL ? handleOrderJointCreditCheck : undefined}
      onChangeAnswers={can.UPDATE_ANSWERS ? handleChangeAnswers : undefined}
      onDownloadReport={can.DOWNLOAD_DOCUMENTS ? handleDownloadReport : undefined}
      onViewReport={handleViewReport}
    />
  );
};
