import {
  borderRadius,
  Checkbox,
  createField,
  DatePicker,
  HelpButton,
  type IDateProps,
  type IFieldInputProps,
  palette,
  Select,
  spacing,
  T,
  Tag,
  validateDate,
  validateFromNow as validateFromNowValidator,
} from '@mortgagehippo/ds';
import { isPresent } from '@mortgagehippo/util';
import { isNil } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { DEFAULT_DUE_DATE_DAYS_FROM_NOW, DUE_DATE_PRESETS_OPTIONS } from './constant';
import { calculateDaysFromNow, getDueDateFromToday, getPresetFromDate } from './util';

const pluralize = (word: string, count: number) => (count === 1 ? word : `${word}s`);

const getPresetPlaceholder = (date: string) => {
  const daysFromNow = calculateDaysFromNow(date);

  if (daysFromNow < 0) {
    const absoluteDaysFromNow = Math.abs(daysFromNow);
    return `Due ${absoluteDaysFromNow} ${pluralize('day', absoluteDaysFromNow)} ago`;
  }

  if (daysFromNow === 0) {
    return 'Due today';
  }

  return `Due in ${daysFromNow} ${pluralize('day', daysFromNow)}`;
};

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${palette('neutral50')};
  padding: ${spacing(3)};
  border-radius: ${borderRadius(2)};
  gap: ${spacing(3)};
`;

const Col = styled.div<{ fixed?: boolean }>`
  flex: 1 1 auto;

  &:first-child {
    display: flex;
  }

  ${(p) =>
    p.fixed &&
    css`
      flex: 0 0 auto;
    `}
`;

interface IDueDateInputProps
  extends Pick<IDateProps, 'validateFromNow' | 'validateMin' | 'validateMax'>,
    IFieldInputProps<string | null> {
  defaultDaysFromNow?: number;
}

const DueDateInput = (props: IDueDateInputProps) => {
  const {
    name,
    value,
    onChange,
    disabled,
    size,
    error,
    warning,
    validateFromNow,
    validateMin,
    validateMax,
    defaultDaysFromNow,
  } = props;

  const [hasDueDate, setHasDueDate] = useState(!!value);
  // keep track of the selected date in state, so we can preserve its value when the "has due date" checkbox is unchecked
  const [selectedDate, setSelectedDate] = useState<string | null>(value || null);
  const [selectedPreset, setSelectedPreset] = useState<number | undefined>(
    value ? getPresetFromDate(value) : undefined
  );

  const handleCheckboxChange = useCallback(
    (nextHasDueDate: boolean) => {
      let nextValue = null;

      // if we are turning on the checkbox set the value back to what had previously been entered or force a default
      if (nextHasDueDate) {
        const defaultDueDate = getDueDateFromToday(
          !isNil(defaultDaysFromNow) ? defaultDaysFromNow : DEFAULT_DUE_DATE_DAYS_FROM_NOW
        );
        nextValue = selectedDate || defaultDueDate;
      }

      onChange(nextValue);
    },
    [defaultDaysFromNow, onChange, selectedDate]
  );

  const handleDateChange = useCallback(
    (nextDate: string | undefined) => {
      const nextValue = nextDate || null;
      onChange(nextValue);
    },
    [onChange]
  );

  const handlePresetChange = useCallback(
    (nextPreset: number) => {
      const nextValue = getDueDateFromToday(nextPreset);
      onChange(nextValue);
    },
    [onChange]
  );

  // respond to value changes from outside
  useEffect(() => {
    const nextHasDueDate = !!value;
    const nextPreset = value ? getPresetFromDate(value) : undefined;

    if (value) {
      setSelectedDate(value);
    }

    setSelectedPreset(nextPreset);
    setHasDueDate(nextHasDueDate);
  }, [value]);

  const presetPlaceholder =
    isNil(selectedPreset) && value ? getPresetPlaceholder(value) : undefined;

  return (
    <FieldContainer>
      <Col fixed>
        <Checkbox.Input
          name={`${name}.has_due_date`}
          value={hasDueDate}
          onChange={handleCheckboxChange}
          disabled={disabled}
          size={size}
          compact
          error={error}
          warning={warning}
        >
          <T>Has due date?</T>
        </Checkbox.Input>
      </Col>
      {!hasDueDate && (
        <Col>
          <Tag color="warning" icon="warning" size={size === 'normal' ? 'sm' : 'xs'} compact>
            Borrower will not receive notifications on this task if no due date
          </Tag>
        </Col>
      )}
      {hasDueDate ? (
        <>
          <Col>
            <DatePicker.Input
              name={`${name}.due_date`}
              value={value}
              onChange={handleDateChange}
              disabled={disabled}
              size={size}
              compact
              error={error}
              warning={warning}
              validateFromNow={validateFromNow}
              validateMin={validateMin}
              validateMax={validateMax}
              aria-label="Due date"
            />
          </Col>
          <Col>
            <Select.Input
              name={`${name}.due_date_presets`}
              options={DUE_DATE_PRESETS_OPTIONS}
              onChange={handlePresetChange}
              value={selectedPreset}
              disabled={disabled}
              size={size}
              compact
              error={error}
              warning={warning}
              placeholder={presetPlaceholder}
              aria-label="Due in"
            />
          </Col>
          <Col fixed>
            <HelpButton size="xxs" icon="information" popoverProps={{ align: 'BottomCenter' }}>
              If any task is not completed 24 hours before the due date, the borrower will receive
              an email reminder listing all tasks that are still open.
            </HelpButton>
          </Col>
        </>
      ) : null}
    </FieldContainer>
  );
};

export const DueDate = createField<IDueDateInputProps>(DueDateInput, undefined, {
  validate: async (value, _allValues, props) => {
    const { validateFromNow, validateMin, validateMax } = props;
    if (!isPresent(value)) return undefined;

    const error = validateDate(value, validateMin, validateMax);

    if (!isNil(error)) {
      return error;
    }

    return validateFromNowValidator(value, validateFromNow);
  },
});
