import { type ReactNode } from 'react';

export interface ITutorial {
  title?: string;
  titleCid?: string;
  content: ReactNode;
  contentCid: string;
}

export const Tutorials: Record<string, ITutorial> = {
  borrowerPortal: {
    titleCid: 'tutorials:borrowerPortal.title',
    title: 'The "Borrower Tasks" Tab',
    contentCid: 'tutorials:borrowerPortal.content',
    content: (
      <>
        <p>
          On this tab you see the list of borrower tasks in the same order that the borrower sees
          them. Tasks are action items that must be completed by the borrower. Tasks prompt the
          borrower to enter information, provide documents, sign documents electronically, etc.
        </p>
        <p>
          Some tasks will appear locked, and you will not be able to interact with them. They will
          automatically become open once one or more previous tasks are marked as completed.
        </p>
        <p> Here you can:</p>

        <ul>
          <li>
            Enter information on behalf of the borrower. Every update you make is automatically
            saved.
          </li>
          <li>Create borrower tasks to request or send additional information to the borrower.</li>
          <li>
            Mark tasks as completed. Depending on the task, this completing it may trigger an on
            completion action, for example, running a credit report, or pushing information to your
            LOS.
          </li>
        </ul>
      </>
    ),
  },
  services: {
    titleCid: 'tutorials:services.title',
    title: 'The "Services" Tab',
    contentCid: 'tutorials:services.content',
    content: (
      <>
        <p>
          On this tab you will see a list of third-party service integrations that you can interact
          with regarding this application.{' '}
        </p>
        <p>
          Depending on the enabled services, you may be able to run and review credit reports,
          verify assets and income, push application data and documents to your LOS, etc.
        </p>
      </>
    ),
  },
  documents: {
    titleCid: 'tutorials:documents.title',
    title: 'The "Documents" Tab',
    contentCid: 'tutorials:documents.content',
    content: (
      <>
        <p>This tab keeps track of all documents related to this application.</p>
        <p>
          The <strong>Borrower Documents</strong> table contains documents that the borrower has
          sent you, and you or the system has sent to the borrower. The borrower can see those
          documents as well. Such documents may be bank statements, W-2, etc. For better tracking,
          each document is usually associated with a specific Borrower Task (e.g. if the application
          contains a task called &quot;Upload Your W-2&quot;, you would expect it to contain
          &quot;W-2&quot; related documents ). Here you can also upload any documents that you want
          the borrower to see.
        </p>
        <p>
          The <strong>Lender Documents</strong> table contains documents that are part of this
          application, but only you can see. They could be AUS reports, or other documents. You can
          also upload any additional documents that must be part of this application but should not
          be accessible by the borrower.
        </p>
      </>
    ),
  },
  application: {
    titleCid: 'tutorials:application.title',
    title: 'The "Application" Tab',
    contentCid: 'tutorials:application.content',
    content: (
      <>
        <p>
          This tab provides the &quot;1003&quot; view of the current application. It contains the
          same sections as the 1003. Every time you make an update to a field it is automatically
          saved.
        </p>
        <p>
          You can also add a borrower to this application by clicking the &quot;Add Borrower&quot;
          button, or remove a borrower, if there are more than one.
        </p>

        <p>
          Please be aware that this is not how the borrower sees the application. If you want to see
          the application as the borrower, go to the &quot;Borrower Tasks&quot; tab.
        </p>
      </>
    ),
  },
  team: {
    titleCid: 'tutorials:team.title',
    title: 'The "Team" Tab',
    contentCid: 'tutorials:team.content',
    content: (
      <p>
        Here you can see who in your organization is responsible for managing this application. You
        can assign team members by using the &quot;Manage Team&quot; button.
      </p>
    ),
  },
};
