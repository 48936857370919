import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo';
import {
  type DocumentType,
  type IApplicationFileDocumentsQuery,
  type LosType,
} from '../../apollo/graphql';

export type IApplicationFileDocument = NonNullable<
  IApplicationFileDocumentsQuery['applicationFile']
>['documents']['items'][0];

export type IApplicationFileDocumentTask = NonNullable<
  NonNullable<IApplicationFileDocumentsQuery['applicationFile']>['documents']['items'][0]['task']
>;

export type IApplicationFileDocumentPush = NonNullable<
  NonNullable<IApplicationFileDocumentsQuery['applicationFile']>['documents']['items'][0]['losPush']
>;

const QApplicationFileDocuments = graphql(`
  query IApplicationFileDocuments(
    $applicationFileId: ID!
    $types: [DocumentType!]
    $losType: LOSType
  ) {
    applicationFile(id: $applicationFileId) {
      id
      documents(types: $types, options: { perPage: 1000, orderBy: { created_at: "desc" } }) {
        items {
          id
          filename
          url
          size
          type
          description
          createdAt
          task {
            id
            meta {
              title
            }
            isVisible
            primaryApplicantId
            common
          }
          uploadedBy {
            id
            name
          }
          losPush(losType: $losType) {
            id
            createdAt
            updatedAt
            statusCode
            statusDescription
          }
        }
      }
    }
  }
`);

export const useApplicationFileDocuments = (
  applicationFileId: string,
  types: DocumentType[] = [],
  losType: LosType | null = null,
  options: IUseApolloQueryOptions = {}
) => {
  const [data, loading, , { refetch }] = useQuery(
    QApplicationFileDocuments,
    {
      applicationFileId,
      types,
      losType,
    },
    { suspend: false, fetchPolicy: 'network-only', ...options }
  );

  const handleRefresh = useCallback(async () => {
    await refetch({ applicationFileId, types, losType });
  }, [applicationFileId, losType, refetch, types]);

  const result: IApplicationFileDocument[] = data?.applicationFile?.documents.items || [];

  return [result, loading, handleRefresh] as const;
};
