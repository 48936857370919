import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../apollo';

const MDeleteTask = graphql(`
  mutation DeleteTask($taskId: ID!) {
    deleteTask(taskId: $taskId)
  }
`);

export const useDeleteTask = () => {
  const fn = useMutation(MDeleteTask);
  return async (taskId: string) => {
    await fn({ taskId });
  };
};
