import {
  ApplicationOverviewAusSection,
  ApplicationOverviewLatestActivitySection,
  ApplicationOverviewLenderMilestonesSection,
  ApplicationOverviewLiabilitiesSection,
  ApplicationOverviewLoanSection,
  ApplicationOverviewMilestonesSection,
  ApplicationOverviewPricingSection,
  ApplicationOverviewTasksSection,
  ApplicationOverviewUnderwritingConditionsSection,
} from './sections';
import { type IApplicationOverviewSection } from './types';

export const OVERVIEW_SECTIONS: IApplicationOverviewSection[] = [
  {
    id: 'milestones',
    component: ApplicationOverviewMilestonesSection,
    size: 'full',
    cid: 'pageApplication:tabOverview.sections.milestones.disabled',
  },
  {
    id: 'pricing',
    component: ApplicationOverviewPricingSection,
    size: 'full',
    cid: 'pageApplication:tabOverview.sections.pricing.disabled',
  },
  {
    id: 'liabilities',
    component: ApplicationOverviewLiabilitiesSection,
    cid: 'pageApplication:tabOverview.sections.liabilities.disabled',
    column: 'left',
  },
  {
    id: 'loan',
    component: ApplicationOverviewLoanSection,
    cid: 'pageApplication:tabOverview.sections.loan.disabled',
    column: 'right',
  },
  {
    id: 'underwriting_conditions',
    component: ApplicationOverviewUnderwritingConditionsSection,
    cid: 'pageApplication:tabOverview.sections.underwritingConditions.disabled',
    column: 'right',
  },
  {
    id: 'tasks',
    component: ApplicationOverviewTasksSection,
    cid: 'pageApplication:tabOverview.sections.tasks.disabled',
    column: 'right',
  },
  {
    id: 'aus',
    component: ApplicationOverviewAusSection,
    cid: 'pageApplication:tabOverview.sections.aus.disabled',
    column: 'left',
  },
  {
    id: 'los_milestones',
    component: ApplicationOverviewLenderMilestonesSection,
    cid: 'pageApplication:tabOverview.sections.losMilestones.disabled',
    column: 'left',
  },
  {
    id: 'latest_activity',
    component: ApplicationOverviewLatestActivitySection,
    cid: 'pageApplication:tabOverview.sections.latestActivity.disabled',
    column: 'right',
  },
];
