import {
  Dropdown,
  type IButtonProps,
  Icon,
  type IDropdownAction,
  spacing,
} from '@mortgagehippo/ds';
import { AnimatePresence, motion } from 'framer-motion';
import { type MouseEvent, type ReactNode, useCallback } from 'react';
import styled, { css } from 'styled-components';

type PanelIconLocation = 'left' | 'right';

const actionsButtonProps: IButtonProps = {
  type: 'neutral',
  size: 'xs',
  icon: 'menu-dots',
  importance: 'tertiary',
  iconButton: true,
  compact: true,
  iconButtonHumble: true,
};

const PanelHeader = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  outline: 0;

  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: pointer;
    `}
`;

const IconColumn = styled.div<{ iconLocation?: PanelIconLocation }>`
  flex: 0 0 auto;
  line-height: 0;
  ${({ iconLocation }) => {
    if (iconLocation === 'right') {
      return css`
        margin-left: ${spacing(2)};
      `;
    }

    return css`
      margin-right: ${spacing(2)};
    `;
  }}}
`;

const LabelColumn = styled.div`
  flex: 1 1 auto;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ActionsColumn = styled.div`
  flex: 0 0 auto;
  line-height: 0;
  margin-left: ${spacing(2)};
`;

const StyledLi = styled.li`
  .mh-panel-content {
    /* allows certain input styles to not be cutout */
    padding-left: ${spacing(2)};
    padding-right: ${spacing(2)};
    margin-left: -${spacing(2)};
    margin-right: -${spacing(2)};
  }
`;

export interface ICollapsePanelProps {
  panelKey?: string;
  isActive?: boolean;
  label: string | ReactNode;
  iconLocation?: PanelIconLocation;
  showIcon?: boolean;
  disabled?: boolean;
  onClick?: (key: string) => void;
  actions?: IDropdownAction[];
  onAction?:
    | ((actionKey: string, key?: string) => Promise<any>)
    | ((actionKey: string, key?: string) => void);
  className?: string;
  children?: ReactNode;
}

const defaultActions: IDropdownAction[] = [];
export const CollapsePanel = (props: ICollapsePanelProps) => {
  const {
    panelKey,
    isActive = false,
    label,
    iconLocation: propsIconLocation = 'left',
    showIcon = true,
    disabled = false,
    onClick,
    actions = defaultActions,
    onAction,
    className,
    children,
  } = props;
  const activeClass = isActive ? 'active' : 'inactive';
  const icon = isActive ? 'down-arrow' : 'right-arrow';
  let headerProps = {};
  let itemOptionsEl: ReactNode = null;
  let iconEl: ReactNode = null;

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(panelKey || '');
    }
  }, [onClick, panelKey]);

  const handleDropdownClick = useCallback((event: MouseEvent) => {
    // stop propagation of click event
    event.stopPropagation();
  }, []);

  if (!disabled) {
    headerProps = {
      onClick: handleClick,
      role: 'button',
      tabIndex: 0,
    };
  }

  if (actions && actions.length > 0 && onAction) {
    itemOptionsEl = (
      <ActionsColumn onClick={handleDropdownClick}>
        <Dropdown
          data={panelKey}
          actions={actions}
          onAction={onAction}
          label="Actions"
          buttonProps={actionsButtonProps}
        />
      </ActionsColumn>
    );
  }

  const iconLocation = propsIconLocation === 'right' && itemOptionsEl ? 'left' : propsIconLocation;

  if (showIcon) {
    iconEl = (
      <IconColumn iconLocation={iconLocation}>
        <Icon className="mh-panel-icon" name={icon} />
      </IconColumn>
    );
  }

  return (
    <StyledLi className={`mh-panel ${activeClass} ${className}`}>
      <PanelHeader className="mh-panel-header" disabled={disabled} {...headerProps}>
        {iconLocation === 'left' && iconEl}
        <LabelColumn>{label}</LabelColumn>
        {iconLocation === 'right' && iconEl}
        {itemOptionsEl}
      </PanelHeader>
      <AnimatePresence initial={false}>
        {isActive ? (
          <motion.div
            transition={{ ease: 'easeInOut', duration: 550 / 1000 }}
            key="collapse-panel-anim"
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            style={{ overflow: 'hidden' }}
            className="mh-panel-content"
          >
            {children}
          </motion.div>
        ) : null}
      </AnimatePresence>
    </StyledLi>
  );
};
