import { ModalForm, notifications, T, useCustomizations, useModal, YesNo } from '@mortgagehippo/ds';
import { type IDocumentSubmissionTask, useTask } from '@mortgagehippo/tasks';
import { startCase } from '@mortgagehippo/util';
import { useCallback, useMemo } from 'react';

import { useUpdateDocumentSubmissionTask } from '../../../pages/application/use-update-document-submission-task';
import { CustomTaskInitialFields } from './custom-task-initial-fields';
import { type ICustomTaskTypeProps } from './types';

interface ICustomRequestDocumentTaskFormValues {
  description?: string;
  title?: string;
  required?: boolean;
  due_date?: string | null;
}

export const CustomRequestDocumentTask = (props: ICustomTaskTypeProps) => {
  const { taskId, onDone, onCancel, showDueDate } = props;

  const [isOpen, , closeModal] = useModal(true);

  const [task, , taskLoading] = useTask<IDocumentSubmissionTask>(taskId);

  const updateSubmitDocumentTask = useUpdateDocumentSubmissionTask();

  const customizations = useCustomizations();
  const title = customizations.text('custom_task:request_document', 'Request Documents Task');

  const initialValues: ICustomRequestDocumentTaskFormValues = useMemo(() => {
    if (task) {
      return {
        title: task.meta.title,
        description: task.data.panel?.description,
        required: task.data.required ?? false,
        due_date: task.dueDate,
      };
    }

    return {};
  }, [task]);

  const handleUpdate = async (values: ICustomRequestDocumentTaskFormValues) => {
    try {
      const { title: taskTitle, description, required, due_date } = values;

      const data = {
        title: startCase(taskTitle)!,
        description: description!,
        required: required!,
        dueDate: due_date,
      };

      await updateSubmitDocumentTask(taskId!, data);

      closeModal(() => {
        notifications.success({
          messageCid: 'custom_task:request_document_task_update_success_msg',
          message: 'Successfully updated task',
        });
        onDone();
      });
    } catch (e) {
      console.error(e);
      notifications.error({
        messageCid: 'custom_task:request_document_task_update_failure_msg',
        message: 'There was an unexpected error updating the task',
      });
    }
  };

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  return (
    <ModalForm
      loading={taskLoading}
      width="medium"
      isOpen={isOpen}
      title={title}
      onRequestClose={handleCloseModal}
      initialValues={initialValues}
      okButtonLabel={<T>Update task</T>}
      onSubmit={handleUpdate}
      disableOverlayClickClose
    >
      <CustomTaskInitialFields showDueDate={showDueDate}>
        <YesNo.Box name="required" label="Required" initialValue required />
      </CustomTaskInitialFields>
    </ModalForm>
  );
};
