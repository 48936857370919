import { Button, Format, Icon, spacing } from '@mortgagehippo/ds';
import styled from 'styled-components';

import { type LosType } from '../../../../apollo/graphql';
import {
  isFailedDocumentPush,
  isSuccessfulDocumentPush,
  LosDocumentErrorButton,
} from '../../application-services';
import { type IApplicationFileDocumentPush } from '../../use-application-file-documents';

const StyledDocumentErrorButton = styled(LosDocumentErrorButton)`
  margin-left: ${spacing(2)};
`;

interface ILosStatusColumnProps {
  type: LosType;
  push?: IApplicationFileDocumentPush | null;
  onTriggerDocumentPush?: () => Promise<unknown> | undefined;
  disabled?: boolean;
}

export const LosStatusColumn = (props: ILosStatusColumnProps) => {
  const { type, push, onTriggerDocumentPush, disabled } = props;

  const { statusDescription, createdAt } = push || {};

  const canPushDocument = !!onTriggerDocumentPush;

  const isPushed = !!push && isSuccessfulDocumentPush(push);
  const isFailed = !!push && isFailedDocumentPush(push);

  if (isPushed) {
    return (
      <>
        <Icon name="check" color="success600" size="sm" />{' '}
        <Format.Date format="date-short" value={createdAt!} />
      </>
    );
  }

  return (
    <>
      {canPushDocument  ? (
        <Button
          size="xs"
          importance="tertiary"
          icon="direction-up"
          onClick={onTriggerDocumentPush}
          disabled={disabled}
        >
          Push to LOS
        </Button>
      ) : null}
      {isFailed ? (
        <StyledDocumentErrorButton type={type} errorDetails={statusDescription} size="xs" />
      ) : null}
    </>
  );
};
