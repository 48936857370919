import {
  type FormSubmitHandler,
  ModalWizard2 as ModalWizard,
  useModal,
  useModalConfirm,
} from '@mortgagehippo/ds';
import { type ITaskTemplate, TaskType } from '@mortgagehippo/tasks';
import { startCase } from '@mortgagehippo/util';
import { flatten, isNil, uniq } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as uuid from 'uuid';

import { DEFAULT_DUE_DATE_DAYS_FROM_NOW, getDueDateFromToday } from '$components/due-date-field';

import { type IApplicationApplicant } from '../../../pages/application/use-application-file-data';
import { type IUnderwritingCondition } from '../../../pages/application/use-undewriting-conditions';
import { COMMON_APPLICANT_OPTION_VALUE, DEFAULT_CUSTOM_TASK_TITLE } from './constants';
import { Step1, Step2 } from './steps';
import { TaskEditor } from './task-editor';
import { type IBulkCustomTask } from './types';
import { isDefaultTemplate, isValidTask } from './util';

/*
 * WARNING: If the type for underwriting condition tasks is expanded from DocumentSubmissionTask
 * considerations need to be made here (other types might not allow multiple applicants or for it to be common).
 * Also the data property must be accounted for
 */
const tasksFromUnderwritingConditions = (
  underwritingConditions: IUnderwritingCondition[],
  applicants: IApplicationApplicant[],
  primaryApplicantId: string
): IBulkCustomTask[] => {
  const hasMultipleApplicants = applicants.length > 1;

  const defaultApplicantIds = hasMultipleApplicants
    ? [COMMON_APPLICANT_OPTION_VALUE]
    : primaryApplicantId;

  return underwritingConditions.map((underwritingCondition) => {
    const { id, title, description, applicants: conditionApplicants } = underwritingCondition;
    const [firstConditionApplicant] = conditionApplicants || [];

    const conditionApplicantIds = hasMultipleApplicants
      ? conditionApplicants?.map((a) => a.id)
      : firstConditionApplicant?.id;

    return {
      id: uuid.v4(),
      title: title || DEFAULT_CUSTOM_TASK_TITLE,
      description: description || undefined,
      type: TaskType.DocumentSubmissionTask,
      applicant_ids: conditionApplicantIds || defaultApplicantIds,
      data: {
        required: true,
      },
      underwriting_condition_id: id,
    };
  });
};

enum WizardSteps {
  TASKS = 'TASKS',
  MESSAGE = 'MESSAGE',
  SUCCESS = 'SUCCESS',
}

interface IBulkCreateCustomTaskWizardProps {
  applicationFileId: string;
  primaryApplicantId?: string | null;
  applicants: IApplicationApplicant[];
  templates: ITaskTemplate[];
  underwritingConditions?: IUnderwritingCondition[];
  underwritingTaskTypes?: TaskType[];
  isOpen: boolean;
  loading: boolean;
  onFinish: () => void;
  onSave: (tasks: IBulkCustomTask[], message?: string | null) => Promise<any>;
  onCancel: () => void;
  showDueDate?: boolean;
}

export const BulkCreateCustomTaskWizard = (props: IBulkCreateCustomTaskWizardProps) => {
  const {
    applicationFileId,
    primaryApplicantId,
    isOpen,
    loading: propsLoading,
    applicants,
    templates,
    underwritingConditions,
    underwritingTaskTypes,
    onSave,
    onFinish,
    onCancel,
    showDueDate,
  } = props;

  // we should show the spinner until we've created any tasks from underwriting conditions
  const createdUnderwritingTasksRef = useRef(false);

  const [tasks, setTasks] = useState<IBulkCustomTask[]>([]);
  const [activeTaskIndex, setActiveTaskIndex] = useState<number | undefined>();
  const [newDraft, setNewDraft] = useState<IBulkCustomTask | undefined>();
  const [step, setStep] = useState<WizardSteps | undefined>(WizardSteps.TASKS);

  const [editModalIsOpen, openEditModal, closeEditModal] = useModal(false);
  const [confirm, modalConfirm] = useModalConfirm(
    'You will lose all your changes if you exit. Are you sure you want to exit?'
  );

  const totalTasks = tasks.length;
  const shouldConfirmBeforeCancel = step === WizardSteps.TASKS && totalTasks > 0;

  const [activeTask, activeTemplate, isCreating, isEditing] = useMemo(() => {
    const nextIsCreating = !isNil(newDraft);
    const nextIsEditing = !isNil(activeTaskIndex);
    const nextActiveTask = nextIsEditing ? tasks[activeTaskIndex!] : newDraft;
    const nextActiveTemplate = nextActiveTask?.template_key
      ? templates.find((t) => t.key === nextActiveTask.template_key)
      : undefined;

    return [nextActiveTask, nextActiveTemplate, nextIsCreating, nextIsEditing];
  }, [activeTaskIndex, newDraft, tasks, templates]);

  const handleCreateTask = useCallback(
    (templateKey: string) => {
      const nextTemplate = templates.find((t) => t.key === templateKey);

      if (!nextTemplate) {
        return;
      }

      const { title, description, type_name, data, default_due_date_days } = nextTemplate;
      const defaultDueDate = showDueDate
        ? getDueDateFromToday(
            !isNil(default_due_date_days) ? default_due_date_days : DEFAULT_DUE_DATE_DAYS_FROM_NOW
          )
        : null;

      // if it's a default template the agent will manually enter everything
      const nextDraft = !isDefaultTemplate(templateKey)
        ? ({
            id: uuid.v4(),
            title,
            description,
            data,
            type: type_name,
            template_key: templateKey,
            due_date: defaultDueDate,
          } as IBulkCustomTask)
        : ({
            id: uuid.v4(),
            description,
            type: type_name,
            template_key: templateKey,
            due_date: defaultDueDate,
          } as IBulkCustomTask);

      setActiveTaskIndex(undefined);
      setNewDraft(nextDraft);
      openEditModal();
    },
    [openEditModal, showDueDate, templates]
  );

  const handleEditTask = useCallback(
    (index: number) => {
      setNewDraft(undefined);
      setActiveTaskIndex(index);
      openEditModal();
    },
    [openEditModal]
  );

  const handleCancelTask = useCallback(() => {
    closeEditModal(() => {
      setActiveTaskIndex(undefined);
      setNewDraft(undefined);
    });
  }, [closeEditModal]);

  const handleSaveTask: FormSubmitHandler = useCallback(
    (nextTask: any) => {
      const { title } = nextTask as IBulkCustomTask;

      const casedTask = {
        ...nextTask,
        title: startCase(title),
      };

      if (isCreating) {
        setTasks((prevTasks) => [...prevTasks, casedTask as IBulkCustomTask]);
      }

      if (isEditing) {
        setTasks((prevTasks) =>
          prevTasks.map((prevTask, i) => {
            if (activeTaskIndex === i) {
              return casedTask as IBulkCustomTask;
            }

            return prevTask;
          })
        );
      }

      closeEditModal(() => {
        setActiveTaskIndex(undefined);
        setNewDraft(undefined);
      });
    },
    [activeTaskIndex, closeEditModal, isCreating, isEditing]
  );

  const handleDeleteTask = useCallback((index: number) => {
    setTasks((prevTasks) => [...prevTasks.slice(0, index), ...prevTasks.slice(index + 1)]);
  }, []);

  const handleStepChange = async (
    nextStep: string | undefined,
    _currentStep: string,
    values: any
  ) => {
    const { message } = values;

    if (nextStep === WizardSteps.SUCCESS) {
      const success = await onSave(tasks, message);

      if (!success) {
        return;
      }
    }

    if (nextStep) {
      setStep(nextStep as WizardSteps);
      return;
    }

    onFinish();
  };

  const handleRequestClose = useCallback(() => {
    if (shouldConfirmBeforeCancel) {
      confirm({
        onConfirm: onCancel,
      });
      return;
    }

    if (onCancel) {
      onCancel();
    }
  }, [confirm, onCancel, shouldConfirmBeforeCancel]);

  useEffect(() => {
    if (!underwritingConditions?.length || !primaryApplicantId || !applicants.length) {
      return;
    }

    const nextTasks = tasksFromUnderwritingConditions(
      underwritingConditions,
      applicants,
      primaryApplicantId
    );

    setTasks(nextTasks);
    createdUnderwritingTasksRef.current = true;
  }, [applicants, primaryApplicantId, underwritingConditions]);

  const applicantIds = uniq(flatten(tasks.map((t) => t.applicant_ids)));
  const hasMultipleBorrowers =
    applicantIds.length > 1 || applicantIds.some((id) => id === COMMON_APPLICANT_OPTION_VALUE);

  const tasksNextButtonLabel = `Send ${totalTasks} task${totalTasks === 1 ? '' : 's'} to borrower${
    hasMultipleBorrowers ? 's' : ''
  }`;
  const tasksNextButtonDisabled = totalTasks === 0 || tasks.some((t) => !isValidTask(t));

  const emailToBorrowerTitle = `Review Email to Borrower${hasMultipleBorrowers ? 's' : ''}`;
  const width = step === WizardSteps.TASKS ? 1100 : 700;
  const loading =
    propsLoading || (!!underwritingConditions?.length && !createdUnderwritingTasksRef.current);

  return (
    <>
      <ModalWizard
        width={width}
        disableOverlayClickClose
        isOpen={isOpen}
        loading={loading}
        step={step}
        onStepChange={handleStepChange}
        onRequestClose={handleRequestClose}
      >
        <ModalWizard.Step
          id={WizardSteps.TASKS}
          title="Add Follow-up Tasks"
          nextButtonLabel={tasksNextButtonLabel}
          nextButtonDisabled={tasksNextButtonDisabled}
          hidePrevButton
        >
          <Step1
            tasks={tasks}
            templates={templates}
            applicants={applicants}
            underwritingConditions={underwritingConditions}
            onEdit={handleEditTask}
            onDelete={handleDeleteTask}
            onCreate={handleCreateTask}
          />
        </ModalWizard.Step>

        <ModalWizard.Step
          id={WizardSteps.MESSAGE}
          title={emailToBorrowerTitle}
          hideCancelButton
          prevButtonLabel="Back"
          nextButtonLabel="Send"
          nextButtonIcon="send"
        >
          <Step2 tasks={tasks} pluralize={hasMultipleBorrowers} />
        </ModalWizard.Step>

        <ModalWizard.Step
          id={WizardSteps.SUCCESS}
          title="Success!"
          hidePrevButton
          hideCancelButton
          nextButtonLabel="Finish"
          icon="success"
          iconColor="success600"
        >
          <p>
            <strong>The follow-up tasks were successfully sent.</strong>
          </p>
          <p>
            You can see them in the tasks list if you want to track their completion status or make
            any changes.
          </p>
        </ModalWizard.Step>
      </ModalWizard>
      <TaskEditor
        applicationFileId={applicationFileId}
        task={activeTask}
        applicants={applicants}
        underwritingConditions={underwritingConditions}
        underwritingTaskTypes={underwritingTaskTypes}
        isOpen={editModalIsOpen}
        onSubmit={handleSaveTask}
        onRequestClose={handleCancelTask}
        title={
          isCreating ? `New Task: ${activeTemplate?.title}` : `Edit Task: ${activeTask?.title}`
        }
        okButtonLabel={isCreating ? 'Add task' : 'Save'}
        width="medium"
        showDueDate={showDueDate}
      />
      {modalConfirm}
    </>
  );
};
