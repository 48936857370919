import { Content, Layout } from '../../layouts/main';
import { ReportsContent } from './reports-content';

export const ReportsPage = () => (
  <Layout pageTitle="Reports" compact>
    <Content>
      <ReportsContent />
    </Content>
  </Layout>
);
