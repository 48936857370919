import { useApplicationFileAgents } from '../../../../pages/application/use-application-file-agents';
import { TeamAgentsList } from './team-agents-list';
import { TeamAgentsListSkeleton } from './team-agents-list-skeleton';

interface ITeamAgentsListContainerProps {
  applicationFileId: string;
}

export const TeamAgentsListContainer = (props: ITeamAgentsListContainerProps) => {
  const { applicationFileId } = props;
  const [{ agents }, loading] = useApplicationFileAgents(applicationFileId);

  if (loading) {
    return <TeamAgentsListSkeleton />;
  }

  return <TeamAgentsList agents={agents || []} />;
};
