import { type IButtonProps, Popover } from '@mortgagehippo/ds';
import { isPromise } from '@mortgagehippo/util';
import { useCallback, useState } from 'react';

interface IAvatarDeleteButtonProps extends IButtonProps {
  onClick?: (() => Promise<any>) | (() => void);
}

export const AvatarDeleteButton = (props: IAvatarDeleteButtonProps) => {
  const { onClick, children, ...rest } = props;

  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(async () => {
    if (!onClick) {
      return;
    }

    const result = onClick();

    if (isPromise(result)) {
      setLoading(true);

      await result;

      setLoading(false);
    }
  }, [onClick]);

  const buttonProps = {
    ...rest,
    loading,
  };

  return (
    <Popover
      content="This action cannot be undone."
      title="Are you sure?"
      confirm
      onConfirm={handleClick}
      buttonProps={buttonProps}
    >
      {children}
    </Popover>
  );
};
