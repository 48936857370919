import { useQuery } from '@mortgagehippo/apollo-hooks';
import { Alert, Button, spacing } from '@mortgagehippo/ds';
import { merge } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ActivityFeedContent } from './activity-feed-content';
import { ActivityFeedSkeleton } from './activity-feed-skeleton';
import { type IActivityFeed, type IActivityFeedDictionary, type IActivityFeedEvent } from './types';

const Footer = styled.div`
  margin-top: ${spacing(6)};
`;

export interface IActivityFeedInternalVariables {
  cursor?: string | null;
  perPage?: number | null;
}

interface IActivityFeedProps<Result, Variables> {
  query: any;
  variables?: Omit<Variables, keyof IActivityFeedInternalVariables>;
  dataSource: (result: Result) => IActivityFeed | null;
}

export function ActivityFeed<Result, Variables extends IActivityFeedInternalVariables>(
  props: IActivityFeedProps<Result, Variables>
) {
  const { dataSource: getDataSource, query, variables } = props;
  const [dictionary, setDictionary] = useState<IActivityFeedDictionary>({});
  const [items, setItems] = useState<IActivityFeedEvent[]>([]);
  const [nextCursor, setNextCursor] = useState<string | null>(null);

  const [result, loading, , { refetch, error }] = useQuery<Result, Variables>(
    query,
    { ...variables } as Variables,
    {
      suspend: false,
      fetchPolicy: 'no-cache', // need this because of the errorPolicy...
      throw: false,
      errorPolicy: 'all', // without this the hook will not update when you do a refetch after an error...
    }
  );

  const handleShowMore = useCallback(async () => {
    if (!nextCursor) {
      return undefined;
    }

    return refetch({
      ...(variables || {}),
      cursor: nextCursor,
    } as Variables);
  }, [nextCursor, refetch, variables]);

  useEffect(() => {
    if (error || loading || !result) {
      return;
    }

    const feed = getDataSource(result);

    if (!feed) {
      return;
    }

    const { items: nextItems, dictionary: nextDictionary } = feed;

    setDictionary((prevDictionary: any) => merge(prevDictionary, nextDictionary));
    setItems((prevItems) => [...prevItems, ...nextItems]);
    setNextCursor(feed.nextCursor);
  }, [result, getDataSource, error, loading]);

  if (loading) {
    return <ActivityFeedSkeleton />;
  }

  if (error) {
    return (
      <Alert size="sm" inline>
        An error ocurred while loading the activity history. Please try again later.
      </Alert>
    );
  }

  return (
    <>
      <ActivityFeedContent dictionary={dictionary} items={items} />
      {nextCursor ? (
        <Footer>
          <Button importance="secondary" size="sm" onClick={handleShowMore}>
            Show more ...
          </Button>
        </Footer>
      ) : null}
    </>
  );
}
