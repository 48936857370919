import { ForbiddenError, useAuth } from '@mortgagehippo/auth';
import {
  type IShadow,
  type ITabsCustomColors,
  MediaBreakpoint,
  shadow,
  T,
  TabRouter,
  useCustomizations,
  useResponsive,
} from '@mortgagehippo/ds';
import { NotFoundError } from '@mortgagehippo/util';
import { isArray, orderBy } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { type RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import { EXITED_APPLICATION } from '$components/actionable';
import { useApplicationFileCan } from '$components/permissions';
import { TabOverlayRoute } from '$components/tab-overlay-route';

import { LAYOUT_DIMENSIONS } from '../../layouts/application';
import { ApplicationHeaderContainer } from '../../layouts/application/application-header-container';
import { history } from '../../services/history';
import { ApplicationActions } from './application-actions';
import { ApplicationActivityRoute } from './application-activity';
import { ApplicationAlerts } from './application-alerts';
import { ApplicationDocumentsRoute } from './application-documents';
import { ApplicationInvitesRoute } from './application-invites';
import { ApplicationMilestonesRoute } from './application-milestones';
import { ApplicationOverviewRoute } from './application-overview';
import { ApplicationPreQualificationRoute } from './application-pre-qualification';
import { ApplicationPricingRoute } from './application-pricing';
import { ApplicationServicesRoute } from './application-services';
import { ApplicationTasksRoute } from './application-tasks';
import { ApplicationTeamRoute } from './application-team';
import { ApplicationViewRoute } from './application-view';
import { isApplicationFileAlerted } from './util';

const ARCHIVED_KEY = 'archived';
const UNARCHIVED_KEY = 'unarchived';

const StyledTabRouter = styled(TabRouter)<{
  shadowLevel: IShadow;
  shadowIntense: boolean;
  isAlerted?: boolean;
}>`
  position: relative;
  z-index: 5;
  /* prettier-ignore */
  height: ${(p) =>
    !p.isAlerted
      ? `calc(100% - ${LAYOUT_DIMENSIONS.headerHeight}px)`
      : `calc(100% - ${LAYOUT_DIMENSIONS.headerHeight}px - ${LAYOUT_DIMENSIONS.alertHeight}px)`};
  box-shadow: ${(p) => shadow(p.shadowLevel, (p.shadowIntense && 'intense') || undefined)};

  ${MediaBreakpoint.PHONE} {
    height: auto;
    min-height: 100%;
  }

  & .tab-bar:before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    bottom: -1px;
    box-shadow: ${shadow(4, 'intense')};
    z-index: 0;
  }

  & .tab-content-container {
    /* prettier-ignore */
    height: calc(100% - ${LAYOUT_DIMENSIONS.mainNavigationHeight}px);

    ${MediaBreakpoint.PHONE} {
      height: auto;
      min-height: 100%;
    }

    & .tab-content {
      height: 100%;
    }
  }
`;

export interface IApplicationPageParams {
  applicationFileId: string;
  id?: string;
}

export const ApplicationPage = (props: RouteComponentProps<IApplicationPageParams>) => {
  const { match } = props;
  const { params } = match;
  const { applicationFileId, id } = params;

  const [user] = useAuth();
  const [can, canReady, { data: applicationFile }] = useApplicationFileCan(applicationFileId, {
    fetchPolicy: 'network-only',
  });

  const responsive = useResponsive();
  const customizations = useCustomizations();
  const customTabOrder = customizations.json('pageApplication:menu.tabs.order');
  const customizationsReady = customizations.ready();

  const isAlerted = !!applicationFile && isApplicationFileAlerted(applicationFile);

  const accessibleRoutes = useMemo(() => {
    if (!canReady || !applicationFile || !customizationsReady) {
      return [];
    }

    const routes = [
      {
        key: 'overview',
        label: 'Overview',
        to: `/applications/${applicationFile.id}/overview`,
        path: '/applications/:applicationFileId/overview',
        component: ApplicationOverviewRoute,
        hidden: !can.VIEW_OVERVIEW_TAB,
      },
      {
        key: 'application',
        label: <T cid="pageApplication:menu.tabs.application.label">Application</T>,
        to: `/applications/${applicationFile.id}/view`,
        path: '/applications/:applicationFileId/view/:applicantId?/:taskId?',
        component: ApplicationViewRoute,
        hidden: !can.VIEW_APPLICATION_TAB,
      },
      {
        key: 'tasks',
        label: <T cid="pageApplication:menu.tabs.tasks.label">Borrower Tasks</T>,
        to: `/applications/${applicationFile.id}/tasks`,
        path: '/applications/:applicationFileId/tasks/:applicantId?/:taskId?',
        component: ApplicationTasksRoute,
        hidden: !can.VIEW_TASKS_TAB,
      },
      {
        key: 'services',
        label: <T cid="pageApplication:menu.tabs.services.label">Services</T>,
        to: `/applications/${applicationFile.id}/integrations`,
        path: '/applications/:applicationFileId/integrations/:serviceId?',
        component: ApplicationServicesRoute,
        hidden: !can.VIEW_SERVICES_TAB,
      },
      {
        key: 'documents',
        label: <T cid="pageApplication:menu.tabs.documents.label">Documents</T>,
        to: `/applications/${applicationFile.id}/documents`,
        path: '/applications/:applicationFileId/documents',
        component: ApplicationDocumentsRoute,
        hidden: !can.VIEW_DOCUMENTS_TAB,
      },
      {
        key: 'pricer',
        label: <T cid="pageApplication:menu.tabs.pricer.label">Loan Pricer</T>,
        to: `/applications/${applicationFile.id}/pricing`,
        path: '/applications/:applicationFileId/pricing',
        component: ApplicationPricingRoute,
        hidden: !can.VIEW_PRICING_TAB,
      },
      {
        key: 'team',
        label: <T cid="pageApplication:menu.tabs.team.label">Team</T>,
        to: `/applications/${applicationFile.id}/team`,
        path: '/applications/:applicationFileId/team',
        component: ApplicationTeamRoute,
        hidden: !can.VIEW_TEAM_TAB,
      },
      // route replaced by /milestones
      {
        key: 'status',
        label: <T cid="pageApplication:menu.tab.status.label">Status</T>,
        to: `/applications/${applicationFile.id}/status`,
        path: '/applications/:applicationFileId/status',
        component: ApplicationMilestonesRoute,
        hidden: true,
      },
      {
        key: 'milestones',
        label: <T cid="pageApplication:menu.tab.milestones.label">Milestones</T>,
        to: `/applications/${applicationFile.id}/milestones`,
        path: '/applications/:applicationFileId/milestones',
        component: ApplicationMilestonesRoute,
        hidden: !can.VIEW_MILESTONES_TAB,
      },
      {
        key: 'prequalification',
        label: <T cid="pageApplication:menu.tabs.preQualification.label">Pre-Qual</T>,
        to: `/applications/${applicationFile.id}/prequalification`,
        path: '/applications/:applicationFileId/prequalification',
        component: ApplicationPreQualificationRoute,
        hidden: !can.VIEW_PREQUALIFICATION_LETTERS_PAGE,
      },
      {
        key: 'invite',
        label: <T cid="pageApplication:menu.tabs.invite.label">Invite Borrower</T>,
        to: `/applications/${applicationFile.id}/invite`,
        path: '/applications/:applicationFileId/invite',
        component: ApplicationInvitesRoute,
        silent: true,
      },
      {
        key: 'activity',
        label: 'Activity',
        to: `/applications/${applicationFile.id}/activity`,
        path: '/applications/:applicationFileId/activity',
        component: ApplicationActivityRoute,
        hidden: !can.VIEW_APPLICATION_ACTIVITY_PAGE,
        silent: true,
      },
    ];

    const visibleRoutes = routes.filter((r) => !r.hidden);

    if (!customTabOrder || !isArray(customTabOrder) || !customTabOrder.length) {
      return visibleRoutes;
    }

    return orderBy(
      visibleRoutes,
      [
        (r) => {
          const index = customTabOrder.indexOf(r.key);
          return index === -1 ? 100000 : index;
        },
      ],
      ['asc']
    );
  }, [canReady, applicationFile, customizationsReady, can, customTabOrder]);

  useEffect(() => {
    const shouldRedirect = !id && accessibleRoutes.length > 0;
    const redirectRoute = accessibleRoutes.find((r) => !r.silent);

    if (shouldRedirect && redirectRoute) {
      history.replace(`#${redirectRoute.to}`);
    }
  }, [id, accessibleRoutes]);

  useEffect(
    () => () => {
      EXITED_APPLICATION(Number(applicationFileId));
    },
    [applicationFileId]
  );

  const color = customizations.color('app:application.header.menu.color', 'primary50');
  const activeColor = customizations.color('app:application.header.menu.activeColor', 'primary700');
  const hoverColor = customizations.color('app:application.header.menu.hoverColor', 'primary50');
  const hoverBackground = customizations.color(
    'app:application.header.menu.hoverBackground',
    'primary600'
  );
  const background = customizations.color('app:application.header.menu.background', 'primary700');
  const activeBackground = customizations.color(
    'app:application.header.menu.active.background',
    'neutral50'
  );

  const shadowLevel = customizations.shadow('app:application.header.menu.shadow.level', 4);
  const shadowIntense = customizations.bool('app:application.header.menu.shadow.intense', true);

  const tabColors: ITabsCustomColors = {
    color,
    activeColor,
    hoverColor,
    hoverBackground,
    background,
    activeBackground,
  };

  const [tabHeight, tabSize] = useMemo(() => {
    if (responsive.PHONE.EXACT_OR_SMALLER) {
      return [`${LAYOUT_DIMENSIONS.mainNavigationHeightMobile}px`, 'sm'] as const;
    }

    return [`${LAYOUT_DIMENSIONS.mainNavigationHeight}px`, 'sm'] as const;
  }, [responsive.PHONE.EXACT_OR_SMALLER]);

  if (!user || !canReady) {
    return null;
  }

  if (!applicationFile) {
    throw new NotFoundError();
  }

  if (!can.VIEW_APPLICATION_FILE_PAGE) {
    throw new ForbiddenError();
  }

  // force refreshing the content of whatever page you are viewing when you archive the file
  const key = applicationFile.archivedAt ? ARCHIVED_KEY : UNARCHIVED_KEY;

  return (
    <>
      <ApplicationHeaderContainer applicationFileId={applicationFileId} />
      {isAlerted ? <ApplicationAlerts applicationFileId={applicationFileId} /> : null}
      <StyledTabRouter
        key={key}
        shadowLevel={shadowLevel}
        shadowIntense={shadowIntense}
        actions={<ApplicationActions applicationFileId={applicationFileId} />}
        overlay
        height={tabHeight}
        size={tabSize}
        tabStyle="card"
        customColors={tabColors}
        isAlerted={isAlerted}
      >
        {accessibleRoutes.map((r) => (
          <TabOverlayRoute
            key={r.path}
            label={r.label}
            to={r.to}
            path={r.path}
            silent={!!r.silent}
            component={r.component}
          />
        ))}
      </StyledTabRouter>
    </>
  );
};
