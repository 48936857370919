import { type IActionProps, type IEditCustomTaskAction } from '../types';
import { CustomTask } from './custom-task';

export const EditCustomTaskAction = (props: IActionProps<IEditCustomTaskAction>) => {
  const { action, onDone, onCancel } = props;
  const { taskId, applicationFileId, taskType } = action;

  return (
    <CustomTask
      taskType={taskType}
      taskId={taskId}
      applicationFileId={applicationFileId}
      onDone={onDone}
      onCancel={onCancel}
    />
  );
};
