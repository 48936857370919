/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  borderRadius,
  createLayout,
  createSlot,
  Image,
  type ITemplateProps,
  MediaBreakpoint,
  shadow,
  spacing,
  Title,
  useCustomizations,
} from '@mortgagehippo/ds';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import { GlobalScripts } from '$components/global-scripts';

import { useFavicon } from '../../hooks/use-favicon';
import { ContentComponent } from './content-component';

interface IActiveSlots {
  isNavActive?: boolean;
  isContentNavActive?: boolean;
  isSidebarActive?: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const BodyComponent = styled('div')<IActiveSlots>`
  padding: ${spacing(6)};

  ${MediaBreakpoint.PHONE} {
    padding: 0 ${spacing(2)};
  }
`;

const Header = styled.div`
  text-align: center;
  margin: ${spacing(5)};
`;

const Box = styled.div`
  padding: ${spacing(6)};
  width: 500px;
  background: white;
  border-radius: ${borderRadius(2)};
  box-shadow: ${shadow(5)};
  margin: 0 auto;

  ${Header} {
    margin-top: 0;
  }

  ${MediaBreakpoint.PHONE} {
    width: 100%;
    max-width: 500px;
    padding: ${spacing(5)};
  }
`;

interface IMyTemplateProps extends ITemplateProps {
  title?: string;
  titleCid?: string;
}

const Template = (props: IMyTemplateProps) => {
  const { title, titleCid } = props;
  const activeSlots: IActiveSlots = {
    isNavActive: true,
  };

  const customizations = useCustomizations();
  const logoShowInsideContent = customizations.bool(
    'app:layoutLogin.logo.showInsideContent',
    false
  );

  const faviconSrc = useFavicon();

  const pageTitle = title || 'Lender Hub';

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="icon" type="image/png" href={faviconSrc} />
      </Helmet>
      <GlobalScripts />
      <Container>
        <BodyComponent {...activeSlots}>
          {!logoShowInsideContent && (
            <Header>
              <Image cid="app:header.logo" src="/maxwell.svg" style={{ maxWidth: 200 }} />
            </Header>
          )}

          <Box>
            {logoShowInsideContent ? (
              <Header>
                <Image cid="app:header.logo" src="/maxwell.svg" style={{ maxWidth: 200 }} />
              </Header>
            ) : null}
            {title ? (
              <Title align="center" cid={titleCid}>
                {title}
              </Title>
            ) : null}
            <Content.Slot />
          </Box>
        </BodyComponent>
      </Container>
    </>
  );
};

export const Layout = createLayout(Template);
export const Content = createSlot(Layout, ContentComponent);
