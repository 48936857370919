import {
  Button,
  Currency,
  FieldBox,
  type FormSubmitHandler,
  type IModalFormProps,
  Input,
  ModalForm,
  SimpleFieldAutoArray,
  spacing,
} from '@mortgagehippo/ds';
import { type ITransnationalInvoiceLineItem } from '@mortgagehippo/tasks';
import { useCallback } from 'react';
import styled from 'styled-components';

import { type IApplicationApplicant } from '../../../../../pages/application/use-application-file-data';
import { DefaultFields } from '../default-fields';

const validateLineItem = (value: ITransnationalInvoiceLineItem) => {
  if (!value.description || !value.amount) {
    return 'Invalid Item';
  }

  return undefined;
};

const LINE_ITEM_PRESENT_FIELDS = ['description', 'amount'];

const LineItemRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${spacing(3)};

  &:first-child {
    margin-top: 0;
  }
`;

const LineItemCol = styled.div<{ flex?: number }>`
  flex: ${({ flex }) => (flex === undefined ? 1 : flex)};
  flex-basis: auto;
  margin-right: ${spacing(3)};

  &:last-child {
    margin-right: 0;
  }
`;

interface IPaymentTaskEditorProps extends IModalFormProps {
  applicants: IApplicationApplicant[];
  showDueDate?: boolean;
}

export const PaymentTaskEditor = (props: IPaymentTaskEditorProps) => {
  const { applicants, onSubmit, showDueDate, children, ...rest } = props;

  const handleSubmit: FormSubmitHandler = useCallback(
    (values, form, callback) => {
      const { data } = values || {};
      const { line_items: lineItems } = data || {};

      const sanitizedLineItems = (lineItems || []).filter(
        (v: any) => validateLineItem(v) === undefined
      );

      const nextValues = {
        ...values,
        data: {
          ...data,
          line_items: sanitizedLineItems,
        },
      };

      if (!onSubmit) {
        return undefined;
      }

      return onSubmit(nextValues, form, callback);
    },
    [onSubmit]
  );

  return (
    <ModalForm {...rest} onSubmit={handleSubmit}>
      {children}
      <DefaultFields
        applicants={applicants}
        allowCommon
        allowMultipleApplicants={false}
        showDueDate={showDueDate}
      />
      <FieldBox
        name="data.line_items"
        label="Payment Items"
        description="The items you are requesting payment for."
        required
      >
        <SimpleFieldAutoArray<ITransnationalInvoiceLineItem>
          name="data.line_items"
          validateItem={validateLineItem}
          presentFields={LINE_ITEM_PRESENT_FIELDS}
          validateMin={1}
          render={({ name: itemName, index, value, error, onRemove, active }) => (
            <LineItemRow key={value.id || index}>
              <LineItemCol>
                <Input
                  name={`${itemName}.description`}
                  placeholder="Item Description"
                  compact
                  aria-label="Item Description"
                  error={!active && !!error}
                />
              </LineItemCol>
              <LineItemCol flex={0}>
                <Currency
                  name={`${itemName}.amount`}
                  placeholder="Amount"
                  compact
                  aria-label="Amount"
                  error={!active && !!error}
                  decimalMode="allow"
                />
              </LineItemCol>
              <LineItemCol flex={0}>
                <Button
                  icon="delete"
                  iconButton
                  size="xs"
                  type="danger"
                  importance="tertiary"
                  onClick={onRemove}
                  compact
                />
              </LineItemCol>
            </LineItemRow>
          )}
        />
      </FieldBox>
    </ModalForm>
  );
};
