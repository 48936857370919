import {
  type FormSubmitHandler,
  ModalForm,
  type UploadHandler,
  UploadInput,
} from '@mortgagehippo/ds';
import { ACCEPTED_DOCUMENT_MIME_TYPES } from '@mortgagehippo/tasks';

interface IUploadLenderDocumentsModalProps {
  isOpen: boolean;
  title: string;
  onUpload: UploadHandler;
  onSubmit: FormSubmitHandler;
  onRequestClose: () => void;
  submitDisabled?: boolean;
}

const LENDER_ACCEPTED_DOCUMENT_MIME_TYPES = [...ACCEPTED_DOCUMENT_MIME_TYPES, 'application/xml'];

export const UploadLenderDocumentsModal = (props: IUploadLenderDocumentsModalProps) => {
  const { title, onUpload, onRequestClose, onSubmit, isOpen, submitDisabled } = props;

  return (
    <ModalForm
      title={title}
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      onSubmit={onSubmit}
      okButtonDisabled={submitDisabled}
    >
      <UploadInput.Box
        name="documents"
        label="Documents"
        onUpload={onUpload}
        required
        descriptionField
        accept={LENDER_ACCEPTED_DOCUMENT_MIME_TYPES}
        fileTypeDescription="document"
      />
    </ModalForm>
  );
};
