import { DATE_FORMAT_SERVER } from '@mortgagehippo/ds';
import moment from 'moment';

import { DUE_DATE_PRESETS_OPTIONS } from './constant';

export const calculateDaysFromNow = (date: string) => {
  /*
   * have to use start of day (midnight) so that the calculation is correct, otherwise partial days
   * might be ignored
   */
  const today = moment().startOf('day');
  const inputDate = moment(date, DATE_FORMAT_SERVER);

  return inputDate.diff(today, 'days');
};

export const getPresetFromDate = (date: string) => {
  const daysFromNow = calculateDaysFromNow(date);

  if (daysFromNow < 0) return undefined;

  const isValidPreset = DUE_DATE_PRESETS_OPTIONS.find((preset) => preset.value === daysFromNow);

  if (!isValidPreset) {
    return undefined;
  }

  return daysFromNow;
};

export const getDueDateFromToday = (daysToAdd: number) => {
  const today = moment().startOf('day');
  const nextDate = today.add(daysToAdd, 'days');
  return nextDate.format(DATE_FORMAT_SERVER);
};
