import { ForbiddenError } from '@mortgagehippo/auth';
import { useCustomizations, useOpenClose } from '@mortgagehippo/ds';
import { NotFoundError } from '@mortgagehippo/util';
import { sortBy } from 'lodash-es';
import { useMemo } from 'react';
import { Redirect, type RouteComponentProps } from 'react-router';

import { useApplicationFileCan } from '$components/permissions';

import { Content, Layout, Nav } from '../../../layouts/application';
import { useApplicationFile } from '../use-application-file-data';
import { ApplicationServicesContent } from './application-services-content';
import { ApplicationServicesSidebar } from './application-services-sidebar';
import { ApplicationServicesSkeleton } from './application-services-skeleton';
import { type IApplicationService } from './types';

interface IApplicationServicesRouteParams {
  applicationFileId: string;
  serviceId?: string;
}

export const ApplicationServicesRoute = (
  props: RouteComponentProps<IApplicationServicesRouteParams>
) => {
  const { match } = props;
  const { params } = match;
  const { applicationFileId, serviceId } = params;

  const [isMobileNavOpen, openMobileNav, closeMobileNav] = useOpenClose(false);

  const [can, canReady, { accessibleServices }] = useApplicationFileCan(applicationFileId);

  const [{ applicationFile, applicants, loading: applicationFileLoading }] =
    useApplicationFile(applicationFileId);

  const customizations = useCustomizations();

  const loading = applicationFileLoading || !canReady || customizations.loading();

  const services: IApplicationService[] = useMemo(() => {
    /*
     * Sort services by position and then by customized name
     * Doing this here as when we redirect to the first one
     * they already need to be ordered
     */
    const nextServices = accessibleServices.map((s) => ({
      ...s,
      position: s.position || 50,
      name: customizations.text(s.nameCid, s.name),
    }));

    return sortBy(nextServices, ['position', 'name']);
  }, [accessibleServices, customizations]);

  if (loading) {
    return <ApplicationServicesSkeleton />;
  }

  if (!applicationFile) {
    throw new NotFoundError();
  }

  if (!can.VIEW_SERVICES_TAB) {
    throw new ForbiddenError();
  }

  let service: IApplicationService | undefined;

  if (serviceId) {
    service = services.find((i) => i.path === serviceId);
  } else {
    const firstAccessibleService = services.find((i) => !!i.path);
    const redirectPath = firstAccessibleService?.path;

    if (redirectPath) {
      return <Redirect to={`#/applications/${applicationFileId}/integrations/${redirectPath}`} />;
    }
  }

  if (!service) {
    // either no redirect path or no service found
    throw new NotFoundError();
  }

  return (
    <Layout tutorialId="services" applicationFileId={applicationFileId}>
      <Nav
        mobileOpen={isMobileNavOpen}
        onMobileOpen={openMobileNav}
        onMobileClose={closeMobileNav}
        mobileNavTitle="Service List"
      >
        <ApplicationServicesSidebar applicationFileId={applicationFileId} services={services} />
      </Nav>
      <Content>
        <ApplicationServicesContent
          applicationFileId={applicationFileId}
          applicants={applicants}
          type={service.type}
        />
      </Content>
    </Layout>
  );
};
