import { useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';
import { useMemo } from 'react';

import { graphql } from '../../../../../apollo';

const QAusOverviewSection = graphql(`
  query AusOverviewSection($applicationFileId: ID!) {
    applicationFile(id: $applicationFileId) {
      id
      ausIntegrationTypes
      underwritingAssessments(options: { perPage: 1000, orderBy: { created_at: "desc" } }) {
        items {
          id
          recommendation
          createdAt
          type
        }
      }
    }
  }
`);

export const useAusOverviewSection = (applicationFileId: string) => {
  const [data, loading, , { refetch }] = useQuery(
    QAusOverviewSection,
    { applicationFileId },
    {
      suspend: false,
      fetchPolicy: 'network-only',
    }
  );

  const handleRefresh = useSafeCallback(() => refetch({ applicationFileId }));

  const result = useMemo(() => {
    const ausIntegrationTypes = data?.applicationFile?.ausIntegrationTypes || [];
    const underwritingAssessments = data?.applicationFile?.underwritingAssessments.items || [];

    return ausIntegrationTypes.map((type) => {
      const assessment = underwritingAssessments.find((a) => a.type === type);

      return {
        type,
        assessment,
      };
    });
  }, [data]);

  return [result, loading, handleRefresh] as const;
};
