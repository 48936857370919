import { ModalDialog, useCustomizations, useModal } from '@mortgagehippo/ds';
import { useApplicationFileApplicants, useSagaSubmitHandler } from '@mortgagehippo/tasks';
import { useCallback } from 'react';

import { hasAuthorizedJointCreditPull } from '../../../pages/application/application-services/services/credit-check';
import { useTriggerJointCreditCheck } from '../../../pages/application/use-trigger-joint-credit-check';
import { type IActionProps, type IJointCreditPullAction } from '../types';
import { JointCreditPullAuthorizationModal } from './joint-credit-pull-authorization-modal';

export const JointCreditPullAction = (props: IActionProps<IJointCreditPullAction>) => {
  const { action, onSubmit, onDone, onCancel } = props;
  const { applicationFileId } = action;

  const [isOpen, , closeModal] = useModal(true);

  const customizations = useCustomizations();
  const title = customizations.text('joint-credit-pull:confirm-title', 'Are you sure?');
  const explanation = customizations.text(
    'joint-credit-pull:confirm-explanation',
    'You are about to trigger a joint credit pull.'
  );

  const [{ data: applicants, loading }] = useApplicationFileApplicants(applicationFileId);

  const requireAuthorization = applicants.some((a) => !hasAuthorizedJointCreditPull(a.answers));

  const triggerJointCreditCheck = useTriggerJointCreditCheck();

  const [handleSubmit, running] = useSagaSubmitHandler({
    infoMessageCid: 'joint-credit-pull:info',
    successMessageCid: 'joint-credit-pull:success',
    errorMessageCid: 'joint-credit-pull:error',
    infoMessage: 'Processing joint credit pull',
    successMessage: 'Successfully processed joint credit pull',
    errorMessage: 'There was an unexpected error processing the joint credit pull',
    async onSubmit(_: any, setAsyncId) {
      const asyncId = await triggerJointCreditCheck(applicationFileId, requireAuthorization);
      closeModal(() => {
        setAsyncId(asyncId);
        onSubmit();
      });
    },
    async onDone() {
      closeModal(onDone);
    },
  });

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  if (loading || running) return null;

  if (requireAuthorization) {
    return (
      <JointCreditPullAuthorizationModal
        applicants={applicants}
        onSubmit={handleSubmit}
        onRequestClose={handleCloseModal}
        isOpen={isOpen}
      />
    );
  }

  // TODO: use hook useModalConfirm
  return (
    <ModalDialog
      type="info"
      title={title}
      onOk={handleSubmit as any}
      onRequestClose={handleCloseModal}
      isOpen={isOpen}
    >
      {explanation}
    </ModalDialog>
  );
};
