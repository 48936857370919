import { type AusType } from '../../../../../apollo/graphql';
import { useAusTypeLabel } from './use-aus-type-label';

interface IAusPageTypeLabelProps {
  type: AusType;
}

export const AusPageTypeLabel = (props: IAusPageTypeLabelProps) => {
  const { type } = props;

  const typeLabel = useAusTypeLabel(type);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{typeLabel}</>;
};
