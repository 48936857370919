import {
  Button,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  MediaBreakpoint,
  Responsive,
  spacing,
  useCustomizations,
} from '@mortgagehippo/ds';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import styled, { css } from 'styled-components';

import {
  type IApplicationApplicant,
  type IApplicationFile,
} from '../../pages/application/use-application-file-data';
import { type IPrimaryApplicantApplicationFile } from '../../pages/application/use-primary-applicants-files';
import { ApplicationHeaderApplicants } from './application-header-applicants';
import { ApplicationHeaderStatus } from './application-header-status';
import { ApplicationHeaderTitle } from './application-header-title';

const Container = styled.div`
  display: flex;
  align-items: flex-start;

  ${MediaBreakpoint.PHONE} {
    flex-direction: column;
  }
`;

const TitleColumn = styled.div`
  flex: 0 0 auto;
  margin-right: ${spacing(6)};

  ${MediaBreakpoint.PHONE} {
    flex: 1 1 auto;
    padding-left: ${spacing(1)};
    margin: 0;
    width: 100%;
  }
`;

const ApplicantsColumn = styled.div`
  flex: 1 1 auto;

  ${MediaBreakpoint.PHONE} {
    padding: 0 0 ${spacing(5)} ${spacing(1)};
    width: 100%;
  }
`;

const StatusColumn = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  align-self: center;

  ${MediaBreakpoint.PHONE} {
    padding: 0 0 ${spacing(5)} ${spacing(1)};
    width: 100%;
  }
`;

const MobileTitleButton = styled(Button)<{ isExpanded: boolean }>`
  &&& {
    font-size: ${fontSize('sm')};
    line-height: ${lineHeight('normal')};
    padding: 0 ${spacing(2)} ${spacing(1)};
    margin-left: -${spacing(2)};

    svg {
      position: relative;
      margin-left: ${spacing(1)};
      top: 0;
    }

    ${(p) =>
      (!p.isExpanded &&
        css`
          font-family: ${fontFamily('secondary')};
          font-weight: ${fontWeight('semibold', 'secondary')};
          font-size: ${fontSize('md')};
          svg {
            margin-left: ${spacing(2)};
            top: 2px;
          }
        `) ||
      ''}
  }
`;

const ExpandableMobileTitle = styled('div')`
  overflow: hidden;
`;

interface IApplicationHeaderContentProps {
  applicationFile: IApplicationFile;
  applicants: IApplicationApplicant[];
  primaryApplicantsFiles: IPrimaryApplicantApplicationFile[];
  showApplicationStatus?: boolean;
  disableApplicationStatus?: boolean;
  onStartApplication?: () => void;
  onUpdateApplicantEmail?: (applicantId: string) => void;
  onDeleteApplicant?: (applicant: string) => void;
}

export const ApplicationHeaderContent = (props: IApplicationHeaderContentProps) => {
  const {
    applicationFile,
    applicants,
    primaryApplicantsFiles,
    showApplicationStatus,
    disableApplicationStatus,
    onStartApplication,
    onUpdateApplicantEmail,
    onDeleteApplicant,
  } = props;
  const { id: applicationFileId, losType, losLoanNumber, activeLenderMilestone } = applicationFile;

  const expandableTitleRef = useRef<HTMLDivElement>(null);

  const [expanded, setExpanded] = useState(false);

  const customizations = useCustomizations();
  const expandButtonInverted = customizations.bool('app:application.header.buttons.inverted', true);

  const mainApplicant = applicants.find((applicant) => applicant.primary);
  const pageTitle = mainApplicant
    ? `${mainApplicant.name} | Application ${applicationFileId}`
    : `Application ${applicationFileId}`;

  const lenderMilestone = activeLenderMilestone?.name;

  const showStatusColumn = showApplicationStatus || !!losType || !!lenderMilestone;

  const handleExpandTitleClick = useCallback(() => {
    const newExpanded = !expanded;
    setExpanded(newExpanded);
    if (newExpanded && expandableTitleRef.current) {
      expandableTitleRef.current.focus();
    }
  }, [expanded]);

  const headerContent = (
    <>
      <TitleColumn>
        <ApplicationHeaderTitle
          applicationFile={applicationFile}
          primaryApplicantsFiles={primaryApplicantsFiles}
          onStartApplication={onStartApplication}
        />
      </TitleColumn>
      <ApplicantsColumn>
        <ApplicationHeaderApplicants
          applicants={applicants}
          onUpdateApplicantEmail={onUpdateApplicantEmail}
          onDeleteApplicant={onDeleteApplicant}
        />
      </ApplicantsColumn>
      {showStatusColumn ? (
        <StatusColumn>
          <ApplicationHeaderStatus
            applicationFileId={applicationFileId}
            losType={losType}
            losNumber={losLoanNumber}
            lenderMilestone={lenderMilestone}
            showApplicationStatus={showApplicationStatus}
            disableApplicationStatus={disableApplicationStatus}
          />
        </StatusColumn>
      ) : null}
    </>
  );

  return (
    <Container>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Responsive>
        <Responsive.Phone>
          <ExpandableMobileTitle
            role="region"
            tabIndex={-1}
            id="application-title-mobile-region"
            ref={expandableTitleRef}
          >
            <AnimatePresence initial={false}>
              {expanded ? (
                <motion.div
                  transition={{ ease: 'easeInOut', duration: 300 / 1000 }}
                  key="application-title-mobile-region"
                  initial={{ height: 0 }}
                  animate={{ height: 'auto' }}
                  exit={{ height: 0 }}
                >
                  {headerContent}
                </motion.div>
              ) : null}
            </AnimatePresence>
          </ExpandableMobileTitle>
          <MobileTitleButton
            onClick={handleExpandTitleClick}
            aria-expanded={(expanded && 'true') || 'false'}
            aria-controls="application-title-mobile-region"
            size="normal"
            icon={(expanded && 'up-arrow-small') || 'menu-dots'}
            iconLocation="right"
            compact
            inverted={expandButtonInverted}
            importance="tertiary"
            isExpanded={expanded}
            type="neutral"
          >
            {expanded ? 'show less' : mainApplicant?.name || 'Anonymous'}
          </MobileTitleButton>
        </Responsive.Phone>
        <Responsive.Desktop>{headerContent}</Responsive.Desktop>
      </Responsive>
    </Container>
  );
};
