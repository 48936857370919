import { type FontSize, Icon, Tag } from '@mortgagehippo/ds';
import { isNil } from 'lodash-es';
import { type ReactNode } from 'react';
import styled from 'styled-components';

import { toTagColorProps } from './util';

const DropdownOptionCheckbox = styled.span`
  display: inline-block;
  width: 20px;
  margin-left: -10px;
`;

interface ApplicationStatusDropdownOptionProps {
  active: boolean;
  color?: number;
  size?: FontSize;
  children?: ReactNode;
}

export const ApplicationStatusDropdownOption = (props: ApplicationStatusDropdownOptionProps) => {
  const { active, color: propsColor, children, size } = props;

  const { color, inverted } = !isNil(propsColor)
    ? toTagColorProps(propsColor)
    : ({ color: 'neutral', inverted: undefined } as const);

  return (
    <div>
      <DropdownOptionCheckbox>
        {active ? <Icon name="check" size="sm" /> : null}
      </DropdownOptionCheckbox>
      <Tag color={color} inverted={inverted} size={size || 'sm'}>
        {children}
      </Tag>
    </div>
  );
};
