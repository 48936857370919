import type { PricingScenarioInput } from '@mortgagehippo/tasks/build/gql/graphql';

export const toInitialValues = (pricingScenario: any) => {
  const {
    calculated_loan_amount,
    loan_type,
    tri_merge_credit_score,
    cash_out_amount,
    citizenship,
    construction,
    foreclosure,
    bankrupt,
    collateral,
    down_payment,
  } = pricingScenario;

  const { property_type_name, property_usage_type, number_of_units, county_name, postal_code } =
    collateral || {};

  return {
    loan_amount: calculated_loan_amount,
    loan_type,
    credit_score: tri_merge_credit_score,
    cash_out_amount,
    citizenship_type: citizenship,
    is_construction_loan: construction,
    property_foreclosed: foreclosure,
    has_declared_bankruptcy: bankrupt,
    property_type: property_type_name,
    property_usage_type,
    number_of_units,
    county: county_name,
    zip_code: postal_code,
    down_payment,
  };
};

export const toServerInput = (values: any): PricingScenarioInput | undefined => {
  if (!values) {
    return undefined;
  }

  const {
    loan_amount,
    loan_type,
    credit_score,
    cash_out_amount,
    citizenship_type,
    is_construction_loan,
    property_foreclosed,
    has_declared_bankruptcy,
    property_type,
    property_usage_type,
    number_of_units,
    county,
    zip_code,
    down_payment,
  } = values;

  return {
    citizenshipType: citizenship_type,
    propertyForeclosed: property_foreclosed,
    hasDeclaredBankruptcy: has_declared_bankruptcy,
    zipCode: zip_code,
    propertyUsageType: property_usage_type,
    county,
    propertyType: property_type,
    numberOfUnits: number_of_units,
    isConstructionLoan: is_construction_loan,
    loanType: loan_type,
    loanAmount: loan_amount,
    downPayment: down_payment,
    creditScore: credit_score,
    cashOutAmount: cash_out_amount,
  };
};
