import { Alert, Content } from '@mortgagehippo/ds';

export const PasswordTabDisabled = () => (
  <Alert type="info">
    <Content>
      You are signed in with an account managed by an external provider (e.g. your online banking or
      a social account). Please use provider’s account management feature to change your password.
    </Content>
  </Alert>
);
