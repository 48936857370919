import { ModalDialog, T, useCustomizations, useModal } from '@mortgagehippo/ds';
import { useSagaClickHandler } from '@mortgagehippo/tasks';
import { useCallback, useEffect } from 'react';

import { useTriggerLPAAssessment } from '../../../pages/application/use-trigger-lpa-assessment';
import { type IActionProps, type IGenerateLPAAssessmentAction } from '../types';

export const GenerateLPAAssessmentAction = (props: IActionProps<IGenerateLPAAssessmentAction>) => {
  const { action, onSubmit, onDone, onCancel } = props;
  const { applicationFileId, confirm } = action;

  const [isOpen, , closeModal] = useModal(true);

  const customizations = useCustomizations();
  const title = customizations.text('generate-lpa-assessment:confirm-title', 'Are you sure?');
  const explanation = customizations.text(
    'generate-lpa-assessment:confirm-explanation',
    'You are about to push the application to LPA.'
  );

  const triggerLPAAssessment = useTriggerLPAAssessment();

  const [handleOk, running] = useSagaClickHandler({
    infoMessageCid: 'generate-lpa-assessment:info',
    successMessageCid: 'generate-lpa-assessment:success',
    errorMessageCid: 'generate-lpa-assessment:error',
    infoMessage: 'Pushing to LPA',
    successMessage: 'Successfully pushed to LPA',
    errorMessage: 'There was an unexpected error pushing to LPA',
    async onClick(setAsyncId) {
      const asyncId = await triggerLPAAssessment(applicationFileId);

      closeModal(() => {
        setAsyncId(asyncId);
        onSubmit();
      });
    },
    async onDone() {
      onDone();
    },
  });

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  useEffect(() => {
    if (!confirm && !running) {
      handleOk();
    }
  }, [confirm, handleOk, running]);

  if (running || !confirm) return null;

  // TODO: use hook useModalConfirm
  return (
    <ModalDialog
      type="info"
      title={title}
      onOk={handleOk}
      okButtonLabel="Yes"
      onRequestClose={handleCloseModal}
      isOpen={isOpen}
    >
      {explanation} <T>If this requires a separate credit pull, charges will be incurred.</T>
      <br />
      <br />
      <T>Would you like to proceed?</T>
    </ModalDialog>
  );
};
