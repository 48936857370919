import { ForbiddenError } from '@mortgagehippo/auth';
import { useCustomizations, useResponsive } from '@mortgagehippo/ds';

import { useUserCan } from '$components/permissions';

import { Content, Layout } from '../../layouts/main';
import { ArchivePageContent } from './archive-content';

export const ArchivePage = () => {
  const responsive = useResponsive();

  const [can, canReady] = useUserCan();

  const customizations = useCustomizations();
  const title = customizations.text('pageArchive:title', 'Archive');

  if (!canReady) {
    return null;
  }

  if (!can.VIEW_ARCHIVE_PAGE) {
    throw new ForbiddenError();
  }

  return (
    <Layout pageTitle={title} compact={responsive.PHONE.BIGGER}>
      <Content>
        <ArchivePageContent title={title} />
      </Content>
    </Layout>
  );
};
