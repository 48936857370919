import {
  type FormSubmitHandler,
  ModalForm,
  notifications,
  T,
  useCustomizations,
  useModal,
} from '@mortgagehippo/ds';
import { type IDocusignTask, useTask } from '@mortgagehippo/tasks';
import { startCase } from '@mortgagehippo/util';
import { useCallback, useMemo } from 'react';

import { CustomTaskInitialFields } from '../custom-task-initial-fields';
import { type ICustomTaskTypeProps } from '../types';
import { useUpdateDocusignTask } from './use-update-docusign-task';

interface ICustomDocusignTaskFormValues {
  description?: string;
  title?: string;
  due_date?: string | null;
}

export const CustomDocusignTask = (props: ICustomTaskTypeProps) => {
  const { taskId, onDone, onCancel, showDueDate } = props;

  const [isOpen, , closeModal] = useModal(true);

  const [task, , taskLoading] = useTask<IDocusignTask>(taskId);

  const customizations = useCustomizations();
  const updateTask = useUpdateDocusignTask();

  const modalTitle = customizations.text('custom_task:docusign_task', 'Sign Documents Task');

  const initialValues: ICustomDocusignTaskFormValues = useMemo(() => {
    if (task) {
      return {
        title: task.meta.title,
        description: task.data.panel?.description,
        due_date: task.dueDate,
      };
    }

    return {};
  }, [task]);

  const handleUpdate: FormSubmitHandler<ICustomDocusignTaskFormValues> = async (nextValues) => {
    try {
      const { title, description, due_date } = nextValues;

      const data = {
        title: startCase(title)!,
        description: description!,
        dueDate: due_date,
      };

      await updateTask(taskId!, data);

      closeModal(() => {
        notifications.success({
          messageCid: 'custom_task:docusign_task_update_success_msg',
          message: 'Successfully updated task',
        });
        onDone();
      });

      return undefined;
    } catch (e) {
      console.error(e);
      notifications.error({
        messageCid: 'custom_task:docusign_task_update_failure_msg',
        message: 'There was an unexpected error updating the task',
      });

      return 'There was an unexpected error updating the task';
    }
  };

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  return (
    <ModalForm
      onSubmit={handleUpdate}
      width="medium"
      isOpen={isOpen}
      loading={taskLoading}
      title={modalTitle}
      onRequestClose={handleCloseModal}
      initialValues={initialValues}
      secondaryActionButtonPosition="left"
      okButtonLabel={<T>Update task</T>}
      disableOverlayClickClose
    >
      <CustomTaskInitialFields showDueDate={showDueDate} />
    </ModalForm>
  );
};
