import { ButtonRouterLink, Card, fontSize, MediaBreakpoint, spacing, T } from '@mortgagehippo/ds';
import { PricingLockAlert } from '@mortgagehippo/tasks';
import { useContext, useEffect } from 'react';
import styled from 'styled-components';

import { useApplicationFileCan } from '../../../../../components/permissions';
import { ApplicationOverviewContext } from '../../application-overview-context';
import { type IApplicationOverviewSectionProps } from '../../types';
import { BlockContentContainer, BlockLabel, SectionBlock } from '../common';
import { ApplicationOverviewPricingSectionProduct } from './application-overview-pricing-section-product';
import { usePricingOverviewSection } from './use-pricing-overview-section';

const StyledSectionBlock = styled(SectionBlock)`
  flex-wrap: wrap;
`;

const WideBlockContent = styled(BlockContentContainer)`
  ${MediaBreakpoint.TABLET} {
    padding-top: ${spacing(2)};
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex: 1 1 100%;

  ${MediaBreakpoint.TABLET} {
    justify-content: flex-start;
    flex-direction: column;
  }
`;

const RightFlex = styled.div<{
  justifyContent?: 'flex-end' | 'flex-start';
}>`
  display: flex;
  flex-direction: column;
  justify-content: ${(p) => (p.justifyContent ? p.justifyContent : 'space-between')};
  flex: 0 0 auto;
  align-items: flex-end;
  padding-left: ${spacing(2)};

  ${MediaBreakpoint.TABLET} {
    display: block;
    padding-left: 0;
  }
`;

const RateLock = styled.div`
  flex: 0 0 auto;
  font-size: ${fontSize('xs')};
  line-height: ${fontSize('xs')};
  text-align: right;

  ${MediaBreakpoint.TABLET} {
    flex: 1 1 100%;
    padding-bottom: ${spacing(4)} !important;
  }
`;

const StyledButtonRouterLink = styled(ButtonRouterLink)`
  &&& {
    font-size: ${fontSize('xs')};
    line-height: ${fontSize('xs')};
    text-align: right;
    flex: 0 0 25%;

    ${MediaBreakpoint.TABLET} {
      display: block;
    }
  }
`;

export const ApplicationOverviewPricingSection = (props: IApplicationOverviewSectionProps) => {
  const { id, applicationFileId, className } = props;

  const { sectionReady, sectionDisabled, ready } = useContext(ApplicationOverviewContext)!;

  const [can, canReady] = useApplicationFileCan(applicationFileId);
  const [{ selectedPricingLoan }, sectionLoading] = usePricingOverviewSection(applicationFileId);

  useEffect(() => {
    if (sectionLoading || !canReady) {
      return;
    }

    sectionReady(id);
  }, [sectionLoading, id, sectionReady, sectionDisabled, canReady]);

  if (!ready) {
    return null;
  }

  const { lockExpiresAt, lockStatus } = selectedPricingLoan || {};

  const pricingUrl = `#/applications/${applicationFileId}/pricing`;

  const canViewPricingTab = can.VIEW_PRICING_TAB;

  let rightFlexJustify: 'flex-end' | 'flex-start' | undefined;
  if (!lockStatus && canViewPricingTab) {
    rightFlexJustify = 'flex-end';
  } else if (lockStatus && !canViewPricingTab) {
    rightFlexJustify = 'flex-start';
  }

  return (
    <Card compact className={className}>
      <StyledSectionBlock>
        <BlockLabel maxWidth>Loan Product</BlockLabel>
        <WideBlockContent>
          <Flex>
            <ApplicationOverviewPricingSectionProduct selectedPricingLoan={selectedPricingLoan} />

            <RightFlex justifyContent={rightFlexJustify}>
              {lockStatus ? (
                <RateLock>
                  <PricingLockAlert
                    lockStatus={lockStatus}
                    expiresAt={lockExpiresAt}
                    showLockErrors={false}
                    variant="secondary"
                  />
                </RateLock>
              ) : null}
              {canViewPricingTab ? (
                <StyledButtonRouterLink
                  to={pricingUrl}
                  icon="right-arrow"
                  iconLocation="right"
                  importance="tertiary"
                  size="xs"
                  compact
                >
                  <T>Open Loan Pricer</T>
                </StyledButtonRouterLink>
              ) : null}
            </RightFlex>
          </Flex>
        </WideBlockContent>
      </StyledSectionBlock>
    </Card>
  );
};
