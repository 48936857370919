import { type FormSubmitHandler, ModalForm } from '@mortgagehippo/ds';

import {
  type IUploadDocumentsModalFormProps,
  UploadDocumentsModalForm,
} from './upload-documents-modal-form';

interface IUploadDocumentsModalProps extends IUploadDocumentsModalFormProps {
  isOpen: boolean;
  loading: boolean;
  title: string;
  onSubmit: FormSubmitHandler;
  onRequestClose: () => void;
  width?: number;
  submitDisabled?: boolean;
}

export const UploadDocumentsModal = (props: IUploadDocumentsModalProps) => {
  const {
    title,
    onRequestClose,
    onSubmit,
    isOpen,
    width,
    loading,
    submitDisabled,
    applicants,
    tasks,
    onUpload,
  } = props;

  return (
    <ModalForm
      loading={loading}
      title={title}
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      width={width}
      onSubmit={onSubmit}
      okButtonDisabled={submitDisabled}
      okButtonLabel="Save"
    >
      <UploadDocumentsModalForm applicants={applicants} tasks={tasks} onUpload={onUpload} />
    </ModalForm>
  );
};
