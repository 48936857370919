import {
  Checkbox,
  Col,
  Email,
  fontSize,
  HelpButton,
  Input,
  Phone,
  Row,
  spacing,
  useFormValue,
  YesNo,
} from '@mortgagehippo/ds';
import { isNil } from 'lodash-es';
import { useEffect } from 'react';
import styled from 'styled-components';

const ShareEmailCheckbox = styled(Checkbox)`
  font-size: ${fontSize('sm')};
  margin-top: ${spacing(2)};
  margin-bottom: ${spacing(5)};
`;

interface IAddApplicantModalFormProps {
  forceSharedTasks?: boolean;
  allowSharedTasks?: boolean;
  allowSharedTasksNonSpouse?: boolean;
  allowSharedEmailAddress?: boolean;
}

export const AddApplicantModalForm = (props: IAddApplicantModalFormProps) => {
  const { forceSharedTasks, allowSharedTasks, allowSharedTasksNonSpouse, allowSharedEmailAddress } =
    props;

  const [isSpouse] = useFormValue<boolean | undefined>('is_spouse');
  const [shareTasks, setShareTasks] = useFormValue<boolean | undefined>('share_tasks');
  const [, setEmail] = useFormValue<string | undefined>('email');
  const [shareEmail, setShareEmail] = useFormValue<boolean | undefined>('share_email');
  const [, setSendInvitation] = useFormValue<boolean | undefined>('send_invitation');

  const taskSharingEnabled = forceSharedTasks || allowSharedTasks;
  const showShareTasksQuestion =
    !forceSharedTasks && allowSharedTasks && (allowSharedTasksNonSpouse || isSpouse);
  const isSharingTasks = forceSharedTasks || shareTasks;

  const showEmailField =
    !allowSharedEmailAddress ||
    !taskSharingEnabled ||
    forceSharedTasks ||
    (!showShareTasksQuestion && !isNil(isSpouse)) ||
    (showShareTasksQuestion && !isNil(shareTasks));
  const showShareEmailCheckbox = isSharingTasks && allowSharedEmailAddress;

  const showInvitationField = showEmailField && !shareEmail;

  useEffect(() => {
    if (shareEmail) {
      setEmail(undefined);
    }
  }, [setEmail, shareEmail]);

  useEffect(() => {
    if (!showShareTasksQuestion) {
      setShareTasks(false);
    }
  }, [setShareTasks, showShareTasksQuestion]);

  useEffect(() => {
    if (!showShareEmailCheckbox) {
      setShareEmail(false);
    }
  }, [setShareEmail, showShareEmailCheckbox]);

  useEffect(() => {
    if (!showInvitationField) {
      setSendInvitation(false);
    }
  }, [setSendInvitation, showInvitationField]);

  return (
    <>
      <Row compact>
        <Col md={12}>
          <Input.Box name="first_name" label="First Name" required />
        </Col>
        <Col md={12}>
          <Input.Box name="last_name" label="Last Name" required />
        </Col>
      </Row>
      <Phone.Box name="cell_phone_number" label="Cell Phone" />
      <YesNo.Box
        name="is_spouse"
        label="Is this person the spouse of the primary borrower?"
        required
      />
      {showShareTasksQuestion ? (
        <YesNo.Box
          name="share_tasks"
          label="Should the primary borrower be able to complete the co-borrower's part of the application?"
          required
          initialValue={false}
        />
      ) : null}
      {showEmailField ? (
        <>
          <Email.Box
            label="Email"
            name="email"
            required={!shareEmail}
            disabled={shareEmail}
            compact={showShareEmailCheckbox}
          />
          {showShareEmailCheckbox ? (
            <ShareEmailCheckbox name="share_email">
              The co-borrower does not have an email or they share the same email with the primary
              borrower.
              <HelpButton size="xs">
                <p>
                  If you provide an email address for the co-borrower, they will be able to sign in
                  using a separate account and receive notifications, upload and sign documents
                  electronically.
                </p>
                <p>
                  If the co-borrower does not have an email or they share the same email address
                  with the primary borrower, they will share a single account.
                </p>
              </HelpButton>
            </ShareEmailCheckbox>
          ) : null}
        </>
      ) : null}
      {showInvitationField ? (
        <YesNo.Box
          name="send_invitation"
          label="Send invitation email to borrower?"
          initialValue={false}
          required
        />
      ) : null}
    </>
  );
};
