import { type ISearchFilter, type TableColSortDirection } from '@mortgagehippo/ds';
import { Storage } from '@mortgagehippo/util';
import { useCallback, useRef } from 'react';

interface ISmartviewStoredData {
  sortColumn?: string;
  sortDirection?: TableColSortDirection;
  searchFilters?: ISearchFilter[];
}

const storage = new Storage('local');

const getStoredValue = (localStorageKey?: string): ISmartviewStoredData | undefined => {
  if (!localStorageKey) {
    return undefined;
  }

  try {
    const value = storage.get(localStorageKey, null);

    return value ? JSON.parse(value) : undefined;
  } catch (e) {
    return undefined;
  }
};

export const useSmartviewStorage = (localStorageKey?: string) => {
  const storedValuesRef = useRef<ISmartviewStoredData | undefined>(getStoredValue(localStorageKey));

  const setStoredValue = useCallback(
    (value: Partial<ISmartviewStoredData>) => {
      // get the full value from storage again in case something changed from outside...
      const prevValue = getStoredValue(localStorageKey);

      const nextValue = {
        ...(prevValue || {}),
        ...value,
      };

      storedValuesRef.current = nextValue;

      if (!localStorageKey) {
        return;
      }

      try {
        storage.set(localStorageKey, JSON.stringify(nextValue));
      } catch (e) {
        //
      }
    },
    [localStorageKey]
  );

  return [storedValuesRef.current, setStoredValue, storedValuesRef] as const;
};
