import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo';

export const MUpdateApplicationLenderMilestones = graphql(`
  mutation IUpdateApplicationLenderMilestones(
    $applicationFileId: ID!
    $completedMilestoneIds: [ID!]!
    $activeMilestoneId: ID
  ) {
    updateApplicationLenderMilestones(
      applicationFileId: $applicationFileId
      completedMilestoneIds: $completedMilestoneIds
      activeMilestoneId: $activeMilestoneId
    )
  }
`);

export const useUpdateLenderMilestones = (applicationFileId: string) => {
  const updateLenderMilestones = useMutation(MUpdateApplicationLenderMilestones);

  return useCallback(
    async (completedMilestoneIds: string[], activeMilestoneId: string | null = null) => {
      const response = await updateLenderMilestones({
        applicationFileId,
        completedMilestoneIds,
        activeMilestoneId,
      });
      return response.data!.updateApplicationLenderMilestones;
    },
    [applicationFileId, updateLenderMilestones]
  );
};
