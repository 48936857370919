import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { graphql } from '../apollo';
import { type SmartviewModelType } from '../apollo/graphql';
import { usePartnerDomain } from './use-partner-domain';

const QSmartviews = graphql(`
  query Smartviews($partnerId: ID!, $model: SmartviewModelType!) {
    partner(id: $partnerId) {
      id
      smartviews(model: $model) {
        ...SmartviewFragment
      }
    }
  }
`);

export const useSmartviews = (model: SmartviewModelType, options: IUseApolloQueryOptions = {}) => {
  const partnerId = usePartnerDomain();
  const [data, loading, , { refetch }] = useQuery(
    QSmartviews,
    {
      partnerId,
      model,
    },
    {
      fetchPolicy: 'network-only',
      ...options,
    }
  );

  const handleRefetch = useSafeCallback(async () => refetch());

  const smartviews = data?.partner?.smartviews || [];

  return [smartviews, loading, handleRefetch] as const;
};
