export const isApplicationFileAlerted = (applicationFile: {
  id: string;
  archivedAt?: string | null;
}) => {
  if (applicationFile.archivedAt) {
    return true;
  }

  // add more alerts

  return false;
};
