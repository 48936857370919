import { ArchivedApplicationFilesSmartview } from '$components/smart-view';

const LOCAL_STORAGE_KEY = 'lp:archive-pipeline';

interface IArchivePageContentProps {
  title: string;
}

export const ArchivePageContent = (props: IArchivePageContentProps) => {
  const { title } = props;

  return (
    <ArchivedApplicationFilesSmartview
      ariaLabel={title}
      caption={title}
      localStorageKey={LOCAL_STORAGE_KEY}
    />
  );
};
