import type { ISelectOption } from '@mortgagehippo/ds';

const getDueDatePresetsOptions = (min: number, max: number) => {
  const options: ISelectOption[] = [];

  for (let i = min; i <= max; i += 1) {
    let label;
    let selectedLabel;

    switch (i) {
      case 0:
        label = 'Today';
        selectedLabel = 'Due today';
        break;
      case 1:
        label = 'Tomorrow';
        selectedLabel = 'Due tomorrow';
        break;
      default:
        label = `${i} days`;
        selectedLabel = `Due in ${i} days`;
        break;
    }

    options.push({
      label,
      value: i,
      selectedLabel,
    });
  }

  return options;
};

export const DEFAULT_DUE_DATE_DAYS_FROM_NOW = 3;

export const DUE_DATE_PRESETS_OPTIONS: ISelectOption[] = getDueDatePresetsOptions(0, 20);
