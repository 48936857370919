import { useMutation } from '@mortgagehippo/apollo-hooks';
import { notifications } from '@mortgagehippo/ds';
import { useCallback } from 'react';

import { graphql } from '../../apollo';

const DEFAULT_FILE_NAME = 'fannie-mae-file.fnm';

const downloadFile = (contents: string, filename: string = DEFAULT_FILE_NAME) => {
  const href = `data:text/plain;charset=utf-8,${encodeURIComponent(contents)}`;

  const downloadLink = document.createElement('a');
  downloadLink.href = href;
  downloadLink.download = filename;
  downloadLink.click();
};

const MGenerateFannieMaeFile = graphql(`
  mutation GenerateFannieMaeFile($applicationFileId: ID!) {
    generateFannieMaeFile(applicationFileId: $applicationFileId) {
      file
      filename
    }
  }
`);

export const useDownloadFannieMaeFile = (applicationFileId: string) => {
  const generateFannieMaeFile = useMutation(MGenerateFannieMaeFile, { applicationFileId });

  return useCallback(async () => {
    const key = notifications.info({
      message: 'Generating Fannie Mae File',
      duration: 0,
      loading: true,
    });

    try {
      const response = await generateFannieMaeFile();

      const result = response?.data?.generateFannieMaeFile || {
        file: undefined,
        filename: undefined,
      };
      const { file, filename } = result;

      if (!file) {
        notifications.error({
          key,
          message: 'There was a problem generating your Fannie Mae file.',
          loading: false,
        });
        return;
      }

      downloadFile(file, filename);
      notifications.success({
        key,
        message: 'Fannie Mae file was successfully generated and should automatically download.',
        loading: false,
        duration: 'auto',
      });
    } catch (e) {
      notifications.error({
        key,
        message: 'There was a problem generating your Fannie Mae file.',
        loading: false,
      });
    }
  }, [generateFannieMaeFile]);
};
