import { useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { graphql } from '../../../../../apollo';

const QMilestonesOverviewSection = graphql(`
  query MilestonesOverviewSection($applicationFileId: ID!) {
    applicationFile(id: $applicationFileId) {
      id
      milestones {
        id
        key
        name
        position
        active
      }
      pipelineData {
        percentComplete
      }
    }
  }
`);

export const useMilestonesOverviewSection = (applicationFileId: string) => {
  const [data, loading, , { refetch }] = useQuery(
    QMilestonesOverviewSection,
    { applicationFileId },
    {
      suspend: false,
      fetchPolicy: 'network-only',
    }
  );

  const handleRefresh = useSafeCallback(() => refetch({ applicationFileId }));

  const milestones = data?.applicationFile?.milestones || [];
  const pipelineData = data?.applicationFile?.pipelineData;

  return [{ milestones, pipelineData }, loading, handleRefresh] as const;
};
