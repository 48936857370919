import { notifications, type UploadHandler, useCustomizations, useModal } from '@mortgagehippo/ds';
import { isInsecureFileUploadError, useScanDocument } from '@mortgagehippo/tasks';
import axios, { type AxiosProgressEvent } from 'axios';
import { useCallback, useState } from 'react';

import { type DocumentInput } from '../../../apollo/graphql';
import { useApplicationFile } from '../../../pages/application/use-application-file-data';
import { useApplicationFileTasks } from '../../../pages/application/use-application-file-tasks';
import { useCreateLenderDocuments } from '../../../pages/application/use-create-lender-documents';
import { useLenderDocumentSubmitUrl } from '../../../pages/application/use-lender-document-submit-url';
import { useSubmitDocuments } from '../../../pages/application/use-submit-documents';
import { type IActionProps, type IUploadDocumentsAction } from '../types';
import { UploadDocumentsModal } from './upload-documents-modal';

export const UploadDocumentsAction = (props: IActionProps<IUploadDocumentsAction>) => {
  const { action, onDone, onCancel } = props;
  const { applicationFileId } = action;

  const [submitDisabled, setSubmitDisabled] = useState<boolean | undefined>();

  const [{ applicants, loading: applicationFileLoading }] = useApplicationFile(applicationFileId);

  const [{ tasks }, tasksLoading] = useApplicationFileTasks(applicationFileId);

  const loading = applicationFileLoading || tasksLoading;

  const customizations = useCustomizations();
  const modalTitle = customizations.text('uploadDocumentsModal:title', 'Upload Documents');

  const getSubmitUrl = useLenderDocumentSubmitUrl();
  const scanDocument = useScanDocument();
  const submitDocuments = useSubmitDocuments();
  const createLenderDocuments = useCreateLenderDocuments();

  const [isOpen, , closeModal] = useModal(true);

  const handleSubmit = async (values: any) => {
    try {
      const { task_id: taskId } = values;

      const documents: DocumentInput[] = (values.documents || []).map(
        ({ id, description }: any) => ({
          id,
          description,
        })
      );

      if (taskId) {
        await submitDocuments(taskId, documents);
      } else {
        await createLenderDocuments(applicationFileId, documents);
      }

      closeModal(() => {
        notifications.success({
          messageCid: 'uploadDocumentsModal:notification.create.success',
          message: 'Successfully uploaded documents',
        });

        onDone();
      });
    } catch (e: unknown) {
      console.error(e);
      notifications.error({
        messageCid: 'uploadDocumentsModal:notification.create.error',
        message: 'There was an unexpected error creating the documents',
      });
    }
  };

  const handleUpload: UploadHandler = async (file, meta, progress) => {
    try {
      setSubmitDisabled(true);
      const { id: uploadId, postUrl } = await getSubmitUrl(applicationFileId, meta.filename);

      await axios.put(postUrl, file, {
        headers: {
          'Content-Type': meta.mime,
        },
        onUploadProgress: (e: AxiosProgressEvent) => {
          if (!e.total) return;
          const percent = Math.round((e.loaded * 100) / e.total);
          progress(percent);
        },
      });

      try {
        await scanDocument(uploadId);
      } catch (e: unknown) {
        if (isInsecureFileUploadError(e)) {
          return { id: uploadId, hasVirus: true };
        }
        throw e;
      }

      return { id: uploadId };
    } catch (e) {
      notifications.error({
        messageCid: 'uploadDocumentsModal:notification.upload.error',
        message: 'There was an unexpected error while uploading your file',
      });
      throw e;
    } finally {
      setSubmitDisabled(false);
    }
  };

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  return (
    <UploadDocumentsModal
      isOpen={isOpen}
      loading={loading}
      title={modalTitle}
      applicants={applicants}
      tasks={tasks}
      onUpload={handleUpload}
      onSubmit={handleSubmit}
      onRequestClose={handleCloseModal}
      submitDisabled={submitDisabled}
    />
  );
};
