import { Format, Tag } from '@mortgagehippo/ds';
import styled from 'styled-components';

import { WorkNumberReportStatusCode } from '../../../apollo/graphql';
import { type IWorkNumberReport } from './use-request-work-number-instant-report';
import { WorkNumberInstantReportEmptyResult } from './worknumber-instant-report-empty-result';
import { WorkNumberInstantReportErrorResult } from './worknumber-instant-report-error-result';

const EmployerHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const EmployerName = styled.h4`
~ flex: 0 0 auto;
  margin: 0 0.5em 0 0;
`;

const EmployerStatusType = styled.div`
  flex: 0 0 auto;
`;

const EmployerDetails = styled.dl`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  div {
    flex: 1 1 33%;
    padding-right: 1em;
  }

  dt {
    display: block;
    white-space: nowrap;
    font-weight: bold;
  }

  dd {
    display: block;
    white-space: nowrap;
  }
`;

export interface IWorkNumberInstantReportResultsProps {
  report?: IWorkNumberReport;
  error?: any;
}

export const WorkNumberInstantReportResult = (props: IWorkNumberInstantReportResultsProps) => {
  const { report, error } = props;

  if (!report) return null;

  const { statusCode } = report;

  if (statusCode === WorkNumberReportStatusCode.SALARY_KEY_REQUIRED) {
    return null;
  }

  if (statusCode === WorkNumberReportStatusCode.ERROR || error) {
    return <WorkNumberInstantReportErrorResult report={report} error={error} />;
  }

  if (
    statusCode === WorkNumberReportStatusCode.NOTFOUND ||
    (report && report.employers.length === 0)
  ) {
    return <WorkNumberInstantReportEmptyResult />;
  }

  return (
    <div>
      <p>We found the following employers, please review the information</p>
      {report.employers.map((employer) => (
        <div key={`${employer.id}`}>
          <EmployerHeader>
            <EmployerName>{employer.fullName}</EmployerName>
            <EmployerStatusType>
              {employer.statusType === 'Current' && (
                <Tag color="primary" size="sm">
                  Current Employer
                </Tag>
              )}
              {employer.statusType === 'Previous' && (
                <Tag color="primary" size="sm">
                  Previous Employer
                </Tag>
              )}
            </EmployerStatusType>
          </EmployerHeader>
          <EmployerDetails>
            <div>
              <dt>Position:</dt>
              <dd>{employer.positionDescription || <>&emdash;</>}</dd>
            </div>
            <div>
              <dt>Start date:</dt>
              <dd>
                {employer.startDate ? <Format.Date value={employer.startDate} /> : <>&emdash;</>}
              </dd>
            </div>
            <div>
              <dt>End date:</dt>
              <dd>{employer.endDate ? <Format.Date value={employer.endDate} /> : <>&mdash;</>}</dd>
            </div>
            {employer.incomes.map((income) => (
              <div key={`${income.id}`}>
                <dt>{income.typeName} Income</dt>
                <dd>
                  <Format.Currency value={income.monthlyAmount!} />
                  /mo
                </dd>
              </div>
            ))}

            {!employer.incomes.length && (
              <div key="no-income">
                <dt>Base Income</dt>
                <dd>No income reported for this employer</dd>
              </div>
            )}
          </EmployerDetails>
        </div>
      ))}
    </div>
  );
};
