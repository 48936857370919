import { type FormSubmitHandler, notifications, useModal } from '@mortgagehippo/ds';
import { toArray } from '@mortgagehippo/util';
import { useCallback, useEffect, useMemo } from 'react';

import { useUserCan } from '$components/permissions';
import { getChoicesFn, toSearchFields } from '$components/smart-view';

import { usePartnerId } from '../../../hooks/use-partner-id';
import { useSmartviewConfig } from '../../../hooks/use-smartview-config';
import { useSmartviewDocumentation } from '../../../hooks/use-smartview-documentation';
import { useSmartviews } from '../../../hooks/use-smartviews';
import { history } from '../../../services/history';
import { type IActionProps, type ICreateSmartviewAction } from '../types';
import { SmartviewModal } from './smartview-modal';
import { useCreateSmartview } from './use-create-smartview';

const DEFAULT_SORT = 'desc';

export const CreateSmartviewAction = (props: IActionProps<ICreateSmartviewAction>) => {
  const { action, onDone, onCancel } = props;
  const { model, initialValues: actionInitialValues } = action;

  const [isOpen, , closeModal] = useModal(true);
  const [partnerId] = usePartnerId();

  const [can, canReady] = useUserCan();
  const [documentation, documentationLoading] = useSmartviewDocumentation(model);
  const [defaultConfig, defaultConfigLoading] = useSmartviewConfig(model);
  const [, , refetchSmartviews] = useSmartviews(model, {
    skip: true,
  });

  const createSmartview = useCreateSmartview();

  const loading = !canReady || documentationLoading || defaultConfigLoading;
  const allowOrganizationLevel = can.CREATE_ORGANIZATION_LEVEL_SMARTVIEW;

  const handleSubmit: FormSubmitHandler = async (values) => {
    try {
      const { title, config, isOrganizationLevel = false } = values;

      if (!partnerId) {
        return;
      }

      const input = {
        title,
        model,
        config,
        isOrganizationLevel,
      };

      const result = await createSmartview(partnerId, input);

      const { id } = result;

      // don't have a good place to do this, layout gets unmounted on redirect
      await refetchSmartviews();

      closeModal(async () => {
        history.push(`/smartviews/${id}`);
        notifications.success({
          message: 'Successfully created smartview',
        });
        onDone();
      });
    } catch (e) {
      notifications.error({
        message: 'There was an unexpected error creating the smartview',
      });
    }
  };

  const handleRequestClose = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  const initialValues = useMemo(() => {
    if (!defaultConfig) {
      return {};
    }

    const { columns: defaultColumns } = defaultConfig;
    const {
      filters,
      defaultSortField: initialValuesSortField,
      defaultSortDirection: initialValuesSortDirection,
    } = actionInitialValues || {};

    const [firstColumn] = defaultColumns;
    const firstColumnField = firstColumn ? toArray(firstColumn.fields)[0] : undefined;
    const defaultSortField = initialValuesSortField || firstColumnField;
    const defaultSortDirection = initialValuesSortDirection || DEFAULT_SORT;

    return {
      config: {
        filters,
        columns: defaultColumns,
        defaultSortField,
        defaultSortDirection,
      },
    };
  }, [actionInitialValues, defaultConfig]);

  const searchFields = useMemo(() => {
    if (!defaultConfig || !partnerId) {
      return {};
    }

    const { searchFields: defaultSearchFields, metadata } = defaultConfig;

    return toSearchFields(defaultSearchFields || [], metadata, getChoicesFn(partnerId, model));
  }, [defaultConfig, model, partnerId]);

  const loadingFailed = !loading && (!documentation || !defaultConfig);
  useEffect(() => {
    if (loadingFailed) {
      notifications.error({
        message: 'An error occurred while processing your request. Please try again later.',
      });
      handleRequestClose();
    }
  }, [handleRequestClose, loadingFailed]);

  const showLoading = loading || !documentation || !defaultConfig;

  return (
    <SmartviewModal
      title="New Smartview"
      onSubmit={handleSubmit}
      onRequestClose={handleRequestClose}
      isOpen={isOpen}
      initialValues={initialValues}
      allowOrganizationLevel={allowOrganizationLevel}
      loading={showLoading}
      documentation={documentation}
      searchFields={searchFields}
    />
  );
};
