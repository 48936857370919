import { type IPopoverProps, Popover, useOpenClose } from '@mortgagehippo/ds';
import { type ReactNode, useCallback } from 'react';

import { type IFolderAgent } from '../../../hooks/use-folder-agents';
import { AddFolderUserPopoverContainer } from './add-folder-user-popover-container';
import { type ManagedFolder } from './manager';

interface IAddFolderUserPopoverProps
  extends Omit<IPopoverProps, 'content' | 'open' | 'onShow' | 'onHide'> {
  folder: ManagedFolder;
  onAddUsers: (agents: IFolderAgent[]) => void;
  children: ReactNode;
}

export const AddFolderUserPopover = (props: IAddFolderUserPopoverProps) => {
  const { folder, onAddUsers, children, ...rest } = props;

  const [isOpen, openPopover, closePopover] = useOpenClose(false);

  const handleAddUsers = useCallback(
    (agents: IFolderAgent[]) => {
      onAddUsers(agents);

      closePopover();
    },
    [closePopover, onAddUsers]
  );

  const handleCancel = useCallback(() => {
    closePopover();
  }, [closePopover]);

  return (
    <Popover
      content={
        <AddFolderUserPopoverContainer
          folder={folder}
          onAddUsers={handleAddUsers}
          onCancel={handleCancel}
        />
      }
      open={isOpen}
      onShow={openPopover}
      onHide={closePopover}
      {...rest}
    >
      {children}
    </Popover>
  );
};
