import { type IFile, type ITreeSelectOption } from '@mortgagehippo/ds';
import { sortBy, uniqBy } from 'lodash-es';

import {
  type AgentInput,
  type AgentInputSite,
  type CreateAgentInput,
  type UpdateAgentInput,
} from '../../../apollo/graphql';
import { type IAgentData } from '../../../hooks/agents';
import { type IMetadataFieldValue, type IMetadataItem } from './agent-modal-metadata';
import { type IFolder } from './use-agent-modal-data';

export const getInitialValues = (data: IAgentData) => {
  const {
    authRole,
    defaultFolderId,
    folders,
    sites,
    jobFunctions,
    teamTemplates,
    licensedStates,
    name,
    email,
    jobTitle,
    phone,
    cellPhone,
    alternatePhone,
    secondaryEmail,
    license,
    bio,
    facebookUrl,
    linkedinUrl,
    twitterUrl,
    slug,
    avatarUrl,
    avatarUrlKey,
    metadata,
    visible,
  } = data;

  const authRoleValue = authRole?.id;
  const sitesValue = sites.items.map((t) => t.id);

  const jobFunctionsValue = jobFunctions.items.reduce(
    (accumulator: Record<string, string[]>, t) => {
      const siteKey = `site-${t.siteId}`;
      accumulator[siteKey] ||= [];
      accumulator[siteKey]!.push(t.id);
      return accumulator;
    },
    {}
  );

  const teamTemplatesValue = teamTemplates.items.reduce(
    (accumulator: Record<string, string[]>, tt) => {
      tt.jobFunctions.forEach((jf) => {
        const siteKey = `site-${tt.siteId}`;
        accumulator[siteKey] ||= [];
        accumulator[siteKey]!.push(`${tt.id}-${jf.id}`);
      });
      return accumulator;
    },
    {}
  );

  const licensedStatesValue: string[] | undefined = licensedStates
    ? licensedStates.split(',')
    : undefined;

  const foldersValue: string[] = folders.map((folder) => folder.id.toString());

  const avatar: Array<Partial<IFile>> | undefined = avatarUrlKey
    ? [
        {
          id: avatarUrlKey,
          filename: avatarUrlKey.substring(avatarUrlKey.lastIndexOf('/') + 1),
          url: avatarUrl || undefined,
        },
      ]
    : [];

  const secondaryEmailValue = secondaryEmail.map((v) => ({
    email: v,
  }));

  const metadataValue: IMetadataFieldValue = metadata
    ? Object.keys(metadata).reduce((accumulator: IMetadataFieldValue, key: string) => {
        accumulator.push({ name: key, value: metadata[key] });
        return accumulator;
      }, [])
    : [];

  return {
    defaultFolderId,
    folders: foldersValue,
    authRoleId: authRoleValue,
    sites: sitesValue,
    jobFunctions: jobFunctionsValue,
    teamTemplates: teamTemplatesValue,
    licensedStates: licensedStatesValue,
    name,
    email,
    jobTitle,
    phone,
    cellPhone,
    alternatePhone,
    secondaryEmail: secondaryEmailValue,
    license,
    bio,
    facebookUrl,
    linkedinUrl,
    twitterUrl,
    slug,
    avatar,
    metadata: metadataValue,
    visible,
  };
};

export const toAgentInput = (values: any): AgentInput => {
  const {
    licensedStates = [],
    name,
    email,
    jobTitle = null,
    phone = null,
    cellPhone = null,
    alternatePhone = null,
    secondaryEmail = [],
    license = null,
    bio = null,
    facebookUrl = null,
    linkedinUrl = null,
    twitterUrl = null,
    avatar = [],
    metadata = [],
    visible = true,
  }: {
    licensedStates: string[];
    avatar: null | undefined | Array<Partial<IFile>>;
    metadata: IMetadataFieldValue;
    [k: string]: any;
  } = values;

  const licensedStatesInput = licensedStates.join(',') || null;

  const avatarUrlKey = avatar && avatar.length > 0 ? `${avatar[0]!.id}` : null;

  const secondaryEmailInput = secondaryEmail.reduce((accumulator: string[], item: any) => {
    if (item.email) {
      accumulator.push(item.email);
    }

    return accumulator;
  }, []);

  const metadataInput = metadata.reduce((accumulator: any, item: IMetadataItem) => {
    if (item.name) {
      accumulator[item.name] = item.value;
    }

    return accumulator;
  }, {});

  return {
    licensedStates: licensedStatesInput,
    name,
    email,
    jobTitle,
    phone,
    cellPhone,
    alternatePhone,
    secondaryEmail: secondaryEmailInput,
    license,
    bio,
    facebookUrl,
    linkedinUrl,
    twitterUrl,
    avatarUrlKey,
    metadata: metadataInput,
    visible,
  };
};

export const toAgentSitesInput = (values: any): AgentInputSite[] => {
  const {
    sites = [],
    jobFunctions = {},
    teamTemplates = {},
  }: {
    sites: string[];
    jobFunctions: Record<string, string[]>;
    teamTemplates: Record<string, string[]>;
    [k: string]: any;
  } = values;

  return sites.map((siteId) => ({
    id: siteId,
    jobFunctions: jobFunctions[`site-${siteId}`] || [],
    assignments:
      teamTemplates[`site-${siteId}`]?.map((t) => {
        const [teamTemplateId, jobFunctionId] = t.split('-');
        return {
          teamTemplateId: teamTemplateId!,
          jobFunctionId: jobFunctionId!,
        };
      }) ?? [],
  }));
};

export const toCreateAgentServerInput = (values: any): CreateAgentInput => {
  const { authRoleId, defaultFolderId, folders, ...rest } = values;

  return {
    authRoleId,
    agent: toAgentInput(rest),
    agentFolders: folders || [],
    defaultFolderId,
    sites: toAgentSitesInput(rest),
  };
};

export const toUpdateAgentServerInput = (values: any): UpdateAgentInput => {
  const { authRoleId, defaultFolderId, slug, folders, ...rest } = values;

  return {
    authRoleId,
    agent: toAgentInput(rest),
    agentFolders: folders || [],
    defaultFolderId,
    sites: toAgentSitesInput(rest),
    slug,
  };
};

interface IAugmentedFolder extends IFolder {
  children?: IAugmentedFolder[];
}

export const foldersToOptions = (folders: IFolder[]) => {
  const nest = (
    foldersToNest: IAugmentedFolder[],
    parent?: IAugmentedFolder
  ): ITreeSelectOption[] => {
    const children: ITreeSelectOption[] = foldersToNest
      .filter((c) => {
        if (!parent) {
          if (c.path.length === 1) {
            return true;
          }

          // eg. folder has path [1, 2, 3] but folders 1 and 2 are not in the array
          const previousPath = c.path[c.path.length - 2];
          const hasParent = !!foldersToNest.find((folder) => folder.id === previousPath);
          return !hasParent;
        }
        return c.path.includes(parent.id) && parent.path.length === c.path.length - 1;
      })
      .map((folder) => ({
        label: folder.name,
        value: folder.id,
        children: nest(foldersToNest, folder),
      }));

    return sortBy(children, 'label');
  };

  return nest(folders);
};

export const filterSelectedFolders = (
  agentFolders: IFolder[],
  selectedFolderIds: string[]
): IFolder[] => {
  if (!agentFolders.length || !selectedFolderIds.length) {
    return [];
  }

  const filteredAgentFolders: IFolder[] = [];
  selectedFolderIds.forEach((selectedFolderId) => {
    agentFolders.forEach((agentFolder) => {
      if (agentFolder.path.includes(selectedFolderId)) {
        filteredAgentFolders.push({
          ...agentFolder,
          path: agentFolder.path.slice(agentFolder.path.indexOf(selectedFolderId)), // remove any parent folder ids of selected ids (assumes path is always ordered from parent down)
        });
      }
    });
  });

  return uniqBy(filteredAgentFolders, (i) => i.id);
};
