import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { graphql } from '../apollo';
import { type FolderAgentsQuery } from '../apollo/graphql';
import { usePartnerDomain } from './use-partner-domain';

const PER_PAGE = 10000;

export type IFolderAgent = NonNullable<
  NonNullable<FolderAgentsQuery['partner']>['folder']
>['agents']['items'][0];

export const QFolderAgents = graphql(`
  query FolderAgents(
    $partnerId: ID!
    $folderId: ID!
    $perPage: Int!
    $unassigned: Boolean
    $cursor: String
  ) {
    partner(id: $partnerId) {
      id
      folder(id: $folderId) {
        id
        agents(
          unassigned: $unassigned
          options: { perPage: $perPage, cursor: $cursor, orderBy: { name: "asc" } }
        ) {
          total
          nextCursor
          previousCursor
          items {
            id
            email
            name
          }
        }
      }
    }
  }
`);

export const useFolderAgents = (
  folderId: string,
  unassigned = false,
  options: IUseApolloQueryOptions = {}
) => {
  const partnerId = usePartnerDomain();

  const [data, loading, , { refetch }] = useQuery(
    QFolderAgents,
    {
      partnerId,
      folderId,
      perPage: PER_PAGE,
      unassigned,
    },
    {
      fetchPolicy: 'network-only',
      ...options,
    }
  );

  const handleRefresh = useSafeCallback(async () =>
    refetch({ partnerId, folderId, perPage: PER_PAGE })
  );

  const result = data?.partner?.folder?.agents.items || [];

  return [result, loading, handleRefresh] as const;
};
