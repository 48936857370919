import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../apollo';

const MTriggerMortgageDirectorDocumentPush = graphql(`
  mutation TriggerMortgageDirectorDocumentPush($applicationFileId: ID!, $documentId: ID!) {
    triggerMortgageDirectorDocumentPush(
      applicationFileId: $applicationFileId
      documentId: $documentId
    )
  }
`);

export const useTriggerMortgageDirectorDocumentPush = () => {
  const fn = useMutation(MTriggerMortgageDirectorDocumentPush, undefined);

  return useCallback(
    async (applicationFileId: string, documentId: string) => {
      const response = await fn({
        applicationFileId,
        documentId,
      });
      return response.data!.triggerMortgageDirectorDocumentPush;
    },
    [fn]
  );
};
