import { useModal } from '@mortgagehippo/ds';
import { useSagaSubmitHandler } from '@mortgagehippo/tasks';
import { useCallback } from 'react';

import { useCreateAgent } from '../../../hooks/agents';
import { usePartnerDomain } from '../../../hooks/use-partner-domain';
import { type IActionProps, type ICreateAgentAction } from '../types';
import { AgentModalContainer } from './agent-modal-container';
import { toCreateAgentServerInput } from './util';

export const CreateAgentAction = (props: IActionProps<ICreateAgentAction>) => {
  const { onDone, onCancel } = props;

  const [isOpen, , closeModal] = useModal(true);

  const partnerId = usePartnerDomain();
  const createAgent = useCreateAgent();

  const [handleSubmit, running] = useSagaSubmitHandler({
    infoMessageCid: 'create-agent-action:info',
    successMessageCid: 'create-agent-action:success',
    errorMessageCid: 'create-agent-action:error',
    infoMessage: 'Adding agent to partner',
    successMessage: 'Successfully added agent to partner',
    errorMessage: 'There was an unexpected error adding agent to partner',
    async onSubmit(values: any, setAsyncId) {
      const input: any = toCreateAgentServerInput(values);

      const nextAsyncId = await createAgent(partnerId, input);
      closeModal(() => {
        setAsyncId(nextAsyncId);
      });
    },
    async onDone() {
      closeModal(onDone);
    },
  });

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  if (running) {
    return null;
  }

  return (
    <AgentModalContainer
      partnerId={partnerId}
      isOpen={isOpen}
      onSubmit={handleSubmit}
      onRequestClose={handleCloseModal}
    />
  );
};
