import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../apollo';
import { type SiteJobFunctionsQuery } from '../apollo/graphql';

const QSiteJobFunctions = graphql(`
  query SiteJobFunctions($siteId: ID!) {
    site(id: $siteId) {
      id
      jobFunctions(options: { perPage: 10000, orderBy: { primary: "desc", name: "asc" } }) {
        items {
          id
          name
          primary
        }
      }
    }
  }
`);

export type ISiteJobFunction = NonNullable<
  SiteJobFunctionsQuery['site']
>['jobFunctions']['items'][0];

export const useSiteJobFunctions = (siteId: string, options: IUseApolloQueryOptions = {}) => {
  const [data, loading] = useQuery(
    QSiteJobFunctions,
    {
      siteId,
    },
    { fetchPolicy: 'network-only', ...options }
  );

  const jobFunctions = data?.site?.jobFunctions.items || [];

  return [jobFunctions, loading] as const;
};
