import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../apollo';

export const MLenderDocumentPostUrl = graphql(`
  mutation ILenderDocumentPostUrl($applicationFileId: ID!, $filename: String!) {
    getLenderDocumentPostUrl(applicationFileId: $applicationFileId, filename: $filename) {
      id
      url
      postUrl
    }
  }
`);

export const useLenderDocumentSubmitUrl = () => {
  const getUpload = useMutation(MLenderDocumentPostUrl);
  return async (applicationFileId: string, filename: string) => {
    const response = await getUpload(
      {
        applicationFileId,
        filename,
      },
      { fetchPolicy: 'no-cache' }
    );
    return response.data!.getLenderDocumentPostUrl;
  };
};
