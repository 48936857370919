import { ApplicationStatusDropdown } from '$components/application-status-dropdown';

interface IApplicationStatusRendererProps {
  applicationFileId: string;
  statusId?: string | null;
  disabled?: boolean;
}

export const ApplicationStatusRenderer = (props: IApplicationStatusRendererProps) => {
  const { applicationFileId, statusId, disabled } = props;

  return (
    <div style={{ minWidth: 100 }}>
      <ApplicationStatusDropdown
        applicationFileId={applicationFileId}
        statusId={statusId}
        size="xs"
        disabled={disabled}
      />
    </div>
  );
};
