import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { graphql } from '../../../../../apollo';
import { type PricingOverviewSectionQuery } from '../../../../../apollo/graphql';

const QPricingOverviewSection = graphql(`
  query PricingOverviewSection($applicationFileId: ID!) {
    applicationFile(id: $applicationFileId) {
      id
      selectedPricingLoan {
        lockExpiresAt
        lockStatus
        price
        productId
        productName
        rate
        apr
        loanAmount
        selectedAt
        closingCost
        totalPayment
      }
    }
  }
`);

export type ISelectedPricingLoan = NonNullable<
  NonNullable<PricingOverviewSectionQuery['applicationFile']>['selectedPricingLoan']
>;

export const usePricingOverviewSection = (
  applicationFileId: string,
  options: IUseApolloQueryOptions = {}
) => {
  const [data, loading, , { refetch }] = useQuery(
    QPricingOverviewSection,
    { applicationFileId },
    {
      fetchPolicy: 'network-only',
      ...options,
    }
  );

  const handleRefetch = useSafeCallback(() => refetch({ applicationFileId }));

  const selectedPricingLoan = data?.applicationFile?.selectedPricingLoan;

  return [{ selectedPricingLoan }, loading, handleRefetch] as const;
};
