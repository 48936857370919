import { useMutation } from '@mortgagehippo/apollo-hooks';
import { type ITableActions, notifications, useModal } from '@mortgagehippo/ds';
import {
  type IQueryTableColumns,
  type IQueryTableDataActions,
  QueryTable,
} from '@mortgagehippo/query-components';
import { UnreachableCaseError, useSessionStorage } from '@mortgagehippo/util';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { useUserCan } from '$components/permissions';
import { PrimaryLabel } from '$components/primary-label';
import { SiteSelect } from '$components/site-select';
import { SiteSelectSkeleton } from '$components/site-select-skeleton';

import { type TeamTemplatesQuery, type TeamTemplatesQueryVariables } from '../../../apollo/graphql';
import { useSites } from '../../../hooks/use-sites';
import { type ITeamTemplate, MDeleteTeamTemplateMutation, QTeamTemplatesQuery } from './queries';
import { TeamTemplateModalContainer } from './team-templates-modal-container';

const TopContent = styled.div`
  max-width: 300px;
`;

enum TeamTemplatesTabActionType {
  VIEW = 'view',
  EDIT = 'edit',
  DELETE = 'delete',
}

const basicColumns: IQueryTableColumns<ITeamTemplate> = [
  {
    title: 'Team',
    key: 'team',
    render: (template) => template.name,
  },
];

const generateColumns = (result: TeamTemplatesQuery) => {
  if (!result?.site) {
    return [];
  }

  const { site } = result;
  const { jobFunctions } = site;

  const jobFunctionColumns: IQueryTableColumns = jobFunctions.items.map((jobFunction) => {
    const title = jobFunction.primary ? (
      <PrimaryLabel>{jobFunction.name}</PrimaryLabel>
    ) : (
      jobFunction.name
    );

    return {
      title,
      key: jobFunction.id,
      render: (template: ITeamTemplate) => {
        const agents = template.jobFunctions
          .filter((jf) => jf.id === jobFunction.id)
          .flatMap((jf) => jf.agents);

        if (!agents.length) {
          return <>&mdash;</>;
        }

        return agents.map((agent) => <div key={`${agent.id}`}>{agent.name}</div>);
      },
    };
  });

  return [...basicColumns, ...jobFunctionColumns];
};

export const TemplatesTab = () => {
  const [siteId, setSiteId] = useSessionStorage('teamTemplateTabsSiteId', null);
  const [templateId, setTemplateId] = useState<string | undefined>();
  const [modalOpen, openModal, closeModal] = useModal(false);
  const tableRef = useRef<any>();

  const [can] = useUserCan();

  const [sites, loading] = useSites();

  const deleteTeamTemplate = useMutation(MDeleteTeamTemplateMutation);

  useEffect(() => {
    if (!siteId && !loading && sites && sites.length === 1) {
      setSiteId(sites[0]!.value);
    }
  }, [setSiteId, siteId, loading, sites]);

  const handleSiteChange = useCallback(
    (nextSiteId: string) => {
      setSiteId(nextSiteId);
    },
    [setSiteId]
  );

  const handleAdd = useCallback(() => {
    setTemplateId(undefined);
    openModal();
  }, [openModal]);

  const handleEdit = useCallback(
    (record: ITeamTemplate) => {
      setTemplateId(record.id);
      openModal();
    },
    [openModal]
  );

  const handleClose = useCallback(() => {
    closeModal(() => {
      setTemplateId(undefined);
    });
  }, [closeModal]);

  const handleSubmit = useCallback(() => {
    if (tableRef) {
      tableRef!.current.refetch();
    }

    closeModal(() => {
      setTemplateId(undefined);
    });
  }, [closeModal]);

  const handleRowClick = useCallback(
    (record: ITeamTemplate) => {
      if (can.UPDATE_TEAM_TEMPLATE || can.VIEW_TEAM_TEMPLATE) {
        handleEdit(record);
      }
    },
    [can.UPDATE_TEAM_TEMPLATE, can.VIEW_TEAM_TEMPLATE, handleEdit]
  );

  const handleDelete = useCallback(
    async (record: ITeamTemplate) => {
      try {
        await deleteTeamTemplate({ teamTemplateId: record.id });
        notifications.success({ message: 'The team template was deleted successfully.' });

        if (tableRef) {
          tableRef.current.refetch();
        }
      } catch (error) {
        notifications.error({
          message: 'There was an error processing your request, please try again later',
        });
      }
    },
    [deleteTeamTemplate, tableRef]
  );

  const handleAction = useCallback(
    async (actionKey: string, record: ITeamTemplate) => {
      const actionType = actionKey as TeamTemplatesTabActionType;

      switch (actionType) {
        case TeamTemplatesTabActionType.VIEW:
        case TeamTemplatesTabActionType.EDIT: {
          handleEdit(record);
          break;
        }
        case TeamTemplatesTabActionType.DELETE: {
          await handleDelete(record);
          break;
        }
        default: {
          throw new UnreachableCaseError(actionType);
        }
      }
    },
    [handleEdit, handleDelete]
  );

  const actions: IQueryTableDataActions = useMemo(
    () => [
      {
        key: 'options',
        label: 'Options',
        buttonProps: {
          icon: 'menu-dots',
        },
        onGroupAction: handleAction,
        actions: [
          {
            key: TeamTemplatesTabActionType.VIEW,
            label: 'View',
            iconProps: {
              name: 'preview',
            },
            hidden: can.UPDATE_TEAM_TEMPLATE || !can.VIEW_TEAM_TEMPLATE,
          },
          {
            key: TeamTemplatesTabActionType.EDIT,
            label: 'Edit',
            iconProps: {
              name: 'edit',
            },
            hidden: !can.UPDATE_TEAM_TEMPLATE,
          },
          {
            key: TeamTemplatesTabActionType.DELETE,
            label: 'Delete',
            iconProps: {
              name: 'delete',
            },
            confirm: {
              title: 'Warning',
              type: 'warning',
              explanation:
                'Are you sure you want to delete this template? This action cannot be undone.',
            },
            hidden: !can.DELETE_TEAM_TEMPLATE,
          },
        ],
      },
    ],
    [can.DELETE_TEAM_TEMPLATE, can.UPDATE_TEAM_TEMPLATE, can.VIEW_TEAM_TEMPLATE, handleAction]
  );

  const topActions: ITableActions = useMemo(
    () => [
      {
        key: 'add-new-team',
        label: 'Add new team',
        buttonProps: {
          icon: 'plus',
        },
        hidden: !can.CREATE_TEAM_TEMPLATE,
        onAction: handleAdd,
      },
    ],
    [can.CREATE_TEAM_TEMPLATE, handleAdd]
  );

  const topContent = useMemo(
    () => (
      <TopContent>
        <SiteSelect.Input
          value={siteId}
          onChange={handleSiteChange}
          name="siteId"
          aria-label="Select site to see team templates"
          placeholder="Select site to see team templates"
          compact
          size="sm"
        />
      </TopContent>
    ),
    [siteId, handleSiteChange]
  );

  if (!siteId) {
    return <SiteSelectSkeleton onSiteChange={handleSiteChange} />;
  }

  return (
    <>
      <QueryTable<TeamTemplatesQuery, TeamTemplatesQueryVariables>
        caption="Team Templates"
        rowActions={actions}
        query={QTeamTemplatesQuery}
        dataSource={(result) => result.site?.teamTemplates.items || []}
        itemTotal={(result) => result.site?.teamTemplates.total || 0}
        nextCursor={(result) => result.site?.teamTemplates.nextCursor}
        previousCursor={(result) => result.site?.teamTemplates.previousCursor}
        rowKey={(item) => item.id}
        columns={generateColumns}
        variables={{ siteId }}
        onRowClick={handleRowClick}
        ref={tableRef}
        bottomContent={<QueryTable.Pagination />}
        topContent={topContent}
        topActions={topActions}
      >
        <QueryTable.Data />
      </QueryTable>
      <TeamTemplateModalContainer
        siteId={siteId}
        templateId={templateId}
        onRequestClose={handleClose}
        onSubmit={handleSubmit}
        isOpen={modalOpen}
      />
    </>
  );
};
