import { Skeleton, useOpenClose } from '@mortgagehippo/ds';

import { Content, Layout, Nav } from '../../../layouts/application';

export const ApplicationTasksSkeleton = () => {
  const [isMobileNavOpen, openMobileNav, closeMobileNav] = useOpenClose(false);

  return (
    <Layout>
      <Nav mobileOpen={isMobileNavOpen} onMobileOpen={openMobileNav} onMobileClose={closeMobileNav}>
        <Skeleton />
      </Nav>
      <Content>
        <Skeleton />
      </Content>
    </Layout>
  );
};
