import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../apollo';
import { type UpdatePartnerFoldersInput } from '../../../apollo/graphql';

export const MPartnerFolders = graphql(`
  mutation UpdatePartnerFolders($partnerId: ID!, $data: UpdatePartnerFoldersInput!) {
    updatePartnerFolders(partnerId: $partnerId, data: $data)
  }
`);

export const useUpdatePartnerFolders = () => {
  const updatePartnerFolders = useMutation(MPartnerFolders);

  return useCallback(
    async (partnerId: string, data: UpdatePartnerFoldersInput) => {
      const response = await updatePartnerFolders({
        partnerId,
        data,
      });

      return response.data!.updatePartnerFolders;
    },
    [updatePartnerFolders]
  );
};
