import { ModalForm, notifications, useModal, YesNo } from '@mortgagehippo/ds';
import { useCreateApplicationFromApplicant } from '@mortgagehippo/tasks';
import { useCallback } from 'react';

import { getPrimaryApplicantId } from '../../../hooks/get-primary-applicant-id';
import { useDuplicateApplicationFile } from '../../../pages/application/use-duplicate-application-file';
import {
  type IActionProps,
  type IDuplicateApplicationAction,
  type IDuplicateApplicationActionResult,
} from '../types';

const INITIAL_VALUES = { import_data: true };

interface IDuplicateApplicationForm {
  import_data?: boolean;
}

export const DuplicateApplicationAction = (
  props: IActionProps<IDuplicateApplicationAction, IDuplicateApplicationActionResult>
) => {
  const { action, onDone, onSubmit, onCancel } = props;
  const { applicationFileId } = action;

  const [isOpen, , closeModal] = useModal(true);

  const duplicateApplicationFile = useDuplicateApplicationFile();
  const createApplicationFile = useCreateApplicationFromApplicant();

  const handleSubmit = useCallback(
    async (values: IDuplicateApplicationForm) => {
      try {
        const { import_data: importData } = values || {};

        onSubmit();

        let nextApplicationFileId: string;
        if (!importData) {
          const primaryApplicantId = await getPrimaryApplicantId(applicationFileId);
          if (!primaryApplicantId) {
            throw new Error('Could not find primary applicant');
          }
          nextApplicationFileId = await createApplicationFile(
            primaryApplicantId,
            {
              assignmentOptions: {
                agentId: null,
                copyFromApplicationFileId: applicationFileId,
              },
              sendInvitation: null,
            },
            true
          );
        } else {
          nextApplicationFileId = await duplicateApplicationFile(
            applicationFileId,
            undefined,
            true
          );
        }

        closeModal(() => {
          onDone({
            applicationFileId: nextApplicationFileId,
          });
        });
      } catch (e) {
        console.error(e);
        notifications.error({
          message: 'There was an unexpected error creating the application.',
        });
      }
    },
    [
      onSubmit,
      closeModal,
      applicationFileId,
      createApplicationFile,
      duplicateApplicationFile,
      onDone,
    ]
  );

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  return (
    <ModalForm<IDuplicateApplicationForm>
      title="Start New Application"
      onSubmit={handleSubmit}
      onRequestClose={handleCloseModal}
      isOpen={isOpen}
      okButtonLabel="Create new application"
      initialValues={INITIAL_VALUES}
    >
      <YesNo.Box
        name="import_data"
        label="Would you like to import borrower information from the current application into the new one?"
        description="Imported borrower information will exclude data obtained from services such as credit, liabilities, selected loan programs, assets from online banking, etc. Those services will have to be run again for the new application."
        labelYes="Yes, import borrower information"
        labelNo="No, start a blank application"
        displayVertical
        initialValue
        required
      />
    </ModalForm>
  );
};
