import { useCallback } from 'react';

import { type OwnerType } from './types';
import { useGetPartnerReportUrl } from './use-get-partner-report-url';
import { useGetSiteReportUrl } from './use-get-site-report-url';

export const useGetReportUrl = () => {
  const partnerFn = useGetPartnerReportUrl();
  const siteFn = useGetSiteReportUrl();

  return useCallback(
    async (type: OwnerType, ownerId: string, dashboard: string): Promise<string | null> => {
      const fn = type === 'partner' ? partnerFn : siteFn;

      return fn(ownerId, dashboard);
    },
    [partnerFn, siteFn]
  );
};
