import { Alert, ModalForm, notifications, Spinner, useModal } from '@mortgagehippo/ds';
import { useCallback, useEffect, useState } from 'react';

import { useCreateUpscopeWatchUrl } from '../../../hooks/use-create-upscope-watch-url';
import { useUpscopeVisitors } from '../../../hooks/use-upscope-visitors';
import { type IActionProps, type IGetScreenshareWatchUrlAction } from '../types';
import {
  Bold,
  BorrowerRequestMessage,
  RefreshButton,
  SpinnerContainer,
  SpinnerMessage,
  StyledChoiceBox,
} from './styled';

export const GetScreenshareWatchUrlAction = (
  props: IActionProps<IGetScreenshareWatchUrlAction>
) => {
  const { action, onDone, onCancel } = props;
  const { applicants } = action;

  const [isOpen, , closeModal] = useModal(true);
  const [isFetchingVisitors, setIsFetchingVisitors] = useState(false);
  const [upscopeVisitors, setUpscopeVisitors] = useState<string[]>([]);
  const [selectedBorrowerId, setSelectedBorrowerId] = useState<string | null>(null);
  const visitors = useUpscopeVisitors();

  const fetchVisitors = useCallback(async () => {
    setIsFetchingVisitors(true);
    try {
      const result = await visitors(action.applicationFileId);
      setUpscopeVisitors([...new Set(result)]);
    } catch (e) {
      console.error('Error fetching screenshare users: ', e);
    }
    setIsFetchingVisitors(false);
  }, [action.applicationFileId, visitors]);

  useEffect(() => {
    fetchVisitors();
  }, [fetchVisitors]);

  useEffect(() => {
    if (upscopeVisitors.length === 1) {
      const [borrowerId] = upscopeVisitors;
      const id = borrowerId?.split(':')[1];
      if (id) setSelectedBorrowerId(id);
    }
  }, [upscopeVisitors]);

  const getOnlineBorrowerInfo = () => {
    const visitorIds = upscopeVisitors.map((visitor) => {
      const [, id] = visitor.split(':');
      return id;
    });
    const borrowerNameOrEmail = applicants
      .filter((applicant) => visitorIds.includes(applicant.id))
      .map((applicant) => applicant.name || applicant.email);
    return borrowerNameOrEmail;
  };

  const getBorrowerOptions = () =>
    upscopeVisitors.map((visitor) => {
      const [, id] = visitor.split(':');
      const borrower = applicants.find((applicant) => applicant.id === id);
      return {
        label: borrower?.name || borrower?.email || `Borrower ${id}`,
        value: id,
      };
    });

  const getScreenshareWatchUrl = useCreateUpscopeWatchUrl();

  const handleOk = async () => {
    try {
      const watchUrl = await getScreenshareWatchUrl(selectedBorrowerId!);
      closeModal(async () => {
        onDone(watchUrl);
      });
    } catch (e) {
      notifications.error({
        messageCid: 'get-screenshare-watch-url:notification-error',
        message: 'The applicant is not online.',
      });
    }
  };

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
    window.close();
  }, [closeModal, onCancel]);

  const handleRefreshClick = () => {
    fetchVisitors();
  };

  const handleChoiceChange = (value: string) => {
    setSelectedBorrowerId(value);
  };

  const title = <>Request Screen Share with Borrower</>;

  const displayBorrowerAvailability = () => {
    if (isFetchingVisitors) {
      return (
        <SpinnerContainer>
          <Spinner size="xl" color="neutral500" />
          <SpinnerMessage>Checking if borrower is online...</SpinnerMessage>
        </SpinnerContainer>
      );
    }
    if (upscopeVisitors.length === 1) {
      const [borrowerInfo] = getOnlineBorrowerInfo();
      return (
        <Alert type="success">
          {borrowerInfo ? (
            <>
              Borrower <Bold>{borrowerInfo}</Bold>
            </>
          ) : (
            'The borrower'
          )}{' '}
          is online and you can request a screen share.
        </Alert>
      );
    }
    if (upscopeVisitors.length > 1) {
      return (
        <>
          <Alert type="success">
            More than one borrower associated with this application is online. Please select to whom
            to send the screen share request.
          </Alert>
          <StyledChoiceBox
            name="selectedBorrower"
            label=""
            options={getBorrowerOptions()}
            onChange={handleChoiceChange}
            showSuccess
          />
        </>
      );
    }
    return (
      <Alert type="warning">
        <div>
          The borrower is not online yet. Use the button below to check if they have become
          available.
        </div>
        <RefreshButton size="sm" icon="refresh" onClick={handleRefreshClick}>
          Check if borrower is online
        </RefreshButton>
      </Alert>
    );
  };

  const explanation = (
    <>
      <p>
        <strong>Screen sharing allows you to assist your borrower in real-time.</strong>
      </p>
      <BorrowerRequestMessage>
        The borrower must be online and provide permission for you to continue. By clicking the
        button below, you will request access to view and control the borrower&apos;s screen.
      </BorrowerRequestMessage>
      {displayBorrowerAvailability()}
    </>
  );

  // TODO: use hook useModalConfirm
  return (
    <ModalForm
      title={title}
      onSubmit={handleOk}
      okButtonLabel="Send screen share request"
      okButtonDisabled={!selectedBorrowerId || isFetchingVisitors}
      onRequestClose={handleCloseModal}
      isOpen={isOpen}
      disableOverlayClickClose
      width="medium"
    >
      {explanation}
    </ModalForm>
  );
};
