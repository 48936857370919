import { Alert, fontWeight, MediaBreakpoint, palette, Popover } from '@mortgagehippo/ds';
import styled from 'styled-components';

import { useTutorial } from '../../hooks/use-tutorial/use-tutorial';

const StyledAlert = styled(Alert)`
  max-height: 80vh;

  ${MediaBreakpoint.PHONE} {
    max-height: 100vh;
  }
`;

const StyledPopover = styled(Popover)`
  && svg {
    color: ${palette('secondary600')};
  }
`;

const StyledAlertContent = styled.span`
  font-weight: ${fontWeight('regular')};
`;

interface IApplicationTutorialSidebarProps {
  tutorialId: string | undefined;
}

export const ApplicationTutorialButton = (props: IApplicationTutorialSidebarProps) => {
  const { tutorialId } = props;

  const [tutorial, isTutorialOpen, handleTutorialOpen, handleTutorialClose] =
    useTutorial(tutorialId);

  if (!tutorial) {
    return null;
  }

  const content = (
    <StyledAlert
      type="info"
      title={tutorial.title}
      compact
      closable
      onClose={handleTutorialClose}
      animateClose={false}
    >
      <StyledAlertContent>{tutorial.content}</StyledAlertContent>
    </StyledAlert>
  );

  return (
    <StyledPopover
      content={content}
      open={isTutorialOpen}
      onShow={handleTutorialOpen}
      onHide={handleTutorialClose}
      buttonProps={{
        icon: 'information',
        type: 'neutral',
        importance: 'tertiary',
        size: 'md',
        iconButton: true,
        iconButtonRound: true,
        iconButtonHumble: true,
        compact: true,
      }}
      align="TopRight"
      widthCSS="450px"
      maxWidthCSS="600px"
      borderless
      compact
    >
      Help
    </StyledPopover>
  );
};
