import { Alert, Button, Card, Empty, spacing, T, Tag, Title } from '@mortgagehippo/ds';
import { compact } from 'lodash-es';
import { type ReactNode, useMemo } from 'react';
import styled from 'styled-components';

import { CreditCheckLiabilities } from './credit-check-liabilities';
import { CreditCheckReport } from './credit-check-report';
import { type ICreditCheckPageApplicant } from './types';
import { createModel, isJointCreditPull } from './util';

const StyledTitle = styled(Title)`
  margin-bottom: ${spacing(4)};
`;

const StyledTag = styled(Tag)`
  margin-left: ${spacing(1)};
  vertical-align: middle;
`;

const Spacer = styled.div`
  margin-top: ${spacing(5)};
`;

interface ICreditCheckApplicantProps {
  applicant: ICreditCheckPageApplicant;
  showName?: boolean;
  startButtonLabel: ReactNode;
  onOrderCreditCheck?: () => void;
  onOrderJointCreditCheck?: () => void;
  onChangeAnswers?: (answers: any) => Promise<any>;
  onDownloadReport?: (creditPullId: string) => Promise<string | null>;
  onViewReport: (creditPullId: string) => Promise<string | null>;
  showLiabilities?: boolean;
}

export const CreditCheckApplicant = (props: ICreditCheckApplicantProps) => {
  const {
    applicant,
    showName,
    startButtonLabel,
    onOrderCreditCheck,
    onOrderJointCreditCheck,
    onChangeAnswers,
    onDownloadReport,
    onViewReport,
    showLiabilities,
  } = props;

  const { id: applicantId, name, firstName, creditPull, answers } = applicant;

  const isJoint = !!creditPull && isJointCreditPull(creditPull);
  const jointBorrowers = isJoint
    ? (creditPull?.scores || []).map((score) => {
        if (score.applicant && score.applicant.id !== applicantId) {
          return score.applicant.name;
        }

        return undefined;
      })
    : undefined;
  const jointNames = compact(jointBorrowers).join(', ');

  const model = useMemo(() => createModel(answers), [answers]);

  const showReport = !!creditPull;
  const showButton = !creditPull && !!onOrderCreditCheck;
  const showEmptyState = !creditPull && !onOrderCreditCheck;
  const duplicateLiabilityCount = model.getDuplicateLiabilityCount();
  const unassociatedLiabilitiesCount = model.getUnassociatedLoanCount();

  return (
    <>
      {showName ? (
        <StyledTitle level={2}>
          {name}
          {isJoint && !!jointNames ? (
            <StyledTag size="xs" color="secondary">
              <T
                cid="pageApplication:tabServices.creditCheck.results.joint.title"
                cvars={{ names: jointNames }}
              >
                Joint report with {jointNames}
              </T>
            </StyledTag>
          ) : null}
          {isJoint && !jointNames ? (
            <StyledTag size="xs" color="secondary">
              <T cid="pageApplication:tabServices.creditCheck.results.jointWithDeleted.title">
                Joint report
              </T>
            </StyledTag>
          ) : null}
        </StyledTitle>
      ) : null}
      {showButton ? (
        <Button onClick={onOrderCreditCheck} size="sm" icon="user">
          {startButtonLabel}
        </Button>
      ) : null}
      {showEmptyState ? (
        <Card>
          <Empty>
            <T>No credit reports are available for this borrower.</T>
          </Empty>
        </Card>
      ) : null}
      {showReport ? (
        <CreditCheckReport
          applicantId={applicantId}
          applicantName={firstName || name}
          creditPull={creditPull!}
          onOrderCreditCheck={onOrderCreditCheck}
          onOrderJointCreditCheck={onOrderJointCreditCheck}
          onDownloadReport={onDownloadReport}
          onViewReport={onViewReport}
        />
      ) : null}
      {showLiabilities ? (
        <>
          {unassociatedLiabilitiesCount > 0 && (
            <Spacer>
              <Alert type="warning" icon="warning">
                {unassociatedLiabilitiesCount === 1 && (
                  <T count={unassociatedLiabilitiesCount}>
                    There is {unassociatedLiabilitiesCount} loan that has no association with owned
                    properties
                  </T>
                )}
                {unassociatedLiabilitiesCount > 1 && (
                  <T count={unassociatedLiabilitiesCount}>
                    There are {unassociatedLiabilitiesCount} loans which have no association with
                    owned properties
                  </T>
                )}
              </Alert>
            </Spacer>
          )}

          {duplicateLiabilityCount > 0 && (
            <Spacer>
              <Alert type="warning" icon="copy">
                <T count={duplicateLiabilityCount}>
                  There are {duplicateLiabilityCount} liabilities that are possible duplicates
                </T>
              </Alert>
            </Spacer>
          )}

          <Spacer>
            <CreditCheckLiabilities model={model} onChangeAnswers={onChangeAnswers} />
          </Spacer>
        </>
      ) : null}
    </>
  );
};
