import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../../apollo';

export const MDeleteAgentAvatar = graphql(`
  mutation DeleteAgentAvatar($agentId: ID!) {
    deleteAgentAvatar(agentId: $agentId) {
      ...CurrentUserDataFragment
    }
  }
`);

export const useDeleteAgentAvatar = () => {
  const deleteAgentAvatar = useMutation(MDeleteAgentAvatar, undefined);

  return async (agentId: string) => {
    await deleteAgentAvatar({ agentId });
  };
};
