import { type IModalFormProps, ModalForm, T } from '@mortgagehippo/ds';

import { type ISiteTeamTemplate } from '../../../hooks/use-site-team-templates';
import { ManageApplicationTeamActionModalForm } from './manage-application-team-action-modal-form';
import { type IApplicationFileTeamJobFunction } from './use-application-team';

interface IManageApplicationTeamActionModalProps extends Omit<IModalFormProps, 'children'> {
  team: IApplicationFileTeamJobFunction[];
  teamTemplates: ISiteTeamTemplate[];
}

export const ManageApplicationTeamActionModal = (props: IManageApplicationTeamActionModalProps) => {
  const {
    loading,
    team,
    teamTemplates,
    hideOkButton,
    cancelButtonLabel: propsCancelButtonLabel,
    ...rest
  } = props;

  const showError = !loading && !team.length;
  const cancelButtonLabel = showError ? 'Close' : propsCancelButtonLabel;

  return (
    <ModalForm
      {...rest}
      loading={loading}
      hideOkButton={hideOkButton || showError}
      cancelButtonLabel={cancelButtonLabel}
    >
      {(!showError && (
        <ManageApplicationTeamActionModalForm team={team} teamTemplates={teamTemplates} />
      )) || (
        <T>
          This application belongs to a site which has no roles defined. Please ask your
          administrator to define roles.
        </T>
      )}
    </ModalForm>
  );
};
