import {
  fontSize,
  fontWeight,
  type IconSize,
  type ISpacing,
  lineHeight,
  palette,
  type PaletteColor,
  spacing,
  T,
} from '@mortgagehippo/ds';
import { type IBaseTask, type ITaskApplicant, TaskStateIcon } from '@mortgagehippo/tasks';
import { trim } from 'lodash-es';
import { type ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const ListItem = styled.li`
  display: flex;
  margin-top: ${spacing(3)};

  &:first-child {
    margin: 0;
  }
`;

const ListItemIcon = styled.div<{ iconMargin: ISpacing }>`
  flex: 0 0 auto;
  margin-right: ${(p) => spacing(p.iconMargin)};

  svg {
    vertical-align: middle;
  }
`;

const ListItemContent = styled.div`
  flex: 1 1 auto;
`;

const ListItemTitle = styled.span<{ linkColor?: PaletteColor }>`
  font-size: ${fontSize('sm')};
  line-height: ${lineHeight('sm')};
  font-weight: ${fontWeight('semibold')};
  vertical-align: middle;

  a,
  a:visited {
    word-wrap: break-word;
    word-break: break-word;

    ${(p) =>
      p.linkColor &&
      css`
        color: ${palette(p.linkColor)};
      `}
  }
`;

const ListItemTitleName = styled.span`
  font-weight: ${fontWeight('light')};
`;

interface ITasksListItemProps<T extends IBaseTask, A extends ITaskApplicant> {
  task: T;
  applicant: A;
  showName?: boolean;
  showInitial?: boolean;
  to?: string;
  linkColor?: PaletteColor;
  iconSize?: IconSize;
  iconMargin?: ISpacing;
  renderItemDescription?: (task: T) => ReactNode;
}

export const TasksListItem = <T extends IBaseTask, A extends ITaskApplicant>(
  props: ITasksListItemProps<T, A>
) => {
  const {
    task,
    applicant,
    showName,
    showInitial,
    to,
    linkColor,
    iconSize,
    iconMargin,
    renderItemDescription,
  } = props;

  const applicantInitial =
    (showInitial && applicant.lastName && `${applicant.lastName[0]!.toUpperCase()}.`) || '';
  const applicantName = (applicant.firstName &&
    trim(`${applicant.firstName} ${applicantInitial}`)) || (
    <>
      <T cid="pageTasks:taskList.borrower.genericLabel">Borrower</T>
      {` #${(applicant.position || 0) + 1}`}
    </>
  );

  const titleEl = (
    <>
      {!task.common && showName ? (
        <ListItemTitleName>{applicantName}:&nbsp;</ListItemTitleName>
      ) : null}
      {task.meta.title}
    </>
  );

  const descriptionEl = renderItemDescription ? renderItemDescription(task) : undefined;

  return (
    <ListItem>
      <ListItemIcon iconMargin={iconMargin || 2}>
        <TaskStateIcon
          state={task.state}
          alerted={task.isAlerted}
          frozen={task.isFrozen}
          size={iconSize || 'md'}
        />
      </ListItemIcon>
      <ListItemContent>
        <ListItemTitle linkColor={linkColor}>
          {to ? <Link to={to}>{titleEl}</Link> : null}
          {!to && titleEl}
        </ListItemTitle>
        {descriptionEl ? <div>{descriptionEl}</div> : null}
      </ListItemContent>
    </ListItem>
  );
};
