import { Alert, type FormSubmitHandler, Input, ModalForm, spacing } from '@mortgagehippo/ds';
import styled from 'styled-components';

const StyledAlert = styled(Alert)`
  margin: ${spacing(1)} 0 ${spacing(5)};
`;

interface IUpdateLendingQBPushModalProps {
  isOpen: boolean;
  loading: boolean;
  title: string;
  onSubmit: FormSubmitHandler;
  onRequestClose: () => void;
  width?: number;
  initialValues?: any;
}

export const UpdateLendingQBPushModal = (props: IUpdateLendingQBPushModalProps) => {
  const { title, onRequestClose, onSubmit, isOpen, width, initialValues, loading } = props;

  return (
    <ModalForm
      loading={loading}
      title={title}
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      width={width}
      onSubmit={onSubmit}
      initialValues={initialValues}
      autoComplete={false}
    >
      <StyledAlert type="warning" compact>
        The system uses this number to synchronize documents and data with Meridian Link. Only change it
        if you understand the implications.
      </StyledAlert>
      <Input.Box label="Loan Number" name="loan_number" required />
    </ModalForm>
  );
};
