import { graphql } from '../../../apollo';
import type { TeamTemplatesQuery } from '../../../apollo/graphql';

export const QTeamTemplatesQuery = graphql(`
  query TeamTemplates($siteId: ID!, $perPage: Int!, $cursor: String) {
    site(id: $siteId) {
      id
      teamTemplates(options: { perPage: $perPage, cursor: $cursor, orderBy: { name: "asc" } }) {
        total
        nextCursor
        previousCursor
        items {
          id
          name
          slug
          jobFunctions {
            id
            agents {
              id
              name
            }
          }
        }
      }

      jobFunctions(options: { perPage: 1000, orderBy: { primary: "desc", name: "asc" } }) {
        items {
          id
          name
          primary
        }
      }
    }
  }
`);

export const QTeamTemplateQuery = graphql(`
  query TeamTemplate($siteId: ID!, $templateId: ID!) {
    site(id: $siteId) {
      id
      teamTemplate(id: $templateId) {
        id
        name
        slug
        jobFunctions {
          id
          primary
          agents {
            id
          }
        }
      }
    }
  }
`);

export type ITeamTemplate = NonNullable<
  NonNullable<TeamTemplatesQuery['site']>['teamTemplates']
>['items'][0];

export const MCreateTeamTemplateMutation = graphql(`
  mutation CreateTeamTemplate($siteId: ID!, $data: CreateTeamTemplateInput!) {
    createTeamTemplate(siteId: $siteId, data: $data) {
      id
      name
      slug
    }
  }
`);

export const MUpdateTeamTemplateMutation = graphql(`
  mutation UpdateTeamTemplate($teamTemplateId: ID!, $data: UpdateTeamTemplateInput!) {
    updateTeamTemplate(teamTemplateId: $teamTemplateId, data: $data) {
      id
      name
      slug
    }
  }
`);

export const MDeleteTeamTemplateMutation = graphql(`
  mutation DeleteTeamTemplate($teamTemplateId: ID!) {
    deleteTeamTemplate(teamTemplateId: $teamTemplateId) {
      id
      name
      slug
    }
  }
`);
