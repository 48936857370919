import { useResponsive } from '@mortgagehippo/ds';

import { Content, Layout } from '../../layouts/main';
import { DashboardPageContent } from './dashboard-content';

const TITLE = 'Pipeline';

export const DashboardPage = () => {
  const responsive = useResponsive();

  return (
    <Layout pageTitle={TITLE} compact={responsive.PHONE.BIGGER}>
      <Content>
        <DashboardPageContent title={TITLE} />
      </Content>
    </Layout>
  );
};
