import { type ITableActions, useModal } from '@mortgagehippo/ds';
import { type IQueryTableColumns, QueryTable } from '@mortgagehippo/query-components';
import { UnreachableCaseError } from '@mortgagehippo/util';
import { useCallback, useMemo, useRef, useState } from 'react';

import { useUserCan } from '$components/permissions';

import { type PermissionsQuery, type PermissionsQueryVariables } from '../../../apollo/graphql';
import { usePartnerDomain } from '../../../hooks/use-partner-domain';
import { type IPermissionsAuthRole, QPermissions } from './queries';
import { UsersModalContainer } from './users-modal';

enum PermissionsTabActionType {
  USERS = 'users',
}

const columns: IQueryTableColumns<IPermissionsAuthRole> = [
  {
    title: 'Access Level',
    key: 'name',
    render: (t) => t.name,
  },
  {
    title: 'Description',
    key: 'description',
    render: (t) => t.description,
  },
  {
    title: 'Users',
    key: 'users',
    render: (t) => t.agents.total,
  },
];

export const PermissionsTab = () => {
  const partnerId = usePartnerDomain();
  const [selectedAuthRole, setSelectedAuthRole] = useState<IPermissionsAuthRole | undefined>();
  const [modalOpen, openModal, closeModal] = useModal(false);
  const tableRef = useRef<any>();
  const [can] = useUserCan();

  const handleUsers = useCallback(
    (record: IPermissionsAuthRole) => {
      setSelectedAuthRole(record);
      openModal();
    },
    [openModal]
  );

  const handleClose = useCallback(
    (triggerRefresh = false) => {
      if (triggerRefresh) {
        tableRef!.current.refetch();
      }

      closeModal(() => {
        setSelectedAuthRole(undefined);
      });
    },
    [closeModal]
  );

  const handleAction = useCallback(
    (actionKey: string, record: IPermissionsAuthRole) => {
      const actionType = actionKey as PermissionsTabActionType;

      switch (actionType) {
        case PermissionsTabActionType.USERS:
          handleUsers(record);
          break;
        default: {
          throw new UnreachableCaseError(actionType);
        }
      }
    },
    [handleUsers]
  );

  const actions: ITableActions = useMemo(
    () => [
      {
        key: 'options',
        label: 'Options',
        buttonProps: {
          icon: 'menu-dots',
        },
        hidden: !can.VIEW_AUTH_ROLE_USERS,
        actions: [
          {
            key: PermissionsTabActionType.USERS,
            label: 'See Users',
            iconProps: {
              name: 'team',
            },
            hidden: !can.VIEW_AUTH_ROLE_USERS,
            onAction: handleAction,
          },
        ],
      },
    ],
    [can.VIEW_AUTH_ROLE_USERS, handleAction]
  );

  return (
    <>
      <QueryTable<PermissionsQuery, PermissionsQueryVariables>
        caption="Permissions"
        rowActions={actions}
        query={QPermissions}
        dataSource={(result) => (result.partner ? result.partner.authRoles.items : [])}
        itemTotal={(result) => (result.partner ? result.partner.authRoles.total : 0)}
        nextCursor={(result) => result.partner?.authRoles.nextCursor}
        previousCursor={(result) => result.partner?.authRoles.previousCursor}
        rowKey={(item) => item.id}
        columns={columns}
        variables={{ partnerId }}
        ref={tableRef}
        bottomContent={<QueryTable.Pagination />}
      >
        <QueryTable.Data />
      </QueryTable>
      <UsersModalContainer
        partnerId={partnerId}
        authRoleId={selectedAuthRole ? selectedAuthRole.id : undefined}
        authRoleName={selectedAuthRole ? selectedAuthRole.name : undefined}
        title={selectedAuthRole ? `${selectedAuthRole.name} Users` : undefined}
        onRequestClose={handleClose}
        isOpen={modalOpen}
        width={800}
      />
    </>
  );
};
