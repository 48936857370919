import { fontSize, fontWeight, Icon, spacing, Title } from '@mortgagehippo/ds';
import { useEffect, useRef } from 'react';
import { type RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import { LoadingPage } from '../../../../loading';
import { useSmartFeesFrame } from './use-smart-fees-frame';

const BackDrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: white;
`;

const Content = styled.div`
  flex: 1;
  padding: ${spacing(10)};
  text-align: center;
`;

const ErrorTitle = styled(Title)`
  font-size: ${fontSize('md')};
  padding: ${spacing(3)} ${spacing(2)} 0;
  font-weight: ${fontWeight('bold')};
  text-align: center;
`;

const ErrorMessage = styled.p`
  max-width: 750px;
  margin: auto;
`;

export const SmartFeesIFramePage = (props: RouteComponentProps<{ id: string }>) => {
  const { match } = props;
  const { params } = match;
  const { id } = params;

  const frame = useSmartFeesFrame(id);
  const formRef = useRef<any>(undefined);

  useEffect(() => {
    if (frame?.statusCode !== 'FAILED') {
      formRef.current?.submit();
    }
  }, [frame]);

  const formValues = frame?.formValues;

  if (!frame) {
    return <LoadingPage />;
  }

  if (frame.statusCode === 'FAILED') {
    return (
      <BackDrop>
        <Content>
          <Icon size="xxxl" name="error" color="danger500" />
          <ErrorTitle level={2}>There was an error creating the smart fees file:</ErrorTitle>
          <ErrorMessage>{frame.statusMessage}</ErrorMessage>
        </Content>
      </BackDrop>
    );
  }

  return (
    <BackDrop>
      <Content>
        <form
          encType="application/x-www-form-urlencoded"
          ref={formRef}
          method="POST"
          action={frame.url || ''}
        >
          {Object.keys(formValues).map((k) => (
            <input type="hidden" key={k} name={k} value={formValues[k]} readOnly />
          ))}
        </form>
      </Content>
    </BackDrop>
  );
};
