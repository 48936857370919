import { useQuery } from '@mortgagehippo/apollo-hooks';
import { type IChoiceOption } from '@mortgagehippo/ds';

import { graphql } from '../apollo';
import { usePartnerDomain } from './use-partner-domain';

const QPartnerSites = graphql(`
  query PartnerSites($partnerId: ID!) {
    partner(id: $partnerId) {
      id
      sites(options: { perPage: 10000, orderBy: { name: "asc" } }) {
        items {
          value: id
          label: name
        }
      }
    }
  }
`);

export const useSites = (): [IChoiceOption[] | undefined, boolean] => {
  const partnerId = usePartnerDomain();
  const [data, loading] = useQuery(QPartnerSites, {
    partnerId,
  });

  const sites = data?.partner?.sites.items;
  return [sites, loading] as const;
};
