import { useQuery } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../apollo';

const QApplicationFileLosTypes = graphql(`
  query IApplicationFileLosTypes($applicationFileId: ID!) {
    applicationFile(id: $applicationFileId) {
      id
      site {
        id
        losTypes
      }
    }
  }
`);

export const useApplicationFileLosTypes = (applicationFileId: string) => {
  const [data, loading, , { refetch }] = useQuery(
    QApplicationFileLosTypes,
    {
      applicationFileId,
    },
    { suspend: false, fetchPolicy: 'network-only' }
  );

  const handleRefresh = useCallback(async () => {
    await refetch({ applicationFileId });
  }, [applicationFileId, refetch]);

  const losTypes = data?.applicationFile?.site?.losTypes || [];

  return [losTypes, loading, handleRefresh] as const;
};
