// Mutation for setting the relationships between agents and an application file
import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../apollo';

export const MAssignApplicationFileToFolder = graphql(`
  mutation AssignApplicationFileToFolder($applicationFileId: ID!, $folderId: ID!) {
    assignApplicationFileToFolder(applicationFileId: $applicationFileId, folderId: $folderId)
  }
`);

export const useAssignApplicationFileFolder = () => {
  const assignApplicationFileToFolder = useMutation(MAssignApplicationFileToFolder);

  return useCallback(
    async (applicationFileId: string, folderId: string) => {
      await assignApplicationFileToFolder({
        applicationFileId,
        folderId,
      });
    },
    [assignApplicationFileToFolder]
  );
};
