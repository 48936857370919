import { Checkbox, type FormSubmitHandler, ModalForm, T, Text, Title } from '@mortgagehippo/ds';
import { type IApplicationFileApplicant } from '@mortgagehippo/tasks';
import { orderBy } from 'lodash-es';
import { useCallback, useMemo } from 'react';

import { hasAuthorizedJointCreditPull } from '../../../pages/application/application-services/services/credit-check';

interface IJointCreditPullAuthorizationModalProps {
  applicants: IApplicationFileApplicant[];
  onSubmit: FormSubmitHandler;
  onRequestClose: () => void;
  isOpen: boolean;
}

export const JointCreditPullAuthorizationModal = (
  props: IJointCreditPullAuthorizationModalProps
) => {
  const { applicants, ...rest } = props;

  const validateCheckbox = useCallback((value: any) => {
    if (value !== true) {
      return 'Required';
    }

    return undefined;
  }, []);

  const unauthorizedApplicants = useMemo(() => {
    const result = applicants.filter((a) => !hasAuthorizedJointCreditPull(a));
    const sortedResult = orderBy(result, ['position']);

    return sortedResult.map((a, index) => ({
      ...a,
      name: a.name || <T cvars={{ number: index + 1 }}>Borrower #{index + 1}</T>,
    }));
  }, [applicants]);

  return (
    <ModalForm title="Order Credit Check" okButtonLabel={<T>Order report</T>} {...rest}>
      <Text as="p">
        This action will request a credit pull using the borrower&apos;s information currently in
        the system.
      </Text>
      <Text as="p">
        Make sure not to use this feature unless every borrower has authorized a credit check.
      </Text>
      <Title level={3}>Currently unauthorized:</Title>
      <ul>
        {unauthorizedApplicants.map((a) => (
          <li key={a.id}>{a.name}</li>
        ))}
      </ul>

      <Checkbox.Box
        label="Borrower authorization"
        labelInvisible
        name="authorized_credit_check"
        required
        validate={validateCheckbox}
      >
        <T>All borrowers have authorized a credit check.</T>
      </Checkbox.Box>
    </ModalForm>
  );
};
