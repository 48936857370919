import styled from 'styled-components';

export const CONTAINER_MAX_WIDTH = 480;

export const Container = styled.div`
  max-width: ${CONTAINER_MAX_WIDTH}px;
`;

export const Actions = styled.div`
  text-align: right;
`;
