import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../apollo';

export const MSendApplicantInvitation = graphql(`
  mutation SendApplicantInvitation($applicantId: ID!) {
    sendApplicantInvitation(applicantId: $applicantId)
  }
`);

export const useSendApplicantInvitation = () => {
  const sendApplicantInvitation = useMutation(MSendApplicantInvitation);

  return async (applicantId: string) => {
    const response = await sendApplicantInvitation({
      applicantId,
    });
    return response.data!.sendApplicantInvitation;
  };
};
