import {
  type ColumnOrderFieldType,
  getTableSortDirectionOptions,
  type ISelectOption,
  Select,
  useFormValue,
} from '@mortgagehippo/ds';
import { flatten, keyBy, reduce, sortBy } from 'lodash-es';
import { useEffect, useMemo } from 'react';

import {
  type ISmartViewColumn,
  type ISmartviewDocumentation,
  toColumnOrderFieldType,
} from '$components/smart-view';

interface ISmartviewModalSortProps {
  documentation?: ISmartviewDocumentation;
}

export const SmartviewModalSort = (props: ISmartviewModalSortProps) => {
  const { documentation } = props;
  const { dataFields } = documentation || {};

  const [selectedColumns] = useFormValue<ISmartViewColumn[] | undefined>('config.columns');
  const [defaultSortField, setDefaultSortField] = useFormValue<string | undefined>(
    'config.defaultSortField'
  );
  const [, setDefaultSortDirection] = useFormValue<string | undefined>(
    'config.defaultSortDirection'
  );

  const sortFieldOptions = useMemo(() => {
    if (!dataFields) {
      return [];
    }

    const dataFieldsByName = keyBy(dataFields, (f) => f.name);
    const selectedFields = selectedColumns ? flatten(selectedColumns.map((c) => c.fields)) : [];

    const nextSortFieldOptions = reduce<string, ISelectOption[]>(
      selectedFields,
      (accum, name) => {
        const field = dataFieldsByName[name];

        if (!field) {
          return accum;
        }

        const { title } = field;

        accum.push({
          label: title,
          value: name,
        });

        return accum;
      },
      []
    );

    return sortBy(nextSortFieldOptions, 'label');
  }, [dataFields, selectedColumns]);

  const sortDirectionOptions = useMemo(() => {
    let fieldType: ColumnOrderFieldType | undefined;

    if (defaultSortField && dataFields) {
      const field = dataFields.find((f) => f.name === defaultSortField);
      fieldType = field ? toColumnOrderFieldType(field.type) : undefined;
    }

    return getTableSortDirectionOptions(fieldType);
  }, [dataFields, defaultSortField]);

  useEffect(() => {
    if (!defaultSortField) {
      return;
    }

    const selectedFields = selectedColumns ? flatten(selectedColumns.map((c) => c.fields)) : [];

    if (!selectedFields.includes(defaultSortField)) {
      setDefaultSortField(undefined);
    }
  }, [defaultSortField, selectedColumns, setDefaultSortField]);

  useEffect(() => {
    if (!defaultSortField) {
      setDefaultSortDirection(undefined);
    }
  }, [defaultSortField, setDefaultSortDirection]);

  return (
    <>
      <Select.Box
        name="config.defaultSortField"
        label="Default sort column"
        options={sortFieldOptions}
        searchable
        size="sm"
        placeholder="Select column"
        required
      />
      <Select.Box
        name="config.defaultSortDirection"
        label="Default sort direction"
        options={sortDirectionOptions}
        size="sm"
        required
        disabled={!defaultSortField}
      />
    </>
  );
};
