import { type IUSAddress } from '@mortgagehippo/ds';
import {
  type ICreditPullScore,
  type ILiability,
  type IRelationship,
  LiabilityType,
} from '@mortgagehippo/tasks';
import { isPresent } from '@mortgagehippo/util';
import { get, has } from 'lodash-es';
import moment from 'moment';
import * as uuid from 'uuid';

import { type CreditPullStatusType } from '../../../../../apollo/graphql';
import { type ICreditPull } from '../../../use-applicants-credit-pull-data';
import {
  ASSET_OBJECT_TYPE,
  ASSET_REAL_ESTATE_OWNED_TYPE_NAME,
  LIABILITY_OBJECT_TYPE,
} from './constants';
import { type ICreditCheckPageCreditPull } from './types';

export const createEmptyCreditPull = (
  status: CreditPullStatusType
): Omit<ICreditPull, 'scores'> & { scores: ICreditPullScore[] } => ({
  id: uuid.v4(),
  statusCode: status,
  statusDescription: null,
  vendorOrderIdentifier: null,
  createdAt: moment.now().toString(),
  scores: [],
  requestedBy: null,
});

export const isIndividualCreditPull = (creditPull: ICreditCheckPageCreditPull) =>
  creditPull.scores.length <= 1;

export const isJointCreditPull = (creditPull: ICreditCheckPageCreditPull) =>
  creditPull.scores.length > 1;

export const hasAuthorizedCreditPull = (answers: any) =>
  get(answers, 'authorized_credit_check', false);

export const hasAuthorizedJointCreditPull = (answers: any) =>
  get(answers, 'authorized_joint_credit_pull', false);

export const downloadPDF = (fileContents: string, fileName = 'credit_report.pdf') => {
  const linkSource = `data:application/pdf;base64,${fileContents}`;
  const downloadLink = document.createElement('a');

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const assetToString = (asset: any): string => {
  const { line_text, unit_identifier, postal_code, city, state_code } = asset.address || {};

  const result: string[] = [];

  if (isPresent(line_text)) {
    result.push(line_text);
  }

  if (isPresent(unit_identifier)) {
    result.push(unit_identifier);
  }

  if (isPresent(city)) {
    result.push(city);
  }

  if (isPresent(state_code) || isPresent(postal_code)) {
    const formattedPostalCode =
      postal_code?.length >= 5 ? `${postal_code.slice(0, 5)}-${postal_code.slice(5)}` : postal_code;
    result.push(`${state_code || ''} ${formattedPostalCode || ''}`);
  }

  return result.join(', ');
};

export interface IRealEstateOwned {
  id: string | number;
  __id: string | number;
  __deleted?: boolean;
  type_name?: string;
  address?: IUSAddress;
}

export const createModel = (answers: Record<string, any> | undefined) => {
  const liabilities: ILiability[] = answers?.liabilities?.filter((l: any) => !l.__deleted) ?? [];
  const realEstateOwned: IRealEstateOwned[] =
    answers?.assets?.filter(
      (a: IRealEstateOwned) => !a.__deleted && a.type_name === ASSET_REAL_ESTATE_OWNED_TYPE_NAME
    ) ?? [];
  const loans = liabilities.filter((l) => l.type_name === LiabilityType.MortgageLoan);
  const relationships: IRelationship[] = answers?.relationships ?? [];

  // Create relationship maps
  const liabilityToReoMap: Record<string | number, string | number> = {};
  const reoToLiabilityMap: Record<string | number, string | number> = {};

  for (let i = 0; i < relationships.length; i += 1) {
    const r = relationships[i]!;
    if (r.__deleted) continue;
    if (!r.parent_object_id) continue;
    if (!r.child_object_id) continue;
    if (r.parent_object_type !== ASSET_OBJECT_TYPE) continue;
    if (r.child_object_type !== LIABILITY_OBJECT_TYPE) continue;

    reoToLiabilityMap[r.parent_object_id] = r.child_object_id;
    liabilityToReoMap[r.child_object_id] = r.parent_object_id;
  }

  // Create account number map
  const liabilityAccountNumberMap: Record<string, number> = {};
  liabilities.forEach((l) => {
    if (isPresent(l.account_identifier)) {
      liabilityAccountNumberMap[l.account_identifier!] ||= 0;
      liabilityAccountNumberMap[l.account_identifier!] += 1;
    }
  });

  // Calculate duplicate count
  let duplicateCount = 0;
  Object.values(liabilityAccountNumberMap).forEach((count) => {
    if (count > 1) duplicateCount += count;
  });

  // Calculate unassociated liabilities count
  const isDuplicateLiability = (l: ILiability) =>
    isPresent(l.account_identifier) && liabilityAccountNumberMap[l.account_identifier!]! > 1;

  const getDuplicateLiabilityCount = () => duplicateCount;

  const isExcludedUnknownStatusLiability = (l: ILiability) =>
    l.excluded &&
    has(l, ['__verified', 'account_status']) &&
    l.__verified?.account_status !== 'Open';

  const getUnassociatedLoanCount = () =>
    loans.reduce((acum, l) => acum + (!liabilityToReoMap[l.id! || l.__id!] ? 1 : 0), 0);

  const getRealEstateOwnedForLiability = (
    l: ILiability | undefined
  ): IRealEstateOwned | undefined => {
    if (!l) return undefined;
    const realEstateOwnedId = liabilityToReoMap[l.id! || l.__id!];
    return realEstateOwned.find(
      (r: any) =>
        (r.id && `${r.id}` === `${realEstateOwnedId}`) ||
        (r.__id && `${r.__id}` === `${realEstateOwnedId}`)
    );
  };

  const getLiabilityForRealEstateOwned = (
    a: IRealEstateOwned | undefined
  ): ILiability | undefined => {
    if (!a) return undefined;
    const liabilityId = reoToLiabilityMap[a.id! || a.__id!];
    return liabilities.find(
      (l) =>
        (l.id && `${l.id}` === `${liabilityId}`) || (l.__id && `${l.__id}` === `${liabilityId}`)
    );
  };

  const getRealEstateOwnedIdForLiability = (
    l: ILiability | undefined
  ): string | number | undefined => {
    if (!l) return undefined;
    const a = getRealEstateOwnedForLiability(l);
    return a?.id || a?.__id;
  };

  const getLiabilityIdForRealEstateOwned = (
    a: IRealEstateOwned | undefined
  ): string | number | undefined => {
    if (!a) return undefined;
    const l = getLiabilityForRealEstateOwned(a);
    return l?.id || a?.__id;
  };

  return {
    liabilities,
    realEstateOwned,
    getDuplicateLiabilityCount,
    getUnassociatedLoanCount,
    isDuplicateLiability,
    isExcludedUnknownStatusLiability,
    getLiabilityForRealEstateOwned,
    getLiabilityIdForRealEstateOwned,
    getRealEstateOwnedForLiability,
    getRealEstateOwnedIdForLiability,
  };
};

export type IModel = ReturnType<typeof createModel>;
