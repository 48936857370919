import { type IModalFormProps, ModalForm, YesNo } from '@mortgagehippo/ds';

import { type IApplicationApplicant } from '../../../../../pages/application/use-application-file-data';
import { DefaultFields } from '../default-fields';

interface IRequestDocumentTaskEditorProps extends IModalFormProps {
  applicants: IApplicationApplicant[];
  showDueDate?: boolean;
}

export const RequestDocumentTaskEditor = (props: IRequestDocumentTaskEditorProps) => {
  const { applicants, showDueDate, children, ...rest } = props;

  return (
    <ModalForm {...rest}>
      {children}
      <DefaultFields applicants={applicants} allowCommon showDueDate={showDueDate} />
      <YesNo.Box name="data.required" label="Required" initialValue required />
    </ModalForm>
  );
};
