import { type RouteComponentProps } from 'react-router';

import { LinkActivityEvent } from '../../components/link-activity-event';
import { LoadingPage } from '../loading';
import { NotFoundPage } from '../not-found';

export interface ILinkActivityEventPageParams {
  path: string;
}

export const LinkActivityEventPage = (props: RouteComponentProps<ILinkActivityEventPageParams>) => {
  const { match } = props;
  const { params } = match;
  const { path } = params;

  return (
    <LinkActivityEvent
      path={path}
      loadingComponent={<LoadingPage />}
      errorComponent={<NotFoundPage />}
    />
  );
};
