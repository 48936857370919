import { type IAction } from '@mortgagehippo/actionable';
import { fontWeight, spacing, Title } from '@mortgagehippo/ds';
import {
  APPLICATION_TASK_TYPES,
  type ApplicationErrors,
  type IBlueprint,
  type ITask,
  SubmitApplicationTask,
  TaskContainer,
  TaskSkeleton,
  TaskType,
  useAnswers,
} from '@mortgagehippo/tasks';
import { NotFoundError, withErrorBoundary } from '@mortgagehippo/util';
import { find } from 'lodash-es';
import { type ReactNode } from 'react';
import styled from 'styled-components';

import { GeneralError } from '$components/general-error';

import { useApplicationFileCan } from '../../../components/permissions';
import { type IApplicationApplicant } from '../use-application-file-data';
import { useApplicationErrors } from './use-application-errors';

const StyledTitle = styled(Title)`
  margin-bottom: ${spacing(7)};

  span {
    font-weight: ${fontWeight('light', 'secondary')};
  }
`;

interface IApplicationViewContentProps {
  applicationBlueprint: IBlueprint;
  applicationFileId: string;
  applicantId: string;
  applicants: IApplicationApplicant[];
  task: ITask | null;
  onNext: (key: string) => void;
  onAction?: (action: IAction<string, any, any>) => void;
}

const ApplicationViewContentWithBoundary = (props: IApplicationViewContentProps) => {
  const {
    applicationBlueprint,
    applicationFileId,
    applicantId,
    applicants,
    task,
    onNext,
    onAction,
  } = props;

  const [applicationErrors, setApplicationErrors] = useApplicationErrors();

  const applicant = find(applicants, (a) => a.id === applicantId);
  const applicantFirstName =
    (applicant && applicants.length > 1 && applicant.firstName) || undefined;

  const shouldLoadAnswers = !!task && task.typeName === TaskType.SubmitApplicationTask;
  const [answers, answersLoading] = useAnswers(applicationFileId, applicantId, !shouldLoadAnswers);
  const [can, canReady] = useApplicationFileCan(applicationFileId);

  const handleValidationStatusChange = (errors: ApplicationErrors | null) => {
    setApplicationErrors(errors);
  };

  const handleComplete = (key: string) => async () => {
    onNext(key);
    return undefined;
  };

  const isValidTaskType = !!task && !!APPLICATION_TASK_TYPES.find((type) => type === task.typeName);
  if (!task || !isValidTaskType) {
    throw new NotFoundError();
  }

  let el: ReactNode;

  if (answersLoading || !canReady) {
    el = <TaskSkeleton />;
  } else if (task.typeName === TaskType.SubmitApplicationTask) {
    el = (
      <SubmitApplicationTask
        task={task}
        frame={false}
        blueprint={applicationBlueprint}
        initialValues={answers}
        applicationFileId={applicationFileId}
        applicantId={applicantId}
        applicants={applicants}
        showHideButton={!!applicationErrors}
        onAction={onAction}
        onValidationStatusChange={handleValidationStatusChange}
      />
    );
  } else {
    el = (
      <TaskContainer
        key={task.key}
        task={task}
        taskBlueprintType="lender"
        applicationFileId={applicationFileId}
        frame={false}
        applicantId={applicantId}
        onAction={onAction}
        freezeVerifiedFields={false}
        formAutoComplete={false}
        formShowErrorIf={applicationErrors ? 'always' : 'touched'}
        onComplete={handleComplete(task.key)}
        preventDownloads={!can.DOWNLOAD_DOCUMENTS}
      />
    );
  }

  return (
    <>
      <StyledTitle>
        {applicantFirstName ? <span>{`${applicantFirstName}: `}</span> : null}
        {task.meta.title}
      </StyledTitle>
      {el}
    </>
  );
};

export const ApplicationViewContent = withErrorBoundary<IApplicationViewContentProps>(GeneralError)(
  ApplicationViewContentWithBoundary
);
