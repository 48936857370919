import {
  FORM_ERROR,
  FormError,
  type FormSubmitHandler,
  type IModalFormProps,
  ModalForm,
  notifications,
  type UploadHandler,
} from '@mortgagehippo/ds';
import axios, { type AxiosProgressEvent } from 'axios';
import { useCallback } from 'react';

import { type IApplicationApplicant } from '../../../../../pages/application/use-application-file-data';
import { useCreateDocusignEnvelopeUploadUrl } from '../../../edit-custom-task-action/custom-docusign-task/use-create-docusign-envelope-upload-url';
import { DocusignTaskEditorFields } from './docusign-task-editor-fields';

interface IDocusignTaskEditorProps extends IModalFormProps {
  applicationFileId: string;
  applicants: IApplicationApplicant[];
  showDueDate?: boolean;
}

export const DocusignTaskEditor = (props: IDocusignTaskEditorProps) => {
  const { applicationFileId, applicants, onSubmit, showDueDate, children, ...rest } = props;

  const createUploadUrl = useCreateDocusignEnvelopeUploadUrl();

  const handleUpload: UploadHandler = async (file, meta, progress) => {
    try {
      const { id, postUrl } = await createUploadUrl(applicationFileId, meta.filename);

      await axios.put(postUrl, file, {
        headers: {
          'Content-Type': meta.mime,
        },
        onUploadProgress: (e: AxiosProgressEvent) => {
          if (!e.total) return;
          const percent = Math.round((e.loaded * 100) / e.total);
          progress(percent);
        },
      });

      return { id };
    } catch (e) {
      notifications.error({
        message: 'There was an unexpected error while uploading your file',
      });
      throw e;
    }
  };

  const handleSubmit: FormSubmitHandler = useCallback(
    (values, form, callback) => {
      const { data } = values || {};
      const { envelope_id: envelopeId } = data || {};

      if (!envelopeId) {
        return { [FORM_ERROR]: 'You must finish configuring the signatures.' };
      }

      if (!onSubmit) {
        return undefined;
      }

      return onSubmit(values, form, callback);
    },
    [onSubmit]
  );

  return (
    <ModalForm {...rest} onSubmit={handleSubmit}>
      {children}
      <DocusignTaskEditorFields
        applicationFileId={applicationFileId}
        applicants={applicants}
        onUpload={handleUpload}
        showDueDate={showDueDate}
      />
      <FormError />
    </ModalForm>
  );
};
