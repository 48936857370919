import {
  borderRadius,
  Email,
  Input,
  type ISelectOption,
  Phone,
  Select,
  spacing,
  Title,
  YesNo,
} from '@mortgagehippo/ds';
import styled from 'styled-components';

import { Collapse } from '$components/collapse';

const StyledPanel = styled(Collapse.Panel)`
  border-radius: ${borderRadius(2)};
  padding: ${spacing(4)};
  margin: ${spacing(6)} -${spacing(4)} 0;
  background-color: #f7f8fa;

  &:first-child {
    margin-top: 0;
  }

  .mh-panel-content {
    margin-top: ${spacing(3)};
  }
`;

const StyledTitle = styled(Title)`
  margin-bottom: 0;
`;

const FieldsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -${spacing(3)};
`;

const FieldsCol = styled.div`
  flex: 1 1 auto;
  margin: 0 ${spacing(3)};
`;

interface ICreateApplicationModalFieldsProps {
  siteOptions: ISelectOption[];
}

export const CreateApplicationModalFields = (props: ICreateApplicationModalFieldsProps) => {
  const { siteOptions } = props;

  return (
    <>
      <Email.Box name="email" label="Email" required />
      <Select.Box name="site_id" label="Site" options={siteOptions} required searchable />
      <YesNo.Box name="send_invitation" label="Send invitation email to borrower?" initialValue />
      <Collapse defaultActiveKeys={['basic']}>
        <StyledPanel
          key="basic"
          label={<StyledTitle level={3}>Basic Information (optional)</StyledTitle>}
          iconLocation="right"
          showIcon={false}
          disabled
        >
          <FieldsRow>
            <FieldsCol>
              <Input.Box label="First Name" name="first_name" />
            </FieldsCol>
            <FieldsCol>
              <Input.Box label="Last Name" name="last_name" />
            </FieldsCol>
          </FieldsRow>
          <Phone.Box label="Cell Phone" name="cell_phone_number" />
          <Phone.Box label="Home Phone" name="home_phone_number" />
        </StyledPanel>
      </Collapse>
    </>
  );
};
