import { useQuery } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../apollo';
import { usePartnerDomain } from './use-partner-domain';

const QPartnerAuthRoles = graphql(`
  query PartnerAuthRoles($partnerId: ID!) {
    partner(id: $partnerId) {
      id
      authRoles(options: { perPage: 10000, orderBy: { name: "asc" } }) {
        items {
          id
          name
          description
        }
      }
    }
  }
`);

export const useAuthRoles = (partnerId?: string | number) => {
  const defaultPartnerId = usePartnerDomain();
  const [data, loading] = useQuery(
    QPartnerAuthRoles,
    {
      partnerId: (partnerId || defaultPartnerId) as string,
    },
    { fetchPolicy: 'network-only' }
  );

  const authRoles = !loading && data?.partner ? data.partner.authRoles.items : undefined;

  return [authRoles, loading] as const;
};
