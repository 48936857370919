import { Children, cloneElement, type ReactNode, useCallback, useState } from 'react';
import styled from 'styled-components';

import { CollapsePanel } from './collapse-panel';

interface ICollapseProps {
  defaultActiveKeys?: string[];
  className?: string;
  children?: ReactNode;
}

const PanelsList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const CollapseContainer = (props: ICollapseProps) => {
  const { defaultActiveKeys, className = '', children } = props;
  const [activeKeys, setActiveKeys] = useState<string[]>(defaultActiveKeys || []);

  const handlePanelClick = useCallback(
    (key: string) => {
      const index = activeKeys.indexOf(key);

      if (index > -1) {
        const copy = [...activeKeys];
        copy.splice(index, 1);
        setActiveKeys([...copy]);
      } else {
        setActiveKeys([key, ...activeKeys]);
      }
    },
    [activeKeys]
  );

  const panels = Children.map(children, (child: any, index) => {
    if (!child) {
      return null;
    }

    // If there is no key provide, use the panel order as default key
    const key = child.key || String(index);
    const isActive = activeKeys.includes(key);

    const childProps = {
      ...child.props,
      key,
      panelKey: key,
      isActive,
      onClick: handlePanelClick,
    };

    return cloneElement(child, childProps);
  });

  return <PanelsList className={`mh-collapse ${className}`}>{panels}</PanelsList>;
};

export const Collapse = Object.assign(CollapseContainer, { Panel: CollapsePanel });
