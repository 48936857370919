import { ModalDialog, notifications, useCustomizations, useModal } from '@mortgagehippo/ds';
import { useCallback } from 'react';
import { useCompleteTask } from 'src/pages/application/application-tasks/use-complete-task';

import { useDeleteTask } from '../../../pages/application/use-delete-task';
import { type IActionProps, type IDeleteTaskAction } from '../types';

export const DeleteTaskAction = (props: IActionProps<IDeleteTaskAction>) => {
  const { action, onDone, onCancel } = props;
  const { taskId } = action;

  const [isOpen, , closeModal] = useModal(true);

  const customizations = useCustomizations();
  const title = customizations.text('delete-custom-task-action:confirm-title', 'Are you sure?');
  const explanation = customizations.text(
    'delete-custom-task-action:confirm-explanation',
    'This action is irreversible'
  );

  const deleteTask = useDeleteTask();
  const completeTask = useCompleteTask();

  const handleOk = async () => {
    try {
      await completeTask(taskId);
      await deleteTask(taskId);

      closeModal(() => {
        notifications.success({
          messageCid: 'delete-custom-task-action:notification-success',
          message: 'Successfully deleted task',
        });
        onDone();
      });
    } catch (e) {
      notifications.error({
        messageCid: 'delete-custom-task-action:notification-error',
        message: 'There was an unexpected error deleting task',
      });
    }
  };

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  // TODO: use hook useModalConfirm
  return (
    <ModalDialog
      type="error"
      title={title}
      onOk={handleOk}
      onRequestClose={handleCloseModal}
      isOpen={isOpen}
    >
      {explanation}
    </ModalDialog>
  );
};
