import { useModal } from '@mortgagehippo/ds';
import { useSagaSubmitHandler } from '@mortgagehippo/tasks';
import { useCallback } from 'react';

import { useUpdateAgent } from '../../../hooks/agents/use-update-agent';
import { usePartnerDomain } from '../../../hooks/use-partner-domain';
import { type IActionProps, type IUpdateAgentAction } from '../types';
import { AgentModalContainer } from './agent-modal-container';
import { toUpdateAgentServerInput } from './util';

export const UpdateAgentAction = (props: IActionProps<IUpdateAgentAction>) => {
  const { action, onDone, onCancel } = props;
  const { agentId } = action;

  const [isOpen, , closeModal] = useModal(true);

  const partnerId = usePartnerDomain();
  const updateAgent = useUpdateAgent();

  const [handleSubmit] = useSagaSubmitHandler({
    infoMessageCid: 'update-agent-action:info',
    successMessageCid: 'update-agent-action:notification-success',
    errorMessageCid: 'update-agent-action:notification-error',
    infoMessage: 'Updating agent',
    successMessage: 'Successfully updated agent.',
    errorMessage: 'There was an unexpected error updating the agent.',
    async onSubmit(values: any, setAsyncId) {
      const input: any = toUpdateAgentServerInput(values);

      const nextAsyncId = await updateAgent(agentId, input);

      closeModal(() => {
        setAsyncId(nextAsyncId);
      });
    },
    async onDone() {
      closeModal(onDone);
    },
  });

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  return (
    <AgentModalContainer
      partnerId={partnerId}
      isOpen={isOpen}
      agentId={agentId}
      onSubmit={handleSubmit}
      onRequestClose={handleCloseModal}
    />
  );
};
