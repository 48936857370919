import { Spinner } from '@mortgagehippo/ds';
import styled from 'styled-components';

const Container = styled.div`
  background: white;
  height: 100vh;
  flex: 1 1 100vw;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f3f6fa; /* neutral50 - can't use palette - no customization provider here */
`;

const StyledSpinner = styled(Spinner)`
  width: 100px !important;
  height: 100px !important;
  color: #262a2f; /* neutral800 - can't use palette - no customization provider here */
`;

export const LoadingPage = () => (
  <Container>
    <StyledSpinner size="xxxl" type="spinner4" />
  </Container>
);
