export enum FolderActionType {
  ADD_FOLDER = 'ADD_FOLDER',
  UPDATE_FOLDER = 'UPDATE_FOLDER',
  DELETE_FOLDER = 'DELETE_FOLDER',
  MOVE_FOLDER = 'MOVE_FOLDER',
  ADD_USERS = 'ADD_USERS',
  DELETE_USER = 'DELETE_USER',
  RESTORE_USER = 'RESTORE_USER',
}

export interface IBaseFolderAction {
  id: string;
  type: FolderActionType;
  folderId: string;
  prevData: any;
  nextData: any;
}

export interface IAddFolderAction extends IBaseFolderAction {
  type: FolderActionType.ADD_FOLDER;
  prevData: null;
  nextData: {
    name: string;
    description?: string | null;
    parentId?: string;
  };
}

export interface IUpdateFolderAction extends IBaseFolderAction {
  type: FolderActionType.UPDATE_FOLDER;
  prevData: {
    name: string;
    description?: string | null;
  };
  nextData: {
    name: string;
    description?: string | null;
  };
}

export interface IDeleteFolderAction extends IBaseFolderAction {
  type: FolderActionType.DELETE_FOLDER;
  prevData: {
    name: string;
    description?: string | null;
    parentId?: string;
  };
  nextData: null;
}

export interface IMoveFolderAction extends IBaseFolderAction {
  type: FolderActionType.MOVE_FOLDER;
  prevData: {
    parentId?: string;
  };
  nextData: {
    parentId?: string;
  };
}

export interface IAddFolderUsersAction extends IBaseFolderAction {
  type: FolderActionType.ADD_USERS;
  prevData: null;
  nextData: {
    users: Array<{ userId: string; name: string; email: string }>;
  };
}

export interface IDeleteFolderUserAction extends IBaseFolderAction {
  type: FolderActionType.DELETE_USER;
  prevData: {
    userId: string;
    name: string;
    email: string;
  };
  nextData: null;
}

export interface IRestoreFolderUserAction extends IBaseFolderAction {
  type: FolderActionType.RESTORE_USER;
  prevData: {
    userId: string;
  };
  nextData: {
    userId: string;
  };
}

export type IFolderAction =
  | IAddFolderAction
  | IUpdateFolderAction
  | IDeleteFolderAction
  | IMoveFolderAction
  | IAddFolderUsersAction
  | IDeleteFolderUserAction
  | IRestoreFolderUserAction;
