import { Divider } from '@mortgagehippo/ds';
import { Fragment } from 'react';

import { ApplicationOverviewLiabilitiesSectionCreditScore } from './application-overview-liabilities-section-credit-score';
import { type ILiabilitiesOverviewSectionApplicant } from './use-liabilities-overview-section';

export interface IApplicationOverviewLiabilitiesSectionCreditPullsProps {
  applicants: ILiabilitiesOverviewSectionApplicant[];
}

export const ApplicationOverviewLiabilitiesSectionCreditPulls = (
  props: IApplicationOverviewLiabilitiesSectionCreditPullsProps
) => {
  const { applicants } = props;

  const showNames = applicants.length > 1;

  if (!applicants.length) {
    return null;
  }

  return (
    <>
      {applicants.map((applicant) => (
        <Fragment key={`${applicant.id}_credit_score`}>
          <Divider compact />
          <ApplicationOverviewLiabilitiesSectionCreditScore
            applicant={applicant}
            showNames={showNames}
          />
        </Fragment>
      ))}
    </>
  );
};
