import { useAuth } from '@mortgagehippo/auth';
import { notifications, type UploadHandler, useModal } from '@mortgagehippo/ds';
import { useSagaSubmitHandler } from '@mortgagehippo/tasks';
import { useCallback, useState } from 'react';

import { useImportMismo } from '../../../pages/application/use-import-mismo';
import { type IActionProps, type IImportMismoAction } from '../types';
import { ImportMismoModalContainer } from './import-mismo-modal-container';

export const ImportMismoAction = (props: IActionProps<IImportMismoAction>) => {
  const { onDone, onSubmit, onCancel } = props;
  const [user] = useAuth();

  const importMismo = useImportMismo();
  const [isOpen, , closeModal] = useModal(true);

  const [submitDisabled, setSubmitDisabled] = useState<boolean | undefined>();

  const [handleSubmit, running] = useSagaSubmitHandler({
    infoMessageCid: 'import-application-action:info',
    successMessageCid: 'import-application-action:success',
    errorMessageCid: 'import-application-action:error',
    infoMessage: 'Importing the application',
    successMessage: 'The application was successfully imported.',
    errorMessage: 'There was an unexpected error importing.',
    async onSubmit(values: any, setAsyncId) {
      const { site_id: siteId, data } = values;
      const agentId = user!.id;
      const file = (data.documents || []).map(({ text }: any) => text)[0];
      const nextAsyncId = await importMismo(agentId, siteId, file);

      closeModal(() => {
        setAsyncId(nextAsyncId);
        onSubmit();
      });
    },
    async onDone() {
      closeModal(onDone);
    },
  });

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  const handleUpload: UploadHandler = async (file, _meta, _progress) => {
    try {
      setSubmitDisabled(true);

      const text = await file.text();

      return { id: file.name, text };
    } catch (e) {
      notifications.error({
        messageCid: 'importMismoModal:notification.upload.error',
        message: 'There was an unexpected error while uploading your file',
      });
      throw e;
    } finally {
      setSubmitDisabled(false);
    }
  };

  if (running || !user) {
    return null;
  }

  return (
    <ImportMismoModalContainer
      isOpen={isOpen}
      onSubmit={handleSubmit}
      onRequestClose={handleCloseModal}
      onUpload={handleUpload}
      submitDisabled={submitDisabled}
    />
  );
};
