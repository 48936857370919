import {
  borderRadius,
  fontSize,
  fontWeight,
  type IModalFormProps,
  type ISelectOption,
  lineHeight,
  palette,
  Select,
  spacing,
} from '@mortgagehippo/ds';
import { getTaskTypeLabel, TaskType } from '@mortgagehippo/tasks';
import { toArray } from '@mortgagehippo/util';
import { isArray, isNil, sortBy } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { type IApplicationApplicant } from '../../../../pages/application/use-application-file-data';
import { type IUnderwritingCondition } from '../../../../pages/application/use-undewriting-conditions';
import { COMMON_APPLICANT_OPTION_VALUE, DEFAULT_CUSTOM_TASK_TITLE } from '../constants';
import { type IBulkCustomTask } from '../types';
import {
  AssetsTaskEditor,
  DocusignTaskEditor,
  HellosignTaskEditor,
  InformationTaskEditor,
  PaymentTaskEditor,
  RequestDocumentTaskEditor,
  SendDocumentTaskEditor,
} from './editors';

const translateApplicantIds = (
  _prevType: TaskType,
  nextType: TaskType,
  applicantIds?: string | string[]
) => {
  // docusign doesn't allow common tasks
  const allowCommon = nextType !== TaskType.DocusignTask;
  // hellosign uses radioboxes (no multiple applicant selection)
  const allowMultiple = nextType !== TaskType.HellosignTask;

  if (isNil(applicantIds)) {
    return applicantIds;
  }

  const idsArray = toArray(applicantIds);

  const isCommon = idsArray.some((id) => id === COMMON_APPLICANT_OPTION_VALUE);
  if (isCommon && !allowCommon) {
    // current selection is incompatible
    return undefined;
  }

  const isMultiple = idsArray.length > 1;
  if (isMultiple && !allowMultiple) {
    // current selection is incompatible
    return undefined;
  }

  if (isArray(applicantIds)) {
    return allowMultiple ? applicantIds : applicantIds[0];
  }

  // applicantIds is a string
  return allowMultiple ? [applicantIds] : applicantIds;
};

const Item = styled.div``;
const Label = styled.div``;
const Value = styled.div``;

const UnderwritingCondition = styled.div`
  background-color: ${palette('neutral50')};
  padding: ${spacing(4)};
  color: ${palette('neutral900')};
  border-radius: ${borderRadius(3)};
  margin-bottom: ${spacing(5)};

  ${Item} {
    display: flex;
    align-items: center;
    margin-top: ${spacing(2)};
    font-size: ${fontSize('xs')};
    line-height: ${lineHeight('xs')};

    &:first-child {
      margin-top: 0;
    }
  }

  ${Label} {
    flex: 0 0 70px;
    text-transform: uppercase;
    padding-right: ${spacing(2)};
  }

  ${Value} {
    flex: 1 1 auto;
    font-weight: ${fontWeight('bold')};
  }
`;

interface ITaskEditorModalProps extends Omit<IModalFormProps, 'children'> {
  applicationFileId: string;
  applicants: IApplicationApplicant[];
  task?: IBulkCustomTask;
  underwritingConditions?: IUnderwritingCondition[];
  underwritingTaskTypes?: TaskType[];
  showDueDate?: boolean;
}

export const TaskEditor = (props: ITaskEditorModalProps) => {
  const {
    applicationFileId,
    task,
    applicants,
    underwritingConditions,
    underwritingTaskTypes,
    ...rest
  } = props;
  const { type: taskType, underwriting_condition_id: underwritingConditionId } = task || {};

  const [overriddenType, setOverriddenType] = useState<TaskType | undefined>();

  const activeType = overriddenType || taskType;

  const handleOverrideType = useCallback(
    (nextType: TaskType) => {
      if (nextType === taskType) {
        // if we are setting it back to what it originally was just undo the change
        setOverriddenType(undefined);
        return;
      }

      setOverriddenType(nextType);
    },
    [taskType]
  );

  const initialValues = useMemo(() => {
    if (!task) {
      return {};
    }

    const { title, applicant_ids: applicantIds } = task;

    const isDefaultTitle =
      !!title && title.toLowerCase() === DEFAULT_CUSTOM_TASK_TITLE.toLowerCase();

    let nextInitialValues: any = {
      ...task,
      title: !isDefaultTitle ? title : undefined,
    };

    if (overriddenType && overriddenType !== task.type) {
      /*
       * some tasks may allow for common tasks, some may allow for multiple applicants, etc
       * we have to make adjustments when switching task types
       */
      const nextApplicantIds = translateApplicantIds(task.type, overriddenType, applicantIds);

      /*
       * if we overrode the type we should reset any fields
       * that are specific to the task type
       */
      nextInitialValues = {
        ...nextInitialValues,
        type: overriddenType,
        data: undefined,
        state: undefined,
        applicant_ids: nextApplicantIds,
      };
    }

    return nextInitialValues;
  }, [overriddenType, task]);

  const underwritingCondition = underwritingConditionId
    ? underwritingConditions?.find((u) => u.id === underwritingConditionId)
    : undefined;

  const taskTypeOptions: ISelectOption[] = useMemo(() => {
    const nextOptions: ISelectOption[] = (underwritingTaskTypes || []).map((t) => ({
      label: getTaskTypeLabel(t),
      value: t,
    }));

    return sortBy(nextOptions, ['label']);
  }, [underwritingTaskTypes]);

  const children = underwritingCondition && (
    <UnderwritingCondition>
      {taskTypeOptions.length > 1 && (
        <Item>
          <Label id="override_type_label">Task type</Label>
          <Value>
            <Select.Input
              name="override_type"
              value={activeType}
              onChange={handleOverrideType}
              options={taskTypeOptions}
              aria-labelledby="override_type_label"
              size="sm"
              compact
            />
          </Value>
        </Item>
      )}
      <Item>
        <Label>Condition</Label>
        <Value>{underwritingCondition.title || <>&mdash;</>}</Value>
      </Item>
    </UnderwritingCondition>
  );

  useEffect(() => {
    // reset when the modal is closed
    if (!task || task.type === overriddenType) {
      setOverriddenType(undefined);
    }
  }, [overriddenType, task]);

  switch (activeType) {
    case TaskType.InformationTask: {
      return (
        <InformationTaskEditor applicants={applicants} initialValues={initialValues} {...rest}>
          {children}
        </InformationTaskEditor>
      );
    }
    case TaskType.AssetsTask: {
      return (
        <AssetsTaskEditor applicants={applicants} initialValues={initialValues} {...rest}>
          {children}
        </AssetsTaskEditor>
      );
    }
    case TaskType.PaymentTask: {
      return (
        <PaymentTaskEditor applicants={applicants} initialValues={initialValues} {...rest}>
          {children}
        </PaymentTaskEditor>
      );
    }
    case TaskType.DocumentSubmissionTask: {
      return (
        <RequestDocumentTaskEditor applicants={applicants} initialValues={initialValues} {...rest}>
          {children}
        </RequestDocumentTaskEditor>
      );
    }
    case TaskType.SendDocumentTask: {
      return (
        <SendDocumentTaskEditor
          applicationFileId={applicationFileId}
          applicants={applicants}
          initialValues={initialValues}
          {...rest}
        >
          {children}
        </SendDocumentTaskEditor>
      );
    }
    case TaskType.HellosignTask: {
      return (
        <HellosignTaskEditor
          applicationFileId={applicationFileId}
          applicants={applicants}
          initialValues={initialValues}
          {...rest}
        >
          {children}
        </HellosignTaskEditor>
      );
    }
    case TaskType.DocusignTask: {
      return (
        <DocusignTaskEditor
          applicationFileId={applicationFileId}
          applicants={applicants}
          initialValues={initialValues}
          {...rest}
        >
          {children}
        </DocusignTaskEditor>
      );
    }
    default: {
      return null;
    }
  }
};
