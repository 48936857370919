import { type ReactNode } from 'react';

export interface ContentComponentProps {
  children?: ReactNode;
}

export const ContentComponent = (props: ContentComponentProps) => {
  const { children } = props;
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
