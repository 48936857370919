import {
  fontWeight,
  type IBoxProps,
  T,
  useCustomizations,
  useFieldInitialValue,
  useFormValue,
} from '@mortgagehippo/ds';
import { isPresent } from '@mortgagehippo/util';
import { isEqual, isNil } from 'lodash-es';
import { Children, cloneElement, type ReactElement, type ReactNode, useCallback } from 'react';
import styled from 'styled-components';

import { ApplicationPricingFieldWarning } from './application-pricing-field-warning';

const Label = styled.span`
  font-weight: ${fontWeight('light')};
`;
interface IApplicationPricingFormFieldProps {
  children: ReactElement<IBoxProps<any>>;
  formatInitialValue?: (initialValue: any) => ReactNode;
}

export const ApplicationPricingFormField = (props: IApplicationPricingFormFieldProps) => {
  const { children, formatInitialValue } = props;

  const customizations = useCustomizations();
  const emptyInitialValueLabel = customizations.text(
    'pageApplication:tabPricing.form.warning.initialValue.empty',
    'None'
  );

  const child = Children.only(children);
  const { props: childProps } = child;
  const { name } = childProps;

  const initialValue = useFieldInitialValue(name);
  const [currentValue, setCurrentValue] = useFormValue(name);

  const hasChanged =
    (isNil(currentValue) && !isNil(initialValue)) ||
    (isNil(initialValue) && !isNil(currentValue)) ||
    (!isNil(initialValue) && !isNil(currentValue) && !isEqual(currentValue, initialValue));

  const handleReset = useCallback(() => {
    setCurrentValue(initialValue);
  }, [initialValue, setCurrentValue]);

  if (!hasChanged) {
    return child;
  }

  const formattedInitialValue = !isPresent(initialValue)
    ? emptyInitialValueLabel
    : (formatInitialValue && formatInitialValue(initialValue)) || initialValue;

  const newChildProps = {
    ...(childProps || {}),
    warning: (
      <ApplicationPricingFieldWarning onReset={handleReset}>
        <Label>
          <T>Initial value:</T>
        </Label>{' '}
        {formattedInitialValue}
      </ApplicationPricingFieldWarning>
    ),
  };

  return cloneElement(child, newChildProps);
};
