import { useAuthService } from '@mortgagehippo/auth';
import { Button, Content as CustomizableContent, T, Text } from '@mortgagehippo/ds';

import { Content, Layout } from '../../layouts/login';

export const RootPage = () => {
  const auth = useAuthService();

  const handleLogin = () => {
    auth.login('/dashboard');
  };

  return (
    <Layout title="Lender Hub" titleCid="pageStart:title">
      <Content>
        <CustomizableContent cid="pageStart:description">
          <Text align="center" as="p">
            This website is intended for internal company use only. If you need assistance, please
            contact your corporate IT department.
          </Text>
        </CustomizableContent>

        <Button
          onClick={handleLogin}
          importance="primary"
          icon="right-arrow"
          iconLocation="right"
          block
        >
          <T cid="pageStart:startButton.label">Sign in</T>
        </Button>
      </Content>
    </Layout>
  );
};
