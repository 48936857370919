import { Input, ModalForm, notifications, T, useCustomizations, useModal } from '@mortgagehippo/ds';
import { useTask } from '@mortgagehippo/tasks';
import { useCallback, useMemo } from 'react';

import { DueDate } from '$components/due-date-field';

import { useUpdateFollowUpTask } from '../../../pages/application/use-update-follow-up-task';
import { type IActionProps, type IEditFollowUpTaskAction } from '../types';

export const EditFollowUpTaskAction = (props: IActionProps<IEditFollowUpTaskAction>) => {
  const { action, onDone, onCancel } = props;
  const { taskId } = action;
  const [isOpen, , closeModal] = useModal(true);

  const [task, , taskLoading] = useTask(taskId);

  const customizations = useCustomizations();

  const dueDateDisabled = customizations.bool('pageApplication:tabTasks.dueDates.disabled', true);
  const customizationsReady = customizations.ready();

  const updateFollowUpTask = useUpdateFollowUpTask();

  const handleSubmit = async (values: any) => {
    try {
      const { title, due_date } = values;

      const data = {
        title,
        dueDate: due_date,
      };

      await updateFollowUpTask(taskId, data);

      closeModal(() => {
        notifications.success({
          message: 'Successfully updated task',
        });
        onDone();
      });
    } catch (e) {
      console.error(e);
      notifications.error({
        message: 'There was an unexpected error updating the task',
      });
    }
  };

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  const initialValues = useMemo(() => {
    if (task) {
      return {
        title: task.meta.title,
        due_date: task.dueDate,
      };
    }

    return {};
  }, [task]);

  const loading = !customizationsReady || taskLoading;

  return (
    <ModalForm
      loading={loading}
      width="medium"
      title="Edit Task"
      onSubmit={handleSubmit}
      onRequestClose={handleCloseModal}
      isOpen={isOpen}
      okButtonLabel={<T>Update task</T>}
      disableOverlayClickClose
      initialValues={initialValues}
    >
      {!dueDateDisabled && (
        <DueDate.Box
          name="due_date"
          label="Due Date"
          labelInvisible
          size="sm"
          validateFromNow="gte"
        />
      )}
      <Input.Box
        name="title"
        label="Task Title"
        labelTooltip="The task title should describe what the borrower should do in up to 5 words. Do not include the borrower’s name if there are more than one borrowers - the system will automatically display it."
        required
      />
    </ModalForm>
  );
};
