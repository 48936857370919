/* eslint-disable react/no-unused-prop-types */
import { Button } from '@mortgagehippo/ds';
import { type IQueryTableColumns, QueryTable } from '@mortgagehippo/query-components';
import { keyBy } from 'lodash-es';
import { forwardRef, useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { type FolderAgentsQuery, type FolderAgentsQueryVariables } from '../../../apollo/graphql';
import { type IFolderAgent, QFolderAgents } from '../../../hooks/use-folder-agents';
import { type ManagedFolder } from './manager';

interface IFolderAgentsTableProps {
  partnerId: string;
  folder: ManagedFolder;
  onDeleteUser: (agent: IFolderAgent) => void;
  disabled?: boolean;
  caption: string;
  ref?: any;
}

const CellContent = styled.span<{ strikeThrough?: boolean }>`
  text-decoration: ${(p) => (p.strikeThrough ? 'line-through' : 'none')};
`;

function FolderAgentsTableComponent(props: IFolderAgentsTableProps, ref: any) {
  const { partnerId, folder, onDeleteUser, disabled, caption } = props;

  const folderId = folder.getId();

  const deletedUsersById = useMemo(() => {
    const nextChangedUsers = folder.getUsers().filter((user) => user.hasUnsavedChanges());
    const nextDeletedUsers = nextChangedUsers.filter((user) => user.isSoftDeleted());

    const nextDeletedUsersById = keyBy(nextDeletedUsers, (user) => user.getId());

    return nextDeletedUsersById;
  }, [folder]);

  const handleDeleteUser = useCallback(
    (agent: IFolderAgent) => () => {
      onDeleteUser(agent);
    },
    [onDeleteUser]
  );

  const columns: IQueryTableColumns<IFolderAgent> = useMemo(
    () => [
      {
        title: 'Name',
        key: 'name',
        render: ({ id, name }) => {
          const strikeThrough = !!deletedUsersById[id];

          return <CellContent strikeThrough={strikeThrough}>{name}</CellContent>;
        },
      },
      {
        title: 'Email',
        key: 'email',
        render: ({ id, email }) => {
          const strikeThrough = !!deletedUsersById[id];

          return <CellContent strikeThrough={strikeThrough}>{email}</CellContent>;
        },
      },
      {
        title: '',
        key: 'actions',
        align: 'right',
        render: (record) => {
          const { id } = record;
          const isDeleted = !!deletedUsersById[id];

          if (isDeleted) {
            return null;
          }

          return (
            <Button
              icon="close"
              type="neutral"
              size="xs"
              onClick={handleDeleteUser(record)}
              importance="tertiary"
              compact
              disabled={disabled}
            >
              Remove
            </Button>
          );
        },
      },
    ],
    [deletedUsersById, disabled, handleDeleteUser]
  );

  const skip = folder.isNew();

  return (
    <QueryTable<FolderAgentsQuery, FolderAgentsQueryVariables>
      ref={ref}
      caption={caption}
      query={QFolderAgents}
      dataSource={(result) => result.partner?.folder?.agents.items || []}
      itemTotal={(result) => result.partner?.folder?.agents.total || 0}
      nextCursor={(result) => result.partner?.folder?.agents.nextCursor}
      previousCursor={(result) => result.partner?.folder?.agents.previousCursor}
      rowKey={(item) => item.id}
      columns={columns}
      bottomContent={
        <>
          <QueryTable.Total />
          <QueryTable.Pagination />
        </>
      }
      variables={{ partnerId, folderId }}
      skip={skip}
    >
      <QueryTable.Data />
    </QueryTable>
  );
}

export const FolderAgentsTable = forwardRef(
  FolderAgentsTableComponent
) as typeof FolderAgentsTableComponent;
