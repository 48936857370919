import { DEFAULT_CUSTOM_TASK_TITLE, DEFAULT_TEMPLATES } from './constants';
import { type IBulkCustomTask } from './types';

export const isDefaultTemplate = (templateKey: string) =>
  DEFAULT_TEMPLATES.some((t) => t.key === templateKey);

export const getTaskValidationErrors = (task: IBulkCustomTask) => {
  const { title, description, applicant_ids } = task;

  const errors: string[] = [];

  if (!title || title === DEFAULT_CUSTOM_TASK_TITLE) {
    errors.push('Missing "Task Title"');
  }

  if (!description) {
    errors.push('Missing "Instructions for the borrower"');
  }

  if (!applicant_ids) {
    errors.push('Missing "Assign to"');
  }

  return errors;
};

export const isValidTask = (task: IBulkCustomTask) => {
  const errors = getTaskValidationErrors(task);

  return errors.length === 0;
};
