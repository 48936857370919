import { Icon, spacing, Text } from '@mortgagehippo/ds';
import styled from 'styled-components';

import { type IWorkNumberReport } from './use-request-work-number-instant-report';

const ErrorContainer = styled.div`
  padding: ${spacing(6)};
  text-align: center;
`;

export interface IWorkNumberInstantReportErrorResult {
  report?: IWorkNumberReport;
  error?: any;
}

export const WorkNumberInstantReportErrorResult = (props: IWorkNumberInstantReportErrorResult) => {
  const { report, error } = props;

  const errorCode = error ? '1001' : report?.errorCode;
  const errorDescription = error || report?.errorDescription || 'Unknown Error';

  return (
    <>
      <ErrorContainer>
        <Icon name="warning" size="xxl" color="danger500" />
        <br />
        <Text as="p">There was an unexpected error running the report</Text>
      </ErrorContainer>
      {errorCode ? (
        <details>
          <summary>Error: {errorCode}</summary>
          <p>{errorDescription}</p>
        </details>
      ) : null}
    </>
  );
};
