import { Format, type ITableActions, spacing, T } from '@mortgagehippo/ds';
import { type IQueryTableColumns, QueryTable } from '@mortgagehippo/query-components';
import { useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';

import {
  type ApplicationFileAgentsQuery,
  type ApplicationFileAgentsQueryVariables,
} from '../../../apollo/graphql';
import { ActionType, useDispatchAction } from '../../../components/actions';
import { useApplicationFileCan } from '../../../components/permissions';
import { PrimaryLabel } from '../../../components/primary-label';
import {
  type IApplicationFileTeamAgent,
  QApplicationFileAgents,
} from '../use-application-file-agents';
import { ApplicationTeamFoldersSection } from './application-team-folders-section';
import { useApplicationFileFolders } from './use-application-file-folders';

const QueryTableContainer = styled.div`
  margin-bottom: ${spacing(7)};
`;

export interface IApplicationTeamProps {
  applicationFileId: string;
}

const columns: IQueryTableColumns<IApplicationFileTeamAgent> = [
  {
    title: 'Name',
    key: 'name',
    render: (t) => {
      if (t.jobFunctions.items.some((j) => j.primary)) {
        return <PrimaryLabel>{t.name || ''}</PrimaryLabel>;
      }

      return t.name;
    },
  },
  {
    title: 'Roles',
    key: 'roles',
    render: ({ jobFunctions }) =>
      jobFunctions.items.map((j) => j.name).join(', ') || <T>No role assigned</T>,
  },
  {
    title: 'Email',
    key: 'email',
    render: (t) => t.email,
  },
  {
    title: 'Phone',
    key: 'phone',
    render: ({ phone }) => {
      if (!phone) {
        return '';
      }

      return <Format.Phone value={phone} />;
    },
  },
];

export const ApplicationTeam = (props: IApplicationTeamProps) => {
  const { applicationFileId } = props;
  const tableRef = useRef<any>();
  const [can, , { data: applicationFile }] = useApplicationFileCan(applicationFileId);

  const [
    { data: applicationFileFoldersData, loading: applicationFileFoldersDataLoading },
    refetchApplicationFileFoldersData,
  ] = useApplicationFileFolders(applicationFileId);

  const dispatch = useDispatchAction();

  const handleManage = useCallback(() => {
    dispatch(
      {
        type: ActionType.MANAGE_APPLICATION_TEAM_ACTION,
        applicationFileId,
      },
      () => {
        if (tableRef) {
          tableRef.current.refetch();
        }
        refetchApplicationFileFoldersData();
      }
    );
  }, [applicationFileId, dispatch, refetchApplicationFileFoldersData]);

  const topActions: ITableActions = useMemo(
    () => [
      {
        key: 'manage-team',
        label: 'Manage team',
        buttonProps: {
          icon: 'team',
        },
        hidden: !can.MANAGE_TEAM,
        onAction: handleManage,
      },
    ],
    [can.MANAGE_TEAM, handleManage]
  );

  if (!applicationFile) {
    return null;
  }

  return (
    <>
      <QueryTableContainer>
        <QueryTable<ApplicationFileAgentsQuery, ApplicationFileAgentsQueryVariables>
          caption="Agents"
          query={QApplicationFileAgents}
          dataSource={(result) => result.applicationFile?.teamAgents.items || []}
          itemTotal={(result) => result.applicationFile?.teamAgents.total || 0}
          nextCursor={(result) => result.applicationFile?.teamAgents.nextCursor}
          previousCursor={(result) => result.applicationFile?.teamAgents.previousCursor}
          rowKey={(item) => item.id}
          columns={columns}
          variables={{ applicationFileId }}
          ref={tableRef}
          bottomContent={<QueryTable.Pagination />}
          topActions={topActions}
          hideTopBackground
        >
          <QueryTable.Data />
        </QueryTable>
      </QueryTableContainer>
      <ApplicationTeamFoldersSection
        applicationFileId={applicationFileId}
        canManage={can.APPLICATION_FILE_TEAM_TAB_SHOW_FOLDERS}
        data={applicationFileFoldersData}
        loading={applicationFileFoldersDataLoading}
      />
    </>
  );
};
