import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../../apollo';
import {
  type CreateDocusignEnvelopeMutation,
  type CreateDocusignEnvelopeMutationVariables,
  type DocusignExternalSigner,
} from '../../../../apollo/graphql';

const MCreateDocusignEnvelope = graphql(`
  mutation CreateDocusignEnvelope(
    $applicationFileId: ID!
    $applicantIds: [ID!]!
    $documentIds: [ID!]!
    $externalSigners: [DocusignExternalSigner!]!
  ) {
    docusign {
      createEnvelope(
        applicationFileId: $applicationFileId
        applicantIds: $applicantIds
        documentIds: $documentIds
        externalSigners: $externalSigners
      ) {
        envelopeId
      }
    }
  }
`);

export type DocusignCreateEnvelope = CreateDocusignEnvelopeMutation['docusign']['createEnvelope'];

export const useCreateDocusignEnvelope = () => {
  const fn = useMutation<CreateDocusignEnvelopeMutation, CreateDocusignEnvelopeMutationVariables>(
    MCreateDocusignEnvelope,
    undefined
  );

  return useCallback(
    async (
      applicationFileId: string,
      applicantIds: string[],
      documentIds: string[],
      externalSigners: DocusignExternalSigner[]
    ): Promise<DocusignCreateEnvelope> => {
      const result = await fn({ applicationFileId, applicantIds, documentIds, externalSigners });
      return result.data!.docusign.createEnvelope;
    },
    [fn]
  );
};
