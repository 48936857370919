import { type IModalFormProps, Input, ModalForm } from '@mortgagehippo/ds';
import { AgentSelect } from 'src/components/agent-select';

import { PrimaryLabel } from '$components/primary-label';

import { type ISiteJobFunction } from '../../../hooks/use-site-job-functions';

interface ITeamTemplateModalProps extends Omit<IModalFormProps, 'children'> {
  siteId: string;
  jobFunctions: ISiteJobFunction[];
  disabled?: boolean;
  showSlug?: boolean;
}

export const TeamTemplateModal = (props: ITeamTemplateModalProps) => {
  const { siteId, jobFunctions, disabled, showSlug, ...rest } = props;

  return (
    <ModalForm {...rest} okButtonDisabled={disabled}>
      <Input.Box name="name" label="Name" required disabled={disabled} />
      {showSlug ? <Input.Box name="slug" label="Slug" disabled={disabled} required /> : null}
      {jobFunctions.map((jobFunction) => {
        const { id, primary } = jobFunction;

        const label = primary ? <PrimaryLabel>{jobFunction.name}</PrimaryLabel> : jobFunction.name;

        return (
          <AgentSelect
            key={id}
            siteId={siteId}
            jobFunctionId={id}
            name={`jobFunctions.id:${jobFunction.id}`}
            label={label}
            multiple={!primary}
            disabled={disabled}
            required={primary}
          />
        );
      })}
    </ModalForm>
  );
};
