import { fontSize, fontWeight, Format, MediaBreakpoint, spacing } from '@mortgagehippo/ds';
import styled from 'styled-components';

import { BlockValue, BlockValueLabel } from '../common';
import { Dash } from '../dash';
import { type ISelectedPricingLoan } from './use-pricing-overview-section';

const Product = styled.div`
  flex: 1 1 auto;
`;

const ProductDetailsItem = styled.div`
  text-align: center;
  font-size: ${fontSize('sm')};
  line-height: ${fontSize('sm')};
  padding: ${spacing(2)} ${spacing(6)} 0 0;

  &:last-child {
    padding-right: 0;
  }

  ${MediaBreakpoint.TABLET} {
    text-align: left;
    padding-bottom: ${spacing(2)};
  }
`;

const ProductDetails = styled.div`
  display: flex;
  padding-top: ${spacing(2)};

  ${MediaBreakpoint.TABLET} {
    flex-wrap: wrap;
    padding-bottom: ${spacing(2)};
  }
`;

const ProductDetailsValue = styled.div`
  font-weight: ${fontWeight('bold')};
`;

const ProductDetailsValueLabel = styled(BlockValueLabel)`
  padding-bottom: 0;
`;

export interface IApplicationOverviewSectionProductProps {
  selectedPricingLoan?: ISelectedPricingLoan | null;
}

export const ApplicationOverviewPricingSectionProduct = (
  props: IApplicationOverviewSectionProductProps
) => {
  const { selectedPricingLoan } = props;

  const { productName, price, apr, rate, closingCost, totalPayment } = selectedPricingLoan || {};

  if (!productName) {
    return (
      <BlockValue>
        <Dash />
      </BlockValue>
    );
  }

  return (
    <Product>
      <BlockValue fontSize="md">{productName}</BlockValue>
      <ProductDetails>
        <ProductDetailsItem>
          <ProductDetailsValue>
            {price ? <Format.Number value={price} suffix="%" decimals={3} /> : <Dash />}
          </ProductDetailsValue>
          <ProductDetailsValueLabel>Points</ProductDetailsValueLabel>
        </ProductDetailsItem>
        <ProductDetailsItem>
          <ProductDetailsValue>
            {rate ? <Format.Number value={rate} suffix="%" decimals={3} /> : <Dash />}
          </ProductDetailsValue>
          <ProductDetailsValueLabel>Rate</ProductDetailsValueLabel>
        </ProductDetailsItem>
        <ProductDetailsItem>
          <ProductDetailsValue>{apr ? <Format.APR value={apr} /> : <Dash />}</ProductDetailsValue>
          <ProductDetailsValueLabel>APR</ProductDetailsValueLabel>
        </ProductDetailsItem>
        <ProductDetailsItem>
          <ProductDetailsValue>
            {totalPayment ? <Format.Currency value={totalPayment} /> : <Dash />}
          </ProductDetailsValue>
          <ProductDetailsValueLabel>Mo. Payment</ProductDetailsValueLabel>
        </ProductDetailsItem>
        <ProductDetailsItem>
          <ProductDetailsValue>
            {closingCost ? <Format.Currency value={closingCost} /> : <Dash />}
          </ProductDetailsValue>
          <ProductDetailsValueLabel>Closing Costs</ProductDetailsValueLabel>
        </ProductDetailsItem>
      </ProductDetails>
    </Product>
  );
};
