import {
  type ISearchFilter,
  type ITableAction,
  type ITableActions,
  notifications,
  T,
} from '@mortgagehippo/ds';
import { type IQueryTableDataActions } from '@mortgagehippo/query-components';
import { useMountedRef } from '@mortgagehippo/util';
import {
  forwardRef,
  type MouseEvent,
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import { EXITED_APPLICATION, useActionEffect as useActionableEffect } from '$components/actionable';
import { ActionStatus, ActionType, useActionEffect, useDispatchAction } from '$components/actions';
import { useUserCan } from '$components/permissions';
import { type ISmartviewRecord, SmartView } from '$components/smart-view';
import { getOrderBy } from '$components/smart-view/util';

import { type ExportSettings, SmartviewModelType } from '../../../apollo/graphql';
import { useLocation } from '../../../hooks/use-location';
import { usePartnerDomain } from '../../../hooks/use-partner-domain';
import { useRequestApplicationFilesExport } from '../../../hooks/use-request-application-files-export';
import { LayoutContext } from '../../../layouts/layout-context';
import { history } from '../../../services/history';

enum GroupActionType {
  MANAGE_TEAM = 'manage-team',
  ARCHIVE = 'archive',
  START_APPLICATION = 'start_application',
}

enum BulkActionType {
  ARCHIVE = 'archive',
}

interface IApplicationFilesSmartviewProps {
  id?: string;
  caption: string;
  ariaLabel: string;
  localStorageKey?: string;
  topActions?: IQueryTableDataActions;
  presetsLabel?: string;
  onCreateFrom?: (criteria?: ISearchFilter[], sortField?: string, sortDirection?: string) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  ref?: any;
}

function FApplicationFilesSmartview(props: IApplicationFilesSmartviewProps, ref: any) {
  const {
    id: smartviewId,
    caption,
    ariaLabel,
    localStorageKey,
    topActions,
    presetsLabel,
    onCreateFrom,
  } = props;

  const smartviewRef = useRef<any>();
  const mounted = useMountedRef();

  const partnerId = usePartnerDomain();
  const [can] = useUserCan();

  const { layoutContext } = useContext(LayoutContext);

  const requestApplicationFilesExport = useRequestApplicationFilesExport();

  const dispatch = useDispatchAction();

  const { pathname } = useLocation();

  useActionEffect(
    (action) => {
      if (
        [
          ActionType.CREATE_APPLICATION,
          ActionType.CREATE_APPLICATION_FROM_APPLICANT,
          ActionType.DELETE_APPLICATION,
          ActionType.MANAGE_APPLICATION_TEAM_ACTION,
          ActionType.ARCHIVE_APPLICATION,
          ActionType.DUPLICATE_APPLICATION,
          ActionType.IMPORT_MISMO,
        ].includes(action.type)
      ) {
        setTimeout(() => {
          if (mounted.current && smartviewRef.current) {
            smartviewRef.current.refetch();
          }
        }, 1500);
      }
    },
    undefined,
    ActionStatus.DONE
  );

  useActionableEffect(EXITED_APPLICATION, (action) => {
    const { id: ensureId } = action.payload;

    if (mounted.current && smartviewRef.current) {
      smartviewRef.current.refetch(ensureId);
    }
  });

  useImperativeHandle(ref, () => smartviewRef.current, []);

  const handleRowClick = useCallback(
    (record: { id: string | number }, _index: number, clickDetails?: MouseEvent) => {
      if (clickDetails?.altKey || clickDetails?.metaKey) {
        window.open(`#/applications/${record.id}`);
      } else {
        history.push(`#/applications/${record.id}`);
      }
    },
    []
  );

  const handleMiddleRowClick = useCallback((key: string) => {
    window.open(`#/applications/${key}`);
  }, []);

  const handleExport = useCallback(
    async (criteria?: ISearchFilter[], sortField?: string, sortDirection?: string) => {
      let asyncId;
      try {
        const orderBy = getOrderBy(sortField, sortDirection);

        const settings: ExportSettings = {
          criteria: criteria as any,
          options: {
            orderBy,
          },
        };

        asyncId = await requestApplicationFilesExport(partnerId, smartviewId, settings);
      } catch (e) {
        asyncId = null;
      } finally {
        if (!asyncId) {
          notifications.error({
            message: 'An error occurred while processing your request. Please try again later.',
          });
        }
      }

      return asyncId;
    },
    [requestApplicationFilesExport, partnerId, smartviewId]
  );

  const handleGroupAction = useCallback(
    async (actionKey: string, record: ISmartviewRecord) => {
      const { id: applicationFileId } = record;

      switch (actionKey as GroupActionType) {
        case GroupActionType.MANAGE_TEAM:
          dispatch({
            type: ActionType.MANAGE_APPLICATION_TEAM_ACTION,
            applicationFileId,
          });
          break;
        case GroupActionType.START_APPLICATION:
          dispatch(
            {
              type: ActionType.DUPLICATE_APPLICATION,
              applicationFileId,
            },
            (_action, status, result) => {
              const { applicationFileId: nextApplicationFileId } = result || {};

              if (status !== ActionStatus.DONE || !nextApplicationFileId) {
                return;
              }

              history.push(`#/applications/${nextApplicationFileId}`);
            }
          );
          break;
        case GroupActionType.ARCHIVE:
          dispatch({
            type: ActionType.ARCHIVE_APPLICATION,
            applicationFileId,
          });
          break;
        default: {
          throw new Error(`Unknown action ${actionKey}`);
        }
      }
    },
    [dispatch]
  );

  const handleOpenInNewTabAction = useCallback(
    async (_actionKey: string, record: ISmartviewRecord) => {
      const newWin = window.open(`${pathname}#/applications/${record.id}`);
      if (newWin) {
        newWin.focus();
      }
    },
    [pathname]
  );

  const handleBulkAction = useCallback(
    (actionKey: string, records?: ISmartviewRecord[]) => {
      const applicationFileId = (records || []).map((record) => record.id);

      if (!applicationFileId.length) {
        return;
      }

      switch (actionKey as BulkActionType) {
        case BulkActionType.ARCHIVE:
          dispatch({
            type: ActionType.ARCHIVE_APPLICATION,
            applicationFileId,
          });
          break;
        default: {
          throw new Error(`Unknown action ${actionKey}`);
        }
      }
    },
    [dispatch]
  );

  const [rowActions, selectionActions, rowActionsColWidth] = useMemo(() => {
    const nextGroupActions: ITableAction[] = [];

    if (can.MANAGE_TEAM) {
      nextGroupActions.push({
        key: GroupActionType.MANAGE_TEAM,
        label: <T cid="pageDashboard:pipeline.actions.manageTeam.label">Manage team</T>,
        iconProps: {
          name: 'team',
        },
      });
    }

    if (can.CREATE_APPLICATION_FILE) {
      nextGroupActions.push({
        key: GroupActionType.START_APPLICATION,
        label: (
          <T cid="pageDashboard:pipeline.actions.startApplication.label">Start new application</T>
        ),
        iconProps: {
          name: 'plus',
        },
      });
    }

    if (can.ARCHIVE_APPLICATION_FILE) {
      nextGroupActions.push({
        key: GroupActionType.ARCHIVE,
        label: <T cid="pageDashboard:pipeline.actions.archive.label">Archive</T>,
        iconProps: {
          name: 'archive',
        },
      });
    }

    const nextRowActions: ITableActions = [];
    // Open in new tab action
    nextRowActions.push({
      key: 'open-new-tab',
      label: 'Open in new tab',
      buttonProps: {
        icon: 'open-in-new',
        tooltip: 'Open in new tab',
        tooltipProps: {
          showDelayMs: 1000,
        },
      },
      onAction: handleOpenInNewTabAction,
    });

    if (nextGroupActions.length) {
      nextRowActions.push({
        key: 'options',
        label: 'Options',
        buttonProps: {
          icon: 'menu-dots',
        },
        actions: nextGroupActions,
        onGroupAction: handleGroupAction,
      });
    }

    const nextSelectionActions: IQueryTableDataActions = [];

    if (can.ARCHIVE_APPLICATION_FILE) {
      nextSelectionActions.push({
        key: BulkActionType.ARCHIVE,
        label: <T cid="pageDashboard:pipeline.actions.archive.label">Archive</T>,
        buttonProps: {
          icon: 'archive',
        },
        onBulkAction: handleBulkAction,
      });
    }

    // the cell padding + 24 button width+ 2px button left margin
    const nextRowActionsColWidth = `${16 + nextRowActions.length * 26}px`;

    return [nextRowActions, nextSelectionActions, nextRowActionsColWidth];
  }, [
    can.ARCHIVE_APPLICATION_FILE,
    can.CREATE_APPLICATION_FILE,
    can.MANAGE_TEAM,
    handleBulkAction,
    handleGroupAction,
    handleOpenInNewTabAction,
  ]);

  return (
    <SmartView
      ref={smartviewRef}
      model={SmartviewModelType.ApplicationFile}
      id={smartviewId}
      ariaLabel={ariaLabel}
      caption={caption}
      rowActions={rowActions}
      rowActionsColWidth={rowActionsColWidth}
      rowActionsFixed
      selectionActions={selectionActions}
      selectionFixed
      onRowClick={handleRowClick}
      onRowMiddleClick={handleMiddleRowClick}
      localStorageKey={localStorageKey}
      paginate
      summarize
      onExport={can.EXPORT_CSV ? handleExport : undefined}
      tableFullHeight
      tableFullHeightOffset={layoutContext.headerHeight}
      resizableColumns
      topActions={topActions}
      presetsLabel={presetsLabel}
      onCreateFrom={onCreateFrom}
    />
  );
}

export const ApplicationFilesSmartview = forwardRef(
  FApplicationFilesSmartview
) as typeof FApplicationFilesSmartview;
