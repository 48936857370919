import { ManagedFolderUser } from './managed-folder-user';

export class ManagedFolder {
  private id: string;
  private name: string;
  private description?: string | null;
  private users: ManagedFolderUser[] = [];

  private parent?: ManagedFolder;
  private children: ManagedFolder[] = [];

  private _isNew = false;
  private _isEdited = false;
  private _isDeleted = false;

  private readonly initialName: string;
  private readonly initialDescription?: string | null;
  private readonly initialParentId?: string;

  public constructor(
    id: string,
    name: string,
    description?: string | null,
    parent?: ManagedFolder
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.parent = parent;

    this.initialName = name;
    this.initialDescription = description;
    this.initialParentId = parent?.getId();

    if (parent) {
      parent.addChild(this);
    }
  }

  public edit(nextName: string, nextDescription?: string | null) {
    // if its the same name don't do anything
    if (nextName === this.name && nextDescription === this.description) {
      return false;
    }

    if (!this.canEdit()) {
      return false;
    }

    this.name = nextName;
    this.description = nextDescription;

    if (this.isBackToOriginalState()) {
      this._isEdited = false;
    } else {
      this._isEdited = true;
    }

    return true;
  }

  public moveTo(nextParent?: ManagedFolder) {
    const prevParent = this.parent;

    if (!this.canEdit()) {
      return false;
    }

    // if its the same parent don't do anything
    if (
      (!prevParent && !nextParent) ||
      (!!prevParent && !!nextParent && prevParent.getId() === nextParent.getId())
    ) {
      return false;
    }

    // can't move to a deleted folder
    if (nextParent && nextParent.isSoftDeleted()) {
      return false;
    }

    if (prevParent) {
      prevParent.removeChild(this.id);
    }

    this.parent = nextParent;

    if (nextParent) {
      nextParent.children.push(this);
    }

    if (this.isBackToOriginalState()) {
      this._isEdited = false;
    } else {
      this._isEdited = true;
    }

    return true;
  }

  public addChild(child: ManagedFolder) {
    const exists = this.children.some((c) => c.getId() === child.getId());

    if (exists) {
      return;
    }

    this.children.push(child);
  }

  public removeChild(folderId: string) {
    this.children = this.children.filter((f) => f.id !== folderId);
  }

  public softDelete() {
    if (this._isDeleted) {
      return false;
    }

    this._isDeleted = true;

    return true;
  }

  public restore() {
    if (!this._isDeleted) {
      return false;
    }

    this._isDeleted = false;

    return true;
  }

  public registerExistingUser(id: string, name: string, email: string) {
    const exists = this.users.some((user) => user.getId() === id);

    if (exists) {
      return false;
    }

    const newUser = new ManagedFolderUser(id, name, email);

    this.users.push(newUser);

    return true;
  }

  public addUser(id: string, name: string, email: string) {
    const exists = this.users.some((user) => user.getId() === id);

    if (exists) {
      return false;
    }

    const newUser = ManagedFolderUser.new(id, name, email);

    this.users.push(newUser);

    return true;
  }

  public deleteUser(id: string) {
    const user = this.users.find((u) => u.getId() === id);

    if (!user) {
      return false;
    }

    if (user.isNew()) {
      this.users = this.users.filter((u) => u.getId() !== id);
    } else {
      user.softDelete();
    }

    return true;
  }

  public restoreUser(id: string) {
    const user = this.users.find((u) => u.getId() === id);

    if (!user) {
      return false;
    }

    if (!user.isSoftDeleted()) {
      return false;
    }

    user.restore();

    return true;
  }

  public getUser(id: string) {
    return this.users.find((user) => user.getId() === id);
  }

  public getUsers() {
    return this.users;
  }

  public getId() {
    return this.id;
  }

  public getName() {
    return this.name;
  }

  public getDescription() {
    return this.description;
  }

  public getParent() {
    return this.parent;
  }

  public getParentId() {
    return this.parent?.getId();
  }

  public getPath() {
    const getParentIds = (folder: ManagedFolder, accum: string[] = []): string[] => {
      const parent = folder.getParent();
      if (!parent) {
        return accum;
      }

      return getParentIds(parent, [...accum, parent.getId()]);
    };

    return getParentIds(this);
  }

  public getChildren() {
    return this.children;
  }

  public getActiveChildren() {
    return this.children.filter((folder) => !folder.isSoftDeleted());
  }

  public isNew() {
    return this._isNew;
  }

  public isEdited() {
    return this._isEdited || this.users.some((u) => u.hasUnsavedChanges());
  }

  public isSoftDeleted(): boolean {
    if (this._isDeleted) {
      return this._isDeleted;
    }

    if (this.parent) {
      return this.parent.isSoftDeleted();
    }

    return false;
  }

  public canEdit() {
    return !this.isSoftDeleted();
  }

  public canSoftDelete() {
    return !this.isNew() && !this.isSoftDeleted();
  }

  public canPermanentlyDelete(): boolean {
    return this.isNew() && this.children.length === 0;
  }

  public canDelete() {
    return this.canSoftDelete() || this.canPermanentlyDelete();
  }

  public hasUnsavedChanges() {
    return this.isNew() || this.isEdited() || this.isSoftDeleted();
  }

  private isBackToOriginalState() {
    return (
      this.name === this.initialName &&
      this.description === this.initialDescription &&
      this.initialParentId === this.parent?.id
    );
  }

  public static new(id: string, name: string, description?: string | null, parent?: ManagedFolder) {
    // can't create folder in deleted parent
    if (parent && parent.isSoftDeleted()) {
      return undefined;
    }

    const folder = new ManagedFolder(id, name, description, parent);

    folder._isNew = true;

    return folder;
  }
}
