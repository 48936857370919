import { Button, type ITableCols, Table, Tag } from '@mortgagehippo/ds';
import { orderBy } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { type ManagedFolderUser } from './manager';

const CellContent = styled.span<{ strikeThrough?: boolean }>`
  text-decoration: ${(p) => (p.strikeThrough ? 'line-through' : 'none')};
`;

interface IFolderAgentsPendingTableProps {
  users: ManagedFolderUser[];
  onCancelChange: (user: ManagedFolderUser) => void;
  disabled?: boolean;
  caption: string;
}

const rowKey = (record: ManagedFolderUser) => record.getId();

export const FolderAgentsPendingTable = (props: IFolderAgentsPendingTableProps) => {
  const { users, onCancelChange, disabled, caption } = props;

  const data = useMemo(() => orderBy(users, (u) => u.getName(), ['asc']), [users]);

  const handleCancel = useCallback(
    (record: ManagedFolderUser) => () => {
      onCancelChange(record);
    },
    [onCancelChange]
  );

  const columns: ITableCols<ManagedFolderUser> = useMemo(
    () => [
      {
        title: 'Action',
        key: 'action',
        render: (record) => {
          if (record.isNew()) {
            return (
              <Tag size="xs" color="success">
                Added
              </Tag>
            );
          }

          return (
            <Tag size="xs" color="danger">
              Removed
            </Tag>
          );
        },
      },
      {
        title: 'Name',
        key: 'name',
        render: (record) => (
          <CellContent strikeThrough={record.isSoftDeleted()}>{record.getName()}</CellContent>
        ),
      },
      {
        title: 'Email',
        key: 'email',
        render: (record) => (
          <CellContent strikeThrough={record.isSoftDeleted()}>{record.getEmail()}</CellContent>
        ),
      },
      {
        title: '',
        key: 'actions',
        align: 'right',
        render: (record) => (
          <Button
            icon="close"
            type="neutral"
            size="xs"
            onClick={handleCancel(record)}
            importance="tertiary"
            compact
            disabled={disabled}
          >
            Cancel
          </Button>
        ),
      },
    ],
    [disabled, handleCancel]
  );

  return (
    <Table<ManagedFolderUser>
      caption={caption}
      cols={columns}
      data={data}
      rowKey={rowKey}
      size="sm"
    />
  );
};
