import { useContext, useEffect, useRef } from 'react';

import { ApplicationActionsContext } from './application-actions-context';
import { type Action, type ActionListener, type ActionStatus } from './types';

export const useActionEffect = <A extends Action = any, R = any, T = A['type']>(
  fn: ActionListener<A, R>,
  type?: T,
  status?: ActionStatus
) => {
  /* eslint-disable react-hooks/exhaustive-deps */
  const context = useContext(ApplicationActionsContext);
  const fnRef = useRef(fn);

  useEffect(() => {
    fnRef.current = fn;
  }, [fn]);

  useEffect(
    () =>
      context!.subscribe(
        (action: A, actionStatus, result) => {
          fnRef.current(action, actionStatus, result);
        },
        type as any,
        status
      ),
    []
  );
};
