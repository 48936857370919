import { type IModalFormProps, ModalForm } from '@mortgagehippo/ds';

import { type IApplicationApplicant } from '../../../../../pages/application/use-application-file-data';
import { DefaultFields } from '../default-fields';

interface IInformationTaskEditorProps extends IModalFormProps {
  applicants: IApplicationApplicant[];
  showDueDate?: boolean;
}

export const InformationTaskEditor = (props: IInformationTaskEditorProps) => {
  const { applicants, showDueDate, children, ...rest } = props;

  return (
    <ModalForm {...rest}>
      {children}
      <DefaultFields applicants={applicants} allowCommon showDueDate={showDueDate} />
    </ModalForm>
  );
};
