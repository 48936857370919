import { ForbiddenError } from '@mortgagehippo/auth';
import { spacing, TabRoute, TabRouter } from '@mortgagehippo/ds';
import { useEffect } from 'react';
import { type RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import { useUserCan } from '$components/permissions';

import { Content, Layout } from '../../layouts/main';
import { history } from '../../services/history';
import { FoldersTab } from './folders-tab';
import { JobFunctionsTab } from './job-functions-tab';
import { PermissionsTab } from './permissions-tab';
import { TemplatesTab } from './templates-tab';
import { UsersTab } from './users-tab';

interface IUsersPageParams {
  id?: string;
}

const StyledTabRouter = styled(TabRouter)`
  margin-bottom: ${spacing(5)};
`;

export const UsersPage = (props: RouteComponentProps<IUsersPageParams>) => {
  const { match } = props;
  const { params, url } = match;
  const { id } = params;

  const [can, canReady] = useUserCan();

  if (canReady && !can.VIEW_USERS_MANAGEMENT_PAGE) {
    throw new ForbiddenError();
  }

  const routes = [
    {
      label: 'Users',
      path: '/users',
      component: UsersTab,
      exact: true,
      hidden: !can.VIEW_USERS_TAB,
    },
    {
      label: 'Roles',
      path: '/users/roles',
      component: JobFunctionsTab,
      hidden: !can.VIEW_JOB_FUNCTIONS_TAB,
    },
    {
      label: 'Team Assignment Templates',
      path: '/users/templates',
      component: TemplatesTab,
      hidden: !can.VIEW_TEAM_TEMPLATES_TAB,
    },
    {
      label: 'Access Levels',
      path: '/users/permissions',
      component: PermissionsTab,
      hidden: !can.VIEW_AUTH_ROLES_TAB,
    },
    {
      label: 'Folders',
      path: '/users/folders',
      component: FoldersTab,
      hidden: !can.VIEW_FOLDERS_TAB,
    },
  ];

  const accessibleRoutes = routes.filter((r) => !r.hidden);

  useEffect(() => {
    if (
      canReady &&
      !id &&
      accessibleRoutes.length > 0 &&
      accessibleRoutes[0]!.path !== url.toLowerCase()
    ) {
      history.push(accessibleRoutes[0]!.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canReady, id]);

  return (
    <Layout pageTitle="User Management">
      <Content>
        <StyledTabRouter>
          {accessibleRoutes.map((r) => (
            <TabRoute
              key={r.path}
              label={r.label}
              path={r.path}
              component={r.component}
              exact={r.exact || false}
            />
          ))}
        </StyledTabRouter>
      </Content>
    </Layout>
  );
};
