import { Button, Icon, spacing, T, Text } from '@mortgagehippo/ds';
import {
  containsTheActiveTask,
  type IBaseTask,
  isFallbackTaskGroup,
  type ITaskGroup,
  TaskState,
} from '@mortgagehippo/tasks';
import { find, uniq } from 'lodash-es';
import { useCallback } from 'react';
import styled from 'styled-components';

import { MAIN_CONTENT_ID } from '../../../layouts/application';
import { type IApplicationApplicant } from '../use-application-file-data';
import { TasksListMenu } from './tasks-list-menu';
import { TasksListMenuGroup } from './tasks-list-menu-group';
import { TasksListMenuGroupItem } from './tasks-list-menu-group-item';

const ButtonContainer = styled.div<{ showTitle?: boolean }>`
  margin-bottom: ${(p) => (p.showTitle ? '0' : spacing(2))};
  padding: ${spacing(2)} 0 0;
`;

const NoTasks = styled.div`
  padding: ${spacing(6)} ${spacing(1)} ${spacing(6)} ${spacing(5)};
`;

/*
 * this is sketchy but allows us to not mess with the layout
 * components just to get this spacing right and allows other
 * things in the nav to maintain consistent spacing with other
 * pages
 */
const StyledTasksListMenu = styled(TasksListMenu)`
  margin: 0 -${spacing(3)};
`;

const handleClick = () => {
  // a11y - focus the main content container
  const el = document.getElementById(`${MAIN_CONTENT_ID}`);
  if (el && typeof el.focus === 'function') {
    el.focus();
  }
};

interface IApplicationTasksSidebarProps<T extends IBaseTask> {
  applicationFileId: string;
  activeTaskId?: string;
  applicants: IApplicationApplicant[];
  taskGroups: ITaskGroup<T>[];
  onCreate?: () => void;
}

export const ApplicationTasksSidebar = <T extends IBaseTask>(
  props: IApplicationTasksSidebarProps<T>
) => {
  const { applicationFileId, activeTaskId, applicants, taskGroups, onCreate } = props;

  const showNames = applicants.length > 1;
  const showDividers = showNames;
  const showInitials =
    showNames && uniq(applicants.map((v) => v.firstName)).length < applicants.length;
  const totalGroups = taskGroups.length;
  const collapsible = totalGroups > 1;

  // if a task is selected open its group
  const activeTaskIdGroup =
    activeTaskId && taskGroups.find((g) => containsTheActiveTask(g, activeTaskId));

  const firstGroupWithOpenTask = find(
    taskGroups,
    (group) => (group.tasks && group.tasks.some((t) => t.state === TaskState.open)) || false // || false to make VS code happy
  );

  /*
   * which group to open:
   * 1. task selected - open it's group
   * 2. otherwise open the group with teh 1st open task
   * 3. otherwise open the last group
   */
  const defaultActiveKeys =
    (activeTaskIdGroup && [activeTaskIdGroup.key]) ||
    (firstGroupWithOpenTask && [firstGroupWithOpenTask.key]) ||
    (totalGroups > 0 && [taskGroups[totalGroups - 1]!.key]) ||
    undefined;

  const renderItem = useCallback(
    (itemProps: any) => {
      const { task } = itemProps;

      return (
        <TasksListMenuGroupItem
          {...itemProps}
          to={`#/applications/${applicationFileId}/tasks/${task.primaryApplicantId}/${task.id}`}
          onClick={handleClick}
        />
      );
    },
    [applicationFileId]
  );

  const showTitle = taskGroups.some((g) => !isFallbackTaskGroup(g));

  return (
    <>
      {onCreate ? (
        <ButtonContainer showTitle={showTitle}>
          <Button
            onClick={onCreate}
            importance="secondary-outline"
            size="sm"
            icon="plus"
            compact
            block
          >
            <T>Add follow-up tasks</T>
          </Button>
        </ButtonContainer>
      ) : null}
      {!taskGroups.length && (
        <NoTasks>
          <Text variant="secondary" size="sm">
            <T cid="application_tasks:taskList.empty.message">There are no outstanding tasks</T>{' '}
            <Icon name="satisfied" size="sm" outline />
          </Text>
        </NoTasks>
      )}
      {taskGroups.length > 0 && (
        <StyledTasksListMenu collapsible={collapsible} defaultActiveKeys={defaultActiveKeys}>
          {taskGroups.map((taskGroup) => (
            <TasksListMenuGroup
              key={taskGroup.key}
              taskGroup={taskGroup}
              activeTaskId={activeTaskId}
              applicants={applicants}
              showNames={showNames}
              showInitials={showInitials}
              showDividers={showDividers}
              renderItem={renderItem}
              alwaysShowUpcomingTasks
              showTitle={showTitle}
            />
          ))}
        </StyledTasksListMenu>
      )}
    </>
  );
};
