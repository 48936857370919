import {
  ButtonLink,
  fontSize,
  Format,
  Icon,
  lineHeight,
  palette,
  Responsive,
} from '@mortgagehippo/ds';
import { toArray } from '@mortgagehippo/util';
import { type ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { ApplicationStatusRenderer, TeamAgentsRenderer } from './custom-renderers';
import { type ISmartviewFieldOptions, type ISmartviewRecord, SmartviewFieldType } from './types';
import { truncateText } from './util';

const Block = styled.div<{ noWrap?: boolean }>`
  ${(p) =>
    p.noWrap &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: normal;
    `}
`;

const Time = styled.span`
  display: block;
  color: ${palette('neutral600')};
  font-size: ${fontSize('xs')};
  line-height: ${lineHeight('xs')};
`;

type SmartviewRenderer = (
  value: any,
  record: ISmartviewRecord,
  options?: Partial<ISmartviewFieldOptions>
) => ReactNode;

// TODO: figure out list keys for arrays of values
export const SMARTVIEW_RENDERERS: Record<SmartviewFieldType, SmartviewRenderer> = {
  [SmartviewFieldType.DATE]: (value, _record, options) => {
    const { column } = options || {};
    const { format } = column || {};

    const values = toArray(value);

    if (!values.length) {
      return null;
    }

    return values.map((v) => {
      if (format) {
        return (
          <Block key={v}>
            <Format.Date format={format} value={v} />
          </Block>
        );
      }

      return (
        <Block key={v}>
          <Format.Date format="date-short" value={v} />
          <Time>
            <Format.Date format="time" value={v} />
          </Time>
        </Block>
      );
    });
  },
  [SmartviewFieldType.TEXT]: (value, _record, options) => {
    const { column } = options || {};
    const { noWrap, truncate } = column || {};

    const values = toArray(value);

    if (!values.length) {
      return null;
    }

    // put the values in separate lines
    return values.map((v) => {
      let output = v;

      if (truncate) {
        const truncatedOutput = truncateText(output, truncate);
        const isTruncated = output.length !== truncatedOutput.length;

        if (isTruncated) {
          // show the full text on mobile (no hover)
          output = (
            <Responsive>
              <Responsive.Tablet>{output}</Responsive.Tablet>
              <Responsive.Desktop>
                <span title={output}>{truncatedOutput}&hellip;</span>
              </Responsive.Desktop>
            </Responsive>
          );
        }
      }

      return (
        <Block key={output} noWrap={noWrap}>
          {output}
        </Block>
      );
    });
  },
  [SmartviewFieldType.PHONE]: (value) => {
    const values = toArray(value);

    if (!values.length) {
      return null;
    }

    // put the values in separate lines
    return values.map((v) => (
      <Block key={v}>
        <Format.Phone value={v} />
      </Block>
    ));
  },
  [SmartviewFieldType.BOOLEAN]: (value) => {
    const values = toArray(value);

    if (!values.length) {
      return null;
    }

    // put the values in separate lines
    return values.map((v) => (
      <Block key={v ? 1 : 0}>{v ? <Icon name="check" /> : <>&nbsp;</>}</Block>
    ));
  },
  [SmartviewFieldType.PERCENT]: (value) => {
    const values = toArray(value);

    if (!values.length) {
      return null;
    }

    // put the values in separate lines
    return values.map((v) => (
      <Block key={v}>
        <Format.Number value={v} suffix="%" />
      </Block>
    ));
  },
  [SmartviewFieldType.NUMBER]: (value) => {
    const values = toArray(value);

    if (!values.length) {
      return null;
    }

    // put the values in separate lines
    return values.map((v) => (
      <Block key={v}>
        <Format.Number value={v} />
      </Block>
    ));
  },
  [SmartviewFieldType.URL]: (value: string | string[]) => {
    const values = toArray(value);

    if (!values.length) {
      return null;
    }

    // put the values in separate lines
    return values.map((v) => {
      const url = v.includes('http://') || v.includes('https://') ? v : `https://${v}`;

      return (
        <Block key={v}>
          <ButtonLink
            href={url}
            icon="open-in-new"
            iconSize="xs"
            iconLocation="right"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            {v}
          </ButtonLink>
        </Block>
      );
    });
  },
  [SmartviewFieldType.CURRENCY]: (value) => {
    const values = toArray(value);

    if (!values.length) {
      return null;
    }

    // put the values in separate lines
    return values.map((v) => (
      <Block key={v}>
        <Format.Currency value={v} />
      </Block>
    ));
  },
  [SmartviewFieldType.ID]: (value) => {
    const values = toArray(value);

    if (!values.length) {
      return null;
    }

    // put the values in separate lines
    return values.map((v) => <Block key={v}>{v}</Block>);
  },
  [SmartviewFieldType.TEAM_AGENTS]: (value, { id }) => (
    <TeamAgentsRenderer applicationFileId={id} primaryAgentName={value} />
  ),
  [SmartviewFieldType.APPLICATION_STATUS]: (value, { id }, options) => {
    const { column } = options || {};
    const { disabled } = column || {};

    return (
      <ApplicationStatusRenderer applicationFileId={id} statusId={value} disabled={disabled} />
    );
  },
  [SmartviewFieldType.SSN]: (value) => {
    const values = toArray(value);

    if (!values.length) {
      return null;
    }

    // put the values in separate lines
    return values.map((v) => (
      <Block key={v}>
        <Format.SSN value={v} />
      </Block>
    ));
  },
};
