import { useSagaClickHandler } from '@mortgagehippo/tasks';
import { useEffect } from 'react';

import { type IActionProps, type IRefreshMortgageFlexLoanStatusAction } from '../types';
import { useTriggerRefreshMortgageFlexLoanStatus } from './use-trigger-refresh-mortgageflex-status';

export const RefreshMortgageFlexLoanStatusAction = (
  props: IActionProps<IRefreshMortgageFlexLoanStatusAction>
) => {
  const { action, onSubmit, onDone } = props;
  const { applicationFileId, silent } = action;

  const triggerRefreshMortgageFlexLoanStatus = useTriggerRefreshMortgageFlexLoanStatus();

  const [handleClick, running] = useSagaClickHandler({
    infoMessage: 'Refreshing milestones from LOS',
    infoMessageCid: 'refresh-mortgageflex-status:info',
    successMessage: 'Successfully refreshed milestones from LOS',
    successMessageCid: 'refresh-mortgageflex-status:success',
    errorMessage: 'There was an unexpected error refreshing the milestones from LOS',
    errorMessageCid: 'refresh-mortgageflex-status:error',
    silent,
    async onClick(setAsyncId) {
      const nextAsyncId = await triggerRefreshMortgageFlexLoanStatus(applicationFileId);

      setAsyncId(nextAsyncId);

      onSubmit();
    },
    async onDone() {
      onDone();
    },
  });

  useEffect(() => {
    if (running) {
      return;
    }

    (async () => {
      await handleClick();
    })();
  }, [handleClick, running]);

  return null;
};
