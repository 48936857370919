import { uniqueId } from 'lodash-es';
import { createElement, type ReactNode, useCallback, useMemo, useRef, useState } from 'react';

import { AddApplicantAction } from './add-applicant-action';
import { CreateAgentAction, UpdateAgentAction } from './agent-action';
import {
  ApplicationActionsContext,
  type IApplicationActionsContext,
} from './application-actions-context';
import { ArchiveApplicationAction } from './archive-application-action';
import { BulkCreateCustomTaskAction } from './bulk-create-custom-task-action';
import { CreateApplicationAction } from './create-application-action';
import { CreateApplicationFromApplicantAction } from './create-application-from-applicant-action';
import { CreditPullAction } from './credit-pull-action';
import { DeleteAgentAction } from './delete-agent-action';
import { DeleteApplicantAction } from './delete-applicant-action';
import { DeleteApplicationAction } from './delete-application-action';
import { DeleteTaskAction } from './delete-custom-task-action';
import { DuplicateApplicationAction } from './duplicate-application-action';
import { EditCustomTaskAction } from './edit-custom-task-action';
import { EditFollowUpTaskAction } from './edit-follow-up-task-action';
import { GenerateDUAssessmentAction } from './generate-du-assessment-action';
import { GenerateLPAAssessmentAction } from './generate-lpa-assessment-action';
import { GetScreenshareWatchUrlAction } from './get-screenshare-watch-url-action';
import { ImportMismoAction } from './import-mismo-action';
import { JointCreditPullAction } from './joint-credit-pull-action';
import { ManageApplicationTeamAction } from './manage-application-team-action';
import { PushDocumentToLosAction } from './push-document-to-los-action';
import { PushDocumentsToLosAction } from './push-documents-to-los-action';
import { PushToInternalSystemAction } from './push-to-internal-system-action';
import { PushToLosAction } from './push-to-los-action';
import { RefreshFinicityReportAction } from './refresh-finicity-report-action';
import { RefreshMortgageFlexLoanStatusAction } from './refresh-mortgageflex-status-action';
import { RefreshUnderwritingConditionsAction } from './refresh-underwriting-conditions';
import { RestoreApplicationAction } from './restore-application-action';
import { SendApplicantInvitationAction } from './send-applicant-invitation-action';
import { SendPreQualificationLetterAction } from './send-prequalification-letter-action';
import { ShareAssignmentLinkAction } from './share-assignment-link-action';
import { CreateSmartviewAction, UpdateSmartviewAction } from './smartview-action';
import { type Action, type ActionListener, ActionStatus, ActionType } from './types';
import { UpdateApplicantBorrowerInformationAction } from './update-applicant-borrower-information-action';
import { UpdateLendingQBPushAction } from './update-lending-qb-push-action';
import { UploadDocumentsAction } from './upload-documents-action';
import { UploadLenderDocumentsAction } from './upload-lender-documents-action';
import { WorkNumberInstantReportAction } from './worknumber-instant-report-action';

const ActionComponents: Record<ActionType, any> = {
  [ActionType.CREATE_APPLICATION_FROM_APPLICANT]: CreateApplicationFromApplicantAction,
  [ActionType.DELETE_APPLICATION]: DeleteApplicationAction,
  [ActionType.DELETE_TASK]: DeleteTaskAction,
  [ActionType.EDIT_CUSTOM_TASK]: EditCustomTaskAction,
  [ActionType.ADD_APPLICANT]: AddApplicantAction,
  [ActionType.DELETE_APPLICANT]: DeleteApplicantAction,
  [ActionType.CREATE_AGENT]: CreateAgentAction,
  [ActionType.UPDATE_AGENT]: UpdateAgentAction,
  [ActionType.CREATE_APPLICATION]: CreateApplicationAction,
  [ActionType.PUSH_TO_LOS]: PushToLosAction,
  [ActionType.CREDIT_PULL]: CreditPullAction,
  [ActionType.JOINT_CREDIT_PULL]: JointCreditPullAction,
  [ActionType.GENERATE_DU_ASSESSMENT]: GenerateDUAssessmentAction,
  [ActionType.GET_SCREENSHARE_WATCH_URL]: GetScreenshareWatchUrlAction,
  [ActionType.SEND_PREQUALIFICATION_LETTER]: SendPreQualificationLetterAction,
  [ActionType.WORK_NUMBER_INSTANT_REPORT]: WorkNumberInstantReportAction,
  [ActionType.DELETE_AGENT]: DeleteAgentAction,
  [ActionType.UPLOAD_LENDER_DOCUMENTS]: UploadLenderDocumentsAction,
  [ActionType.UPLOAD_DOCUMENTS]: UploadDocumentsAction,
  [ActionType.SEND_APPLICANT_INVITATION]: SendApplicantInvitationAction,
  [ActionType.UPDATE_APPLICANT_BORROWER_INFORMATION]: UpdateApplicantBorrowerInformationAction,
  [ActionType.GENERATE_LPA_ASSESSMENT]: GenerateLPAAssessmentAction,
  [ActionType.BULK_CREATE_CUSTOM_TASK]: BulkCreateCustomTaskAction,
  [ActionType.UPDATE_LENDING_QB_PUSH]: UpdateLendingQBPushAction,
  [ActionType.REFRESH_FINICITY_REPORT]: RefreshFinicityReportAction,
  [ActionType.REFRESH_MORTGAGEFLEX_STATUS]: RefreshMortgageFlexLoanStatusAction,
  [ActionType.MANAGE_APPLICATION_TEAM_ACTION]: ManageApplicationTeamAction,
  [ActionType.PUSH_TO_INTERNAL_SYSTEM]: PushToInternalSystemAction,
  [ActionType.ARCHIVE_APPLICATION]: ArchiveApplicationAction,
  [ActionType.RESTORE_APPLICATION]: RestoreApplicationAction,
  [ActionType.DUPLICATE_APPLICATION]: DuplicateApplicationAction,
  [ActionType.CREATE_SMARTVIEW]: CreateSmartviewAction,
  [ActionType.UPDATE_SMARTVIEW]: UpdateSmartviewAction,
  [ActionType.PUSH_DOCUMENT_TO_LOS]: PushDocumentToLosAction,
  [ActionType.PUSH_DOCUMENTS_TO_LOS]: PushDocumentsToLosAction,
  [ActionType.REFRESH_UNDERWRITING_CONDITIONS]: RefreshUnderwritingConditionsAction,
  [ActionType.SHARE_ASSIGNMENT_LINK]: ShareAssignmentLinkAction,
  [ActionType.IMPORT_MISMO]: ImportMismoAction,
  [ActionType.EDIT_FOLLOW_UP_TASK]: EditFollowUpTaskAction,
};

interface IActionsProviderProps {
  children?: ReactNode;
}

export const ActionsProvider = (props: IActionsProviderProps) => {
  const { children } = props;

  const [actions, setActions] = useState<Array<[string, Action, ActionListener | undefined]>>([]);

  const listenersRef = useRef<
    Array<{
      listener: ActionListener;
      type?: ActionType;
      status?: ActionStatus;
    }>
  >([]);

  const handleAction = useCallback((action: Action, cb?: ActionListener) => {
    setActions((state) => [...state, [uniqueId(), action, cb]]);
    listenersRef.current.forEach(({ listener, type, status }) => {
      if ((type && action.type !== type) || (status && status !== ActionStatus.OPEN)) {
        return;
      }
      listener(action, ActionStatus.OPEN);
    });
  }, []);

  const handleSubscribe = (listener: ActionListener, type?: ActionType, status?: ActionStatus) => {
    listenersRef.current = [...listenersRef.current, { listener, type, status }];
    return () => {
      listenersRef.current = listenersRef.current.filter(({ listener: l }) => l !== listener);
    };
  };

  const handleSubmit = (action: Action) => () => {
    listenersRef.current.forEach(({ listener, type, status }) => {
      if ((type && action.type !== type) || (status && status !== ActionStatus.SUBMITTED)) {
        return;
      }
      listener(action, ActionStatus.SUBMITTED);
    });
  };

  const handleDone = (action: Action) => (result?: Record<string, unknown>) => {
    setActions((state) =>
      state.filter(([, a, cb]) => {
        if (a === action) {
          if (cb) {
            cb(a, ActionStatus.DONE, result || undefined);
          }

          listenersRef.current.forEach(({ listener, type, status }) => {
            if ((type && a.type !== type) || (status && status !== ActionStatus.DONE)) {
              return;
            }
            listener(a, ActionStatus.DONE, result || undefined);
          });
        }

        return a !== action;
      })
    );
  };

  const handleCancel = (action: Action) => () => {
    setActions((state) =>
      state.filter(([, a, cb]) => {
        if (a === action) {
          if (cb) {
            cb(a, ActionStatus.CANCEL);
          }

          listenersRef.current.forEach(({ listener, type, status }) => {
            if ((type && a.type !== type) || (status && status !== ActionStatus.CANCEL)) {
              return;
            }
            listener(a, ActionStatus.CANCEL);
          });
        }

        return a !== action;
      })
    );
  };

  const value: IApplicationActionsContext = useMemo(
    () => ({
      dispatch: handleAction,
      subscribe: handleSubscribe,
    }),
    [handleAction]
  );

  return (
    <ApplicationActionsContext.Provider value={value}>
      {children}
      {actions.map(([id, action]) => {
        const { type } = action;
        const component = ActionComponents[type];
        return createElement(component, {
          key: id,
          action,
          onSubmit: handleSubmit(action),
          onDone: handleDone(action),
          onCancel: handleCancel(action),
        });
      })}
    </ApplicationActionsContext.Provider>
  );
};
