import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../apollo';

const MUpscopeVisitors = graphql(`
  mutation UpscopeVisitors($applicationFileId: ID!) {
    upscope {
      visitors(applicationFileId: $applicationFileId)
    }
  }
`);

export const useUpscopeVisitors = () => {
  const fn = useMutation(MUpscopeVisitors);

  return useCallback(
    async (applicationFileId: string) => {
      const result = await fn({ applicationFileId });
      return result.data!.upscope.visitors;
    },
    [fn]
  );
};
