import { useMemo } from 'react';

import { useLinkTask } from './use-link-task';

/**
 * // global regex is stateful, so using "test" will return the next match until there are no matches
 * // see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/test
 * // IF "test" is changed to "matchAll" ever....
 * // "matchAll" method requires global regex however it will reset state everytime:
 * // see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/@@matchAll
 * // /[a-zA-Z_]+:[a-zA-Z_]+:(?<=:)\d+/ << DOES NOT WORK ON SAFARI OR IE YAY
 *
 */

const REGEX = /[a-zA-Z_]+:[a-zA-Z_]+:\d+/;

export const useGetRedirectPath = (encodedPath: string): any => {
  const decodedPath = atob(encodedPath);
  const isValid = REGEX.test(decodedPath);
  const [, model, modelId] = decodedPath.split(':');

  const taskId = isValid && model === 'task' ? modelId : undefined;

  const [taskApplicantId, taskApplicationFileId, linkTaskLoading] = useLinkTask(taskId);

  const loading = linkTaskLoading;

  // generate redirect path
  const redirectPath = useMemo(() => {
    if (!isValid) {
      return undefined;
    }
    let nextPath;
    if (model === 'task' && taskApplicationFileId && taskApplicantId && modelId) {
      nextPath = `/dashboard#/applications/${taskApplicationFileId}/tasks/${taskApplicantId}/${modelId}`;
    } else if (model === 'application_file' && modelId) {
      nextPath = `/dashboard#/applications/${modelId}`;
    }
    return nextPath;
  }, [isValid, model, modelId, taskApplicantId, taskApplicationFileId]);

  return [redirectPath, loading];
};
