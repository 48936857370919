import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';
import { orderBy } from 'lodash-es';
import { useMemo } from 'react';

import { graphql } from '../../../../../apollo';

const QTasksOverviewSection = graphql(`
  query TasksOverviewSection($applicationFileId: ID!) {
    applicationFile(id: $applicationFileId) {
      id
      primaryApplicant {
        id
      }
      applicants(options: { perPage: 1000 }) {
        items {
          id
          firstName
          lastName
          position
        }
      }
      tasks(state: open) {
        id
        key
        title
        typeName
        isAlerted
        isFrozen
        isNew
        isVisible
        isStarted
        common
        custom
        blueprintIndex
        primaryApplicantId
        state
        meta {
          title
        }
      }
    }
  }
`);

export const useTasksOverviewSection = (
  applicationFileId: string,
  options: IUseApolloQueryOptions = {}
) => {
  const [data, loading, , { refetch }] = useQuery(
    QTasksOverviewSection,
    { applicationFileId },
    {
      fetchPolicy: 'network-only',
      ...options,
      throw: false,
    }
  );

  const handleRefetch = useSafeCallback(() => refetch({ applicationFileId }));

  const result = useMemo(() => {
    const tasks = data?.applicationFile?.tasks || [];
    const applicants = data?.applicationFile?.applicants.items || [];
    const sortedApplicants = orderBy(applicants, ['position']);
    const primaryApplicantId = data?.applicationFile?.primaryApplicant?.id || undefined;

    return {
      tasks,
      applicants: sortedApplicants,
      primaryApplicantId,
    };
  }, [data]);

  return [result, loading, handleRefetch] as const;
};
