import { type FormSubmitHandler } from '@mortgagehippo/ds';

import { useSites } from '../../../hooks/use-sites';
import { CreateApplicationModal } from './create-application-modal';

interface ICreateApplicationModalContainerProps {
  isOpen: boolean;
  onSubmit: FormSubmitHandler;
  onRequestClose: () => void;
}

export const CreateApplicationModalContainer = (props: ICreateApplicationModalContainerProps) => {
  const { onSubmit, onRequestClose, isOpen } = props;

  const [sitesData, sitesLoading] = useSites();

  return (
    <CreateApplicationModal
      isOpen={isOpen}
      loading={sitesLoading}
      siteOptions={sitesData || []}
      onSubmit={onSubmit}
      onRequestClose={onRequestClose}
    />
  );
};
