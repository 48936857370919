import {
  borderRadius,
  Button,
  Choice,
  Constant,
  type IChoiceOption,
  Icon,
  notifications,
  palette,
  spacing,
  Spinner,
  type UploadHandler,
  UploadInput,
  useFormValue,
} from '@mortgagehippo/ds';
import {
  ACCEPTED_DOCUMENT_MIME_TYPES,
  HellosignEvent,
  useHellosignClient,
} from '@mortgagehippo/tasks';
import { toArray } from '@mortgagehippo/util';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { type IApplicationApplicant } from '../../../../../pages/application/use-application-file-data';
import { useCreateHellosignUnclaimedDraft } from '../../../edit-custom-task-action/custom-hellosign-task/use-create-hellosign-unclaimed-draft';
import { COMMON_APPLICANT_OPTION_VALUE } from '../../constants';
import { DefaultFields } from '../default-fields';

const StatusContainer = styled.div`
  text-align: center;
  background-color: ${palette('neutral50')};
  color: ${palette('neutral600')};
  padding: ${spacing(3)} ${spacing(2)};
  border-radius: ${borderRadius(2)};
`;

export enum SignatureSelectionMethod {
  AUTOMATIC = 'automatic',
  MANUAL = 'manual',
}

export const HellosignStyles = createGlobalStyle`
  .x-hellosign-embedded__modal-content {
    width: 90vw !important;
    max-width: 1400px !important;
  }
`;

const SIGNATURE_SELECTION_METHODS: IChoiceOption[] = [
  {
    label: 'Automatically append a signature page',
    value: SignatureSelectionMethod.AUTOMATIC,
  },
  {
    label: 'Manually place signature blocks',
    value: SignatureSelectionMethod.MANUAL,
  },
];

interface IHellosignTaskEditorFieldsProps {
  applicationFileId: string;
  applicants: IApplicationApplicant[];
  onUpload: UploadHandler;
  showDueDate?: boolean;
}

export const HellosignTaskEditorFields = (props: IHellosignTaskEditorFieldsProps) => {
  const { applicationFileId, applicants, showDueDate, onUpload } = props;

  const hasInitializedRef = useRef(false);
  const prepareButtonRef = useRef<HTMLButtonElement | null>();

  const [title] = useFormValue('title');
  const [applicantIds] = useFormValue('applicant_ids');
  const [document, setDocument] = useFormValue('data.document');
  const [documents, setDocuments] = useFormValue('state.documents');

  const [signatureSelectionMethod] = useFormValue<SignatureSelectionMethod | undefined>(
    'state.signature_selection_method'
  );
  const [signatureRequestId, setSignatureRequestId] = useFormValue<string | undefined>(
    'data.signature_request_id'
  );

  const [editingSignatureRequestId, setEditingSignatureRequestId] = useState<string | undefined>();

  const finishedSignatureSetup = !!signatureRequestId;

  const hasApplicants = toArray(applicantIds).length > 0;

  const shouldShowPrepareButton =
    hasApplicants &&
    !!document &&
    signatureSelectionMethod === SignatureSelectionMethod.MANUAL &&
    !finishedSignatureSetup;

  const waitingForSignatures = shouldShowPrepareButton && !!signatureRequestId;

  const client = useHellosignClient();

  const createUnclaimedDraft = useCreateHellosignUnclaimedDraft();

  const handleShowSignatureEditor = useCallback(async () => {
    const requestApplicantIds = toArray(applicantIds);
    const requestApplicantId =
      requestApplicantIds[0] === COMMON_APPLICANT_OPTION_VALUE ? null : requestApplicantIds[0];
    const documentId = document?.id;

    try {
      const inputData = {
        applicantId: requestApplicantId,
        documentId,
        title,
      };

      const {
        signatureRequestId: nextSignatureRequestId,
        clientId,
        claimUrl,
      } = await createUnclaimedDraft(applicationFileId, inputData);

      setEditingSignatureRequestId(nextSignatureRequestId);

      client.open(claimUrl, clientId);
    } catch (e) {
      console.error(e);
      notifications.error({
        message: 'There was an unexpected error creating your template.',
      });
    }
  }, [applicantIds, applicationFileId, client, createUnclaimedDraft, document, title]);

  useEffect(() => {
    // initialize documents field from template document
    if (!hasInitializedRef.current) {
      hasInitializedRef.current = true;
      setDocuments(document ? [document] : []);
    }
  }, [document, documents, setDocuments]);

  useEffect(() => {
    const [nextDocument] = documents || [];
    setDocument(nextDocument);
  }, [documents, setDocument]);

  useEffect(() => {
    // listen to manual signature setup completion
    if (!client.ready) {
      return undefined;
    }

    return client.subscribe((event: HellosignEvent) => {
      if (event !== HellosignEvent.FINISH) {
        // ignore other events
        return;
      }

      setSignatureRequestId(editingSignatureRequestId);
    });
  }, [client, editingSignatureRequestId, setSignatureRequestId]);

  useEffect(() => {
    if (shouldShowPrepareButton) {
      prepareButtonRef.current?.scrollIntoView();
    }
  }, [shouldShowPrepareButton]);

  return (
    <>
      <HellosignStyles />
      <Constant name="data.document" />
      <Constant name="data.signature_request_id" />

      <DefaultFields
        applicants={applicants}
        disableApplicantSelection={finishedSignatureSetup}
        allowMultipleApplicants={false}
        allowCommon
        showDueDate={showDueDate}
      />

      {!finishedSignatureSetup && (
        <UploadInput.Box
          label="Document"
          name="state.documents"
          onUpload={onUpload}
          required
          maxItems={1}
          accept={ACCEPTED_DOCUMENT_MIME_TYPES}
          fileTypeDescription="document"
        />
      )}

      {!finishedSignatureSetup && (
        <Choice.Box
          name="state.signature_selection_method"
          label="How would you like to set up the signature fields?"
          required
          options={SIGNATURE_SELECTION_METHODS}
        />
      )}

      {shouldShowPrepareButton ? (
        <Button
          ref={prepareButtonRef}
          icon="sign"
          importance="secondary"
          onClick={handleShowSignatureEditor}
          block
        >
          Configure signatures
        </Button>
      ) : null}

      {finishedSignatureSetup ? (
        <StatusContainer>
          <Icon size="xl" name="success" color="success600" />
          <br />
          <strong>Signatures successfully configured.</strong>
          <br />
          You can now save the task.
        </StatusContainer>
      ) : null}

      {waitingForSignatures ? (
        <StatusContainer>
          <Spinner size="xl" color="neutral600" />
          <br /> <strong>Waiting for signature placement...</strong>
        </StatusContainer>
      ) : null}
    </>
  );
};
