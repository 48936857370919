import {
  type ISearchFields,
  type ISearchFilter,
  normalizeFilters,
  palette,
  spacing,
  Text,
  useFormValue,
} from '@mortgagehippo/ds';
import { noop } from 'lodash-es';
import { useMemo } from 'react';
import styled from 'styled-components';

import { SmartviewModalFilterGroup } from './smartview-modal-filter-group';

interface ISmartviewModalFiltersProps {
  searchFields?: ISearchFields;
}

const Container = styled.div`
  position: relative;
`;

const Line = styled.div`
  position: absolute;
  height: calc(100% - 50px);
  left: ${spacing(4)};
  border-left: 2px dotted ${palette('neutral300')};
`;

const FiltersContainer = styled.div`
  position: relative;
`;

const Operator = styled.div`
  padding: 0 0 ${spacing(3)} ${spacing(5)};
  color: ${palette('neutral500')};
  font-style: italic;
`;

export const SmartviewModalFilters = (props: ISmartviewModalFiltersProps) => {
  const { searchFields } = props;
  const [rawFilters = [[]], setSearchFilters] = useFormValue<ISearchFilter[][] | undefined>(
    'config.filters'
  );

  const searchFilters = useMemo(() => {
    const nextFilters = normalizeFilters(rawFilters || []);
    if (nextFilters.length === 0) {
      nextFilters.push([]);
    }
    return nextFilters;
  }, [rawFilters]);

  const hasFilters = useMemo(
    () => searchFilters.reduce((acum, group) => acum + group.length, 0) > 0,
    [searchFilters]
  );

  const handleAddFilter = (index: number) => (filter: ISearchFilter) => {
    const nextSearchFilters = [...searchFilters];
    const groupFilters = nextSearchFilters[index] || [];
    groupFilters.push(filter);
    nextSearchFilters[index] = groupFilters;
    setSearchFilters(nextSearchFilters);
  };

  const handleAddFilterGroup = (filter: ISearchFilter) => {
    const nextSearchFilters = [...searchFilters];
    nextSearchFilters.push([filter]);
    setSearchFilters(nextSearchFilters);
  };

  const handleOnRemoveFilter = (groupIndex: number) => (filterIndex: number) => {
    let nextSearchFilters = [...searchFilters];

    // Remove the field from the group
    nextSearchFilters[groupIndex]!.splice(filterIndex, 1);

    // Remove any empty groups
    nextSearchFilters = nextSearchFilters.reduce((acum, filterGroup) => {
      if (filterGroup.length) {
        acum.push(filterGroup);
      }
      return acum;
    }, [] as ISearchFilter[][]);

    // Ensure we always have at least one group
    if (nextSearchFilters.length === 0) {
      nextSearchFilters.push([]);
    }

    setSearchFilters(nextSearchFilters);
  };

  return (
    <Container>
      <Line />
      <FiltersContainer>
        {searchFilters.map((searchFilterGroup, i) => (
          <>
            <SmartviewModalFilterGroup
              filters={searchFilterGroup}
              searchFields={searchFields}
              onAddFilter={handleAddFilter(i)}
              onRemoveFilter={handleOnRemoveFilter(i)}
            />
            {hasFilters ? <Operator>OR</Operator> : null}
          </>
        ))}

        {hasFilters ? (
          <SmartviewModalFilterGroup
            filters={[]}
            searchFields={searchFields}
            onAddFilter={handleAddFilterGroup}
            onRemoveFilter={noop}
          >
            <Text as="p" size="xs" variant="secondary">
              Add an alternative set of conditions:
            </Text>
          </SmartviewModalFilterGroup>
        ) : null}
      </FiltersContainer>
    </Container>
  );
};
