import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../apollo';
import { type ResetPasswordInput } from '../../../apollo/graphql';

const MResetPasswordAgent = graphql(`
  mutation ResetPasswordAgent($data: ResetPasswordInput!) {
    resetPassword(data: $data) {
      status
      message
    }
  }
`);

export const useResetPassword = () => {
  const resetPassword = useMutation(MResetPasswordAgent, undefined);

  return useCallback(
    async (data: ResetPasswordInput) => {
      const result = await resetPassword({ data });
      return result.data!.resetPassword;
    },
    [resetPassword]
  );
};
