import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo';

export const MTriggerJointCreditCheck = graphql(`
  mutation TriggerJointCreditCheck($applicationFileId: ID!, $forceAuthorization: Boolean) {
    triggerJointCreditPull(
      applicationFileId: $applicationFileId
      forceAuthorization: $forceAuthorization
    )
  }
`);

export const useTriggerJointCreditCheck = () => {
  const triggerJointCreditCheck = useMutation(MTriggerJointCreditCheck, undefined);

  return useCallback(
    async (applicationFileId: string, forceAuthorization: boolean) => {
      const response = await triggerJointCreditCheck({
        applicationFileId,
        forceAuthorization,
      });
      return response.data!.triggerJointCreditPull;
    },
    [triggerJointCreditCheck]
  );
};
