import {
  Alert,
  borderRadius,
  Button,
  Icon,
  type IFile,
  palette,
  Spacer,
  spacing,
  Spinner,
} from '@mortgagehippo/ds';
import { DOCUSIGN_EDITOR_PATH, DocusignEventType, useDocusignEvent } from '@mortgagehippo/tasks';
import { toArray } from '@mortgagehippo/util';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { type DocusignExternalSigner } from '../../../../apollo/graphql';
import { useCreateDocusignEnvelope } from './use-create-docusign-envelope';

const StatusContainer = styled.div`
  text-align: center;
  background-color: ${palette('neutral50')};
  color: ${palette('neutral600')};
  padding: ${spacing(3)} ${spacing(2)};
  border-radius: ${borderRadius(2)};
`;

type EditorStatus = 'creating_envelope' | 'envelope_created' | 'awaiting_editor' | 'done' | 'error';

type EditorState = {
  status: EditorStatus;
  editorUrl?: string;
  envelopeId?: string;
};

interface IDocusignEditorProps {
  applicationFileId: string;
  externalSigners?: DocusignExternalSigner[];
  applicantIds?: string | string[];
  documents?: IFile[];
  onFinish: (envelopeId: string) => void;
  envelopeId?: string;
}

export const DocusignEditor = (props: IDocusignEditorProps) => {
  const {
    externalSigners = [],
    applicationFileId,
    applicantIds = [],
    documents,
    onFinish,
    envelopeId: initialEnvelopeId,
  } = props;

  const initialStatus = initialEnvelopeId ? 'done' : 'creating_envelope';

  const ref = useRef<HTMLDivElement | null>(null);

  const [editorState, setEditorState] = useState<EditorState>({
    envelopeId: initialEnvelopeId,
    status: initialStatus,
  });
  const { status, editorUrl, envelopeId } = editorState;

  const createEnvelope = useCreateDocusignEnvelope();

  const autoScroll = initialStatus !== 'done';

  useDocusignEvent(DocusignEventType.EDITOR_FINISHED, (event) => {
    if (event.envelopeId === envelopeId && event.status === 'completed') {
      setEditorState(() => ({ ...editorState, status: 'done' }));
      onFinish(envelopeId!);
    }
  });

  useEffect(() => {
    let canceled = false;
    (async () => {
      try {
        const documentIds = documents?.map((d) => `${d.id}`) ?? [];
        const nextApplicantIds = toArray(applicantIds);

        if (envelopeId || !documentIds.length || !nextApplicantIds.length) {
          return;
        }

        const { envelopeId: nextEnvelopeId } = await createEnvelope(
          applicationFileId,
          nextApplicantIds,
          documentIds,
          externalSigners
        );

        const nextEditorUrl = DOCUSIGN_EDITOR_PATH.replace(':envelopeId', nextEnvelopeId);
        if (canceled) return;

        setEditorState((s) => ({
          ...s,
          envelopeId: nextEnvelopeId,
          editorUrl: nextEditorUrl,
          status: 'envelope_created',
        }));
      } catch (e) {
        if (canceled) return;
        setEditorState((s) => ({ ...s, status: 'error' }));
      }
    })();

    return () => {
      canceled = true;
    };
  }, [externalSigners, applicantIds, applicationFileId, createEnvelope, documents, envelopeId]);

  useEffect(() => {
    if (autoScroll) {
      ref.current?.scrollIntoView();
    }
  }, [autoScroll, status]);

  const handleClick = () => {
    setEditorState((s) => ({ ...s, status: 'awaiting_editor' }));
  };

  return (
    <div ref={ref}>
      {status === 'creating_envelope' && (
        <StatusContainer>
          <Spinner size="xl" color="neutral600" />
          <br />
          <strong>Preparing documents...</strong>
        </StatusContainer>
      )}

      {status === 'envelope_created' && (
        <>
          <StatusContainer>
            <Icon size="xl" name="success" color="success600" />
            <br />
            <strong>Documents successfully prepared</strong>
          </StatusContainer>
          <Spacer bottom={3} top={5}>
            <Alert type="info" size="sm">
              Use the button below to place the signatures. This will open the Docusign Editor in a
              new tab. Once done, return to this tab to finish creating the task.
            </Alert>
          </Spacer>
          <Button
            href={editorUrl}
            target="_blank"
            icon="sign"
            importance="secondary"
            onClick={handleClick}
            block
          >
            Configure signatures
          </Button>
        </>
      )}

      {status === 'awaiting_editor' && (
        <StatusContainer>
          <Spinner size="xl" color="neutral600" />
          <br /> <strong>Waiting for signature placement...</strong>
          <br /> (check the newly created tab)
        </StatusContainer>
      )}

      {status === 'done' && (
        <StatusContainer>
          <Icon size="xl" name="success" color="success600" />
          <br />
          <strong>Signatures successfully configured.</strong>
          <br />
          You can now save the task.
        </StatusContainer>
      )}

      {status === 'error' && (
        <StatusContainer>
          <Icon size="xl" name="error" color="danger600" />
          <br />
          <strong>There was an error preparing the documents</strong>
        </StatusContainer>
      )}
    </div>
  );
};
