import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../apollo';

const QSiteReports = graphql(`
  query SiteReports($siteId: ID!) {
    site(id: $siteId) {
      id
      reportDashboards
    }
  }
`);

export const useSiteReportDashboards = (siteId: string, options: IUseApolloQueryOptions = {}) => {
  const [data, loading] = useQuery(
    QSiteReports,
    {
      siteId,
    },
    options
  );

  const dashboards = !loading ? data?.site?.reportDashboards : undefined;

  return [dashboards, loading] as const;
};
