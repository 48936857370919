import {
  type ISelectOption,
  ModalDialog,
  Select,
  useCustomizations,
  useModal,
} from '@mortgagehippo/ds';
import { useSagaClickHandler } from '@mortgagehippo/tasks';
import { useCallback, useState } from 'react';

import { useRefreshFinicityReport } from '../../../pages/application/use-refresh-finicity-report';
import { type IActionProps, type IRefreshFinicityReportAction } from '../types';

const reportDayOptions: ISelectOption[] = [
  {
    value: 60,
    label: '2 months',
  },
  {
    value: 90,
    label: '3 months',
  },
  {
    value: 180,
    label: '6 months',
  },
  {
    value: 365,
    label: '12 months',
  },
];

export const RefreshFinicityReportAction = (props: IActionProps<IRefreshFinicityReportAction>) => {
  const { action, onSubmit, onDone, onCancel } = props;
  const { applicationFileId, applicantId, canChangeDays, defaultReportDays } = action;
  const [reportDays, setReportDays] = useState<number | undefined>(defaultReportDays);

  const [isOpen, , closeModal] = useModal(true);

  const customizations = useCustomizations();
  const title = customizations.text('refresh-finicity-report:confirm-title', 'Refresh Report');
  const explanation = customizations.text(
    'refresh-finicity-report:confirm-explanation',
    'Please confirm that you want to refresh the VOA report. A new report file will be generated with the most current information.'
  );

  const refreshFinicityReport = useRefreshFinicityReport();

  const [handleOk, running] = useSagaClickHandler({
    infoMessageCid: 'refresh-finicity-report:info',
    successMessageCid: 'refresh-finicity-report:success',
    errorMessageCid: 'refresh-finicity-report:error',
    infoMessage: 'Refreshing Finicity Report',
    successMessage: 'Successfully refreshed verification of assets report',
    errorMessage: 'There was an unexpected error refreshing the verification of assets report',
    async onClick(setAsyncId) {
      const asyncId = await refreshFinicityReport(applicationFileId, applicantId, reportDays);

      closeModal(() => {
        if (asyncId) {
          setAsyncId(asyncId);
        }
        onSubmit();
      });
    },
    async onDone() {
      closeModal(onDone);
    },
  });

  const handleReportDaysChange = useCallback((newValue: number) => {
    setReportDays(newValue);
  }, []);

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  if (running) return null;

  // TODO: use hook useModalConfirm
  return (
    <ModalDialog
      type="dialog"
      title={title}
      onOk={handleOk}
      onRequestClose={handleCloseModal}
      isOpen={isOpen}
      okButtonLabel="Refresh report"
    >
      <p>{explanation}</p>
      {!!canChangeDays && (
        <Select.InputBox
          name="reportDays"
          label="Report history range"
          options={reportDayOptions}
          onChange={handleReportDaysChange}
          value={reportDays}
        />
      )}
    </ModalDialog>
  );
};
