import { Choice, type IChoiceGroup, type IChoiceOption, Input } from '@mortgagehippo/ds';
import { type ITaskTemplate } from '@mortgagehippo/tasks';
import { find, groupBy, sortBy, startsWith } from 'lodash-es';
import { useMemo, useState } from 'react';

import { CUSTOM_TEMPLATE_GROUP, FALLBACK_TEMPLATE_GROUP } from '../../constants';

const filterTemplatesBy = (templates: ITaskTemplate[], search: string) =>
  templates.filter((template) => {
    const title = template.title.toLowerCase();
    const casedSearch = search.toLowerCase();

    const titleParts = title.split(/[()\s,.:-]+/);
    return (
      startsWith(title, casedSearch) || !!find(titleParts, (part) => startsWith(part, casedSearch))
    );
  });

interface ITasksChoiceProps {
  templates: ITaskTemplate[];
  onCreate: (templateKey: string) => void;
}

export const TaskChoice = (props: ITasksChoiceProps) => {
  const { templates, onCreate } = props;

  const [search, setSearch] = useState<string | undefined>();

  const templateOptions: IChoiceOption[] | IChoiceGroup[] = useMemo(() => {
    const hasGroups = templates.some((t) => !!t.group);

    const matchingTemplates = search ? filterTemplatesBy(templates, search) : templates;

    if (!hasGroups) {
      const sortedTemplates = sortBy(matchingTemplates, ['title']);

      return sortedTemplates.map((t) => ({
        label: <strong>{t.title}</strong>,
        value: t.key,
      }));
    }

    const sortedTemplates = sortBy(matchingTemplates, ['title']);
    const groupedTemplates = groupBy(sortedTemplates, (t) => t.group || FALLBACK_TEMPLATE_GROUP);

    // put custom group last
    const sortedGroups = Object.keys(groupedTemplates).sort((a, b) => {
      if (a === CUSTOM_TEMPLATE_GROUP) return 1;
      if (b === CUSTOM_TEMPLATE_GROUP) return -1;

      if (a < b) {
        return -1;
      }

      if (a > b) {
        return 1;
      }

      return 0;
    });

    return sortedGroups.map((groupName) => {
      const groupTemplates = groupedTemplates[groupName] || [];

      return {
        label: groupName,
        options: groupTemplates.map((t) => ({
          label: <strong>{t.title}</strong>,
          value: t.key,
        })),
      };
    });
  }, [search, templates]);

  return (
    <>
      <div>
        <Input.Input
          name="search"
          placeholder="Search task types"
          value={search}
          onChange={setSearch}
          size="sm"
        />
      </div>
      <Choice.Input
        name="template"
        onChange={onCreate}
        value={undefined}
        options={templateOptions}
        hideRadioIcon
        size="sm"
        compact
      />
    </>
  );
};
