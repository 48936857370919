import {
  Button,
  Dropdown,
  type FontSize,
  fontWeight,
  Icon,
  type IDropdownAction,
  Tag,
} from '@mortgagehippo/ds';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { ApplicationStatusDropdownOption } from './application-status-dropdown-option';
import {
  type IApplicationStatus,
  useApplicationStatus,
  useApplicationStatuses,
  useSetApplicationStatus,
} from './use-application-statuses';
import { toTagColorProps } from './util';

const NONE_OPTION: IApplicationStatus = {
  id: '0',
  name: 'None',
  color: -1,
  index: -1,
};

const StyledDropdown = styled(Dropdown)<any>`
  && {
    ${Button} {
      display: inline-block;
      background: transparent;
      padding: 0;
      font-weight: ${fontWeight('regular')};
      line-height: 1em;

      &:hover,
      &:focus {
        opacity: 0.7;
      }

      &:active {
        opacity: 0.7;
      }
    }
  }
`;

const StyledIcon = styled(Icon)`
  margin-left: 2px; // exception to using DS
  margin-right: -2px; // exception to using DS
`;

export interface IApplicationStatusDropdownProps {
  applicationFileId: string;
  statusId?: string | null;
  size?: FontSize;
  disabled?: boolean;
}

export const ApplicationStatusDropdown = (props: IApplicationStatusDropdownProps) => {
  const { applicationFileId, statusId, size, disabled } = props;

  const [active, setActive] = useState<IApplicationStatus>(NONE_OPTION);
  const [loading, setLoading] = useState(false);

  const [statuses] = useApplicationStatuses();

  const shouldQueryForStatus = statusId === undefined;
  const [status] = useApplicationStatus(applicationFileId, { skip: !shouldQueryForStatus });

  const setApplicationStatus = useSetApplicationStatus();

  const handleAction = async (_key: string, _data: any, actionItemData: IApplicationStatus) => {
    setLoading(true);
    await setApplicationStatus(
      applicationFileId,
      actionItemData.id !== '0' ? `${actionItemData.id}` : null
    );
    setActive(actionItemData);
    setLoading(false);
  };

  const actions: IDropdownAction[] = useMemo(
    () =>
      [NONE_OPTION, ...(statuses || [])].map((o) => ({
        key: `${o.id}`,
        label: (
          <ApplicationStatusDropdownOption
            active={active.id === o.id}
            color={o.color >= 0 ? o.color : undefined}
            size={size}
          >
            {o.name}
          </ApplicationStatusDropdownOption>
        ),
        data: o,
      })),
    [active.id, statuses, size]
  );

  const initialValue = statusId || status;
  useEffect(() => {
    if (initialValue && statuses) {
      const item = statuses.find((i) => `${i.id}` === `${initialValue}`);
      setActive(item || NONE_OPTION);
    }
  }, [initialValue, statuses]);

  const { color, inverted } =
    active.color >= 0
      ? toTagColorProps(active.color)
      : ({ color: 'neutral', inverted: false } as const);

  const tagEl = (
    <Tag size={size || 'sm'} compact color={color} inverted={inverted}>
      {active.name}
      {!disabled && <StyledIcon size="xs" name="down-arrow-small" />}
    </Tag>
  );

  if (disabled) {
    return tagEl;
  }

  return (
    <StyledDropdown
      size="sm"
      buttonProps={{
        size: 'xxs',
        type: 'neutral',
        importance: 'tertiary',
        align: 'left',
        block: false,
        compact: true,
        loading,
      }}
      actions={actions}
      label={tagEl}
      onAction={handleAction}
    />
  );
};
