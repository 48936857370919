import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { graphql } from '../../apollo';
import { type PrimaryApplicantsFilesQuery } from '../../apollo/graphql';

export const QPrimaryApplicantsFiles = graphql(`
  query PrimaryApplicantsFiles($applicationFileId: ID!) {
    applicationFile(id: $applicationFileId) {
      id
      primaryApplicant {
        id
        applicationFiles(options: { perPage: 100, orderBy: { created_at: "desc" } }) {
          items {
            id
            detailedDescription
            createdAt
          }
        }
      }
    }
  }
`);

export type IPrimaryApplicantApplicationFile = NonNullable<
  NonNullable<PrimaryApplicantsFilesQuery['applicationFile']>['primaryApplicant']
>['applicationFiles']['items'][0];

export const usePrimaryApplicantsFiles = (
  applicationFileId: string,
  options: IUseApolloQueryOptions = {}
) => {
  const [data, loading, , { refetch }] = useQuery(
    QPrimaryApplicantsFiles,
    { applicationFileId },
    {
      fetchPolicy: 'network-only',
      ...options,
    }
  );

  const handleRefresh = useSafeCallback(async () => {
    await refetch({ applicationFileId });
  });

  let result;

  if (!loading && data?.applicationFile?.primaryApplicant) {
    result = data.applicationFile.primaryApplicant.applicationFiles.items;
  }

  return [{ data: result, loading }, handleRefresh] as const;
};
