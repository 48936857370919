import {
  type FormSubmitHandler,
  type IFile,
  ModalWizard2 as ModalWizard,
  notifications,
  T,
  type UploadHandler,
  UploadInput,
  useCustomizations,
  useModal,
} from '@mortgagehippo/ds';
import {
  ACCEPTED_DOCUMENT_MIME_TYPES,
  type ISendDocumentTask,
  isInsecureFileUploadError,
  useScanDocument,
  useSentDocuments,
  useTask,
} from '@mortgagehippo/tasks';
import { startCase } from '@mortgagehippo/util';
import axios, { type AxiosProgressEvent } from 'axios';
import { useCallback, useMemo, useState } from 'react';

import { type DocumentInput } from '../../../apollo/graphql';
import { useSendDocumentSubmitUrl } from '../../../pages/application/use-send-document-submit-url';
import { useUpdateSendDocumentTask } from '../../../pages/application/use-update-send-document-task';
import { CustomTaskInitialFields } from './custom-task-initial-fields';
import { type ICustomTaskTypeProps } from './types';

enum WizardSteps {
  TASK_FIELDS = 'task-fields',
  UPLOAD_DOCUMENTS = 'upload-documents',
}

interface ICustomSendDocumentTaskFormValues {
  description: string;
  title: string;
  documents: IFile[];
  due_date?: string | null;
}

export const CustomSendDocumentTask = (props: ICustomTaskTypeProps) => {
  const { taskId, applicationFileId, onDone, onCancel, showDueDate } = props;

  const [step, setStep] = useState<string>(WizardSteps.TASK_FIELDS);
  const [isOpen, , closeModal] = useModal(true);

  const [task, , taskLoading] = useTask<ISendDocumentTask>(taskId);
  const [taskDocuments, taskDocumentsLoading] = useSentDocuments(applicationFileId, taskId);

  const getSubmitUrl = useSendDocumentSubmitUrl();
  const updateSendDocumentTask = useUpdateSendDocumentTask();
  const scanDocument = useScanDocument();

  const customizations = useCustomizations();
  const title = customizations.text('custom_task:send_documents', 'Send Documents Task');

  const initialValues = useMemo(() => {
    if (task) {
      const documents: IFile[] = taskDocuments.map((d) => {
        const { id, filename, size, url, description } = d;

        return {
          id,
          filename: filename || '',
          size: size || undefined,
          url: url || undefined,
          description: description || '',
        };
      });

      return {
        title: task.meta.title,
        description: task.data.panel?.description,
        documents,
        due_date: task.dueDate,
      };
    }

    return {};
  }, [task, taskDocuments]);

  const handleStepChange = async (nextStep?: string) => {
    if (nextStep) {
      setStep(nextStep);
    }
  };

  const handleUpdate: FormSubmitHandler<ICustomSendDocumentTaskFormValues> = async (values) => {
    try {
      const { title: inputTitle, description: inputDescription, documents = [], due_date } = values;

      const inputDocuments: DocumentInput[] = documents.map(({ id, description }) => ({
        id: `${id}`,
        description,
      }));

      const data = {
        title: startCase(inputTitle)!,
        description: inputDescription!,
        documents: inputDocuments,
        dueDate: due_date,
      };

      await updateSendDocumentTask(taskId!, data);

      closeModal(() => {
        notifications.success({
          messageCid: 'custom_task:send_document_task_update_success_msg',
          message: 'Successfully updated task',
        });
        onDone();
      });
    } catch (e) {
      console.error(e);
      notifications.error({
        messageCid: 'custom_task:send_document_task_update_failure_msg',
        message: 'There was an unexpected error updating the task',
      });
    }
  };

  const handleUpload: UploadHandler = async (file, meta, progress) => {
    try {
      const { id: uploadId, postUrl } = await getSubmitUrl(applicationFileId, meta.filename);

      await axios.put(postUrl, file, {
        headers: {
          'Content-Type': meta.mime,
        },
        onUploadProgress: (e: AxiosProgressEvent) => {
          if (!e.total) return;
          const percent = Math.round((e.loaded * 100) / e.total);
          progress(percent);
        },
      });

      try {
        await scanDocument(uploadId);
      } catch (e: unknown) {
        if (isInsecureFileUploadError(e)) {
          return { id: uploadId, hasVirus: true };
        }
        throw e;
      }

      return { id: uploadId };
    } catch (e) {
      notifications.error({
        messageCid: 'custom_task:send_document_task_upload_failure_msg',
        message: 'There was an unexpected error while uploading your file',
      });
      throw e;
    }
  };

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  const loading = taskLoading || taskDocumentsLoading;

  return (
    <ModalWizard<ICustomSendDocumentTaskFormValues>
      loading={loading}
      width="wide"
      isOpen={isOpen}
      step={step}
      title={title}
      onRequestClose={handleCloseModal}
      onStepChange={handleStepChange}
      initialValues={initialValues}
      completeButtonLabel={taskId ? <T>Update task</T> : <T>Create task</T>}
      nextButtonLabel={<T>Continue</T>}
      prevButtonLabel={<T>Back</T>}
      onSubmit={handleUpdate}
      disableOverlayClickClose
    >
      <ModalWizard.Step id={WizardSteps.TASK_FIELDS} hidePrevButton>
        <CustomTaskInitialFields showDueDate={showDueDate} />
      </ModalWizard.Step>
      <ModalWizard.Step id={WizardSteps.UPLOAD_DOCUMENTS}>
        <UploadInput.Box
          name="documents"
          label="Documents"
          onUpload={handleUpload}
          required
          descriptionField
          accept={ACCEPTED_DOCUMENT_MIME_TYPES}
          fileTypeDescription="document"
        />
      </ModalWizard.Step>
    </ModalWizard>
  );
};
