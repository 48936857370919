import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../apollo';

const MTriggerMortgageflexDocumentPush = graphql(`
  mutation TriggerMortgageflexDocumentPush($applicationFileId: ID!, $documentId: ID!) {
    triggerMortgageFlexDocumentPush(applicationFileId: $applicationFileId, documentId: $documentId)
  }
`);

export const useTriggerMortgageflexDocumentPush = () => {
  const fn = useMutation(MTriggerMortgageflexDocumentPush, undefined);

  return useCallback(
    async (applicationFileId: string, documentId: string) => {
      const response = await fn({
        applicationFileId,
        documentId,
      });
      return response.data!.triggerMortgageFlexDocumentPush;
    },
    [fn]
  );
};
