import { ModalDialog, useCustomizations, useModal } from '@mortgagehippo/ds';
import { useSagaClickHandler } from '@mortgagehippo/tasks';
import { useCallback } from 'react';

import { type IActionProps, type IPushToLosAction } from '../types';
import { useTriggerLosPush } from './use-trigger-los-push';

export const PushToLosAction = (props: IActionProps<IPushToLosAction>) => {
  const { action, onSubmit, onDone, onCancel } = props;
  const { applicationFileId, losType, asyncLossExport } = action;

  const [isOpen, , closeModal] = useModal(true);

  const customizations = useCustomizations();
  const title = customizations.text('push-to-los:confirm-title', 'Are you sure?');
  const explanation = customizations.text(
    'push-to-los:confirm-explanation',
    'You are about to push the application to your LOS.'
  );

  const triggerLosPush = useTriggerLosPush(losType, asyncLossExport);

  const [handleOk, running] = useSagaClickHandler({
    infoMessageCid: 'push-to-los:info',
    successMessageCid: 'push-to-los:success',
    errorMessageCid: 'push-to-los:error',
    infoMessage: 'Pushing to LOS',
    successMessage: 'Successfully pushed to LOS',
    errorMessage: 'There was an unexpected error pushing to LOS',
    silent: asyncLossExport,
    async onClick(setAsyncId) {
      const asyncId = await triggerLosPush(applicationFileId);

      closeModal(() => {
        setAsyncId(asyncId);
        onSubmit();
      });
    },
    async onDone() {
      closeModal(onDone);
    },
  });

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  if (running) return null;

  // TODO: use hook useModalConfirm
  return (
    <ModalDialog
      type="dialog"
      title={title}
      onOk={handleOk}
      onRequestClose={handleCloseModal}
      isOpen={isOpen}
    >
      {explanation}
    </ModalDialog>
  );
};
