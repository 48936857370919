export class ManagedFolderUser {
  private id: string;
  private name: string;
  private email: string;

  private _isNew = false;
  private _isDeleted = false;

  public constructor(id: string, name: string, email: string) {
    this.id = id;
    this.name = name;
    this.email = email;
  }

  public softDelete() {
    if (this._isDeleted) {
      return false;
    }

    this._isDeleted = true;

    return true;
  }

  public restore() {
    if (!this._isDeleted) {
      return false;
    }

    this._isDeleted = false;

    return true;
  }

  public getId() {
    return this.id;
  }

  public getName() {
    return this.name;
  }

  public getEmail() {
    return this.email;
  }

  public isNew() {
    return this._isNew;
  }

  public isSoftDeleted(): boolean {
    return this._isDeleted;
  }

  public hasUnsavedChanges() {
    return this.isNew() || this.isSoftDeleted();
  }

  public static new(id: string, name: string, email: string) {
    const user = new ManagedFolderUser(id, name, email);

    user._isNew = true;

    return user;
  }
}
