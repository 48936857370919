import { useSagaProgressNotification } from '@mortgagehippo/tasks';
import { useEffect, useRef, useState } from 'react';

import { type IActionProps, type IPushDocumentToLosAction } from '../types';
import { useTriggerLosDocumentPush } from './use-trigger-los-document-push';

export const PushDocumentToLosAction = (props: IActionProps<IPushDocumentToLosAction>) => {
  const { action, onDone: _onDone } = props;
  const { applicationFileId, documentId, losType } = action;

  const onDoneRef = useRef(_onDone);

  const [asyncId, setAsyncId] = useState<string | undefined>(undefined);

  const triggerLosDocumentPush = useTriggerLosDocumentPush(losType);

  const [notification] = useSagaProgressNotification({
    asyncId,
    onDone: _onDone,
    errorMessageCid: 'los-push-document:error',
    errorMessage: 'There was an unexpected error pushing your document to the LOS',
  });

  const running = !!asyncId;

  useEffect(() => {
    onDoneRef.current = _onDone;
  }, [_onDone]);

  useEffect(() => {
    if (running) {
      return;
    }

    (async () => {
      try {
        const nextAsyncId = await triggerLosDocumentPush(applicationFileId, documentId);

        setAsyncId(nextAsyncId);
      } catch (e) {
        const onDone = onDoneRef.current;
        notification.error();
        onDone();
      }
    })();
  }, [applicationFileId, documentId, notification, running, triggerLosDocumentPush]);

  return null;
};
