import { type ITaskTemplate, TaskType } from '@mortgagehippo/tasks';
import { useMemo } from 'react';

import { useApplicationFileCan } from '$components/permissions';

import { useApplicationBlueprint } from '../../../pages/application/use-application-blueprint';
import { DEFAULT_TEMPLATES } from './constants';

export type IUseTaskTemplatesResult = [ITaskTemplate[], boolean];

export const useTaskTemplates = (applicationFileId: string): IUseTaskTemplatesResult => {
  const [can, canReady, { data: applicationFile }] = useApplicationFileCan(applicationFileId);

  const siteId = applicationFile ? Number(applicationFile.site.id) : undefined;

  const [blueprint, blueprintLoading] = useApplicationBlueprint(siteId!, {
    skip: !siteId,
  });

  const loading = !canReady || blueprintLoading;

  const templates: ITaskTemplate[] = useMemo(() => {
    if (!canReady) {
      return [];
    }

    const blueprintTemplates: ITaskTemplate[] = blueprint?.custom_task_templates?.v1?.items || [];

    const allTemplates = [...blueprintTemplates, ...DEFAULT_TEMPLATES];

    const nextTemplates = allTemplates.filter((template) => {
      const { type_name: typeName } = template;

      switch (typeName) {
        case TaskType.AssetsTask:
          return can.CREATE_ASSETS_TASK;
        case TaskType.PaymentTask:
          return can.CREATE_PAYMENT_TASK;
        case TaskType.HellosignTask:
          return can.CREATE_HELLOSIGN_TASK;
        case TaskType.DocusignTask:
          return can.CREATE_DOCUSIGN_TASK;
        default:
          return true;
      }
    });

    return nextTemplates;
  }, [blueprint, can, canReady]);

  return [templates, loading];
};
