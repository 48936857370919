import { Address, fontSize, fontWeight, Icon, palette, spacing, T } from '@mortgagehippo/ds';
import { type ILiability } from '@mortgagehippo/tasks';
import { isPresent } from '@mortgagehippo/util';
import { keyBy } from 'lodash-es';
import styled from 'styled-components';

import { LIABILITY_TYPE_OPTIONS } from './constants';
import { type IModel } from './util';

export const LiabilityType = styled.div`
  margin-bottom: ${spacing(3)};
  font-weight: ${fontWeight('semibold')};
  font-size: ${fontSize('normal')};
`;

const Row = styled.div`
  display: flex;
`;

const IconColumn = styled.div`
  padding-right: ${spacing(1)};
  color: ${palette('neutral600')};
  display: flex;
  align-items: center;
  flex: 0;
`;

const LabelColumn = styled.div`
  flex: 1;
`;

export interface ICreditLiabilityCreditorColProps {
  liability: ILiability;
  model: IModel;
}

const keyedLiabilityTypes = keyBy(LIABILITY_TYPE_OPTIONS, (o) => o.value);

export const CreditCheckLiabilitiesColType = (props: ICreditLiabilityCreditorColProps) => {
  const { liability, model } = props;
  const { type_name: typeName } = liability;

  if (!typeName) {
    return <>&mdash;</>;
  }

  const label = keyedLiabilityTypes[typeName]?.label || typeName;
  const isLoan = typeName === 'MortgageLoan';
  const isDuplicate = model.isDuplicateLiability(liability);
  const isExcludedUnknownStatus = model.isExcludedUnknownStatusLiability(liability);
  const realEstateOwned = model.getRealEstateOwnedForLiability(liability);

  return (
    <>
      <LiabilityType>{label}</LiabilityType>

      {isLoan ? (
        <>
          {!isPresent(realEstateOwned) && (
            <Row>
              <IconColumn>
                <Icon name="warning" size="sm" color="warning600" />
              </IconColumn>
              <LabelColumn>
                <T>No associated property</T>
              </LabelColumn>
            </Row>
          )}

          {isDuplicate ? (
            <Row>
              <IconColumn>
                <Icon name="copy" size="sm" color="warning600" />
              </IconColumn>
              <LabelColumn>
                <T>Possible duplicate liability</T>
              </LabelColumn>
            </Row>
          ) : null}

          {isPresent(realEstateOwned?.address) && (
            <Row>
              <IconColumn />
              <LabelColumn>
                <Address address={realEstateOwned!.address!} />
              </LabelColumn>
            </Row>
          )}
        </>
      ) : null}
      {isExcludedUnknownStatus ? (
        <Row>
          <IconColumn>
            <Icon name="warning" size="sm" color="warning600" />
          </IconColumn>
          <LabelColumn>
            <T>
              Unexpected account status &mdash; liability excluded from ratios. Edit to include in
              ratios.
            </T>
          </LabelColumn>
        </Row>
      ) : null}
    </>
  );
};
