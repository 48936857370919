import { type ITaskTemplate, TaskType } from '@mortgagehippo/tasks';

export const CUSTOM_TEMPLATE_KEY_PREFIX = '__CUSTOM';
export const CUSTOM_TEMPLATE_GROUP = 'Custom';
export const FALLBACK_TEMPLATE_GROUP = 'Other';

export const COMMON_APPLICANT_OPTION_VALUE = '-';

export const DEFAULT_CUSTOM_TASK_TITLE = 'Unnamed Task';

export const DEFAULT_TEMPLATES: ITaskTemplate[] = [
  {
    key: `${CUSTOM_TEMPLATE_KEY_PREFIX}:review_information`,
    title: 'Show Information',
    type_name: TaskType.InformationTask,
    group: CUSTOM_TEMPLATE_GROUP,
  },
  {
    key: `${CUSTOM_TEMPLATE_KEY_PREFIX}:send_document`,
    title: 'Send Document',
    type_name: TaskType.SendDocumentTask,
    group: CUSTOM_TEMPLATE_GROUP,
  },
  {
    key: `${CUSTOM_TEMPLATE_KEY_PREFIX}:assets`,
    title: 'Request Assets',
    type_name: TaskType.AssetsTask,
    group: CUSTOM_TEMPLATE_GROUP,
    description:
      'Now it’s time to tell us about your assets. We ask about money you have in the bank or retirement accounts because it may qualify you for a better rate.',
  },
  {
    key: `${CUSTOM_TEMPLATE_KEY_PREFIX}:request_document`,
    title: 'Request Document',
    type_name: TaskType.DocumentSubmissionTask,
    group: CUSTOM_TEMPLATE_GROUP,
  },
  {
    key: `${CUSTOM_TEMPLATE_KEY_PREFIX}:payment`,
    title: 'Request Payment',
    description: 'A payment is required for the items below.',
    type_name: TaskType.PaymentTask,
    group: CUSTOM_TEMPLATE_GROUP,
  },
  {
    key: `${CUSTOM_TEMPLATE_KEY_PREFIX}:e_sign_document_hellosign`,
    title: 'Sign Documents',
    type_name: TaskType.HellosignTask,
    group: CUSTOM_TEMPLATE_GROUP,
  },
  {
    key: `${CUSTOM_TEMPLATE_KEY_PREFIX}:e_sign_document_docusign`,
    title: 'Sign Documents',
    type_name: TaskType.DocusignTask,
    group: CUSTOM_TEMPLATE_GROUP,
  },
];
