import { T } from '@mortgagehippo/ds';

import {
  BlockContent,
  BlockContentContainer,
  BlockLabel,
  BlockSublabel,
  BlockValue,
  BlockValueLabel,
  SectionBlock,
} from '../common';
import { Dash } from '../dash';
import { type ILiabilitiesOverviewSectionApplicant } from './use-liabilities-overview-section';

export interface IApplicationOverviewLiabilitiesSectionEmptyCreditScoreProps {
  applicant: ILiabilitiesOverviewSectionApplicant;
  showNames?: boolean;
}

export const ApplicationOverviewLiabilitiesSectionEmptyCreditScore = (
  props: IApplicationOverviewLiabilitiesSectionEmptyCreditScoreProps
) => {
  const { applicant, showNames } = props;

  return (
    <SectionBlock hasBlockValueLabels>
      <BlockLabel>
        <T>Credit Scores</T>
        {showNames ? <BlockSublabel>{applicant.name}</BlockSublabel> : null}
      </BlockLabel>
      <BlockContentContainer>
        <BlockContent textAlign="center">
          <BlockValue>
            <Dash />
          </BlockValue>
          <BlockValueLabel>Equifax</BlockValueLabel>
        </BlockContent>
        <BlockContent textAlign="center">
          <BlockValue>
            <Dash />
          </BlockValue>
          <BlockValueLabel>Experian</BlockValueLabel>
        </BlockContent>
        <BlockContent textAlign="center">
          <BlockValue>
            <Dash />
          </BlockValue>
          <BlockValueLabel>Transunion</BlockValueLabel>
        </BlockContent>
      </BlockContentContainer>
    </SectionBlock>
  );
};
