import {
  fontWeight,
  Icon,
  type ITreeSelectOption,
  spacing,
  TreeSelect,
  useFormValue,
} from '@mortgagehippo/ds';
import { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { type IFolder } from './use-agent-modal-data';
import { filterSelectedFolders, foldersToOptions } from './util';

const FolderContainer = styled.div<{
  indented?: boolean;
}>`
  display: flex;
  align-items: center;
  margin-left: ${spacing(2)};
  font-weight: ${fontWeight('semibold')};
  font-style: italic;

  &:nth-of-type(3) {
    margin-bottom: ${spacing(4)} !important;
  }

  ${(p) =>
    p.indented &&
    css`
      margin-left: ${spacing(6)};
    `}

  > * {
    flex: 0 0 auto;
  }
`;

const FolderIcon = styled(Icon)`
  margin-right: ${spacing(1)};
  vertical-align: middle;
`;

const descriptionTooltip = (
  <>
    <p>Imagine this sample folder structure:</p>
    <FolderContainer>
      <FolderIcon name="folder-open" outline /> Parent Folder
    </FolderContainer>
    <FolderContainer indented>
      <FolderIcon name="folder" outline /> Subfolder A
    </FolderContainer>
    <FolderContainer indented>
      <FolderIcon name="folder" outline /> Subfolder B
    </FolderContainer>
    <p>
      Selecting{' '}
      <strong>
        <em>Parent Folder</em>
      </strong>{' '}
      is the same as selecting{' '}
      <strong>
        <em>Parent Folder</em>
      </strong>
      ,{' '}
      <strong>
        <em>Subfolder A</em>
      </strong>
      , and{' '}
      <strong>
        <em>Subfolder B</em>
      </strong>{' '}
      - either way will grant access to all 3 folders.
    </p>
    <p>
      Selecting{' '}
      <strong>
        <em>Subfolder A</em>
      </strong>{' '}
      will grant access only to{' '}
      <strong>
        <em>Subfolder A</em>
      </strong>
      .
    </p>
    <p>
      Selecting{' '}
      <strong>
        <em>Subfolder A</em>
      </strong>{' '}
      and{' '}
      <strong>
        <em>Subfolder B</em>
      </strong>{' '}
      will grant access to{' '}
      <strong>
        <em>Subfolder A</em>
      </strong>{' '}
      and{' '}
      <strong>
        <em>Subfolder B</em>
      </strong>{' '}
      , but not to{' '}
      <strong>
        <em>Parent Folder</em>
      </strong>{' '}
      .
    </p>
  </>
);

interface IAgentModalFoldersSectionProps {
  folders: IFolder[];
}

export const AgentModalFoldersSection = (props: IAgentModalFoldersSectionProps) => {
  const { folders } = props;

  const [defaultFolderOptions, setDefaultFolderOptions] = useState<ITreeSelectOption[]>([]);

  const [agentFolderFieldValue] = useFormValue<string[]>('folders');
  const [defaultFolderFieldValue, setDefaultFolderFieldValue] = useFormValue<string | undefined>(
    'defaultFolderId'
  );

  const folderOptions = useMemo(() => foldersToOptions(folders), [folders]);

  useEffect(() => {
    // reset value if new options is empty array
    if (!agentFolderFieldValue?.length) {
      setDefaultFolderFieldValue(undefined);
      setDefaultFolderOptions([]);
      return;
    }

    // if no existing default folder, always populate from first selected folder id (first in order on the input field)
    if (!defaultFolderFieldValue) {
      setDefaultFolderFieldValue(agentFolderFieldValue[0]);
      setDefaultFolderOptions(
        foldersToOptions(filterSelectedFolders(folders, agentFolderFieldValue))
      );
      return;
    }

    const filteredAgentFolders = filterSelectedFolders(folders, agentFolderFieldValue);

    // if existing default folder, reset value if old value is not in new options
    if (
      !filteredAgentFolders.find((filteredFolder) => filteredFolder.id === defaultFolderFieldValue)
    ) {
      setDefaultFolderFieldValue(agentFolderFieldValue[0]);
    }

    setDefaultFolderOptions(foldersToOptions(filteredAgentFolders));
  }, [agentFolderFieldValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const isDefaultFolderDisabled =
    defaultFolderOptions.length === 1 && !defaultFolderOptions[0]?.children?.length;

  return (
    <>
      <TreeSelect.Box
        name="folders"
        label="Folders"
        description="All folders inside of a selected folder will be included."
        descriptionTooltip={descriptionTooltip}
        options={folderOptions}
        allowClear
        leafNodeIconProps={{ name: 'folder', outline: true }}
        multiple
        searchable
        treeIconProps={{ name: 'folder', outline: true }}
        treeIconExpandedProps={{ name: 'folder-open', outline: true }}
      />

      {!!defaultFolderOptions.length && (
        <TreeSelect.Box
          name="defaultFolderId"
          label="Default Folder"
          options={defaultFolderOptions}
          disabled={isDefaultFolderDisabled}
          required={!!agentFolderFieldValue.length}
          leafNodeIconProps={{ name: 'folder', outline: true }}
          treeIconProps={{ name: 'folder', outline: true }}
          treeIconExpandedProps={{ name: 'folder-open', outline: true }}
          allowClear
          expandAll
          searchable
        />
      )}
    </>
  );
};
