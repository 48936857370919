import { AuthEventType } from '@mortgagehippo/auth';
import { TraceContext } from '@mortgagehippo/util';
import * as Sentry from '@sentry/browser';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, Observable, split } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { ErrorLink } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { RetryLink } from 'apollo-link-retry';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import { store } from '$components/actionable';

import { config } from '../config';
import { auth } from '../services/auth';

const cache = new InMemoryCache({});

const errorLink = new ErrorLink(({ graphQLErrors, operation, forward }) => {
  // Determine if we need authentication
  const needsAuthentication =
    !!graphQLErrors &&
    graphQLErrors.some((e) => e.extensions && e.extensions.code === 'UNAUTHENTICATED');

  const { response } = operation.getContext();
  const requestId = response?.headers?.get('x-req-id');

  if (!needsAuthentication) {
    return undefined;
  }

  return new Observable((observer) => {
    (async () => {
      try {
        // Get next token
        const nextToken = await auth.refreshSession();
        if (!nextToken) {
          throw new Error("Couldn't refresh token");
        }

        // Update the token
        operation.setContext((prevContext: any) => ({
          headers: {
            ...(prevContext.headers || {}),
            authorization: `Bearer ${nextToken}`,
          },
        }));

        // Retry last failed request
        const subscriber = {
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        };

        forward(operation).subscribe(subscriber);
      } catch (e) {
        observer.error(e);
        Sentry.captureException(e, {
          tags: {
            requestId,
          },
        });
      }
    })();
  });
});

const httpLink = new HttpLink({
  uri: config.GRAPHQL_ENDPOINT,
  fetch,
});

const subscriptionClient = new SubscriptionClient(config.GRAPHQL_SUBSCRIPTION_ENDPOINT, {
  lazy: true,
  reconnect: true,
  connectionParams: async () => {
    const token = await auth.getOrRefreshAccessToken();

    return {
      token,
    };
  },
});

const wsLink = new WebSocketLink(subscriptionClient);

const authLink = setContext(async () => {
  const token = await auth.getOrRefreshAccessToken();

  if (token) {
    return {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
  }

  return {};
});

/*
 * Create W3C compliant traceparent/tracestate headers
 * Use Trace ID for the request ID
 */
const requestIdLink = setContext((_operation, previousContext) => {
  const { headers } = previousContext;

  const traceContext = TraceContext.new();

  return {
    ...previousContext,
    headers: {
      ...headers,
      ...traceContext.getHeaders(),
      'x-req-id': traceContext.getTraceId(),
    },
  };
});

const networkLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink
);

const retryLink = new RetryLink({
  attempts: {
    max: 5,
    retryIf: (error, { query }) => {
      const definition = getMainDefinition(query);
      if (definition.kind === 'OperationDefinition' && definition.operation === 'mutation') {
        return false;
      }
      return !!error;
    },
  },
});

export const client = new ApolloClient({
  link: ApolloLink.from([retryLink as any, errorLink, authLink, requestIdLink, networkLink]),
  cache,
  connectToDevTools: process.env.NODE_ENV !== 'production',
});

let previousUser: any;
auth.subscribe((event: AuthEventType | null, _state, currentUser: any) => {
  const isSameUser =
    (!currentUser && !previousUser) ||
    (currentUser &&
      previousUser &&
      currentUser.type_name === previousUser.type_name &&
      currentUser.id === previousUser.id);

  if (isSameUser) {
    return;
  }

  // Reload the subscription after authenticating
  subscriptionClient.close(false, true);

  if (event === AuthEventType.AUTHENTICATED) {
    store.startSubscription();
  }

  previousUser = currentUser;

  /*
   * This crashes the app, it would be good to do
   * but may not be needed since logging out
   * redirects you to auth0
   * clearing the store
   * client.resetStore();
   */
});
