import { notifications } from '@mortgagehippo/ds';
import { useCallback, useEffect, useState } from 'react';

import {
  ActionStatus,
  ActionType,
  type IRefreshMortgageFlexLoanStatusAction,
  useActionEffect,
  useDispatchAction,
} from '$components/actions';
import { useApplicationFileCan } from '$components/permissions';

import { usePartner } from '../../../../hooks/use-partner';
import { useLenderMilestones } from '../../use-lender-milestones';
import { useUpdateLenderMilestones } from '../../use-update-lender-milestones';
import { ApplicationLenderMilestonesContent } from './application-lender-milestones-content';

interface IApplicationLenderMilestonesProps {
  applicationFileId: string;
}

export const ApplicationLenderMilestones = (props: IApplicationLenderMilestonesProps) => {
  const { applicationFileId } = props;

  const [autoRefreshed, setAutoRefreshed] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const [{ milestones, pushData }, milestonesLoading, refetchLenderMilestones] =
    useLenderMilestones(applicationFileId);
  const [partnerData, partnerDataLoading] = usePartner();
  const updateLenderMilestones = useUpdateLenderMilestones(applicationFileId);

  const [can, canReady] = useApplicationFileCan(applicationFileId);
  const canRefreshMilestones = canReady && can.REFRESH_MORTGAGEFLEX_LOAN_STATUS;
  const canEditLenderMilestones = canReady && can.UPDATE_APPLICATION_LENDER_MILESTONES;

  const dispatch = useDispatchAction();

  const shouldRefreshMilestones = !!milestones.length && canRefreshMilestones && !!pushData?.pushed;
  const hasExternalLosMilestones = milestones.some(
    (milestone) => !!milestone.externalMilestoneKeyId
  );

  const handleRefreshLenderMilestones = useCallback(() => {
    dispatch({
      type: ActionType.REFRESH_MORTGAGEFLEX_STATUS,
      applicationFileId,
    });
  }, [applicationFileId, dispatch]);

  const handleSave = useCallback(
    async (completedMilestoneIds: string[], activeMilestoneId?: string) => {
      try {
        await updateLenderMilestones(completedMilestoneIds, activeMilestoneId);
        await refetchLenderMilestones();
        notifications.success({
          message: 'Successfully updated LOS milestones.',
          messageCid:
            'pageApplication:tabMilestones.lenderMilestones.saveLosMilestonesSuccessNotification',
        });
        return true;
      } catch (e) {
        notifications.error({
          message: 'Error saving LOS milestones.',
          messageCid:
            'pageApplication:tabMilestones.lenderMilestones.saveLosMilestonesFailureNotification',
        });
        return false;
      }
    },
    [refetchLenderMilestones, updateLenderMilestones]
  );

  useEffect(() => {
    if (!shouldRefreshMilestones) {
      return;
    }

    dispatch({
      type: ActionType.REFRESH_MORTGAGEFLEX_STATUS,
      applicationFileId,
      silent: true,
    } as IRefreshMortgageFlexLoanStatusAction);
  }, [applicationFileId, dispatch, shouldRefreshMilestones]);

  useEffect(() => {
    setAutoRefreshed(false);
  }, [applicationFileId]);

  useActionEffect<IRefreshMortgageFlexLoanStatusAction>(
    async (action, status) => {
      const { applicationFileId: actionApplicationFileId } = action;

      if (actionApplicationFileId !== applicationFileId) {
        // this action belongs to another application file ID
        return;
      }

      if (status === ActionStatus.OPEN) {
        setRefreshing(true);
        return;
      }

      if (status === ActionStatus.DONE) {
        await refetchLenderMilestones();
        setAutoRefreshed(true);
        setRefreshing(false);
      }
    },
    ActionType.REFRESH_MORTGAGEFLEX_STATUS,
    undefined
  );

  const loading =
    !canReady ||
    milestonesLoading ||
    partnerDataLoading ||
    refreshing ||
    (shouldRefreshMilestones && !autoRefreshed);
  const showRefreshButton = hasExternalLosMilestones && canRefreshMilestones;
  const allowEditing = canEditLenderMilestones && !hasExternalLosMilestones;
  const sequentialLenderMilestones = partnerData?.sequentialLenderMilestones;

  return (
    <ApplicationLenderMilestonesContent
      milestones={milestones}
      loading={loading}
      canRefreshMilestones={showRefreshButton}
      onLosLoanStatusRefresh={handleRefreshLenderMilestones}
      canEditMilestones={allowEditing}
      onSave={handleSave}
      sequentialLenderMilestones={sequentialLenderMilestones}
    />
  );
};
