import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { graphql } from '../../../apollo';
import { type ApplicationFileFoldersQuery } from '../../../apollo/graphql';

export const QApplicationFileFolders = graphql(`
  query ApplicationFileFolders($applicationFileId: ID!, $includeChildren: Boolean) {
    applicationFile(id: $applicationFileId) {
      id
      folder {
        id
        name
        path
      }
      primaryAgent {
        id
        folders(includeChildren: $includeChildren, orderBy: { name: "asc" }) {
          id
          name
          path
        }
      }
    }
  }
`);

export type IApplicationFileFolders = NonNullable<ApplicationFileFoldersQuery['applicationFile']>;

export const useApplicationFileFolders = (
  applicationFileId: string,
  options: IUseApolloQueryOptions = {}
) => {
  const [data, loading, , { refetch }] = useQuery(
    QApplicationFileFolders,
    {
      applicationFileId,
      includeChildren: true,
    },
    {
      ...options,
      suspend: false,
      fetchPolicy: 'network-only',
      skip: !applicationFileId,
    }
  );

  const handleRefresh = useSafeCallback(async () => refetch({ applicationFileId }));
  return [{ data: data?.applicationFile, loading }, handleRefresh] as const;
};
