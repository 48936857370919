import {
  Avatar,
  Button,
  Dropdown,
  fontSize,
  lineHeight,
  MediaBreakpoint,
  palette,
  spacing,
  T,
  Title,
  useCustomizations,
  useResponsive,
} from '@mortgagehippo/ds';
import { forwardRef, useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { ActionType, useDispatchAction } from '$components/actions';
import { useUserCan } from '$components/permissions';

import { useUser } from '../../hooks/use-user';
import { NotificationsButton } from './notifications-button';

const Container = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${palette('neutral100')};
  padding: ${spacing(1)} 0 ${spacing(1)} ${spacing(4)};
  width: 100%;
  z-index: 10;

  // for IE11 only since it does not support position: sticky
  @media all and (-ms-high-contrast: none) {
    position: relative;
    border: none; // border set in similar rule in MainLayout component (BoxPadding)
    background: transparent; // background must be off as it is cutout during x-scroll
  }

  ${MediaBreakpoint.PHONE} {
    position: static;
    width: auto;
    padding: ${spacing(2)} 0 ${spacing(1)} 0;
    flex-flow: column;
  }
`;

const Menu = styled.div`
  flex: 0 0 auto;
  display: flex;
  white-space: nowrap;
  margin-left: auto;
`;

const DropdownLabel = styled.span`
  vertical-align: middle;
`;

const StyledTitle = styled(Title)`
  font-size: ${fontSize('lg')};
  line-height: ${lineHeight('lg')};
  margin: 0 0 0 ${spacing(4)};
  padding: 0;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;

  ${MediaBreakpoint.PHONE} {
    margin-left: ${spacing(5)};
    width: 100%;
  }
`;

export interface ITopBarProps {
  menuOpen: boolean;
  handleMenuToggle: () => void;
  pageTitle?: string;
}

export const TopBar = forwardRef<HTMLDivElement, ITopBarProps>((props, ref) => {
  const { menuOpen, handleMenuToggle, pageTitle } = props;

  const [can] = useUserCan();
  const dispatch = useDispatchAction();
  const [user] = useUser();
  const responsive = useResponsive();

  const customizations = useCustomizations();
  const chatDisabled = customizations.bool('app:chat.disabled', true);
  const notesDisabled = customizations.bool('app:notes.disabled', true);
  const notificationsDisabled = chatDisabled && notesDisabled;

  const handleNewApplicationClick = useCallback(() => {
    dispatch({
      type: ActionType.CREATE_APPLICATION,
    });
  }, [dispatch]);

  const handleImportMismoClick = useCallback(() => {
    dispatch({
      type: ActionType.IMPORT_MISMO,
    });
  }, [dispatch]);

  const handleShareAssignmentLinkClick = useCallback(() => {
    if (!user) {
      return;
    }
    dispatch({
      type: ActionType.SHARE_ASSIGNMENT_LINK,
      agentId: user.id,
    });
  }, [dispatch, user]);

  const dropdownButtonLabel = useMemo(
    () => (
      <>
        {responsive.PHONE.BIGGER ? (
          <Avatar
            size="xs"
            name={(user && user.name) || undefined}
            icon={!(user && user.name) ? 'user' : undefined}
            alt="user avatar"
            src={user ? user.avatarUrl : null}
          />
        ) : null}
        <DropdownLabel>
          {(user && user.name) || <T cid="header:profile.label">Profile</T>}
        </DropdownLabel>
      </>
    ),
    [user, responsive.PHONE.BIGGER]
  );

  const menuToggleButton = useMemo(
    () =>
      responsive.PHONE.BIGGER ? (
        <Button
          icon={menuOpen ? 'slide-left' : 'menu'}
          onClick={handleMenuToggle}
          iconButton
          importance="tertiary"
          type="neutral"
          size="xs"
          compact
        >
          {menuOpen ? 'close' : 'open'} side navigation
        </Button>
      ) : null,
    [menuOpen, responsive.PHONE.BIGGER, handleMenuToggle]
  );

  return (
    <Container ref={ref}>
      {menuToggleButton}
      <StyledTitle>{pageTitle}</StyledTitle>
      <Menu>
        {can.CREATE_APPLICATION_FILE ? (
          <Button
            type="neutral"
            importance="tertiary"
            icon="plus"
            size={responsive.PHONE.EXACT_OR_SMALLER ? 'xs' : 'sm'}
            compact
            onClick={handleNewApplicationClick}
          >
            New application
          </Button>
        ) : null}
        {can.CREATE_APPLICATION_IMPORT ? (
          <Button
            type="neutral"
            importance="tertiary"
            icon="upload"
            size={responsive.PHONE.EXACT_OR_SMALLER ? 'xs' : 'sm'}
            compact
            onClick={handleImportMismoClick}
          >
            Import from MISMO 3.4
          </Button>
        ) : null}
        <Button
          type="neutral"
          importance="tertiary"
          icon="share"
          size={responsive.PHONE.EXACT_OR_SMALLER ? 'xs' : 'sm'}
          compact
          onClick={handleShareAssignmentLinkClick}
          tooltip='Share my "Start Application" link with prospect borrowers to start an application assigned to me'
          iconButton
        />
        {notificationsDisabled ? null : <NotificationsButton />}
        <Dropdown
          buttonProps={{
            size: responsive.PHONE.EXACT_OR_SMALLER ? 'xs' : 'sm',
            type: 'neutral',
            importance: 'tertiary',
            icon: 'down-arrow-small',
            iconLocation: 'right',
            compact: true,
          }}
          label={dropdownButtonLabel}
          actions={[
            {
              key: 'account',
              to: '/account/settings',
              label: 'Account settings',
            },
            {
              key: 'logout',
              to: '/logout',
              label: 'Sign out',
            },
          ]}
        />
      </Menu>
    </Container>
  );
});
