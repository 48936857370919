import { Spinner } from '@mortgagehippo/ds';
import styled from 'styled-components';

import { Content, Layout } from '../../../layouts/application';

const SpinnerWrapper = styled('div')`
  position: relative;
  min-height: 100%;
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
  margin: auto;
  top: 70vh;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
`;

export const ApplicationInvitesSkeleton = () => (
  <Layout>
    <Content>
      <SpinnerWrapper>
        <StyledSpinner type="spinner1" color="neutral400" size="xxxl" />
      </SpinnerWrapper>
    </Content>
  </Layout>
);
