import {
  Button,
  ButtonRouterLink,
  Card,
  Divider,
  Format,
  MediaBreakpoint,
  palette,
  spacing,
  T,
} from '@mortgagehippo/ds';
import { orderBy } from 'lodash-es';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import {
  ActionStatus,
  ActionType,
  type IRefreshMortgageFlexLoanStatusAction,
  useActionEffect,
  useDispatchAction,
} from '../../../../../components/actions';
import { LenderMilestonesList } from '../../../../../components/lender-milestones/lender-milestones-list';
import { useApplicationFileCan } from '../../../../../components/permissions';
import { useLenderMilestones } from '../../../use-lender-milestones';
import { ApplicationOverviewContext } from '../../application-overview-context';
import { type IApplicationOverviewSectionProps } from '../../types';
import {
  BlockContent,
  BlockContentContainer,
  BlockLabel,
  BlockValue,
  SectionBlock,
  SectionFooter,
} from '../common';
import { Dash } from '../dash';

const LenderMilestoneSectionBlock = styled(SectionBlock)`
  flex-wrap: wrap;
`;

const ActiveMilestone = styled(BlockContentContainer)`
  display: block;

  ${MediaBreakpoint.TABLET} {
    padding-top: ${spacing(2)};
    padding-bottom: ${spacing(2)};
  }
`;

const LastUpdated = styled.span`
  color: ${palette('neutral600')};
`;

export const ApplicationOverviewLenderMilestonesSection = (
  props: IApplicationOverviewSectionProps
) => {
  const { id, applicationFileId, className } = props;

  const [autoRefreshed, setAutoRefreshed] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const { sectionReady, sectionDisabled, ready } = useContext(ApplicationOverviewContext)!;

  const [{ milestones, pushData }, milestonesLoading, refetch] =
    useLenderMilestones(applicationFileId);

  const [can, canReady] = useApplicationFileCan(applicationFileId);

  const canRefreshMilestones = canReady && can.REFRESH_MORTGAGEFLEX_LOAN_STATUS;
  const canViewLenderMilestones = canReady && can.VIEW_LENDER_MILESTONES;
  const canManageLenderMilestones = canReady && can.UPDATE_APPLICATION_LENDER_MILESTONES;

  const dispatch = useDispatchAction();

  const shouldRefreshMilestones = milestones.length && canRefreshMilestones && !!pushData?.pushed;

  const sectionLoading =
    !canReady || milestonesLoading || (shouldRefreshMilestones && !autoRefreshed);

  const isDisabled = !sectionLoading && (!milestones.length || !canViewLenderMilestones);

  const handleRefreshLenderMilestones = useCallback(() => {
    dispatch({
      type: ActionType.REFRESH_MORTGAGEFLEX_STATUS,
      applicationFileId,
    });
  }, [applicationFileId, dispatch]);

  const { activeMilestone, lastCompletedMilestone } = useMemo(() => {
    const nextActiveMilestone = milestones.find((m) => m.active);
    const completedMilestones = milestones.filter((m) => m.completed);
    const nextLastCompletedMilestone = orderBy(completedMilestones, ['updatedOn'], ['desc'])[0];

    return {
      activeMilestone: nextActiveMilestone,
      lastCompletedMilestone: nextLastCompletedMilestone,
    };
  }, [milestones]);

  useEffect(() => {
    if (!shouldRefreshMilestones) {
      return;
    }

    dispatch({
      type: ActionType.REFRESH_MORTGAGEFLEX_STATUS,
      applicationFileId,
      silent: true,
    } as IRefreshMortgageFlexLoanStatusAction);
  }, [applicationFileId, dispatch, shouldRefreshMilestones]);

  useEffect(() => {
    setAutoRefreshed(false);
  }, [applicationFileId]);

  useActionEffect<IRefreshMortgageFlexLoanStatusAction>(
    async (action, status) => {
      const { applicationFileId: actionApplicationFileId } = action;

      if (actionApplicationFileId !== applicationFileId) {
        // this action belongs to another application file ID
        return;
      }

      if (status === ActionStatus.OPEN) {
        setRefreshing(true);
        return;
      }

      if (status === ActionStatus.DONE) {
        await refetch();
        setAutoRefreshed(true);
        setRefreshing(false);
      }
    },
    ActionType.REFRESH_MORTGAGEFLEX_STATUS,
    undefined
  );

  useEffect(() => {
    if (sectionLoading) {
      return;
    }

    if (isDisabled) {
      sectionDisabled(id);
      return;
    }

    sectionReady(id);
  }, [sectionLoading, id, sectionReady, sectionDisabled, isDisabled]);

  if (!ready || isDisabled) {
    return null;
  }

  const manageUrl = `#/applications/${applicationFileId}/milestones`;
  const lastUpdated = lastCompletedMilestone?.updatedOn;

  return (
    <Card compact className={className}>
      <LenderMilestoneSectionBlock>
        <BlockLabel maxWidth>
          <T cid="pageApplication:tabOverview.sections.losMilestones.title">LOS Milestones</T>
        </BlockLabel>
        <ActiveMilestone>
          <BlockContent>
            <BlockValue fontSize="normal">{activeMilestone?.name || <Dash />}</BlockValue>
            <LenderMilestonesList milestones={milestones} />
          </BlockContent>
        </ActiveMilestone>
      </LenderMilestoneSectionBlock>
      {shouldRefreshMilestones ? (
        <>
          <Divider compact />
          <SectionFooter>
            {lastUpdated ? (
              <LastUpdated>
                Last updated: <Format.Date value={lastUpdated} format="fromnow" />
              </LastUpdated>
            ) : null}
            <Button
              icon="refresh"
              iconLocation="left"
              onClick={handleRefreshLenderMilestones}
              type="default"
              importance="tertiary"
              size="xxs"
              compact
              loading={refreshing}
            >
              Update from LOS
            </Button>
          </SectionFooter>
        </>
      ) : null}
      {canManageLenderMilestones ? (
        <>
          <Divider compact />
          <SectionFooter>
            <ButtonRouterLink
              to={manageUrl}
              icon="right-arrow"
              iconLocation="right"
              importance="tertiary"
              size="xs"
              compact
            >
              Manage milestones
            </ButtonRouterLink>
          </SectionFooter>
        </>
      ) : null}
    </Card>
  );
};
