import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { graphql } from '../../../../../apollo';

const QLoanOverviewSection = graphql(`
  query LoanOverviewSection($applicationFileId: ID!) {
    applicationFile(id: $applicationFileId) {
      id
      calculations {
        propertyValuationAmount
        downPayment
        downPaymentPercent
        loanAmount
      }
    }
  }
`);

export const useLoanOverviewSection = (
  applicationFileId: string,
  options: IUseApolloQueryOptions = {}
) => {
  const [data, loading, , { refetch }] = useQuery(
    QLoanOverviewSection,
    { applicationFileId },
    {
      fetchPolicy: 'network-only',
      ...options,
    }
  );

  const handleRefetch = useSafeCallback(() => refetch({ applicationFileId }));

  const calculations = data?.applicationFile?.calculations;

  return [{ calculations }, loading, handleRefetch] as const;
};
