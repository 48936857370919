import { graphql } from '../../apollo';

export const QAgentsSelect = graphql(`
  query AgentsSelect($partnerId: ID!, $siteId: ID, $jobFunctionId: ID, $search: String) {
    agents(
      conditions: {
        partnerId: $partnerId
        siteId: $siteId
        jobFunctionId: $jobFunctionId
        search: $search
      }
    ) {
      items {
        id
        name
      }
    }
  }
`);

export const QAgentSelect = graphql(`
  query AgentSelect($value: ID!) {
    agent(id: $value) {
      id
      name
    }
  }
`);
