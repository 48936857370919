import { type RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import { Content, Layout, LAYOUT_DIMENSIONS } from '../../../layouts/application';
import { ApplicationOverview } from './application-overview';

const LayoutContent = styled(Content)`
  position: relative;
`;

const ContentWrapper = styled('div')`
  max-width: ${LAYOUT_DIMENSIONS.extendedMaxContentWidth}px;
`;

interface IApplicationOverviewRouteParams {
  applicationFileId: string;
}

export const ApplicationOverviewRoute = (
  props: RouteComponentProps<IApplicationOverviewRouteParams>
) => {
  const { match } = props;
  const { params } = match;
  const { applicationFileId } = params;

  return (
    <Layout tutorialId="overview" applicationFileId={applicationFileId}>
      <LayoutContent>
        <ContentWrapper>
          <ApplicationOverview applicationFileId={applicationFileId} />
        </ContentWrapper>
      </LayoutContent>
    </Layout>
  );
};
