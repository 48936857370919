import { graphql } from '../../apollo';

export const QSmartviewData = graphql(`
  query SmartviewData(
    $partnerId: ID!
    $model: SmartviewModelType!
    $smartviewId: ID
    $page: Int!
    $perPage: Int!
    $criteria: [SearchCriteria!]
    $orderBy: JSON
    $ensureId: ID
    $timezone: String
  ) {
    partner(id: $partnerId) {
      id
      smartview(id: $smartviewId, model: $model) {
        id
        data(
          settings: {
            options: { page: $page, perPage: $perPage, orderBy: $orderBy }
            criteria: $criteria
            ensureId: $ensureId
            timezone: $timezone
          }
        ) {
          itemsTotal
          itemsCount
          pageNumber
          pageCount
          items {
            id
            data
          }
        }
      }
    }
  }
`);

export const QSmartviewChoices = graphql(`
  query SmartviewChoices(
    $partnerId: ID!
    $model: SmartviewModelType!
    $smartviewId: ID
    $token: String!
  ) {
    partner(id: $partnerId) {
      id
      smartview(id: $smartviewId, model: $model) {
        id
        choices(token: $token)
      }
    }
  }
`);
