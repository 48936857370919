import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { graphql } from '../apollo';

const QMilestones = graphql(`
  query Milestones($applicationFileId: ID!) {
    applicationFile(id: $applicationFileId) {
      id
      milestones {
        id
        key
        name
        position
        active
      }
    }
  }
`);

export const useMilestones = (applicationFileId: string, options: IUseApolloQueryOptions = {}) => {
  const [data, loading, , { refetch }] = useQuery(
    QMilestones,
    { applicationFileId },
    {
      ...options,
      suspend: false,
      fetchPolicy: 'network-only',
    }
  );

  const handleRefresh = useSafeCallback(() => refetch({ applicationFileId }));

  const milestones = data?.applicationFile?.milestones || [];

  return [milestones, loading, handleRefresh] as const;
};
