import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../apollo';

const QPartnerReports = graphql(`
  query PartnerReports($partnerId: ID!) {
    partner(id: $partnerId) {
      id
      reportDashboards
    }
  }
`);

export const usePartnerReportDashboards = (
  partnerId: string,
  options: IUseApolloQueryOptions = {}
) => {
  const [data, loading] = useQuery(
    QPartnerReports,
    {
      partnerId,
    },
    options
  );

  const dashboards = !loading ? data?.partner?.reportDashboards : undefined;

  return [dashboards, loading] as const;
};
