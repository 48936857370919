import { useMutation, useQuery } from '@mortgagehippo/apollo-hooks';
import { get } from 'lodash-es';
import { useCallback } from 'react';

import { graphql } from '../apollo';

/*
 * if we use onboardingFlag(flag: $flag) we wouldn't be able to use the apollo cache (the value of
 * one flag would override the other). Also just loading all the flags is better for performance
 * (the query will only happen once)
 */
const QUserOnboardingFlag = graphql(`
  query UserOnboardingFlag {
    me {
      id
      onboardingFlags
    }
  }
`);

const MSetOnboardingFlag = graphql(`
  mutation SetOnboardingFlag($flag: String!, $value: Boolean!) {
    setOnboardingFlag(flag: $flag, value: $value) {
      id
      onboardingFlags
    }
  }
`);

export const useOnboardingFlag = (flagId: string | undefined) => {
  const [data, loading] = useQuery(QUserOnboardingFlag, {});

  const setOnboardingFlag = useMutation(MSetOnboardingFlag);

  const setFlag = useCallback(
    async (value: boolean) => {
      if (!flagId) return;
      await setOnboardingFlag({
        flag: flagId,
        value,
      });
    },
    [flagId, setOnboardingFlag]
  );

  const flags = data?.me?.onboardingFlags || {};
  const flag = flagId ? get(flags, flagId, false) : false;

  return [flag, setFlag, loading] as const;
};
