import { withFallback } from '@mortgagehippo/apollo-hooks';
import { Empty } from '@mortgagehippo/ds';
import styled from 'styled-components';

import { SiteSelect } from '$components/site-select';

const SiteSelectWrapper = styled.div`
  max-width: 400px;
  margin: 0 auto;
`;

export interface ISiteSelectSkeletonProps {
  onSiteChange: (nextSiteId: string) => void;
  description?: string;
}

const SiteSelectSkeletonWithBoundary = (props: ISiteSelectSkeletonProps) => {
  const { description, onSiteChange } = props;

  return (
    <Empty>
      {description || 'Choose a site to continue'}
      <SiteSelectWrapper>
        <SiteSelect.Input
          name="site"
          value=""
          onChange={onSiteChange}
          placeholder="Site"
          size="sm"
        />
      </SiteSelectWrapper>
    </Empty>
  );
};

export const SiteSelectSkeleton = withFallback<ISiteSelectSkeletonProps>(() => null)(
  SiteSelectSkeletonWithBoundary
);
