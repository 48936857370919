import { createContext } from 'react';

import { type Action, type ActionListener, type ActionStatus, type ActionType } from './types';

export interface IApplicationActionsContext {
  dispatch(action: Action, cb?: ActionListener): void;
  subscribe(listener: ActionListener<any>, type?: ActionType, status?: ActionStatus): () => void;
}

export const ApplicationActionsContext = createContext<IApplicationActionsContext | null>(null);
