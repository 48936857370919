import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../apollo';

const MTriggerMortgageBotPush = graphql(`
  mutation TriggerMortgageBotPushMutation($applicationFileId: ID!) {
    triggerMortgageBotPush(applicationFileId: $applicationFileId)
  }
`);

export const useTriggerMortgageBotPush = () => {
  const fn = useMutation(MTriggerMortgageBotPush, undefined);

  return useCallback(
    async (applicationFileId: string) => {
      const response = await fn({ applicationFileId });
      return response.data!.triggerMortgageBotPush;
    },
    [fn]
  );
};
