import { type ISubmitButtonProps, paletteAlpha, spacing, SubmitButton } from '@mortgagehippo/ds';
import { type ReactNode } from 'react';
import styled from 'styled-components';

import { LAYOUT_DIMENSIONS } from '../../../layouts/application';

const FixedBar = styled.div`
  position: sticky;
  bottom: -${spacing(LAYOUT_DIMENSIONS.sideNavInnerPaddingSpacing.bottom)};
  // prettier-ignore
  margin: 0 -${spacing(LAYOUT_DIMENSIONS.sideNavInnerPaddingSpacing.horizontal)};
  // TODO: the padding is based on the layout's nav padding, find a better way
  padding: ${spacing(3)} ${spacing(3)} ${spacing(4)};
  background-color: ${paletteAlpha('neutral50', 5)};
  backdrop-filter: blur(4px);
`;

interface IApplicationPricingScenarioSubmitProps extends ISubmitButtonProps {
  children: ReactNode;
}

export const ApplicationPricingFormSubmit = (props: IApplicationPricingScenarioSubmitProps) => {
  const { children, ...rest } = props;

  return (
    <FixedBar>
      <SubmitButton importance="primary" size="sm" compact block {...rest}>
        {children}
      </SubmitButton>
    </FixedBar>
  );
};
