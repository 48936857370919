import { fontWeight, Format, Tag } from '@mortgagehippo/ds';
import { isNaN, isPlainObject, isString, reduce } from 'lodash-es';
import { type ReactElement } from 'react';
import styled, { css } from 'styled-components';

import { generateLinkUrl } from '../link-activity-event';
import {
  ActivityFeedFormatters,
  type IActivityFeedDictionary,
  type IActivityFeedEnrichSentinel,
  type IActivityFeedFormattedValue,
  type IActivityFeedFormatterSentinel,
} from './types';

const FormatWrapper = styled.span<{ italicize?: boolean; isBold?: boolean; underlined?: boolean }>`
  ${(p) =>
    p.italicize &&
    css`
      font-style: italic;
    `}

  ${(p) =>
    p.isBold &&
    css`
      font-weight: ${fontWeight('bold')};
    `}

  ${(p) =>
    p.underlined &&
    css`
      text-decoration: underline;
    `}
`;

export function isEnrichSentinel(sentinel: any): sentinel is IActivityFeedEnrichSentinel {
  if (!isPlainObject(sentinel)) return false;
  const { key, type } = sentinel;
  return !!(key && type);
}

export function isFormatterSentinel(sentinel: any): sentinel is IActivityFeedFormatterSentinel {
  if (!isPlainObject(sentinel)) return false;
  const { type } = sentinel;
  return type === 'formatted_value';
}

const resolveDefaultActorValue = (key: string) => {
  const [, userType, userId] = key.split(':');

  if (userType && !userId) {
    return userType;
  }

  return undefined;
};

const resolveEnrichSentinel = (
  sentinel: IActivityFeedEnrichSentinel,
  dictionary: IActivityFeedDictionary
) => {
  const { resource, key, id, type } = sentinel;

  const dictionaryValue = dictionary[key] || '';

  if (type === 'actor') {
    return dictionaryValue || resolveDefaultActorValue(key);
  }

  switch (resource) {
    case 'task': {
      if (!dictionaryValue) {
        return dictionaryValue;
      }

      if (!id) {
        return (
          <Tag size="xs" color="secondary" compact inverted>
            {dictionaryValue}
          </Tag>
        );
      }

      const link = generateLinkUrl({
        descriptor: 'resource',
        model: resource,
        modelId: Number(id),
      });

      return (
        <Tag href={link} color="secondary" size="xs" compact inverted>
          {dictionaryValue}
        </Tag>
      );
    }
    case 'applicant': {
      // TODO: figure out how to make it say "Borrower #1" or "Borrower #2"
      return dictionaryValue || 'Borrower';
    }
    default:
      return dictionaryValue;
  }
};

const resolveFormatterSentinelValue = (value: any, formatters: ActivityFeedFormatters[]) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  let valueComponent = <>{value}</>;

  const isCurrencyValue = formatters.some(
    (formatter) => formatter === ActivityFeedFormatters.currency
  );
  if (isCurrencyValue) {
    const numberValue = Number(value);
    if (!isNaN(numberValue)) {
      valueComponent = <Format.Currency value={numberValue} noDecimals />;
    }
  }

  const isTagValue = formatters.some((formatter) => formatter === ActivityFeedFormatters.tag);
  if (isTagValue) {
    valueComponent = (
      <Tag size="xs" color="primary" compact inverted>
        {value}
      </Tag>
    );
  }

  const italicize = formatters.some((formatter) => formatter === ActivityFeedFormatters.italicized);

  return <FormatWrapper italicize={italicize}>{valueComponent}</FormatWrapper>;
};

const resolveFormatterSentinel = (sentinel: IActivityFeedFormatterSentinel) => {
  const { formatters, value } = sentinel;

  // if value can be an object in the future then this must change
  if (value === undefined || value === null || isPlainObject(value)) {
    return '';
  }

  if (Array.isArray(value)) {
    return (
      <>
        {value.map((val, index) => (
          <>
            {index !== 0 ? <>&nbsp;</> : null}
            {resolveFormatterSentinelValue(val, formatters)}
          </>
        ))}
      </>
    );
  }

  return resolveFormatterSentinelValue(value, formatters);
};

export const resolveFormattedValue = (
  value: IActivityFeedFormattedValue,
  dictionary: IActivityFeedDictionary
) =>
  reduce(
    value,
    (accum: Array<string | ReactElement>, current) => {
      if (isString(current)) {
        return [...accum, ' ', current];
      }

      if (isEnrichSentinel(current)) {
        const sentinelValue = resolveEnrichSentinel(current, dictionary);

        if (!sentinelValue) {
          return accum;
        }

        return [...accum, ' ', sentinelValue];
      }

      if (isFormatterSentinel(current)) {
        const sentinelValue = resolveFormatterSentinel(current);

        if (!sentinelValue) {
          return accum;
        }

        return [...accum, ' ', sentinelValue];
      }

      return accum;
    },
    []
  );
