import { type IApplicationOverviewSection } from './types';

interface IApplicationOverviewSectionProps {
  applicationFileId: string;
  section: IApplicationOverviewSection;
  className?: string;
}

export const ApplicationOverviewSection = (props: IApplicationOverviewSectionProps) => {
  const { applicationFileId, section, className } = props;

  const { id, component: Component } = section;

  return <Component id={id} applicationFileId={applicationFileId} className={className} />;
};
