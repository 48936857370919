import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { type ApplicationFileTeamQuery } from 'src/apollo/graphql';

import { graphql } from '../../../apollo';

export const QApplicationFileTeam = graphql(`
  query ApplicationFileTeam($applicationFileId: ID!) {
    applicationFile(id: $applicationFileId) {
      id
      siteId
      jobFunctions {
        id
        name
        primary
        agents {
          id
        }
      }
    }
  }
`);

export type IApplicationFileTeamJobFunction = NonNullable<
  ApplicationFileTeamQuery['applicationFile']
>['jobFunctions'][0];

export const useApplicationTeam = (applicationFileId: string, options?: IUseApolloQueryOptions) => {
  const [data, loading] = useQuery(
    QApplicationFileTeam,
    { applicationFileId },
    { fetchPolicy: 'network-only', ...options }
  );
  return [data?.applicationFile?.siteId, data?.applicationFile?.jobFunctions, loading] as const;
};
