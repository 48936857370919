import { Chat, ChatError, ChatSkeleton, useChannelUnreadCount } from '@mortgagehippo/chat';
import { ErrorBoundary } from '@mortgagehippo/util';
import { Suspense } from 'react';

import { Drawer, DrawerItem, useDrawerTabId } from '$components/drawer';
import { useApplicationFileCan } from '$components/permissions';

import { chatService } from '../../services/chat';

export interface IApplicationDrawerProps {
  applicationFileId: string;
  chatEnabled: boolean;
  notesEnabled: boolean;
  screenshareEnabled: boolean;
}

export const ApplicationDrawer = (props: IApplicationDrawerProps) => {
  const { applicationFileId, chatEnabled, notesEnabled, screenshareEnabled } = props;

  const messagesChannelId = `applicationfile:${applicationFileId}`;
  const notesChannelId = `notes:${applicationFileId}`;

  const messagesUnreadCount = useChannelUnreadCount(chatService, messagesChannelId);
  const notesUnreadCount = useChannelUnreadCount(chatService, notesChannelId);
  const [initialId, setInitialId] = useDrawerTabId();

  const [can, _canReady, { data }] = useApplicationFileCan(applicationFileId);
  const isArchived = !!data?.archivedAt;

  const canSeeChat = chatEnabled && can.VIEW_CHAT;
  const canSeeNotes = notesEnabled && can.VIEW_NOTES;
  const canSeeScreenShare = screenshareEnabled && !isArchived;

  const handleOpen = (id: string) => {
    setInitialId(id);
  };

  const handleClose = () => {
    setInitialId(null);
  };

  if (!canSeeChat && !canSeeNotes && !canSeeScreenShare) {
    return null;
  }

  return (
    <Drawer initialId={initialId || undefined} onOpen={handleOpen} onClose={handleClose}>
      {canSeeChat ? (
        <DrawerItem id="applicationfile" icon="chat" label="Messages" count={messagesUnreadCount}>
          <ErrorBoundary errorComponent={ChatError}>
            <Suspense fallback={<ChatSkeleton />}>
              <Chat
                service={chatService}
                channel={messagesChannelId}
                emptyLabel={
                  <>
                    <strong>No Messages Yet</strong>
                    <br />
                    Here you can exchange messages with the borrower regarding this application.
                  </>
                }
              />
            </Suspense>
          </ErrorBoundary>
        </DrawerItem>
      ) : null}
      {canSeeNotes ? (
        <DrawerItem id="notes" icon="notebook" label="Notes" count={notesUnreadCount}>
          <ErrorBoundary errorComponent={ChatError}>
            <Suspense fallback={<ChatSkeleton />}>
              <Chat
                service={chatService}
                channel={notesChannelId}
                type="flat"
                formPlaceholder="Add a new note"
                submitLabel="Add note"
                submitIcon="check"
                submitOnEnter={false}
                editorMode="extended"
                editable
                emptyLabel={
                  <>
                    <strong>No Notes Yet</strong>
                    <br />
                    You can add internal notes here related to this application. These notes will
                    not be visible to the borrower.
                  </>
                }
              />
            </Suspense>
          </ErrorBoundary>
        </DrawerItem>
      ) : null}
      {canSeeScreenShare ? (
        <DrawerItem
          id="screenshare"
          icon="screen-share"
          label="Screen Share"
          onClick={() => window.open(`/screenshare/${applicationFileId}`, '_blank')}
        />
      ) : null}
    </Drawer>
  );
};
