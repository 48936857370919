import { Button, type IButtonProps, T } from '@mortgagehippo/ds';
import { useClipboard } from '@mortgagehippo/util';
import { useCallback, useEffect, useState } from 'react';

const JUST_COPIED_MSG_DURATION = 3000;

interface IApplicationInvitesApplicantCopyButtonProps extends IButtonProps {
  claimUrl: string;
}

export const ApplicationInvitesApplicantCopyButton = (
  props: IApplicationInvitesApplicantCopyButtonProps
) => {
  const { claimUrl, children, ...rest } = props;

  const [justCopied, setJustCopied] = useState(false);

  const clipboard = useClipboard();

  useEffect(() => {
    let timeoutId: any;

    if (justCopied) {
      timeoutId = setTimeout(() => {
        setJustCopied(false);
      }, JUST_COPIED_MSG_DURATION);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [justCopied]);

  const handleClick = useCallback(async () => {
    const success = await clipboard.copy(claimUrl);

    if (success) {
      setJustCopied(true);
    }
  }, [claimUrl, clipboard]);

  const handleMouseOut = useCallback(() => {
    setJustCopied(false);
  }, []);

  const tooltip = justCopied ? <T>Copied</T> : undefined;

  if (!clipboard.canCopy()) {
    return null;
  }

  return (
    <Button {...rest} onClick={handleClick} tooltip={tooltip} onMouseOut={handleMouseOut}>
      {children}
    </Button>
  );
};
