import { type OwnerType } from './types';
import { usePartnerReportDashboards } from './use-partner-report-dashboards';
import { useSiteReportDashboards } from './use-site-report-dashboards';

export const useReportDashboards = (ownerType: OwnerType, ownerId: string, skip?: boolean) => {
  const isPartner = ownerType === 'partner';

  const [partnerDashboards, partnerLoading] = usePartnerReportDashboards(ownerId, {
    skip: skip || !isPartner,
  });
  const [siteDashboards, siteLoading] = useSiteReportDashboards(ownerId, {
    skip: skip || isPartner,
  });

  const dashboards = isPartner ? partnerDashboards : siteDashboards;
  const loading = isPartner ? partnerLoading : siteLoading;

  return [dashboards, loading] as const;
};
