import { type FormSubmitHandler, notifications, type UploadHandler } from '@mortgagehippo/ds';
import axios, { type AxiosProgressEvent } from 'axios';
import { isNil } from 'lodash-es';
import { useCallback, useEffect, useMemo } from 'react';

import { useUserCan } from '$components/permissions';

import { useAgentData, useCreateAgentAvatarUploadUrl } from '../../../hooks/agents';
import { AgentModal } from './agent-modal';
import { useAgentModalData } from './use-agent-modal-data';
import { getInitialValues } from './util';

interface IAgentModalContainerProps {
  partnerId: string;
  agentId?: string;
  isOpen: boolean;
  onSubmit: FormSubmitHandler;
  onRequestClose: () => void;
}

export const AgentModalContainer = (props: IAgentModalContainerProps) => {
  const { partnerId, agentId, onRequestClose, onSubmit, isOpen } = props;

  const [can] = useUserCan();

  const isEditing = !isNil(agentId);

  const [{ data: agentData, loading: agentDataLoading }] = useAgentData(agentId!, {
    skip: !isEditing,
  });

  const [{ folders = [], loading: modalDataLoading }] = useAgentModalData();

  const createAgentAvatarUploadUrl = useCreateAgentAvatarUploadUrl();

  const loading = agentDataLoading || modalDataLoading;

  const loadingFailed = isEditing && !loading && !agentData;
  useEffect(() => {
    if (loadingFailed) {
      notifications.error({
        message: 'An error occurred while processing your request. Please try again later.',
      });
      onRequestClose();
    }
  }, [onRequestClose, loadingFailed]);

  const handleUpload: UploadHandler = useCallback(
    async (file, meta, progress: (p: number) => void) => {
      try {
        const result = await createAgentAvatarUploadUrl(partnerId, meta.filename);

        const { presignedUrl: uploadUrl, uploadKey } = result;

        await axios.put(uploadUrl, file, {
          headers: {
            'Content-Type': meta.mime,
          },
          onUploadProgress: (e: AxiosProgressEvent) => {
            if (!e.total) return;
            const percent = Math.round((e.loaded * 100) / e.total);
            progress(percent);
          },
        });

        return { id: uploadKey };
      } catch (e) {
        notifications.error({ message: 'An error occurred while uploading your file.' });
        throw e;
      }
    },
    [createAgentAvatarUploadUrl, partnerId]
  );

  const initialValues = useMemo(() => {
    if (!isEditing) {
      return { visible: true };
    }

    if (!agentData) {
      return {};
    }

    return getInitialValues(agentData);
  }, [agentData, isEditing]);

  const readOnly = isEditing && !can.UPDATE_USER;
  const title = !isEditing ? 'Add User' : `${!readOnly ? 'Edit' : 'View'} User`;

  return (
    <AgentModal
      agentId={agentId}
      isOpen={isOpen}
      title={title}
      initialValues={initialValues}
      folders={folders}
      onSubmit={onSubmit}
      onRequestClose={onRequestClose}
      readOnly={readOnly}
      showSlug={isEditing}
      onUpload={handleUpload}
      loading={loading || loadingFailed}
      disableOverlayClickClose
    />
  );
};
