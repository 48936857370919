import { fontSize, T } from '@mortgagehippo/ds';
import {
  type IBaseTask,
  type ITaskApplicant,
} from '@mortgagehippo/tasks';
import styled from 'styled-components';

import { TasksList } from '$components/tasks-list';

const Empty = styled.div`
  font-size: ${fontSize('sm')};
  line-height: ${fontSize('md')};
`;

interface IApplicationOverviewTasksListProps<T extends IBaseTask, A extends ITaskApplicant> {
  tasks: T[];
  applicants: A[];
  primaryApplicantId?: string;
  to?: (taskId: string, applicantId: string) => string;
}

export const ApplicationOverviewTasksList = <T extends IBaseTask, A extends ITaskApplicant>(
  props: IApplicationOverviewTasksListProps<T, A>
) => {
  const { tasks, applicants, to } = props;



  if (!tasks.length) {
    return (
      <Empty>
        <T>No open borrower tasks</T>
      </Empty>
    );
  }

  return (
    <TasksList
      tasks={tasks}
      applicants={applicants}
      to={to}
      linkColor="neutral900"
      iconSize="normal"
      iconMargin={1}
    />
  );
};
