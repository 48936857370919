import { type ILosDocument, type ILosDocumentPush, type ILosPush } from './types';

export const isSuccessfulPush = (push: ILosPush) => push.pushed;

export const isErroredPush = (push: ILosPush) => push.statusCode === 'ERROR';

export const isProcessingPush = (push: ILosPush) => push.statusCode === 'PROCESSING';

export const isDocumentPushed = (document: ILosDocument) =>
  document.losPush?.statusCode === 'SUCCESS';

export const isSuccessfulDocumentPush = (push: ILosDocumentPush) => push.statusCode === 'SUCCESS';

export const isFailedDocumentPush = (push: ILosDocumentPush) => push.statusCode === 'ERROR';
