import { useContext } from 'react';

import { type IUserCan, PermissionsContext } from './permissions-context';
import { createEmptyCan } from './util';

type IUseUserCanResult = [IUserCan, boolean, { user: any }];

export const useUserCan = (): IUseUserCanResult => {
  const context = useContext(PermissionsContext);
  if (!context) {
    return [createEmptyCan(), false, { user: undefined }];
  }

  const { can, ready, user } = context;
  return [can, ready, { user }];
};
