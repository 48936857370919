import { useChannelListUnreadCount } from '@mortgagehippo/chat';
import { MediaBreakpoint, NewBadge, Popover, useResponsive } from '@mortgagehippo/ds';
import { useState } from 'react';
import styled from 'styled-components';

import { chatService } from '../../services/chat';
import { history } from '../../services/history';
import { NotificationsPopover } from './notifications-popover';

const NotificationsContainer = styled.div`
  display: inline-block;
  position: relative;
  display: flex;
  align-items: center;
`;

const NotificationsBadgeContainer = styled.div`
  position: absolute;
  right: 0px;
  top: -3px;
  z-index: 100;

  ${MediaBreakpoint.PHONE} {
    right: -1px;
    top: -8px;
  }
`;

export const NotificationsButton = () => {
  const [open, setOpen] = useState(false);
  const messagesCount = useChannelListUnreadCount(chatService, {
    channelType: 'applicationfile',
  });

  const notesCount = useChannelListUnreadCount(chatService, {
    channelType: 'notes',
  });

  const count = messagesCount + notesCount;

  const handleClick = (applicationFileId: string, channelId: string) => {
    const [channelType] = channelId.split(':');
    history.push(`#/applications/${applicationFileId}/?collaboration=${channelType}`);
    setOpen(false);
  };

  const responsive = useResponsive();

  return (
    <NotificationsContainer>
      {count ? (
        <NotificationsBadgeContainer>
          <NewBadge textSize="xxs" count={count} autoPosition={false} bounce />
        </NotificationsBadgeContainer>
      ) : null}
      <Popover
        open={open}
        align="BottomCenter"
        onShow={() => {
          setOpen(true);
        }}
        onHide={() => {
          setOpen(false);
        }}
        content={<NotificationsPopover onClick={handleClick} />}
        buttonProps={{
          compact: true,
          type: 'neutral',
          importance: 'tertiary',
          icon: 'bell',
          iconSize: 'normal',
          iconButton: true,
          iconButtonRound: true,
          size: responsive.PHONE.EXACT_OR_SMALLER ? 'xs' : 'sm',
        }}
        maxWidthCSS="auto"
        compact
      >
        Notifications
      </Popover>
    </NotificationsContainer>
  );
};
