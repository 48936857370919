import { Icon, spacing, Text } from '@mortgagehippo/ds';
import styled from 'styled-components';

import { type IWorkNumberReport } from './use-request-work-number-instant-report';

const ErrorContainer = styled.div`
  padding: ${spacing(6)};
  text-align: center;
`;

export interface IWorkNumberInstantReportEmptyResultProps {
  report?: IWorkNumberReport;
}

export const WorkNumberInstantReportEmptyResult = () => (
  <ErrorContainer>
    <Icon name="error" size="xxl" color="warning500" />
    <br />
    <Text as="p">We couldn&rsquo;t find any employers associated with this information.</Text>
  </ErrorContainer>
);
