
import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../apollo';
import { type LosType } from '../../../apollo/graphql';

const MTriggerLosDocumentsPush = graphql(`
  mutation TriggerLosDocumentsPush($applicationFileId: ID!, $losType: LOSType , $documentIds: [ID!]) {
    triggerLosDocumentsPush(
      applicationFileId: $applicationFileId
      losType: $losType
      documentIds: $documentIds
    )
  }
`);

export const useTriggerLosDocumentsPush = (losType: LosType) => {
  const fn = useMutation(MTriggerLosDocumentsPush, undefined);

  return useCallback(
    async (applicationFileId: string, documentIds?: string[]) => {
      const response = await fn({ applicationFileId, losType, documentIds });

      return response.data!.triggerLosDocumentsPush;
    },
    [fn, losType]
  );
};
