import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo';

export const MTriggerDUAssessment = graphql(`
  mutation TriggerDUAssessment($applicationFileId: ID!) {
    triggerDUAssessment(applicationFileId: $applicationFileId)
  }
`);

export const useTriggerDuAssessment = () => {
  const triggerDUAssessment = useMutation(MTriggerDUAssessment, undefined);

  return useCallback(
    async (applicationFileId: string) => {
      const response = await triggerDUAssessment({ applicationFileId });
      return response.data!.triggerDUAssessment;
    },
    [triggerDUAssessment]
  );
};
