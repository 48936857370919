import { fontSize, fontWeight, lineHeight, palette, spacing } from '@mortgagehippo/ds';
import styled from 'styled-components';

import { ContentEditor } from '$components/content-editor';

import { type IBulkCustomTask } from '../../types';

const ListHeader = styled.p`
  margin: 0;
  font-size: ${fontSize('sm')};
  line-height: ${lineHeight('sm')};
`;

const List = styled.ul`
  padding: 0;
  margin: 0 0 ${spacing(4)};
  list-style-position: inside;
`;

const LI = styled.li`
  &::marker {
    color: ${palette('primary600')};
  }
`;

const TaskName = styled.span`
  font-weight: ${fontWeight('bold')};
  font-size: ${fontSize('xs')};
  line-height: ${lineHeight('xs')};
`;

interface IStep2Props {
  tasks: IBulkCustomTask[];
  pluralize?: boolean;
}

export const Step2 = (props: IStep2Props) => {
  const { tasks, pluralize } = props;

  const label = `Message to borrower${pluralize ? 's' : ''}`;

  return (
    <>
      <ListHeader>The following tasks will be sent:</ListHeader>
      <List>
        {tasks.map((t) => (
          <LI key={t.id}>
            <TaskName>{t.title}</TaskName>
          </LI>
        ))}
      </List>
      <ContentEditor.Box name="message" label={label} mode="micro" />
    </>
  );
};
