import { graphql } from '../../../apollo';

export const QCanResetPassword = graphql(`
  query CanResetPassword {
    me {
      id
      canResetPassword
    }
  }
`);
