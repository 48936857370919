import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../../apollo';
import { type CreateSmartviewInput } from '../../../apollo/graphql';

export const MCreateSmartview = graphql(`
  mutation CreateSmartview($partnerId: ID!, $data: CreateSmartviewInput!) {
    createSmartview(partnerId: $partnerId, data: $data) {
      id
    }
  }
`);

export const useCreateSmartview = () => {
  const createSmartview = useMutation(MCreateSmartview, undefined);

  return async (partnerId: string, data: CreateSmartviewInput) => {
    const response = await createSmartview({
      partnerId,
      data,
    });

    return response.data!.createSmartview;
  };
};
