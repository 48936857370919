import { MediaBreakpoint, palette, spacing } from '@mortgagehippo/ds';
import { useCallback } from 'react';
import styled, { css } from 'styled-components';

import { type ILenderMilestone } from '../../pages/application/use-lender-milestones';
import { LenderMilestonesListItem } from './lender-milestones-list-item';

const List = styled.ol<{ mobileLeftMargin?: boolean; hasSubtitle?: boolean }>`
  display: block;
  list-style: none;
  margin: ${spacing(4)} 0 0 0;
  padding: 0;

  ${(p) =>
    p.mobileLeftMargin &&
    css`
      ${MediaBreakpoint.TABLET} {
        margin-left: calc((${spacing(8)} + ${spacing(9)}) / 2);
      }
    `}

  ${(p) =>
    p.hasSubtitle &&
    css`
      margin-top: ${spacing(3)};
    `}
`;

const Item = styled.li<{ isEditing?: boolean }>`
  margin-top: ${spacing(3)};
  margin-bottom: ${spacing(2)};
  padding-bottom: ${spacing(2)};

  &:first-child {
    margin-top: 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${palette('neutral100')};

    ${(p) =>
      p.isEditing &&
      css`
        border-bottom: 1px solid ${palette('primary100')};
      `}
  }
`;

interface ILenderMilestonesListProps {
  milestones: ILenderMilestone[];
  isEditing?: boolean;
  onEditMilestoneChange?: (milestoneId: string, newValue: boolean) => void;
}

export const LenderMilestonesList = (props: ILenderMilestonesListProps) => {
  const { isEditing, milestones, onEditMilestoneChange } = props;

  // adds left margin on mobile so that dates are displayed properly
  const mobileLeftMargin = milestones.some((milestone) => !!milestone.updatedOn);

  const handleChange = useCallback(
    (milestoneId: string) => (newValue: boolean) => {
      if (onEditMilestoneChange) {
        onEditMilestoneChange(milestoneId, newValue);
      }
    },
    [onEditMilestoneChange]
  );

  return (
    <List mobileLeftMargin={mobileLeftMargin} hasSubtitle={isEditing}>
      {milestones.map((milestone) => {
        const { id } = milestone;
        return (
          <Item key={id} isEditing={isEditing}>
            <LenderMilestonesListItem
              milestone={milestone}
              isEditing={isEditing}
              onChange={handleChange(id)}
            />
          </Item>
        );
      })}
    </List>
  );
};
