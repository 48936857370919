import { Input } from '@mortgagehippo/ds';
import { type ReactNode } from 'react';

import { ContentEditor } from '$components/content-editor';
import { DueDate } from '$components/due-date-field';

export interface ICustomTaskInitialStep {
  showDueDate?: boolean;
  children?: ReactNode;
}

export const CustomTaskInitialFields = (props: ICustomTaskInitialStep) => {
  const { showDueDate, children } = props;

  return (
    <>
      {!!showDueDate && (
        <DueDate.Box
          name="due_date"
          label="Due Date"
          labelInvisible
          size="sm"
          validateFromNow="gte"
        />
      )}
      <Input.Box
        name="title"
        label="Task Title"
        labelCid="custom-task:task-title"
        labelTooltip="The task title should describe what the borrower should do in up to 5 words. Do not include the borrower’s name if there are more than one borrowers - the system will automatically display it."
        required
      />
      <ContentEditor.Box
        name="description"
        label="Instructions for borrower"
        mode="micro"
        required
        labelTooltip="Write a clear and brief explanation or instructions to help the borrower successfully complete this task."
      />
      {children}
    </>
  );
};
