import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';
import { useMemo } from 'react';

import { graphql } from '../../apollo';
import { type ApplicationFileCanQuery, IntegrationType, VerticalType } from '../../apollo/graphql';
import {
  type IApplicationService,
  integrationsToServices,
  ServiceType,
} from '../../pages/application/application-services';
import { type IUserCan } from './permissions-context';
import { useUserCan } from './use-user-can';
import { createEmptyCan } from './util';

export const QApplicationFileCan = graphql(`
  query ApplicationFileCan($applicationFileId: ID!) {
    applicationFile(id: $applicationFileId) {
      id
      isFrozen
      configuredIntegrations
      archivedAt
      site {
        id
        verticalName
      }
      preQualificationDetails {
        prerequisitesMet
      }
      primaryAgent {
        id
      }
    }
  }
`);

type IUseApplicationFileCanResult = [
  IUserCan,
  boolean,
  {
    data: ApplicationFileCanQuery['applicationFile'];
    accessibleServices: IApplicationService[];
    configuredIntegrations: IntegrationType[];
    refresh: () => Promise<any>;
  },
];

export const useApplicationFileCan = (
  applicationFileId: string,
  options: IUseApolloQueryOptions = {}
): IUseApplicationFileCanResult => {
  const [userCan, userCanReady, { user }] = useUserCan();

  const [data, dataLoading, , { refetch }] = useQuery(
    QApplicationFileCan,
    { applicationFileId },
    {
      fetchPolicy: 'cache-first',
      ...options,
      skip: !userCanReady,
      throw: false,
    }
  );

  const canReady = !dataLoading && userCanReady;

  const handleRefresh = useSafeCallback(async () => refetch({ applicationFileId }));

  const [can, accessibleServices, configuredIntegrations] = useMemo(() => {
    if (!canReady || !data?.applicationFile) {
      return [createEmptyCan(), [], []];
    }

    const { applicationFile } = data;
    const {
      isFrozen,
      configuredIntegrations: nextConfiguredIntegrations,
      site,
      preQualificationDetails,
      archivedAt,
      primaryAgent,
    } = applicationFile;
    const { verticalName } = site;
    const { id: primaryAgentId } = primaryAgent || {};

    const isArchived = !!archivedAt;
    const { prerequisitesMet: preQualificationPrerequisitesMet } = preQualificationDetails;
    const isPrimaryAgent = !!primaryAgentId && !!user && `${user.id}` === `${primaryAgentId}`;

    let nextCan: IUserCan = userCan;

    if (isPrimaryAgent) {
      nextCan = {
        ...nextCan,
        APPLICATION_FILE_TEAM_TAB_SHOW_FOLDERS:
          nextCan.APPLICATION_FILE_TEAM_TAB_SHOW_FOLDERS || isPrimaryAgent,
      };
    }

    if (isFrozen) {
      nextCan = {
        ...nextCan,
        ADD_BORROWER: false,
        DELETE_BORROWER: false,
        SELECT_PRICING_OPTION:
          nextCan.SELECT_PRICING_OPTION && nextCan.UPDATE_FROZEN_APPLICATION_FILE,
        UPDATE_APPLICANT_EMAIL:
          nextCan.UPDATE_APPLICANT_EMAIL && nextCan.UPDATE_FROZEN_APPLICATION_FILE,
      };
    }

    if (isArchived) {
      nextCan = {
        ...nextCan,
        ADD_BORROWER: false,
        ARCHIVE_APPLICATION_FILE: false,
        CREATE_TASK: false,
        DELETE_BORROWER: false,
        DELETE_LENDER_DOCUMENT: false,
        GENERATE_DU_ASSESSMENT: false,
        GENERATE_LPA_ASSESSMENT: false,
        MANAGE_TEAM: false,
        PUSH_DOCUMENT_TO_LOS: false,
        PUSH_TO_INTERNAL_SYSTEM: false,
        PUSH_TO_LOS: false,
        REFRESH_FINICITY_REPORT: false,
        REFRESH_MORTGAGEFLEX_LOAN_STATUS: false,
        REQUEST_WORKNUMBER_INSTANT_REPORT: false,
        SIGN_IDS_DOCUMENT: false,
        TRIGGER_CREDIT_PULL: false,
        UPDATE_ANSWERS: false,
        UPDATE_TASK: false,
        UPDATE_APPLICANT_EMAIL: false,
        UPLOAD_DOCUMENT: false,
        UPDATE_LENDING_QB_PUSH: false,
        VIEW_PREQUALIFICATION_LETTERS_PAGE: false,
        VIEW_PRICING_TAB: false,
        VIEW_SEND_INVITES_PAGE: false,
        LOCK_PRICING_OPTION: false,
        UPDATE_APPLICATION_ACTIVE_MILESTONE: false,
        UPDATE_APPLICATION_LENDER_MILESTONES: false,
        UPDATE_APPLICATION_STATUS: false,
        DELETE_TASK: false,
        SELECT_PRICING_OPTION: false,
        VIEW_SMART_FEES: false,
        VIEW_DOCUTECH: false,
        VIEW_CHAT: false,
        VIEW_NOTES: false,
        APPLICATION_FILE_TEAM_TAB_SHOW_FOLDERS: false,
      };
    }

    if (!isArchived) {
      nextCan = {
        ...nextCan,
        RESTORE_APPLICATION_FILE: false,
      };
    }

    /*
     * TODO: make it so that we have additional configuration options on permissions
     * like requiredVertical or requiredIntegrations so that we can just have
     * generic logic for these things.
     */
    if (verticalName !== VerticalType.Mortgage && verticalName !== VerticalType.OldMortgage) {
      nextCan = {
        ...nextCan,
        VIEW_PRICING_TAB: false,
        SELECT_PRICING_OPTION: false,
        DOWNLOAD_FANNIE_MAE_FILE: false,
        DOWNLOAD_MISMO_34_FILE: false,
        DOWNLOAD_MISMO_34_PLUS_DU_FILE: false,
        DOWNLOAD_MISMO_34_PLUS_LPA_FILE: false,
      };
    }

    if (!preQualificationPrerequisitesMet) {
      nextCan = {
        ...nextCan,
        VIEW_PREQUALIFICATION_LETTERS_PAGE: false,
      };
    }

    if (!nextConfiguredIntegrations.find((i) => i === IntegrationType.Pricing)) {
      nextCan = {
        ...nextCan,
        VIEW_PRICING_TAB: false,
        SELECT_PRICING_OPTION: false,
      };
    }

    if (!nextConfiguredIntegrations.find((i) => i === IntegrationType.Transnational)) {
      nextCan = {
        ...nextCan,
        CREATE_PAYMENT_TASK: false,
      };
    }

    if (!nextConfiguredIntegrations.find((i) => i === IntegrationType.HelloSign)) {
      nextCan = {
        ...nextCan,
        CREATE_HELLOSIGN_TASK: false,
      };
    }

    if (!nextConfiguredIntegrations.find((i) => i === IntegrationType.Docusign)) {
      nextCan = {
        ...nextCan,
        CREATE_DOCUSIGN_TASK: false,
      };
    }

    if (!nextConfiguredIntegrations.find((i) => i === IntegrationType.LendingQb)) {
      nextCan = {
        ...nextCan,
        UPDATE_LENDING_QB_PUSH: false,
      };
    }

    if (!nextConfiguredIntegrations.find((i) => i === IntegrationType.MortgageFlex)) {
      nextCan = {
        ...nextCan,
        REFRESH_MORTGAGEFLEX_LOAN_STATUS: false,
      };
    }

    const nextAccessibleServices = nextCan.VIEW_SERVICES_TAB
      ? integrationsToServices(nextConfiguredIntegrations, nextCan)
      : [];

    if (nextAccessibleServices.length === 0) {
      nextCan = {
        ...nextCan,
        VIEW_SERVICES_TAB: false,
      };
    }

    if (!nextAccessibleServices.find((i) => i.type === ServiceType.Docutech)) {
      nextCan = {
        ...nextCan,
        VIEW_DOCUTECH: false,
      };
    }

    if (!nextAccessibleServices.find((i) => i.type === ServiceType.SmartFees)) {
      nextCan = {
        ...nextCan,
        VIEW_SMART_FEES: false,
      };
    }

    if (!nextAccessibleServices.find((i) => i.type === ServiceType.UnderwritingConditions)) {
      nextCan = {
        ...nextCan,
        VIEW_UNDERWRITING_CONDITIONS_PAGE: false,
      };
    }

    return [nextCan, nextAccessibleServices, nextConfiguredIntegrations];
  }, [canReady, data, user, userCan]);

  const applicationFile = canReady ? data?.applicationFile || null : null;

  return [
    can,
    canReady,
    { data: applicationFile, accessibleServices, configuredIntegrations, refresh: handleRefresh },
  ];
};
