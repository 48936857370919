import { useCustomizations } from '@mortgagehippo/ds';

import { AusType } from '../../../../../apollo/graphql';

const AUS_TYPE_SETTINGS: Record<AusType, { cid: string; defaultValue: string }> = {
  [AusType.DesktopUnderwriter]: {
    cid: 'pageApplication:tabServices.aus.du.label',
    defaultValue: 'DU / Fannie Mae',
  },
  [AusType.LoanProductAdvisor]: {
    cid: 'pageApplication:tabServices.aus.lpa.label',
    defaultValue: 'LPA / Freddie Mac',
  },
};

export const useAusTypeLabel = (type?: AusType): string | undefined => {
  const customizations = useCustomizations();

  if (!type) {
    return undefined;
  }

  const settings = AUS_TYPE_SETTINGS[type];

  if (!settings) {
    return undefined;
  }

  const { cid, defaultValue } = settings;
  return customizations.text(cid, defaultValue);
};
