import { CopyButton, Input, type ISelectOption, Modal, Select, spacing } from '@mortgagehippo/ds';
import { noop } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

const StyledSelectWrapper = styled.div`
  > * {
    margin-right: 0;
  }
`;

const CopyWrapper = styled.div`
  width: 100%;
  position: relative;

  > * {
    margin-right: 0;
  }
`;

const StyledCopyButton = styled(CopyButton)`
  position: absolute !important;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto !important;
  padding-right: ${spacing(7)} !important;
  padding-left: ${spacing(7)} !important;
`;

interface IShareAssignmentLinkModalProps {
  onRequestClose: () => void;
  isOpen: boolean;
  sharePath: string;
  siteOptions: ISelectOption[];
  loading: boolean;
}

export const ShareAssignmentLinkModal = (props: IShareAssignmentLinkModalProps) => {
  const { isOpen, onRequestClose, sharePath, siteOptions, loading } = props;

  const [selectedDomain, setSelectedDomain] = useState<string | undefined>();

  const handleSiteChange = useCallback((newValue: string) => {
    setSelectedDomain(newValue);
  }, []);

  const shareLink = useMemo(() => {
    if (!sharePath || !selectedDomain) {
      return undefined;
    }

    return `https://${selectedDomain}${sharePath}`;
  }, [selectedDomain, sharePath]);

  useEffect(() => {
    if (!selectedDomain && siteOptions.length) {
      setSelectedDomain(siteOptions[0]!.value);
    }
  }, [selectedDomain, siteOptions]);

  const showCopyButton = !!selectedDomain && !!shareLink;
  const hasMultipleSites = siteOptions.length > 1;

  return (
    <Modal
      title='Share My "Start Application" Link'
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onOk={onRequestClose}
      disableOverlayClickClose
      loading={loading}
      width="medium"
      okButtonLabel="Done"
      hideCancelButton
    >
      <p>
        Prospect borrowers who start an application after following this link will create an
        application automatically assigned to you.
      </p>
      {hasMultipleSites ? (
        <StyledSelectWrapper>
          <Select.Input
            name="site_select"
            onChange={handleSiteChange}
            options={siteOptions}
            value={selectedDomain}
          />
        </StyledSelectWrapper>
      ) : null}

      {showCopyButton ? (
        <CopyWrapper>
          <Input.Input name="copy_link" value={shareLink} disabled onChange={noop} />
          <StyledCopyButton copyText={shareLink || ''}>Copy</StyledCopyButton>
        </CopyWrapper>
      ) : null}
    </Modal>
  );
};
