import { Button, Icon, palette, spacing } from '@mortgagehippo/ds';
import { type IErrorComponentProps } from '@mortgagehippo/util';
import styled from 'styled-components';

import { OverlayClose } from './close';

const Container = styled.div`
  background: ${palette('neutral50')};
  min-height: 100vh;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled.div`
  margin-bottom: ${spacing(3)};
`;

export const OverlayNotFoundPage = (props: IErrorComponentProps) => {
  const { reset } = props;

  const handleClick = () => {
    reset();
  };

  return (
    <Container>
      <IconContainer>
        <Icon name="error" size="xxl" />
      </IconContainer>
      <p>The page you requested was not found.</p>
      <OverlayClose>
        <Button onClick={handleClick}>Back</Button>
      </OverlayClose>
    </Container>
  );
};
