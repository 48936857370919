import { Checkbox, type FormSubmitHandler, ModalForm, T, Text } from '@mortgagehippo/ds';
import { useCallback } from 'react';

interface ICreditPullAuthorizationModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit: FormSubmitHandler;
}

export const CreditPullAuthorizationModal = (props: ICreditPullAuthorizationModalProps) => {
  const { isOpen, onRequestClose, onSubmit } = props;
  const validateCheckbox = useCallback((value: any) => {
    if (value !== true) {
      return 'Required';
    }

    return undefined;
  }, []);

  return (
    <ModalForm
      title="Order Credit Check"
      okButtonLabel={<T>Order report</T>}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onSubmit={onSubmit}
    >
      <Text as="p">
        This action will request a credit pull using the borrower&apos;s information currently in
        the system.
      </Text>
      <Text as="p">
        Make sure not to use this feature unless the borrower has authorized a credit check.
      </Text>
      <Checkbox.Box
        label="Borrower authorization"
        labelInvisible
        name="authorized_credit_check"
        required
        validate={validateCheckbox}
      >
        <T>The borrower has authorized a credit check.</T>
      </Checkbox.Box>
    </ModalForm>
  );
};
