import { fontSize, Icon, type IIconProps, NewBadge } from '@mortgagehippo/ds';
import styled from 'styled-components';

interface INotificationIconProps extends IIconProps {
  count?: number;
  bounce?: boolean;
}

const Container = styled.span`
  display: inline-block;
  position: relative;
`;

const BadgeContainer = styled.div`
  position: absolute;
  top: -${fontSize('xs')};
  right: -${fontSize('xs')};
`;

export const NotificationIcon = (props: INotificationIconProps) => {
  const { count, bounce = true, ...rest } = props;

  return (
    <Container>
      {count ? (
        <BadgeContainer>
          <NewBadge count={count} bounce={bounce} autoPosition={false} textSize="xs" />
        </BadgeContainer>
      ) : null}
      <Icon {...rest} />
    </Container>
  );
};
