import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../apollo';

const MCompleteTask = graphql(`
  mutation CompleteTask($taskId: ID!) {
    completeTask(taskId: $taskId) {
      id
      typeName
      state
      isAlerted
      alertMessage
      data
    }
  }
`);

export const useCompleteTask = (options: any = {}) => {
  const fn = useMutation(MCompleteTask, undefined, options);

  return useCallback(
    async (taskId: string): Promise<any> => {
      const response = await fn({ taskId });
      return response.data!.completeTask;
    },
    [fn]
  );
};
