import {
  Alert,
  type ITreeSelectOption,
  notifications,
  spacing,
  Title,
  TreeSelect,
} from '@mortgagehippo/ds';
import * as Sentry from '@sentry/browser';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { foldersToOptions } from '../../../components/actions/agent-action/util';
import { type IApplicationFileFolders } from './use-application-file-folders';
import { useAssignApplicationFileFolder } from './use-assign-application-file-to-folder';

const FolderContainer = styled.div`
  max-width: 400px;
`;

const StyledAlert = styled(Alert)`
  margin-bottom: ${spacing(4)};
`;

const FolderTitle = styled(Title)`
  margin-bottom: ${spacing(5)};
`;

export interface IApplicationTeamFoldersSectionProps {
  applicationFileId: string;
  canManage?: boolean;
  data?: IApplicationFileFolders | null;
  loading: boolean;
}

export const ApplicationTeamFoldersSection = (props: IApplicationTeamFoldersSectionProps) => {
  const { applicationFileId, canManage, data, loading } = props;
  const [folderValue, setFolderValue] = useState<string | undefined>();
  const [folderValueLoading, setFolderValueLoading] = useState<boolean>(true);
  const [assignFolderLoading, setAssignFolderLoading] = useState<boolean>(false);

  const assignApplicationFileFolder = useAssignApplicationFileFolder();

  const applicationFolder = data?.folder;
  const primaryAgentFolders = data?.primaryAgent?.folders;
  const hasFolders = !loading && (applicationFolder || !!primaryAgentFolders?.length);

  const displayFolderSection = hasFolders && canManage;

  const showAlert =
    applicationFolder?.id &&
    !(primaryAgentFolders || []).find((folder) => folder.id === applicationFolder.id);

  const handleApplicationFolderChange = useCallback(
    async (newValue: string) => {
      setAssignFolderLoading(true);
      try {
        await assignApplicationFileFolder(applicationFileId, newValue);
        setFolderValue(newValue);
        const match = (primaryAgentFolders || []).find((folder) => folder.id === newValue);
        notifications.success({
          message: match
            ? `Application moved to folder ${match.name}.`
            : 'Successfully moved application.',
        });
      } catch (e) {
        notifications.error({ message: 'Failed to moved application.' });
        Sentry.captureException(e, {
          extra: {
            applicationFileId,
            newFolderId: newValue,
          },
        });
      }
      setAssignFolderLoading(false);
    },
    [applicationFileId, assignApplicationFileFolder, primaryAgentFolders]
  );

  const applicationFolderOptions = useMemo(() => {
    const options: ITreeSelectOption[] = [];
    if (!displayFolderSection) {
      return options;
    }

    return showAlert
      ? [
          {
            label: applicationFolder?.name || '',
            value: applicationFolder?.id || '',
            disabled: true,
          },
          ...foldersToOptions(primaryAgentFolders || []),
        ]
      : foldersToOptions(primaryAgentFolders || []);
  }, [
    applicationFolder?.id,
    applicationFolder?.name,
    displayFolderSection,
    primaryAgentFolders,
    showAlert,
  ]);

  useEffect(() => {
    if (!loading) {
      setFolderValue(data?.folder?.id);
      setFolderValueLoading(false);
    }
  }, [data, loading]);

  const folderSelectDisabled = folderValueLoading || assignFolderLoading;
  const folderSelectLoading = folderValueLoading || assignFolderLoading;

  if (!displayFolderSection) {
    return null;
  }

  return (
    <>
      <FolderTitle level={2} cid="pageApplication:tabTeam.applicationFolder.title">
        Application Folder
      </FolderTitle>
      {showAlert ? (
        <StyledAlert type="info">
          The primary agent for this application does not have access to this folder.
        </StyledAlert>
      ) : null}
      <FolderContainer>
        <TreeSelect.InputBox
          name="applicationFolder"
          label="Select the folder in which this application should live"
          labelTooltip="Only folders accessible by the primary agent of this application are listed."
          placeholder={!folderValueLoading && 'Select a folder'}
          options={applicationFolderOptions}
          disabled={folderSelectDisabled}
          leafNodeIconProps={{ name: 'folder', outline: true }}
          treeIconProps={{ name: 'folder', outline: true }}
          treeIconExpandedProps={{ name: 'folder-open', outline: true }}
          onChange={handleApplicationFolderChange}
          value={folderValue}
          loading={folderSelectLoading}
          expandAll
          searchable
        />
      </FolderContainer>
    </>
  );
};
