import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../apollo';

const MCreateDocumentsArchive = graphql(`
  mutation CreateDocumentsArchive($applicationFileId: ID!, $documentIds: [String!]!) {
    createDocumentArchive(applicationFileId: $applicationFileId, documentIds: $documentIds)
  }
`);

export const useCreateDocumentsArchive = () => {
  const fn = useMutation(MCreateDocumentsArchive, undefined);
  return async (applicationFileId: string, documentIds: string[]) => {
    const result = await fn({ applicationFileId, documentIds });
    return result.data!.createDocumentArchive;
  };
};
