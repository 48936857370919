import { ModalDialog, notifications, useCustomizations, useModal } from '@mortgagehippo/ds';
import { useCallback } from 'react';

import { useCloseOverlay } from '$components/overlay';

import { useDeleteApplicationFile } from '../../../pages/application/use-delete-application';
import { history } from '../../../services/history';
import { type IActionProps, type IDeleteApplicationAction } from '../types';

export const DeleteApplicationAction = (props: IActionProps<IDeleteApplicationAction>) => {
  const { action, onDone, onCancel } = props;
  const { applicationFileId } = action;
  const [isOpen, , closeModal] = useModal(true);

  const customizations = useCustomizations();
  const modalTitle = customizations.text(
    'delete-application-action:confirm-title',
    'Are you sure you want to delete this application?'
  );
  const explanation = customizations.text(
    'delete-application-action:confirm-explanation',
    'This action is irreversible'
  );

  const closeOverlay = useCloseOverlay();
  const deleteApplication = useDeleteApplicationFile();

  const handleOk = async () => {
    try {
      await deleteApplication(applicationFileId);

      closeModal(() => {
        closeOverlay();
        history.push(window.location.pathname);
        notifications.success({
          messageCid: 'delete-application-action:notification-success',
          message: 'Successfully deleted application',
        });
        onDone();
      });
    } catch (e) {
      console.error(e);
      notifications.error({
        messageCid: 'delete-application-action:notification-error',
        message: 'There was an unexpected error deleting application',
      });
    }
  };

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  // TODO: use hook useModalConfirm
  return (
    <ModalDialog
      type="warning"
      title={modalTitle}
      onOk={handleOk}
      onRequestClose={handleCloseModal}
      isOpen={isOpen}
    >
      {explanation}
    </ModalDialog>
  );
};
