import { Editor } from '@mortgagehippo/ckeditor';
import {
  borderRadius,
  createField,
  HtmlRenderer,
  type IFieldInputProps,
  palette,
  ReadOnlyBox,
  spacing,
  Spinner,
} from '@mortgagehippo/ds';
import { isPresent } from '@mortgagehippo/util';
import { Suspense } from 'react';
import styled from 'styled-components';

interface IEditorInputProps extends IFieldInputProps {
  // eslint-disable-next-line react/no-unused-prop-types
  mode?: 'default' | 'extended' | 'micro';
}

const LoadingContainer = styled.div`
  width: 100%;
  color: ${palette('neutral600')};
  border-radius: ${borderRadius(2)};
  border: 1px solid ${palette('neutral300')};
  padding: ${spacing(3)};
  background: ${palette('white')};
`;

const EditorInput = (props: IEditorInputProps) => {
  const { value, onChange, disabled, mode } = props;

  return (
    <Suspense
      fallback={
        <LoadingContainer>
          <Spinner /> Loading editor...
        </LoadingContainer>
      }
    >
      <Editor mode={mode} value={value} onChange={onChange} disabled={disabled} />
    </Suspense>
  );
};

export const EditorInputView = (props: IEditorInputProps) => {
  const { value } = props;

  const el = <HtmlRenderer value={value} />;

  return <ReadOnlyBox>{isPresent(value) ? el : <>&mdash;</>}</ReadOnlyBox>;
};

export const ContentEditor = createField<IEditorInputProps>(EditorInput, EditorInputView);
