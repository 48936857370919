import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { objToKey, useSafeCallback } from '@mortgagehippo/util';
import { cloneDeep, sortBy } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';

import { graphql } from '../apollo';
import { type PartnerFoldersQuery, type PartnerFoldersQueryVariables } from '../apollo/graphql';
import { usePartnerDomain } from './use-partner-domain';

export interface IPartnerFolder {
  id: string;
  name: string;
  description?: string | null;
  parentId?: string;
}

export type INestedFolder<T extends IPartnerFolder = IPartnerFolder> = T & {
  parent?: INestedFolder;
  children?: INestedFolder<T>[];
};

type IApolloFolder = NonNullable<PartnerFoldersQuery['partner']>['folders']['items']['0'];

const toIFolder = (folders: IApolloFolder[]): IPartnerFolder[] =>
  folders.map((folder) => {
    const { id, name, description, path } = folder;
    const parentId = path[path.length - 2];

    return {
      id,
      name,
      description,
      parentId,
    };
  });

export const toNestedFolders = <T extends IPartnerFolder>(
  flattenedFolders: T[]
): INestedFolder<T>[] => {
  const getChildren = (parent: INestedFolder<T>): INestedFolder<T>[] => {
    const children: INestedFolder<T>[] = flattenedFolders
      .filter((folder) => folder.parentId === parent.id)
      .map((folder) => ({ ...folder, parent, children: getChildren(folder) }));

    return sortBy(children, 'label');
  };

  const topLevel = flattenedFolders.filter((f) => f.parentId === undefined);
  const sortedTopLevel = sortBy(topLevel, 'label');

  return sortedTopLevel.map((folder) => ({
    ...folder,
    children: getChildren(folder),
  }));
};

const QPartnerFolders = graphql(`
  query PartnerFolders($partnerId: ID!) {
    partner(id: $partnerId) {
      id
      folders(options: { perPage: 10000 }) {
        items {
          id
          name
          description
          path
        }
      }
    }
  }
`);

export const usePartnerFolders = (nested = false, options: IUseApolloQueryOptions = {}) => {
  const partnerId = usePartnerDomain();
  /*
   * we are tracking the data in state because we want a new data object be the result of every
   * refetch call. By default a refetch may not result in a different object if none of the data
   * returned from the server has changed.
   */
  const [refetchData, setRefetchData] = useState<PartnerFoldersQuery | undefined>();

  const variables: PartnerFoldersQueryVariables = {
    partnerId,
  };

  const [queryData, loading, , { refetch }] = useQuery(QPartnerFolders, variables, {
    fetchPolicy: 'network-only',
    ...options,
  });

  const variablesKey = objToKey(variables || {});
  useEffect(() => {
    setRefetchData(undefined);
  }, [variablesKey]);

  const handleRefresh = useSafeCallback(async () => {
    const nextResult = await refetch(variables);
    const nextData = cloneDeep(nextResult.data);

    setRefetchData(nextData);

    return {
      ...nextResult,
      data: nextData,
    };
  });

  const data = refetchData || queryData;
  const result: IPartnerFolder[] | INestedFolder[] = useMemo(() => {
    if (loading) {
      return [];
    }

    const items = data?.partner?.folders.items || [];

    const folders: IPartnerFolder[] = toIFolder(items);

    if (!nested) {
      return folders;
    }

    return toNestedFolders(folders);
  }, [loading, data, nested]);

  return [result, loading, handleRefresh] as const;
};
