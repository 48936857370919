import { useQuery } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../apollo';

export const QApplicationFileActivity = graphql(`
  query ApplicationFileActivity($applicationFileId: ID!, $cursor: String, $perPage: Int) {
    applicationFile(id: $applicationFileId) {
      id
      activityFeed(options: { cursor: $cursor, perPage: $perPage }) {
        dictionary
        items {
          id
          actor
          createdAt
          event
        }
        nextCursor
      }
    }
  }
`);

export interface IUseApplicationFileActivityFeedParams {
  applicationFileId: string;
  perPage?: number;
  skip?: boolean;
}

export const useApplicationFileActivity = (params: IUseApplicationFileActivityFeedParams) => {
  const { applicationFileId, perPage, skip } = params;

  const [result, loading, , { refetch, error }] = useQuery(
    QApplicationFileActivity,
    {
      applicationFileId,
      perPage,
    },
    {
      suspend: false,
      skip,
    }
  );

  const activityFeedResult = result?.applicationFile?.activityFeed;

  return [activityFeedResult, loading, refetch, error] as const;
};
