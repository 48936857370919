import { useCustomizations } from '@mortgagehippo/ds';
import { type ReactNode, useMemo } from 'react';

import { PermissionConfig, type PermissionType } from './permissions';
import { type IPermissionsContext, type IUserCan, PermissionsContext } from './permissions-context';
import { useUserScopes } from './use-user-scopes';

interface IPermissionsProviderProps {
  children?: ReactNode;
}

export const PermissionsProvider = (props: IPermissionsProviderProps) => {
  const { children } = props;
  const [scopes, scopesReady, { user }] = useUserScopes();

  const customizations = useCustomizations();

  const value: IPermissionsContext = useMemo(() => {
    const result: Partial<IUserCan> = {};

    const permissions = Object.keys(PermissionConfig) as PermissionType[];

    permissions.forEach((permission) => {
      const config = PermissionConfig[permission];
      const { requiredScopes, condition, requiredCustomizations } = config;

      const hasScopes =
        scopesReady && condition === 'or'
          ? requiredScopes.some((s) => scopes.includes(s))
          : requiredScopes.every((s) => scopes.includes(s));

      const hasCustomizations = !requiredCustomizations || requiredCustomizations(customizations);

      result[permission] = hasScopes && hasCustomizations;
    });

    return {
      can: result as IUserCan,
      ready: scopesReady && customizations.ready(),
      user,
    };
  }, [customizations, scopes, scopesReady, user]);

  return <PermissionsContext.Provider value={value}>{children}</PermissionsContext.Provider>;
};
