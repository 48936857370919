import { useQuery } from '@mortgagehippo/apollo-hooks';
import { useAuth } from '@mortgagehippo/auth';
import { useMemo } from 'react';

import { graphql } from '../apollo';
import { client } from '../apollo/apollo-client';
import { type CurrentUserDataQuery, type CurrentUserDataQueryVariables } from '../apollo/graphql';
import { auth } from '../services/auth';
import { getPartnerDomain, usePartnerDomain } from './use-partner-domain';

export const FCurrentUserData = graphql(`
  fragment CurrentUserDataFragment on Agent {
    id
    name
    email
    phone
    cellPhone
    alternatePhone
    secondaryEmail
    bio
    jobTitle
    facebookUrl
    linkedinUrl
    twitterUrl
    avatarUrl
    notificationOptIns
  }
`);

const QCurrentUserData = graphql(`
  query CurrentUserData($partnerId: ID!, $agentId: ID!) {
    me {
      id
      createdAt
      lastLoginAt
      loginCount
    }
    partner(id: $partnerId) {
      id
      agent(id: $agentId) {
        ...CurrentUserDataFragment
        authRole {
          name
        }
      }
    }
  }
`);

export type ILPUser = NonNullable<CurrentUserDataQuery['me']> &
  NonNullable<CurrentUserDataQuery['partner']>['agent'];

export const useUser = (): [ILPUser | null | undefined, boolean] => {
  const partnerId = usePartnerDomain();
  const [user] = useAuth();
  const userId = user?.id;

  const [data, loading] = useQuery(
    QCurrentUserData,
    { partnerId, agentId: userId! },
    { skip: !userId }
  );

  const result = useMemo(() => {
    if (loading) {
      return undefined;
    }

    if (!data?.partner?.agent || !data.me) {
      return null;
    }

    return {
      ...data.me,
      ...data.partner.agent,
    };
  }, [data, loading]);

  return [result, loading] as const;
};

export const getUser = async (): Promise<ILPUser | null> => {
  const partnerId = getPartnerDomain();
  const user = auth.getAccessTokenPayload();
  const userId = user?.id;

  if (!userId) {
    return null;
  }

  const result = await client.query<CurrentUserDataQuery, CurrentUserDataQueryVariables>({
    query: QCurrentUserData,
    variables: { partnerId, agentId: userId },
  });

  const { data } = result;

  if (!data.partner?.agent || !data.me) {
    return null;
  }

  return {
    ...data.me,
    ...data.partner.agent,
  };
};
