import { type IModalFormProps, Input, ModalForm } from '@mortgagehippo/ds';

interface IJobFunctionsModalProps extends Omit<IModalFormProps, 'children'> {
  disabled?: boolean;
}

export const JobFunctionsModal = (props: IJobFunctionsModalProps) => {
  const { disabled = false, ...rest } = props;

  return (
    <ModalForm {...rest} okButtonDisabled={disabled}>
      <Input.Box name="name" label="Name" required disabled={disabled} />
    </ModalForm>
  );
};
