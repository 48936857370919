import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../apollo';

const MTriggerMortgageflexPush = graphql(`
  mutation TriggerMortgageflexPush($applicationFileId: ID!) {
    triggerMortgageFlexPush(applicationFileId: $applicationFileId)
  }
`);

export const useTriggerMortgageflexPush = () => {
  const fn = useMutation(MTriggerMortgageflexPush, undefined);

  return useCallback(
    async (applicationFileId: string) => {
      const response = await fn({ applicationFileId });
      return response.data!.triggerMortgageFlexPush;
    },
    [fn]
  );
};
