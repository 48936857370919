import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../apollo';

const MTriggerEncompassApiDocumentsPush = graphql(`
  mutation TriggerEncompassApiDocumentsPush($applicationFileId: ID!, $documentId: ID!) {
    triggerEncompassApiDocumentsPush(applicationFileId: $applicationFileId, documentId: $documentId)
  }
`);

export const useTriggerEncompassDocumentsPush = () => {
  const fn = useMutation(MTriggerEncompassApiDocumentsPush, undefined);

  return useCallback(
    async (applicationFileId: string, documentId: string) => {
      const response = await fn({ applicationFileId, documentId });

      return response.data!.triggerEncompassApiDocumentsPush;
    },
    [fn]
  );
};
