import { isArray } from 'lodash-es';

import { NotificationOptInType } from '../../apollo/graphql';
import { NotificationOptInsFieldInternalValue } from './types';

export const fromExternalValue = (value: any) => {
  if (!isArray(value)) {
    return undefined;
  }

  if (value.includes(NotificationOptInType.email) && value.includes(NotificationOptInType.sms)) {
    return NotificationOptInsFieldInternalValue.EmailAndTextMessage;
  }

  if (value.includes(NotificationOptInType.email)) {
    return NotificationOptInsFieldInternalValue.Email;
  }

  if (value.includes(NotificationOptInType.sms)) {
    return NotificationOptInsFieldInternalValue.TextMessage;
  }

  return undefined;
};

export const toExternalValue = (value: NotificationOptInsFieldInternalValue) => {
  switch (value) {
    case NotificationOptInsFieldInternalValue.Email: {
      return [NotificationOptInType.email];
    }
    case NotificationOptInsFieldInternalValue.TextMessage: {
      return [NotificationOptInType.sms];
    }
    case NotificationOptInsFieldInternalValue.EmailAndTextMessage: {
      return [NotificationOptInType.email, NotificationOptInType.sms];
    }
    default:
      return [];
  }
};
