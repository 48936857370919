import { Button, palette, Popover, spacing, T } from '@mortgagehippo/ds';
import { type ReactNode, useMemo } from 'react';
import styled from 'styled-components';

import { type IBulkCustomTask } from '../../types';
import { TaskIcon } from './task-icon';

const IconColumn = styled('div')`
  padding-right: ${spacing(2)};
  flex: 0 0 auto;
  outline: 0;
  cursor: pointer;
`;

const LabelColumn = styled('div')`
  padding-right: ${spacing(2)};
  flex: 1 1 auto;
  outline: 0;
  cursor: pointer;
`;

const ActionsColumn = styled('div')`
  flex: 0 0 auto;
  white-space: nowrap;
  z-index: 1;
`;

const Box = styled.div`
  display: flex;
  align-items: stretch;
  margin: 0;
  padding: ${spacing(3)} ${spacing(5)};

  ${ActionsColumn} {
    visibility: hidden;
  }

  &:hover {
    background-color: ${palette('primary100')};

    ${ActionsColumn} {
      visibility: visible;
    }
  }
`;

interface ITasksArrayListItemProps {
  itemValue: IBulkCustomTask;
  renderItemLabel?: (itemValue: IBulkCustomTask) => ReactNode;
  onEdit: () => void;
  onDelete: () => void;
}

export const TasksArrayListItem = (props: ITasksArrayListItemProps) => {
  const { itemValue, onEdit, onDelete, renderItemLabel } = props;
  const { title, type } = itemValue;

  const label = useMemo(() => {
    if (!renderItemLabel) {
      return undefined;
    }

    return renderItemLabel(itemValue);
  }, [itemValue, renderItemLabel]);

  return (
    <Box onClick={onEdit} onKeyDown={onEdit} role="button" tabIndex={0}>
      <IconColumn>
        <TaskIcon taskType={type} />
      </IconColumn>

      <LabelColumn>{label || title}</LabelColumn>

      <ActionsColumn>
        <Popover
          content={<T>Are you sure you want to delete this item?</T>}
          confirmLabelYes={<T>Yes</T>}
          confirmLabelNo={<T>No</T>}
          onConfirm={onDelete}
          confirm
          align="TopRight"
          buttonProps={{
            className: 'delete',
            importance: 'tertiary',
            icon: 'delete-close',
            size: 'xs',
            iconButton: true,
            iconButtonRound: true,
            compact: true,
          }}
        >
          Remove
        </Popover>

        <Button
          importance="tertiary"
          icon="edit"
          size="xs"
          iconButton
          iconButtonRound
          compact
          onClick={onEdit}
          className="edit"
        >
          Edit
        </Button>
      </ActionsColumn>
    </Box>
  );
};
