import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo';
import { type UpdateFollowUpTaskInput } from '../../apollo/graphql';

export const MUpdateFollowUpTask = graphql(`
  mutation UpdateFollowUpTaskMutation($taskId: ID!, $data: UpdateFollowUpTaskInput!) {
    updateFollowUpTask(taskId: $taskId, data: $data) {
      id
      meta {
        title
      }
      data
      dueDate
    }
  }
`);

export const useUpdateFollowUpTask = () => {
  const updateFollowUpTask = useMutation(MUpdateFollowUpTask, undefined);

  return useCallback(
    async (taskId: string, data: UpdateFollowUpTaskInput) => {
      const response = await updateFollowUpTask({
        taskId,
        data,
      });

      return response.data!.updateFollowUpTask;
    },
    [updateFollowUpTask]
  );
};
