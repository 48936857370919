import { useCallback } from 'react';

import {
  ActionStatus,
  ActionType,
  type IPushToLosAction,
  type IUpdateApplicantBorrowerInformationAction,
  type IUpdateLendingQbPushAction,
  useActionEffect,
  useDispatchAction,
} from '$components/actions';
import { useApplicationStatuses } from '$components/application-status-dropdown';
import { useApplicationFileCan } from '$components/permissions';

import { useApplicationFile } from '../../pages/application/use-application-file-data';
import { usePrimaryApplicantsFiles } from '../../pages/application/use-primary-applicants-files';
import { history } from '../../services/history';
import { ApplicationHeader } from './application-header';

interface IApplicationHeaderContainerProps {
  applicationFileId: string;
}

export const ApplicationHeaderContainer = (props: IApplicationHeaderContainerProps) => {
  const { applicationFileId } = props;

  const [{ applicationFile, applicants }, refetch] = useApplicationFile(applicationFileId);
  const [{ data: primaryApplicantsFiles }] = usePrimaryApplicantsFiles(applicationFileId);
  const [statuses] = useApplicationStatuses();

  const [can] = useApplicationFileCan(applicationFileId);

  const dispatch = useDispatchAction();

  useActionEffect<
    IPushToLosAction | IUpdateApplicantBorrowerInformationAction | IUpdateLendingQbPushAction
  >(
    async (action) => {
      if (
        action.type !== ActionType.PUSH_TO_LOS &&
        action.type !== ActionType.UPDATE_APPLICANT_BORROWER_INFORMATION &&
        action.type !== ActionType.UPDATE_LENDING_QB_PUSH
      ) {
        return;
      }

      const { applicationFileId: actionApplicationFileId } = action;

      if (actionApplicationFileId !== applicationFileId) {
        // this action belongs to another application file ID
        return;
      }

      await refetch();
    },
    undefined,
    ActionStatus.DONE
  );

  const handleStartApplication = useCallback(() => {
    dispatch(
      {
        type: ActionType.DUPLICATE_APPLICATION,
        applicationFileId,
      },
      (_action, status, result) => {
        const { applicationFileId: nextApplicationFileId } = result || {};

        if (status !== ActionStatus.DONE || !nextApplicationFileId) {
          return;
        }

        history.push(`#/applications/${nextApplicationFileId}`);
      }
    );
  }, [applicationFileId, dispatch]);

  const handleUpdateApplicantEmail = useCallback(
    (applicantId: string) => {
      dispatch({
        type: ActionType.UPDATE_APPLICANT_BORROWER_INFORMATION,
        applicationFileId,
        applicantId,
      });
    },
    [applicationFileId, dispatch]
  );

  const handleDeleteApplicant = useCallback(
    (applicantId: string) => {
      dispatch({
        type: ActionType.DELETE_APPLICANT,
        applicationFileId,
        applicantId,
      });
    },
    [applicationFileId, dispatch]
  );

  const showApplicationStatus = statuses.length > 0;

  return (
    <ApplicationHeader
      applicationFile={applicationFile}
      applicants={applicants}
      primaryApplicantsFiles={primaryApplicantsFiles}
      showApplicationStatus={showApplicationStatus}
      disableApplicationStatus={!can.UPDATE_APPLICATION_STATUS}
      onStartApplication={can.CREATE_APPLICATION_FILE ? handleStartApplication : undefined}
      onUpdateApplicantEmail={can.UPDATE_APPLICANT_EMAIL ? handleUpdateApplicantEmail : undefined}
      onDeleteApplicant={can.DELETE_BORROWER ? handleDeleteApplicant : undefined}
    />
  );
};
