import { Col, Form, Modal, Row, spacing, SubmitButton } from '@mortgagehippo/ds';
import {
  type IQueryTableColumns,
  type IQueryTableDataActions,
  QueryTable,
} from '@mortgagehippo/query-components';
import { isPromise, UnreachableCaseError } from '@mortgagehippo/util';
import { useCallback, useMemo, useRef, useState } from 'react';
import { AgentSelect } from 'src/components/agent-select';
import styled from 'styled-components';

import { useUserCan } from '$components/permissions';

import {
  type JobFunctionAgentsListQuery,
  type JobFunctionAgentsListQueryVariables,
} from '../../../../apollo/graphql';
import { type IJobFunctionAgent, QJobFunctionAgentsList } from '../queries';

const TableWrapper = styled.div`
  margin-bottom: ${spacing(3)};
`;

enum UsersModalActionType {
  DELETE = 'delete',
}

const columns: IQueryTableColumns<IJobFunctionAgent> = [
  {
    title: 'Name',
    key: 'name',
    render: (t) => t.name,
  },
  {
    title: 'Email',
    key: 'email',
    render: (t) => t.email,
  },
];

interface IUsersModalProps {
  siteId: string;
  jobFunctionId?: string;
  title?: string;
  onAdd?: ((agentId: string) => Promise<any>) | ((agentId: string) => void);
  onDelete?: ((agentId: string) => Promise<any>) | ((agentId: string) => void);
  onRequestClose?: () => void;
  isOpen: boolean;
  width?: number;
}

export const UsersModal = (props: IUsersModalProps) => {
  const { siteId, jobFunctionId, onAdd, onDelete, onRequestClose, isOpen, ...rest } = props;
  const tableRef = useRef<any>();
  const [formKey, setFormKey] = useState(0);
  const [can] = useUserCan();

  const skip = !isOpen || !jobFunctionId;

  const handleAdd = useCallback(
    async (values: any) => {
      if (!onAdd) {
        return;
      }

      const { agentId } = values;
      const result = onAdd(agentId);

      if (isPromise(result)) {
        await result;
      }

      setFormKey(formKey + 1);

      tableRef!.current.refetch();
    },
    [formKey, onAdd]
  );

  const handleDelete = useCallback(
    async (record: IJobFunctionAgent) => {
      if (!onDelete) {
        return;
      }

      const result = onDelete(record.id);

      if (isPromise(result)) {
        await result;
      }

      tableRef!.current.refetch();
    },
    [onDelete]
  );

  const handleAction = useCallback(
    async (actionKey: string, record: IJobFunctionAgent) => {
      const actionType = actionKey as UsersModalActionType;

      switch (actionType) {
        case UsersModalActionType.DELETE: {
          await handleDelete(record);
          break;
        }
        default: {
          throw new UnreachableCaseError(actionType);
        }
      }
    },
    [handleDelete]
  );

  const actions: IQueryTableDataActions = useMemo(
    () => [
      {
        key: 'options',
        label: 'Options',
        buttonProps: {
          icon: 'menu-dots',
        },
        hidden: !can.DELETE_JOB_FUNCTION_USERS,
        onGroupAction: handleAction,
        actions: [
          {
            key: UsersModalActionType.DELETE,
            label: 'Delete',
            iconProps: {
              name: 'delete',
            },
            confirm: {
              title: 'Warning',
              explanation:
                'Are you sure you want to remove this user from this role? This action cannot be undone.',
              type: 'warning',
            },
            hidden: !can.DELETE_JOB_FUNCTION_USERS,
          },
        ],
      },
    ],
    [can.DELETE_JOB_FUNCTION_USERS, handleAction]
  );

  return (
    <Modal
      hideCancelButton
      onRequestClose={onRequestClose}
      onOk={onRequestClose}
      isOpen={isOpen}
      {...rest}
    >
      {can.CREATE_JOB_FUNCTION_USERS ? (
        <Form key={formKey} onSubmit={handleAdd}>
          <Row>
            <Col xs={10}>
              <AgentSelect siteId={siteId} name="agentId" required size="sm" compact />
            </Col>
            <Col xs={6}>
              <SubmitButton type="default" importance="secondary" size="xs" compact>
                Add to role
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ) : null}
      <TableWrapper>
        <QueryTable<JobFunctionAgentsListQuery, JobFunctionAgentsListQueryVariables>
          caption="Agents"
          rowActions={actions}
          query={QJobFunctionAgentsList}
          dataSource={(result) => result.site?.jobFunction?.agents.items || []}
          itemTotal={(result) => result.site?.jobFunction?.agents.total || 0}
          nextCursor={(result) => result.site?.jobFunction?.agents.nextCursor}
          previousCursor={(result) => result.site?.jobFunction?.agents.previousCursor}
          rowKey={(item) => item.id}
          variables={{ siteId, jobFunctionId: jobFunctionId! }}
          columns={columns}
          ref={tableRef}
          skip={skip}
          bottomContent={<QueryTable.Pagination />}
        >
          <QueryTable.Data />
        </QueryTable>
      </TableWrapper>
    </Modal>
  );
};
