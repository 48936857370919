import {
  Avatar,
  createField,
  type IFieldInputProps,
  type IFile,
  type IUploadProps,
  ReadOnlyBox,
  Upload as UploadComponent,
} from '@mortgagehippo/ds';
import { useCallback } from 'react';

const IMAGE_MIME_TYPES = ['image/png', 'image/jpeg'];

interface IUploadAvatarInputProps
  extends Omit<IUploadProps, 'maxItems' | 'accept' | 'descriptionField' | 'onDescriptionChange'>,
    IFieldInputProps<IFile[]> {
  value: IFile[];
  // eslint-disable-next-line react/no-unused-prop-types
  onChange: (f: IFile[]) => void;
}

const UploadAvatarInput = (props: IUploadAvatarInputProps) => {
  const { name, value, onChange, minSize, maxSize, onUpload, onDelete, disabled } = props;

  const avatarFile = value.length ? value[0] : null;

  const onFileUploaded = useCallback(
    (f: IFile[]) => {
      onChange(f);
    },
    [onChange]
  );

  return (
    <UploadComponent
      name={name}
      value={value}
      onChange={onFileUploaded}
      onUpload={onUpload}
      onDelete={onDelete}
      minSize={minSize}
      maxSize={maxSize}
      maxItems={1}
      accept={IMAGE_MIME_TYPES}
      disabled={disabled}
      fileTypeDescription="photo"
      compact
      childrenPosition="top"
    >
      <Avatar size="lg" src={avatarFile?.url} alt="Avatar" />
    </UploadComponent>
  );
};

export const UploadAvatarInputView = (props: IUploadAvatarInputProps) => {
  const { value } = props;

  const avatarFile = value.length ? value[0] : null;

  return (
    <ReadOnlyBox>
      <Avatar size="xl" src={avatarFile?.url} alt="Avatar" />
    </ReadOnlyBox>
  );
};

export const UploadAvatar = createField<IUploadAvatarInputProps>(
  UploadAvatarInput,
  UploadAvatarInputView,
  {
    initialValue: [],
  }
);
