import {
  borderRadius,
  MediaBreakpoint,
  palette,
  Popover,
  Responsive,
  spacing,
  Title,
} from '@mortgagehippo/ds';
import { type ITaskTemplate } from '@mortgagehippo/tasks';
import styled from 'styled-components';

import { type IApplicationApplicant } from '../../../../../pages/application/use-application-file-data';
import { type IUnderwritingCondition } from '../../../../../pages/application/use-undewriting-conditions';
import { type IBulkCustomTask } from '../../types';
import { TaskChoice } from './task-choice';
import { TasksList } from './tasks-list';

const Container = styled.div`
  display: flex;
  align-items: stretch;
  overflow: hidden;

  ${MediaBreakpoint.TABLET} {
    display: block;
  }
`;

const Scrollable = styled.div<{ padded?: boolean }>`
  height: auto;
  max-height: calc(100vh - 270px);
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: ${(p) => (p.padded ? spacing(4) : '0')};
`;

const ChoiceColumn = styled.div`
  flex: 0 0 38%;
  margin: 0 ${spacing(6)} 0 0;
  display: flex;
  flex-direction: column;

  ${MediaBreakpoint.TABLET} {
    display: block;
    margin: 0;
  }
`;

const Border = styled.div`
  position: relative;
  border-right: 1px solid ${palette('neutral300')};
  flex: 1 1 auto;
  padding-right: ${spacing(4)};

  ${MediaBreakpoint.TABLET} {
    padding: 0;
    border-right: none;
  }
`;

const Triangle = styled.div`
  position: absolute;
  top: 50%;
  right: -20px;
  margin-top: -10px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-left-color: ${palette('neutral300')};

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -8px;
    left: -10px;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-left-color: ${palette('white')};
  }

  ${MediaBreakpoint.TABLET} {
    display: none;
  }
`;

const ListColumn = styled.div`
  flex: 1 1 62%;
  display: flex;
  flex-direction: column;

  ${MediaBreakpoint.TABLET} {
    display: block;
  }
`;

const Background = styled.div`
  background-color: ${palette('neutral50')};
  border-radius: ${borderRadius(3)};
  flex: 1 1 auto;
  overflow: hidden;
`;

interface IStep1Props {
  applicants: IApplicationApplicant[];
  templates: ITaskTemplate[];
  tasks: IBulkCustomTask[];
  underwritingConditions?: IUnderwritingCondition[];
  onCreate: (templateKey: string) => void;
  onEdit: (index: number) => void;
  onDelete: (index: number) => void;
}

export const Step1 = (props: IStep1Props) => {
  const { applicants, templates, tasks, underwritingConditions, onCreate, onEdit, onDelete } =
    props;

  return (
    <Container>
      <ChoiceColumn>
        <Responsive>
          <Responsive.Desktop>
            <Title level={4} caps="uppercase">
              Select a Task To Add
            </Title>
            <Border>
              <Triangle />
              <Scrollable padded>
                <TaskChoice templates={templates} onCreate={onCreate} />
              </Scrollable>
            </Border>
          </Responsive.Desktop>
          <Responsive.Tablet>
            <Popover
              content={
                <Scrollable padded>
                  <TaskChoice templates={templates} onCreate={onCreate} />
                </Scrollable>
              }
              buttonProps={{
                size: 'sm',
                align: 'left',
                block: true,
                icon: 'down-arrow-small',
                iconLocation: 'right',
              }}
              widthCSS="90vw"
              maxWidthCSS="none"
            >
              Add follow-up task
            </Popover>
          </Responsive.Tablet>
        </Responsive>
      </ChoiceColumn>
      <ListColumn>
        <Responsive>
          <Responsive.Desktop>
            <Title level={4} caps="uppercase">
              Added Follow-up tasks
            </Title>
            <Background>
              <Scrollable>
                <TasksList
                  tasks={tasks}
                  applicants={applicants}
                  underwritingConditions={underwritingConditions}
                  onEdit={onEdit}
                  onDelete={onDelete}
                />
              </Scrollable>
            </Background>
          </Responsive.Desktop>
          <Responsive.Tablet>
            <Background>
              <TasksList
                tasks={tasks}
                applicants={applicants}
                underwritingConditions={underwritingConditions}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            </Background>
          </Responsive.Tablet>
        </Responsive>
      </ListColumn>
    </Container>
  );
};
