import {
  Alert,
  borderRadius,
  Button,
  Constant,
  Email,
  FieldBox,
  fontSize,
  type FormSubmitHandler,
  HelpButton,
  Input,
  ItemizationList,
  ModalForm,
  palette,
  SimpleFieldAutoArray,
  spacing,
  Text,
  type UploadHandler,
  UploadInput,
  useFormValue,
  useModal,
} from '@mortgagehippo/ds';
import { ACCEPTED_DOCUMENT_MIME_TYPES } from '@mortgagehippo/tasks';
import { isEmpty, toArray } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { type DocusignExternalSigner } from '../../../../../apollo/graphql';
import { type IApplicationApplicant } from '../../../../../pages/application/use-application-file-data';
import { DocusignEditor } from '../../../edit-custom-task-action/custom-docusign-task/docusign-editor';
import { DefaultFields } from '../default-fields';

const ExternalSignersTitle = styled.span`
  font-size: ${fontSize('sm')};
`;

const ExternalSignersTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const ExternalSignersContainer = styled.div`
  background-color: ${palette('neutral50')};
  border-radius: ${borderRadius(2)};
  padding: ${spacing(4)};
  color: ${palette('neutral600')};
  margin-bottom: ${spacing(5)};

  li:first-child {
    margin-top: 0;
  }
`;

const ModalDescription = styled.p`
  margin-bottom: ${spacing(5)};
`;

const Row = styled.div`
  display: flex;
  align-items: stretch;
  margin-bottom: ${spacing(4)};
`;

const Col = styled.div<{ flex?: number }>`
  flex: ${({ flex }) => (flex === undefined ? 1 : flex)};
  flex-basis: ${({ flex }) => (flex === undefined ? '100%' : 'auto')};
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

const validator = (value: any, allValues: Record<string, any>) => {
  const { externalSigners } = allValues;
  if (!externalSigners?.length) {
    return undefined;
  }

  const { firstName, lastName, email } = value;

  let errors;

  if (!firstName) {
    errors = {
      firstName: 'First name is required.',
    };
  }

  if (!lastName) {
    errors = {
      ...errors,
      lastName: 'Last name is required.',
    };
  }

  if (!email) {
    errors = {
      ...errors,
      email: 'Email is required.',
    };
  }

  return errors;
};

interface IDocusignTaskEditorFieldsProps {
  applicationFileId: string;
  applicants: IApplicationApplicant[];
  onUpload: UploadHandler;
  showDueDate?: boolean;
}

export const DocusignTaskEditorFields = (props: IDocusignTaskEditorFieldsProps) => {
  const { applicationFileId, applicants, onUpload, showDueDate } = props;

  const prepareButtonRef = useRef<HTMLButtonElement | null>();

  const [externalSigners, setExternalSigners] = useFormValue<DocusignExternalSigner[] | undefined>(
    'data.external_signers'
  );

  const [isOpen, openModal, closeModal] = useModal(false);
  const [applicantIds] = useFormValue('applicant_ids');
  const [documents] = useFormValue('data.documents');
  const [envelopeId, setEnvelopeId] = useFormValue('data.envelope_id');

  const setupCompleted = !!envelopeId;

  const [showSignatureEditor, setShowSignatureEditor] = useState(setupCompleted);

  const hasApplicants = toArray(applicantIds).length > 0;
  const hasDocuments = (documents || []).length > 0;

  const disableApplicantSelection = showSignatureEditor;
  const hideDocumentsField = showSignatureEditor;

  const showPrepareDocumentsButton = hasDocuments && !showSignatureEditor;
  const disablePrepareDocumentsButton = !hasApplicants;
  const showApplicantsAlert = showPrepareDocumentsButton && !hasApplicants;

  const handleShowSignatureEditor = useCallback(() => {
    setShowSignatureEditor(true);
  }, []);

  const handleSignatureEditorFinished = useCallback(
    (nextEnvelopeId: string) => {
      setEnvelopeId(nextEnvelopeId);
    },
    [setEnvelopeId]
  );

  const handleManageExternalSigners = useCallback(() => {
    openModal();
  }, [openModal]);

  const handleExternalSignersSubmit: FormSubmitHandler = useCallback(
    (values) => {
      const { externalSigners: externalSignersValue } = values;

      setExternalSigners(
        externalSignersValue.filter((externalSigner: unknown) => !isEmpty(externalSigner))
      );

      closeModal();
    },
    [closeModal, setExternalSigners]
  );

  const externalSignersList = useMemo(() => {
    if (!externalSigners?.length) {
      return [
        {
          id: 'no-signers',
          label: 'No external signers',
          value: '',
        },
      ];
    }

    return externalSigners.map((signer) => ({
      id: signer.email,
      label: `${signer.firstName} ${signer.lastName}`,
      value: signer.email,
    }));
  }, [externalSigners]);

  const modalInitialValues = useMemo(
    () => ({
      externalSigners,
    }),
    [externalSigners]
  );

  useEffect(() => {
    if (showPrepareDocumentsButton) {
      prepareButtonRef.current?.scrollIntoView();
    }
  }, [showPrepareDocumentsButton]);

  const hasExternalSigners = !!externalSigners?.length;

  return (
    <>
      <Constant name="data.envelope_id" />
      <Constant name="data.external_signers" />

      <DefaultFields
        applicants={applicants}
        allowCommon={false}
        allowMultipleApplicants
        disableApplicantSelection={disableApplicantSelection}
        showMultipleApplicantsAlert={false}
        showDueDate={showDueDate}
      >
        <div>
          <ExternalSignersTitleContainer>
            <ExternalSignersTitle>
              External signers
              <HelpButton icon="information" size="xs">
                External signers are people who are not part of the application but need to sign a
                document related to this application. They will receive an email from DocuSign
                inviting them to e-sign it. They will not have access to the application. The status
                of their signatures can be viewed from this task once you create and assign it to
                one or more of the borrowers.
              </HelpButton>
            </ExternalSignersTitle>
            <Button
              icon={hasExternalSigners ? 'edit' : 'plus'}
              onClick={handleManageExternalSigners}
              size="xs"
              importance="tertiary"
              compact
              disabled={disableApplicantSelection}
            >
              {`${hasExternalSigners ? 'Edit' : 'Add'} external signers`}
            </Button>
          </ExternalSignersTitleContainer>
          <ExternalSignersContainer>
            {hasExternalSigners ? (
              <ItemizationList items={externalSignersList} size="normal" />
            ) : (
              <Text size="normal">No external signers</Text>
            )}
          </ExternalSignersContainer>
        </div>
      </DefaultFields>

      {!hideDocumentsField && (
        <UploadInput.Box
          name="data.documents"
          label="Document"
          onUpload={onUpload}
          required
          maxItems={10}
          accept={ACCEPTED_DOCUMENT_MIME_TYPES}
          fileTypeDescription="document"
        />
      )}

      {showPrepareDocumentsButton ? (
        <Button
          ref={prepareButtonRef}
          icon="documents"
          importance="secondary"
          block
          onClick={handleShowSignatureEditor}
          disabled={disablePrepareDocumentsButton}
        >
          Prepare documents for signing
        </Button>
      ) : null}

      {showApplicantsAlert ? (
        <Alert type="warning" size="sm">
          Please assign one or more borrowers before preparing the documents.
        </Alert>
      ) : null}

      {showSignatureEditor ? (
        <DocusignEditor
          externalSigners={externalSigners}
          applicationFileId={applicationFileId}
          applicantIds={applicantIds}
          documents={documents}
          envelopeId={envelopeId}
          onFinish={handleSignatureEditorFinished}
        />
      ) : null}

      <ModalForm
        onRequestClose={closeModal}
        onSubmit={handleExternalSignersSubmit}
        isOpen={isOpen}
        title="External Signers"
        okButtonLabel="Save"
        width={800}
        initialValues={modalInitialValues}
      >
        <ModalDescription>Enter the names and emails of all external signers</ModalDescription>
        <FieldBox name="modal_external_signers" label="External Signers" labelInvisible>
          <SimpleFieldAutoArray
            name="externalSigners"
            presentFields={['firstName', 'lastName', 'email']}
            render={({ name: itemName, index, isLastItem, onRemove }) => (
              <Row key={itemName}>
                <Col flex={1}>
                  <Input.Box
                    name={`${itemName}.firstName`}
                    label="First Name"
                    placeholder="First Name"
                    labelInvisible
                    hideRequiredLabel
                    compact
                    required={index !== 0 && !isLastItem}
                  />
                </Col>
                <Col flex={1}>
                  <Input.Box
                    name={`${itemName}.lastName`}
                    label="Last Name"
                    placeholder="Last Name"
                    labelInvisible
                    hideRequiredLabel
                    compact
                    required={index !== 0 && !isLastItem}
                  />
                </Col>
                <Col>
                  <Email.Box
                    name={`${itemName}.email`}
                    label="Email"
                    placeholder="Email"
                    labelInvisible
                    hideRequiredLabel
                    compact
                    required={index !== 0 && !isLastItem}
                  />
                </Col>
                <Col flex={0}>
                  <Button
                    icon="delete"
                    iconButton
                    size="sm"
                    type="danger"
                    importance="tertiary"
                    onClick={onRemove}
                    compact
                  />
                </Col>
              </Row>
            )}
            validateItem={validator}
          />
        </FieldBox>
      </ModalForm>
    </>
  );
};
