import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';
import { useMemo } from 'react';

import { graphql } from '../../apollo';
import { type UnderwritingConditionsQuery } from '../../apollo/graphql';

export type IUnderwritingCondition = NonNullable<
  UnderwritingConditionsQuery['applicationFile']
>['underwritingConditions']['items'][0];

export type IUnderwritingConditionStats = NonNullable<
  UnderwritingConditionsQuery['applicationFile']
>['underwritingConditions']['stats'];

export type IUnderwritingConditionTask = NonNullable<IUnderwritingCondition['tasks']>[0];

const QUnderwritingConditions = graphql(`
  query UnderwritingConditions($applicationFileId: ID!, $underwritingConditionIds: [ID!]) {
    applicationFile(id: $applicationFileId) {
      id
      losLoanNumber
      underwritingConditions(underwritingConditionIds: $underwritingConditionIds) {
        items {
          id
          createdAt
          title
          description
          status
          applicants {
            id
          }
          tasks {
            id
            key
            title
            typeName
            isAlerted
            isFrozen
            isNew
            isVisible
            isStarted
            common
            custom
            blueprintIndex
            primaryApplicantId
            state
            meta {
              title
            }
            createdAt
          }
        }
        stats {
          open
          completed
        }
      }
    }
  }
`);

type IUseUnderwritingConditionsResult = [
  { items: IUnderwritingCondition[]; stats: IUnderwritingConditionStats; pushed: boolean },
  boolean,
  () => Promise<any>,
];

export const useUnderwritingConditions = (
  applicationFileId: string,
  underwritingConditionIds: string[] = [],
  options: IUseApolloQueryOptions = {}
): IUseUnderwritingConditionsResult => {
  const [data, loading, , { refetch }] = useQuery(
    QUnderwritingConditions,
    { applicationFileId, underwritingConditionIds },
    {
      suspend: false,
      fetchPolicy: 'network-only',
      ...options,
    }
  );

  const handleRefresh = useSafeCallback(() => refetch({ applicationFileId }));

  const result = useMemo(() => {
    const nextItems = data?.applicationFile?.underwritingConditions.items || [];
    const nextStats = data?.applicationFile?.underwritingConditions.stats || {
      open: 0,
      completed: 0,
    };
    const nextPushed = !!data?.applicationFile?.losLoanNumber;

    return {
      stats: nextStats,
      items: nextItems,
      pushed: nextPushed,
    };
  }, [data]);

  return [result, loading, handleRefresh] as const;
};
