import { Content, useCustomizations } from '@mortgagehippo/ds';
import { useCallback } from 'react';

import { useOnboardingFlag } from '../use-onboarding-flag';
import { Tutorials } from './constants';

export const useTutorial = (tutorialId: string | undefined) => {
  const [isSeenFlag, setIsSeenFlag, flagLoading] = useOnboardingFlag(tutorialId);

  const close = useCallback(() => {
    setIsSeenFlag(true);
  }, [setIsSeenFlag]);

  const open = useCallback(() => {
    setIsSeenFlag(false);
  }, [setIsSeenFlag]);

  const foundTutorial = tutorialId ? Tutorials[tutorialId] : undefined;

  const customizations = useCustomizations();
  const title = customizations.text(foundTutorial?.titleCid || undefined, foundTutorial?.title);
  const content =
    (foundTutorial && <Content cid={foundTutorial.contentCid}>{foundTutorial.content}</Content>) ||
    null;

  const loading = flagLoading || customizations.loading();

  const tutorial =
    content && !loading
      ? {
          title,
          content,
        }
      : undefined;
  const isOpen = !isSeenFlag && !loading;

  return [tutorial, isOpen, open, close] as const;
};
