import { notifications, Popover, T } from '@mortgagehippo/ds';
import { useState } from 'react';

import { useApplicationFile } from '../use-application-file-data';
import { ApplicationTaskReopenForm } from './application-task-reopen-form';
import { useOpenTask } from './use-open-task';

export interface IApplicationTaskReopenButtonProps {
  applicationFileId: string;
  taskKey: string;
  taskId: string;
  isFollowUp?: boolean;
}

export const ApplicationTaskReopenButton = (props: IApplicationTaskReopenButtonProps) => {
  const { applicationFileId, taskKey, taskId, isFollowUp } = props;
  const [isReopening, setIsReopening] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const openTask = useOpenTask(taskId);
  const [, refresh] = useApplicationFile(applicationFileId, { skip: true });

  const handleSubmit = async (reason: string, dueDate: string | null) => {
    setIsReopening(false);
    try {
      setSubmitting(true);
      await openTask({
        reason,
        dueDate,
      });
      await refresh();
      notifications.success({
        messageCid: 'application_tasks:reopen_success',
        message: 'Successfully Re-Opened task',
      });
    } catch (e) {
      notifications.error({
        messageCid: 'application_tasks:reopen_error',
        message: 'There was an unexpected error, re-opening task',
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleCancel = () => {
    setIsReopening(false);
  };

  const handleVisibilityChange = (b: boolean) => {
    setIsReopening(b);
  };

  return (
    <Popover
      size="sm"
      content={
        <ApplicationTaskReopenForm
          taskKey={taskKey}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          isFollowUp={isFollowUp}
        />
      }
      open={isReopening}
      onShow={() => {
        handleVisibilityChange(true);
      }}
      onHide={() => {
        handleVisibilityChange(false);
      }}
      maxWidthCSS="none"
      align="BottomRight"
      buttonProps={{
        icon: 'refresh',
        importance: 'secondary',
        size: 'xs',
        loading: submitting,
        dataAttributes: {
          'task-reopen-button': taskKey,
        },
      }}
    >
      <T cid="application_tasks:reopen_task_button">Re-open task</T>
    </Popover>
  );
};
