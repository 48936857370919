import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo';
import { type ArchiveApplicationFileInput } from '../../apollo/graphql';

const MArchiveApplicationFile = graphql(`
  mutation ArchiveApplicationFile($applicationFileId: ID!, $data: ArchiveApplicationFileInput) {
    archiveApplicationFile(applicationFileId: $applicationFileId, data: $data) {
      id
      archivedAt
      archivalReason
    }
  }
`);

export const useArchiveApplicationFile = () => {
  const archiveApplicationFile = useMutation(MArchiveApplicationFile, undefined);

  return useCallback(
    async (applicationFileId: string, data: ArchiveApplicationFileInput) => {
      await archiveApplicationFile({ applicationFileId, data });
    },
    [archiveApplicationFile]
  );
};
