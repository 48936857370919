import { type IUserCan } from '$components/permissions';

import { type IntegrationType } from '../../../apollo/graphql';
import { type IApplicationApplicant } from '../use-application-file-data';

export enum ServiceType {
  AccountChek = 'AccountChek',
  Byte = 'Byte',
  CreditPull = 'CreditPull',
  AUS = 'AUS',
  Docutech = 'Docutech',
  EncompassApi = 'EncompassApi',
  Finicity = 'Finicity',
  IDS = 'IDS',
  InternalSystem = 'InternalSystem',
  LendingQb = 'LendingQb',
  MortgageBot = 'MortgageBot',
  MortgageDirector = 'MortgageDirector',
  MortgageFlex = 'MortgageFlex',
  MortgageInsurance = 'MortgageInsurance',
  SmartFees = 'SmartFees',
  TotalExpert = 'TotalExpert',
  Velocify = 'Velocify',
  WorkNumber = 'WorkNumber',
  UnderwritingConditions = 'UnderwritingConditions',
  Truework = 'Truework',
}

export interface IApplicationServicePageProps {
  applicationFileId: string;
  applicants: IApplicationApplicant[];
}

export interface IApplicationService {
  name: string;
  nameCid?: string;
  path: string;
  type: ServiceType;
  position?: number;
  canAccess?: (can: IUserCan) => boolean;
  integrations: IntegrationType | IntegrationType[];
}
