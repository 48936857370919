import {
  ColumnOrderFieldType,
  type IField,
  type ISearchFields,
  type ITreeSelectOption,
  SearchFieldType,
} from '@mortgagehippo/ds';
import { type IQueryTableColumn, type IQueryTableColumns } from '@mortgagehippo/query-components';
import { type ISelectChoice } from '@mortgagehippo/tasks';
import { toArray } from '@mortgagehippo/util';
import * as Sentry from '@sentry/browser';
import { compact, get, isString, reduce } from 'lodash-es';
import styled from 'styled-components';

import { client } from '../../apollo/apollo-client';
import {
  type SmartviewChoicesQuery,
  type SmartviewChoicesQueryVariables,
  type SmartviewModelType,
} from '../../apollo/graphql';
import { QSmartviewChoices } from './queries';
import { SMARTVIEW_RENDERERS } from './renderers';
import {
  type ChoicesFn,
  type ISmartViewColumn,
  type ISmartviewDataFieldOptions,
  type ISmartviewFieldMetadata,
  type ISmartviewMetadata,
  type ISmartviewRecord,
  type ISmartViewSearchField,
  SmartviewFieldType,
} from './types';

const COLUMN_KEY_SEPARATOR = ':';

const Block = styled.div``;

const defaultColumnProps: Partial<IQueryTableColumn> = {
  alignMobile: 'left',
  colTitleLocationMobile: 'top',
  sortable: true,
};

export const getColumnKey = (column: ISmartViewColumn) => {
  const { sortField } = column;

  const fields = toArray(column.fields);

  // build the column key out of the fields relevant to this column
  const keyFields = sortField ? [sortField, ...fields] : fields;

  return keyFields.join(COLUMN_KEY_SEPARATOR);
};

export const getOrderBy = (sortField?: string, sortDirection?: string) => {
  if (!sortField || !sortDirection) {
    return undefined;
  }

  return {
    [sortField]: sortDirection,
  };
};

export const reduceDataFieldOptions = (fields: string[], metadata: ISmartviewMetadata) =>
  reduce(
    fields,
    (accum: Partial<ISmartviewDataFieldOptions>, renderField) => {
      const fieldMeta = metadata[renderField];

      if (!fieldMeta) {
        return accum;
      }

      const { options } = fieldMeta;
      const { column } = options || {};
      const { noWrap, minWidth } = column || {};

      return {
        ...accum,
        noWrap: accum.noWrap || noWrap,
        minWidth: Math.max(accum.minWidth || 0, minWidth || 0),
      };
    },
    {}
  );

export const toColumnOrderFieldType = (type: SmartviewFieldType): ColumnOrderFieldType => {
  switch (type) {
    case SmartviewFieldType.URL:
    case SmartviewFieldType.PHONE:
    case SmartviewFieldType.TEXT:
    case SmartviewFieldType.APPLICATION_STATUS:
    case SmartviewFieldType.TEAM_AGENTS:
      return ColumnOrderFieldType.STRING;
    case SmartviewFieldType.BOOLEAN:
      return ColumnOrderFieldType.BOOLEAN;
    case SmartviewFieldType.ID:
    case SmartviewFieldType.NUMBER:
    case SmartviewFieldType.CURRENCY:
    case SmartviewFieldType.PERCENT:
      return ColumnOrderFieldType.NUMBER;
    case SmartviewFieldType.DATE:
      return ColumnOrderFieldType.DATE;
    default:
      return ColumnOrderFieldType.STRING;
  }
};

export const toQueryTableColumns = (
  columns: ISmartViewColumn[],
  metadata: ISmartviewMetadata
): IQueryTableColumns<ISmartviewRecord> => {
  const nextColumns = columns.map((column) => {
    const { title, fields } = column;

    const key = getColumnKey(column);
    const renderFields = toArray(fields);

    const firstField = renderFields[0]!;
    const firstFieldMeta = metadata[firstField];

    // shouldn't happen but ignore column
    if (!firstFieldMeta) {
      return undefined;
    }

    // use title from first field
    const { title: firstFieldTitle, type: firstFieldType } = firstFieldMeta;

    const { noWrap, minWidth } = reduceDataFieldOptions(renderFields, metadata);

    // use metadata to build render function
    return {
      key,
      render: (record: ISmartviewRecord) =>
        renderFields.map((field) => {
          const value = get(record.data, field);

          const fieldMeta = metadata[field];

          // shouldn't happen but ignore field
          if (!fieldMeta) {
            return null;
          }

          const { type, options } = fieldMeta;

          const renderer = SMARTVIEW_RENDERERS[type];

          // shouldn't happen but just render the value
          if (!renderer) {
            return <Block key={field}>{value}</Block>;
          }

          return <Block key={field}>{renderer(value, record, options)}</Block>;
        }),
      title: title || firstFieldTitle,
      type: toColumnOrderFieldType(firstFieldType),
      noWrap,
      minWidth: minWidth ? `${minWidth}px` : undefined,
      ...defaultColumnProps,
    };
  });

  return compact(nextColumns);
};

export const getChoicesFn =
  (partnerId: string, model: SmartviewModelType, smartviewId?: string) =>
  (token: string) =>
  async (): Promise<ISelectChoice[] | ITreeSelectOption[]> => {
    try {
      const result = await client.query<SmartviewChoicesQuery, SmartviewChoicesQueryVariables>({
        query: QSmartviewChoices,
        variables: {
          partnerId,
          model,
          token,
          smartviewId,
        },
      });

      const choices = result.data?.partner?.smartview?.choices;

      return choices || [];
    } catch (e) {
      Sentry.captureException(e);
      return [];
    }
  };

const getInferredSearchFieldProps = (type: SmartviewFieldType): Partial<IField> | null => {
  switch (type) {
    case SmartviewFieldType.PHONE:
      return {
        inputMode: 'phone',
      };
    case SmartviewFieldType.CURRENCY:
      return {
        inputMode: 'currency',
      };
    case SmartviewFieldType.SSN:
      return {
        inputMode: 'ssn',
      };
    default:
      return null;
  }
};

export const toSearchFieldType = (
  type: SmartviewFieldType,
  fieldMeta: ISmartviewFieldMetadata
): SearchFieldType => {
  const { options } = fieldMeta;
  const { searchField } = options || {};
  const { choices } = searchField || {};

  if (choices) {
    return SearchFieldType.CHOICE;
  }

  switch (type) {
    case SmartviewFieldType.URL:
    case SmartviewFieldType.PHONE:
    case SmartviewFieldType.TEXT:
    case SmartviewFieldType.APPLICATION_STATUS:
    case SmartviewFieldType.TEAM_AGENTS:
    case SmartviewFieldType.SSN:
      return SearchFieldType.STRING;
    case SmartviewFieldType.BOOLEAN:
      return SearchFieldType.BOOLEAN;
    case SmartviewFieldType.ID:
    case SmartviewFieldType.NUMBER:
    case SmartviewFieldType.CURRENCY:
    case SmartviewFieldType.PERCENT:
      return SearchFieldType.NUMBER;
    case SmartviewFieldType.DATE:
      return SearchFieldType.DATE;
    default:
      return SearchFieldType.STRING;
  }
};

export const toSearchFields = (
  searchFields: ISmartViewSearchField[],
  metadata: ISmartviewMetadata,
  choicesFn: ChoicesFn
): ISearchFields => {
  const nextSearchFields = reduce(
    searchFields,
    (accum: ISearchFields, searchField) => {
      const { label, field } = searchField;

      const fieldMeta = metadata[field];

      // shouldn't happen but ignore field
      if (!fieldMeta) {
        return accum;
      }

      const { title, type, options } = fieldMeta;
      const { searchField: searchFieldOptions } = options || {};
      const { group, choices, inputMode } = searchFieldOptions || {};

      const inferredProps = getInferredSearchFieldProps(type);

      // choices might be a token to use for an async request
      const nextChoices = isString(choices) ? choicesFn(choices) : choices;

      const nextField: IField = {
        ...(inferredProps || {}),
        label: label || title,
        type: toSearchFieldType(type, fieldMeta),
        group,
        choices: nextChoices,
      };

      if (inputMode) {
        nextField.inputMode = inputMode;
      }

      // eslint-disable-next-line no-param-reassign
      accum[field] = nextField;

      return accum;
    },
    {}
  );

  return nextSearchFields;
};

export const truncateText = (text: string, length: number) => {
  if (text.length <= length) {
    return text;
  }

  // truncate down to desired length
  let substr = text.slice(0, length - 1);

  // truncate down to end of last full word
  substr = substr.slice(0, substr.lastIndexOf(' '));

  return substr;
};
