import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo';
import { type UpdatePaymentTaskInput } from '../../apollo/graphql';

export const MUpdatePaymentTask = graphql(`
  mutation UpdatePaymentTask($taskId: ID!, $data: UpdatePaymentTaskInput!) {
    updatePaymentTask(taskId: $taskId, data: $data) {
      id
      meta {
        title
      }
      data
      dueDate
    }
  }
`);

export const useUpdatePaymentTask = () => {
  const updatePaymentTask = useMutation(MUpdatePaymentTask);

  return useCallback(
    async (taskId: string, data: UpdatePaymentTaskInput) => {
      const response = await updatePaymentTask({
        taskId,
        data,
      });

      return response.data!.updatePaymentTask;
    },
    [updatePaymentTask]
  );
};
