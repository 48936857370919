import { keyBy } from 'lodash-es';
import { useCallback, useMemo } from 'react';

import { type IFolderAgent, useFolderAgents } from '../../../hooks/use-folder-agents';
import { usePartnerAgents } from '../../../hooks/use-partner-agents';
import { AddFolderUserPopoverForm } from './add-folder-user-popover-form';
import { AddFolderUserPopoverSkeleton } from './add-folder-user-popover-skeleton';
import { type ManagedFolder } from './manager';

interface AddFolderUserPopoverContainerProps {
  folder: ManagedFolder;
  onAddUsers: (agents: IFolderAgent[]) => void;
  onCancel: () => void;
}

export const AddFolderUserPopoverContainer = (props: AddFolderUserPopoverContainerProps) => {
  const { folder, onAddUsers, onCancel } = props;

  const skipFolderAgents = folder.isNew();
  const [unassignedAgents, unassignedAgentsLoading] = useFolderAgents(folder.getId(), true, {
    skip: skipFolderAgents,
    fetchPolicy: 'no-cache',
  });

  const skipPartnerAgents = !folder.isNew();
  const [partnerAgents, partnerAgentsLoading] = usePartnerAgents({
    skip: skipPartnerAgents,
  });

  const loading = unassignedAgentsLoading || partnerAgentsLoading;

  const agents = useMemo(() => {
    const addedUsers = folder.getUsers().filter((user) => user.isNew());
    const addedUsersById = keyBy(addedUsers, (user) => user.getId());

    if (folder.isNew()) {
      return partnerAgents.filter((agent) => !(agent.id in addedUsersById));
    }

    return unassignedAgents.filter((agent) => !(agent.id in addedUsersById));
  }, [folder, partnerAgents, unassignedAgents]);

  const handleSubmit = useCallback(
    (values: any) => {
      const agentIds = new Set(values.agents);

      const agentsToAdd = agents.filter((agent) => agentIds.has(agent.id));

      onAddUsers(agentsToAdd);
    },
    [onAddUsers, agents]
  );

  if (loading) {
    return <AddFolderUserPopoverSkeleton />;
  }

  return (
    <AddFolderUserPopoverForm
      folderName={folder.getName()}
      agents={agents}
      onSubmit={handleSubmit}
      onCancel={onCancel}
    />
  );
};
