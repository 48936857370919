import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../../apollo';

export const MDeleteLenderDocumentMutation = graphql(`
  mutation DeleteLenderDocument($applicationFileId: ID!, $documentId: ID!) {
    deleteLenderDocument(applicationFileId: $applicationFileId, documentId: $documentId) {
      id
    }
  }
`);

export const useDeleteLenderDocument = () => {
  const deleteLenderDocument = useMutation(MDeleteLenderDocumentMutation);

  return async (applicationFileId: string, documentId: string) => {
    await deleteLenderDocument({ applicationFileId, documentId });
  };
};
