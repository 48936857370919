import { FormSection, Select, useFormValue } from '@mortgagehippo/ds';

import { useSites } from '../../../hooks/use-sites';
import { AgentModalSiteSection } from './agent-modal-site-section';

export interface IAgentModalSitesSectionProps {
  agentId?: string;
}

export const AgentModalSitesSection = (props: IAgentModalSitesSectionProps) => {
  const { agentId } = props;
  const [sites, loading] = useSites();
  const [selected] = useFormValue<string[] | undefined>('sites');

  return (
    <>
      <FormSection title="Sites">
        <Select.Box
          name="sites"
          label="Sites"
          options={sites || []}
          loading={loading}
          multiple
          required
        />
      </FormSection>
      {(selected || []).map((siteId) => {
        const siteName = sites?.find((site) => site.value === siteId)?.label;
        return (
          <AgentModalSiteSection
            key={siteId}
            siteId={siteId}
            siteName={siteName as string}
            agentId={agentId}
          />
        );
      })}
    </>
  );
};
