import { useMutation } from '@mortgagehippo/apollo-hooks';
import { notifications } from '@mortgagehippo/ds';
import { useCallback, useState } from 'react';

import { MSwitchAgentPermission } from '../queries';
import { UsersModal } from './users-modal';

interface IUsersModalContainerProps {
  partnerId: string;
  authRoleId?: string;
  authRoleName?: string;
  title?: string;
  onRequestClose?: (triggerRefresh?: boolean) => void;
  isOpen: boolean;
  width?: number;
}

export const UsersModalContainer = (props: IUsersModalContainerProps) => {
  const { partnerId, authRoleId, authRoleName, onRequestClose, ...rest } = props;
  const [actionTriggered, setActionTriggered] = useState(false);

  const switchAgentRole = useMutation(MSwitchAgentPermission);

  const handleSwitchRole = useCallback(
    async (agentId: string) => {
      try {
        if (!authRoleId) {
          return;
        }

        await switchAgentRole({ agentId, authRoleId });
        setActionTriggered(true);

        notifications.success({
          message: `The user was successfully switched to ${authRoleName}.`,
        });
      } catch (error) {
        notifications.error({
          message: 'There was an error processing your request, please try again later',
        });
      }
    },
    [authRoleId, switchAgentRole, authRoleName]
  );

  const handleClose = useCallback(() => {
    if (onRequestClose) {
      onRequestClose(actionTriggered);

      setActionTriggered(false);
    }
  }, [onRequestClose, actionTriggered]);

  return (
    <UsersModal
      partnerId={partnerId}
      authRoleId={authRoleId}
      authRoleName={authRoleName}
      onSwitchRole={handleSwitchRole}
      onRequestClose={handleClose}
      {...rest}
    />
  );
};
