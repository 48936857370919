import { type CustomizationValues } from '@mortgagehippo/ds';

export type PermissionCondition = 'and' | 'or';

export enum PermissionType {
  VIEW_USERS_MANAGEMENT_PAGE = 'VIEW_USERS_MANAGEMENT_PAGE',
  VIEW_REPORTING_DASHBOARD_PAGE = 'VIEW_REPORTING_DASHBOARD_PAGE',
  VIEW_PARTNER_REPORTS = 'VIEW_PARTNER_REPORTS',
  VIEW_USERS_TAB = 'VIEW_USERS_TAB',
  VIEW_USER = 'VIEW_USER',
  CREATE_USER = 'CREATE_USER',
  UPDATE_USER = 'UPDATE_USER',
  DELETE_USER = 'DELETE_USER',
  VIEW_JOB_FUNCTIONS_TAB = 'VIEW_JOB_FUNCTIONS_TAB',
  CREATE_JOB_FUNCTION = 'CREATE_JOB_FUNCTION',
  UPDATE_JOB_FUNCTION = 'UPDATE_JOB_FUNCTION',
  DELETE_JOB_FUNCTION = 'DELETE_JOB_FUNCTION',
  VIEW_JOB_FUNCTION_USERS = 'VIEW_JOB_FUNCTION_USERS',
  CREATE_JOB_FUNCTION_USERS = 'CREATE_JOB_FUNCTION_USERS',
  DELETE_JOB_FUNCTION_USERS = 'DELETE_JOB_FUNCTION_USERS',
  VIEW_TEAM_TEMPLATES_TAB = 'VIEW_TEAM_TEMPLATES_TAB',
  VIEW_TEAM_TEMPLATE = 'VIEW_TEAM_TEMPLATE',
  CREATE_TEAM_TEMPLATE = 'CREATE_TEAM_TEMPLATE',
  UPDATE_TEAM_TEMPLATE = 'UPDATE_TEAM_TEMPLATE',
  DELETE_TEAM_TEMPLATE = 'DELETE_TEAM_TEMPLATE',
  VIEW_AUTH_ROLES_TAB = 'VIEW_AUTH_ROLES_TAB',
  VIEW_AUTH_ROLE_USERS = 'VIEW_AUTH_ROLE_USERS',
  UPDATE_AUTH_ROLE_USERS = 'UPDATE_AUTH_ROLE_USERS',
  CREATE_APPLICATION_FILE = 'CREATE_APPLICATION_FILE',
  VIEW_APPLICATION_FILE_PAGE = 'VIEW_APPLICATION_FILE_PAGE',
  ADD_BORROWER = 'ADD_BORROWER',
  DELETE_BORROWER = 'DELETE_BORROWER',
  VIEW_APPLICATION_TAB = 'VIEW_APPLICATION_TAB',
  VIEW_TASKS_TAB = 'VIEW_TASKS_TAB',
  CREATE_TASK = 'CREATE_TASK',
  UPDATE_TASK = 'UPDATE_TASK',
  DELETE_TASK = 'DELETE_TASK',
  VIEW_SERVICES_TAB = 'VIEW_SERVICES_TAB',
  VIEW_DOCUMENTS_TAB = 'VIEW_DOCUMENTS_TAB',
  VIEW_PRICING_TAB = 'VIEW_PRICING_TAB',
  SELECT_PRICING_OPTION = 'SELECT_PRICING_OPTION',
  VIEW_TEAM_TAB = 'VIEW_TEAM_TAB',
  MANAGE_TEAM = 'MANAGE_TEAM',
  VIEW_STATUS_TAB = 'VIEW_STATUS_TAB',
  VIEW_MILESTONES_TAB = 'VIEW_MILESTONES_TAB',
  DELETE_APPLICATION_FILE = 'DELETE_APPLICATION_FILE',
  EXPORT_CSV = 'EXPORT_CSV',
  EXPORT_DOCUMENTS = 'EXPORT_DOCUMENTS',
  DOWNLOAD_DOCUMENTS = 'DOWNLOAD_DOCUMENTS',
  VIEW_PIPELINE = 'VIEW_PIPELINE',
  CREATE_ASSETS_TASK = 'CREATE_ASSETS_TASK',
  CREATE_PAYMENT_TASK = 'CREATE_PAYMENT_TASK',
  CREATE_HELLOSIGN_TASK = 'CREATE_HELLOSIGN_TASK',
  VIEW_PREQUALIFICATION_LETTERS_PAGE = 'VIEW_PREQUALIFICATION_LETTERS_PAGE',
  PREQUALIFICATION_LETTERS_BYPASS_LIMITS = 'PREQUALIFICATION_LETTERS_BYPASS_LIMITS',
  CONFIGURE_BORROWER_PREQUALIFICATION_LETTERS = 'CONFIGURE_BORROWER_PREQUALIFICATION_LETTERS',
  MANAGE_PREQUALIFICATION_LETTER_CONTINGENGY = 'MANAGE_PREQUALIFICATION_LETTER_CONTINGENGY',
  CONFIGURE_PREQUALIFICATION_LETTER_CUSTOM_MESSAGE = 'CONFIGURE_PREQUALIFICATION_LETTER_CUSTOM_MESSAGE',
  CONFIGURE_PREQUALIFICATION_LETTER_MORTGAGE_INSURANCE = 'CONFIGURE_PREQUALIFICATION_LETTER_MORTGAGE_INSURANCE',
  DOWNLOAD_FANNIE_MAE_FILE = 'DOWNLOAD_FANNIE_MAE_FILE',
  UPDATE_APPLICANT_EMAIL = 'UPDATE_APPLICANT_EMAIL',
  DOWNLOAD_MISMO_FILE = 'DOWNLOAD_MISMO_FILE',
  DOWNLOAD_MISMO_34_FILE = 'DOWNLOAD_MISMO_34_FILE',
  DOWNLOAD_MISMO_34_PLUS_DU_FILE = 'DOWNLOAD_MISMO_34_PLUS_DU_FILE',
  DOWNLOAD_MISMO_34_PLUS_LPA_FILE = 'DOWNLOAD_MISMO_34_PLUS_LPA_FILE',
  UPDATE_LENDING_QB_PUSH = 'UPDATE_LENDING_QB_PUSH',
  UPDATE_FROZEN_APPLICATION_FILE = 'UPDATE_FROZEN_APPLICATION_FILE',
  PUSH_TO_INTERNAL_SYSTEM = 'PUSH_TO_INTERNAL_SYSTEM',
  VIEW_ARCHIVE_PAGE = 'VIEW_ARCHIVE_PAGE',
  ARCHIVE_APPLICATION_FILE = 'ARCHIVE_APPLICATION_FILE',
  RESTORE_APPLICATION_FILE = 'RESTORE_APPLICATION_FILE',
  VIEW_SEND_INVITES_PAGE = 'VIEW_SEND_INVITES_PAGE',
  VIEW_FOLDERS_TAB = 'VIEW_FOLDERS_TAB',
  CREATE_FOLDER = 'CREATE_FOLDER',
  UPDATE_FOLDER = 'UPDATE_FOLDER',
  DELETE_FOLDER = 'DELETE_FOLDER',
  UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT',
  DELETE_LENDER_DOCUMENT = 'DELETE_LENDER_DOCUMENT',
  TRIGGER_CREDIT_PULL = 'TRIGGER_CREDIT_PULL',
  SIGN_IDS_DOCUMENT = 'SIGN_IDS_DOCUMENT',
  REFRESH_FINICITY_REPORT = 'REFRESH_FINICITY_REPORT',
  CHANGE_FINICITY_REPORT_DAYS = 'CHANGE_FINICITY_REPORT_DAYS',
  REFRESH_MORTGAGEFLEX_LOAN_STATUS = 'REFRESH_MORTGAGEFLEX_LOAN_STATUS',
  REQUEST_WORKNUMBER_INSTANT_REPORT = 'REQUEST_WORKNUMBER_INSTANT_REPORT',
  GENERATE_LPA_ASSESSMENT = 'GENERATE_LPA_ASSESSMENT',
  GENERATE_DU_ASSESSMENT = 'GENERATE_DU_ASSESSMENT',
  PUSH_TO_LOS = 'PUSH_TO_LOS',
  PUSH_DOCUMENT_TO_LOS = 'PUSH_DOCUMENT_TO_LOS',
  UPDATE_ANSWERS = 'UPDATE_ANSWERS',
  LOCK_PRICING_OPTION = 'LOCK_PRICING_OPTION',
  VIEW_SMART_FEES = 'VIEW_SMART_FEES',
  APPLICATION_FILE_TEAM_TAB_SHOW_FOLDERS = 'APPLICATION_FILE_TEAM_TAB_SHOW_FOLDERS',
  VIEW_DOCUTECH = 'VIEW_DOCUTECH',
  VIEW_LENDER_MILESTONES = 'VIEW_LENDER_MILESTONES',
  VIEW_OVERVIEW_TAB = 'VIEW_OVERVIEW_TAB',
  VIEW_APPLICATION_ACTIVITY_PAGE = 'VIEW_APPLICATION_ACTIVITY_PAGE',
  CREATE_DOCUSIGN_TASK = 'CREATE_DOCUSIGN_TASK',
  UPDATE_APPLICATION_ACTIVE_MILESTONE = 'UPDATE_APPLICATION_ACTIVE_MILESTONE',
  UPDATE_APPLICATION_LENDER_MILESTONES = 'UPDATE_APPLICATION_LENDER_MILESTONES',
  VIEW_SMARTVIEWS = 'VIEW_SMARTVIEWS',
  CREATE_ORGANIZATION_LEVEL_SMARTVIEW = 'CREATE_ORGANIZATION_LEVEL_SMARTVIEW',
  UPDATE_APPLICATION_STATUS = 'UPDATE_APPLICATION_STATUS',
  VIEW_CHAT = 'VIEW_CHAT',
  VIEW_NOTES = 'VIEW_NOTES',
  REQUEST_TRUEWORK_INSTANT_REPORT = 'REQUEST_TRUEWORK_INSTANT_REPORT',
  VIEW_UNDERWRITING_CONDITIONS_PAGE = 'VIEW_UNDERWRITING_CONDITIONS_PAGE',
  REFRESH_UNDERWRITING_CONDITIONS = 'REFRESH_UNDERWRITING_CONDITIONS',
  UPDATE_UNDERWRITING_CONDITION_STATUS = 'UPDATE_UNDERWRITING_CONDITION_STATUS',
  CREATE_APPLICATION_IMPORT = 'CREATE_APPLICATION_IMPORT',
}

export interface IPermissionConfig {
  requiredScopes: string[];
  condition?: PermissionCondition;
  requiredCustomizations?: (customizations: CustomizationValues) => boolean;
}

export const PermissionConfig: Record<PermissionType, IPermissionConfig> = {
  [PermissionType.VIEW_USERS_MANAGEMENT_PAGE]: {
    requiredScopes: [
      'agent:index',
      'job_function:index',
      'team_templates:index',
      'auth_roles:index',
    ],
    condition: 'or',
  },
  [PermissionType.VIEW_REPORTING_DASHBOARD_PAGE]: {
    requiredScopes: ['partner:reports:show', 'site:reports:show'],
    condition: 'or',
  },
  [PermissionType.VIEW_PARTNER_REPORTS]: {
    requiredScopes: ['partner:reports:show'],
  },
  [PermissionType.VIEW_USERS_TAB]: {
    requiredScopes: ['agent:index', 'site:agent:show'],
  },
  [PermissionType.VIEW_USER]: {
    requiredScopes: [
      'site:agent:show',
      'job_function:show',
      'team_template:show',
      'auth_role:show',
      'agent_job_function:show',
      'agent_site:show',
      'agent_team_function:show',
      'user_role:show',
    ],
  },
  [PermissionType.CREATE_USER]: {
    requiredScopes: [
      'site:agent:create',
      'job_function:show',
      'team_template:show',
      'auth_role:show',
      'agent_job_function:create',
      'agent_site:create',
      'agent_team_function:create',
      'user_role:create',
    ],
  },
  [PermissionType.UPDATE_USER]: {
    requiredScopes: [
      'site:agent:show',
      'site:agent:update',
      'job_function:show',
      'team_template:show',
      'auth_role:show',
      'agent_job_function:show',
      'agent_job_function:create',
      'agent_job_function:destroy',
      'agent_site:show',
      'agent_site:create',
      'agent_site:destroy',
      'agent_team_function:show',
      'agent_team_function:create',
      'agent_team_function:destroy',
      'user_role:show',
      'user_role:update',
    ],
  },
  [PermissionType.DELETE_USER]: {
    requiredScopes: [
      'site:agent:show',
      'site:agent:destroy',
      'agent_job_function:destroy',
      'agent_site:destroy',
      'agent_team_function:destroy',
      'user_role:destroy',
    ],
  },
  [PermissionType.VIEW_JOB_FUNCTIONS_TAB]: {
    requiredScopes: [
      'job_function:index',
      'job_function:show',
      'site:agent:show',
      'agent_job_function:show',
    ],
  },
  [PermissionType.CREATE_JOB_FUNCTION]: {
    requiredScopes: ['job_function:create'],
  },
  [PermissionType.UPDATE_JOB_FUNCTION]: {
    requiredScopes: ['job_function:show', 'job_function:update'],
  },
  [PermissionType.DELETE_JOB_FUNCTION]: {
    requiredScopes: [
      'job_function:destroy',
      'agent_job_function:destroy',
      'agent_team_function:destroy',
    ],
  },
  [PermissionType.VIEW_JOB_FUNCTION_USERS]: {
    requiredScopes: ['job_function:show', 'site:agent:show', 'agent_job_function:show'],
  },
  [PermissionType.CREATE_JOB_FUNCTION_USERS]: {
    requiredScopes: ['site:agent:show', 'agent_job_function:show', 'agent_job_function:create'],
  },
  [PermissionType.DELETE_JOB_FUNCTION_USERS]: {
    requiredScopes: ['agent_job_function:destroy'],
  },
  [PermissionType.VIEW_TEAM_TEMPLATES_TAB]: {
    requiredScopes: [
      'team_templates:index',
      'team_template:show',
      'job_function:show',
      'site:agent:show',
      'agent_team_function:show',
    ],
  },
  [PermissionType.VIEW_TEAM_TEMPLATE]: {
    requiredScopes: [
      'team_template:show',
      'job_function:show',
      'site:agent:show',
      'agent_team_function:show',
    ],
  },
  [PermissionType.CREATE_TEAM_TEMPLATE]: {
    requiredScopes: [
      'team_template:create',
      'job_function:show',
      'site:agent:show',
      'agent_team_function:create',
    ],
  },
  [PermissionType.UPDATE_TEAM_TEMPLATE]: {
    requiredScopes: [
      'team_template:show',
      'team_template:update',
      'job_function:show',
      'site:agent:show',
      'agent_team_function:show',
      'agent_team_function:create',
      'agent_team_function:destroy',
    ],
  },
  [PermissionType.DELETE_TEAM_TEMPLATE]: {
    requiredScopes: ['team_template:show', 'team_template:destroy', 'agent_team_function:destroy'],
  },
  [PermissionType.VIEW_AUTH_ROLES_TAB]: {
    requiredScopes: ['auth_roles:index', 'auth_role:show', 'site:agent:show'],
  },
  [PermissionType.VIEW_AUTH_ROLE_USERS]: {
    requiredScopes: ['auth_role:show', 'site:agent:show'],
  },
  [PermissionType.UPDATE_AUTH_ROLE_USERS]: {
    requiredScopes: ['user_role:show', 'user_role:create', 'user_role:update', 'site:agent:show'],
  },
  [PermissionType.CREATE_APPLICATION_FILE]: {
    requiredScopes: [
      'application_file:create',
      'application_membership:create',
      'applicant:create',
    ],
  },
  [PermissionType.VIEW_APPLICATION_FILE_PAGE]: {
    requiredScopes: [
      'application_file:workflow:show',
      'application_file:document:show',
      'application_file:integration:show',
      'application_file:task:show',
      'application_file:application_file:show',
      'application_file:team_template:show',
    ],
    condition: 'or',
  },
  [PermissionType.ADD_BORROWER]: {
    requiredScopes: [
      'application_file:application_file:update',
      'applicant:create',
      'application_membership:create',
    ],
  },
  [PermissionType.DELETE_BORROWER]: {
    requiredScopes: ['application_file:application_file:update', 'application_membership:destroy'],
  },
  [PermissionType.VIEW_APPLICATION_TAB]: {
    requiredScopes: ['application_file:application_file:show'],
  },
  [PermissionType.VIEW_TASKS_TAB]: {
    requiredScopes: ['application_file:task:show'],
  },
  [PermissionType.CREATE_TASK]: {
    requiredScopes: ['application_file:task:create'],
  },
  [PermissionType.UPDATE_TASK]: {
    requiredScopes: ['application_file:task:update'],
  },
  [PermissionType.DELETE_TASK]: {
    requiredScopes: ['application_file:task:destroy'],
  },
  [PermissionType.VIEW_SERVICES_TAB]: {
    requiredScopes: ['application_file:integration:show'],
  },
  [PermissionType.VIEW_DOCUMENTS_TAB]: {
    requiredScopes: ['application_file:document:show', 'document:show', 'applicant:show'],
  },
  [PermissionType.VIEW_PRICING_TAB]: {
    requiredScopes: ['application_file:pricing:index'],
  },
  [PermissionType.SELECT_PRICING_OPTION]: {
    requiredScopes: ['application_file:pricing:update'],
  },
  [PermissionType.VIEW_TEAM_TAB]: {
    requiredScopes: [
      'application_file:team_template:show',
      'site:agent:show',
      'job_function:show',
      'agent_job_function:show',
      'agent_application_file:show',
    ],
  },
  [PermissionType.MANAGE_TEAM]: {
    requiredScopes: [
      'application_file:team_template:update',
      'site:agent:show',
      'job_function:show',
      'team_template:show',
      'agent_job_function:show',
      'agent_team_function:show',
      'agent_application_file:show',
      'agent_application_file:create',
      'agent_application_file:destroy',
    ],
  },
  [PermissionType.VIEW_STATUS_TAB]: {
    requiredScopes: [
      'application_file:workflow:show',
      'workflow_item_event:show',
      'milestone:show',
    ],
  },
  // TODO: add application_lender_milestone:show ?
  [PermissionType.VIEW_MILESTONES_TAB]: {
    requiredScopes: [
      'application_file:workflow:show',
      'workflow_item_event:show',
      'milestone:show',
    ],
  },
  [PermissionType.DELETE_APPLICATION_FILE]: {
    requiredScopes: ['application_file:application_file:destroy'],
  },
  [PermissionType.EXPORT_CSV]: {
    requiredScopes: ['csv_export:show', 'document:download'],
  },
  [PermissionType.EXPORT_DOCUMENTS]: {
    requiredScopes: [
      'application_file:document:show',
      'document:show',
      'applicant:show',
      'document:download',
    ],
  },
  [PermissionType.DOWNLOAD_DOCUMENTS]: {
    requiredScopes: ['document:show', 'document:download'],
  },
  [PermissionType.VIEW_PIPELINE]: {
    requiredScopes: ['pipeline:show'],
  },
  [PermissionType.CREATE_ASSETS_TASK]: {
    requiredScopes: ['application_file:task:create', 'application_file:task-assets:create'],
  },
  [PermissionType.CREATE_PAYMENT_TASK]: {
    // same as CREATE_TASK, using separate entry for other conditions
    requiredScopes: ['application_file:task:create'],
  },
  [PermissionType.CREATE_HELLOSIGN_TASK]: {
    // same as CREATE_TASK, using separate entry for other conditions
    requiredScopes: ['application_file:task:create'],
  },
  [PermissionType.CREATE_DOCUSIGN_TASK]: {
    // same as CREATE_TASK, using separate entry for other conditions
    requiredScopes: ['application_file:task:create'],
  },
  [PermissionType.VIEW_PREQUALIFICATION_LETTERS_PAGE]: {
    requiredScopes: ['pre_qualification_letter:show'],
  },
  [PermissionType.PREQUALIFICATION_LETTERS_BYPASS_LIMITS]: {
    requiredScopes: ['pre_qualification_letter:bypass_limits'],
  },
  [PermissionType.CONFIGURE_BORROWER_PREQUALIFICATION_LETTERS]: {
    requiredScopes: ['pre_qualification_letter_configuration:show'],
  },
  [PermissionType.MANAGE_PREQUALIFICATION_LETTER_CONTINGENGY]: {
    requiredScopes: ['pre_qualification_letter_contingency:show'],
  },
  [PermissionType.CONFIGURE_PREQUALIFICATION_LETTER_CUSTOM_MESSAGE]: {
    requiredScopes: ['pre_qualification_letter_custom_message:show'],
  },
  [PermissionType.CONFIGURE_PREQUALIFICATION_LETTER_MORTGAGE_INSURANCE]: {
    requiredScopes: ['pre_qualification_letter_mortgage_insurance:update'],
  },
  [PermissionType.DOWNLOAD_FANNIE_MAE_FILE]: {
    requiredScopes: ['fannie_mae_file:show', 'document:download'],
  },
  [PermissionType.UPDATE_APPLICANT_EMAIL]: {
    requiredScopes: ['applicant:individual:update_email'],
  },
  [PermissionType.DOWNLOAD_MISMO_FILE]: {
    requiredScopes: ['mismo_34:show', 'mismo_du:show', 'mismo_lpa:show'],
    condition: 'or',
  },
  [PermissionType.DOWNLOAD_MISMO_34_FILE]: {
    requiredScopes: ['mismo_34:show', 'document:download'],
  },
  [PermissionType.DOWNLOAD_MISMO_34_PLUS_DU_FILE]: {
    requiredScopes: ['mismo_du:show', 'document:download'],
  },
  [PermissionType.DOWNLOAD_MISMO_34_PLUS_LPA_FILE]: {
    requiredScopes: ['mismo_lpa:show', 'document:download'],
  },
  [PermissionType.UPDATE_LENDING_QB_PUSH]: {
    requiredScopes: ['los:update'],
  },
  [PermissionType.UPDATE_FROZEN_APPLICATION_FILE]: {
    requiredScopes: ['frozen_application_file:update'],
  },
  [PermissionType.PUSH_TO_INTERNAL_SYSTEM]: {
    requiredScopes: ['internal_system:show'],
  },
  [PermissionType.VIEW_ARCHIVE_PAGE]: {
    requiredScopes: ['archive:index'],
  },
  [PermissionType.ARCHIVE_APPLICATION_FILE]: {
    requiredScopes: ['archive:create'],
  },
  [PermissionType.RESTORE_APPLICATION_FILE]: {
    requiredScopes: ['archive:destroy'],
  },
  [PermissionType.VIEW_SEND_INVITES_PAGE]: {
    requiredScopes: ['application_file:invites:show'],
  },
  [PermissionType.VIEW_FOLDERS_TAB]: {
    requiredScopes: ['folder:index'],
  },
  [PermissionType.CREATE_FOLDER]: {
    requiredScopes: ['folder:create'],
  },
  [PermissionType.UPDATE_FOLDER]: {
    requiredScopes: ['folder:show', 'folder:update'],
  },
  [PermissionType.DELETE_FOLDER]: {
    requiredScopes: ['folder:destroy'],
  },
  [PermissionType.UPLOAD_DOCUMENT]: {
    requiredScopes: ['document:create'],
  },
  [PermissionType.DELETE_LENDER_DOCUMENT]: {
    requiredScopes: ['document:destroy'],
  },
  [PermissionType.TRIGGER_CREDIT_PULL]: {
    requiredScopes: ['credit_pull:create'],
  },
  [PermissionType.SIGN_IDS_DOCUMENT]: {
    requiredScopes: ['ids_agent_signing_event:create'],
  },
  [PermissionType.REFRESH_FINICITY_REPORT]: {
    requiredScopes: ['finicity_report:create'],
  },
  [PermissionType.CHANGE_FINICITY_REPORT_DAYS]: {
    requiredScopes: ['finicity_report:create', 'finicity_report_days:change'],
  },
  [PermissionType.REFRESH_MORTGAGEFLEX_LOAN_STATUS]: {
    requiredScopes: ['los:update'],
  },
  [PermissionType.REQUEST_WORKNUMBER_INSTANT_REPORT]: {
    requiredScopes: ['work_number:create'],
  },
  [PermissionType.GENERATE_DU_ASSESSMENT]: {
    requiredScopes: ['automated_underwriting_assessments:create'],
  },
  [PermissionType.GENERATE_LPA_ASSESSMENT]: {
    requiredScopes: ['automated_underwriting_assessments:create'],
  },
  [PermissionType.PUSH_TO_LOS]: {
    requiredScopes: ['los:create'],
  },
  [PermissionType.PUSH_DOCUMENT_TO_LOS]: {
    requiredScopes: ['los:create'],
  },
  [PermissionType.UPDATE_ANSWERS]: {
    requiredScopes: ['borrower:update'],
  },
  [PermissionType.LOCK_PRICING_OPTION]: {
    requiredScopes: ['application_file:pricing:lock'],
  },
  [PermissionType.VIEW_SMART_FEES]: {
    requiredScopes: ['smart_fees:show'],
  },
  [PermissionType.APPLICATION_FILE_TEAM_TAB_SHOW_FOLDERS]: {
    requiredScopes: [
      'application_file:team_tab_folder:show', // feature flag, allows non-primary agent to manage the folder
      'application_file:update',
    ],
  },
  [PermissionType.VIEW_DOCUTECH]: {
    requiredScopes: ['docutech:show'],
  },
  [PermissionType.VIEW_LENDER_MILESTONES]: {
    requiredScopes: ['application_file:workflow:show'],
  },
  [PermissionType.VIEW_OVERVIEW_TAB]: {
    requiredScopes: ['application_file:overview_tab:show'],
  },
  [PermissionType.VIEW_APPLICATION_ACTIVITY_PAGE]: {
    requiredScopes: ['application_file:activity_feed:show'],
  },
  [PermissionType.UPDATE_APPLICATION_ACTIVE_MILESTONE]: {
    requiredScopes: ['application_milestone:show', 'application_milestone:update'],
  },
  [PermissionType.UPDATE_APPLICATION_LENDER_MILESTONES]: {
    requiredScopes: [
      'application_lender_milestone:show',
      'application_lender_milestone:update',
      'application_lender_milestone:create',
      'application_lender_milestone:destroy',
    ],
  },
  [PermissionType.VIEW_SMARTVIEWS]: {
    // use same scope as pipeline for now
    requiredScopes: ['pipeline:show'],
  },
  [PermissionType.CREATE_ORGANIZATION_LEVEL_SMARTVIEW]: {
    requiredScopes: ['smartview:admin'],
  },
  [PermissionType.UPDATE_APPLICATION_STATUS]: {
    requiredScopes: [
      'application_file_status:create',
      'application_file_status:update',
      'application_file_status:destroy',
    ],
  },
  [PermissionType.VIEW_CHAT]: {
    requiredScopes: ['channel:chat:show'],
  },
  [PermissionType.VIEW_NOTES]: {
    requiredScopes: ['channel:notes:show'],
  },
  [PermissionType.REQUEST_TRUEWORK_INSTANT_REPORT]: {
    // TODO: update scope
    requiredScopes: [],
  },
  [PermissionType.VIEW_UNDERWRITING_CONDITIONS_PAGE]: {
    requiredScopes: ['underwriting_condition:show'],
  },
  [PermissionType.REFRESH_UNDERWRITING_CONDITIONS]: {
    requiredScopes: ['underwriting_condition:create'],
  },
  [PermissionType.UPDATE_UNDERWRITING_CONDITION_STATUS]: {
    requiredScopes: ['underwriting_condition:update'],
  },
  [PermissionType.CREATE_APPLICATION_IMPORT]: {
    requiredScopes: ['application_import:create'],
  },
};
