import {
  type FormSubmitHandler,
  Input,
  ModalForm,
  ModalWizard2 as ModalWizard,
  Select,
  SSN,
} from '@mortgagehippo/ds';
import { useAnswers, useSaveAnswers } from '@mortgagehippo/tasks';
import { useState } from 'react';

import { WorkNumberInstantReportRunningStep } from '$components/actions/worknumber-instant-report-action/worknumber-instant-report-running-step';

import { WorkNumberReportStatusCode } from '../../../apollo/graphql';
import { useApplicationFile } from '../../../pages/application/use-application-file-data';
import { type IActionProps, type IWorkNumberInstantReportAction } from '../types';
import {
  type IWorkNumberReport,
  useRequestWorkNumberInstantReport,
} from './use-request-work-number-instant-report';
import { WorkNumberInstantReportResult } from './worknumber-instant-report-result';

enum WizardStep {
  REQUEST_INFO = 'requestInfo',
  RUNNING_REPORT = 'runningReport',
  RERUNNING_REPORT = 'rerunningReport',
  REQUEST_SALARY_KEY = 'requestSalaryKey',
  SHOW_RESULTS = 'showResults',
}

export const WorkNumberInstantReportActionWizard = (
  props: IActionProps<IWorkNumberInstantReportAction> & {
    applicationFileId: string;
    applicantId: string;
  }
) => {
  const { onDone, applicationFileId, applicantId } = props;
  const [answers, answersLoading] = useAnswers(applicationFileId, applicantId);
  const saveAnswers = useSaveAnswers(applicationFileId, applicantId);
  const [step, setStep] = useState<WizardStep>(WizardStep.REQUEST_INFO);
  const [report, setReport] = useState<IWorkNumberReport | undefined>();
  const [error, setError] = useState<Error | undefined>();
  const requestReport = useRequestWorkNumberInstantReport();

  const handleRequestClose = () => {
    onDone();
  };

  const handleOnStepChange = async (
    nextStep: string | undefined,
    _currentStep: string,
    values: any
  ) => {
    if (nextStep === WizardStep.RUNNING_REPORT || nextStep === WizardStep.RERUNNING_REPORT) {
      try {
        const { salaryKey, alternateId, first_name, last_name, social_security_number } = values;

        await saveAnswers({
          first_name,
          last_name,
          social_security_number,
        });

        requestReport(applicationFileId, applicantId!, {
          salaryKey,
          alternateId,
        })
          .then((nextReport) => {
            const { statusCode } = nextReport;
            if (statusCode === WorkNumberReportStatusCode.SALARY_KEY_REQUIRED) {
              setStep(WizardStep.REQUEST_SALARY_KEY);
            } else {
              setReport(nextReport);
              setStep(WizardStep.SHOW_RESULTS);
            }
            return nextReport;
          })
          .catch((e) => {
            setError(e);
            setStep(WizardStep.SHOW_RESULTS);
          });

        setStep(WizardStep.RUNNING_REPORT);
        return;
      } catch (e: any) {
        console.error(e);
        setError(e);
        setStep(WizardStep.SHOW_RESULTS);
      }
    }

    if (nextStep) {
      setStep(nextStep as WizardStep);
    }
  };

  const handleSubmit = async () => {
    onDone();
  };

  const isSalaryKeyStepActive = !report
    ? false
    : report.statusCode === WorkNumberReportStatusCode.SALARY_KEY_REQUIRED;

  if (answersLoading) {
    return null;
  }

  return (
    <ModalWizard
      initialValues={answers}
      onStepChange={handleOnStepChange}
      onSubmit={handleSubmit}
      onRequestClose={handleRequestClose}
      title="Work Number - Instant Report"
      step={step}
      isOpen
    >
      <ModalWizard.Step id={WizardStep.REQUEST_INFO}>
        <p>
          Work Number requires a minimum set of information, to run this report. Please fill any
          missing details
        </p>

        <Input.Box label="First Name" name="first_name" required />
        <Input.Box label="Last Name" name="last_name" required />
        <SSN.Box
          label="Social Security Number"
          name="social_security_number"
          validateSane={false}
          required
        />

        <p>
          Some employers require that you provide an alternate id. If you
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          don't know if the borrowers employer does leave it blank
        </p>

        <Input.Box label="Alternate id" name="alternateId" />
      </ModalWizard.Step>
      <ModalWizard.Step
        id={WizardStep.RUNNING_REPORT}
        hideCancelButton
        hidePrevButton
        hideNextButton
      >
        <WorkNumberInstantReportRunningStep />
      </ModalWizard.Step>
      <ModalWizard.Step
        id={WizardStep.REQUEST_SALARY_KEY}
        active={isSalaryKeyStepActive}
        hidePrevButton
      >
        <p>
          Work Number has reported that this user requires a salary key to be able to run this
          report. Please ask the borrower for This key is provided by their employer
        </p>

        <Input.Box label="Salary key" name="salaryKey" />
      </ModalWizard.Step>
      <ModalWizard.Step
        id={WizardStep.RERUNNING_REPORT}
        hideCancelButton
        hidePrevButton
        hideNextButton
      >
        <WorkNumberInstantReportRunningStep />
      </ModalWizard.Step>
      <ModalWizard.Step id={WizardStep.SHOW_RESULTS} active hidePrevButton>
        <WorkNumberInstantReportResult report={report} error={error} />
      </ModalWizard.Step>
    </ModalWizard>
  );
};

export const WorkNumberInstantReportAction = (
  props: IActionProps<IWorkNumberInstantReportAction>
) => {
  const { action, onDone } = props;
  const { applicationFileId, applicantId: providedApplicantId } = action;
  const [applicantId, setApplicantId] = useState(providedApplicantId);
  const [{ applicants, loading: applicantsLoading }] = useApplicationFile(applicationFileId);

  const handleRequestClose = onDone;

  const handleSubmit: FormSubmitHandler = (values) => {
    const { applicantId: nextApplicantId } = values;
    setApplicantId(nextApplicantId);
  };

  /*
   * useEffect(() => {
   *   if (applicantsLoading) return;
   *   if (applicants.length === 1) {
   *     setApplicantId(applicants[0].id);
   *   } else if (applicants.length === 0) {
   *     onDone();
   *   }
   * }, [applicants, applicantsLoading, onDone]);
   */

  const applicantOptions = applicants.map((a) => ({
    value: a.id,
    label: a.name,
  }));

  if (applicantId) {
    return (
      <WorkNumberInstantReportActionWizard
        applicationFileId={applicationFileId}
        applicantId={applicantId!}
        {...props}
      />
    );
  }

  return (
    <ModalForm
      loading={applicantsLoading}
      onSubmit={handleSubmit}
      onRequestClose={handleRequestClose}
      okButtonLabel="Next"
      title="Work Number - Instant Report"
      isOpen
    >
      <Select.Box
        name="applicantId"
        label="Choose an applicant to run the report for"
        placeholder="Choose Applicant"
        options={applicantOptions}
      />
    </ModalForm>
  );
};
