import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../apollo';

export const QApplicationBlueprint = graphql(`
  query ApplicationBlueprint($siteId: ID!) {
    site(id: $siteId) {
      id
      applicationBlueprint {
        id
        json
      }
    }
  }
`);

export const useApplicationBlueprint = (siteId: number, options?: IUseApolloQueryOptions) => {
  const [_data, ...rest] = useQuery(QApplicationBlueprint, { siteId: `${siteId}` }, options);

  const data = _data?.site?.applicationBlueprint.json;

  return [data, ...rest] as const;
};
