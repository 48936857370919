import { notifications, type UploadHandler } from '@mortgagehippo/ds';
import axios, { type AxiosProgressEvent } from 'axios';
import { uniqueId } from 'lodash-es';
import { useCallback, useState } from 'react';

import { useCreateAgentAvatarUploadUrl } from '../../../hooks/agents';
import { usePartnerId } from '../../../hooks/use-partner-id';
import { AvatarModal } from './avatar-modal';
import { useUpdateAgentAvatar } from './use-update-agent-avatar';

interface IAvatarModalContainerProps {
  agentId: string;
  onRequestClose: () => void;
  onSubmit: () => void;
  isOpen: boolean;
}

export const AvatarModalContainer = (props: IAvatarModalContainerProps) => {
  const { agentId, onRequestClose, onSubmit, ...rest } = props;
  const [uploading, setUploading] = useState(false);
  const [partnerId] = usePartnerId();

  const createAgentAvatarUploadUrl = useCreateAgentAvatarUploadUrl();
  const updateAgentAvatar = useUpdateAgentAvatar();

  const handleRequestClose = useCallback(() => {
    if (uploading) {
      return;
    }

    onRequestClose();
  }, [onRequestClose, uploading]);

  const handleUpload: UploadHandler = useCallback(
    async (file, meta, progress) => {
      try {
        setUploading(true);

        const result = await createAgentAvatarUploadUrl(`${partnerId}`, meta.filename);

        const { presignedUrl: uploadUrl, uploadKey } = result;

        await axios.put(uploadUrl, file, {
          headers: {
            'Content-Type': meta.mime,
          },
          onUploadProgress: (e: AxiosProgressEvent) => {
            if (!e.total) return;
            const percent = Math.round((e.loaded * 100) / e.total);
            progress(percent);
          },
        });

        await updateAgentAvatar(agentId, uploadKey);

        onSubmit();

        return { id: uniqueId() };
      } catch (e) {
        notifications.error({ message: 'An error occurred while uploading your file.' });
        throw e;
      } finally {
        setUploading(false);
      }
    },
    [agentId, createAgentAvatarUploadUrl, onSubmit, partnerId, updateAgentAvatar]
  );

  if (!partnerId) {
    return null;
  }

  return <AvatarModal onUpload={handleUpload} onRequestClose={handleRequestClose} {...rest} />;
};
