import {
  type FormSubmitHandler,
  type ISelectOption,
  ModalForm,
  type UploadHandler,
} from '@mortgagehippo/ds';

import { ImportMismoModalFields } from './import-mismo-modal-fields';

interface ICreateApplicationModalProps {
  isOpen: boolean;
  initialValues?: any;
  loading: boolean;
  siteOptions: ISelectOption[];
  onSubmit: FormSubmitHandler;
  onRequestClose: () => void;
  onUpload: UploadHandler;
  submitDisabled?: boolean;
}

const defaultInitialValues = {};
export const ImportMismoModal = (props: ICreateApplicationModalProps) => {
  const {
    initialValues = defaultInitialValues,
    isOpen,
    loading,
    siteOptions,
    onRequestClose,
    onSubmit,
    onUpload,
    submitDisabled,
  } = props;

  return (
    <ModalForm
      loading={loading}
      title="Import from MISMO 3.4"
      initialValues={initialValues}
      isOpen={isOpen}
      okButtonLabel="Import"
      onRequestClose={onRequestClose}
      onSubmit={onSubmit}
      disableOverlayClickClose
      autoComplete={false}
      okButtonDisabled={submitDisabled}
    >
      <ImportMismoModalFields siteOptions={siteOptions} onUpload={onUpload} />
    </ModalForm>
  );
};
