import { type IChoiceOption, T } from '@mortgagehippo/ds';
import { LiabilityType } from '@mortgagehippo/tasks';

export const ASSET_REAL_ESTATE_OWNED_TYPE_NAME = 'RealEstateOwned';
export const LIABILITY_OBJECT_TYPE = 'Liability';
export const ASSET_OBJECT_TYPE = 'Asset';

export const LIABILITY_TYPE_OPTIONS: IChoiceOption<LiabilityType>[] = [
  {
    label: <T>Mortgage</T>,
    value: LiabilityType.MortgageLoan,
  },
  {
    label: <T>Revolving (e.g., credit cards)</T>,
    value: LiabilityType.Revolving,
  },
  {
    label: <T>Installment (e.g., car, student, personal loans)</T>,
    value: LiabilityType.Installment,
  },
  {
    label: <T>Open 30-Day (balance paid monthly)</T>,
    value: LiabilityType.Open30DayChargeAccount,
  },
  {
    label: <T>Lease (not real estate)</T>,
    value: LiabilityType.LeasePayment,
  },
];
