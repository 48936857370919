import {
  Dropdown,
  fontFamily,
  fontSize,
  fontWeight,
  type IButtonProps,
  type IDropdownActionList,
  type IIconProps,
  lineHeight,
  MediaBreakpoint,
  palette,
  spacing,
  T,
  Text,
  useCustomizations,
} from '@mortgagehippo/ds';
import { ApplicationDescription, ApplicationStartDate } from '@mortgagehippo/tasks';
import { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { type IApplicationFile } from '../../pages/application/use-application-file-data';
import { type IPrimaryApplicantApplicationFile } from '../../pages/application/use-primary-applicants-files';

/*
 * trying to match the styles on the button version so that the elements
 * line up properly.
 */
const NotAButton = styled.span<{ headerTitleColor?: string }>`
  display: inline-block;
  font-family: ${fontFamily('secondary')};
  line-height: ${lineHeight('xs')};
  color: ${(p) => p.headerTitleColor || palette('primary50')} !important;
  padding: 1px 0 7px;

  ${MediaBreakpoint.PHONE} {
    margin-bottom: ${spacing(1)};
  }
`;

const CurrentApplicationName = styled(Text)`
  vertical-align: middle;
  font-weight: ${fontWeight('semibold', 'secondary')};
  line-height: ${lineHeight('xs')};
`;

const CurrentApplicationDescription = styled('div')<{ headerDetailsColor?: string }>`
  display: block;
  margin-top: -${spacing(1)};
  font-size: ${fontSize('xs')};
  line-height: ${lineHeight('xs')};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 300px;
  color: ${(p) => p.headerDetailsColor || palette('primary200')};
  font-weight: ${fontWeight('light')};

  ${MediaBreakpoint.PHONE} {
    display: block;
    max-width: 100%;
  }
`;

const StyledDropdown = styled(Dropdown)<{ headerTitleColor?: string }>`
  margin-left: -${spacing(2)};

  && .application-header-popover-button {
    padding: 1px 8px 7px;
    font-family: ${fontFamily('secondary')};
    font-weight: ${fontWeight('semibold', 'secondary')};
    line-height: ${lineHeight('xs')};
    color: ${(p) => p.headerTitleColor || palette('primary50')} !important;
    background: transparent !important;
    border-width: 0;

    svg {
      margin-left: ${spacing(1)};
    }
  }
`;

const DropdownApplicationName = styled('span')`
  display: block;
  font-size: ${fontSize('normal')};
  line-height: ${lineHeight('sm')};
`;

const DropdownApplicationDescription = styled.span`
  display: inline-block;
  color: ${palette('neutral600')};
  font-size: ${fontSize('xs')};
  line-height: 1.25em;
  font-weight: ${fontWeight('light', 'secondary')};
  padding-top: ${spacing(1)};
`;

const DropdownApplication = styled.span<{
  selected?: boolean;
}>`
  font-family: ${fontFamily('secondary')};

  ${(p) => css`
    ${(p.selected &&
      css`
        ${DropdownApplicationName} {
          color: ${palette('primary600')};
        }

        ${DropdownApplicationDescription} {
          color: ${palette('primary500')};
        }
      `) ||
    ''}
  `}
`;

const currentApplicationButtonProps: IButtonProps = {
  size: 'md',
  importance: 'tertiary',
  inverted: true,
  icon: 'down-arrow-small',
  iconLocation: 'right',
  compact: true,
  dimIcon: true,
  className: 'application-header-popover-button',
};

const dropdownSelectedApplicationIconProps: IIconProps = {
  name: 'circle',
  color: 'primary600',
  size: 'xs',
};

interface IApplicationHeaderTitleProps {
  applicationFile: IApplicationFile;
  primaryApplicantsFiles: IPrimaryApplicantApplicationFile[];
  onStartApplication?: () => void;
}

export const ApplicationHeaderTitle = (props: IApplicationHeaderTitleProps) => {
  const { applicationFile, primaryApplicantsFiles, onStartApplication } = props;
  const { id: activeApplicationFileId, createdAt, description } = applicationFile;

  const customizations = useCustomizations();
  const headerTitleColor = customizations.color('app:application.header.title.color');
  const headerDetailsColor = customizations.color('app:application.header.details.color');

  const totalApplicationFiles = primaryApplicantsFiles.length;

  const dropdownActions = useMemo(() => {
    const actions: IDropdownActionList = [];

    if (onStartApplication) {
      actions.push({
        key: 'new-application',
        onAction: onStartApplication,
        label: 'Start new application',
        iconProps: {
          name: 'plus',
          size: 'xs',
        },
      });
    }

    if (totalApplicationFiles > 0) {
      const applicationFileActions = primaryApplicantsFiles.map((primaryApplicantsFile) => {
        const key = primaryApplicantsFile.id;
        const selected = key === activeApplicationFileId;

        const label = (
          <DropdownApplication selected={selected}>
            <DropdownApplicationName>Application {key}</DropdownApplicationName>
            <DropdownApplicationDescription>
              <ApplicationDescription description={primaryApplicantsFile.detailedDescription}>
                <ApplicationStartDate createdAt={primaryApplicantsFile.createdAt} />
              </ApplicationDescription>
            </DropdownApplicationDescription>
          </DropdownApplication>
        );

        return {
          key,
          label,
          to: `#/applications/${key}`,
          iconProps: selected ? dropdownSelectedApplicationIconProps : undefined,
        };
      });

      if (actions.length > 0) {
        actions.push({
          separator: true,
        });
      }

      actions.push(...applicationFileActions);
    }

    return actions;
  }, [activeApplicationFileId, onStartApplication, primaryApplicantsFiles, totalApplicationFiles]);

  if (dropdownActions.length === 0) {
    // we don't need the dropdown
    return (
      <>
        <NotAButton>
          <CurrentApplicationName size="md">
            <T cvars={{ id: activeApplicationFileId }}>Application {activeApplicationFileId}</T>
          </CurrentApplicationName>
        </NotAButton>
        <CurrentApplicationDescription headerDetailsColor={headerDetailsColor}>
          <ApplicationDescription description={description}>
            <ApplicationStartDate createdAt={createdAt} />
          </ApplicationDescription>
        </CurrentApplicationDescription>
      </>
    );
  }

  return (
    <>
      <StyledDropdown
        buttonProps={currentApplicationButtonProps}
        label={<T cvars={{ id: activeApplicationFileId }}>Application {activeApplicationFileId}</T>}
        actions={dropdownActions}
        headerTitleColor={headerTitleColor}
      />
      <CurrentApplicationDescription headerDetailsColor={headerDetailsColor}>
        <ApplicationDescription description={description}>
          <ApplicationStartDate createdAt={createdAt} />
        </ApplicationDescription>
      </CurrentApplicationDescription>
    </>
  );
};
