import { type ISpacing } from '@mortgagehippo/ds';

export const MAIN_CONTENT_ID = 'main_content';

export const LAYOUT_DIMENSIONS = {
  headerHeight: 64, // spacing8
  alertHeight: 25,
  mainNavigationHeight: 32, // spacing6
  mainNavigationHeightMobile: 44,
  maxContentWidth: 920,
  extendedMaxContentWidth: 1200,
  sideNavInnerPaddingSpacing: {
    bottom: 8 as ISpacing,
    horizontal: 4 as ISpacing,
    top: 4 as ISpacing,
  },
};
