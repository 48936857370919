import { graphql } from '../apollo';
import { client } from '../apollo/apollo-client';
import {
  type PrimaryApplicantIdQuery,
  type PrimaryApplicantIdQueryVariables,
} from '../apollo/graphql';

export const QPrimaryApplicantId = graphql(`
  query PrimaryApplicantId($applicationFileId: ID!) {
    applicationFile(id: $applicationFileId) {
      primaryApplicant {
        id
      }
    }
  }
`);

export const getPrimaryApplicantId = async (applicationFileId: string) => {
  const result = await client.query<PrimaryApplicantIdQuery, PrimaryApplicantIdQueryVariables>({
    query: QPrimaryApplicantId,
    variables: { applicationFileId },
  });

  return result.data?.applicationFile?.primaryApplicant?.id;
};
