import { useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { graphql } from '../../apollo';
import { type LenderMilestonesQuery } from '../../apollo/graphql';

const QLenderMilestones = graphql(`
  query LenderMilestones($applicationFileId: ID!, $fullResult: Boolean!) {
    applicationFile(id: $applicationFileId) {
      id
      lenderMilestones {
        id
        name
        active
        completed
        updatedOn
        externalMilestoneKeyId
      }
      mortgageFlexLoanFilePush {
        ...MortgageFlexLoanFilePushFragment
      }
    }
  }
`);

export type ILenderMilestone = NonNullable<
  LenderMilestonesQuery['applicationFile']
>['lenderMilestones'][0];

export const useLenderMilestones = (
  applicationFileId: string,
  fullPushResult?: boolean,
  skip?: boolean
) => {
  const [data, loading, , { refetch }] = useQuery(
    QLenderMilestones,
    { applicationFileId, fullResult: !!fullPushResult },
    {
      suspend: false,
      fetchPolicy: 'network-only',
      skip,
    }
  );

  const handleRefresh = useSafeCallback(() =>
    refetch({ applicationFileId, fullResult: !!fullPushResult })
  );

  const milestones = data?.applicationFile?.lenderMilestones || [];
  const pushData = data?.applicationFile?.mortgageFlexLoanFilePush ?? null;

  return [{ milestones, pushData }, loading, handleRefresh] as const;
};
