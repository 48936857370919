// Mutation for setting the relationships between agents and an application file
import { graphql } from '../../../apollo';

export const MSetApplicationFileTeamAgents = graphql(`
  mutation SetApplicationFileTeamAgents(
    $applicationFileId: ID!
    $teamAgents: [ApplicationFileTeamAgentInput!]!
  ) {
    setApplicationFileTeamAgents(applicationFileId: $applicationFileId, teamAgents: $teamAgents)
  }
`);
