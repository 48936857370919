import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { graphql } from '../../apollo';
import { type ApplicantsCreditPullDataQuery } from '../../apollo/graphql';

const QApplicantsCreditPullData = graphql(`
  query ApplicantsCreditPullData($applicationFileId: ID!) {
    applicationFile(id: $applicationFileId) {
      id
      applicants {
        items {
          id
          answers
          creditPulls(options: { perPage: 1, orderBy: { created_at: "desc" } }) {
            items {
              id
              statusCode
              statusDescription
              vendorOrderIdentifier
              createdAt
              requestedBy {
                id
                name
                type
              }
              scores {
                id
                applicantId
                equifax
                transunion
                experian
              }
            }
          }
        }
      }
    }
  }
`);

export type ICreditPull = NonNullable<
  ApplicantsCreditPullDataQuery['applicationFile']
>['applicants']['items'][0]['creditPulls']['items'][0];

export const useApplicantsCreditPullData = (
  applicationFileId: string,
  options: IUseApolloQueryOptions = {}
) => {
  const [data, loading, , { refetch }] = useQuery(
    QApplicantsCreditPullData,
    { applicationFileId },
    {
      fetchPolicy: 'no-cache',
      ...options,
    }
  );

  const handleRefresh = useSafeCallback(async () => refetch({ applicationFileId }));

  const result = data?.applicationFile?.applicants.items || [];

  return [result, loading, handleRefresh] as const;
};
