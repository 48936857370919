import { ModalDialog, useCustomizations, useModal } from '@mortgagehippo/ds';
import { useSagaSubmitHandler } from '@mortgagehippo/tasks';
import { useCallback } from 'react';

import { useApplicationFile } from '../../../pages/application/use-application-file-data';
import { useCreateApplicant } from '../../../pages/application/use-create-applicant';
import { type IActionProps, type IAddApplicantAction } from '../types';
import { AddApplicantModal } from './add-applicant-modal';

export const AddApplicantAction = (props: IActionProps<IAddApplicantAction>) => {
  const { action, onDone, onSubmit, onCancel } = props;
  const { applicationFileId } = action;

  const [isOpen, , closeModal] = useModal(true);

  const [{ applicants, loading }, refresh] = useApplicationFile(applicationFileId);

  const createApplicant = useCreateApplicant();

  const customizations = useCustomizations();
  const forceSharedTasks = customizations.bool('settings:inviteApplicant.forceSharedTasks', false);
  const allowSharedTasks = customizations.bool('settings:inviteApplicant.allowSharedTasks', false);
  const allowSharedTasksNonSpouse = customizations.bool(
    'settings:inviteApplicant.allowSharedTasksNonSpouse',
    false
  );
  const allowSharedEmailAddress = customizations.bool(
    'settings:inviteApplicant.allowSharedEmailAddress',
    false
  );

  const [handleSubmit, running] = useSagaSubmitHandler({
    infoMessageCid: 'pageApplication:actions.addBorrower.notification.info.message',
    successMessageCid: 'pageApplication:actions.addBorrower.notification.success.message',
    errorMessageCid: 'pageApplication:actions.addBorrower.notification.error.message',
    infoMessage: 'Adding borrower to application',
    successMessage: 'Successfully added borrower to application',
    errorMessage: 'There was an unexpected error adding borrower to application',
    async onSubmit(values: any, setAsyncId) {
      const {
        email: inputEmail,
        first_name: firstName,
        last_name: lastName,
        cell_phone_number: mobilePhoneNumber,
        is_spouse: isSpouse,
        send_invitation: sendInvitation = false,
        share_tasks: shareTasks = false,
        share_email: shareEmail = false,
      } = values;

      const data = {
        email: shareEmail ? null : inputEmail,
        firstName,
        lastName,
        mobilePhoneNumber,
        isSpouse,
        sendInvitation,
        shareTasks: forceSharedTasks || shareTasks,
      };

      const nextAsyncId = await createApplicant(applicationFileId, data);

      closeModal(() => {
        setAsyncId(nextAsyncId);
        onSubmit();
      });
    },
    async onDone() {
      await refresh();
      closeModal(onDone);
    },
  });

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  const canAdd = applicants.length < 2;

  if (!canAdd) {
    return (
      <ModalDialog
        isOpen={isOpen}
        onRequestClose={handleCloseModal}
        type="warning"
        hideOkButton
        cancelButtonLabel="Close"
      >
        You can only have up to two borrowers associated with an application, please remove one
        before attempting to add a new one.
      </ModalDialog>
    );
  }

  if (running) {
    return null;
  }

  const modalLoading = loading || !customizations.ready();

  return (
    <AddApplicantModal
      onSubmit={handleSubmit}
      onRequestClose={handleCloseModal}
      isOpen={isOpen}
      forceSharedTasks={forceSharedTasks}
      allowSharedTasks={allowSharedTasks}
      allowSharedTasksNonSpouse={allowSharedTasksNonSpouse}
      allowSharedEmailAddress={allowSharedEmailAddress}
      loading={modalLoading}
    />
  );
};
