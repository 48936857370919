import { type ISearchFilter, notifications } from '@mortgagehippo/ds';
import { type IQueryTableDataActions } from '@mortgagehippo/query-components';
import { UnreachableCaseError, useMountedRef } from '@mortgagehippo/util';
import { useCallback, useMemo, useRef } from 'react';

import { ActionStatus, ActionType, useActionEffect, useDispatchAction } from '$components/actions';
import { useUserCan } from '$components/permissions';

import { type ExportSettings, SmartviewModelType } from '../../../apollo/graphql';
import { usePartnerDomain } from '../../../hooks/use-partner-domain';
import { useRequestAgentsExport } from '../../../hooks/use-request-agents-export';
import { SmartView } from '../smart-view';
import { type ISmartviewRecord } from '../types';
import { getOrderBy } from '../util';

enum RowActionType {
  VIEW = 'view',
  EDIT = 'edit',
  DELETE = 'delete',
}

export interface IAgentSmartviewProps {
  id?: string;
  caption: string;
  ariaLabel: string;
  localStorageKey?: string;
}

export const AgentsSmartview = (props: IAgentSmartviewProps) => {
  const { id, caption, ariaLabel, localStorageKey } = props;

  const smartviewRef = useRef<any>();
  const mounted = useMountedRef();

  const partnerId = usePartnerDomain();
  const [can] = useUserCan();

  const dispatch = useDispatchAction();
  const requestAgentsExport = useRequestAgentsExport();

  useActionEffect(
    (action) => {
      if (
        action.type === ActionType.CREATE_AGENT ||
        action.type === ActionType.UPDATE_AGENT ||
        action.type === ActionType.DELETE_AGENT
      ) {
        if (mounted.current && smartviewRef.current) {
          smartviewRef.current.refetch();
        }
      }
    },
    undefined,
    ActionStatus.DONE
  );

  const handleExport = useCallback(
    async (criteria?: ISearchFilter[], sortField?: string, sortDirection?: string) => {
      let asyncId;
      try {
        const orderBy = getOrderBy(sortField, sortDirection);

        const settings: ExportSettings = {
          criteria: criteria as any,
          options: {
            orderBy,
          },
        };

        asyncId = await requestAgentsExport(partnerId, settings);
      } catch (e) {
        asyncId = null;
      } finally {
        if (!asyncId) {
          notifications.error({
            message: 'An error occurred while processing your request. Please try again later.',
          });
        }
      }

      return asyncId;
    },
    [requestAgentsExport, partnerId]
  );

  const handleRowClick = useCallback(
    (record: ISmartviewRecord) => {
      dispatch({
        type: ActionType.UPDATE_AGENT,
        agentId: record.id,
      });
    },
    [dispatch]
  );

  const handleAction = useCallback(
    async (actionKey: string, record: ISmartviewRecord) => {
      const actionType = actionKey as RowActionType;
      const { id: agentId } = record;

      switch (actionType) {
        case RowActionType.VIEW:
        case RowActionType.EDIT:
          dispatch({
            type: ActionType.UPDATE_AGENT,
            agentId,
          });
          break;
        case RowActionType.DELETE:
          dispatch({
            type: ActionType.DELETE_AGENT,
            agentId,
          });
          break;
        default: {
          throw new UnreachableCaseError(actionType);
        }
      }
    },
    [dispatch]
  );

  const rowActions: IQueryTableDataActions = useMemo(
    () => [
      {
        key: 'options',
        label: 'Options',
        buttonProps: {
          icon: 'menu-dots',
        },
        onGroupAction: handleAction,
        actions: [
          {
            key: RowActionType.VIEW,
            label: 'View',
            iconProps: {
              name: 'preview',
            },
            hidden: can.UPDATE_USER || !can.VIEW_USER,
          },
          {
            key: RowActionType.EDIT,
            label: 'Edit',
            iconProps: {
              name: 'edit',
            },
            hidden: !can.UPDATE_USER,
          },
          {
            key: RowActionType.DELETE,
            label: 'Delete',
            iconProps: {
              name: 'delete',
            },
            hidden: !can.DELETE_USER,
          },
        ],
      },
    ],
    [can, handleAction]
  );

  const topActions: IQueryTableDataActions = useMemo(
    () => [
      {
        key: 'create-user',
        label: 'Add a new user',
        buttonProps: {
          icon: 'plus',
        },
        hidden: !can.CREATE_USER,
        onAction: () => {
          dispatch({
            type: ActionType.CREATE_AGENT,
          });
        },
      },
    ],
    [can.CREATE_USER, dispatch]
  );

  return (
    <SmartView
      ref={smartviewRef}
      model={SmartviewModelType.Agent}
      id={id}
      ariaLabel={ariaLabel}
      caption={caption}
      rowActions={rowActions}
      topActions={topActions}
      onRowClick={can.UPDATE_USER || can.VIEW_USER ? handleRowClick : undefined}
      localStorageKey={localStorageKey}
      paginate
      summarize
      onExport={can.EXPORT_CSV ? handleExport : undefined}
    />
  );
};
