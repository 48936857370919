import { ModalDialog, useCustomizations, useModal } from '@mortgagehippo/ds';
import { useAnswers, useSagaSubmitHandler } from '@mortgagehippo/tasks';
import { useCallback } from 'react';

import { hasAuthorizedCreditPull } from '../../../pages/application/application-services/services/credit-check';
import { useTriggerCreditCheck } from '../../../pages/application/use-trigger-credit-check';
import { type IActionProps, type ICreditPullAction } from '../types';
import { CreditPullAuthorizationModal } from './credit-pull-authorization-modal';

export const CreditPullAction = (props: IActionProps<ICreditPullAction>) => {
  const { action, onSubmit, onDone, onCancel } = props;
  const { applicationFileId, applicantId } = action;

  const [isOpen, , closeModal] = useModal(true);

  const customizations = useCustomizations();
  const title = customizations.text('credit-pull:confirm-title', 'Are you sure?');
  const explanation = customizations.text(
    'credit-pull:confirm-explanation',
    'You are about to trigger a credit pull.'
  );

  const [answers, loading] = useAnswers(applicationFileId, applicantId);
  const requireAuthorization = !answers || !hasAuthorizedCreditPull(answers);

  const triggerCreditCheck = useTriggerCreditCheck();

  const [handleSubmit, running] = useSagaSubmitHandler({
    infoMessageCid: 'credit-pull:info',
    successMessageCid: 'credit-pull:success',
    errorMessageCid: 'credit-pull:error',
    infoMessage: 'Processing credit pull',
    successMessage: 'Successfully processed credit pull',
    errorMessage: 'There was an unexpected error processing the credit pull',
    async onSubmit(_, setAsyncId) {
      const asyncId = await triggerCreditCheck(
        applicationFileId,
        applicantId,
        requireAuthorization
      );
      closeModal(() => {
        setAsyncId(asyncId);
        onSubmit();
      });
    },
    async onDone() {
      closeModal(onDone);
    },
  });

  const handleCloseModal = useCallback(() => {
    closeModal(onCancel);
  }, [closeModal, onCancel]);

  if (loading || running) return null;

  if (requireAuthorization) {
    return (
      <CreditPullAuthorizationModal
        onSubmit={handleSubmit}
        onRequestClose={handleCloseModal}
        isOpen={isOpen}
      />
    );
  }

  // TODO: use hook useModalConfirm
  return (
    <ModalDialog
      type="dialog"
      title={title}
      onOk={handleSubmit as any}
      onRequestClose={handleCloseModal}
      isOpen={isOpen}
    >
      {explanation}
    </ModalDialog>
  );
};
