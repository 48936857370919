import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { graphql } from '../../../../../../apollo';
import { type ILosPush } from '../types';

const QMortgageDirectorLosPush = graphql(`
  query MortgageDirectorLosPush($applicationFileId: ID!) {
    applicationFile(id: $applicationFileId) {
      id
      mortgageDirectorLoanFilePush {
        id
        pushed
        loanNumber
        createdAt
        updatedAt
        statusCode
        statusDescription
      }
    }
  }
`);

export const useMortgageDirectorLosPush = (
  applicationFileId: string,
  options: IUseApolloQueryOptions = {}
) => {
  const [data, loading, , { refetch }] = useQuery(QMortgageDirectorLosPush, { applicationFileId }, options);

  const handleRefresh = useSafeCallback(async () => {
    await refetch({ applicationFileId });
  });

  let result: ILosPush | null | undefined;

  if (!loading) {
    if (data?.applicationFile) {
      result = data.applicationFile.mortgageDirectorLoanFilePush;
    } else {
      result = null;
    }
  }

  return [result, loading, handleRefresh] as const;
};
