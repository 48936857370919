import {
  ButtonRouterLink,
  Card,
  Divider,
  Format,
  HelpButton,
  T,
  useResponsive,
} from '@mortgagehippo/ds';
import { isNil } from 'lodash-es';
import { useContext, useEffect } from 'react';

import { useApplicationFileCan } from '../../../../../components/permissions';
import { ApplicationOverviewContext } from '../../application-overview-context';
import { type IApplicationOverviewSectionProps } from '../../types';
import {
  BlockContent,
  BlockContentContainer,
  BlockLabel,
  BlockValue,
  BlockValueLabel,
  HelpButtonContent,
  SectionBlock,
  SectionFooter,
} from '../common';
import { Dash } from '../dash';
import { ApplicationOverviewLiabilitiesSectionCreditPulls } from './application-overview-liabilities-section-credit-pulls';
import { useLiabilitiesOverviewSection } from './use-liabilities-overview-section';

export const ApplicationOverviewLiabilitiesSection = (props: IApplicationOverviewSectionProps) => {
  const { id, applicationFileId, className } = props;

  const responsive = useResponsive();
  const isTablet = responsive.TABLET.EXACT_OR_SMALLER;

  const [can, canReady] = useApplicationFileCan(applicationFileId);
  const [
    { calculatedLiabilities, applicants, hasExcludedLiabilities },
    calculatedLiabilitiesLoading,
  ] = useLiabilitiesOverviewSection(applicationFileId);

  const { sectionReady, sectionDisabled, ready } = useContext(ApplicationOverviewContext)!;

  useEffect(() => {
    if (calculatedLiabilitiesLoading || !canReady) {
      return;
    }

    sectionReady(id);
  }, [id, calculatedLiabilitiesLoading, sectionDisabled, sectionReady, canReady]);

  if (!ready) {
    return null;
  }

  const liabilitiesUrl = `#/applications/${applicationFileId}/integrations/credit_pull`;

  const showRatiosHelpButton = hasExcludedLiabilities;

  return (
    <Card compact className={className}>
      <SectionBlock hasBlockValueLabels>
        <BlockLabel>
          <div>
            <T>Ratios</T>
            {showRatiosHelpButton ? (
              <HelpButtonContent>
                <HelpButton
                  size="xs"
                  icon="warning"
                  iconColor="warning600"
                  tooltipProps={{ trigger: 'hover+focus' }}
                >
                  Some liabilities were manually excluded from calculating the ratios. See details
                  on the Credit Check page under Services
                </HelpButton>
              </HelpButtonContent>
            ) : null}
          </div>
        </BlockLabel>
        <BlockContentContainer>
          <BlockContent textAlign="center">
            <BlockValue>
              {isNil(calculatedLiabilities?.frontEndDti) ? (
                <Dash />
              ) : (
                <Format.Number value={calculatedLiabilities!.frontEndDti} suffix="%" />
              )}
            </BlockValue>
            <BlockValueLabel>DTI Front</BlockValueLabel>
          </BlockContent>
          <BlockContent textAlign="center">
            <BlockValue>
              {isNil(calculatedLiabilities?.backEndDti) ? (
                <Dash />
              ) : (
                <Format.Number value={calculatedLiabilities!.backEndDti} suffix="%" />
              )}
            </BlockValue>
            <BlockValueLabel>DTI Back</BlockValueLabel>
          </BlockContent>
          <BlockContent textAlign="center">
            <BlockValue>
              {isNil(calculatedLiabilities?.ltv) ? (
                <Dash />
              ) : (
                <Format.Number value={calculatedLiabilities!.ltv * 100} suffix="%" decimals={1} />
              )}
            </BlockValue>
            <BlockValueLabel>LTV</BlockValueLabel>
          </BlockContent>
          <BlockContent textAlign="center">
            <BlockValue>
              {isNil(calculatedLiabilities?.cltv) ? (
                <Dash />
              ) : (
                <Format.Number value={calculatedLiabilities!.cltv * 100} suffix="%" decimals={1} />
              )}
            </BlockValue>
            <BlockValueLabel>CLTV</BlockValueLabel>
          </BlockContent>
        </BlockContentContainer>
      </SectionBlock>
      <Divider compact />
      <SectionBlock>
        <BlockLabel>
          <T>Monthly Income</T>
        </BlockLabel>
        <BlockContentContainer>
          <BlockContent textAlign={isTablet ? 'left' : 'right'}>
            <BlockValue>
              {calculatedLiabilities?.householdMonthlyIncome ? (
                <Format.Currency value={calculatedLiabilities.householdMonthlyIncome} />
              ) : (
                <Dash />
              )}
            </BlockValue>
          </BlockContent>
        </BlockContentContainer>
      </SectionBlock>
      <Divider compact />
      <SectionBlock>
        <BlockLabel>
          <T>Assets</T>
        </BlockLabel>
        <BlockContentContainer>
          <BlockContent textAlign={isTablet ? 'left' : 'right'}>
            <BlockValue>
              {calculatedLiabilities?.assetsAmount ? (
                <Format.Currency value={calculatedLiabilities.assetsAmount} />
              ) : (
                <Dash />
              )}
            </BlockValue>
          </BlockContent>
        </BlockContentContainer>
      </SectionBlock>
      <Divider compact />
      <SectionBlock hasBlockValueLabels>
        <BlockLabel>
          <T>Liabilities</T>
        </BlockLabel>
        <BlockContentContainer>
          <BlockContent textAlign={isTablet ? 'left' : 'right'}>
            <BlockValue>
              {isNil(calculatedLiabilities?.householdMonthlyDebt) ? (
                <Dash />
              ) : (
                <Format.Currency value={calculatedLiabilities!.householdMonthlyDebt} />
              )}
            </BlockValue>
            <BlockValueLabel mobileTextAlign={isTablet ? 'left' : undefined}>
              Total Mo. Payments
            </BlockValueLabel>
          </BlockContent>

          <BlockContent textAlign={isTablet ? 'left' : 'right'}>
            <BlockValue>
              {isNil(calculatedLiabilities?.liabilitiesUnpaidBalanceAmount) ? (
                <Dash />
              ) : (
                <Format.Currency value={calculatedLiabilities!.liabilitiesUnpaidBalanceAmount} />
              )}
            </BlockValue>
            <BlockValueLabel mobileTextAlign={isTablet ? 'left' : undefined}>
              Outstanding Balances
            </BlockValueLabel>
          </BlockContent>
        </BlockContentContainer>
      </SectionBlock>
      <ApplicationOverviewLiabilitiesSectionCreditPulls applicants={applicants} />
      {can.VIEW_SERVICES_TAB ? (
        <>
          <Divider compact />
          <SectionFooter>
            <ButtonRouterLink
              to={liabilitiesUrl}
              icon="right-arrow"
              iconLocation="right"
              importance="tertiary"
              size="xs"
              compact
            >
              Open credit & liabilities
            </ButtonRouterLink>
          </SectionFooter>
        </>
      ) : null}
    </Card>
  );
};
