import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../../apollo';

export const MDeleteSmartview = graphql(`
  mutation DeleteSmartview($smartviewId: ID!) {
    deleteSmartview(smartviewId: $smartviewId) {
      id
    }
  }
`);

export const useDeleteSmartview = () => {
  const deleteSmartview = useMutation(MDeleteSmartview, undefined);

  return async (smartviewId: string) => {
    const response = await deleteSmartview({
      smartviewId,
    });
    return response.data!.deleteSmartview;
  };
};
