import {
  Card,
  fontSize,
  type ISelectOption,
  MediaBreakpoint,
  MilestoneList,
  notifications,
  Popover,
  Select,
  Skeleton,
  spacing,
  Title,
  useResponsive,
} from '@mortgagehippo/ds';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useApplicationFileCan } from '../../../components/permissions';
import { useMilestones } from '../../../hooks/use-milestones';
import { useUpdateActiveMilestone } from '../use-update-active-milestone';

const StyledCard = styled(Card)`
  padding: ${spacing(5)};
  margin-bottom: ${spacing(6)};
  margin-top: ${spacing(2)};
  display: flex;
  flex-wrap: wrap;

  ${MediaBreakpoint.DESKTOP_NARROW} {
    display: block;
  }

  ${MediaBreakpoint.PHONE} {
    padding: ${spacing(3)};
    margin-bottom: ${spacing(4)};
  }
`;

const StyledTitle = styled(Title)`
  font-size: ${fontSize('sm')};
  flex: 0 0 160px;
  margin: 0;

  ${MediaBreakpoint.PHONE} {
    flex: none;
  }
`;

const MilestonesContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: ${spacing(2)};
  padding-bottom: ${spacing(4)};

  ${MediaBreakpoint.PHONE} {
    flex: none;
    flex-wrap: wrap;
    padding-top: 0;
    padding-bottom: ${spacing(2)};
  }

  ${MediaBreakpoint.DESKTOP_NARROW} {
    flex: none;
  }
`;

const PopoverWrapper = styled.div`
  flex: 0 0 auto;
  text-align: right;
  margin-left: auto;
  padding-left: ${spacing(4)};

  ${MediaBreakpoint.DESKTOP_NARROW} {
    margin-left: 0;
    padding-left: 0;
  }

  ${MediaBreakpoint.PHONE} {
    margin-top: ${spacing(4)};
  }
`;

export interface IApplicationStatusMilestonesProps {
  applicationFileId: string;
}

export const ApplicationBorrowerMilestones = (props: IApplicationStatusMilestonesProps) => {
  const { applicationFileId } = props;
  const responsive = useResponsive();
  const [selectedActiveMilestoneId, setSelectedActiveMilestoneId] = useState<string | undefined>();
  const [submitting, setSubmitting] = useState<boolean>(false);

  /*
   * we must use "no-cache" as this component will be rendered at the same time for
   * different application files which will override the values in the cache every time.
   */
  const [milestones, milestonesLoading, refetch] = useMilestones(applicationFileId, {
    fetchPolicy: 'no-cache',
  });
  const updateActiveMilestone = useUpdateActiveMilestone(applicationFileId);
  const [can, canReady] = useApplicationFileCan(applicationFileId);
  const canUpdateActiveMilestone = canReady && can.UPDATE_APPLICATION_ACTIVE_MILESTONE;

  const activeIndex = milestones.findIndex((m) => m.active === true);

  const handleConfirm = useCallback(async () => {
    if (selectedActiveMilestoneId) {
      setSubmitting(true);
      try {
        await updateActiveMilestone(selectedActiveMilestoneId);
        await refetch();
        notifications.success({ message: 'Successfully set active Borrower Milestone.' });
      } catch (e) {
        notifications.error({ message: 'Error setting active Borrower Milestone.' });
      }
      setSubmitting(false);
    }
  }, [refetch, selectedActiveMilestoneId, updateActiveMilestone]);

  const selectOptions: ISelectOption[] = useMemo(
    () =>
      milestones.map((milestone) => ({
        label: milestone.name,
        value: milestone.id,
      })),
    [milestones]
  );

  const PopoverContent = useMemo(
    () => (
      <>
        <Select.Input
          onChange={setSelectedActiveMilestoneId}
          value={selectedActiveMilestoneId}
          name="active_milestone_select"
          options={selectOptions}
        />
        <div>All preceeding milestones will be marked as completed.</div>
      </>
    ),
    [selectOptions, selectedActiveMilestoneId]
  );

  useEffect(() => {
    if (!milestones.length) {
      return;
    }

    const activeMilestone = milestones.find((milestone) => milestone.active);
    if (activeMilestone) {
      setSelectedActiveMilestoneId(activeMilestone.id);
    }
  }, [milestones]);

  const loading = !canReady || milestonesLoading;

  const SelectActiveMilestone =
    !!milestones.length && canUpdateActiveMilestone ? (
      <PopoverWrapper>
        <Popover
          align="BottomCenter"
          buttonProps={{
            importance: 'secondary',
            size: 'sm',
            disabled: loading || submitting,
            loading: loading || submitting,
          }}
          content={PopoverContent}
          confirmLabelYes="Save"
          confirmLabelNo="Cancel"
          onConfirm={handleConfirm}
          confirm
          className="flex-wrap"
        >
          Set milestone
        </Popover>
      </PopoverWrapper>
    ) : null;

  if (loading) {
    return (
      <StyledCard>
        <StyledTitle level={2}>Borrower Milestones</StyledTitle>
        <MilestonesContainer>
          <Skeleton active paragraph={{ rows: 1, width: '100%' }} title={false} />
          {SelectActiveMilestone}
        </MilestonesContainer>
      </StyledCard>
    );
  }

  return (
    <StyledCard>
      <StyledTitle level={2}>Borrower Milestones</StyledTitle>
      <MilestonesContainer>
        <MilestoneList
          activeIndex={activeIndex}
          milestones={milestones}
          compact={responsive.PHONE.EXACT_OR_SMALLER}
        />
        {SelectActiveMilestone}
      </MilestonesContainer>
    </StyledCard>
  );
};
