import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../apollo';

const MTriggerMortgageBotDocumentPush = graphql(`
  mutation TriggerMortgageBotDocumentPushMutation($applicationFileId: ID!, $documentId: ID!) {
    triggerMortgageBotDocumentPush(applicationFileId: $applicationFileId, documentId: $documentId)
  }
`);

export const useTriggerMortgageBotDocumentPush = () => {
  const fn = useMutation(MTriggerMortgageBotDocumentPush, undefined);

  return useCallback(
    async (applicationFileId: string, documentId: string) => {
      const response = await fn({
        applicationFileId,
        documentId,
      });
      return response.data!.triggerMortgageBotDocumentPush;
    },
    [fn]
  );
};
