import { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { type RouteComponentProps } from 'react-router';

import { ActionType, useDispatchAction } from '$components/actions';

import { useFavicon } from '../../hooks/use-favicon';
import { useApplicationFile } from '../application/use-application-file-data';
import { ScreensharePageContent } from './screenshare-content';

const TITLE = 'Screen Share';

export interface IScreensharePageParams {
  applicationFileId: string;
}

export const ScreensharePage = (props: RouteComponentProps<IScreensharePageParams>) => {
  const { match } = props;
  const { params } = match;
  const { applicationFileId } = params;
  const [watchUrl, setWatchUrl] = useState('');

  const [{ applicants }] = useApplicationFile(applicationFileId);

  const faviconSrc = useFavicon();
  const dispatch = useDispatchAction();

  useEffect(() => {
    if (applicants.length > 0) {
      dispatch(
        {
          type: ActionType.GET_SCREENSHARE_WATCH_URL,
          applicationFileId,
          applicants,
        },
        (_action, _status, result: string) => {
          setWatchUrl(result);
        }
      );
    }
  }, [applicationFileId, dispatch, applicants]);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
        <link rel="icon" type="image/png" href={faviconSrc} />
      </Helmet>
      <ScreensharePageContent watchUrl={watchUrl} />
    </>
  );
};
