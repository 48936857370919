import { Empty, FieldBox, fontSize, lineHeight, MediaBreakpoint, spacing } from '@mortgagehippo/ds';
import { useCallback } from 'react';
import styled from 'styled-components';

import { type IApplicationApplicant } from '../../../../../pages/application/use-application-file-data';
import { type IUnderwritingCondition } from '../../../../../pages/application/use-undewriting-conditions';
import { type IBulkCustomTask } from '../../types';
import { TasksArrayList } from './tasks-array-list';
import { TasksListLabel } from './tasks-list-label';

const NoTasks = styled(Empty)`
  padding: ${spacing(5)};
  margin: 0 auto;
  width: 400px;

  p {
    text-align: center;
    font-size: ${fontSize('xs')};
    line-height: ${lineHeight('xs')};

    &:first-child {
      font-weight: bold;
      margin: 0;
    }
  }

  ${MediaBreakpoint.TABLET} {
    margin: 0;
    padding: ${spacing(3)};
    width: auto;
  }
`;

const ListWrapper = styled.div`
  padding-top: ${spacing(3)};
`;

interface ITasksListProps {
  tasks: IBulkCustomTask[];
  applicants: IApplicationApplicant[];
  underwritingConditions?: IUnderwritingCondition[];
  onEdit: (index: number) => void;
  onDelete: (index: number) => void;
}

export const TasksList = (props: ITasksListProps) => {
  const { tasks, applicants, underwritingConditions, onEdit, onDelete } = props;

  const showBorrowerNames = applicants.length > 1;

  const handleRenderItemLabel = useCallback(
    (itemValue: IBulkCustomTask) => (
      <TasksListLabel
        itemValue={itemValue}
        applicants={applicants}
        underwritingConditions={underwritingConditions}
        showBorrowerNames={showBorrowerNames}
      />
    ),
    [applicants, showBorrowerNames, underwritingConditions]
  );

  return (
    <>
      {tasks.length === 0 && (
        <NoTasks>
          <p>No tasks added</p>
          <p>
            After you add the tasks they will be listed here and you will be able to review the
            email to the borrower before sending them.
          </p>
        </NoTasks>
      )}
      {tasks.length > 0 && (
        <ListWrapper>
          <FieldBox name="drafts" label="Drafts" labelInvisible compact>
            <TasksArrayList
              onDelete={onDelete}
              onEdit={onEdit}
              values={tasks}
              renderItemLabel={handleRenderItemLabel}
              aria-labelledby="drafts"
            />
          </FieldBox>
        </ListWrapper>
      )}
    </>
  );
};
