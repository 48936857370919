import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../apollo';

const MTriggerEncompassPush = graphql(`
  mutation TriggerEncompassPush($applicationFileId: ID!) {
    triggerEncompassApiPush(applicationFileId: $applicationFileId)
  }
`);

export const useTriggerEncompassPush = () => {
  const fn = useMutation(MTriggerEncompassPush, undefined);

  return useCallback(
    async (applicationFileId: string): Promise<string> => {
      const response = await fn({ applicationFileId });

      return response.data!.triggerEncompassApiPush;
    },
    [fn]
  );
};
